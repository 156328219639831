import React from 'react';
import { Link } from 'react-router-dom';

import routes from '@constants/routes';
import helpers from '@utils/helpers';
import { noFieldData, permissionsMap } from '@constants/common';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import { commonLeadProviderStatsColumns } from '@constants/leads';
import Box from '@components/shared/Box/Box';
import ConfirmActionSwitch from '@components/shared/DataTable/actions/ConfirmActionSwitch';

export const getCampaignsAdditionalColumns = () => [
  {
    headerName: 'Lead provider name',
    field: 'leadProviderName',
    minWidth: 150,
    flex: 1,
    renderCell: ({ row }) =>
      helpers.getActionPermission(permissionsMap.leadProvidersView) &&
      row.leadProviderName ? (
        <Tooltip title="Lead Provider Page">
          <Link
            to={routes.leads.leadProviders.page.view.url(row.leadProviderId)}
          >
            {row.leadProviderName}
          </Link>
        </Tooltip>
      ) : (
        row.leadProviderName || noFieldData
      )
  }
];

export const getCampaignGroupColumns = (handleStatus) => [
  {
    headerName: 'Action',
    field: 'action',
    width: 60,
    sortable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <ConfirmActionSwitch
          size="small"
          checked={row.status}
          hidden={
            !helpers.getActionPermission(permissionsMap.leadProvidersManage)
          }
          modalProps={{
            itemId: row.campaignGroupId,
            content: row.status
              ? 'Do you want to pause?'
              : 'Do you want to resume?',
            onConfirm: () =>
              row.status
                ? handleStatus(row.campaignGroupId, true)
                : handleStatus(row.campaignGroupId, false)
          }}
        />
      </Box>
    )
  },
  {
    headerName: 'Name',
    field: 'name',
    width: 200
  },
  ...commonLeadProviderStatsColumns
];
