import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { useHistory } from 'react-router';

import { getColumns, initialFilterParams } from './config';

import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import routes from '@constants/routes';
import Loader from '@components/shared/Loader';
import Subscription from '@components/Users/Details/NotificationDetails/Subscription';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const NotificationsDetails = ({ id }) => {
  const history = useHistory();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    history.push({
      pathname: routes.users.page.view.url(id),
      search: ''
    });
  }, []);

  useEffect(() => {
    if (!history.location.search) {
      setLoader(false);
    }
  }, [history.location?.search]);

  if (loader) return <Loader />;

  return (
    <>
      <Subscription id={id} />
      <ODataPaginatedTable
        exportFileName={`Notifications details by user ${id}`}
        getColumns={getColumns}
        usePaginatedDataOptions={{
          apiUrl: adminApiUrls.notifications.list,
          initialFilterParams: {
            $orderBy: 'createdDate desc',
            $filter: {
              ...initialFilterParams,
              userId: {
                eq: id
              }
            }
          }
        }}
        rowKey="notificationId"
        customApi={AdminApi}
      />
    </>
  );
};

NotificationsDetails.propTypes = {
  id: PT.string.isRequired
};

export default NotificationsDetails;
