const secureVaultReportsApiBaseUrl =
  process.env.SECURE_VAULT_REPORT_API_URL || '';

const secureVaultReportsApiUrls = {
  discrepancyReport: {
    discrepancyReport: '/api/DiscrepancyReport',
    summaryReportMissingByDaily: '/api/CustomerCcInfosSummary',
    summaryReportMissingByStore: '/api/CustomerRequestCcInfosSummary',
    report: '/odata/CustomerCcInfos',
    summaryReportMissingByDailyBySummaryDate: (summaryDate) =>
      `/api/CustomerCcInfosSummary/details/${summaryDate}`,
    cardDetailsSummary: (summaryDate) =>
      `/api/CustomerCcInfosSummary/card-missing-previous-day-details/${summaryDate}`,
    customerDetailsSummary: (summaryDate) =>
      `/api/CustomerCcInfosSummary/customer-missing-previous-day-details/${summaryDate}`
  }
};

export { secureVaultReportsApiUrls, secureVaultReportsApiBaseUrl };
