import styled from '@emotion/styled';

import UserIcon from '@assets/icons/user.svg';
import CrownIcon from '@assets/icons/crown.svg';
import CupIcon from '@assets/icons/cup.svg';
import { palette } from '@utils/theme';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  padding-bottom: 16px;
  background-color: #fff;
  border-radius: 16px;
`;

export const StyledAccent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 75%;
  padding: 16px;
  padding-bottom: 85px;
  background: linear-gradient(180deg, #ffee53 0%, #e39600 100%);
  border-radius: 16px;
`;

export const StyledTitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-bottom: 8px;
`;

export const StyledHeading = styled.h1`
  font-weight: 600;
  letter-spacing: -1px;
  margin: 0;

  @media only screen and (width <= 1024px) {
    font-size: 1.5rem;
  }

  @media only screen and (1024 > width <= 1440px) {
    font-size: 2rem;
  }

  @media only screen and (width >= 1520px) {
    font-size: 3rem;
  }
`;

export const StyledCircle = styled.div`
  box-sizing: content-box;
  position: relative;
  margin: 0 auto;
  margin-bottom: 16px;
  background-color: #f8de76;
  border-radius: 50%;
  border: 5px solid #f7d95e;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (width <= 1440px) {
    width: 160px;
    height: 160px;
  }

  @media only screen and (width >= 1441px) {
    width: 260px;
    height: 260px;
  }
`;

export const StyledCrownIcon = styled(CrownIcon)`
  height: 10px;

  @media only screen and (width >= 1441px) {
    width: 30px;
    height: 30px;
  }
`;

export const StyledUserIcon = styled(UserIcon)`
  color: #ab7200;
`;

export const StyledCupIcon = styled(CupIcon)`
  position: absolute;
  bottom: -19px;
  left: 0;
  height: 50px;
  width: 50px;

  @media only screen and (width >= 1441px) {
    bottom: -49px;
    width: 100px;
    height: 100px;
  }
`;

export const StyledTextContainer = styled.div`
  text-align: center;
  color: #fff;
`;

export const StyledTitle = styled.h2`
  margin: 0;
  margin-bottom: 15px;
  font-weight: 600;
  letter-spacing: -1px;

  @media only screen and (width <= 1520px) {
    font-size: 1.5rem;
  }

  @media only screen and (width >= 1520px) {
    font-size: 2.5rem;
  }
`;

export const StyledPaperBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  display: flex;
  align-items: stretch;
  gap: 8px;
  justify-content: center;
  width: 90%;
  transform: translate(-50%, 50%);
`;

export const StyledPaper = styled.div`
  padding: 16px;
  background-color: ${palette.white};
  border-radius: 16px;
  width: 30%;
  border: 1px solid rgb(0 0 0 / 12%);
  box-shadow: 0 8px 12px -2px rgb(0 0 0 / 7%);
  flex: 1;
`;

export const StyledPaperTitle = styled.h2`
  margin: 0;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -1.5px;
  text-align: center;

  @media only screen and (width <= 1440px) {
    font-size: 1.5rem;
  }

  @media only screen and (width >= 1441px) {
    font-size: 2.5rem;
  }
`;

export const StyledPaperSubTitle = styled.h5`
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(0 0 0 / 60%);

  @media only screen and (width<= 1441px) {
    font-size: 0.5rem;
  }

  @media only screen and (width >= 1441px) {
    font-size: 1.1rem;
  }
`;
