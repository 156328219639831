import Logger from '@utils/logger';

class Permissions {
  static policiesKey = 'policies';

  static set(policies) {
    try {
      const serializedState = JSON.stringify(policies);
      localStorage.setItem(this.policiesKey, serializedState);
    } catch (error) {
      Logger.error(error);
    }
  }

  static get() {
    try {
      const serializedState = localStorage.getItem(this.policiesKey);
      return serializedState === null ? undefined : JSON.parse(serializedState);
    } catch (error) {
      Logger.error(error);
    }
  }

  static remove() {
    localStorage.removeItem(this.policiesKey);
  }
}

export default Permissions;
