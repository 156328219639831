import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';
import dayjs from 'dayjs';

import {
  getDateFromQuery,
  getODataFilterParams,
  setODataFilterParams
} from '@components/LeadReports/filters/utils';
import { useQueryParams } from '@hooks/useQueryParams';
import { useServerData } from '@hooks/useServerData';
import { leadManagementApiUrls } from '@constants/leadManagementApi';
import LeadManagementApi from '@utils/leadManagementApi';

const useLeadReportFilters = ({
  baseInitialValues,
  dateRangeField,
  isStackPerformance
}) => {
  const history = useHistory();
  const params = useQueryParams();

  const { data, fetchData } = useServerData({
    endpoint: async () => {
      const response = await LeadManagementApi.get(
        leadManagementApiUrls.common.campaigns,
        {
          params: {
            $select: 'campaignId,campaignName',
            $orderby: 'campaignName'
          }
        }
      );

      return response?.map((item) => ({
        label: item.campaignName,
        value: item.campaignId
      }));
    }
  });

  const initialValues = useMemo(() => {
    if (!history.location.search) {
      return baseInitialValues;
    }
    const filterParams = getODataFilterParams(history.location.search);

    if (filterParams && Object.keys(filterParams)?.length) {
      return {
        ...baseInitialValues,
        ...filterParams,
        [dateRangeField]:
          baseInitialValues[dateRangeField] &&
          getDateFromQuery(
            filterParams?.[dateRangeField],
            baseInitialValues[dateRangeField]
          )
      };
    }
    return baseInitialValues;
  }, [baseInitialValues, history.location.search]);

  useEffect(() => {
    fetchData();
    const filterParams = setODataFilterParams(initialValues, dateRangeField);

    if (isStackPerformance) {
      delete filterParams.LeadCostFrom;
      delete filterParams.LeadCostTo;
      filterParams.LeadCost = {
        ge: 0,
        le: 0
      };
    }
    const datePeriod = initialValues.DatePeriod;

    history.push({
      pathname: history.location.pathname,
      search: qs.stringify(
        {
          $filter: JSON.stringify(filterParams),
          datePeriod: datePeriod
        },
        { skipEmptyString: true, skipNull: true }
      )
    });
  }, []);

  const handleFilterUpdate = useCallback(
    (values) => {
      const updatedDate = [values.Date[0], dayjs(values.Date[1].endOf('day'))];
      const datePeriod = values.DatePeriod;

      const filterParams = setODataFilterParams(
        {
          ...values,
          Date: updatedDate,
          ...(isStackPerformance && {
            LeadCost: {
              ge: values.LeadCostFrom,
              le: values.LeadCostTo
            }
          })
        },
        dateRangeField
      );

      if (isStackPerformance) {
        delete filterParams.LeadCostFrom;
        delete filterParams.LeadCostTo;
      }

      return history.push({
        pathname: history.location.pathname,
        search: qs.stringify({
          $filter: JSON.stringify(filterParams),
          datePeriod: datePeriod
        })
      });
    },
    [history.location.search, params]
  );

  const onReset = async (resetForm) => {
    resetForm({
      values: baseInitialValues,
      errors: {}
    });
    handleFilterUpdate(baseInitialValues);
  };

  return {
    campaignsOptions: data,
    initialValues,
    onReset,
    handleFilterUpdate
  };
};

export default useLeadReportFilters;
