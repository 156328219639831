import React from 'react';
import PT from 'prop-types';
import MuiTable from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { StyledTableContainer } from './styled';

const Table = ({ columns, rows, maxWidth, ...props }) => (
  <StyledTableContainer maxWidth={maxWidth}>
    <MuiTable size="small" {...props}>
      <TableHead>
        <TableRow>
          {columns.map((columnName, idx) => (
            <TableCell key={idx}>{columnName}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, idx) => (
          <TableRow key={idx}>
            <TableCell width={30}>{idx + 1}</TableCell>
            {Object.keys(row).map((key) => (
              <TableCell key={key}>{row[key]}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  </StyledTableContainer>
);

Table.defaultProps = {
  rows: []
};

Table.propTypes = {
  columns: PT.arrayOf(PT.string).isRequired,
  rows: PT.arrayOf(PT.shape({})),
  maxWidth: PT.string
};

export default Table;
