import * as Yup from 'yup';

import validators from '@utils/validators';

export const addABANumberSchema = Yup.object().shape({
  routingNumber: validators.equalsTo(9).label('Routing number').required(),
  bankName: Yup.string().label('Bank name').required(),
  state: Yup.string().label('State').required(),
  bankSourceId: Yup.string().label('Source').required(),
  isBankTranslationNeeded: Yup.bool().label('Is Bank Name Translation needed'),
  bankTranslation: Yup.string()
    .label('Translation Name')
    .when('isBankTranslationNeeded', {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired()
    })
});
