import React from 'react';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import SystemNotificationsDetails from '@components/SystemNotifications/Details';

const ViewSystemNotificationPage = () => (
  <MainLayout title="System notification page">
    <SystemNotificationsDetails />
  </MainLayout>
);

export default ViewSystemNotificationPage;
