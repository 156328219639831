import React, { memo } from 'react';
import PT from 'prop-types';

import Loader from '@components/shared/Loader';
import { leadFormStepsOrder } from '@constants/leadFormConfig';
import Typography from '@components/shared/Typography/Typography';
import { Step, StepLabel, Stepper } from '@components/shared/Stepper/Stepper';

const LeadProviderSteps = ({
  activeStep,
  lastLeadFilledStep,
  setActiveStep,
  leadData
}) => {
  if (!activeStep) return <Loader />;

  const activeStepIndex = leadFormStepsOrder.indexOf(activeStep);
  const lastFilledStepIndex = leadFormStepsOrder.indexOf(lastLeadFilledStep);

  const handleStepChange = (stepKey) => {
    leadData && setActiveStep(stepKey);
  };

  return (
    <div>
      <Typography variant="h4" my={2}>
        Form steps
      </Typography>

      <Stepper orientation="vertical" activeStep={activeStepIndex}>
        {leadFormStepsOrder.map((stepKey, idx) => (
          <Step
            key={idx}
            disabled={idx === activeStepIndex || idx > lastFilledStepIndex + 1}
            onClick={() => handleStepChange(stepKey)}
          >
            <StepLabel>{stepKey}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

LeadProviderSteps.propTypes = {
  activeStep: PT.string,
  lastLeadFilledStep: PT.string,
  setActiveStep: PT.func,
  leadData: PT.any
};

export default memo(LeadProviderSteps);
