import React from 'react';
import { useHistory } from 'react-router-dom';

import { getColumns } from './config';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';
import { subTabsListMain } from '@components/CsvComparison/config';
import routes from '@constants/routes';
import CsvComparisonApi from '@utils/csvComparisonApi';
import { csvComparisonApiUrls } from '@constants/csvComparisonApi';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import { permissionsMap } from '@constants/permissions';
import SecurityGuard from '@components/shared/SecurityGuard/SecurityGuard';
import { tabNameMain } from '@constants/comparison';

const CsvComparisonConfigurationsPage = () => {
  const history = useHistory();

  const onCreateNew = () => {
    history.push(routes.csvCompare.configurations.create);
  };

  return (
    <MainLayout title="CSV Compare">
      <NavTabsPanel
        currentId={tabNameMain.configurations}
        tabsList={subTabsListMain}
        ariaLabel="access-tabs"
      />
      <Stack flexDirection="row" justifyContent="flex-end" marginTop={1}>
        <SecurityGuard permission={permissionsMap.CSVCompareManage}>
          <Button onClick={onCreateNew}>Create a new configuration</Button>
        </SecurityGuard>
      </Stack>

      <ODataPaginatedTable
        exportCSV={false}
        getColumns={getColumns}
        usePaginatedDataOptions={{
          apiUrl: csvComparisonApiUrls.configurations.list,
          initialFilterParams: {
            $select:
              'id,dateCreated,dateUpdated,name,usernameCreated,usernameUpdated,fileIdentifier',
            $orderBy: 'dateCreated desc'
          }
        }}
        customApi={CsvComparisonApi}
        rowKey="id"
      />
    </MainLayout>
  );
};

export default CsvComparisonConfigurationsPage;
