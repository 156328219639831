import React, { useMemo, useState } from 'react';
import PT from 'prop-types';
import Card from '@mui/material/Card';
import { debounce } from 'lodash';

import { StyledList, StyledCardHeader } from './styled';
import SearchInput from './SearchInput';

import Box from '@components/shared/Box/Box';
import ListItem from '@components/shared/List/ListItem';
import { Checkbox } from '@components/shared/Form/Checkbox';
import { CheckListIcon } from '@components/shared/icons/Icons';

const CheckList = ({
  title,
  icon,
  list: _list,
  selectedKeys,
  setSelected,
  listName
}) => {
  const [checkedList, setCheckedList] = useState(
    _list.map((item) => ({
      [item.key]: selectedKeys?.includes(item.key)
    })) || []
  );
  const [list, setList] = useState(_list);

  const getChecked = (list) =>
    list?.filter((item) => Object.keys(item).some((key) => item[key]));

  const handleSelectKeys = (list) => {
    const newCheckedKeysList = getChecked(list)?.map(
      (item) => Object.keys(item)[0]
    );
    setSelected(newCheckedKeysList);
  };

  const handleToggle = (checkedItem, isChecked) => {
    const clonedCheckedList = [...checkedList];
    const newCheckedList = clonedCheckedList.reduce((acc, item) => {
      if (checkedItem.key == Object.keys(item)[0]) {
        return [...acc, { [checkedItem.key]: isChecked }];
      } else {
        return [...acc, item];
      }
    }, []);

    setCheckedList(newCheckedList);
    handleSelectKeys(newCheckedList);
  };

  const handleToggleAll = (isChecked) => {
    const clonedCheckedList = [...checkedList];
    const newCheckedList = clonedCheckedList.map((item) => ({
      [Object.keys(item)[0]]: isChecked
    }));
    setCheckedList(newCheckedList);

    handleSelectKeys(newCheckedList);
  };

  const handleSearch = debounce((value) => {
    if (value?.length > 0) {
      const filteredData = _list?.filter((item) =>
        item.name?.toLowerCase().includes(value.toLowerCase())
      );
      setList(filteredData);
    } else {
      setList(_list);
    }
  }, 0);

  const isAllChecked = useMemo(
    () =>
      getChecked(checkedList)?.length === list?.length && list?.length !== 0,
    [checkedList, handleToggleAll]
  );
  const isIndeterminate = useMemo(
    () =>
      getChecked(checkedList)?.length !== list?.length &&
      getChecked(checkedList)?.length !== 0,
    [checkedList, handleToggleAll]
  );
  const numberOfChecked = useMemo(
    () => getChecked(checkedList)?.length || 0,
    [checkedList]
  );

  return (
    <Box my={2}>
      <Card>
        <StyledCardHeader
          sx={{ px: 2, pt: 0, pb: 0 }}
          avatar={icon || <CheckListIcon />}
          title={title}
          subheader={
            <>
              <Checkbox
                checked={isAllChecked}
                indeterminate={isIndeterminate}
                onChange={(e) => handleToggleAll(e.target.checked)}
                labelStyles={{ marginRight: 0, marginTop: 0, marginBottom: 0 }}
                disabled={!list?.length}
              />
              <span>
                {numberOfChecked}/{list?.length || 0} selected
              </span>
            </>
          }
        />
        <SearchInput handleSearch={handleSearch} />
        <StyledList dense component="div" role="list">
          {list?.map((value) => {
            const labelId = `${listName}-${value.key}-label`;

            return (
              <ListItem key={value.key} disablePadding sx={{ paddingLeft: 2 }}>
                <Checkbox
                  checked={!!checkedList.find((item) => item[value.key])}
                  onChange={(e) => handleToggle(value, e.target.checked)}
                  tabIndex={-1}
                  disableRipple
                  label={value.name}
                  inputProps={{
                    'aria-labelledby': labelId
                  }}
                  fullWidth
                  labelStyles={{ width: '100%' }}
                />
              </ListItem>
            );
          })}
        </StyledList>
      </Card>
    </Box>
  );
};

const itemPropTypes = PT.shape({
  key: PT.number.isRequired,
  name: PT.string.isRequired
});

CheckList.propTypes = {
  title: PT.string,
  icon: PT.node,
  list: PT.arrayOf(itemPropTypes),
  selectedKeys: PT.arrayOf(PT.number),
  setSelected: PT.func,
  listName: PT.string.isRequired
};

export default CheckList;
