import React from 'react';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import {
  tabsIds,
  tabsList,
  getSummaryColumns
} from '@components/PaymentsRerun/PaymentsRerun.config';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import { paymentProcessingApiUrls } from '@constants/paymentProcessingApiUrls';
import PaymentProcessingApi from '@utils/paymentProcessingApi';

const PaymentsRerunSummaryPage = () => (
  <MainLayout title="Performance Summary - Payments Rerun">
    <NavTabsPanel
      currentId={tabsIds.performance}
      tabsList={tabsList}
      ariaLabel="lead-provider-tabs"
    />
    <ODataPaginatedTable
      getColumns={getSummaryColumns}
      usePaginatedDataOptions={{
        apiUrl: paymentProcessingApiUrls.paymentsRerun.performance,
        initialFilterParams: {
          $orderBy: 'plannedDate desc'
        }
      }}
      rowKey="paymentsRerunPerformanceId"
      detailPanelHeight={500}
      customApi={PaymentProcessingApi}
    />
  </MainLayout>
);

export default PaymentsRerunSummaryPage;
