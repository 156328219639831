import React from 'react';

import { LoaderWrapper } from '../styled';

import LinearProgress from '@components/shared/LinearProgress/LinearProgress';

const LoadingOverlay = () => (
  <LoaderWrapper>
    <LinearProgress width="100%" />
  </LoaderWrapper>
);

export default LoadingOverlay;
