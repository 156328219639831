import {
  formatValueWithPercentage,
  getTotalAndSubtotalCellClassName,
  renderTotalAndSubtotalCell
} from '@components/shared/DataTable/DataTable.utils';

export const columns = [
  {
    headerName: 'Denial Reason',
    field: 'denialReason',
    minWidth: 350,
    flex: 1,
    aggregable: false,
    renderCell: renderTotalAndSubtotalCell,
    cellClassName: getTotalAndSubtotalCellClassName
  },
  {
    headerName: 'Denied By',
    field: 'deniedBy',
    minWidth: 200,
    flex: 1
  },
  {
    headerName: 'Count',
    field: 'count',
    type: 'number',
    aggregable: true,
    width: 100,
    cellClassName: getTotalAndSubtotalCellClassName
  },
  {
    headerName: 'Percentage of total',
    field: 'percentageTotal',
    type: 'number',
    width: 170,
    aggregable: true,
    valueFormatter: formatValueWithPercentage,
    cellClassName: getTotalAndSubtotalCellClassName
  }
];
