import React from 'react';
import { Link } from 'react-router-dom';

import UserEventsLink from '@components/shared/UserEventsLink';
import {
  filterOperators,
  noFieldData,
  permissionsMap
} from '@constants/common';
import routes from '@constants/routes';
import helpers from '@utils/helpers';
import { cleanObject } from '@utils/object-cleaner';
import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import Box from '@components/shared/Box/Box';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import { CheckCircleIcon } from '@components/shared/icons/Icons';
import {
  emailColumnWidth,
  eventsColumnWidth,
  fullNameColumnWidth,
  phoneNumberColumnWidth,
  timeDateColumnWidth
} from '@components/shared/DataTable/DataTable.constants';
import { muiDataTableTypes } from '@constants/tableFilterConfig';

export const initialFilterParams = cleanObject({
  'userLoan/loanNumber': '',
  'userLoan/customerId': '',
  'customerInfo/email': {
    contains: ''
  },
  applicationId: '',
  userId: {
    contains: ''
  },
  applicationUid: {
    eq: {
      type: 'guid',
      value: ''
    }
  },
  'customerInfo/firstName': {
    startswith: ''
  },
  'customerInfo/lastName': {
    startswith: ''
  },
  'customerInfo/phone': {
    contains: ''
  },
  createdDate: {
    ge: '',
    le: ''
  },
  locked: ''
});

export const getColumns = () => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <EditActionButton
          path={routes.applications.page.edit.url(row.applicationUid)}
          hidden={
            !helpers.getActionPermission(permissionsMap.applicationManage)
          }
        />
        <ShowDetailsActionButton
          path={routes.applications.page.view.url(row.applicationId)}
        />
      </Box>
    )
  },
  {
    headerName: 'Loan number',
    exportField: 'userLoan.loanNumber',
    field: 'userLoan/loanNumber',
    minWidth: 120,
    flex: 1,
    renderCell: ({ row }) => row.userLoan?.loanNumber
  },
  {
    headerName: 'Full name',
    exportField: 'customerInfo.firstName',
    field: 'customerInfo/firstName',
    minWidth: fullNameColumnWidth,
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) =>
      helpers.getActionPermission(permissionsMap.usersView) ? (
        <Tooltip title="User page">
          <Link to={routes.users.page.view.url(row.userId)}>
            {helpers.getDisplayName(
              row.customerInfo?.firstName,
              row.customerInfo?.lastName
            )}
          </Link>
        </Tooltip>
      ) : (
        helpers.getDisplayName(
          row.customerInfo?.firstName,
          row.customerInfo?.lastName
        )
      )
  },
  {
    headerName: 'SSN (last 4)',
    exportField: 'customer.ssnShort',
    field: 'customer/ssnShort',
    minWidth: 90,
    flex: 1,
    type: muiDataTableTypes.number,
    renderCell: ({ row }) =>
      helpers.getFormattedSsn(row?.customerInfo?.ssnShort) || noFieldData
  },
  {
    headerName: 'User email',
    exportField: 'customerInfo.email',
    field: 'customerInfo/email',
    minWidth: emailColumnWidth,
    flex: 1,
    renderCell: ({ row }) => row.customerInfo?.email
  },
  {
    headerName: 'Loan Type',
    exportField: 'loanType',
    field: 'loanType',
    minWidth: phoneNumberColumnWidth,
    flex: 1,
    renderCell: ({ row }) => row?.loanType,
    type: muiDataTableTypes.singleSelect,
    valueOptions: [
      { label: 'Default', value: 'Default' },
      { label: 'Installment', value: 'Installment' },
      { label: 'Line Of Credit', value: 'LineOfCredit' },
      { label: 'Advance', value: 'Advance' }
    ]
  },
  {
    headerName: 'Phone number',
    exportField: 'customerInfo.phone',
    field: 'customerInfo/phone',
    minWidth: phoneNumberColumnWidth,
    flex: 1,
    renderCell: ({ row }) => row.customerInfo?.phone
  },
  {
    headerName: 'Signed',
    exportField: 'userLoan.status',
    field: 'userLoan/status',
    minWidth: 90,
    flex: 1,
    filterable: false,
    renderCell: ({ row }) =>
      row.userLoan?.status === 'S' ? <CheckCircleIcon /> : ''
  },
  {
    headerName: 'Created date',
    field: 'createdDate',
    minWidth: timeDateColumnWidth,
    flex: 1,
    valueFormatter: (createdDate) => helpers.getFormattedDate(createdDate),
    renderCell: ({ row, field }) => helpers.getFormattedDate(row?.[field]),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Application id',
    field: 'applicationId',
    minWidth: 130,
    flex: 1
  },
  {
    // Hot fix filters
    headerName: 'Events',
    field: 'user',
    minWidth: eventsColumnWidth,
    flex: 1,
    filterable: false,
    sortable: false,
    renderCell: ({ row }) =>
      row.userId && helpers.getActionPermission(permissionsMap.loggedView) ? (
        <UserEventsLink executedBy={row.userId}>
          {helpers.cutString(row.userId, 5)}
        </UserEventsLink>
      ) : (
        row.userId || noFieldData
      )
  }
];
