import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';

import {
  additionalColumns,
  getColumns,
  pinnedColumns,
  aggregationModel,
  getAggregationFunctions,
  exportOptions,
  exportFields
} from './config';

import AffiliateReportsFilterForm from '@components/LeadReports/filters/AffiliateReportsFilterForm/AffiliateReportsFilterForm';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import DataTable from '@components/shared/DataTable/DataTable';
import { useServerData } from '@hooks/useServerData';
import ExportTable from '@components/shared/ExportTable/ExportTable';
import { leadManagementReportsApiUrls } from '@constants/leadManagementReportsApi';
import LeadManagementReportsApi from '@utils/leadManagementReportsApi';

const AffiliateReportPage = () => {
  const history = useHistory();
  const params = qs.parse(history.location.search);
  const apiRef = useRef();

  const { data, isLoading, error, fetchData } = useServerData({
    endpoint: async () => {
      const response = await LeadManagementReportsApi.get(
        leadManagementReportsApiUrls.affiliateReport,
        {
          params
        }
      );

      return response;
    }
  });

  useEffect(() => {
    if (!isEmpty(params)) {
      fetchData();
    }
  }, [history.location.search]);

  return (
    <MainLayout title="Affiliate Report">
      <AffiliateReportsFilterForm />
      <DataTable
        apiRef={apiRef}
        columns={[...getColumns(data), ...additionalColumns]}
        tableData={data}
        rowKey="id"
        loading={isLoading}
        error={error}
        pinnedColumnsLeft={pinnedColumns}
        pagination={false}
        initialParams={{
          columns: { columnVisibilityModel: { leadCampaignName: false } },
          rowGrouping: {
            model: ['leadCampaignName']
          },
          aggregation: {
            model: aggregationModel
          }
        }}
        aggregationFunctions={getAggregationFunctions(data)}
        getAggregationPosition={(groupNode) =>
          groupNode.childrenExpanded ? 'footer' : 'inline'
        }
        disableRowSelectionOnClick
        hideAggregationInColumnMenu
        hideFooter
        exportCsvProps={{
          fields: exportFields.map(({ field }) => field),
          ...exportOptions
        }}
        additionalContentInToolbar={() => (
          <ExportTable
            apiRef={apiRef}
            exportOptions={exportOptions}
            fields={exportFields}
          />
        )}
      />
    </MainLayout>
  );
};

export default AffiliateReportPage;
