import React from 'react';
import PT from 'prop-types';

import CampaignsDataTable from '@components/Leads/CampaignsDataTable';
import '@components/Leads/LeadRatesTable/LeadRatesTable.scss';
import DataTable from '@components/shared/DataTable/DataTable';

const LeadRatesTable = ({
  campaignsTableProps,
  refetchList,
  additionalCampaignColumns,
  additionalCampaignActions,
  updateKey,
  isShownCaps,
  ...props
}) => (
  <>
    <DataTable
      disableColumnFilter
      getDetailPanelContent={({ row }) => (
        <CampaignsDataTable
          updateKey={updateKey}
          tableData={row?.campaigns}
          additionalColumns={additionalCampaignColumns}
          additionalActions={additionalCampaignActions}
          {...campaignsTableProps}
          refetchList={refetchList}
          isShownCaps={isShownCaps}
        />
      )}
      refetchList={refetchList}
      {...props}
    />
  </>
);

LeadRatesTable.propTypes = {
  campaignsTableProps: PT.any,
  refetchList: PT.func,
  additionalCampaignColumns: PT.shape({
    startColumns: PT.array.isRequired,
    endColumns: PT.array.isRequired
  }),
  additionalCampaignActions: PT.func,
  hideExpand: PT.bool,
  updateKey: PT.func,
  isShownCaps: PT.bool
};

export default LeadRatesTable;
