import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import PT from 'prop-types';
import dayjs from 'dayjs';

import { subTabsList } from '@components/Dashboard/InternalPerformance/config';
import Alert from '@components/shared/Alert/Alert';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Logger from '@utils/logger';
import DashboardFilter from '@components/Dashboard/Filter';
import { selectDashboardFilters } from '@store/dashboardFilters';
import DataTable from '@components/shared/DataTable/DataTable';
import helpers from '@utils/helpers';
import { exportTypes } from '@components/shared/ExportTableCSV';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';

const InternalPerformance = ({
  title,
  withoutFilter,
  endpoint,
  exportFileName,
  columns,
  getServerData,
  customApiBaseUrl,
  shouldDisableDate,
  initialFilterValues
}) => {
  const filtersInitialState = useSelector(selectDashboardFilters);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getData();
  }, [history.location.search]);

  const getData = async () => {
    try {
      if (history.location) {
        if (!history.location.search) {
          history.replace({
            search: qs.stringify(filtersInitialState)
          });
        } else {
          setData(null);
          const parsedParams = qs.parse(history.location.search, {
            parseBooleans: true,
            parseNumbers: true
          });
          setIsLoading(true);
          const response = await getServerData(parsedParams);
          setData(response.items);
        }
      }
    } catch (e) {
      Logger.error(e);
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const fields = columns
    .filter(({ field, exportField }) => {
      const fieldName = exportField || field;
      return fieldName !== 'actions' && fieldName;
    })
    .map(({ field }, index) => ({
      field,
      key: index
    }));

  return (
    <MainLayout title={title}>
      {!withoutFilter && (
        <DashboardFilter
          shouldDisableDate={shouldDisableDate}
          initialFilterValues={initialFilterValues}
        />
      )}

      <NavTabsPanel
        currentId={title}
        tabsList={subTabsList}
        ariaLabel="access-tabs"
      />

      {!error && (
        <>
          <DataTable
            hideFilters
            tableData={helpers.mapWithPK(data) || []}
            columns={columns}
            loading={isLoading}
            rowKey="key"
            pagination={false}
            hideFooter
            columnBuffer={2}
            columnThreshold={2}
            isVirtualized
            exportCsvProps={{
              fileName: exportFileName,
              apiUrl: endpoint + history.location.search,
              fields,
              fromServer: true,
              type: exportTypes.json,
              customApiBaseUrl: customApiBaseUrl,
              root: 'items'
            }}
          />
        </>
      )}
      {error && (
        <Alert
          title="Server Error"
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
          sx={{ mt: 3 }}
        />
      )}
    </MainLayout>
  );
};

InternalPerformance.propTypes = {
  title: PT.string.isRequired,
  withoutFilter: PT.bool,
  endpoint: PT.string.isRequired,
  exportFileName: PT.string.isRequired,
  columns: PT.array.isRequired,
  getServerData: PT.func.isRequired,
  customApiBaseUrl: PT.string,
  shouldDisableDate: PT.bool,
  initialFilterValues: PT.shape({
    startDate: PT.instanceOf(dayjs).isRequired
  })
};

export default InternalPerformance;
