import dayjs from 'dayjs';

import { dateFormat } from '@constants/common';

const getDefaultFilter = () => ({
  startDate: dayjs().subtract(1, 'month').format(dateFormat),
  endDate: dayjs().format(dateFormat),
  startTime: dayjs().subtract(1, 'day').startOf('day'),
  endTime: dayjs().endOf('day')
});

export const getInitialValues = (periodFilter) => {
  if (!periodFilter) {
    return getDefaultFilter();
  }
  const parsedFilters = JSON.parse(periodFilter);
  if (!parsedFilters.createdDate) {
    return getDefaultFilter();
  }
  return {
    startDate: dayjs(parsedFilters.createdDate.ge).format(dateFormat),
    endDate: dayjs(parsedFilters.createdDate.le).format(dateFormat),
    startTime: dayjs(parsedFilters.createdDate.ge),
    endTime: dayjs(parsedFilters.createdDate.le)
  };
};
