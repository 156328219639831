import React from 'react';

import { CheckCircleIcon } from '@components/shared/icons/Icons';
import { formStepPulseMap } from '@constants/common';
import helpers from '@utils/helpers';

export const columns = [
  {
    headerName: 'Form step',
    field: 'pageName',
    minWIdth: 120,
    flex: 1,
    renderCell: ({ row }) => formStepPulseMap[row?.pageName] || row?.pageName
  },
  {
    headerName: 'Time spent',
    field: 'pulse',
    minWIdth: 200,
    flex: 1,
    renderCell: ({ row }) =>
      row?.pulse ? helpers.getDateDiffString(row.pulse.pulse) : '-'
  },
  {
    headerName: 'Validation errors',
    field: 'errors',
    minWIdth: 200,
    flex: 1,
    renderCell: ({ row }) => (row?.logs.length ? <CheckCircleIcon /> : '')
  }
];
