import React from 'react';
import PT from 'prop-types';

import { getHistoryColumns, initialFilterParams } from './config';

import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const SystemNotificationUsersTable = ({ id }) => (
  <ODataPaginatedTable
    getColumns={getHistoryColumns}
    usePaginatedDataOptions={{
      apiUrl: adminApiUrls.notificationCustomers.list,
      initialFilterParams: {
        $filter: {
          ...initialFilterParams,
          instanceUid: {
            eq: id
          }
        }
      }
    }}
    rowKey="notificationId"
    customApi={AdminApi}
    exportLastNameField="lastName"
  />
);

SystemNotificationUsersTable.propTypes = {
  id: PT.number
};

export default SystemNotificationUsersTable;
