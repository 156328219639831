import React from 'react';
import PT from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

import Box from '@components/shared/Box/Box';

const sizes = {
  small: 20,
  large: 60
};

const Loader = ({ elSize, sx, ...props }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', p: '20px', ...sx }}>
    <CircularProgress size={sizes[elSize]} {...props} />
  </Box>
);

Loader.defaultProps = {
  elSize: 'large',
  sx: {}
};

Loader.propTypes = {
  elSize: PT.oneOf(['small', 'large']),
  sx: PT.object
};

export default Loader;
