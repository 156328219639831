export const filtersPanelConfig = {
  filterFormProps: {
    // Customize inputs by passing props
    logicOperatorInputProps: {
      variant: 'standard',
      size: 'small'
    },
    columnInputProps: {
      variant: 'outlined',
      size: 'small'
    },
    operatorInputProps: {
      variant: 'outlined',
      size: 'small'
    },
    valueInputProps: {
      InputComponentProps: {
        variant: 'outlined',
        size: 'small'
      }
    },
    deleteIconProps: {
      sx: {
        '& .MuiSvgIcon-root': { color: '#bc2627 !important' }
      }
    }
  },
  sx: {
    '& .MuiDataGrid-filterForm': { p: 2 },
    '& .MuiDataGrid-filterForm:nth-of-type(even)': {
      backgroundColor: (theme) =>
        theme.palette.mode === 'dark' ? '#444' : '#f5f5f5'
    },
    '& .MuiDataGrid-filterFormLogicOperatorInput': {
      mr: 2,
      width: 65
    },
    '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
    '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
    '& .MuiDataGrid-filterFormDeleteIcon': {
      mb: '50px !important'
    }
  }
};
