import React, { useState, useRef, useEffect } from 'react';
import PT from 'prop-types';
import { InputBase, Paper } from '@mui/material';

import FormError from '@components/shared/Form/Error';
import { formHasErrorsMessage } from '@constants/common';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

let searchTimeout = null;

const CCSearchInput = ({ setSearchData }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const searchRef = useRef();

  useEffect(() => {
    if (searchRef) {
      searchRef.current?.focus();
    }
  }, [searchRef]);

  const getSearchData = async (value) => {
    setLoading(true);
    try {
      const searchResult = value.trim();

      const data = await AdminApi.get(adminApiUrls.creditCard.search, {
        params: {
          key: searchResult
        }
      });
      setLoading(false);
      setSearchData(data);
    } catch (e) {
      setLoading(false);
      setError(e.nonFieldErrors || e.message || formHasErrorsMessage);
      setSearchData(null);
    } finally {
      searchRef.current.focus();
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setError(null);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    if (!value) {
      setSearchData(null);
      return;
    }
    searchTimeout = setTimeout(() => getSearchData(value), 1500);
  };

  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          boxShadow: 'none',
          border: '1px solid #b5b5b5',
          paddingRight: 1
        }}
      >
        <InputBase
          type="search"
          ref={searchRef}
          onChange={handleSearch}
          disabled={loading}
          endAdornment={loading ? <span>searching...</span> : null}
          placeholder="Enter customer ID or email"
          fullWidth
          autoFocus
        />
      </Paper>
      <FormError error={error} />
    </>
  );
};

CCSearchInput.propTypes = {
  setSearchData: PT.func.isRequired
};

export default CCSearchInput;
