import React, { useMemo } from 'react';
import PT from 'prop-types';

import { addressStepInitialValues, addressStepSchema } from './schema';

import { monthsOptions, yearsOptions } from '@constants/monthsOptions';
import helpers from '@utils/helpers';
import StepContainer from '@components/Application/LoanForm/StepContainer';
import withStepData from '@components/Application/LoanForm/withStepData';
import FormikField from '@components/shared/Form/FormikField/FormikField';

const AddressStep = ({ onSubmit, initialValues, options }) => {
  const formattedOptions = useMemo(
    () => helpers.getOptionsObject(options),
    [options]
  );

  const mergedInitialValues = useMemo(
    () => helpers.mergeLoanFormData(initialValues, addressStepInitialValues),
    [initialValues]
  );

  const handleSubmit = (values, handlers) => {
    const payload = {
      ...values,
      state: formattedOptions.state?.find(({ value }) => value == values.state)
        ?.value
    };
    onSubmit({ values: payload, initialValues: mergedInitialValues }, handlers);
  };

  return (
    <StepContainer
      formSchema={addressStepSchema}
      initialValues={mergedInitialValues}
      onSubmit={handleSubmit}
      title="Address"
    >
      <FormikField
        type="text"
        name="address1"
        label="Address 1:"
        placeholder="e.g. 3221 Indiana Ave"
      />
      <FormikField
        type="text"
        name="address2"
        label="Address 2:"
        placeholder="e.g. 3221 Indiana Ave"
      />
      <FormikField
        type="text"
        name="city"
        label="City:"
        placeholder="e.g. New York"
      />
      <FormikField
        type="autocomplete"
        name="state"
        label="State:"
        placeholder="Select"
        options={formattedOptions.state}
        filterOption={helpers.searchInclusionsFromWordBeginning}
      />
      <FormikField type="text" name="zip" label="Zip:" placeholder="XXXXX" />
      <FormikField
        type="autocomplete"
        name="timeAtAddressYears"
        label="Years at address:"
        placeholder="Select"
        options={yearsOptions}
      />
      <FormikField
        type="autocomplete"
        name="timeAtAddressMonths"
        label="Months at address:"
        placeholder="Select"
        options={monthsOptions}
      />
    </StepContainer>
  );
};

AddressStep.propTypes = {
  onSubmit: PT.func.isRequired,
  initialValues: PT.object.isRequired,
  options: PT.any.isRequired
};

export default withStepData(AddressStep);
