import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { getDefaultFilterInitialState } from '../abandoned-applications/config';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import { getColumns } from '@components/Dashboard/IBV/StatusReport/config';
import DataTable from '@components/shared/DataTable/DataTable';
import Logger from '@utils/logger';
import DashboardFilter from '@components/Dashboard/Filter';
import Loader from '@components/shared/Loader';
import Alert from '@components/shared/Alert/Alert';
import { adminApiUrls } from '@constants/adminApiUrls';
import AdminApi from '@utils/adminApi';

const IBVTrafficReportPage = () => {
  const [initialFilters] = useState(getDefaultFilterInitialState());
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();
  const columns = getColumns();

  const fetchTableData = async () => {
    try {
      if (!history.location.search) {
        history.replace({
          search: qs.stringify(initialFilters)
        });
      } else {
        setData(null);
        const parsedParams = qs.parse(history.location.search, {
          parseBooleans: true,
          parseNumbers: true
        });
        const response = await AdminApi.get(
          adminApiUrls.dashboard.ibv.statusReport,
          {
            params: parsedParams
          }
        );
        setData(response.rows);
      }
    } catch (e) {
      Logger.error(e);
      setError(e);
    }
  };

  useEffect(() => {
    if (history.location) {
      fetchTableData();
    }
  }, [history.location?.search]);

  return (
    <MainLayout title="IBV Traffic Report">
      <DashboardFilter />
      {!data && !error && <Loader />}
      {data && (
        <DataTable
          disableColumnFilter
          columns={columns}
          tableData={data}
          rowKey="providerId"
          detailPanelHeight="auto"
          pagination={false}
          hideFooter
        />
      )}
      {error && (
        <Alert
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
        />
      )}
    </MainLayout>
  );
};

export default IBVTrafficReportPage;
