import React, { useMemo } from 'react';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import Typography from '@components/shared/Typography/Typography';
import Button from '@components/shared/Button/Button';
import helpers from '@utils/helpers';
import Permissions from '@utils/permissions';

const PermissionDenied = () => {
  const homeLink = useMemo(
    () =>
      helpers.getMainPageRedirect({
        permissions: Permissions.get()
      }),
    []
  );

  return (
    <MainLayout title="Permission denied">
      <Typography variant="h1" my={2}>
        You do not have permission to view this page
      </Typography>
      <Button variant="contained" color="primary" href={homeLink}>
        Go to homepage
      </Button>
    </MainLayout>
  );
};

export default PermissionDenied;
