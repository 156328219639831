import React from 'react';
import PT from 'prop-types';
import { ErrorMessage, Formik, Form } from 'formik';

import { exportCSVSchema } from '@components/shared/ExportTableCSV/ExportTableCSVForm/schema';
import FormError from '@components/shared/Form/Error';
import Box from '@components/shared/Box/Box';
import Button from '@components/shared/Button/Button';
import CheckList from '@components/shared/CheckList/CheckList';
import { DownloadIcon } from '@components/shared/icons/Icons';
import FormikField from '@components/shared/Form/FormikField/FormikField';

const initialValues = {
  properties: [],
  rows: ''
};

const ExportTableCSVForm = ({ fields, onSubmit }) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={exportCSVSchema}
  >
    {({ setFieldValue, isSubmitting }) => (
      <Form>
        <CheckList
          list={fields.map((item) => ({
            ...item,
            key: item.key,
            name: item.fieldName || item.field
          }))}
          title="Columns"
          setSelected={(list) => setFieldValue('properties', list)}
          listName="participants"
          icon={<DownloadIcon size={18} />}
        />
        <ErrorMessage
          render={(errorMessage) => <FormError error={errorMessage} />}
          name="properties"
        />
        <FormikField
          type="text"
          name="rows"
          label="Rows"
          hasFeedback
          showValidateSuccess
          placeholder="Number of lines"
        />
        <Box size={20} align="center">
          <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
            Save
          </Button>
        </Box>
      </Form>
    )}
  </Formik>
);

ExportTableCSVForm.propTypes = {
  fields: PT.array.isRequired,
  onSubmit: PT.func.isRequired
};

export default ExportTableCSVForm;
