import React from 'react';
import { Link } from 'react-router-dom';

import { initialFilterParams } from './config';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import routes from '@constants/routes';
import helpers from '@utils/helpers';
import {
  filterOperators,
  noFieldData,
  permissionsMap
} from '@constants/common';
import toastify from '@utils/toastify';
import Button from '@components/shared/Button/Button';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import Box from '@components/shared/Box/Box';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import { ActionsBox } from '@components/shared/MainLayout/MainLayout.components';
import { timeDateColumnWidth } from '@components/shared/DataTable/DataTable.constants';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import { leadManagementApiUrls } from '@constants/leadManagementApi';
import LeadManagementApi from '@utils/leadManagementApi';
import DeleteActionButton from '@components/shared/DataTable/actions/DeleteActionButton';

const CampaignGroupsListPage = () => {
  const handleDelete = async (record, updatePaginationAfterDelete) => {
    try {
      await LeadManagementApi.delete(
        leadManagementApiUrls.campaignGroups.page(record.campaignGroupId)
      );

      updatePaginationAfterDelete(
        [record.campaignGroupId],
        (record, arrayOfDeletableIds) =>
          !arrayOfDeletableIds.includes(record.campaignGroupId)
      );

      toastify.success({
        message: 'Group deleted'
      });
    } catch (e) {
      toastify.error(
        e?.response?.data?.error
          ? e.response.data.error
          : {
              message: 'Delete operation failure'
            }
      );
    }
  };

  const getColumns = ({ updatePaginationAfterDelete }) => [
    {
      headerName: 'Action',
      field: 'actions',
      width: 60,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <Box size={4}>
          <EditActionButton
            path={routes.leads.campaignGroups.page.edit.url(
              row.campaignGroupId
            )}
            hidden={
              !helpers.getActionPermission(permissionsMap.leadGroupsManage)
            }
          />
          <DeleteActionButton
            hidden={
              !helpers.getActionPermission(permissionsMap.leadGroupsManage)
            }
            itemId={row.campaignGroupId}
            onConfirm={() => handleDelete(row, updatePaginationAfterDelete)}
          />
        </Box>
      )
    },
    {
      headerName: 'Name',
      field: 'name',
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) =>
        helpers.getActionPermission(permissionsMap.leadGroupsManage) ? (
          <Tooltip title="Campaign page">
            <Link
              to={routes.leads.campaignGroups.page.view.url(
                row.campaignGroupId
              )}
            >
              {row.name}
            </Link>
          </Tooltip>
        ) : (
          row.name || noFieldData
        )
    },
    {
      headerName: 'Date created',
      field: 'createdDate',
      minWidth: timeDateColumnWidth,
      flex: 1,
      valueFormatter: (createdDate) => helpers.getFormattedDate(createdDate),
      renderCell: ({ row }) => helpers.getFormattedDate(row.createdDate),
      type: muiDataTableTypes.dateRange,
      filterOperators: [filterOperators.dateRange]
    },
    {
      headerName: 'Date modified',
      field: 'updatedDate',
      minWidth: timeDateColumnWidth,
      flex: 1,
      valueFormatter: (updatedDate) => helpers.getFormattedDate(updatedDate),
      renderCell: ({ row }) => helpers.getFormattedDate(row.updatedDate),
      type: muiDataTableTypes.dateRange,
      filterOperators: [filterOperators.dateRange]
    }
  ];

  return (
    <MainLayout title="Campaign Groups">
      <ActionsBox>
        {helpers.getActionPermission(permissionsMap.leadGroupsManage) && (
          <Button component={Link} to={routes.leads.campaignGroups.page.create}>
            Add new group
          </Button>
        )}
      </ActionsBox>
      <ODataPaginatedTable
        getColumns={getColumns}
        customApi={LeadManagementApi}
        usePaginatedDataOptions={{
          apiUrl: leadManagementApiUrls.campaignGroups.list,
          initialFilterParams: {
            $filter: initialFilterParams,
            $orderBy: 'createdDate desc'
          }
        }}
        rowKey="campaignGroupId"
      />
    </MainLayout>
  );
};

export default CampaignGroupsListPage;
