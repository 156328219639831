import React from 'react';
import { useHistory } from 'react-router-dom';

import FlatPageForm from '@components/FlatPage/Form';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import routes from '@constants/routes';
import toastify from '@utils/toastify';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const FlatPageCreatePage = () => {
  const history = useHistory();

  const handleSubmit = async ({ values }, { onSuccess, onError, onFinish }) => {
    try {
      const responseData = await AdminApi.post(
        adminApiUrls.flatPages.list,
        values
      );

      history.push(
        routes.flatPages.page.edit.url(responseData.contentEntityId)
      );

      toastify.success({
        message: 'Create operation success'
      });

      onSuccess && onSuccess(responseData);
    } catch (e) {
      onError && onError(e);
    } finally {
      onFinish && onFinish();
    }
  };

  return (
    <MainLayout>
      <FlatPageForm onSubmit={handleSubmit} />
    </MainLayout>
  );
};

export default FlatPageCreatePage;
