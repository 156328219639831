import { createSlice } from '@reduxjs/toolkit';

import {
  fetchApiVersion,
  fetchAppVersions,
  fetchServicesStatuses,
  fetchDeclineActions
} from './actions';

import { handleAsyncThunk } from '@store/helpers';

const initialState = {
  apiVersion: {
    data: null,
    isLoading: false,
    error: null
  },
  appVersions: {
    data: null,
    isLoading: false,
    error: null
  },
  servicesStatuses: {
    data: null,
    isLoading: false,
    error: null
  },
  declineActions: {
    data: null,
    isLoading: false,
    error: null
  }
};

export const selectApiVersion = ({ settings }) => settings.apiVersion.data;
export const selectAppVersions = ({ settings }) => settings.appVersions.data;
export const selectServicesStatuses = ({ settings }) =>
  settings.servicesStatuses;
export const selectAreServicesStatusesLoading = ({ settings }) =>
  settings.servicesStatuses.isLoading;
export const selectDeclineActions = ({ settings }) =>
  settings.declineActions.data;

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleAsyncThunk(builder, fetchApiVersion, 'apiVersion');
    handleAsyncThunk(builder, fetchAppVersions, 'appVersions');
    handleAsyncThunk(builder, fetchServicesStatuses, 'servicesStatuses');
    handleAsyncThunk(builder, fetchDeclineActions, 'declineActions');
  }
});

export default settingsSlice.reducer;
