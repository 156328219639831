import React from 'react';
import PT from 'prop-types';
import MuiAlert from '@mui/material/Alert';
import MuiAlertTitle from '@mui/material/AlertTitle';

const Alert = ({ type, title, message, ...props }) => (
  <MuiAlert severity={type} sx={{ marginTop: 1, marginBottom: 1 }} {...props}>
    {title && <MuiAlertTitle>{title}</MuiAlertTitle>}
    {message}
  </MuiAlert>
);

Alert.defaultProps = {
  type: 'info'
};

Alert.propTypes = {
  type: PT.oneOf(['success', 'info', 'warning', 'error']),
  title: PT.string,
  message: PT.string.isRequired
};

export default Alert;
