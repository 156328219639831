import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { Header } from '../Header/Header';

import { StyledMain, StyledMainLayout } from './styled';

export const WallboardLayout = ({ title, children }) => (
  <>
    <Helmet>
      <title>{[title, 'Wallboard'].filter(Boolean).join(' | ')}</title>
    </Helmet>
    <StyledMainLayout>
      <Header title={title} />
      <StyledMain>{children}</StyledMain>
    </StyledMainLayout>
  </>
);

WallboardLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
