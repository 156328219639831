import * as Yup from 'yup';
import dayjs from 'dayjs';

import { compareTime } from './utils';

import { shortTimeFormat } from '@constants/common';
import helpers from '@utils/helpers';

const daySchema = Yup.object().shape({
  active: Yup.bool().required(),
  dayOfWeek: Yup.string(),
  startWork: Yup.string().when('active', (active, schema) =>
    active
      ? schema
          .required('Start work field is required')
          .test(
            'is-lower',
            'start working time should be lower than stop working time',
            function (startWork) {
              return compareTime(startWork, this.parent.stopWork);
            }
          )
      : schema.nullable()
  ),
  stopWork: Yup.string().when('active', (active, schema) =>
    active
      ? schema
          .required('Stop work field is required')
          .test(
            'is-greater',
            'stop working time should be greater than start working time',
            function (stopWork) {
              return compareTime(this.parent.startWork, stopWork);
            }
          )
      : schema.nullable()
  )
});

export const workingCalendarSchema = Yup.object().shape({
  workDays: Yup.array().of(daySchema)
});

export const daysOfWeekOptions = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const workingCalendarServerData = {
  to: (data, isOdata = false) =>
    data
      .filter((item) => item.active)
      .map((item) => ({
        dayOfWeek: isOdata
          ? item.dayOfWeek
          : daysOfWeekOptions.indexOf(item.dayOfWeek),
        startWork: helpers.formatToServerTime(item.startWork),
        stopWork: helpers.formatToServerTime(item.stopWork)
      })),
  from: (data) => {
    const defaultCase = (index) => ({
      active: false,
      dayOfWeek: daysOfWeekOptions[index],
      startWork: dayjs().startOf('d').format(shortTimeFormat.server),
      stopWork: dayjs().endOf('d').format(shortTimeFormat.server)
    });
    return daysOfWeekOptions.map((item, index) => {
      const elemExist = data.find((elem) => elem.dayOfWeek === item);
      if (elemExist != null) {
        return {
          active: true,
          dayOfWeek: elemExist.dayOfWeek,
          startWork: helpers.formatFromServerTime(elemExist.startWork),
          stopWork: helpers.formatFromServerTime(elemExist.stopWork)
        };
      }
      return defaultCase(index);
    });
  }
};
