import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import routes from '@constants/routes';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import MobileAppVersionsForm from '@components/MobileAppVersions/Form';
import Logger from '@utils/logger';
import toastify from '@utils/toastify';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const MobileAppVersionsCreate = () => {
  const params = useParams();
  const history = useHistory();
  const [appVersionToEdit, setAppVersionToEdit] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const { value: appVersions } = await AdminApi.get(
          adminApiUrls.mobileAppVersions.list
        );
        const appVersion = appVersions.find(
          ({ applicationVersionId }) => +applicationVersionId === +params.id
        );
        setAppVersionToEdit(appVersion);
      } catch (e) {
        Logger.error('error occurred fetching app version', e);
      }
    })();
  }, [params.id]);

  const handleSubmit = async (data) => {
    try {
      await AdminApi.put(
        adminApiUrls.mobileAppVersions.page(data.applicationVersionId),
        {
          ...data,
          applicationVersionId: appVersionToEdit.applicationVersionId
        }
      );
      history.push(routes.osAndAppVersions.mobileAppVersions.list);
    } catch (e) {
      Logger.error(e);
      toastify.error({
        message: 'Edit operation failure'
      });
    }
  };

  return (
    <MainLayout title="Edit App Version" isLoading={!appVersionToEdit}>
      <MobileAppVersionsForm
        editMode
        data={appVersionToEdit}
        handleSubmit={handleSubmit}
      />
    </MainLayout>
  );
};

export default MobileAppVersionsCreate;
