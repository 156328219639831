import { dateRange, showAllValue } from '../config';

import { datePeriods } from '@constants/datePeriods';

export const leadPerformanceBaseInitialValues = {
  Date: dateRange,
  LeadCampaignId: showAllValue,
  State: showAllValue
};

export const stackPerformanceBaseInitialValues = {
  ...leadPerformanceBaseInitialValues,
  DatePeriod: datePeriods.Day,
  StackName: showAllValue,
  PromoCode: showAllValue,
  LeadCostFrom: '',
  LeadCostTo: '',
  LoanType: showAllValue
};

export const datePeriodOptions = [
  {
    label: 'Day',
    value: datePeriods.Day
  },
  {
    label: 'Week',
    value: datePeriods.Week
  },
  {
    label: 'Month',
    value: datePeriods.Month
  },
  {
    label: 'Year',
    value: datePeriods.Year
  }
];

export const loanTypesOptions = [
  { label: 'Installment', value: 'Installment' },
  { label: 'Installment Auto', value: 'InstallmentAuto' },
  { label: 'Draw', value: 'Draw' },
  { label: 'Credit Limit', value: 'CreditLimit' }
];
