import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

import MainLayout from '@components/shared/MainLayout/MainLayout';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import filterHelpers from '@utils/filterHelpers';
import {
  dateFormat,
  dateShortTimeFormat,
  permissionsMap,
  shortTimeFormat,
  timeFormat
} from '@constants/common';
import { fieldWidth } from '@constants/filters';
import Button from '@components/shared/Button/Button';
import Logger from '@utils/logger';
import toastify from '@utils/toastify';
import AdminApi from '@utils/adminApi';
import { palette } from '@utils/theme';
import { adminApiUrls } from '@constants/adminApiUrls';
import helpers from '@utils/helpers';

const submitFormError = {
  message: 'Error',
  description: 'Failed to submit form'
};

const MaintenanceMode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [maintenanceModeData, setMaintenanceModeData] = useState(null);

  const getMaintenanceModeData = async () => {
    try {
      setIsLoading(true);
      const response = await AdminApi.get(
        adminApiUrls.maintenanceMode.getMaintenanceMode
      );
      response && setMaintenanceModeData(response);
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMaintenanceModeData();
  }, []);

  const handleSet = async (values) => {
    const hours = values.duration.hour();
    const minutes = values.duration.minute();

    const hoursInMilliseconds = hours * 60 * 60 * 1000;
    const minutesInMilliseconds = minutes * 60 * 1000;

    const totalMilliseconds = hoursInMilliseconds + minutesInMilliseconds;
    const formattedTime = dayjs(values.time).format(timeFormat);
    const startDate = dayjs(`${values.startDate} ${formattedTime}`).set(
      'second',
      0
    );

    const endDate = startDate
      .add(dayjs(values.duration).hour(), 'hour')
      .add(dayjs(values.duration).minute(), 'minute');

    const payload = {
      enable: true,
      startDate,
      endDate,
      duration: totalMilliseconds
    };

    try {
      await AdminApi.post(
        adminApiUrls.maintenanceMode.setMaintenanceMode,
        null,
        { params: payload }
      );
      getMaintenanceModeData();
      toastify.success({
        message: 'Success',
        description: 'Maintenance Mode set'
      });
    } catch (error) {
      Logger.error(error);
      toastify.error(submitFormError);
    }
  };

  const handleDisable = async () => {
    try {
      await AdminApi.post(adminApiUrls.maintenanceMode.setMaintenanceMode, {
        enabled: false
      });
      getMaintenanceModeData();
      toastify.success({
        message: 'Success',
        description: 'Maintenance Mode disabled'
      });
    } catch (error) {
      Logger.error(error);
      toastify.error(submitFormError);
    }
  };

  const initialValues = {
    startDate: dayjs().format(dateFormat),
    time: dayjs(),
    duration: dayjs().hour(0).minute(30)
  };

  const updatedInitialValues = {
    startDate: maintenanceModeData?.startDate,
    time: dayjs(maintenanceModeData?.startDate),
    duration: helpers.getDurationBetweenTwoDates(
      maintenanceModeData?.startDate,
      maintenanceModeData?.endDate
    )
  };

  return (
    <MainLayout title="Maintenance Mode" isLoading={isLoading}>
      <Box my={1}>
        <h2
          style={{
            color: maintenanceModeData?.enabled
              ? palette.error
              : palette.primary
          }}
        >
          Maintenance mode is{' '}
          {maintenanceModeData?.enabled ? 'enabled' : 'disabled'}
        </h2>
        {maintenanceModeData?.enabled && (
          <h3>
            Disabling will occur{' '}
            {dayjs(maintenanceModeData?.endDate).format(dateShortTimeFormat)}
          </h3>
        )}

        <Formik
          initialValues={
            maintenanceModeData?.enabled ? updatedInitialValues : initialValues
          }
          onSubmit={handleSet}
          enableReinitialize
        >
          <Form className="page-filter">
            <Box sx={filterHelpers.getFlexForField(10)}>
              <FormikField
                type="date"
                name="startDate"
                label="Start date"
                size="small"
                format={dateFormat}
                sx={fieldWidth}
                disablePast
                disabled={maintenanceModeData?.enabled}
              />
              <FormikField
                type="time"
                name="time"
                label="Start time"
                format={shortTimeFormat.front}
                disabled={maintenanceModeData?.enabled}
              />
              <FormikField
                type="time"
                name="duration"
                label="Duration"
                format={shortTimeFormat.server}
                timeSteps={{ minutes: 5 }}
                ampm={false}
                disabled={maintenanceModeData?.enabled}
              />

              <Box sx={filterHelpers.getFlexForField(10)}>
                <Button
                  type="submit"
                  disabled={
                    maintenanceModeData?.enabled ||
                    !helpers.getActionPermission(
                      permissionsMap.maintananceModeManage
                    )
                  }
                >
                  Set Maintenance Mode
                </Button>
                <Button
                  onClick={handleDisable}
                  disabled={
                    !maintenanceModeData?.enabled ||
                    !helpers.getActionPermission(
                      permissionsMap.maintananceModeManage
                    )
                  }
                  variant="outlined"
                >
                  Disable Maintenance Mode
                </Button>
              </Box>
            </Box>
          </Form>
        </Formik>
      </Box>
    </MainLayout>
  );
};

export default MaintenanceMode;
