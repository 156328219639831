import React from 'react';
import PT from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import useApplicationDetailsConfig from './useApplicationDetailsConfig';

import { dateTimeFormat, permissionsMap } from '@constants/common';
import routes from '@constants/routes';
import SubmitApplicationButton from '@components/Application/ApplicationDetails/SubmitApplicationButton';
import helpers from '@utils/helpers';
import './ApplicationDetails.scss';
import TabsPanel from '@components/shared/TabsPanel/TabsPanel';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';
import ListContentBlock from '@components/shared/ListContentBlock/ListContentBlock';
import DownloadButton from '@components/shared/DownloadButton/DownloadButton';
import Typography from '@components/shared/Typography/Typography';
import { adminApiUrls } from '@constants/adminApiUrls';

const ApplicationDetails = ({ data, fetchData }) => {
  const { tabContentList, appResultsInfoList, appGeneralInfoList } =
    useApplicationDetailsConfig(data, data.applicationId);

  const fileName = `Application - ${data.applicationId} - ${
    data.customerInfo.firstName
  } ${data.customerInfo.lastName} - ${dayjs(data.createdDate).format(
    dateTimeFormat
  )}.pdf`;

  return (
    <>
      <Stack
        mt={1}
        gap={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Typography variant="h3">Application results</Typography>
        {helpers.getActionPermission(permissionsMap.applicationManage) && (
          <Stack direction="row" flexWrap="wrap" gap={1}>
            <Button
              component={Link}
              to={routes.applications.page.edit.url(data.applicationUid)}
            >
              Edit application
            </Button>
            <DownloadButton
              fileName={fileName}
              endpoint={adminApiUrls.applicationFile.getURL(
                data.applicationUid
              )}
            />
            <SubmitApplicationButton application={data} onSuccess={fetchData} />
          </Stack>
        )}
      </Stack>
      {data && (
        <>
          <ListContentBlock
            contentList={appResultsInfoList}
            wrapperProps={{ mb: 2 }}
          />
          <ListContentBlock
            title="Application general info"
            contentList={appGeneralInfoList}
            wrapperProps={{ mb: 2 }}
            separateToColumns={2}
          />

          <TabsPanel tabContentList={tabContentList} />
        </>
      )}
    </>
  );
};

ApplicationDetails.propTypes = {
  data: PT.object,
  fetchData: PT.func
};

export default ApplicationDetails;
