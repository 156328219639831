import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import isNil from 'lodash/isNil';

import { DEFAULT_ERROR_MESSAGE, permissionsMap } from '@constants/common';
import Logger from '@utils/logger';
import routes from '@constants/routes';
import Loader from '@components/shared/Loader';
import helpers from '@utils/helpers';
import { cleanObject } from '@utils/object-cleaner';
import NotificationsHistory from '@components/NotificationsHistory';
import {
  jobTypes,
  targetMap,
  targetRenderConfig
} from '@components/CustomNotifications/Form/TargetStep/schema';
import {
  fileInfoKeyHelper,
  fileInfoValueHelper
} from '@components/CustomNotifications/Details/config';
import TestNotificationButton from '@components/shared/TestNotificationButton';
import toastify from '@utils/toastify';
import Alert from '@components/shared/Alert/Alert';
import Typography from '@components/shared/Typography/Typography';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';
import Dialog from '@components/shared/Modal/Dialog';
import Accordion from '@components/shared/Accordion/Accordion';
import { FileDownloadIcon } from '@components/shared/icons/Icons';
import ListContentBlock from '@components/shared/ListContentBlock/ListContentBlock';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const CustomNotificationsDetails = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const initialExtraParams = cleanObject({
    $orderBy: 'createdDate desc',
    $filter: {
      notificationJobId: {
        eq: data?.notificationJobId
      }
    }
  });
  const loading = !data && !error;

  const fetchData = useCallback(async () => {
    try {
      const [notificationJob, states] = await Promise.all([
        AdminApi.get(adminApiUrls.notificationJob.page(params.id), {
          params: {
            $expand: 'NotificationJobHistories($orderby=createdDate desc)'
          }
        }),
        AdminApi.get(adminApiUrls.apply.loanForm.userInfo.options)
      ]);

      setData(notificationJob);

      const filterData = notificationJob.filter
        ? JSON.parse(notificationJob.filter)
        : {};

      if (filterData?.JobTypeValues) {
        const jobTypeValues = filterData.JobTypeValues.map(
          ({ JobType, JobTypeValue }) => ({
            JobType,
            JobTypeValue:
              JobType !== jobTypes[targetMap.state]
                ? JobTypeValue
                : states.state.find((item) => item.shortName === JobTypeValue)
                    ?.displayName
          })
        );

        setFilter(jobTypeValues);
      }

      const bulkUploadModelId = 'BulkUploadModelId';

      if (bulkUploadModelId in filterData) {
        setFileInfo(filterData);
      }
    } catch (e) {
      Logger.error(e);
      setError(true);
    }
  }, [params.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleResend = async () => {
    try {
      await AdminApi.patch(adminApiUrls.notificationJob.page(params.id), {
        isActive: true
      });

      toastify.success({
        message: 'Notification will resend shortly'
      });
    } catch (e) {
      toastify.error({
        message: 'Operation failure'
      });
    }
  };

  if (loading) return <Loader />;
  if (error) return <Alert type="error" message={DEFAULT_ERROR_MESSAGE} />;

  const handleDownload = async (event) => {
    event.stopPropagation();
    try {
      const { url } = await AdminApi.get(
        adminApiUrls.notificationBulk.download(data.notificationJobId)
      );
      window.location = url;
    } catch (e) {
      toastify.error(
        e
          ? e
          : {
              message: 'Download operation failure'
            }
      );
    }
  };

  const fileInfoList =
    (fileInfo &&
      Object.entries(fileInfo).map(([key, value]) => ({
        title: fileInfoKeyHelper(key),
        content: fileInfoValueHelper(key, value)
      }))) ||
    [];

  const filterInfoList = filter?.map(({ JobType, JobTypeValue }) => ({
    title: targetRenderConfig[JobType].title || JobType,
    content: targetRenderConfig[JobType].render
      ? targetRenderConfig[JobType].render(JobTypeValue)
      : JobTypeValue
  }));

  const accordionListFilter = [
    {
      key: '0',
      header: 'Target group of users',
      content: <ListContentBlock contentList={filterInfoList} />
    }
  ];

  const accordionListFileInfo = [
    {
      key: '1',
      header: (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <span>Target group file details</span>
          <span>
            <FileDownloadIcon onClick={(event) => handleDownload(event)} />
          </span>
        </Stack>
      ),
      content: <ListContentBlock contentList={fileInfoList} />
    }
  ];

  const accordionListHistory = [
    {
      key: '2',
      header: 'Notification History',
      content: <NotificationsHistory initialExtraParams={initialExtraParams} />
    }
  ];

  const notificationInfoList = [
    {
      title: 'Job title:',
      content: data.jobTitle
    },
    {
      title: 'Message title:',
      content: data.messageTitle
    },
    {
      title: 'Message template:',
      content: data.messageTemplate
    }
  ];

  return (
    <>
      <Stack
        my={2}
        direction="row"
        flexWrap="wrap"
        gap={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3">Notification Details</Typography>
        <Stack direction="row" gap={1}>
          {helpers.getActionPermission(
            permissionsMap.notificationJobManage
          ) && (
            <>
              <Button
                component={Link}
                to={routes.notifications.customNotifications.page.edit.url(
                  data.notificationJobId
                )}
              >
                Edit Notification
              </Button>
              <Button onClick={() => setShowModal(true)} variant="outlined">
                Resend Notification
              </Button>
            </>
          )}
          <TestNotificationButton
            isCustom={true}
            notificationId={data.notificationJobId}
          />
        </Stack>
      </Stack>
      {!loading && !isNil(data) && (
        <>
          <ListContentBlock
            contentList={notificationInfoList}
            wrapperProps={{ mb: 2 }}
          />
          {!!filter?.length && (
            <Accordion accordionList={accordionListFilter} />
          )}
          {fileInfo && <Accordion accordionList={accordionListFileInfo} />}
        </>
      )}
      <Accordion accordionList={accordionListHistory} />

      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={() => {
          handleResend();
          setShowModal(false);
        }}
        title="Confirm resend?"
      />
    </>
  );
};

export default CustomNotificationsDetails;
