import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

export const StyledTooltip = styled(Tooltip)(() => ({
  '&.MuiTooltip--tooltip': {
    minWidth: 900
  }
}));

export const StyledContentDiv = styled('div')(({ width }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: width,
  whiteSpace: 'nowrap'
}));
