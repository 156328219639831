import React from 'react';
import PT from 'prop-types';

import { StyledMuiDivider } from './styled';

import { palette } from '@utils/theme';

const Divider = ({ color, ...props }) => (
  <StyledMuiDivider color={color} {...props} />
);

Divider.defaultProps = {
  color: palette.buttonGrey
};

Divider.propTypes = {
  color: PT.string
};

export default Divider;
