import React from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import startCase from 'lodash/startCase';
import { Link } from 'react-router-dom';

import routes from '@constants/routes';
import { cleanObject } from '@utils/object-cleaner';
import helpers from '@utils/helpers';
import { filterOperators, noFieldData } from '@constants/common';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import { formatValueWithCurrency } from '@components/shared/DataTable/DataTable.utils';
import { timeDateColumnWidth } from '@components/shared/DataTable/DataTable.constants';
import NotYetExecutedMessage from '@components/shared/NotYetExecutedMessage/NotYetExecutedMessage';

dayjs.extend(duration);

export const initialFilterParams = cleanObject({});

const paymentStatusesOptions = [
  { label: 'Planned', value: 'Planned' },
  { label: 'Skipped', value: 'Skipped' },
  { label: 'Success', value: 'Success' },
  { label: 'Failed', value: 'Failed' },
  { label: 'Error', value: 'Error' },
  { label: 'Processed', value: 'Processed' },
  { label: 'Not Applied', value: 'NotApplied' }
];

const summaryStatusesOptions = [
  { label: 'Planned', value: 'Planned' },
  { label: 'Ongoing', value: 'Ongoing' },
  { label: 'All Finished', value: 'AllFinished' },
  { label: 'Partially Finished', value: 'PartiallyFinished' }
];

export const getColumns = ({ declineReasonsOptions }) => [
  {
    headerName: 'Scheduled date',
    field: 'paymentDate',
    minWidth: timeDateColumnWidth,
    type: muiDataTableTypes.dateRange,
    valueFormatter: (date) => helpers.getFormattedDate(date),
    renderCell: ({ row, field }) =>
      helpers.getFormattedDate(row[field]) || noFieldData,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Planned date',
    field: 'plannedDate',
    minWidth: timeDateColumnWidth,
    type: muiDataTableTypes.dateRange,
    valueFormatter: (date) => helpers.getFormattedDate(date),
    renderCell: ({ row, field }) =>
      helpers.getFormattedDate(row[field]) || noFieldData,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Executed date',
    field: 'executedDate',
    minWidth: timeDateColumnWidth,
    type: muiDataTableTypes.dateRange,
    valueFormatter: (date) => helpers.getFormattedDate(date),
    renderCell: ({ row, field }) =>
      helpers.getFormattedDate(row[field]) || noFieldData,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Runtime',
    field: 'runtimeMilliseconds',
    width: 95,
    valueFormatter: (date) => helpers.getFormattedDate(date),
    renderCell: ({ row, field }) =>
      row[field] ? (
        helpers.formatMilliseconds(row[field])
      ) : (
        <NotYetExecutedMessage />
      ),
    filterable: false
  },
  {
    headerName: 'Loan ID',
    field: 'loanId',
    width: 80,
    type: muiDataTableTypes.number,
    renderCell: ({ row, field }) => row[field] || noFieldData
  },
  {
    headerName: 'Customer ID',
    field: 'customerId',
    minWidth: 90,
    type: muiDataTableTypes.number,
    renderCell: ({ row, field }) => row[field] || noFieldData
  },
  {
    headerName: 'Provider',
    field: 'paymentProviderName',
    minWidth: 100,
    renderCell: ({ row, field }) => row[field] || noFieldData
  },
  {
    headerName: 'Amount',
    field: 'amount',
    width: 70,
    type: muiDataTableTypes.number,
    valueFormatter: formatValueWithCurrency
  },
  {
    headerName: 'Status',
    field: 'paymentStatus',
    width: 80,
    type: muiDataTableTypes.singleSelect,
    valueOptions: paymentStatusesOptions
  },
  {
    headerName: 'Decline reason',
    field: 'declineReasonId',
    minWidth: 140,
    renderCell: ({ row }) => (
      <Tooltip title={row.declineReasonName} cutContentByWidth="auto">
        {row.declineReasonName || noFieldData}
      </Tooltip>
    ),
    type: muiDataTableTypes.singleSelect,
    valueOptions: declineReasonsOptions
  },
  {
    headerName: 'Error message',
    field: 'providerErrorMessage',
    minWidth: 150,
    renderCell: ({ row, field }) => (
      <Tooltip title={row[field]} cutContentByWidth="auto">
        {row[field] || noFieldData}
      </Tooltip>
    )
  },
  {
    headerName: 'Note',
    field: 'note',
    minWidth: 150,
    renderCell: ({ row, field }) => (
      <Tooltip title={row[field]}>{row[field] || noFieldData}</Tooltip>
    )
  },
  {
    headerName: 'Proc reference ID',
    field: 'fireInstanceId',
    minWidth: 170,
    renderCell: ({ row, field }) => row[field] || noFieldData
  },
  {
    headerName: 'LMS Reference ID',
    field: 'referenceNumber',
    width: 140,
    renderCell: ({ row }) => row.referenceNumber || noFieldData
  },
  {
    headerName: 'Transaction key',
    field: 'transactionKey',
    width: 125,
    renderCell: ({ row }) => row.transactionKey || noFieldData
  },
  {
    headerName: 'Bin number',
    field: 'binNumber',
    minWidth: 110,
    renderCell: ({ row, field }) => row[field] || noFieldData
  },
  {
    headerName: 'Card number',
    field: 'cardNumber',
    width: 100,
    type: muiDataTableTypes.number,
    renderCell: ({ row }) => helpers.getCardMask(null, row.ccNumberLast4Digits)
  }
];

export const tabsIds = {
  performance: 'scheduled-performance',
  list: 'scheduled-list',
  settings: 'scheduled-settings'
};

export const tabsList = [
  {
    id: tabsIds.performance,
    title: 'Performance Summary',
    link: routes.payments.scheduledPayments.performance
  },
  {
    id: tabsIds.list,
    title: 'Payments List',
    link: routes.payments.scheduledPayments.list
  },
  {
    id: tabsIds.settings,
    title: 'Settings',
    link: routes.payments.scheduledPayments.settings
  }
];

export const getSummaryColumns = () => [
  {
    headerName: 'Planned',
    field: 'plannedDate',
    width: 170,
    type: muiDataTableTypes.dateRange,
    renderCell: ({ row }) => (
      <Link
        to={{
          pathname: routes.payments.scheduledPayments.list,
          state: {
            scheduledPaymentsPerformanceId: row.scheduledPaymentsPerformanceId
          }
        }}
      >
        {helpers.getFormattedDate(row.plannedDate) || noFieldData}
      </Link>
    ),
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Executed',
    field: 'executedDate',
    minWidth: timeDateColumnWidth,
    type: muiDataTableTypes.dateRange,
    valueFormatter: (date) => helpers.getFormattedDate(date),
    renderCell: ({ row, field }) =>
      helpers.getFormattedDate(row[field]) || <NotYetExecutedMessage />,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Runtime',
    field: 'runtimeMilliseconds',
    width: 95,
    valueFormatter: (date) => helpers.getFormattedDate(date),
    renderCell: ({ row, field }) =>
      row[field] ? (
        helpers.formatMilliseconds(row[field])
      ) : (
        <NotYetExecutedMessage />
      ),
    filterable: false
  },
  {
    headerName: '# of payments',
    field: 'numberOfPayments',
    width: 120,
    type: muiDataTableTypes.number
  },
  {
    headerName: '# of successful payments',
    field: 'numberOfSuccessfulPayments',
    width: 200,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Status',
    field: 'status',
    renderCell: ({ row, field }) => startCase(row[field]),
    type: muiDataTableTypes.singleSelect,
    valueOptions: summaryStatusesOptions
  }
];
