import React from 'react';

import FileName from './FileName';

import helpers from '@utils/helpers';
import { timeDateColumnWidth } from '@components/shared/DataTable/DataTable.constants';
import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';
import Box from '@components/shared/Box/Box';
import routes from '@constants/routes';
import CsvComparisonApi from '@utils/csvComparisonApi';
import toastify from '@utils/toastify';
import Logger from '@utils/logger';
import { csvComparisonApiUrls } from '@constants/csvComparisonApi';
import { permissionsMap } from '@constants/permissions';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import DeleteActionButton from '@components/shared/DataTable/actions/DeleteActionButton';

const handleDelete = async (id, updatePaginationAfterDelete) => {
  try {
    await CsvComparisonApi.delete(csvComparisonApiUrls.comparisons.details(id));

    updatePaginationAfterDelete(
      [id],
      (row, arrayOfDeletableIds) => !arrayOfDeletableIds.includes(row.id)
    );

    toastify.success({
      message: 'Compare was deleted'
    });
  } catch (e) {
    Logger.error(e);
    toastify.error(
      e?.response?.data?.error || {
        message: 'An error occurred deleting compare'
      }
    );
  }
};

const compareStatusesOptions = [
  {
    label: 'In Progress',
    value: 'InProgress'
  },
  {
    label: 'Completed',
    value: 'Completed'
  },
  {
    label: 'Failed',
    value: 'Failed'
  }
];

export const getColumns = ({ updatePaginationAfterDelete }) => [
  {
    headerName: 'Date',
    field: 'dateCreated',
    minWidth: timeDateColumnWidth,
    renderCell: ({ row }) => helpers.getFormattedDate(row.dateCreated)
  },
  {
    headerName: 'File 1 name',
    field: 'comparisonFiles[0].fileName',
    minWidth: 120,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      const fileDetails = row.comparisonFiles?.[0];
      return <FileName text={fileDetails?.fileName} id={fileDetails?.id} />;
    }
  },
  {
    headerName: 'File 2 name',
    field: 'comparisonFiles[1].fileName',
    minWidth: 120,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      const fileDetails = row.comparisonFiles?.[1];
      return <FileName text={fileDetails?.fileName} id={fileDetails?.id} />;
    }
  },
  {
    headerName: '# of records',
    field: 'records_count',
    minWidth: 180,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) =>
      `${row.comparisonFiles?.[0]?.fileRows}/${row.comparisonFiles?.[1]?.fileRows}`
  },
  {
    headerName: 'Missing in 1',
    field: 'comparisonFiles[1].fileRowsMismatched',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => row.comparisonFiles?.[1]?.fileRowsMismatched
  },
  {
    headerName: 'Missing in 2',
    field: 'comparisonFiles[2].fileRowsMismatched',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => row.comparisonFiles?.[0]?.fileRowsMismatched
  },
  {
    headerName: 'Run time',
    field: 'runtimeMilliseconds',
    renderCell: ({ row }) => helpers.formatMilliseconds(row.runtimeMilliseconds)
  },
  {
    headerName: 'Status',
    field: 'status',
    type: muiDataTableTypes.singleSelect,
    valueOptions: compareStatusesOptions
  },
  {
    headerName: 'User',
    field: 'usernameCreated'
  },

  {
    headerName: 'Actions',
    field: 'actions',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <ShowDetailsActionButton
          hidden={!helpers.getActionPermission(permissionsMap.CSVCompareManage)}
          path={routes.csvCompare.details.url(
            row.id,
            row?.comparisonFiles?.[1]?.configName
          )}
        />

        <DeleteActionButton
          hidden={!helpers.getActionPermission(permissionsMap.CSVCompareManage)}
          itemId={row.id}
          onConfirm={() => handleDelete(row.id, updatePaginationAfterDelete)}
        />
      </Box>
    )
  }
];
