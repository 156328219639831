import React, { memo } from 'react';
import PT from 'prop-types';
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-premium';

import { ResetFiltersIcon } from '@components/shared/icons/Icons';
import Button from '@components/shared/Button/Button';
import ExportTableCSV, { exportTypes } from '@components/shared/ExportTableCSV';

const CustomToolbar = ({
  hideFilters,
  filtersProps,
  exportCsvProps,
  additionalContentInToolbar
}) => (
  <GridToolbarContainer>
    {!hideFilters && (
      <>
        <GridToolbarFilterButton
          sx={{ textTransform: 'capitalize', fontSize: '14px' }}
        />
        <Button
          variant="text"
          onClick={filtersProps?.resetHandle}
          disabled={filtersProps?.disableReset}
          sx={{ textTransform: 'uppercase', mr: 'auto' }}
          startIcon={<ResetFiltersIcon size={24} />}
        >
          Reset All
        </Button>
      </>
    )}

    {!exportCsvProps?.hide ? (
      exportCsvProps?.fromServer ? (
        <ExportTableCSV
          exportFileName={exportCsvProps?.fileName}
          apiUrl={exportCsvProps?.apiUrl}
          customApiBaseUrl={exportCsvProps?.customApiBaseUrl}
          fields={exportCsvProps?.fields}
          type={exportCsvProps?.type || exportTypes.odata}
          buttonProps={{
            sx: { ml: 'auto' }
          }}
          {...exportCsvProps}
        />
      ) : (
        exportCsvProps && <GridToolbarExport {...exportCsvProps} />
      )
    ) : null}

    {additionalContentInToolbar?.()}
  </GridToolbarContainer>
);
CustomToolbar.propTypes = {
  hideFilters: PT.bool,
  exportCsvProps: PT.shape({
    fileName: PT.string,
    apiUrl: PT.string,
    customApiBaseUrl: PT.string,
    fields: PT.array,
    fromServer: PT.bool,
    type: PT.string,
    hide: PT.bool
  }),
  filtersProps: PT.object,
  additionalContentInToolbar: PT.func
};

export default memo(
  CustomToolbar,
  (prevProps, nextProps) => prevProps.filtersProps === nextProps.filtersProps
);
