import React from 'react';

import ManageGlobalRatesButton from '../ManageGlobalRatesButton';

import Box from '@components/shared/Box/Box';
import Typography from '@components/shared/Typography/Typography';
import ConfirmActionSwitch from '@components/shared/DataTable/actions/ConfirmActionSwitch';
import { commonLeadProviderStatsColumns } from '@constants/leads';

export const getColumns = ({ handleStatus, setAllData }) => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 65,
    sortable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <ManageGlobalRatesButton globalRates={row} onSuccess={setAllData} />

        <ConfirmActionSwitch
          size="small"
          checked={row.status}
          modalProps={{
            itemId: row.leadProviderId,
            content: row.status
              ? 'Do you want to pause?'
              : 'Do you want to resume?',
            onConfirm: () =>
              row.status
                ? handleStatus(row.leadProviderId, true)
                : handleStatus(row.leadProviderId, false)
          }}
        />
      </Box>
    )
  },
  {
    headerName: 'Name',
    field: 'name',
    width: 195,
    renderCell: () => <Typography variant="h5">Total statistic</Typography>
  },
  ...commonLeadProviderStatsColumns
];
