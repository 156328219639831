import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';

import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';
import { permissionsMap } from '@constants/permissions';
import routes from '@constants/routes';
import helpers from '@utils/helpers';
import {
  emailColumnWidth,
  eventsColumnWidth,
  timeDateColumnWidth
} from '@components/shared/DataTable/DataTable.constants';
import UserEventsLink from '@components/shared/UserEventsLink';
import { filterOperators, noFieldData } from '@constants/common';
import { muiDataTableTypes } from '@constants/tableFilterConfig';

export const columns = [
  {
    headerName: 'Action',
    field: 'actions',
    sortable: false,
    filterable: false,
    width: 60,
    hide: !helpers.getActionPermission(permissionsMap.applicationView),
    renderCell: ({ row }) => (
      <Box size={4}>
        <EditActionButton
          path={routes.applications.page.edit.url(
            row.application.applicationUID
          )}
          hidden={!helpers.getActionPermission(permissionsMap.applicationView)}
        />
        <ShowDetailsActionButton
          path={routes.applications.page.view.url(
            row.application.applicationId
          )}
          hidden={
            !(
              row.application.customer &&
              helpers.getActionPermission(permissionsMap.applicationView)
            )
          }
        />
      </Box>
    )
  },
  {
    headerName: 'Application id',
    field: 'applicationId',
    filterable: false,
    minWidth: 120,
    flex: 1,
    renderCell: ({ row }) =>
      helpers.getActionPermission(permissionsMap.applicationView) &&
      row.applicationId ? (
        <Link to={routes.applications.page.view.url(row.applicationId)}>
          {row.applicationId}
        </Link>
      ) : (
        row.applicationId || '-'
      )
  },
  {
    headerName: 'User email',
    field: 'application/customer/email',
    exportField: 'application.customer.email',
    minWidth: emailColumnWidth,
    flex: 1,
    type: muiDataTableTypes.string,
    renderCell: ({ row }) => row.application.customer?.email,
    filterOperators: filterOperators.getMuiOperator('contains')
  },
  {
    headerName: 'Full name',
    exportField: 'application.customer.firstName',
    field: 'application/customer/firstName',
    filterable: false,
    renderCell: ({ row }) => {
      const displayName = helpers.getDisplayName(
        row.application.customer?.firstName,
        row.application.customer?.lastName
      );
      return helpers.getActionPermission(permissionsMap.usersView) &&
        row.application.userId ? (
        <Tooltip title="User page">
          <Link to={routes.users.page.view.url(row.application.userId)}>
            {displayName}
          </Link>
        </Tooltip>
      ) : (
        displayName
      );
    }
  },
  {
    headerName: 'Created date',
    exportField: 'application.createdDate',
    field: 'CreatedDate',
    minWidth: timeDateColumnWidth,
    flex: 1,
    renderCell: ({ row }) =>
      helpers.getFormattedDate(row.application.createdDate),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Start date',
    field: 'startDate',
    type: muiDataTableTypes.date,
    valueFormatter: (startDate) => helpers.getFormattedDate(startDate),
    hide: true,
    filterOperators: filterOperators.getMuiOperator('is')
  },
  {
    headerName: 'End date',
    field: 'endDate',
    type: muiDataTableTypes.date,
    valueFormatter: (endDate) => helpers.getFormattedDate(endDate),
    hide: true,
    filterOperators: filterOperators.getMuiOperator('is')
  },
  {
    headerName: 'Events',
    exportField: 'application.userId',
    filterable: false,
    field: 'userId',
    minWidth: eventsColumnWidth,
    flex: 1,
    renderCell: ({ row }) =>
      helpers.getActionPermission(permissionsMap.loggedView) &&
      row.application.userId ? (
        <UserEventsLink executedBy={row.application.userId}>
          {helpers.cutString(row.application.userId, 5)}
        </UserEventsLink>
      ) : (
        row.application.userId || noFieldData
      )
  }
];
