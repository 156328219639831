import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import qs from 'query-string';

import { columns } from './config';

import DashboardFilter from '@components/Dashboard/Filter';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Logger from '@utils/logger';
import { selectDashboardFilters } from '@store/dashboardFilters';
import Loader from '@components/shared/Loader';
import Alert from '@components/shared/Alert/Alert';
import DataTable from '@components/shared/DataTable/DataTable';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const DashboardApplicationSpentTimePage = () => {
  const filtersInitialState = useSelector(selectDashboardFilters);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();

  const fetchTableData = async () => {
    try {
      if (!history.location.search) {
        history.replace({
          search: qs.stringify(filtersInitialState)
        });
      } else {
        setData(null);
        const parsedParams = qs.parse(history.location.search, {
          parseBooleans: true,
          parseNumbers: true
        });
        const response = await AdminApi.get(
          adminApiUrls.dashboard.applicationTime,
          {
            params: parsedParams
          }
        );
        setData(response);
      }
    } catch (e) {
      Logger.error(e);
      setError(e);
    }
  };

  useEffect(() => {
    if (history.location) {
      fetchTableData();
    }
  }, [history.location?.search]);

  return (
    <MainLayout title="Application time report">
      <DashboardFilter />
      {!data && !error && <Loader />}
      {data && (
        <>
          <DataTable
            disableColumnFilter
            columns={columns}
            tableData={data}
            rowKey="pageName"
            hideFooter
          />
        </>
      )}
      {error && (
        <Alert
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
        />
      )}
    </MainLayout>
  );
};

export default DashboardApplicationSpentTimePage;
