import React from 'react';
import { Link } from 'react-router-dom';
import capitalize from 'lodash/capitalize';

import routes from '@constants/routes';
import UserEventsLink from '@components/shared/UserEventsLink';
import {
  filterOperators,
  noFieldData,
  permissionsMap
} from '@constants/common';
import { cleanObject } from '@utils/object-cleaner';
import helpers from '@utils/helpers';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import {
  eventsColumnWidth,
  fullNameColumnWidth,
  timeDateColumnWidth
} from '@components/shared/DataTable/DataTable.constants';

export const initialFilterParams = cleanObject({
  firstName: {
    startswith: ''
  },
  lastName: {
    startswith: ''
  },
  userId: {
    contains: ''
  },
  device: {
    contains: ''
  },
  createdDate: {
    ge: '',
    le: ''
  },
  virified: {
    eq: true
  }
});

export const getFilledColumns =
  ({ appVersions, operatingSystems }) =>
  () => [
    {
      headerName: 'Full name',
      exportField: 'user.firstName',
      field: 'user/firstName',
      minWidth: fullNameColumnWidth,
      renderCell: ({ row }) => {
        const displayName = helpers.getDisplayName(
          row.user.firstName,
          row.user.lastName
        );
        return helpers.getActionPermission(permissionsMap.usersView) &&
          row.userId ? (
          <Tooltip title="User page">
            <Link to={routes.users.page.view.url(row.userId)}>
              {displayName}
            </Link>
          </Tooltip>
        ) : (
          displayName
        );
      },
      type: muiDataTableTypes.fullName,
      filterOperators: [filterOperators.fullName]
    },
    {
      headerName: 'Operation system',
      field: 'operationSystem',
      minWidth: 140,
      flex: 1,
      renderCell: ({ row }) => row?.operationSystem,
      type: muiDataTableTypes.singleSelect,
      valueOptions: operatingSystems
    },
    {
      headerName: 'App Version',
      field: 'version',
      type: muiDataTableTypes.singleSelect,
      renderCell: ({ row }) => row?.version,
      valueOptions: appVersions,
      minWidth: 120
    },
    {
      headerName: 'First login',
      field: 'createdDate',
      valueFormatter: (updatedDate) => helpers.getFormattedDate(updatedDate),
      minWidth: timeDateColumnWidth,
      flex: 1,
      renderCell: ({ row }) => helpers.getFormattedDate(row.createdDate),
      type: muiDataTableTypes.dateRange,
      filterOperators: [filterOperators.dateRange]
    },
    {
      headerName: 'Device Class',
      field: 'deviceClass',
      minWidth: 100,
      flex: 1,
      type: muiDataTableTypes.singleSelect,
      valueOptions: [
        { label: 'Mobile', value: 'mobile' },
        { label: 'Web', value: 'web' }
      ],
      renderCell: ({ row }) => capitalize(row.deviceClass)
    },
    {
      headerName: 'Events',
      field: 'userId',
      minWidth: eventsColumnWidth,
      flex: 1,
      renderCell: ({ row }) =>
        helpers.getActionPermission(permissionsMap.loggedView) ? (
          <UserEventsLink executedBy={row.userId}>
            {helpers.cutString(row.userId, 5)}
          </UserEventsLink>
        ) : (
          row.userId || noFieldData
        )
    }
  ];
