import React from 'react';

import helpers from '@utils/helpers';
import {
  fullNameColumnWidth,
  timeDateColumnWidth
} from '@components/shared/DataTable/DataTable.constants';
import Box from '@components/shared/Box/Box';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import routes from '@constants/routes';
import { csvComparisonApiUrls } from '@constants/csvComparisonApi';
import CsvComparisonApi from '@utils/csvComparisonApi';
import toastify from '@utils/toastify';
import Logger from '@utils/logger';
import { permissionsMap } from '@constants/permissions';
import DeleteActionButton from '@components/shared/DataTable/actions/DeleteActionButton';

const handleDelete = async (id, updatePaginationAfterDelete) => {
  try {
    await CsvComparisonApi.delete(
      csvComparisonApiUrls.comparisons.configs.details(id)
    );

    updatePaginationAfterDelete(
      [id],
      (row, arrayOfDeletableIds) => !arrayOfDeletableIds.includes(row.id)
    );

    toastify.success({
      message: 'Compare configuration was deleted'
    });
  } catch (e) {
    Logger.error(e);
    toastify.error(
      e?.response?.data?.error || {
        message: 'An error occurred deleting compare configuration'
      }
    );
  }
};

export const getColumns = ({ updatePaginationAfterDelete }) => [
  {
    headerName: 'Configuration name',
    field: 'name',
    minWidth: fullNameColumnWidth
  },
  {
    headerName: 'File Identifier',
    field: 'fileIdentifier',
    minWidth: fullNameColumnWidth
  },
  {
    headerName: 'Created by',
    field: 'usernameCreated',
    minWidth: timeDateColumnWidth
  },
  {
    headerName: 'Updated by',
    field: 'usernameUpdated',
    minWidth: fullNameColumnWidth
  },
  {
    headerName: 'Created date',
    field: 'dateCreated',
    renderCell: ({ row }) => helpers.getFormattedDate(row.dateCreated),
    minWidth: timeDateColumnWidth
  },
  {
    headerName: 'Updated date',
    field: 'dateUpdated',
    renderCell: ({ row }) => helpers.getFormattedDate(row.dateUpdated),
    minWidth: timeDateColumnWidth
  },
  {
    headerName: 'Actions',
    field: 'actions',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <EditActionButton
          hidden={!helpers.getActionPermission(permissionsMap.CSVCompareManage)}
          path={routes.csvCompare.configurations.edit.url(row.id)}
        />
        <DeleteActionButton
          hidden={!helpers.getActionPermission(permissionsMap.CSVCompareManage)}
          itemId={row.id}
          onConfirm={() => handleDelete(row.id, updatePaginationAfterDelete)}
        />
      </Box>
    )
  }
];
