import React from 'react';
import { Link } from 'react-router-dom';

import { costTypeOptions } from '@components/Leads/campaigns/Form/schema';
import ConfirmActionSwitch from '@components/shared/DataTable/actions/ConfirmActionSwitch';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import Box from '@components/shared/Box/Box';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';
import routes from '@constants/routes';
import { noFieldData } from '@constants/common';
import { commonLeadProviderStatsColumns } from '@constants/leads';
import { formatValueWithCurrency } from '@components/shared/DataTable/DataTable.utils';

export const getColumns = ({
  actionsWidth,
  additionalColumns,
  handleStatus,
  additionalActions
}) => [
  {
    headerName: 'Action',
    field: 'action',
    width: actionsWidth,
    sortable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        {row.campaignId > 0 && (
          <ConfirmActionSwitch
            size="small"
            checked={row.status}
            hidden={
              !helpers.getActionPermission(permissionsMap.leadProvidersManage)
            }
            modalProps={{
              itemId: row.campaignId,
              content: row.status
                ? 'Do you want to pause?'
                : 'Do you want to resume?',
              onConfirm: () =>
                row.status
                  ? handleStatus(row.campaignId, true)
                  : handleStatus(row.campaignId, false)
            }}
          />
        )}
        {additionalActions?.(row)}
      </Box>
    )
  },
  ...additionalColumns.startColumns,
  {
    headerName: 'Campaign name',
    field: 'campaignName',
    width: 195,
    renderCell: ({ row }) => {
      const hasPermission = helpers.getActionPermission(
        permissionsMap.leadProvidersView
      );

      if (hasPermission) {
        const campaignLink = row.campaignId > 0 && (
          <Link
            to={routes.leads.leadProviders.campaign.view.url(
              row.leadProviderId,
              row.campaignId
            )}
          >
            {row.campaignName}
          </Link>
        );

        return (
          <Tooltip title="Campaign page">
            {campaignLink || row.campaignName}
          </Tooltip>
        );
      } else {
        return row.campaignName || noFieldData;
      }
    }
  },
  ...commonLeadProviderStatsColumns,
  {
    headerName: 'Cost',
    field: 'cost',
    width: 70,
    valueFormatter: formatValueWithCurrency
  },
  {
    headerName: 'Cost type',
    field: 'providerType',
    width: 70,
    renderCell: ({ row }) =>
      costTypeOptions.find((item) => item.key === row.providerType).label
  },

  ...additionalColumns.endColumns
];
