import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';

import ListItemIcon from '../List/ListItemIcon';

import { closedMixin, drawerWidth, openedMixin } from './helpers';

import List from '@components/shared/List/List';
import Box from '@components/shared/Box/Box';
import ListItem from '@components/shared/List/ListItem';
import ListItemButton from '@components/shared/List/ListItemButton';
import Tooltip, { tooltipClasses } from '@components/shared/Tooltip/Tooltip';
import { palette } from '@utils/theme';

export const StyledDrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingRight: '10px',
  alignSelf: 'flex-end'
}));

export const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  minWidth: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export const StyledMainBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  minWidth: '500px',
  paddingTop: `45px !important`,
  paddingBottom: `45px !important`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open !== undefined && {
    marginLeft: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('xs')]: {
      marginLeft: `calc(${theme.spacing(9)} + 1px)`
    }
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth + 10}px)`,
    marginLeft: `${drawerWidth + 10}px !important`,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

export const StyledListItem = styled(ListItem)(({ open, isInner }) => {
  const highlightedItem = {
    backgroundColor: `${palette.primary} !important`,
    color: `${palette.white} !important`,
    '& .MuiListItemIcon-root': {
      color: palette.white
    }
  };

  return {
    minHeight: 30,
    borderRadius: '5px',
    display: 'block',
    paddingLeft: isInner ? '10px' : '3px',
    paddingRight: '3px',
    justifyContent: open ? 'initial' : 'center',
    '& .MuiListItemButton-root': {
      '&:hover': {
        ...highlightedItem,
        borderRadius: '5px'
      }
    },

    '& .MuiListItemText-primary': {
      fontSize: '14px'
    },

    '& .MuiListItemIcon-root': {
      minWidth: 'unset',
      marginRight: '24px'
    },

    '& .Mui-selected': {
      ...highlightedItem
    }
  };
});

export const StyledListItemButton = styled(ListItemButton)(({ selected }) => {
  const highlightedItem = {
    backgroundColor: `${palette.primary} !important`,
    color: `${palette.white} !important`,
    '& .MuiListItemIcon-root': {
      color: palette.white
    }
  };
  return {
    paddingTop: '5px',
    paddingBottom: '5px',
    borderRadius: '5px',

    ...(selected && { ...highlightedItem }),

    '&.Mui-disabled': {
      opacity: 1
    }
  };
});

export const StyledListItemIcon = styled(ListItemIcon)(({ open }) => ({
  minWidth: 0,
  mr: open ? 3 : 'auto',
  justifyContent: 'center',
  color: palette.dark
}));

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    minHeight: '35px',
    minWidth: '90px',
    borderRadius: '30px',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    fontWeight: '600'
  }
}));

export const StyledList = styled(List)(() => ({
  paddingBottom: '50px'
}));

export const StyledLink = styled(Link)(() => ({
  color: `${palette.dark}`
}));
