import { Form, Formik } from 'formik';
import React, { memo, useState } from 'react';
import dayjs from 'dayjs';
import PT from 'prop-types';
import { useSelector } from 'react-redux';

import {
  CSV_INPUT_NAME,
  csvSchema,
  initialValues
} from './UploadCSVForm.config';

import FormikField from '@components/shared/Form/FormikField/FormikField';
import {
  dateFormat,
  ELOAN_ADMIN_KEY,
  shortTimeFormat,
  timeFormat
} from '@constants/common';
import Stack from '@components/shared/Stack/Stack';
import { fieldWidth } from '@constants/filters';
import toastify from '@utils/toastify';
import Button from '@components/shared/Button/Button';
import ImportCSVButton from '@components/shared/ImportCSV/ImportCSVButton';
import PaymentProcessingApi from '@utils/paymentProcessingApi';
import { paymentProcessingApiUrls } from '@constants/paymentProcessingApiUrls';
import { selectCurrentUser } from '@store/currentUser';

const UploadCSVForm = ({ refetchList, onModalClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const storeCurrentUser = useSelector(selectCurrentUser);

  const getUserName = () =>
    storeCurrentUser.roles.includes(ELOAN_ADMIN_KEY)
      ? storeCurrentUser.userName
      : `${storeCurrentUser.firstName} ${storeCurrentUser.lastName}`;

  const handleSubmit = async (values) => {
    const timeString = values.time.format(timeFormat);
    const date =
      values.when === 'now'
        ? dayjs().toISOString()
        : dayjs(`${values.date} ${timeString}`).toISOString(); // TODO: replace after implementing datetimepicker instead of date and time pickers

    const csvFormData = new FormData();
    csvFormData.append(CSV_INPUT_NAME, values[CSV_INPUT_NAME]);
    csvFormData.append('date', date);
    csvFormData.append('username', getUserName());

    try {
      setIsLoading(true);
      await PaymentProcessingApi.post(
        paymentProcessingApiUrls.unprocessedPayments.uploadCSV,
        csvFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json'
          }
        }
      );
      toastify.success({
        message: 'CSV file saved'
      });
      refetchList?.();
    } catch (error) {
      toastify.error({
        message: 'Save operation failure'
      });
    } finally {
      setIsLoading(false);
      onModalClose();
    }
  };

  const handleUploadFile = (file, setFieldValue) => {
    setFieldValue(CSV_INPUT_NAME, file);
  };

  const handleRemoveFile = (setFieldValue, inputRef) => {
    setFieldValue(CSV_INPUT_NAME, null);
    inputRef.current.value = null;
  };

  const options = [
    { label: 'Now', value: 'now' },
    { label: 'Scheduled', value: 'scheduled' }
  ];

  return (
    <>
      <Formik
        initialValues={{
          ...initialValues,
          when: 'now'
        }}
        validationSchema={csvSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, isValid }) => (
          <Form>
            <Stack direction="row" gap={1} alignItems="center">
              <ImportCSVButton
                file={values[CSV_INPUT_NAME]}
                handleUploadFile={(file) =>
                  handleUploadFile(file, setFieldValue)
                }
                handleRemoveFile={(inputRef) =>
                  handleRemoveFile(setFieldValue, inputRef)
                }
                wrapperProps={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
                isRow
                title="Choose file"
              />
              <FormikField
                type="autocomplete"
                name="when"
                label="Select time"
                size="small"
                options={options}
                sx={fieldWidth}
              />
              {values.when !== 'now' && (
                <>
                  <FormikField
                    type="date"
                    name="date"
                    label="Date"
                    size="small"
                    format={dateFormat}
                    sx={fieldWidth}
                    disablePast
                  />
                  <FormikField
                    type="time"
                    name="time"
                    label="Time"
                    format={shortTimeFormat.front}
                  />
                </>
              )}
              <Button
                type="submit"
                disabled={!isValid || !values[CSV_INPUT_NAME] || isLoading}
                loading={isLoading}
              >
                Save
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

UploadCSVForm.propTypes = {
  refetchList: PT.func.isRequired,
  onModalClose: PT.func
};

export default memo(UploadCSVForm);
