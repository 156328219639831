import React from 'react';
import { Form, Formik } from 'formik';
import isEqual from 'lodash/isEqual';

import { baseInitialValues } from './config';

import {
  shortcutsItems,
  showAllValue
} from '@components/LeadReports/filters/config';
import Stack from '@components/shared/Stack/Stack';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Button from '@components/shared/Button/Button';
import useLeadReportFilters from '@components/LeadReports/filters/useLeadReportFilters';
import { fieldWidth } from '@constants/filters';
import { statesOptions } from '@constants/states';

const AffiliateReportsFilterForm = () => {
  const { initialValues, onReset, handleFilterUpdate, campaignsOptions } =
    useLeadReportFilters({
      baseInitialValues,
      dateRangeField: 'Date'
    });

  return (
    <Formik initialValues={initialValues} onSubmit={handleFilterUpdate}>
      {({ values, resetForm }) => (
        <Form className="page-filter">
          <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
            <FormikField
              type="autocomplete"
              name="LeadCampaignId"
              label="Lead campaigns"
              size="small"
              options={[
                { label: 'All campaigns', value: showAllValue },
                ...(campaignsOptions || [])
              ]}
              sx={fieldWidth}
            />
            <FormikField
              type="autocomplete"
              name="State"
              label="State"
              placeholder="Select state"
              options={[
                { label: 'All states', value: showAllValue },
                ...statesOptions
              ]}
              sx={fieldWidth}
            />
            <FormikField
              type="dateRange"
              name="Date"
              label="Date between"
              size="small"
              shortcutsItems={shortcutsItems}
              sx={fieldWidth}
            />
            <Stack direction="row" gap={1} alignItems="center">
              <Button type="submit">Apply</Button>
              <Button
                variant="outlined"
                onClick={() => onReset(resetForm)}
                disabled={isEqual(baseInitialValues, values)}
              >
                Reset
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default AffiliateReportsFilterForm;
