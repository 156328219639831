import React from 'react';

import routes from '@constants/routes';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';
import { cleanObject } from '@utils/object-cleaner';
import Box from '@components/shared/Box/Box';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';
import { timeDateColumnWidth } from '@components/shared/DataTable/DataTable.constants';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import { filterOperators } from '@constants/common';

export const initialFilterParams = cleanObject({
  name: {
    contains: ''
  },
  createdDate: {
    ge: '',
    le: ''
  },
  updatedDate: {
    ge: '',
    le: ''
  }
});

export const getColumns = () => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <EditActionButton
          path={routes.leads.leadProviders.page.edit.url(row.leadProviderId)}
          hidden={
            !helpers.getActionPermission(permissionsMap.leadProvidersManage)
          }
        />
        <ShowDetailsActionButton
          path={routes.leads.leadProviders.page.view.url(row.leadProviderId)}
        />
      </Box>
    )
  },
  {
    headerName: 'Name',
    field: 'name',
    minWidth: 250,
    flex: 1
  },
  {
    headerName: 'Date created',
    field: 'createdDate',
    minWidth: timeDateColumnWidth,
    flex: 1,
    valueFormatter: (createdDate) => helpers.getFormattedDate(createdDate),
    renderCell: ({ row }) => helpers.getFormattedDate(row.createdDate),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Date modified',
    field: 'updatedDate',
    minWidth: timeDateColumnWidth,
    flex: 1,
    valueFormatter: (updatedDate) => helpers.getFormattedDate(updatedDate),
    renderCell: ({ row }) => helpers.getFormattedDate(row.updatedDate),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  }
];
