import { styled } from '@mui/material/styles';

import List from '@components/shared/List/List';
import ListItemText from '@components/shared/List/ListItemText';

export const StyledList = styled(List)(({
  separateToColumns,
  contentListLength
}) => {
  const isFullWidthRow = contentListLength % 2 !== 0 && separateToColumns !== 1;

  return {
    '&.MuiList-root': {
      display: 'grid',
      gridTemplateColumns: `repeat(${separateToColumns}, 1fr)`,

      '& .MuiListItem-root': {
        [`&:not(:nth-of-type(-n+${separateToColumns}))`]: {
          borderTop: '1px solid #b2b2b2'
        },
        ...(isFullWidthRow && {
          ['&:last-of-type']: {
            gridColumn: `${separateToColumns} span`
          }
        })
      }
    }
  };
});

export const StyledListItemText = styled(ListItemText)`
  && {
    margin: 0;
    grid-template-columns: 170px 2fr;

    & .MuiListItemText {
      &-primary {
        font-size: 14px;
        font-weight: 600;
      }

      &-secondary {
      }
    }
  }
`;
