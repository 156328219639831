import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getColumns, initialFilterParams } from './config';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import routes from '@constants/routes';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';
import Loader from '@components/shared/Loader';
import { ActionsBox } from '@components/shared/MainLayout/MainLayout.components';
import Button from '@components/shared/Button/Button';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

let interval = null;
const LeadProvidersListPage = () => {
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    delayReload();

    return () => {
      clearInterval(interval);
    };
  }, []);

  const delayReload = () => {
    clearInterval(interval);
    interval = setInterval(() => {
      setRefresh(true);
      setRefresh(false);
    }, 60000);
  };

  return (
    <MainLayout title="Lead Providers">
      <ActionsBox>
        {helpers.getActionPermission(permissionsMap.leadProvidersManage) && (
          <Button component={Link} to={routes.leads.leadProviders.page.create}>
            Add new lead provider
          </Button>
        )}
      </ActionsBox>
      {refresh ? (
        <Loader />
      ) : (
        <ODataPaginatedTable
          getColumns={getColumns}
          customApi={LeadManagementApi}
          usePaginatedDataOptions={{
            apiUrl: leadManagementApiUrls.leadProviders.list,
            initialFilterParams: {
              ...initialFilterParams,
              $orderBy: 'createdDate desc'
            }
          }}
          loading={refresh}
          rowKey="leadProviderId"
          tableProps={{
            onChange: delayReload
          }}
        />
      )}
    </MainLayout>
  );
};

export default LeadProvidersListPage;
