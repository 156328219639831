import React from 'react';
import { Form, Formik } from 'formik';
import PT from 'prop-types';

import { initialValues, validationSchema } from './comparisonsModal.config';

import Stack from '@components/shared/Stack/Stack';
import Modal from '@components/shared/Modal/Modal';
import Button from '@components/shared/Button/Button';
import ImportCSVButton from '@components/shared/ImportCSV/ImportCSVButton';

const ComparisonsModal = ({ open, onClose, onSubmit }) => {
  const handleRemoveFile = (setFieldValue, inputRef, name) => {
    setFieldValue(name, null);
    inputRef.current.value = null;
  };

  const handleUploadFile = (file, setFieldValue, name) => {
    setFieldValue(name, file);
  };

  return (
    <Modal
      title="Choose files for compare"
      open={open}
      onClose={onClose}
      withCloseIcon
      minContentWidth={800}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, isSubmitting, isValid }) => (
          <Form>
            <Stack
              mt={4}
              direction="row"
              gap={2}
              justifyContent="center"
              alignItems="center"
            >
              <ImportCSVButton
                file={values.fileA}
                handleUploadFile={(file) =>
                  handleUploadFile(file, setFieldValue, 'fileA')
                }
                handleRemoveFile={(inputRef) =>
                  handleRemoveFile(setFieldValue, inputRef, 'fileA')
                }
                wrapperProps={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
                isRow
                title="Choose file"
              />

              <ImportCSVButton
                file={values.fileB}
                handleUploadFile={(file) =>
                  handleUploadFile(file, setFieldValue, 'fileB')
                }
                handleRemoveFile={(inputRef) =>
                  handleRemoveFile(setFieldValue, inputRef, 'fileB')
                }
                wrapperProps={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
                isRow
                title="Choose file"
              />
            </Stack>
            <Stack direction="row" justifyContent="center" mt={2}>
              <Button
                loading={isSubmitting}
                type="submit"
                disabled={!isValid || (!values.fileA && !values.fileB)}
              >
                Run compare
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

ComparisonsModal.propTypes = {
  open: PT.bool.isRequired,
  onClose: PT.func.isRequired,
  onSubmit: PT.func.isRequired
};

export default ComparisonsModal;
