import React, { useMemo } from 'react';
import PT from 'prop-types';

import { generalStepInitialValues, generalStepSchema } from './schema';

import FormikField from '@components/shared/Form/FormikField/FormikField';
import { dateFormat, fieldsMasks } from '@constants/common';
import helpers from '@utils/helpers';
import StepContainer from '@components/Application/LoanForm/StepContainer';
import withStepData from '@components/Application/LoanForm/withStepData';

const GeneralStep = ({ initialValues, options, onSubmit, isFilledStep }) => {
  const formattedOptions = useMemo(
    () => helpers.getOptionsObject(options),
    [options]
  );

  const mergedInitialValues = useMemo(
    () => ({
      ...helpers.mergeLoanFormData(initialValues, generalStepInitialValues),
      confirmEmail: isFilledStep
        ? initialValues.email
        : generalStepInitialValues.confirmEmail
    }),
    [initialValues]
  );

  const handleSubmit = (values, handlers) =>
    onSubmit({ values, initialValues: mergedInitialValues }, handlers);

  return (
    <StepContainer
      formSchema={generalStepSchema}
      initialValues={mergedInitialValues}
      onSubmit={handleSubmit}
      title="General"
    >
      <FormikField
        type="text"
        name="firstName"
        label="First name:"
        placeholder="e.g. John"
        autoFocus
      />
      <FormikField
        type="text"
        name="lastName"
        label="Last name:"
        placeholder="e.g. Snow"
      />
      <FormikField
        type="date"
        name="dateOfBirth"
        label="Date of birth:"
        placeholder={dateFormat}
        format={dateFormat}
        views={['year', 'month', 'day']}
        disableFuture
      />
      <FormikField
        type="text"
        name="email"
        label="Email:"
        placeholder="example@mail.com"
      />
      <FormikField
        type="text"
        name="confirmEmail"
        label="Confirm email:"
        placeholder="example@mail.com"
      />
      <FormikField
        type="checkbox"
        name="emailCommunication"
        label={
          <>
            Allow communication by <strong>email</strong>
          </>
        }
      />
      <FormikField
        name="phone"
        type="mask"
        fullWidth
        mask={fieldsMasks.phoneUSA.mask}
        label="Phone number:"
        placeholder={fieldsMasks.phoneUSA.placeholder}
        plainValue
        maskChar="X"
      />
      <FormikField
        type="checkbox"
        name="phoneCommunication"
        label={
          <>
            Allow communication by <strong>email</strong>
          </>
        }
      />
      <FormikField
        name="ssn"
        type="mask"
        fullWidth
        mask={fieldsMasks.ssn.mask}
        label="Social security number:"
        placeholder={fieldsMasks.ssn.placeholder}
      />
      <FormikField
        type="text"
        name="dlLicenseNumber"
        label="DL number / ID :"
        placeholder="e.g. Snow"
      />
      <FormikField
        type="select"
        name="dlLicenseState"
        label="DL State:"
        placeholder="Select"
        options={formattedOptions.state}
        filterOptions={helpers.searchInclusionsFromWordBeginning}
      />
      <FormikField
        type="text"
        name="promo"
        label="Promo:"
        placeholder="XXXXXXXXX"
      />
    </StepContainer>
  );
};

GeneralStep.propTypes = {
  isFilledStep: PT.bool.isRequired,
  onSubmit: PT.func.isRequired,
  initialValues: PT.object.isRequired,
  options: PT.any.isRequired
};

export default withStepData(GeneralStep);
