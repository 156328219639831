import React from 'react';

import {
  booleanFilterFieldOptions,
  filterFieldsRenderers
} from '@constants/filters';
import { FilterOptionsContext } from '@components/Applications/Filter/FilterOptionsContext';
import { loanTypeOptions } from '@constants/common';

const defaultRemoveFilterValue = (fieldKey) => (values) =>
  Object.fromEntries(
    Object.entries(values).filter(([key]) => key !== fieldKey)
  );

const filterFieldsDict = {
  userId: 'userId',
  applicationId: 'applicationId',
  applicationUID: 'applicationUid',
  userLoanNumber: 'userLoanNumber',
  userLoanCustomerId: 'userLoanCustomerId',
  locked: 'locked',
  signature: 'signature',
  leadResponseResult: 'leadResponseResult',
  updateDate: 'updateDate',
  customerFirstName: 'customerFirstName',
  customerLastName: 'customerLastName',
  customerBirthDate: 'customerBirthDate',
  customerEmailCommunication: 'customerEmailCommunication',
  customerPhone: 'customerPhone',
  customerPhoneCommunication: 'customerPhoneCommunication',
  customerDLNumber: 'customerDLNumber',
  promo: 'promo',
  bankName: 'bankName',
  bankPhone: 'bankPhone',
  bankRoutingNumber: 'bankRoutingNumber',
  checkingAccount: 'checkingAccount',
  savingsAccount: 'savingsAccount',
  address1: 'address1',
  address2: 'address2',
  city: 'city',
  zip: 'zip',
  addressState: 'addressState',
  dateOfHire: 'dateOfHire',
  employerName: 'employerName',
  employerPhone: 'employerPhone',
  employmentTypeId: 'employmentTypeId',
  payFrequencyId: 'payFrequencyId',
  netPayPerPayroll: 'netPayPerPayroll',
  payrollMethodId: 'payrollMethodId',
  payrollSourceId: 'payrollSourceId',
  militaryStatusId: 'militaryStatusId',
  loanAmount: 'loanAmount',
  loanType: 'loanType',
  ppcQuerySource: 'ppcQuerySource',
  utmValues: 'metaAttributeValue'
};

export const filterFieldsOptions = [
  {
    title: 'Events',
    key: filterFieldsDict.userId
  },
  {
    title: 'Application ID',
    key: filterFieldsDict.applicationId
  },
  {
    title: 'Application UID',
    key: filterFieldsDict.applicationUID
  },
  {
    title: 'LMS loan number',
    key: filterFieldsDict.userLoanNumber
  },
  {
    title: 'LMS customer ID',
    key: filterFieldsDict.userLoanCustomerId
  },
  {
    title: 'Locked',
    key: filterFieldsDict.locked
  },
  {
    title: 'Signature',
    key: filterFieldsDict.signature
  },
  {
    title: 'Lead response result',
    key: filterFieldsDict.leadResponseResult
  },
  {
    title: 'Update date',
    key: filterFieldsDict.updateDate
  },
  {
    title: "Customer's date of birth",
    key: filterFieldsDict.customerBirthDate
  },
  {
    title: "Customer's email communication",
    key: filterFieldsDict.customerEmailCommunication
  },
  {
    title: "Customer's phone communication",
    key: filterFieldsDict.customerPhoneCommunication
  },
  {
    title: "Customer's DL number",
    key: filterFieldsDict.customerDLNumber
  },
  {
    title: 'Promo',
    key: filterFieldsDict.promo
  },
  {
    title: 'Bank name',
    key: filterFieldsDict.bankName
  },
  {
    title: 'Bank phone',
    key: filterFieldsDict.bankPhone
  },
  {
    title: 'Bank routing number',
    key: filterFieldsDict.bankRoutingNumber
  },
  {
    title: 'Checking account number',
    key: filterFieldsDict.checkingAccount
  },
  {
    title: 'Savings account number',
    key: filterFieldsDict.savingsAccount
  },
  {
    title: "Customer's address 1",
    key: filterFieldsDict.address1
  },
  {
    title: "Customer's address 2",
    key: filterFieldsDict.address2
  },
  {
    title: 'City',
    key: filterFieldsDict.city
  },
  {
    title: 'Zip',
    key: filterFieldsDict.zip
  },
  {
    title: 'State address',
    key: filterFieldsDict.addressState
  },
  {
    title: 'Date of hire',
    key: filterFieldsDict.dateOfHire
  },
  {
    title: 'Employer name',
    key: filterFieldsDict.employerName
  },
  {
    title: 'Employer phone',
    key: filterFieldsDict.employerPhone
  },
  {
    title: 'Employment type',
    key: filterFieldsDict.employmentTypeId
  },
  {
    title: 'Pay frequency',
    key: filterFieldsDict.payFrequencyId
  },
  {
    title: 'Net pay per payroll',
    key: filterFieldsDict.netPayPerPayroll
  },
  {
    title: 'Payroll method',
    key: filterFieldsDict.payrollMethodId
  },
  {
    title: 'Payroll source',
    key: filterFieldsDict.payrollSourceId
  },
  {
    title: 'Military status',
    key: filterFieldsDict.militaryStatusId
  },
  {
    title: 'Loan amount',
    key: filterFieldsDict.loanAmount
  },
  {
    title: 'Loan type',
    key: filterFieldsDict.loanType
  },
  {
    title: 'UTM source',
    key: filterFieldsDict.ppcQuerySource
  },
  {
    title: 'UTM value',
    key: filterFieldsDict.utmValues
  }
];

export const filterFieldsConfig = {
  [filterFieldsDict.userId]: {
    initialValue: {
      userId: {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'Events',
      name: 'userId.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue('userId')
  },

  [filterFieldsDict.applicationId]: {
    initialValue: {
      applicationId: ''
    },
    renderFilterField: filterFieldsRenderers.number({
      placeholder: 'Application ID',
      name: 'applicationId'
    }),
    removeFilterValue: defaultRemoveFilterValue('applicationId')
  },
  [filterFieldsDict.applicationUID]: {
    initialValue: {
      applicationUid: {
        qe: {
          value: ''
        }
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'Application UID',
      name: 'applicationUid.eq.value'
    }),
    removeFilterValue: defaultRemoveFilterValue('applicationUid')
  },
  [filterFieldsDict.userLoanNumber]: {
    initialValue: {
      'userLoan/loanNumber': ''
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'LMS loan number',
      name: 'userLoan/loanNumber'
    }),
    removeFilterValue: defaultRemoveFilterValue('userLoan/loanNumber')
  },
  [filterFieldsDict.userLoanCustomerId]: {
    initialValue: {
      'userLoan/customerId': ''
    },
    renderFilterField: filterFieldsRenderers.number({
      placeholder: 'LMS customer ID',
      name: 'userLoan/customerId'
    }),
    removeFilterValue: defaultRemoveFilterValue('userLoan/customerId')
  },
  [filterFieldsDict.locked]: {
    addInitialValue: {
      locked: ''
    },
    renderFilterField: filterFieldsRenderers.select({
      options: booleanFilterFieldOptions,
      placeholder: 'Locked',
      name: 'clocked',
      colProps: { xxl: 4 }
    }),
    removeFilterValue: defaultRemoveFilterValue('locked')
  },
  [filterFieldsDict.signature]: {
    initialValue: {
      signature: {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'Signature',
      name: 'signature.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue('signature')
  },
  [filterFieldsDict.leadResponseResult]: {
    initialValue: {
      'leadResponse/result': ''
    },
    renderFilterField: filterFieldsRenderers.select({
      options: [
        {
          label: 'Unset',
          value: ''
        },
        {
          label: 'Accepted',
          value: 'A'
        },
        {
          label: 'Rejected',
          value: 'R'
        }
      ],
      placeholder: 'Lead response result',
      name: 'leadResponse/result'
    }),
    removeFilterValue: defaultRemoveFilterValue('leadResponse/result')
  },
  [filterFieldsDict.updateDate]: {
    initialValue: {
      updatedDate: {
        ge: '',
        le: ''
      }
    },
    renderFilterField: filterFieldsRenderers.dateRange({
      placeholder: 'Updated date',
      name: 'updatedDate'
    }),
    removeFilterValue: defaultRemoveFilterValue('updatedDate')
  },
  [filterFieldsDict.customerBirthDate]: {
    initialValue: {
      'customer/dateOfBirth': {
        ge: '',
        le: ''
      }
    },
    renderFilterField: filterFieldsRenderers.dateRange({
      placeholder: "Customer's birth date",
      name: 'customer/dateOfBirth'
    }),
    removeFilterValue: defaultRemoveFilterValue('customer/dateOfBirth')
  },
  [filterFieldsDict.customerEmailCommunication]: {
    initialValue: {
      'customer/emailCommunication': ''
    },
    renderFilterField: filterFieldsRenderers.select({
      options: booleanFilterFieldOptions,
      placeholder: "Customer's email communication",
      name: 'customer/emailCommunication'
    }),
    removeFilterValue: defaultRemoveFilterValue('customer/emailCommunication')
  },
  [filterFieldsDict.customerPhoneCommunication]: {
    initialValue: {
      'customer/phoneCommunication': ''
    },
    renderFilterField: filterFieldsRenderers.select({
      options: booleanFilterFieldOptions,
      placeholder: "Customer's phone communication",
      name: 'customer/phoneCommunication'
    }),
    removeFilterValue: defaultRemoveFilterValue('customer/phoneCommunication')
  },
  [filterFieldsDict.customerDLNumber]: {
    initialValue: {
      'customer/dlLicenseNumber': {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: "Customer's DL number",
      name: 'customer/dlLicenseNumber.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue('customer/dlLicenseNumber')
  },
  [filterFieldsDict.promo]: {
    initialValue: {
      'customer/promo': {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'Promo code',
      name: 'customer/promo.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue('customer/promo')
  },
  [filterFieldsDict.bankName]: {
    initialValue: {
      'bankAccount/bankName': {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'Bank name',
      name: 'bankAccount/bankName.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue('bankAccount/bankName')
  },
  [filterFieldsDict.bankPhone]: {
    initialValue: {
      'bankAccount/bankPhone': {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'Bank phone',
      name: 'bankAccount/bankPhone.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue('bankAccount/bankPhone')
  },
  [filterFieldsDict.bankRoutingNumber]: {
    initialValue: {
      'bankAccount/bankRoutingNumber': ''
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'Bank routing number',
      name: 'bankAccount/bankRoutingNumber'
    }),
    removeFilterValue: defaultRemoveFilterValue('bankAccount/bankRoutingNumber')
  },
  [filterFieldsDict.checkingAccount]: {
    initialValue: {
      'bankAccount/checkingAccount': ''
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'Checking account number',
      name: 'bankAccount/checkingAccount'
    }),
    removeFilterValue: defaultRemoveFilterValue('bankAccount/checkingAccount')
  },
  [filterFieldsDict.savingsAccount]: {
    initialValue: {
      'bankAccount/savingsAccount': ''
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'Savings account number',
      name: 'bankAccount/savingsAccount'
    }),
    removeFilterValue: defaultRemoveFilterValue('bankAccount/savingsAccount')
  },
  [filterFieldsDict.address1]: {
    initialValue: {
      'customerAddress/address1': {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: "Customer's address 1",
      name: 'customerAddress/address1.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue('customerAddress/address1')
  },
  [filterFieldsDict.address2]: {
    initialValue: {
      'customerAddress/address2': {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: "Customer's address 2",
      name: 'customerAddress/address2.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue('customerAddress/address2')
  },
  [filterFieldsDict.city]: {
    initialValue: {
      'customerAddress/city': {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'City',
      name: 'customerAddress/city.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue('customerAddress/city')
  },
  [filterFieldsDict.zip]: {
    initialValue: {
      'customerAddress/zip': {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'Zip',
      name: 'customerAddress/zip.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue('customerAddress/zip')
  },
  [filterFieldsDict.addressState]: {
    initialValue: {
      'customerAddress/stateId': ''
    },
    renderFilterField: () => (
      <FilterOptionsContext.Consumer>
        {({ state: options = [] }) =>
          filterFieldsRenderers.select({
            options,
            placeholder: 'State address',
            name: 'customerAddress/stateId'
          })()
        }
      </FilterOptionsContext.Consumer>
    ),
    removeFilterValue: defaultRemoveFilterValue('customerAddress/stateId')
  },
  [filterFieldsDict.dateOfHire]: {
    initialValue: {
      'customerEmployment/dateOfHire': ''
    },
    renderFilterField: filterFieldsRenderers.date({
      placeholder: 'Date of hire',
      name: 'customerEmployment/dateOfHire'
    }),
    removeFilterValue: defaultRemoveFilterValue('customerEmployment/dateOfHire')
  },
  [filterFieldsDict.employerName]: {
    initialValue: {
      'customerEmployment/employerName': {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'Employer name',
      name: 'customerEmployment/employerName.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue(
      'customerEmployment/employerName'
    )
  },
  [filterFieldsDict.employerPhone]: {
    initialValue: {
      'customerEmployment/employerPhone': {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'Employer name',
      name: 'customerEmployment/employerPhone.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue(
      'customerEmployment/employerPhone'
    )
  },
  [filterFieldsDict.employmentTypeId]: {
    initialValue: {
      'customerEmployment/employmentTypeId': ''
    },
    renderFilterField: () => (
      <FilterOptionsContext.Consumer>
        {({ employmentType: options = [] }) =>
          filterFieldsRenderers.select({
            options,
            placeholder: 'Employment type',
            name: 'customerEmployment/employmentTypeId'
          })()
        }
      </FilterOptionsContext.Consumer>
    ),
    removeFilterValue: defaultRemoveFilterValue(
      'customerEmployment/employmentTypeId'
    )
  },
  [filterFieldsDict.payFrequencyId]: {
    initialValue: {
      'customerEmployment/payFrequencyId': ''
    },
    renderFilterField: () => (
      <FilterOptionsContext.Consumer>
        {({ payFrequency: options = [] }) =>
          filterFieldsRenderers.select({
            options,
            placeholder: 'Pay frequency',
            name: 'customerEmployment/payFrequencyId'
          })()
        }
      </FilterOptionsContext.Consumer>
    ),
    removeFilterValue: defaultRemoveFilterValue(
      'customerEmployment/payFrequencyId'
    )
  },
  [filterFieldsDict.payrollMethodId]: {
    initialValue: {
      'customerEmployment/payrollMethodId': ''
    },
    renderFilterField: () => (
      <FilterOptionsContext.Consumer>
        {({ payrollMethod: options = [] }) =>
          filterFieldsRenderers.select({
            options,
            placeholder: 'Payroll method',
            name: 'customerEmployment/payrollMethodId'
          })()
        }
      </FilterOptionsContext.Consumer>
    ),
    removeFilterValue: defaultRemoveFilterValue(
      'customerEmployment/payrollMethodId'
    )
  },
  [filterFieldsDict.payrollSourceId]: {
    initialValue: {
      'customerEmployment/payrollSourceId': ''
    },
    renderFilterField: () => (
      <FilterOptionsContext.Consumer>
        {({ payrollSource: options = [] }) =>
          filterFieldsRenderers.select({
            options,
            placeholder: 'Payroll source',
            name: 'customerEmployment/payrollSourceId'
          })()
        }
      </FilterOptionsContext.Consumer>
    ),
    removeFilterValue: defaultRemoveFilterValue(
      'customerEmployment/payrollSourceId'
    )
  },
  [filterFieldsDict.netPayPerPayroll]: {
    initialValue: {
      'customerEmployment/netPayPerPayroll': ''
    },
    renderFilterField: filterFieldsRenderers.valueRange({
      placeholder: 'Net pay per payroll',
      name: 'customerEmployment/netPayPerPayroll'
    }),
    removeFilterValue: defaultRemoveFilterValue(
      'customerEmployment/netPayPerPayroll'
    )
  },
  [filterFieldsDict.militaryStatusId]: {
    initialValue: {
      'disclosure/militaryStatusId': ''
    },
    renderFilterField: () => (
      <FilterOptionsContext.Consumer>
        {({ militaryStatus: options = [] }) =>
          filterFieldsRenderers.select({
            options,
            placeholder: 'Military status',
            name: 'disclosure/militaryStatusId'
          })()
        }
      </FilterOptionsContext.Consumer>
    ),
    removeFilterValue: defaultRemoveFilterValue('disclosure/militaryStatusId')
  },
  [filterFieldsDict.loanAmount]: {
    initialValue: {
      'loanPrincipal/amount': ''
    },
    renderFilterField: filterFieldsRenderers.valueRange({
      placeholder: 'Loan amount',
      name: 'loanPrincipal/amount'
    }),
    removeFilterValue: defaultRemoveFilterValue('loanPrincipal/amount')
  },
  [filterFieldsDict.loanType]: {
    initialValue: {
      loanType: ''
    },
    renderFilterField: filterFieldsRenderers.select({
      placeholder: 'Loan Type',
      name: 'loanType',
      options: loanTypeOptions
    }),
    removeFilterValue: defaultRemoveFilterValue('loanType')
  },
  [filterFieldsDict.ssnShort]: {
    initialValue: {
      'customer/ssnShort': {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'SSN (last 4 digits)',
      name: 'customer/ssnShort.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue('customer/ssnShort')
  },
  [filterFieldsDict.ppcQuerySource]: {
    initialValue: {
      ppcQuerySource: {
        contains: ''
      }
    },
    renderFilterField: () => (
      <FilterOptionsContext.Consumer>
        {({ utmSource: options = [] }) =>
          filterFieldsRenderers.autoComplete({
            options,
            placeholder: 'UTM source',
            name: 'ppcQuerySource.contains'
          })()
        }
      </FilterOptionsContext.Consumer>
    ),
    removeFilterValue: defaultRemoveFilterValue('ppcQuerySource')
  },
  [filterFieldsDict.utmValues]: {
    initialValue: {
      metaAttributeValue: {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'UTM Value',
      name: 'metaAttributes.any.metaAttributeValue.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue('metaAttributeValue')
  }
};
