import React from 'react';
import PT from 'prop-types';

const StopPropagationWrapper = ({ children, style }) => (
  <div
    onClick={(e) => {
      // for disabling the click event on the Accordion
      e.stopPropagation();
    }}
    style={style}
  >
    {children}
  </div>
);

StopPropagationWrapper.propTypes = {
  children: PT.node.isRequired,
  style: PT.object
};

export default StopPropagationWrapper;
