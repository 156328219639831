import { leadManagementReportsApiUrls } from '@constants/leadManagementReportsApi';
import LeadPerformance from '@components/LeadReports/LeadPerformance/LeadPerformance';

const LeadProviderPerformancePage = () =>
  LeadPerformance(
    leadManagementReportsApiUrls.leadProviderAggregateReport,
    'Lead Performance',
    'leadProviderName'
  );

export default LeadProviderPerformancePage;
