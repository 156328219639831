import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Alert from '@components/shared/Alert/Alert';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Logger from '@utils/logger';
import UserDetails from '@components/Users/Details';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const UserViewPage = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchUserData = async () => {
    try {
      const [userData, customerTier] = await Promise.all([
        AdminApi.get(adminApiUrls.users.page(id)),
        AdminApi.get(adminApiUrls.users.customerTier(id))
      ]);
      setData({ ...userData, customerTier });
    } catch (e) {
      Logger.error(e);
      setError(e);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [id]);

  return (
    <MainLayout title="View Applicant" isLoading={!data && !error}>
      {data && <UserDetails data={data} refreshData={fetchUserData} />}
      {error && (
        <Alert
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
        />
      )}
    </MainLayout>
  );
};

export default UserViewPage;
