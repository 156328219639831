import React from 'react';
import * as Yup from 'yup';

export const CSV_INPUT_NAME = 'file';

export const initialValues = {
  [CSV_INPUT_NAME]: null
};

export const csvSchema = Yup.object().shape({
  [CSV_INPUT_NAME]: Yup.mixed().nullable().required('File is required')
});

export const requiredCSVFields = [
  {
    fieldName: 'Number of funded loans',
    dailyFieldFormat: 'integer number',
    weeklyFieldFormat: 'integer number',
    monthlyFieldFormat: 'integer number'
  },
  {
    fieldName: 'Dollars NET collected',
    dailyFieldFormat: 'integer number',
    weeklyFieldFormat: 'integer number',
    monthlyFieldFormat: 'integer number'
  },
  {
    fieldName: 'Return % for leads',
    dailyFieldFormat: 'integer number',
    weeklyFieldFormat: 'integer number',
    monthlyFieldFormat: 'integer number'
  },
  {
    fieldName: 'Loans per agent (average per day)',
    dailyFieldFormat: 'integer number',
    weeklyFieldFormat: 'integer number',
    monthlyFieldFormat: 'integer number'
  }
];

export const tableColumns = ['#', 'Field Name', 'Daily', 'Weekly', 'Monthly'];

const sampleCSVFileFormat = {
  header: ['Name', 'Daily', 'Weekly', 'Monthly'],
  data: [
    ['Number of Funded Loans', 11, 21, 31],
    ['Dollars NET Collected', 101, 201, 301],
    ['Return % for Leads', 101, 201, 301],
    ['Loans per Agent', 1001, 2001, 3001]
  ]
};

export const SampleElement = () => (
  <>
    {sampleCSVFileFormat.header.toString() + ','}
    <br />
    {sampleCSVFileFormat.data.map((item) => (
      <>
        {item.toString() + ','}
        <br />
      </>
    ))}
  </>
);
