import dayjs from 'dayjs';
import * as Yup from 'yup';
import React from 'react';
import { Link } from 'react-router-dom';
import duration from 'dayjs/plugin/duration';
import startCase from 'lodash/startCase';

import { filterOperators, noFieldData } from '@constants/common';
import { cleanObject } from '@utils/object-cleaner';
import routes from '@constants/routes';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import { formatValueWithCurrency } from '@components/shared/DataTable/DataTable.utils';
import { EditIcon } from '@components/shared/icons/Icons';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import helpers from '@utils/helpers';
import ConfirmActionButton from '@components/shared/DataTable/actions/ConfirmActionButton';
import NotYetExecutedMessage from '@components/shared/NotYetExecutedMessage/NotYetExecutedMessage';
import DeleteActionButton from '@components/shared/DataTable/actions/DeleteActionButton';

dayjs.extend(duration);

export const tabNames = {
  payment: 'Payment list',
  file: 'File list'
};

export const editPlannedExecutionTimeSchema = Yup.object().shape({
  date: Yup.date().required('Date is required'),
  time: Yup.date().required('Time is required')
});

const paymentStatuses = [
  { label: 'Success', value: 'Success' },
  { label: 'Failed', value: 'Failed' },
  { label: 'Planned', value: 'Planned' },
  { label: 'Skipped', value: 'Skipped' },
  { label: 'Not Applied', value: 'NotApplied' }
];

const fileStatuses = [
  { label: 'All Finished', value: 'AllFinished' },
  { label: 'Partially Finished', value: 'PartiallyFinished' },
  { label: 'Ongoing', value: 'Ongoing' },
  { label: 'Planned', value: 'Planned' },
  { label: 'Skipped', value: 'Skipped' }
];

const plannedStatus = 'Planned';
const ongoingStatus = 'Ongoing';
const skippedStatus = 'Skipped';

const getStatusBasedTime = (status, defaultValueFormatter) => {
  switch (status) {
    case ongoingStatus:
    case plannedStatus:
      return <NotYetExecutedMessage />;
    case skippedStatus:
      return noFieldData;

    default:
      return defaultValueFormatter();
  }
};

export const getPaymentColumns = () => [
  {
    headerName: 'Planned',
    field: 'paymentDate',
    width: 170,
    type: muiDataTableTypes.dateRange,
    valueFormatter: (date) => helpers.getFormattedDate(date),
    renderCell: ({ row }) => helpers.getFormattedDate(row?.paymentDate),
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Executed',
    field: 'executedDate',
    width: 170,
    type: muiDataTableTypes.dateRange,
    valueFormatter: (date) => helpers.getFormattedDate(date),
    renderCell: ({ row }) =>
      row.paymentStatus === plannedStatus ? (
        <NotYetExecutedMessage />
      ) : (
        helpers.getFormattedDate(row?.executedDate)
      ),
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Runtime',
    field: 'executionTime',
    width: 100,
    renderCell: ({ row }) => {
      const formattedTime = helpers.formatMilliseconds(row.executionTime);
      return formattedTime || <NotYetExecutedMessage />;
    },
    filterable: false
  },
  {
    headerName: 'Amount',
    field: 'amount',
    width: 70,
    valueFormatter: formatValueWithCurrency,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Loan ID',
    field: 'loanId',
    width: 90,
    renderCell: ({ row }) => row.loanId,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Customer ID',
    field: 'customerId',
    width: 100,
    renderCell: ({ row }) => row.customerId,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Provider',
    field: 'paymentProviderName',
    width: 100
  },
  {
    headerName: 'Status',
    field: 'paymentStatus',
    width: 90,
    type: muiDataTableTypes.singleSelect,
    valueOptions: paymentStatuses
  },
  {
    headerName: 'Decline reason',
    field: 'declineReason',
    width: 120,
    renderCell: ({ row }) => (
      <Tooltip title={row.declineReason} cutContentByWidth="auto">
        {row.declineReason}
      </Tooltip>
    )
  },
  {
    headerName: 'Error message',
    field: 'providerErrorMessage',
    width: 110,
    renderCell: ({ row }) => (
      <Tooltip title={row.providerErrorMessage} cutContentByWidth="auto">
        {row.providerErrorMessage}
      </Tooltip>
    )
  },
  {
    headerName: 'Note',
    field: 'note',
    width: 150,
    renderCell: ({ row }) => <Tooltip title={row.note}>{row.note}</Tooltip>
  },
  {
    headerName: 'Proc Reference ID',
    field: 'orderId',
    width: 130,
    type: muiDataTableTypes.number,
    renderCell: ({ row }) => row.orderId || noFieldData
  },
  {
    headerName: 'LMS Reference ID',
    field: 'referenceNumber',
    width: 180,
    renderCell: ({ row }) => row.referenceNumber || noFieldData
  },
  {
    headerName: 'Transaction key',
    field: 'transactionKey',
    width: 160,
    renderCell: ({ row }) => row.transactionKey || noFieldData
  },
  {
    headerName: 'Job number',
    field: 'fireInstanceId',
    width: 160,
    type: muiDataTableTypes.number,
    renderCell: ({ row }) => row.fireInstanceId || noFieldData
  },
  {
    headerName: 'File name',
    field: 'batchProcessing/fileName',
    width: 200,
    renderCell: ({ row }) => row?.batchProcessing?.fileName
  }
];

export const initialPaymentFilterParams = cleanObject({
  'batchProcessing/plannedExecutionTime': {
    ge: null,
    le: null
  },
  updatedDate: {
    ge: null,
    le: null
  },
  amount: '',
  loanId: '',
  customerId: '',
  paymentProviderName: {
    contains: ''
  },
  paymentStatus: {
    contains: ''
  },
  declineReason: {
    contains: ''
  },
  providerErrorMessage: {
    contains: ''
  },
  note: {
    contains: ''
  },
  orderId: '',
  referenceNumber: {
    contains: ''
  },
  transactionKey: {
    contains: ''
  },
  'batchProcessing/fileName': {
    contains: ''
  },
  instanceUid: {
    eq: ''
  }
});

export const getFileColumns = ({ setFileIdToDelete, getModalParams }) => [
  {
    headerName: 'Actions',
    field: 'actions',
    width: 55,
    renderCell: ({ row }) => {
      const executionDate = new Date(row.plannedExecutionTime);
      const isButtonDisabled = new Date() > executionDate;
      return (
        <>
          <Tooltip
            title={
              isButtonDisabled
                ? 'You cannot edit the scheduled run time because the run has already started'
                : 'Edit execution time'
            }
          >
            <ConfirmActionButton
              disabled={isButtonDisabled}
              icon={<EditIcon />}
              modalProps={{
                itemId: row.batchProcessingId,
                content: 'Are you sure to edit?',
                onConfirm: () => {
                  getModalParams({
                    id: row.batchProcessingId,
                    date: row.plannedExecutionTime,
                    fileName: row.fileName
                  });
                }
              }}
            />
          </Tooltip>

          <Tooltip
            title={
              isButtonDisabled &&
              'You cannot delete this file because the run has already started for this file'
            }
          >
            <DeleteActionButton
              itemId={row.batchProcessingId}
              onConfirm={() => setFileIdToDelete(row.batchProcessingId)}
              disabled={isButtonDisabled}
            />
          </Tooltip>
        </>
      );
    }
  },

  {
    headerName: 'Document name',
    field: 'fileName',
    width: 120,
    renderCell: ({ row }) => (
      <Link
        to={{
          pathname: routes.payments.pastDuePayments.paymentsList,
          state: { instanceUid: row.instanceUid }
        }}
      >
        {row.fileName}
      </Link>
    )
  },
  {
    headerName: 'Planned',
    field: 'plannedExecutionTime',
    width: 170,
    type: muiDataTableTypes.dateRange,
    valueFormatter: (date) => helpers.getFormattedDate(date),
    renderCell: ({ row }) => helpers.getFormattedDate(row.plannedExecutionTime),
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Executed',
    field: 'executedDate',
    width: 170,
    type: muiDataTableTypes.dateRange,
    valueFormatter: (date) => helpers.getFormattedDate(date),
    renderCell: ({ row }) =>
      getStatusBasedTime(row.processingStatus, () =>
        helpers.getFormattedDate(row.executedDate)
      ),
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Runtime',
    field: 'executionTime',
    width: 100,
    renderCell: ({ row }) =>
      getStatusBasedTime(row.processingStatus, () =>
        helpers.formatMilliseconds(row.executionTime)
      ),
    filterable: false
  },
  {
    headerName: '# of payments',
    field: 'numberOfRecords',
    width: 120,
    type: muiDataTableTypes.number
  },
  {
    headerName: '# of successful payments',
    field: 'successfulPaymentsCount',
    width: 200,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Status',
    field: 'processingStatus',
    width: 100,
    type: muiDataTableTypes.singleSelect,
    valueOptions: fileStatuses,
    renderCell: ({ row }) => startCase(row.processingStatus)
  },
  {
    headerName: 'User name',
    field: 'userName',
    width: 120
  },
  {
    headerName: 'Document ID',
    field: 'instanceUid',
    width: 200
  }
];

export const initialFileFilterParams = cleanObject({
  plannedExecutionTime: {
    ge: null,
    le: null
  },
  fileName: {
    contains: ''
  },
  createdDate: {
    ge: null,
    le: null
  },
  numberOfRecords: '',
  successfulPaymentsCount: '',
  instanceUid: {
    contains: ''
  }
});

export const subTabsList = [
  {
    id: tabNames.file,
    title: tabNames.file,
    link: routes.payments.pastDuePayments.fileList
  },
  {
    id: tabNames.payment,
    title: tabNames.payment,
    link: routes.payments.pastDuePayments.paymentsList
  }
];

export const filterFormInitialValue = {
  createdDate: [dayjs().startOf('week'), dayjs().endOf('week')]
};
