import React from 'react';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import CustomNotificationStepForm from '@components/CustomNotifications/StepForm';

const CustomNotificationEdit = () => (
  <MainLayout title="Edit Notification">
    <CustomNotificationStepForm />
  </MainLayout>
);

export default CustomNotificationEdit;
