import { useEffect, useState } from 'react';

export const useSimulationProgress = (setExportCSVModal) => {
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [simulationProgress, setSimulationProgress] = useState(0);
  const [totalProgress, setTotalProgress] = useState(
    simulationProgress + downloadProgress / 2
  );

  useEffect(() => {
    setTotalProgress(simulationProgress + downloadProgress / 2);

    if (totalProgress === 100) {
      setTimeout(() => {
        setExportCSVModal(false);
        setTotalProgress(0);
        setDownloadProgress(0);
        setSimulationProgress(0);
      }, 500);
    }
  }, [simulationProgress, downloadProgress, totalProgress]);

  const simulateProgress = (updateInterval = 50) => {
    const progressTimer = setInterval(() => {
      setSimulationProgress((currentProgress) => {
        const increment = Math.random() * 10;
        const newProgress = Math.min(currentProgress + increment, 50);
        if (newProgress === 50) {
          clearInterval(progressTimer);
        }
        return newProgress;
      });
    }, updateInterval);
  };
  return { simulateProgress, setDownloadProgress, totalProgress };
};
