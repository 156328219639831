import styled from '@emotion/styled';
import React from 'react';

import SwitchToggle from '@components/shared/SwitchToggle/SwitchToggle';
import { palette } from '@utils/theme';

const stylesSizes = {
  small: {
    root: {
      width: 30,
      height: 14
    },
    checked: {
      transform: 'translateX(16px)'
    },
    thumb: {
      width: 10,
      height: 10
    }
  },
  medium: {
    root: {
      width: 38,
      height: 18
    },
    checked: {
      transform: 'translateX(20px)'
    },
    thumb: {
      width: 14,
      height: 14
    }
  }
};

export const StyledSwitch = styled((props) => (
  <SwitchToggle
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme, size = 'small' }) => ({
  ...stylesSizes[size].root,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      ...stylesSizes[size].checked,
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: palette.primary,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    ...stylesSizes[size].thumb
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: palette.redCellColor,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));
