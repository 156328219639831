import styled from '@emotion/styled';

export const StyledTableRow = styled.tr`
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;
  background-color: 'red';

  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const StyledTable = styled.table`
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;

  width: 100%;
  border-collapse: collapse;

  border-radius: 16px;
  border-style: hidden;
  height: 75vh;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 12%);
`;

export const StyledTd = styled.td`
  padding: 8px;

  font-size: 24px;

  border: 1px solid #ddd;
  border-right: none;
  border-left: none;

  &:first-child {
    width: 45%;

    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -1px;

    @media only screen and (width <= 1024px) {
      font-size: 20px;
    }

    @media only screen and (width >= 1441px) {
      font-size: 36px;
    }
  }

  @media only screen and (width <= 1024px) {
    font-size: 20px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  @media only screen and (width >= 1441px) {
    font-size: 36px;
  }
`;

export const StyledTh = styled.th`
  padding: 8px;

  font-size: 24px;

  border: 1px solid #ddd;
  border-right: none;
  border-left: none;

  text-align: left;

  background-color: #f2f2f2;

  &:first-child {
    border-top-left-radius: 16px;
  }

  &:last-child {
    border-top-right-radius: 16px;
  }

  @media only screen and (width <= 1024px) {
    font-size: 20px;
  }

  @media only screen and (width >= 1441px) {
    font-size: 36px;
  }
`;
