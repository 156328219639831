import { tabNameMain } from '@constants/comparison';
import routes from '@constants/routes';

export const subTabsListMain = [
  {
    id: tabNameMain.compare,
    title: tabNameMain.compare,
    link: routes.csvCompare.path
  },
  {
    id: tabNameMain.configurations,
    title: tabNameMain.configurations,
    link: routes.csvCompare.configurations.list
  }
];
