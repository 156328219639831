import React from 'react';

import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';
import routes from '@constants/routes';
import { permissionsMap } from '@constants/permissions';
import { cleanObject } from '@utils/object-cleaner';
import helpers from '@utils/helpers';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import Box from '@components/shared/Box/Box';
import toastify from '@utils/toastify';
import Logger from '@utils/logger';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import { filterOperators, noFieldData } from '@constants/common';
import DeleteActionButton from '@components/shared/DataTable/actions/DeleteActionButton';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const notificationScheduleErrMsg = 'NotificationSchedule not found';

export const initialFilterParams = cleanObject({
  jobTitle: {
    contains: ''
  },
  messageTitle: {
    contains: ''
  },
  updatedDate: {
    ge: '',
    le: ''
  }
});

export const tabsList = [
  {
    id: 'tab-0',
    title: 'System Notifications',
    link: routes.notifications.systemNotifications.list
  },
  {
    id: 'tab-1',
    title: 'Custom Notifications',
    link: routes.notifications.customNotifications.list
  }
];

const onSuccess = (id, updatePaginationAfterDelete) => {
  updatePaginationAfterDelete(
    [id],
    (row, arrayOfDeletableIds) =>
      !arrayOfDeletableIds.includes(row.notificationJobId)
  );

  toastify.success({ message: 'Notification deleted' });
};

const handleDeleteNotificationJob = async (id, updatePaginationAfterDelete) => {
  try {
    await AdminApi.delete(adminApiUrls.notificationJob.page(id));

    onSuccess(id, updatePaginationAfterDelete);
  } catch (err) {
    Logger.error(err);
    toastify.error({ message: 'Delete operation failure' });
  }
};

const handleDelete = async (id, updatePaginationAfterDelete) => {
  try {
    await AdminApi.delete(adminApiUrls.notificationSchedules.page(id));
    await AdminApi.delete(adminApiUrls.notificationJob.page(id));

    onSuccess(id, updatePaginationAfterDelete);
  } catch (e) {
    if (e.response.data?.error?.message === notificationScheduleErrMsg) {
      return await handleDeleteNotificationJob(id, updatePaginationAfterDelete);
    } else {
      toastify.error({ message: 'Delete operation failure' });
    }
  }
};

export const getColumns = ({ updatePaginationAfterDelete }) => [
  {
    headerName: 'Action',
    field: 'action',
    width: 80,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <EditActionButton
          path={routes.notifications.customNotifications.page.edit.url(
            row.notificationJobId
          )}
          hidden={
            !helpers.getActionPermission(permissionsMap.notificationJobManage)
          }
        />
        <ShowDetailsActionButton
          path={routes.notifications.customNotifications.page.view.url(
            row.notificationJobId
          )}
        />
        <DeleteActionButton
          hidden={
            !helpers.getActionPermission(permissionsMap.notificationJobManage)
          }
          itemId={row.notificationJobId}
          onConfirm={() =>
            handleDelete(row.notificationJobId, updatePaginationAfterDelete)
          }
        />
      </Box>
    )
  },
  {
    headerName: 'Job title',
    field: 'jobTitle',
    minWidth: 180,
    flex: 1
  },
  {
    headerName: 'Message title',
    field: 'messageTitle',
    minWidth: 130,
    flex: 1
  },
  {
    headerName: 'Number of total sending attempts',
    field: 'notificationJobHistories.sendingAttempts',
    sortable: false,
    minWidth: 130,
    flex: 1,
    filterable: false,
    renderCell: ({ row }) => row?.notificationJobHistories?.[0]?.sendingAttempts
  },
  {
    headerName: 'Number of successful sending attempts',
    field: 'notificationJobHistories.successfulSending',
    sortable: false,
    minWidth: 130,
    flex: 1,
    filterable: false,
    renderCell: ({ row }) =>
      row?.notificationJobHistories?.[0]?.successfulSending
  },
  {
    headerName: 'Last Executed On',
    field: 'createdDate',
    sortable: false,
    minWidth: 200,
    flex: 1,
    renderCell: ({ row }) =>
      row?.notificationJobHistories?.length
        ? helpers.getFormattedDate(
            row?.notificationJobHistories?.[0].createdDate
          )
        : noFieldData,
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Last Modified On',
    field: 'updatedDate',
    minWidth: 200,
    flex: 1,
    valueFormatter: (updatedDate) => helpers.getFormattedDate(updatedDate),
    renderCell: ({ row }) =>
      row?.updatedDate && helpers.getFormattedDate(row.updatedDate),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  }
];
