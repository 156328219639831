import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import qs from 'query-string';

import {
  getColumns,
  initialFilterParams,
  tabsIds,
  tabsList
} from '@components/PaymentsRerun/PaymentsRerun.config';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import Logger from '@utils/logger';
import PaymentProcessingApi from '@utils/paymentProcessingApi';
import { paymentProcessingApiUrls } from '@constants/paymentProcessingApiUrls';

const PaymentsRerunPage = () => {
  const [declineReasonsOptions, setDeclineReasonsOptions] = useState([]);
  const [originalDeclineReasonsOptions, setOriginalDeclineReasonsOptions] =
    useState([]);
  const [paymentsRerunPerformanceId, setPaymentsRerunPerformanceId] =
    useState('');
  const [signal, setSignal] = useState(null);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location?.state?.paymentsRerunPerformanceId) {
      setPaymentsRerunPerformanceId(location.state.paymentsRerunPerformanceId);
    }
  }, [location]);

  useEffect(() => {
    const controller = new AbortController();
    setSignal(controller.signal);
    if (paymentsRerunPerformanceId) {
      history.push({
        pathname: history.location.pathname,
        search: qs.stringify({
          ...qs.parse(history.location.search),
          $filter: JSON.stringify({
            paymentsRerunPerformanceId: { eq: paymentsRerunPerformanceId }
          }),
          $skip: 0
        })
      });
    }
    return () => {
      controller.abort();
    };
  }, [paymentsRerunPerformanceId]);

  useEffect(() => {
    (async () => {
      try {
        const [declineReasons, originalDeclineReasons] = await Promise.all([
          PaymentProcessingApi.get(
            paymentProcessingApiUrls.declineReasons.list
          ),
          PaymentProcessingApi.get(
            paymentProcessingApiUrls.common.originalDeclineReasons
          )
        ]);

        setDeclineReasonsOptions(
          declineReasons?.value?.map((item) => ({
            value: item.declineReasonId,
            label: item.declineReasonName
          }))
        );
        setOriginalDeclineReasonsOptions(
          createOptionsForSelect(originalDeclineReasons)
        );
      } catch (error) {
        Logger.error(error);
      }
    })();
  }, []);

  const createOptionsForSelect = (data) =>
    data?.items?.map(({ value }) => ({
      label: value.trim(),
      value: value.trim()
    })) || [];

  const getPaymentsRerunColumns = useCallback(
    () => getColumns({ declineReasonsOptions, originalDeclineReasonsOptions }),
    [declineReasonsOptions, originalDeclineReasonsOptions]
  );

  return (
    <MainLayout title="Payments Rerun">
      <NavTabsPanel
        currentId={tabsIds.list}
        tabsList={tabsList}
        ariaLabel="lead-provider-tabs"
      />
      <ODataPaginatedTable
        signal={signal}
        getColumns={getPaymentsRerunColumns}
        usePaginatedDataOptions={{
          apiUrl: paymentProcessingApiUrls.paymentsRerun.list,
          initialFilterParams: {
            $orderBy: 'reversedPaymentId desc',
            $expand: 'DeclineReason',
            $filter: {
              ...initialFilterParams
            }
          }
        }}
        rowKey="reversedPaymentId"
        customApi={PaymentProcessingApi}
      />
    </MainLayout>
  );
};

export default PaymentsRerunPage;
