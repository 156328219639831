import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';

import { systemServicesStatuses } from '@constants/common';

export const getSystemStatus = (servicesStatuses) => {
  if (!servicesStatuses) return null;
  return servicesStatuses.every(
    ({ status }) => status === systemServicesStatuses.active
  )
    ? systemServicesStatuses.active
    : systemServicesStatuses.failed;
};

export const getSystemStatusStyles = (systemStatus) => {
  if (systemStatus === null) {
    return {
      color: 'inherit',
      '&:hover': {
        color: 'inherit'
      }
    };
  }
  if (systemStatus === systemServicesStatuses.active) {
    return {
      color: green[500],
      '&:hover': {
        color: green[500]
      }
    };
  }
  return {
    color: red[500],
    '&:hover': {
      color: red[500]
    },
    textShadow: `0 0 10px ${red[500]}`,
    animation: 'blinking 1s infinite',
    '@keyframes blinking': {
      '0%': {
        textShadow: 'none'
      },
      '50%': {
        textShadow: `0 0 10px ${red[500]}`
      },
      '100%': {
        textShadow: 'none'
      }
    }
  };
};
