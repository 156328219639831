import React from 'react';
import PT from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

import { StyledButton } from './styled';

const Button = ({
  children,
  loading,
  tooltipProps,
  filterVariant,
  title,
  ...rest
}) => {
  const Wrapper = tooltipProps ? Tooltip : React.Fragment;
  const filterVariantProps = filterVariant
    ? {
        variant: 'text'
      }
    : {};
  return (
    <Wrapper {...tooltipProps}>
      <StyledButton
        endIcon={
          loading ? <CircularProgress size={24} color="grey" /> : rest.endIcon
        }
        {...rest}
        filterVariant={filterVariant}
        {...filterVariantProps}
      >
        {title || children}
      </StyledButton>
    </Wrapper>
  );
};

Button.defaultProps = {
  type: 'button',
  variant: 'contained',
  color: 'primary'
};

export const propTypes = {
  children: PT.node,
  title: PT.string,
  onClick: PT.func,
  variant: PT.oneOf(['text', 'contained', 'outlined']),
  type: PT.oneOf(['button', 'submit', 'reset']),
  size: PT.oneOf(['mini', 'small', 'medium', 'large']),
  icon: PT.node,
  disabled: PT.bool,
  loading: PT.bool,
  color: PT.string,
  endIcon: PT.node,
  className: PT.string,
  tooltipProps: PT.object,
  minWidth: PT.string,
  filterVariant: PT.bool
};
Button.propTypes = propTypes;

export default Button;
