import { totalValueCellClassName } from '@components/shared/DataTable/DataTable.constants';

const highlightedCellClassName = ({ row }) =>
  row.nameStatisticsRow === 'Total' && totalValueCellClassName;

export const columns = [
  {
    headerName: 'Type',
    field: 'nameStatisticsRow',
    minWidth: 180,
    flex: 1,
    filterable: false,
    sorter: (a, b) => a.nameStatisticsRow.localeCompare(b.nameStatisticsRow),
    cellClassName: highlightedCellClassName
  },
  {
    headerName: 'Accepted leads',
    field: 'acceptedLeads',
    type: 'number',
    minWidth: 120,
    flex: 1,
    filterable: false,
    cellClassName: highlightedCellClassName
  },
  {
    headerName: 'Declined leads',
    field: 'declinedLeads',
    type: 'number',
    minWidth: 120,
    flex: 1,
    filterable: false,
    cellClassName: highlightedCellClassName
  },
  {
    headerName: 'Total leads',
    field: 'totalLeads',
    type: 'number',
    minWidth: 100,
    flex: 1,
    filterable: false,
    cellClassName: highlightedCellClassName
  },
  {
    headerName: 'Accepted leads rate',
    field: 'acceptedLeadsRate',
    type: 'number',
    minWidth: 160,
    flex: 1,
    filterable: false,
    cellClassName: highlightedCellClassName
  },
  {
    headerName: 'Signed loans',
    field: 'signedLoans',
    type: 'number',
    minWidth: 100,
    flex: 1,
    filterable: false,
    cellClassName: highlightedCellClassName
  },
  {
    headerName: 'Not Signed loans',
    field: 'notSignedLoans',
    type: 'number',
    minWidth: 140,
    flex: 1,
    filterable: false,
    cellClassName: highlightedCellClassName
  },
  {
    headerName: 'Total loans amount',
    field: 'totalLoansAmount',
    type: 'number',
    minWidth: 120,
    flex: 1,
    filterable: false,
    cellClassName: highlightedCellClassName
  },
  {
    headerName: 'Total signed loans amounts',
    field: 'totalSignedLoansAmount',
    type: 'number',
    minWidth: 180,
    flex: 1,
    filterable: false,
    cellClassName: highlightedCellClassName
  }
];
