import React, { useEffect, useState } from 'react';
import PT from 'prop-types';

import { intersection, not } from './TransferList.utils';
import { SideList } from './TransferList.components';

import Stack from '@components/shared/Stack/Stack';
import Box from '@components/shared/Box/Box';
import Grid from '@components/shared/Grid/Grid';
import Button from '@components/shared/Button/Button';
import {
  ArrowBackIcon,
  ArrowForwardIcon
} from '@components/shared/icons/Icons';

const TransferList = ({
  choicesProps,
  chosenProps,
  onSelectChange,
  paginationProps,
  loading
}) => {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(choicesProps?.list);
  const [right, setRight] = useState(chosenProps?.list);

  useEffect(() => {
    choicesProps?.list && setLeft(choicesProps?.list);
  }, [choicesProps?.list, paginationProps?.onChange]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleCheckedRight = () => {
    const rightList = right.concat(leftChecked).sort((a, b) => a.key - b.key);

    setRight(rightList);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));

    onSelectChange?.(rightList);
    paginationProps?.onChange(
      paginationProps.current,
      paginationProps.pageSize
    );
  };

  const handleCheckedLeft = () => {
    const leftList = left.concat(rightChecked).sort((a, b) => a.key - b.key);

    setLeft(leftList);
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));

    onSelectChange?.(not(right, rightChecked));
    paginationProps?.onChange(
      paginationProps.current,
      paginationProps.pageSize
    );
  };

  return (
    <Stack
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        gridGap: '10px',
        alignItems: 'center'
      }}
    >
      <Box>
        <SideList
          title={choicesProps.title}
          list={choicesProps.searchResults || left}
          checkedList={checked}
          setCheckedList={setChecked}
          loading={loading}
          paginationProps={paginationProps}
          searchTerm={choicesProps.searchTerm}
          setSearchTerm={choicesProps.setSearchTerm}
          choicesSide
        />
      </Box>
      <Grid item>
        <Stack gap={1}>
          <Button
            variant="outlined"
            size="mini"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <ArrowForwardIcon size={14} />
          </Button>
          <Button
            variant="outlined"
            size="mini"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <ArrowBackIcon size={14} />
          </Button>
        </Stack>
      </Grid>
      <Box height="100%">
        <SideList
          title={chosenProps.title}
          list={chosenProps.searchResults || right}
          checkedList={checked}
          setCheckedList={setChecked}
        />
      </Box>
    </Stack>
  );
};

const itemPropTypes = PT.shape({
  key: PT.number.isRequired,
  name: PT.string.isRequired
});
const listPropTypes = PT.shape({
  title: PT.string,
  list: PT.arrayOf(itemPropTypes),
  searchResults: PT.arrayOf(itemPropTypes),
  handleSearch: PT.func,
  searchTerm: PT.string,
  setSearchTerm: PT.func
});

TransferList.defaultProps = {
  chosenList: [],
  choicesTitle: 'Choices',
  chosenTitle: 'Chosen'
};

TransferList.propTypes = {
  choicesProps: listPropTypes.isRequired,
  chosenProps: listPropTypes,
  onSelectChange: PT.func.isRequired,
  paginationProps: PT.shape({
    onChange: PT.func,
    current: PT.number,
    pageSize: PT.number,
    total: PT.number
  }),
  loading: PT.bool
};

export default TransferList;
