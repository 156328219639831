import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { ArrowForwardIcon } from '@components/shared/icons/Icons';

export const StyledAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

export const StyledAccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIcon size={15} />} {...props} />
))(() => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  minHeight: 35,
  '& .MuiAccordionSummary-content': {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 8
  }
}));

export const StyledAccordionDetails = styled(MuiAccordionDetails)(
  ({ noPadding }) => ({
    padding: noPadding ? 0 : 16,
    borderTop: '1px solid rgba(0, 0, 0, .125)'
  })
);
