import React, { memo } from 'react';
import PT from 'prop-types';

import { StyledDrawer, StyledDrawerHeader, StyledList } from './styled';
import SidebarItemCollapse from './SidebarItemCollapse';
import SidebarItem from './SidebarItem';

import { ArrowForwardIcon } from '@components/shared/icons/Icons';
import IconButton from '@components/shared/IconButton/IconButton';
import { mainMenu } from '@constants/common';
import helpers from '@utils/helpers';
import Permissions from '@utils/permissions';

const MenuIconButton = ({ isOpen, onToggleSidebar }) => (
  <IconButton onClick={onToggleSidebar}>
    <ArrowForwardIcon
      sx={{
        transform: `scaleX(${isOpen ? '-1' : '1'})`,
        transition: 'transform 150ms ease-in-out'
      }}
    />
  </IconButton>
);
MenuIconButton.propTypes = {
  isOpen: PT.bool.isRequired,
  onToggleSidebar: PT.func.isRequired
};

const SidebarMenu = ({ isOpen, onToggleSidebar }) => {
  const policies = Permissions.get();

  const checkPolicyForMenuItem = (menuItem) => {
    if (policies && menuItem) {
      return helpers.checkPolicies(policies, menuItem);
    }
    return true;
  };

  return (
    <StyledDrawer variant="permanent" open={isOpen}>
      <StyledDrawerHeader>
        <MenuIconButton isOpen={isOpen} onToggleSidebar={onToggleSidebar} />
      </StyledDrawerHeader>
      <StyledList>
        {mainMenu.map((itemData, idx) => {
          const isPermissionExist = checkPolicyForMenuItem(itemData);
          if (isPermissionExist) {
            return itemData.children ? (
              <SidebarItemCollapse
                item={itemData}
                isSidebarOpen={isOpen}
                key={idx}
              />
            ) : (
              <SidebarItem isSidebarOpen={isOpen} item={itemData} key={idx} />
            );
          }
        })}
      </StyledList>
    </StyledDrawer>
  );
};

SidebarMenu.propTypes = {
  isOpen: PT.bool.isRequired,
  onToggleSidebar: PT.func.isRequired
};

export default memo(SidebarMenu);
