/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import qs from 'query-string';

import routes from '@constants/routes';
import { routesPermissionsMap } from '@constants/common';
import helpers from '@utils/helpers';
import { authService } from '@services/auth';
import Permissions from '@utils/permissions';

export const PrivateRoute = (props) => {
  const history = useHistory();
  const loginRedirectPath = useMemo(
    () => helpers.getLoginRedirectUrl(history.location.pathname),
    [history.location.pathname]
  );

  const policies = Permissions.get();
  if (policies) {
    const isPermissionExist = helpers.checkPolicies(
      policies,
      routesPermissionsMap[props.path]
    );
    if (!isPermissionExist) {
      return <Redirect to={routes.permissionDenied} />;
    }
  }
  return authService.isTokenSet() ? (
    <Route {...props} />
  ) : (
    <Redirect to={loginRedirectPath} />
  );
};

export const AuthRoute = (props) => {
  const history = useHistory();
  const isLogout = qs.parse(history.location.search).logout;

  if (isLogout) {
    authService.onTokenChange(null);
    authService.redirectToAuthorize();
    return;
  }

  return !authService.getToken() ? (
    <Route {...props} />
  ) : (
    <Redirect to={routes.applications.list} />
  );
};
