import React from 'react';
import PT from 'prop-types';
import { Form, Formik } from 'formik';

import Button from '@components/shared/Button/Button';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Box from '@components/shared/Box/Box';
import { fieldsMasks } from '@constants/common';
import filterHelpers from '@utils/filterHelpers';

const getGridForField = () => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 65px',
  alignItems: 'end'
});

const ExpDateFilter = ({ item, applyValue, fieldName }) => {
  const parsedFilters = filterHelpers.parseQueryFilters();

  const initialValues = {
    [fieldName]: parsedFilters[fieldName]?.eq || ''
  };

  const handleSubmit = (values, { setSubmitting }) => {
    applyValue({
      ...item,
      value: `${values?.[fieldName]?.slice(0, 2)}/${values?.[fieldName]?.slice(
        2
      )}`
    });

    setSubmitting(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Form style={{ marginTop: '16px' }}>
        <Box sx={getGridForField}>
          <FormikField
            type="mask"
            name="ccExpDate"
            mask={fieldsMasks.cardExpire.mask}
            filterVariant
            variant="standard"
            sx={{ width: 90 }}
          />
          <Box style={{ marginBottom: 6, marginLeft: 'auto' }}>
            <Button type="submit" variant="text" filterVariant>
              Apply
            </Button>
          </Box>
        </Box>
      </Form>
    </Formik>
  );
};

ExpDateFilter.propTypes = {
  item: PT.any,
  applyValue: PT.func,
  fieldName: PT.string
};

export default ExpDateFilter;
