import React from 'react';
import PT from 'prop-types';
import { Formik, Form } from 'formik';

import {
  campaignFieldsSchema,
  campaignTypeOptions,
  costTypeOptions,
  getInitialValues,
  priceRejectValue
} from './schema';

import Alert from '@components/shared/Alert/Alert';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import LoanFormControls from '@components/Application/LoanForm/LoanFormControls';
import Box from '@components/shared/Box/Box';
import ConfirmDeleteButton from '@components/shared/ConfirmDeleteButton/ConfirmDeleteButton';

const CampaignForm = ({ onSubmit, data, editMode, onDelete }) => {
  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);

    onSubmit(
      { values, actions },
      {
        onFinish: () => {
          actions.setSubmitting(false);
        }
      }
    );
  };

  const isClearCostHandler = (value) => value === priceRejectValue;

  return (
    <Formik
      initialValues={getInitialValues(data)}
      onSubmit={handleSubmit}
      validationSchema={campaignFieldsSchema}
      validateOnChange={false}
      validateOnBlur
    >
      {({ values, errors, setFieldValue }) => (
        <Form>
          <Box width="100%" maxWidth="500px">
            <FormikField
              type="text"
              name="campaignName"
              label="Campaign:"
              placeholder="Campaign"
              autoFocus
            />
            <FormikField type="checkbox" name="status" label="Enabled" />
            <FormikField
              type="text"
              name="storeKey"
              label="Store name:"
              placeholder="Store name"
            />
            <FormikField
              type="number"
              name="hourlyLookCap"
              label="Hr look cap:"
              placeholder="Hr look cap"
            />
            <FormikField
              type="number"
              name="dailyLookCap"
              label="Day Look cap:"
              placeholder="Day Look cap"
            />
            <FormikField
              type="number"
              name="dailyAcceptCap"
              label="Day accept cap:"
              placeholder="Day accept cap"
            />
            <FormikField
              type="number"
              name="hourlyAcceptCap"
              label="Hr accept cap:"
              placeholder="Hr accept cap"
            />
            <FormikField
              type="number"
              name="dailyCostCap"
              label="Day cost cap:"
              placeholder="Day cost cap"
            />
            <FormikField
              type="number"
              name="cost"
              label="Cost:"
              placeholder="Cost"
              disabled={values.providerType === priceRejectValue}
            />
            <FormikField
              type="select"
              name="providerType"
              label="Cost type"
              placeholder="Cost type"
              options={costTypeOptions}
              onChange={(e) => {
                isClearCostHandler(e.target.value) && setFieldValue('cost', 0);
              }}
            />
            <FormikField
              type="text"
              name="underwritingKey"
              label="Underwriting key:"
              placeholder="Underwriting key"
            />
            <FormikField
              type="text"
              name="lmsLogin"
              label="LMS login:"
              placeholder="LMS login"
            />
            <FormikField
              type="password"
              name="lmsPassword"
              label="LMS password:"
              placeholder="LMS password"
            />
            <FormikField
              type="select"
              name="strategyId"
              label="Campaign type"
              placeholder="Campaign type"
              options={campaignTypeOptions}
            />

            {errors.nonFieldErrors && (
              <Alert
                message={errors.nonFieldErrors}
                type="error"
                className="mb-3"
              />
            )}
          </Box>
          <LoanFormControls
            additionalControls={
              editMode && (
                <ConfirmDeleteButton
                  itemName="campaign"
                  handleDelete={onDelete}
                />
              )
            }
          />
        </Form>
      )}
    </Formik>
  );
};

CampaignForm.defaultProps = {
  data: {},
  editMode: false
};

CampaignForm.propTypes = {
  onSubmit: PT.func,
  data: PT.object,
  editMode: PT.bool,
  onDelete: PT.func
};

export default CampaignForm;
