/* eslint-disable react/prop-types */
import React from 'react';
import MuiStepper from '@mui/material/Stepper';
import MuiStepLabel from '@mui/material/StepLabel';
import MuiStepContent from '@mui/material/StepContent';

import { StyledStep } from './styled';

export const Stepper = (props) => <MuiStepper {...props} />;
export const Step = (props) => <StyledStep {...props} sx={{ ...props.sx }} />;
export const StepLabel = (props) => <MuiStepLabel {...props} />;
export const StepContent = (props) => <MuiStepContent {...props} />;
