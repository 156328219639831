import React, { useState } from 'react';
import PT from 'prop-types';
import { uniqBy } from 'lodash';

import { FormAutocomplete } from '@components/shared/Form/Select/Select';

const FilterByOptions = ({
  options: _options,
  data,
  item,
  applyValue,
  filterField
}) => {
  const [value, setValue] = useState(item?.value || '');

  const options = uniqBy(
    data?.map((item) => {
      if (item[filterField]) {
        return {
          label: item[filterField],
          value: item[filterField]
        };
      }
    }),
    'label'
  ).filter(Boolean);

  const handleFilterUpdate = (newValue) => {
    setValue(newValue);
    return applyValue({ ...item, value: newValue });
  };

  return (
    <FormAutocomplete
      name={item[filterField]}
      size="small"
      options={_options || options || []}
      variant="standard"
      value={value}
      sx={{ mt: '14px', mb: '4px' }}
      onChange={(_, newValue) => handleFilterUpdate(newValue?.value)}
    />
  );
};

FilterByOptions.propTypes = {
  options: PT.array,
  data: PT.array,
  item: PT.object,
  applyValue: PT.func,
  filterField: PT.string
};

export default FilterByOptions;
