import capitalize from 'lodash/capitalize';
import mapValues from 'lodash/mapValues';
import React, { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router';
import qs from 'query-string';
import dayjs from 'dayjs';

import { cleanObject } from '@utils/object-cleaner';
import { booleanFilterFieldOptions, fieldWidth } from '@constants/filters';
import { dataTypesOptions } from '@constants/logsDataTypes';
import { groupTypesOptions } from '@constants/logsGroupTypes';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';

export const baseInitialValues = {
  logGroup: '',
  success: '',
  FormData: {
    any: { dataType: '' }
  },
  deviceId: '',
  period: ''
};

const periodTypes = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year'
};
const periods = mapValues(
  periodTypes,
  (value) => () => dayjs().subtract(1, value)
);
const periodTypesOptions = Object.keys(periodTypes).map((value) => ({
  value,
  label: capitalize(value)
}));

const LoggedEventsFilter = () => {
  const history = useHistory();
  const [periodType, setPeriodType] = useState(periodTypes.day);

  const handleSubmit = (values, setSubmitting) => {
    handleFilterUpdate(values);
    setPeriodType(values.period);
    setSubmitting(false);
  };

  const updFilterData = useMemo(() => {
    const { $filter } = qs.parse(history.location.search);
    const initialFilters = {
      ...baseInitialValues,
      timeStamp: {
        ge: dayjs().subtract(1, periodTypes.day),
        le: dayjs()
      },
      period: periodType
    };

    if ($filter) {
      const parsedFilters = JSON.parse($filter);
      const values = {};
      Object.keys(initialFilters).forEach((item) => {
        if (Object.keys(parsedFilters).includes(item)) {
          values[item] = parsedFilters[item];
        }
      });
      return {
        ...initialFilters,
        ...values
      };
    }
    return initialFilters;
  }, [history.location.search, periodType]);

  const handleFilterUpdate = (values) => {
    const clearedValues = cleanObject(values) || {};
    if (clearedValues.period) {
      const ge = periods[clearedValues.period]();
      delete clearedValues.period;
      clearedValues.timeStamp = {
        ge,
        le: dayjs()
      };
    }
    history.push({
      pathname: history.location.pathname,
      search: qs.stringify({
        ...qs.parse(history.location.search),
        $filter: JSON.stringify(clearedValues),
        $skip: 0
      })
    });
  };

  let initialValues = updFilterData;

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      {({ values, setSubmitting }) => (
        <>
          <Form className="page-filter">
            <Stack direction="row" gap={1} alignItems="stretch" flexWrap="wrap">
              <FormikField
                type="select"
                name="success"
                label="Successful request"
                size="small"
                options={booleanFilterFieldOptions}
                sx={fieldWidth}
              />
              <FormikField
                type="select"
                name="logGroup"
                label="Group"
                size="small"
                options={groupTypesOptions}
                sx={fieldWidth}
              />

              <FormikField
                type="select"
                name="FormData.any.dataType"
                label="Data type"
                size="small"
                options={dataTypesOptions}
                sx={fieldWidth}
              />

              <FormikField
                type="text"
                name="deviceId"
                label="Device ID"
                size="small"
                placeholder="Enter device Id"
                sx={fieldWidth}
              />

              <FormikField
                type="select"
                name="period"
                label="Select period"
                size="small"
                options={periodTypesOptions}
                sx={fieldWidth}
              />
              <Button
                onClick={() => handleSubmit(values, setSubmitting)}
                type="submit"
                sx={{ mt: '5px', mb: '4px' }}
              >
                Apply
              </Button>
            </Stack>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default LoggedEventsFilter;
