import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';

import { columns } from './config';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import DataTable from '@components/shared/DataTable/DataTable';
import DenialReasonReportsFilterForm from '@components/LeadReports/filters/DenialReasonReportsFilterForm/DenialReasonReportsFilterForm';
import { useServerData } from '@hooks/useServerData';
import { leadManagementReportsApiUrls } from '@constants/leadManagementReportsApi';
import LeadManagementReportsApi from '@utils/leadManagementReportsApi';

const DenialReasonsReportPage = () => {
  const history = useHistory();
  const params = qs.parse(history.location.search);

  const { data, isLoading, error, fetchData } = useServerData({
    endpoint: async () =>
      await LeadManagementReportsApi.get(
        leadManagementReportsApiUrls.denialReasonReport,
        {
          params
        }
      )
  });

  useEffect(() => {
    if (!isEmpty(params)) {
      fetchData();
    }
  }, [history.location.search]);

  return (
    <MainLayout title="Denial Reason Report">
      <DenialReasonReportsFilterForm />
      <DataTable
        columns={columns}
        tableData={data}
        rowKey="id"
        loading={isLoading}
        error={error}
        pagination={false}
        getRowHeight={() => 'auto'}
        initialParams={{
          columns: { columnVisibilityModel: { deniedBy: false } }, // TODO: remove this line when the backend fixes the issue
          aggregation: {
            model: {
              count: 'sum',
              percentageTotal: 'sum'
            }
          }
        }}
        getAggregationPosition={(groupNode) =>
          groupNode.childrenExpanded ? 'footer' : 'inline'
        }
        exportCsvProps={{ allColumns: true, fileName: 'Denial_Reasons_Report' }}
        disableRowSelectionOnClick
        hideAggregationInColumnMenu
        hideFooter
      />
    </MainLayout>
  );
};

export default DenialReasonsReportPage;
