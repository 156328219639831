import React from 'react';
import PT from 'prop-types';

import Stack from '../Stack/Stack';

import Box from '@components/shared/Box/Box';

export const ActionsBox = ({ children, ...props }) => (
  <Box mt={1} display="inline-flex" gap={1} {...props}>
    {children}
  </Box>
);

ActionsBox.propTypes = {
  children: PT.any.isRequired
};

export const ActionsSection = ({ children, ...props }) => (
  <Box
    mt={1}
    display="flex"
    verticalAlign="top"
    alignItems="flex-start"
    {...props}
  >
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      gap={1}
      width="100%"
      flexWrap="wrap"
    >
      {children}
    </Stack>
  </Box>
);

ActionsSection.propTypes = {
  children: PT.any.isRequired
};
