import React, { useEffect, memo, useState } from 'react';
import qs from 'query-string';
import { useParams } from 'react-router-dom';

import LeadForm from '@components/Leads/leadProviders/Form';
import LeadSteps from '@components/Leads/leadProviders/Steps';
import Grid from '@components/shared/Grid/Grid';
import { leadFormStepsOrder } from '@constants/leadFormConfig';
import { workingCalendarServerData } from '@components/Leads/leadProviders/Form/WorkingCalendarStep/schema';
import Logger from '@utils/logger';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

const LeadProvideStepForm = () => {
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(null);
  const [leadData, setLeadData] = useState(null);
  const [leadScheduleData, setLeadScheduleData] = useState(null);
  const [lastLeadFilledStep, setLastLeadFilledStep] = useState(null);

  useEffect(() => {
    const { leadId } = qs.parse(history.location?.search);
    if (!id && !leadId) {
      setActiveStep(leadFormStepsOrder[0]);
    } else {
      (async () => {
        try {
          const serverInfoPageData = await LeadManagementApi.get(
            leadManagementApiUrls.leadProviders.page(id || leadId)
          );
          setLeadData(serverInfoPageData);

          if (serverInfoPageData.weeklyScheduleId) {
            const serverScheduleData = await LeadManagementApi.get(
              leadManagementApiUrls.weeklySchedules.page(
                serverInfoPageData.weeklyScheduleId
              ),
              {
                params: {
                  $expand: 'WorkDays'
                }
              }
            );

            setLeadScheduleData({
              workDays: workingCalendarServerData.from(
                serverScheduleData.workDays
              )
            });
          }
          setLastLeadFilledStep(
            leadFormStepsOrder[leadFormStepsOrder?.length - 1]
          );
          setActiveStep(
            leadId ? 0 : leadFormStepsOrder[leadFormStepsOrder?.length - 1]
          );
        } catch (e) {
          Logger.error(e);
        }
      })();
    }
  }, [id, history.location?.search]);

  return (
    <Grid container gap={1}>
      <Grid item xs={12} sm={3} md={2}>
        <LeadSteps
          activeStep={activeStep}
          lastLeadFilledStep={lastLeadFilledStep}
          setActiveStep={setActiveStep}
          leadData={leadData}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={9}>
        <LeadForm
          id={id}
          activeStep={activeStep}
          leadData={leadData}
          leadScheduleData={leadScheduleData}
          setLastLeadFilledStep={setLastLeadFilledStep}
          setLeadData={setLeadData}
          setLeadScheduleData={setLeadScheduleData}
          setActiveStep={setActiveStep}
        />
      </Grid>
    </Grid>
  );
};

export default memo(LeadProvideStepForm);
