import React, { useMemo } from 'react';
import PT from 'prop-types';

import { bankStepInitialValues, bankStepSchema } from './schema';
import BankExample from './BankExample';

import FormikField from '@components/shared/Form/FormikField/FormikField';
import { fieldsMasks } from '@constants/common';
import { monthsOptions, yearsOptions } from '@constants/monthsOptions';
import withStepData from '@components/Application/LoanForm/withStepData';
import StepContainer from '@components/Application/LoanForm/StepContainer';

const accountTypeOptions = [
  { label: 'Checking', value: 'Checking' },
  { label: 'Saving', value: 'Saving' }
];

const BankStep = ({ onSubmit, initialValues }) => {
  const mergedInitialValues = useMemo(
    () => ({
      ...bankStepInitialValues,
      ...initialValues
    }),
    [initialValues]
  );

  const handleSubmit = (values, handlers) =>
    onSubmit({ values, initialValues: mergedInitialValues }, handlers);

  return (
    <StepContainer
      formSchema={bankStepSchema}
      initialValues={mergedInitialValues}
      onSubmit={handleSubmit}
      title="Bank"
    >
      <BankExample />
      <FormikField
        type="text"
        name="bankName"
        label="Bank name:"
        placeholder="e.g. Bank of America"
      />
      <FormikField
        type="mask"
        name="bankRoutingNumber"
        label="Bank routing number:"
        placeholder="XXX XXX XXX"
        mask="999 999 999"
        maskChar="X"
        plainValue
        fullWidth
      />
      <FormikField
        type="autocomplete"
        name="accountType"
        label="Account Type:"
        placeholder="Select"
        options={accountTypeOptions}
      />
      <FormikField
        type="text"
        name="account"
        label="Account number:"
        placeholder="XXXXXXXXXXXXXXXX"
      />
      <FormikField
        type="text"
        name="accountBalance"
        label="Account balance:"
        placeholder="e.g. 1000"
      />
      <FormikField
        type="autocomplete"
        name="timeAccountOpenYears"
        label="Years at bank:"
        placeholder="Select"
        options={yearsOptions}
      />
      <FormikField
        type="autocomplete"
        name="timeAccountOpenMonths"
        label="Months at bank:"
        placeholder="Select"
        options={monthsOptions}
      />
      <FormikField type="checkbox" name="hasDC" label="Have a debit card" />
      <FormikField
        type="checkbox"
        name="bankruptcyStatus"
        label="Have filed for bankruptcy"
      />
      <FormikField
        type="mask"
        name="bankPhone"
        label="Bank Phone:"
        placeholder={fieldsMasks.phoneUSA.placeholder}
        mask={fieldsMasks.phoneUSA.mask}
        maskChar="X"
        plainValue
        fullWidth
      />
    </StepContainer>
  );
};

BankStep.propTypes = {
  onSubmit: PT.func.isRequired,
  initialValues: PT.object.isRequired
};

export default withStepData(BankStep);
