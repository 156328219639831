const binCheckerApiBaseUrl = process.env.BIN_CHECKER_API_URL || '';

const binCheckerApiUrls = {
  bankBinNumbers: {
    list: '/odata/BankBinNumbers',
    item: (bankBinNumberIdKey) => `/odata/BankBinNumbers/${bankBinNumberIdKey}`
  },
  bankRoutingNumbers: {
    list: '/odata/BankRoutingNumbers',
    item: (bankRoutingNumberId) =>
      `/odata/BankRoutingNumbers/${bankRoutingNumberId}`
  },
  options: {
    ccBrands: '/api/Options/credit-card-brands',
    ccTypes: '/api/Options/credit-card-types',
    ccCurrencies: '/api/Options/credit-card-currencies',
    ccCountries: '/api/Options/credit-card-countries',
    ccSources: '/api/Options/bank-routing-sources'
  }
};

export { binCheckerApiUrls, binCheckerApiBaseUrl };
