import { styled } from '@mui/material/styles';

export const StyledWrapper = styled('div')`
  && {
    margin-top: 10px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & .empty-img-1 {
      fill: #aeb8c2;
    }

    & .empty-img-2 {
      fill: #f5f5f7;
    }

    & .empty-img-3 {
      fill: #dce0e6;
    }

    & .empty-img-4 {
      fill: #fff;
    }

    & .empty-img-5 {
      fill-opacity: 0.8;
      fill: #f5f5f5;
    }
  }
`;
