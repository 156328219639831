import React, { useEffect, useState } from 'react';

import { Table } from '@components/Wallboard/Table/Table';
import { WallboardLayout } from '@components/Wallboard/WallboardLayout/WallboardLayout';
import WallboardApi from '@utils/wallboardAPi';
import { wallboardApiUrls } from '@constants/wallboardApiUrls';
import Logger from '@utils/logger';
import Loader from '@components/shared/Loader';
import { loanProcessingColumns } from '@components/Wallboard/config';
import { useSetWallboardToken } from '@hooks/useSetWallboardToken';

export const LoanProcessing = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useSetWallboardToken();

  const getData = async () => {
    try {
      setLoading(true);
      const response = await WallboardApi.get(wallboardApiUrls.loanProcessing);
      setData(response);
    } catch (error) {
      Logger.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <WallboardLayout title="Loan Processing Last 3 Months">
      {loading && <Loader />}
      {!loading && (
        <Table data={data ?? undefined} columns={loanProcessingColumns} />
      )}
    </WallboardLayout>
  );
};
