import React from 'react';

import { getColumns, initialFilterParams } from './config';

import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const SystemNotificationsListPage = () => (
  <MainLayout title="System Notifications">
    <ODataPaginatedTable
      getColumns={getColumns}
      usePaginatedDataOptions={{
        apiUrl: adminApiUrls.notificationJob.list,
        initialFilterParams: {
          $orderBy: 'createdDate desc',
          $filter: {
            ...initialFilterParams,
            IsCustom: {
              eq: false
            }
          },
          $expand: 'NotificationJobHistories($orderby=createdDate desc;$top=1)'
        }
      }}
      rowKey="notificationJobId"
      exportExcludeFields={['sendingAttempts', 'successfulSending']}
      customApi={AdminApi}
    />
  </MainLayout>
);
export default SystemNotificationsListPage;
