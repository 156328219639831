import sumBy from 'lodash/sumBy';

export const getAppRatePercentage = (data) => {
  const looks = sumBy(data, 'looks');
  const accepted = sumBy(data, 'accepted');
  if (looks === 0) return 0;
  return (accepted / looks) * 100;
};

export const getFundRatePercentage = (data) => {
  const looks = sumBy(data, 'looks');
  const loanApproved = sumBy(data, 'loanApproved');
  if (looks === 0) return 0;
  return (loanApproved / looks) * 100;
};

export const getDuplicateLeadsPercentage = (data) => {
  const looks = sumBy(data, 'looks');
  const duplicateLeads = sumBy(data, 'duplicateLeads');
  if (looks === 0) return 0;
  return (duplicateLeads / looks) * 100;
};

export const getConversionPercentage = (data) => {
  const loanApproved = sumBy(data, 'loanApproved');
  const accepted = sumBy(data, 'accepted');
  if (accepted === 0) return 0;
  return (loanApproved / accepted) * 100;
};

export const getFpdRatePercentage = (data) => {
  const fpd = sumBy(data, 'fpd');
  const loanApproved = sumBy(data, 'loanApproved');
  if (loanApproved === 0) return 0;
  return (fpd / loanApproved) * 100;
};

export const getFpmRatePercentage = (data) => {
  const fpm = sumBy(data, 'fpm');
  const loanApproved = sumBy(data, 'loanApproved');
  if (loanApproved === 0) return 0;
  return (fpm / loanApproved) * 100;
};

export const getSpdRatePercentage = (data) => {
  const spd = sumBy(data, 'spd');
  const loanApproved = sumBy(data, 'loanApproved');
  if (loanApproved === 0) return 0;
  return (spd / loanApproved) * 100;
};

export const getRedirectRate = (data) => {
  const redirectCount = sumBy(data, 'redirected');
  const accepted = sumBy(data, 'accepted');
  if (accepted === 0) return 0;
  return (redirectCount / accepted) * 100;
};

export const getRoiPercentage = (data) => {
  const totalCostWithApprovedAmount =
    sumBy(data, 'costSpent') + sumBy(data, 'approvedAmount');

  if (totalCostWithApprovedAmount === 0) return 0;

  const collectedDollars = sumBy(data, 'collectedDollars');

  return (collectedDollars / totalCostWithApprovedAmount) * 100;
};

export const calculateCpaValue = (data) =>
  calculateCostPerProperty(data, 'costSpent', 'accepted');

export const calculateCpfValue = (data) =>
  calculateCostPerProperty(data, 'costSpent', 'loanApproved');

export const calculateLeadCpfValue = (data) =>
  calculateCostPerProperty(data, 'leadCost', 'loanApproved');

export const calculateDataCpfValue = (data) =>
  calculateCostPerProperty(data, 'dataCost', 'loanApproved');

const calculateCostPerProperty = (data, costKey, propertyKey) => {
  const propertySum = sumBy(data, propertyKey);
  if (propertySum === 0) return 0;

  return sumBy(data, costKey) / propertySum;
};
