import { comparisonMap } from './comparison';
import { permissionsMap } from './permissions';

const routes = {
  home: '/',
  permissionDenied: '/permission-denied',
  auth: {
    login: '/auth/login',
    accessVerification: '/access-verification'
  },
  configuration: {
    path: '/configuration',
    controlPanel: '/configuration/pages',
    globalSettings: '/configuration/global-settings',
    maintenanceMode: '/configuration/maintenance-mode',
    teamGoals: '/configuration/team-goals'
  },
  telemetry: '/logs/telemetry',
  applications: {
    list: '/applications',
    page: {
      edit: {
        path: '/applications/:id/edit',
        url: (id) => `/applications/${id}/edit`
      },
      view: {
        path: '/applications/:id/view',
        url: (id) => `/applications/${id}/view`
      }
    }
  },
  flatPages: {
    list: '/configuration/pages',
    page: {
      create: '/configuration/pages/create',
      edit: {
        path: '/configuration/pages/page/:id/edit',
        url: (id) => `/configuration/pages/page/${id}/edit`
      },
      view: {
        path: '/configuration/pages/page/:id/view',
        url: (id) => `/configuration/pages/page/${id}/view`
      }
    }
  },
  users: {
    list: '/customers',
    page: {
      view: {
        path: '/customers/page/:id/view',
        url: (id) => `/customers/page/${id}/view`
      }
    }
  },
  loggedEvents: '/logs/system-events',
  notifications: {
    path: '/notifications',
    systemNotifications: {
      list: '/notifications/system-notifications',
      page: {
        edit: {
          path: '/notifications/system-notifications/page/:id/edit',
          url: (id) => `/notifications/system-notifications/page/${id}/edit`
        },
        view: {
          path: '/notifications/system-notifications/page/:id/view',
          url: (id) => `/notifications/system-notifications/page/${id}/view`
        }
      },
      historyPage: {
        path: '/notifications/system-notifications/page/:id/history-view',
        url: (id) =>
          `/notifications/system-notifications/page/${id}/history-view`
      }
    },
    customNotifications: {
      list: '/notifications/custom-notifications',
      page: {
        edit: {
          path: '/notifications/custom-notifications/page/:id/edit',
          url: (id) => `/notifications/custom-notifications/page/${id}/edit`
        },
        view: {
          path: '/notifications/custom-notifications/page/:id/view',
          url: (id) => `/notifications/custom-notifications/page/${id}/view`
        },
        create: '/notifications/custom-notifications/create'
      }
    }
  },
  pageNotFound: '/page-404',
  dashboard: {
    path: '/dashboard',
    applicationErrors: {
      list: '/dashboard/fields-error-rate',
      customer: {
        path: '/dashboard/fields-error-rate/Customer'
      },
      page: {
        url: (page, field) => `/dashboard/fields-error-rate/${page}/${field}`,
        path: '/dashboard/fields-error-rate/:page/:field'
      }
    },
    applicationSpentTime: '/dashboard/application-time',
    apiAppErrors: '/dashboard/error-response-rate',
    internalPerformance: {
      path: '/dashboard/internal-performance',
      web: '/dashboard/internal-performance/web-performance',
      db: '/dashboard/internal-performance/database-performance',
      leadReportsPerformance:
        '/dashboard/internal-performance/lead-reports-performance'
    },
    leadStats: '/dashboard/application-rates',
    abandonedApplications: '/dashboard/abandoned-applications',
    healthStatus: '/dashboard/health-status',
    discrepancyReport: {
      path: '/dashboard/discrepancy-report',
      discrepancySummaryReportByDay:
        '/dashboard/discrepancy-summary-report-by-day',
      discrepancySummaryReportByStore:
        '/dashboard/discrepancy-summary-report-by-store'
    },
    ibv: {
      path: '/dashboard/ibv',
      trafficReport: '/dashboard/ibv/traffic-report',
      scoringReport: {
        list: '/dashboard/ibv/scoring-report',
        page: (id) => `/dashboard/ibv/scoring-report/${id}`
      }
    }
  },
  leads: {
    path: '/lead-management',
    leadProviders: {
      list: '/lead-management/lead-providers',
      page: {
        create: '/lead-management/lead-providers/create',
        edit: {
          path: '/lead-management/lead-providers/:id/edit',
          url: (id) => `/lead-management/lead-providers/${id}/edit`
        },
        view: {
          path: '/lead-management/lead-providers/:id/view',
          url: (id) => `/lead-management/lead-providers/${id}/view`
        }
      },
      campaign: {
        create: {
          path: '/lead-management/lead-providers/:leadId/lead-provider/create',
          url: (leadId) =>
            `/lead-management/lead-providers/${leadId}/lead-provider/create`
        },
        edit: {
          path: '/lead-management/lead-providers/:leadId/lead-provider/:campaignId/edit',
          url: (leadId, campaignId) =>
            `/lead-management/lead-providers/${leadId}/lead-provider/${campaignId}/edit`
        },
        view: {
          path: '/lead-management/lead-providers/:leadId/lead-provider/:campaignId/view',
          url: (leadId, campaignId) =>
            `/lead-management/lead-providers/${leadId}/lead-provider/${campaignId}/view`
        }
      }
    },
    campaignGroups: {
      list: '/lead-management/campaign-groups',
      page: {
        create: '/lead-management/campaign-groups/create',
        edit: {
          path: '/lead-management/campaign-groups/:id/edit',
          url: (id) => `/lead-management/campaign-groups/${id}/edit`
        },
        view: {
          path: '/lead-management/campaign-groups/:id/view',
          url: (id) => `/lead-management/campaign-groups/${id}/view`
        }
      }
    },
    leadProvidersRates: '/lead-management/lead-providers-dashboard',
    leadProvidersReports: {
      path: '/lead-management/lead-providers-reports',
      campaigns:
        '/lead-management/lead-providers-reports/lead-providers-performance'
    },
    campaignGroupsRates: '/lead-management/campaign-groups-rates',
    reports: {
      path: '/lead-management/lead-reports',
      affiliateReport: '/lead-management/lead-reports/affiliate-report',
      leadProviderPerformance:
        '/lead-management/lead-reports/lead-provider-lead-provider-performance',
      stackPerformance:
        '/lead-management/lead-reports/stack-lead-provider-performance',
      denialReasonsReport: '/lead-management/lead-reports/denial-reasons-report'
    }
  },
  osAndAppVersions: {
    path: '/os-and-app-versions',
    userAgents: '/os-and-app-versions/devices-and-os',
    mobileAppVersions: {
      list: '/os-and-app-versions/mobile-app-versions',
      create: '/os-and-app-versions/mobile-app-versions/create',
      edit: '/os-and-app-versions/mobile-app-versions/:id/edit'
    }
  },
  userLoans: '/user-loans',
  blockedIPAddresses: '/configuration/blocked-ip-addresses',
  promoCodes: {
    list: '/configuration/promo-codes',
    create: '/configuration/promo-codes/create',
    edit: '/configuration/promo-codes/:id/edit'
  },
  payments: {
    list: '/payments',
    debitCard: '/payments/add-dc-information',
    declineReasonSettings: '/payments/decline-reasons-settings',
    rerun: {
      path: '/payments/payments-rerun',
      list: '/payments/payments-rerun/list',
      settings: '/payments/payments-rerun/settings',
      performance: '/payments/payments-rerun/performance-summary'
    },
    pastDuePayments: {
      path: '/payments/past-due-payments',
      paymentsList: '/payments/past-due-payments/payment-list',
      fileList: '/payments/past-due-payments/file-list'
    },
    scheduledPayments: {
      path: '/payments/scheduled-payments',
      list: '/payments/scheduled-payments/list',
      settings: '/payments/scheduled-payments/settings',
      performance: '/payments/scheduled-payments/performance-summary'
    }
  },
  logs: {
    path: '/logs'
  },
  debitCard: '/debit-card',
  leadProvider: {
    leadRequests: '/lead-provider/lead-requests',
    campaignPerformance: '/lead-provider/campaign-performance'
  },
  csvCompare: {
    path: '/csv-compare',
    comparisons: '/csv-compare/compare',
    configurations: {
      list: '/csv-compare/configurations',
      create: '/csv-compare/configurations/create',
      edit: {
        url: (id) => `/csv-compare/configurations/${id}/edit`,
        path: '/csv-compare/configurations/:id/edit'
      }
    },
    details: {
      url: (id, key = comparisonMap.lpp) => `/csv-compare/${key}/${id}`,
      path: '/csv-compare/:key/:id'
    }
  },
  wallboard: {
    loanOriginatorsDaily: '/wallboard/loan-originators-daily',
    loanOriginatorsWeekly: '/wallboard/loan-originators-weekly',
    loanOriginatorsMonthly: '/wallboard/loan-originators-monthly',
    loanProcessing: '/wallboard/loan-processing',
    collection: '/wallboard/collection',
    teamGoals: '/wallboard/team-goals'
  },
  binChecker: {
    path: '/bin-checker',
    binNumberList: '/bin-checker/bin-number-list',
    abaNumberList: '/bin-checker/aba-number-list'
  }
};

export const orderedRoutesWithPermissions = [
  {
    route: routes.applications.list,
    permission: permissionsMap.applicationView
  },
  {
    route: routes.flatPages.list,
    permission: permissionsMap.flatView
  },
  {
    route: routes.telemetry,
    permission: permissionsMap.telemetryView
  },
  {
    route: routes.users.list,
    permission: permissionsMap.usersView
  },
  {
    route: routes.loggedEvents,
    permission: permissionsMap.loggedView
  },
  {
    route: routes.notifications.systemNotifications.list,
    permission: permissionsMap.notificationJobView
  },
  {
    route: routes.promoCodes.list,
    permission: permissionsMap.promoCodesView
  },
  {
    route: routes.dashboard.ibv.scoringReport.list,
    permission: permissionsMap.ibvResultView
  },
  {
    route: routes.dashboard.ibv.trafficReport,
    permission: permissionsMap.ibvResultView
  },
  {
    route: routes.dashboard.applicationErrors.list,
    permission: permissionsMap.fieldsErrorRateView
  },
  {
    route: routes.dashboard.applicationSpentTime,
    permission: permissionsMap.applicationTimeView
  },
  {
    route: routes.dashboard.apiAppErrors,
    permission: permissionsMap.errorResponseRateView
  },
  {
    route: routes.dashboard.internalPerformance.web,
    permission: permissionsMap.internalPerformanceView
  },
  {
    route: routes.dashboard.leadStats,
    permission: permissionsMap.applicationRatesView
  },
  {
    route: routes.dashboard.abandonedApplications,
    permission: permissionsMap.abandonStepStatisticsView
  },
  {
    route: routes.dashboard.discrepancyReport,
    permission: permissionsMap.DiscrepancyReportView
  },
  {
    route: routes.blockedIPAddresses,
    permission: permissionsMap.loggedView
  },
  {
    route: routes.configuration.globalSettings,
    permission: permissionsMap.viewSettings
  },
  {
    route: routes.users.list,
    permission: permissionsMap.usersView
  },
  {
    route: routes.userLoans,
    permission: permissionsMap.userLoansView
  },
  { route: routes.payments.list, permission: permissionsMap.paymentUserView },
  {
    route: routes.payments.debitCard,
    permission: permissionsMap.debitCardManage
  },
  {
    route: routes.payments.pastDuePayments.fileList,
    permission: permissionsMap.paymentUserView
  },
  {
    route: routes.payments.rerun.performance,
    permission: permissionsMap.paymentsRerunView
  },
  {
    route: routes.notifications.customNotifications.list,
    permission: permissionsMap.notificationJobView
  },
  {
    route: routes.osAndAppVersions.userAgents,
    permission: permissionsMap.userAgentsView
  },
  {
    route: routes.osAndAppVersions.mobileAppVersions.list,
    permission: permissionsMap.appVersionView
  },
  {
    route: routes.leads.reports.affiliateReport,
    permission: permissionsMap.leadReportsView
  },
  {
    route: routes.leads.reports.leadProviderPerformance,
    permission: permissionsMap.leadReportsView
  },
  {
    route: routes.leads.reports.denialReasonsReport,
    permission: permissionsMap.leadReportsView
  },
  {
    route: routes.leads.leadProvidersRates,
    permission: permissionsMap.leadRatesView
  },
  {
    route: routes.leads.leadProviders.list,
    permission: permissionsMap.leadProvidersView
  },
  {
    route: routes.leads.leadProvidersReports.path,
    permission: permissionsMap.adminLeadProviderDataView
  },
  {
    route: routes.leads.campaignGroups.list,
    permission: permissionsMap.leadGroupsView
  },
  {
    route: routes.csvCompare.path,
    permission: permissionsMap.CSVCompareView
  },
  {
    route: routes.payments.scheduledPayments.performance,
    permission: permissionsMap.scheduledPaymentsView
  }
];

export const singlePagePermissions = [
  {
    route: routes.debitCard,
    permissions: [permissionsMap.debitCardManage]
  },
  {
    route: routes.leadProvider.leadRequests,
    permissions: [permissionsMap.leadProviderUsersLeadReports]
  },
  {
    route: routes.leadProvider.campaignPerformance,
    permissions: [permissionsMap.leadProviderUsersLeadPerformance]
  }
];

export const leadProviderUserRoutes = [
  routes.leadProvider.leadRequests,
  routes.leadProvider.campaignPerformance
];

export default routes;
