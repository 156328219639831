import React from 'react';
import PT from 'prop-types';

import { StyledList, StyledListItemText } from './styled';

import Box from '@components/shared/Box/Box';
import Typography from '@components/shared/Typography/Typography';
import ListItem from '@components/shared/List/ListItem';
import { noFieldData } from '@constants/common';

const ListContentBlock = ({
  title,
  contentList,
  wrapperProps,
  separateToColumns
}) => (
  <Box {...wrapperProps}>
    {title && (
      <Typography variant="h3" my={1}>
        {title}
      </Typography>
    )}
    <StyledList
      separateToColumns={separateToColumns}
      contentListLength={contentList?.length}
      disablePadding
    >
      {contentList?.map((item) => (
        <ListItem key={item.title} disablePadding>
          <StyledListItemText
            primary={item.title}
            secondary={item.content || noFieldData}
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 2fr',
              gap: 2
            }}
          />
        </ListItem>
      ))}
    </StyledList>
  </Box>
);

ListContentBlock.defaultProps = {
  separateToColumns: 1
};

ListContentBlock.propTypes = {
  title: PT.string,
  contentList: PT.arrayOf(
    PT.shape({
      title: PT.string.isRequired,
      content: PT.string.isRequired
    })
  ).isRequired,
  wrapperProps: PT.object,
  separateToColumns: PT.number
};

export default ListContentBlock;
