import * as Yup from 'yup';

import { loanDefaultLimits, loanDefaultValue } from '@constants/common';
import { loanAmountSchema } from '@components/Application/LoanForm/GeneralStep/schema';

export const addressStepFieldsSchema = Yup.object().shape({
  amount: Yup.number()
    .min(loanDefaultLimits.min)
    .max(loanDefaultLimits.max)
    .label('Loan amount')
    .required(),
  address1: Yup.string().min(5).max(150).label('Address 1').required(),
  address2: Yup.string().min(5).max(150).nullable().label('Address 2'),
  city: Yup.string().min(3).max(50).label('Address').required(),
  state: Yup.string().label('State').required(),
  zip: Yup.string()
    .matches(/^[0-9]*$/, {
      message: 'Only digits are allowed'
    })
    .length(5)
    .label('Zip')
    .required(),
  timeAtAddressYears: Yup.number().label('Years at address').required(),
  timeAtAddressMonths: Yup.number().label('Months at address').required()
});

export const addressStepSchema =
  addressStepFieldsSchema.concat(loanAmountSchema);

export const addressStepInitialValues = {
  amount: loanDefaultValue,
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  timeAtAddressMonths: '',
  timeAtAddressYears: ''
};
