import PT from 'prop-types';
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { StyledSwitch } from '@components/shared/DataTable/actions/styled';
import Stack from '@components/shared/Stack/Stack';
import { TimeInput } from '@components/shared/Form/TimeInput/TimeInput';
import toastify from '@utils/toastify';
import helpers from '@utils/helpers';
import { shortTimeFormat } from '@constants/common';
import { palette } from '@utils/theme';
import StopPropagationWrapper from '@components/shared/StopPropagationWrapper/StopPropagationWrapper';
import PaymentProcessingApi from '@utils/paymentProcessingApi';

const isActiveKey = 'isActive';

const SettingHeaderSection = ({
  settingId,
  timeSlot,
  isActive: _isActive,
  setData,
  isReadOnly,
  renderDayName,
  endpoint
}) => {
  const [isActive, setIsActive] = useState(_isActive);
  const formattedTime = useMemo(() => {
    const time = helpers.getFormattedDurationFromSeconds(timeSlot);
    return dayjs(time, shortTimeFormat.server);
  }, [timeSlot]);

  const updateSettingStatus = async (status) => {
    try {
      await PaymentProcessingApi.patch(endpoint + `?${isActiveKey}=` + status);
      setIsActive(status);
      toastify.success({ message: 'Setting status updated' });
    } catch (err) {
      toastify.error(err || { message: 'Something went wrong' });
    }
  };

  const getTimeSlot = (time) => {
    const timeInSeconds = helpers.getFormattedTimeToSeconds(time);
    return setData(timeInSeconds);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      {renderDayName && renderDayName}
      <StopPropagationWrapper>
        <TimeInput
          name={`timeSlot-${settingId}`}
          value={formattedTime}
          onChange={(e) => getTimeSlot(e)}
          sx={{
            backgroundColor: palette.white
          }}
          disabled={isReadOnly}
        />
      </StopPropagationWrapper>
      <StopPropagationWrapper>
        <StyledSwitch
          name={`status-${settingId}`}
          checked={isActive}
          size="medium"
          onChange={(e) => updateSettingStatus(e.target.checked)}
          disabled={isReadOnly}
        />
      </StopPropagationWrapper>
    </Stack>
  );
};

SettingHeaderSection.propTypes = {
  settingId: PT.number,
  timeSlot: PT.string,
  isActive: PT.bool,
  setData: PT.func.isRequired,
  isReadOnly: PT.bool,
  renderDayName: PT.node,
  endpoint: PT.func
};

export default SettingHeaderSection;
