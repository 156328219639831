import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { useHistory } from 'react-router';

import { getColumns } from './config';

import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import routes from '@constants/routes';
import Loader from '@components/shared/Loader';
import { cleanObject } from '@utils/object-cleaner';
import { adminApiUrls } from '@constants/adminApiUrls';
import AdminApi from '@utils/adminApi';

const initialFilterParams = cleanObject({});

const PaymentOrders = ({ id }) => {
  const history = useHistory();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    history.push({
      pathname: routes.users.page.view.url(id),
      search: ''
    });
  }, []);

  useEffect(() => {
    if (!history.location.search) {
      setLoader(false);
    }
  }, [history.location?.search]);

  if (loader) return <Loader />;

  return (
    <ODataPaginatedTable
      getColumns={getColumns}
      usePaginatedDataOptions={{
        apiUrl: adminApiUrls.paymentOrders,
        initialFilterParams: {
          $orderBy: 'createdDate desc',
          $filter: {
            ...initialFilterParams,
            userId: {
              eq: id
            }
          }
        }
      }}
      rowKey="paymentOrderId"
      exportFileName={`Payments by user ${id}`}
      customApi={AdminApi}
    />
  );
};

PaymentOrders.propTypes = {
  id: PT.string.isRequired
};

export default PaymentOrders;
