import React, { useEffect, useState } from 'react';
import PT from 'prop-types';

import helpers from '@utils/helpers';
import Loader from '@components/shared/Loader';
import Typography from '@components/shared/Typography/Typography';
import Box from '@components/shared/Box/Box';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const Subscription = ({ id }) => {
  const [subStatus, setSubStatus] = useState(null);

  const fetchSub = async (id) => {
    const subscriptionData = await AdminApi.get(
      adminApiUrls.notificationSubscriptions.list,
      {
        params: {
          $filter: {
            userId: {
              eq: id
            }
          }
        }
      }
    );
    setSubStatus(!!subscriptionData.value.length);
  };

  useEffect(() => {
    fetchSub(id);
  }, [id]);

  return (
    <Box pt={1}>
      <Typography variant="h5">
        Subscribed:{' '}
        {subStatus === null ? (
          <Loader elSize="small" />
        ) : (
          helpers.renderBooleanValue(subStatus)
        )}
      </Typography>
    </Box>
  );
};

Subscription.propTypes = {
  id: PT.string.isRequired
};

export default Subscription;
