import { Collapse, List, ListItemIcon } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ArrowDownIcon, ArrowUpIcon, DotIcon } from '../icons/Icons';

import { StyledListItem, StyledListItemButton, StyledTooltip } from './styled';
import SidebarItem from './SidebarItem';

import ListItemText from '@components/shared/List/ListItemText';
import { palette } from '@utils/theme';
import helpers from '@utils/helpers';
import Permissions from '@utils/permissions';

const getIsSelected = (link, pathname) => link === pathname;
const getDepthLevelParts = (link, depthLevel) =>
  link.split('/').filter(Boolean).slice(0, depthLevel).join('/');
const getIsPartOfPath = (link, pathname, depthLevel) => {
  if (getIsSelected(link, pathname)) {
    return true;
  }
  const linkLevelParts = getDepthLevelParts(link, depthLevel);
  const pathLevelParts = getDepthLevelParts(pathname, depthLevel);
  return linkLevelParts === pathLevelParts;
};

const SidebarItemCollapse = ({ item, isSidebarOpen, depthLevel, isInner }) => {
  const isSelected = getIsSelected(item.link, location.pathname);
  const isPartOfPath = getIsPartOfPath(
    item.link,
    location.pathname,
    depthLevel
  );
  const [isExpanded, setIsExpanded] = useState(isSidebarOpen && isPartOfPath);

  useEffect(() => {
    setIsExpanded(isSidebarOpen && isPartOfPath);
  }, [isSidebarOpen]);

  const policies = Permissions.get();

  const checkPolicyForMenuItem = (menuItem) => {
    if (policies && menuItem) {
      return helpers.checkPolicies(policies, menuItem);
    }
    return true;
  };

  return (
    <StyledListItem disablePadding open={isSidebarOpen} isInner={isInner}>
      <StyledTooltip
        title={item.title}
        placement="right"
        disableHoverListener={isSidebarOpen}
      >
        <StyledListItemButton
          to={isSidebarOpen ? undefined : item.link}
          onClick={() => isSidebarOpen && setIsExpanded(!isExpanded)}
          selected={isSelected}
        >
          <ListItemIcon
            sx={{
              color: palette.dark
            }}
          >
            {item.icon && item.icon}
            {!item.icon && <DotIcon size={12} />}
          </ListItemIcon>
          <ListItemText primary={item.title} />
          {isExpanded ? <ArrowUpIcon size={24} /> : <ArrowDownIcon size={24} />}
        </StyledListItemButton>
        <Collapse in={isExpanded} timeout="auto">
          <List>
            {item.children?.map((route, index) => {
              const isPermissionExist = checkPolicyForMenuItem(route);
              if (isPermissionExist) {
                return route.children ? (
                  <SidebarItemCollapse
                    item={route}
                    key={index}
                    isSidebarOpen={isSidebarOpen}
                    depthLevel={depthLevel + 1}
                    isInner
                  />
                ) : (
                  <SidebarItem
                    item={route}
                    isSidebarOpen={isSidebarOpen}
                    isOpen={isExpanded}
                    key={index}
                    isInner
                  />
                );
              }
            })}
          </List>
        </Collapse>
      </StyledTooltip>
    </StyledListItem>
  );
};

SidebarItemCollapse.defaultProps = {
  depthLevel: 1
};
SidebarItemCollapse.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.element,
    children: PropTypes.array
  }),
  isSidebarOpen: PropTypes.bool,
  depthLevel: PropTypes.number,
  isInner: PropTypes.bool
};

export default SidebarItemCollapse;
