import React, { useEffect, useMemo, useState } from 'react';
import PT from 'prop-types';
import buildQuery from 'odata-query';

import useTestUsersData, {
  defaultTestUsersPageSize
} from '@components/shared/TestNotificationButton/useTestUsersData';
import '@components/shared/TestNotificationButton/TestNotificationForm/TestNotificationForm.scss';
import Button from '@components/shared/Button/Button';
import Loader from '@components/shared/Loader';
import TransferList from '@components/shared/TransferList/TransferList';
import Box from '@components/shared/Box/Box';

const TestNotificationForm = ({ onSubmit }) => {
  const [targetKeys, setTargetKeys] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);

  const { data, top, skip, totalAmount, loading, handlePageChange, setParams } =
    useTestUsersData(targetKeys);

  useEffect(() => {
    if (searchTerm != null) {
      const delayDebounceFn = setTimeout(() => {
        const searchValue = {
          contains: searchTerm
        };

        setParams({
          $top: defaultTestUsersPageSize,
          $skip: 1,
          $filter:
            searchTerm !== ''
              ? [
                  `(${buildQuery({
                    filter: {
                      or: {
                        email: searchValue,
                        firstName: searchValue,
                        lastName: searchValue
                      }
                    }
                  }).replace(/\?\$filter=/, '')})`
                ]
              : []
        });
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm]);

  const getName = (item) =>
    item.email + ' | ' + item.firstName + ' ' + item.lastName;

  const choicesList = useMemo(
    () =>
      data
        .map((item) => ({
          ...item,
          key: item.id,
          name: getName(item)
        }))
        .filter((item) => !targetKeys.find((elem) => elem === item.campaignId)),
    [data]
  );

  return (
    <>
      <TransferList
        onSelectChange={(chosenItems) => {
          setTargetKeys(chosenItems.map((item) => item.key));
        }}
        choicesProps={{
          title: 'Available users',
          list: choicesList,
          searchTerm,
          setSearchTerm
        }}
        chosenProps={{
          title: 'Applied users',
          list: targetKeys
        }}
        paginationProps={{
          onChange: handlePageChange,
          current: skip,
          pageSize: top,
          total: totalAmount
        }}
        loading={loading}
      />
      {loading && <Loader />}
      <Box mt={2}>
        <Button
          type="submit"
          onClick={() => onSubmit(targetKeys)}
          disabled={!targetKeys.length}
        >
          Send
        </Button>
      </Box>
    </>
  );
};

TestNotificationForm.propTypes = {
  onSubmit: PT.func.isRequired
};

export default TestNotificationForm;
