import React from 'react';
import dayjs from 'dayjs';

import {
  emailColumnWidth,
  fullNameColumnWidth,
  timeDateColumnWidth
} from '@components/shared/DataTable/DataTable.constants';
import {
  filterOperators,
  noFieldData,
  renderCommonCell
} from '@constants/common';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import helpers from '@utils/helpers';
import ExpDateFilter from '@components/shared/DataTable/filters/ExpDateFilter';
import routes from '@constants/routes';
import Button from '@components/shared/Button/Button';

const ccSources = {
  SecureVaultOnly: 'Secure Vault',
  PlmOnly: 'PLM',
  SecureVaultAndPlm: 'Secure Vault & PLM'
};

const ccSourceOptions = Object.keys(ccSources).map((key) => ({
  label: ccSources[key],
  value: key
}));

const differenceTypes = {
  CustomerMissing: 'Customer Missing',
  CustomerExistsCardMissing: 'Customer Exists Card Missing'
};

const differenceTypesOptions = [
  {
    label: 'Customer Missing',
    value: 'CustomerMissing'
  },
  {
    label: 'Customer Exists Card Missing',
    value: 'CustomerExistsCardMissing'
  }
];

export const getColumns = () => [
  {
    headerName: 'Customer ID',
    field: 'customerId',
    width: 100,
    renderCell: ({ row }) => row.customerId
  },
  {
    headerName: 'Loan ID',
    field: 'loanId',
    width: 100,
    renderCell: ({ row }) => row.loanId
  },
  {
    headerName: 'Loan Approved Date',
    field: 'loanApprovedDate',
    minWidth: timeDateColumnWidth,
    type: muiDataTableTypes.dateRange,
    renderCell: ({ row }) =>
      row.loanApprovedDate
        ? helpers.getFormattedDate(row.loanApprovedDate)
        : noFieldData,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Card number',
    field: 'ccNumberLast4Digits',
    width: 130,
    type: muiDataTableTypes.number,
    renderCell: ({ row }) =>
      helpers.getCardMask(row.ccNumberBin, row.ccNumberLast4Digits)
  },
  {
    headerName: 'Exp. Date',
    field: 'ccExpDate',
    width: 130,
    type: muiDataTableTypes.expDate,
    renderCell: ({ row, field }) => row[field] || noFieldData,
    filterOperators: [
      filterOperators.equals((props) => (
        <ExpDateFilter {...props} fieldName="ccExpDate" />
      ))
    ]
  },
  {
    headerName: 'CardHolder Name',
    field: 'cardHolderName',
    width: fullNameColumnWidth,
    renderCell: ({ row, field }) => row[field] || noFieldData
  },
  {
    headerName: 'Email',
    field: 'email',
    width: emailColumnWidth
  },
  {
    headerName: 'Source',
    field: 'ccSource',
    width: 100,
    type: muiDataTableTypes.singleSelect,
    valueOptions: ccSourceOptions,
    renderCell: ({ row }) => ccSources[row.ccSource] || noFieldData
  },
  {
    headerName: 'Difference Type',
    field: 'differenceType',
    width: 200,
    type: muiDataTableTypes.singleSelect,
    valueOptions: differenceTypesOptions,
    renderCell: ({ row }) => differenceTypes[row.differenceType] || noFieldData
  },
  {
    headerName: 'Created Date',
    field: 'dateCreated',
    width: timeDateColumnWidth,
    type: muiDataTableTypes.dateRange,
    renderCell: ({ row, field }) =>
      row[field] ? helpers.getFormattedDate(row[field]) : noFieldData,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Updated Date',
    field: 'dateUpdated',
    width: timeDateColumnWidth,
    type: muiDataTableTypes.dateRange,
    renderCell: ({ row, field }) =>
      row[field] ? helpers.getFormattedDate(row[field]) : noFieldData,
    filterOperators: [filterOperators.dateRange]
  }
];

export const getMissingByDailyColumns = (setModalProps) => [
  {
    headerName: 'Date',
    field: 'summaryDateTime',
    minWidth: timeDateColumnWidth,
    type: muiDataTableTypes.dateRange,
    renderCell: ({ row }) =>
      row.summaryDateTime
        ? helpers.getFormattedDate(row.summaryDateTime)
        : noFieldData,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Customer',
    field: 'customerNumberDiscrepancyCount',
    width: 150,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Change',
    field: 'customerDetailDiscrepancy',
    width: 150,
    renderCell: ({ row }) => (
      <Button
        variant="text"
        onClick={() =>
          setModalProps({
            isOpen: true,
            summaryDateTime: row?.summaryDateTime,
            customerDetailDiscrepancy: true
          })
        }
      >
        View
      </Button>
    )
  },
  {
    headerName: 'Cards',
    field: 'cardNumberDiscrepancyCount',
    width: 150,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Change',
    field: 'cardDetailDiscrepancy',
    width: 150,
    renderCell: ({ row }) => (
      <Button
        variant="text"
        onClick={() =>
          setModalProps({
            isOpen: true,
            summaryDateTime: row?.summaryDateTime,
            cardDetailDiscrepancy: true
          })
        }
      >
        View
      </Button>
    )
  }
];
export const getMissingByStoreColumns = () => [
  {
    headerName: 'Store',
    field: 'storeName',
    width: 150
  },
  {
    headerName: 'Customers-Vault',
    field: 'missingCount',
    flex: 1
  }
];

export const tabNames = {
  discrepancyReport: 'Discrepancy Report',
  discrepancySummaryReportByDay: 'Discrepancy Summary Report By Day',
  discrepancySummaryReportByStore: 'Discrepancy Summary Report By Store'
};

export const subTabsList = [
  {
    id: tabNames.discrepancyReport,
    title: tabNames.discrepancyReport,
    link: routes.dashboard.discrepancyReport.path
  },
  {
    id: tabNames.discrepancySummaryReportByDay,
    title: tabNames.discrepancySummaryReportByDay,
    link: routes.dashboard.discrepancyReport.discrepancySummaryReportByDay
  },
  {
    id: tabNames.discrepancySummaryReportByStore,
    title: tabNames.discrepancySummaryReportByStore,
    link: routes.dashboard.discrepancyReport.discrepancySummaryReportByStore
  }
];

export const getFilterInitialStateMissingByStore = () => ({
  historicalDate: dayjs().startOf('day').toISOString()
});

export const innerTableColumns = [
  {
    headerName: 'Customer Id',
    field: 'customerId',
    flex: 1,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Loan Id',
    field: 'loanId',
    flex: 1,
    type: muiDataTableTypes.number,
    renderCell: renderCommonCell
  },
  {
    headerName: 'BIN Number',
    field: 'ccNumberBin',
    flex: 1,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Last 4 Digits',
    field: 'ccNumberLast4Digits',
    flex: 1,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Exp. Date',
    field: 'ccExpDate',
    flex: 1,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Card Holder Name',
    field: 'cardHolderName',
    flex: 1,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1,
    renderCell: renderCommonCell
  },
  {
    headerName: 'CC Source',
    field: 'ccSource',
    flex: 1,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Date Created',
    field: 'dateCreated',
    flex: 1,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Loan Approved Date',
    field: 'loanApprovedDate',
    flex: 1,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Difference Type',
    field: 'differenceType',
    flex: 1,
    renderCell: renderCommonCell
  }
];
