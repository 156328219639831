export const cardTypes = {
  electron: 'Electron',
  maestro: 'Maestro',
  dankort: 'Dankort',
  interPayment: 'InterPayment',
  unionPay: 'UnionPay',
  visa: 'Visa',
  mastercard: 'Mastercard',
  amex: 'Amex',
  diners: 'Diners Club',
  discover: 'Discover',
  jcb: 'JCB',
  jcb15: 'JCB 15'
};
