import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

import { DotIcon } from '../icons/Icons';

import {
  StyledListItem,
  StyledListItemButton,
  StyledListItemIcon,
  StyledTooltip
} from './styled';

import ListItemText from '@components/shared/List/ListItemText';

const getIsSelected = (item, pathname) =>
  item.link === pathname || pathname.includes(item?.path);

const SidebarItem = ({ item, isSidebarOpen, isInner }) => {
  const isSelected = getIsSelected(item, location.pathname);
  return (
    <StyledListItem disablePadding open={isSidebarOpen} isInner={isInner}>
      <StyledTooltip
        title={item.title}
        placement="right"
        disableHoverListener={isSidebarOpen}
      >
        <StyledListItemButton
          component={Link}
          to={item.link}
          selected={isSelected}
          target={item.linkTarget || '_self'}
          rel="noopener noreferrer"
        >
          <StyledListItemIcon open={isSidebarOpen}>
            {item.icon && item.icon}
            {!item.icon && <DotIcon size={12} />}
          </StyledListItemIcon>

          <ListItemText
            primary={item.title}
            sx={{ opacity: isSidebarOpen ? 1 : 0 }}
          />
        </StyledListItemButton>
      </StyledTooltip>
    </StyledListItem>
  );
};

SidebarItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.element,
    linkTarget: PropTypes.string
  }),
  isSidebarOpen: PropTypes.bool,
  isInner: PropTypes.bool
};

export default SidebarItem;
