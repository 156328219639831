import { useState, useEffect, useCallback } from 'react';

import Logger from '@utils/logger';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

export const defaultTestUsersPageSize = 5;

const useTestUsersData = (targetKeys) => {
  const [data, setData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    $top: defaultTestUsersPageSize,
    $skip: 1
  });

  const getSkipRecords = () => {
    const isLastPage =
      totalAmount &&
      totalAmount > defaultTestUsersPageSize &&
      !data.filter((item) => !targetKeys.includes(item.id)).length &&
      (params.$skip - 1) * params.$top >=
        totalAmount - defaultTestUsersPageSize;
    if (isLastPage) {
      params.$skip = params.$skip - 1;
      return (params.$skip - 2) * params.$top;
    }
    return (params.$skip - 1) * params.$top;
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const filter = params.$filter ? [...params.$filter] : [];
      if (targetKeys.length) {
        filter.unshift({
          [`id in (${targetKeys.map((item) => `"${item}"`).join(',')})`]: {
            eq: false
          }
        });
      }

      const list = await AdminApi.get(adminApiUrls.users.list, {
        params: {
          ...params,
          $skip: getSkipRecords(),
          $count: true,
          $filter: filter
        }
      });
      setData(list?.value);
      setTotalAmount(list['@odata.count']);
    } catch (e) {
      Logger.error(e);
    } finally {
      setLoading(false);
    }
  }, [params, targetKeys, handlePageChange]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = useCallback(
    (skip, top) => {
      setParams({
        ...params,
        $skip: skip,
        $top: top
      });
    },
    [params]
  );

  return {
    data,
    top: params.$top,
    skip: params.$skip,
    totalAmount,
    loading,
    handlePageChange,
    setParams,
    fetchData
  };
};

export default useTestUsersData;
