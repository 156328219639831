import dayjs from 'dayjs';
import qs from 'query-string';

import {
  filterMuiODataOperators,
  muiDataTableTypes
} from '@constants/tableFilterConfig';
import { filterTimeFormat } from '@constants/common';
import { COLUMN_DROPDOWN_FILTER_NULL_OPTION_VALUE } from '@components/shared/DataTable/DataTable.constants';

const handleValue = (value, type, odataOperator) => {
  if (value == null || value === '') {
    return null;
  }

  const trimValue = (value) => {
    if (Array.isArray(value)) {
      return value.map((item) => item.trim());
    } else {
      return value.trim();
    }
  };

  const getArrayValue = (value) => {
    if (Array.isArray(value)) {
      return value.map((item) => +item);
    }
  };

  switch (type) {
    case muiDataTableTypes.number:
      return odataOperator === 'in' ? getArrayValue(value) : +value;
    case muiDataTableTypes.boolean:
      return value === 'true';
    case muiDataTableTypes.date:
    case muiDataTableTypes.dateTime:
      return dayjs(value).toISOString();
    case muiDataTableTypes.singleSelect:
      return value === COLUMN_DROPDOWN_FILTER_NULL_OPTION_VALUE ? null : value;
    case muiDataTableTypes.dateRange:
      return dayjs(value).toISOString();
    default:
      return trimValue(value);
  }
};

const parseMuiToODataFilters = (columnsData, filterData) => {
  const defaultType = muiDataTableTypes.string;
  const nullableTypes = ['isEmpty', 'isNotEmpty'];
  let parsedFilters = {};

  const handleDefaultTypeFilter = ({ type, operator, field, value }) => {
    const odataOperator =
      filterMuiODataOperators[type]?.find(
        (item) => item[0] === operator
      )?.[1] || operator;

    parsedFilters[field] = {
      ...parsedFilters[field],
      [odataOperator]: handleValue(value, type, odataOperator)
    };
  };

  const handleDateRangeTypeFilter = ({ type, field, value }) => {
    const odataOperators = filterMuiODataOperators[type];

    parsedFilters[field] = {
      ...parsedFilters[field],
      [odataOperators[0]]: handleValue(value[0], type),
      [odataOperators[1]]: handleValue(value[1], type)
    };
  };

  const handleFullNameTypeFilter = ({ type, field, value }) => {
    const odataOperator = filterMuiODataOperators[type][0];

    const prefix = field.substring(0, field.indexOf('/') + 1);
    const firstNameKey = `${prefix}firstName`;
    const lastNameKey = `${prefix}lastName`;

    parsedFilters = {
      ...parsedFilters,
      [firstNameKey]: {
        [odataOperator]: value[Object.keys(value)[0]]?.trim()
      },
      [lastNameKey]: {
        [odataOperator]: value[Object.keys(value)[1]]?.trim()
      }
    };
  };

  filterData.items.forEach(({ field, operator, value }) => {
    if (
      (value == null ||
        value === '' ||
        (Array.isArray(value) && !value.length)) &&
      !nullableTypes.includes(operator)
    ) {
      return;
    }
    const type =
      columnsData.find((item) => item.field === field).type || defaultType;

    if (type === 'dateRange') {
      handleDateRangeTypeFilter({ type, operator, field, value });
    } else if (type === muiDataTableTypes.fullName) {
      handleFullNameTypeFilter({ type, operator, field, value });
    } else {
      handleDefaultTypeFilter({ type, operator, field, value });
    }
  });
  if (filterData.logicOperator === 'Or') {
    parsedFilters = {
      or: { ...parsedFilters }
    };
  }

  return parsedFilters;
};

const parseOperator = (type, value) => {
  const operatorType = filterMuiODataOperators[type];

  if (operatorType.length === 1) {
    return operatorType[0][0];
  }
  const filteredOperators = operatorType.filter((item) => item[1] === value[0]);

  if (filteredOperators.length) {
    return filteredOperators[0][0];
  }
  if (value[1] === null) {
    return filteredOperators.find((operators) =>
      operators[0].match(/^.*Empty$/)
    )[0];
  }
};

const toString = (value, type) => {
  if (value == null) {
    return undefined;
  } else if (Array.isArray(value)) {
    return value;
  }

  switch (type) {
    case muiDataTableTypes.number:
    case muiDataTableTypes.boolean:
      return String(value);
    case muiDataTableTypes.date:
    case muiDataTableTypes.dateTime:
    case muiDataTableTypes.dateRange:
      return dayjs(value).format(filterTimeFormat).toString();
    default:
      return value;
  }
};

const parseODataToMuiFilters = (columns, odataFilters) => {
  const defaultType = muiDataTableTypes.string;
  const muiFilters = [];

  Object.entries(odataFilters)?.map(([filterField, filterValue]) => {
    const type =
      columns.find(({ field }) => field === filterField).type || defaultType;
    if (type === muiDataTableTypes.dateRange) {
      muiFilters.push({
        operator: muiDataTableTypes.dateRange,
        field: filterField,
        value: [dayjs(filterValue.ge), dayjs(filterValue.le)]
      });
    } else if (type === muiDataTableTypes.fullName) {
      muiFilters.push({
        operator: muiDataTableTypes.fullName,
        field: filterField,
        value: { firstName: filterValue.contains }
      });
    } else {
      Object.entries(filterValue).forEach((value) => {
        muiFilters.push({
          operator: parseOperator(type, value),
          field: filterField,
          value: toString(value[1], type)
        });
      });
    }
  });
  return muiFilters;
};

const handleChangeFilter = (tableRef, filterParams) => {
  const {
    columns: { lookup: columnsLookup }
  } = tableRef.current.dataTableApiRef.state;
  const updatedFilterModel = parseODataToMuiFilters(
    Object.values(columnsLookup),
    filterParams
  );
  tableRef.current.dataTableApiRef.setFilterModel({
    items: updatedFilterModel
  });
};

const updFilterData = ($filter, initialValues) => {
  if (!$filter) return initialValues;

  const parsedFilters = JSON.parse($filter);

  return {
    ...initialValues,
    ...parsedFilters
  };
};

const getApplyFilterFn = (filterItem) => (rows) => {
  if (filterItem.operatorValue.start && filterItem.operatorValue.end) {
    const startDate = filterItem.operatorValue.start.toISOString();
    const endDate = filterItem.operatorValue.end.toISOString();
    return rows.filter((row) => row.date >= startDate && row.date <= endDate);
  }
  return rows;
};

const getFlexForField = (gap) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: `0 ${gap}px`,
  flexWrap: 'wrap',
  alignItems: 'center'
});

const parseQueryFilters = () => {
  const parsedLocation = qs.parse(location.search);
  const parsedFilters =
    parsedLocation?.$filter && JSON.parse(parsedLocation?.$filter);
  return parsedFilters || {};
};

const filterHelpers = {
  parseMuiToODataFilters,
  parseODataToMuiFilters,
  handleChangeFilter,
  updFilterData,
  getApplyFilterFn,
  getFlexForField,
  parseQueryFilters
};

export default filterHelpers;
