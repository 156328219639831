import dayjs from 'dayjs';
import * as Yup from 'yup';

import { dateShortTimeFormat } from '@constants/common';

export const defaultScheduledDateTime = dayjs();

export const schedulingFieldsSchema = Yup.object().shape({
  isNow: Yup.bool().label('Select notification frequency').required(),
  dateTime: Yup.date()
    .nullable()
    .label('Select date')
    .when('isNow', ([value], schema) => {
      value
        ? Yup.date()
            .min(defaultScheduledDateTime, 'Min allowed date is current date')
            .required('Select date is required')
        : schema.nullable();
    })
});

export const getInitialValues = (data) => ({
  isNow: true,
  ...data,
  dateTime: data?.dateTime
    ? dayjs(data?.dateTime).format(dateShortTimeFormat)
    : defaultScheduledDateTime
});

export const notificationFrequencyOptions = [
  {
    value: true,
    label: 'Now'
  },
  {
    value: false,
    label: 'Scheduled'
  }
];
