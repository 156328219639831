import React from 'react';
import { Link } from 'react-router-dom';

import { getColumns, initialFilterParams } from './config';

import { permissionsMap } from '@constants/permissions';
import routes from '@constants/routes';
import helpers from '@utils/helpers';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import Button from '@components/shared/Button/Button';
import { ActionsBox } from '@components/shared/MainLayout/MainLayout.components';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const CustomNotificationsListPage = () => (
  <MainLayout title="Custom Notifications">
    <ActionsBox>
      {helpers.getActionPermission(permissionsMap.notificationJobManage) && (
        <Button
          component={Link}
          to={routes.notifications.customNotifications.page.create}
        >
          Add new notification
        </Button>
      )}
    </ActionsBox>
    <ODataPaginatedTable
      getColumns={getColumns}
      usePaginatedDataOptions={{
        apiUrl: adminApiUrls.notificationJob.list,
        initialFilterParams: {
          $orderBy: 'createdDate desc',
          $filter: {
            ...initialFilterParams,
            IsCustom: {
              eq: true
            }
          },
          $expand: 'NotificationJobHistories($orderby=createdDate desc;$top=1)'
        }
      }}
      rowKey="notificationJobId"
      exportExcludeFields={[
        'notificationJobHistory.sendingAttempts',
        'notificationJobHistory.successfulSending'
      ]}
      customApi={AdminApi}
    />
  </MainLayout>
);
export default CustomNotificationsListPage;
