import React from 'react';
import PT from 'prop-types';
import { Link } from 'react-router-dom';

import routes from '@constants/routes';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/common';
import Button from '@components/shared/Button/Button';
import Typography from '@components/shared/Typography/Typography';
import Stack from '@components/shared/Stack/Stack';
import ListContentBlock from '@components/shared/ListContentBlock/ListContentBlock';
import CampaignsStats from '@components/Leads/campaignGroups/Details/CampaignsStats';

const CampaignDetails = ({ data }) => {
  const infoList = [
    {
      title: 'Campaign Group Name:',
      content: data?.name
    },
    {
      title: 'Hr look cap:',
      content: data?.hourlyLookCap
    },
    {
      title: 'Day look cap:',
      content: data?.dailyLookCap
    },
    {
      title: 'Day accept cap:',
      content: data?.dailyAcceptCap
    },
    {
      title: 'Hr accept cap:',
      content: data?.hourlyAcceptCap
    },
    {
      title: 'Day cost cap:',
      content: data?.dailyCostCap
    }
  ];

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={3}
        my={2}
      >
        <Typography variant="h3">Campaign Group Details</Typography>
        {helpers.getActionPermission(permissionsMap.leadGroupsManage) && (
          <Button
            component={Link}
            to={routes.leads.campaignGroups.page.edit.url(data.campaignGroupId)}
            variant="outlined"
          >
            Edit campaign group
          </Button>
        )}
      </Stack>

      <ListContentBlock contentList={infoList} />
      <CampaignsStats campaignGroupId={data.campaignGroupId} />
    </>
  );
};

CampaignDetails.defaultProps = {
  data: {}
};

CampaignDetails.propTypes = {
  data: PT.object
};

export default CampaignDetails;
