import React, { useMemo } from 'react';
import PT from 'prop-types';

import { employmentStepInitialValues, employmentStepSchema } from './schema';

import { dateFormat, fieldsMasks } from '@constants/common';
import helpers from '@utils/helpers';
import withStepData from '@components/Application/LoanForm/withStepData';
import StepContainer from '@components/Application/LoanForm/StepContainer';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Typography from '@components/shared/Typography/Typography';

const EmploymentStep = ({ initialValues, onSubmit, options }) => {
  const formattedOptions = useMemo(
    () => helpers.getOptionsObject(options),
    [options]
  );

  const mergedInitialValues = useMemo(
    () => helpers.mergeLoanFormData(initialValues, employmentStepInitialValues),
    [initialValues]
  );

  const handleSubmit = (values, handlers) =>
    onSubmit({ values, initialValues: mergedInitialValues }, handlers);

  return (
    <StepContainer
      formSchema={employmentStepSchema}
      initialValues={mergedInitialValues}
      onSubmit={handleSubmit}
      title="Employment"
    >
      <div className="loan-form__fields-block">
        <FormikField
          type="text"
          name="netPayPerPayroll"
          label="Net pay per check:"
          placeholder="e.g. 2500"
        />
        <FormikField
          type="autocomplete"
          name="employmentType"
          label="Employment type:"
          placeholder="Select"
          options={formattedOptions.employmentType}
        />
        <FormikField
          type="autocomplete"
          name="payFrequency"
          label="Pay frequency:"
          placeholder="Select"
          options={formattedOptions.payFrequency}
        />
        <FormikField
          type="autocomplete"
          name="payrollMethod"
          label="Payroll method:"
          placeholder="Select"
          options={formattedOptions.payrollMethod}
        />
        <FormikField
          type="autocomplete"
          name="payrollSource"
          label="Payroll source:"
          placeholder="Select"
          options={formattedOptions.payrollSource}
        />
        <FormikField
          type="date"
          name="dateOfHire"
          placeholder={dateFormat}
          format={dateFormat}
          label="Day of hire:"
        />
      </div>

      <Typography variant="h6" mt={1} mb={1}>
        Additional
      </Typography>
      <FormikField
        type="date"
        name="nextPayday"
        placeholder={dateFormat}
        format={dateFormat}
        label="Next Payday:"
      />
      <FormikField
        type="text"
        name="employerName"
        label="Employer Name:"
        placeholder="e.g. Sam Smith"
      />
      <FormikField
        type="mask"
        name="employerPhone"
        label="Employer Phone:"
        placeholder={fieldsMasks.phoneUSA.placeholder}
        mask={fieldsMasks.phoneUSA.mask}
        maskChar="X"
        plainValue
        fullWidth
      />
      <FormikField
        type="text"
        name="employerPhoneExtension"
        label="Employer Phone Extension:"
        placeholder="XXXXX"
      />
    </StepContainer>
  );
};

EmploymentStep.propTypes = {
  onSubmit: PT.func.isRequired,
  initialValues: PT.object.isRequired,
  options: PT.any.isRequired
};

export default withStepData(EmploymentStep);
