import omit from 'lodash/omit';
import qs from 'query-string';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import usePagination from './usePagination';
import { useQueryParams } from './useQueryParams';
import useFirstRenderFlag from './useFirstRenderFlag';

import { defaultPageSize } from '@constants/common';

export const defaultFilterParams = {
  take: defaultPageSize,
  offset: 0
};

export const useQueryPagination = ({
  listUrl,
  persistedFilters = {},
  initialFilters = defaultFilterParams,
  wait = 200,
  omitParamsArray = [],
  requestOptions,
  hiddenParams,
  skipFirstFetch = true,
  customApi = null,
  enabled
}) => {
  const firstRender = useFirstRenderFlag();
  const history = useHistory();
  const params = useQueryParams();

  const queryFilters = omit(params, ['recordsCount', '_filter']);

  const pagination = usePagination({
    skipFirstFetch,
    listUrl,
    initialFilters: {
      ...persistedFilters,
      ...initialFilters,
      ...queryFilters
    },
    initialPage: params.page,
    wait,
    hiddenParams,
    requestOptions,
    customApi,
    enabled
  });

  const { count, page, changePage, filters, changeFilters, isLoading } =
    pagination;

  useEffect(() => {
    const newFilters = omit({ ...initialFilters, ...params }, [
      'recordsCount',
      '_filter',
      ...omitParamsArray
    ]);

    if (JSON.stringify(newFilters) === JSON.stringify(filters) && !firstRender)
      return;

    changeFilters(newFilters, true);
  }, [history.location.search]);

  useEffect(() => {
    const queryPage = +params.page;
    if (!queryPage || page === queryPage) return;

    changePage(queryPage);
  }, [params.page]);

  useEffect(() => {
    if (!isLoading) {
      history.replace({
        search: qs.stringify({
          ...filters,
          ...params,
          recordsCount: count
        })
      });
    }
  }, [count, isLoading]);

  return pagination;
};
