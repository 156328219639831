import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import isNil from 'lodash/isNil';

import { permissionsMap } from '@constants/common';
import Logger from '@utils/logger';
import routes from '@constants/routes';
import Loader from '@components/shared/Loader';
import helpers from '@utils/helpers';
import { cleanObject } from '@utils/object-cleaner';
import NotificationsHistory from '@components/NotificationsHistory';
import TestNotificationButton from '@components/shared/TestNotificationButton';
import '../../Application/ApplicationDetails/ApplicationDetails.scss';
import Accordion from '@components/shared/Accordion/Accordion';
import Alert from '@components/shared/Alert/Alert';
import Typography from '@components/shared/Typography/Typography';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';
import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';
import Box from '@components/shared/Box/Box';
import ListContentBlock from '@components/shared/ListContentBlock/ListContentBlock';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const SystemNotificationsDetails = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const initialExtraParams = cleanObject({
    $orderBy: 'createdDate desc',
    $filter: {
      notificationJobId: {
        eq: data?.notificationJobId
      }
    }
  });
  const loading = !data && !error;

  const fetchData = useCallback(async () => {
    try {
      const record = await AdminApi.get(
        adminApiUrls.notificationJob.page(params.id),
        {
          params: {
            $expand: 'NotificationJobHistories($orderby=createdDate desc)'
          }
        }
      );

      setData(record);
    } catch (e) {
      Logger.error(e);
      setError(true);
    }
  }, [params.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const startAdditionalColumns = [
    {
      headerName: 'Action',
      field: 'actions',
      width: 55,
      sortable: false,
      renderCell: ({ row }) => (
        <Box size={4} mx="auto">
          <ShowDetailsActionButton
            path={routes.notifications.systemNotifications.historyPage.url(
              row.notificationJobHistoryId
            )}
          />
        </Box>
      )
    }
  ];

  const accordionListHistory = [
    {
      key: '1',
      header: 'Notification History',
      content: (
        <NotificationsHistory
          initialExtraParams={initialExtraParams}
          startAdditionalColumns={startAdditionalColumns}
        />
      )
    }
  ];

  if (loading) return <Loader />;

  if (error)
    return (
      <Alert type="error" title="Server error" message="Failed to load data" />
    );

  const notificationInfoList = [
    {
      title: 'Title:',
      content: data.jobTitle
    },
    {
      title: 'Message title:',
      content: data.messageTitle
    },
    {
      title: 'Message template:',
      content: data.messageTemplate
    },
    {
      title: 'Activated:',
      content: helpers.renderBooleanValue(data.isActive)
    },
    {
      title: 'Notification Type:',
      content: data.notificationType
    }
  ];

  return (
    <>
      <Stack
        my={2}
        direction="row"
        flexWrap="wrap"
        gap={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3">Notification Details</Typography>
        <Stack direction="row" gap={1}>
          {helpers.getActionPermission(
            permissionsMap.notificationJobManage
          ) && (
            <Button
              component={Link}
              to={routes.notifications.systemNotifications.page.edit.url(
                data.notificationJobId
              )}
            >
              Edit Notification
            </Button>
          )}
          <TestNotificationButton
            isCustom={false}
            notificationId={data.notificationJobId}
          />
        </Stack>
      </Stack>
      {!loading && !isNil(data) && (
        <>
          <ListContentBlock
            contentList={notificationInfoList}
            wrapperProps={{ mb: 2 }}
          />
          <Accordion accordionList={accordionListHistory} />
        </>
      )}
    </>
  );
};

export default SystemNotificationsDetails;
