import { styled } from '@mui/material/styles';
import MuiFormControl from '@mui/material/FormControl';

export const StyledFormControl = styled(MuiFormControl)(
  ({ filterVariant }) =>
    filterVariant && {
      marginTop: 0,
      '& .MuiFormLabel-root': {
        fontSize: '10px',
        top: '-6px',
        transform: 'none'
      }
    }
);
