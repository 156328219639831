import React from 'react';
import PT from 'prop-types';
import MuiArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import MuiArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import MuiVisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MuiNavigateNextIcon from '@mui/icons-material/NavigateNext';
import MuiMenuIcon from '@mui/icons-material/Menu';
import MuiMenuOpenIcon from '@mui/icons-material/MenuOpen';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import MuiDeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MuiAccountCircle from '@mui/icons-material/AccountCircle';
import MuiMoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MuiCheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MuiSearchIcon from '@mui/icons-material/Search';
import MuiSaveAltIcon from '@mui/icons-material/SaveAlt';
import MuiLoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import MuiCloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MuiDownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import MuiFileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import MuiPlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import MuiListOutlinedIcon from '@mui/icons-material/ListOutlined';
import MuiSettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import MuiDescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MuiFileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import MuiPeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import MuiPlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import MuiPaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import MuiNotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import MuiAppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined';
import MuiSupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import MuiPersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MuiListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import MuiCheckListIcon from '@mui/icons-material/Checklist';
import MuiSettingsIcon from '@mui/icons-material/Settings';
import MuiPasswordIcon from '@mui/icons-material/Password';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import MuiAddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import MuiCircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import MuiLockIcon from '@mui/icons-material/Lock';
import MuiLockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import MuiAddCircleIcon from '@mui/icons-material/AddCircle';
import MuiRemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MuiCompareIcon from '@mui/icons-material/Compare';
import MuiEmojiEventsIconIcon from '@mui/icons-material/EmojiEvents';
import MuiLibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

const defaultIconSize = '18px';
const menuIconSize = '22px';

export const ArrowBackIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiArrowBackIosOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const ArrowForwardIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiArrowForwardIosOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const VisibilityIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiVisibilityOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const NavigateNextIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiNavigateNextIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const MenuIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiMenuIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const MenuOpenIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiMenuOpenIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const EditIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <BorderColorOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const PasswordIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiPasswordIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const DeleteIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiDeleteOutlineOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const AccountIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiAccountCircle sx={{ width: size, height: size, ...sx }} {...props} />
);
export const BurgerMenuIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiMoreHorizIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const CheckCircleIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiCheckCircleOutlineIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const SearchIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiSearchIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const FileDownloadIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiSaveAltIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const LoginIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiLoginOutlinedIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const CloseIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiCloseOutlinedIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const DownloadIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiDownloadOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const FileUploadIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiFileUploadOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const PauseIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <PauseCircleOutlineOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const PlayIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiPlayCircleOutlineOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const DashboardIcon = ({ size = menuIconSize, sx, ...props }) => (
  <MuiListOutlinedIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const ConfigurationIcon = ({ size = menuIconSize, sx, ...props }) => (
  <MuiSettingsApplicationsOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const ApplicationIcon = ({ size = menuIconSize, sx, ...props }) => (
  <MuiDescriptionOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const LogsIcon = ({ size = menuIconSize, sx, ...props }) => (
  <MuiFileCopyOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const ApplicationUsersIcon = ({ size = menuIconSize, sx, ...props }) => (
  <MuiPeopleAltOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const UserLoansIcon = ({ size = menuIconSize, sx, ...props }) => (
  <MuiPlaylistAddCheckOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const PaymentsIcon = ({ size = menuIconSize, sx, ...props }) => (
  <MuiPaymentsOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const NotificationsIcon = ({ size = menuIconSize, sx, ...props }) => (
  <MuiNotificationsOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const VersionsIcon = ({ size = menuIconSize, sx, ...props }) => (
  <MuiAppSettingsAltOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const LeadIcon = ({ size = menuIconSize, sx, ...props }) => (
  <MuiSupervisedUserCircleOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);

export const PersonIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiPersonOutlineOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const ListIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiListAltOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const CheckListIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiCheckListIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const SettingsIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiSettingsIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const DotIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <FiberManualRecordIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const ArrowUpIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <ExpandLessOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const ArrowDownIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <ExpandMoreOutlinedIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);
export const ResetFiltersIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <FilterListOffIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const AddIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiAddIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const RefreshIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <CachedIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const CircleOutlinedIcon = ({
  size = defaultIconSize,
  sx,
  ...props
}) => (
  <MuiCircleOutlinedIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const LockIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiLockIcon sx={{ width: size, height: size, ...sx }} {...props} />
);
export const LockOpenIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiLockOpenIcon sx={{ width: size, height: size, ...sx }} {...props} />
);

export const AddCircleIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiAddCircleIcon sx={{ width: size, height: size, ...sx }} {...props} />
);

export const EyeIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiRemoveRedEyeIcon sx={{ width: size, height: size, ...sx }} {...props} />
);

export const CompareIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiCompareIcon sx={{ width: size, height: size, ...sx }} {...props} />
);

export const WallboardIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiEmojiEventsIconIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);

export const BinCheckerIcon = ({ size = defaultIconSize, sx, ...props }) => (
  <MuiLibraryAddCheckIcon
    sx={{ width: size, height: size, ...sx }}
    {...props}
  />
);

export const propTypes = {
  size: PT.number,
  sx: PT.object
};

ArrowBackIcon.propTypes = propTypes;
ArrowForwardIcon.propTypes = propTypes;
VisibilityIcon.propTypes = propTypes;
NavigateNextIcon.propTypes = propTypes;
MenuIcon.propTypes = propTypes;
MenuOpenIcon.propTypes = propTypes;
EditIcon.propTypes = propTypes;
DeleteIcon.propTypes = propTypes;
AccountIcon.propTypes = propTypes;
BurgerMenuIcon.propTypes = propTypes;
CheckCircleIcon.propTypes = propTypes;
SearchIcon.propTypes = propTypes;
FileDownloadIcon.propTypes = propTypes;
LoginIcon.propTypes = propTypes;
ArrowForwardIcon.propTypes = propTypes;
CloseIcon.propTypes = propTypes;
DownloadIcon.propTypes = propTypes;
FileUploadIcon.propTypes = propTypes;
PauseIcon.propTypes = propTypes;
PlayIcon.propTypes = propTypes;
DashboardIcon.propTypes = propTypes;
ConfigurationIcon.propTypes = propTypes;
ApplicationIcon.propTypes = propTypes;
LogsIcon.propTypes = propTypes;
ApplicationUsersIcon.propTypes = propTypes;
UserLoansIcon.propTypes = propTypes;
PaymentsIcon.propTypes = propTypes;
NotificationsIcon.propTypes = propTypes;
VersionsIcon.propTypes = propTypes;
LeadIcon.propTypes = propTypes;
PersonIcon.propTypes = propTypes;
ListIcon.propTypes = propTypes;
ListIcon.propTypes = propTypes;
CheckListIcon.propTypes = propTypes;
SettingsIcon.propTypes = propTypes;
PasswordIcon.propTypes = propTypes;
DotIcon.propTypes = propTypes;
ArrowUpIcon.propTypes = propTypes;
ArrowDownIcon.propTypes = propTypes;
ResetFiltersIcon.propTypes = propTypes;
AddIcon.propTypes = propTypes;
RefreshIcon.propTypes = propTypes;
CircleOutlinedIcon.propTypes = propTypes;
LockIcon.propTypes = propTypes;
LockOpenIcon.propTypes = propTypes;
AddCircleIcon.propTypes = propTypes;
EyeIcon.propTypes = propTypes;
CompareIcon.propTypes = propTypes;
WallboardIcon.propTypes = propTypes;
BinCheckerIcon.propTypes = propTypes;
