import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export const dashboardFiltersInitialValues = {
  startDate: dayjs().subtract(1, 'month').startOf('day').toISOString(),
  endDate: dayjs().endOf('day').toISOString(),
  startTime: dayjs().subtract(1, 'day').startOf('day').toISOString(),
  endTime: dayjs().endOf('day').toISOString()
};

export const selectDashboardFilters = ({ dashboardFilters }) =>
  dashboardFilters;

const dashboardFiltersSlice = createSlice({
  name: 'dashboardFilters',
  initialState: dashboardFiltersInitialValues,
  reducers: {
    setFilters: (state, { payload }) => ({
      ...state,
      ...payload
    }),
    resetFilters: () => dashboardFiltersInitialValues
  }
});

export const { setFilters, resetFilters } = dashboardFiltersSlice.actions;
export default dashboardFiltersSlice.reducer;
