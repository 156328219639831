import React, { memo, useState } from 'react';
import PT from 'prop-types';

import Button from '@components/shared/Button/Button';

const ShowActiveButton = ({
  showActive: _showActive,
  setShowActive,
  params,
  setParams,
  setExpandAllTables
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const showCampaigns = (showActive) => {
    setIsProcessing(true);

    setShowActive(!showActive);
    setParams({
      ...params,
      offset: 0,
      isActive: !showActive ? 'true' : null
    });

    setExpandAllTables(true);
    setIsProcessing(false);
  };

  return (
    <Button
      onClick={() => showCampaigns(_showActive)}
      loading={isProcessing}
      disabled={isProcessing}
      variant="outlined"
    >
      Show {_showActive ? 'All' : 'Active'}
    </Button>
  );
};

ShowActiveButton.propTypes = {
  isActiveKey: PT.string.isRequired,
  showActive: PT.bool,
  params: PT.object,
  setParams: PT.func.isRequired,
  setShowActive: PT.func,
  setExpandAllTables: PT.func.isRequired
};

export default memo(ShowActiveButton);
