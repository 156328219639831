import React from 'react';
import { Link } from 'react-router-dom';

import {
  commonLeadProviderStatsColumns,
  mainLeadProviderId
} from '@constants/leads';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';
import Box from '@components/shared/Box/Box';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import routes from '@constants/routes';
import { noFieldData } from '@constants/common';
import ConfirmActionSwitch from '@components/shared/DataTable/actions/ConfirmActionSwitch';

const notToShowAction = 'eLoanWarehouse';

export const getColumns = ({ handleStatus, showActive }) => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    renderCell: ({ row }) => {
      if (row.name === notToShowAction) {
        return;
      }
      return (
        <Box size={4}>
          <ConfirmActionSwitch
            size="small"
            checked={row.status}
            hidden={
              !helpers.getActionPermission(permissionsMap.leadProvidersManage)
            }
            modalProps={{
              itemId: row.leadProviderId,
              content: row.status
                ? 'Do you want to pause?'
                : 'Do you want to resume?',
              onConfirm: () =>
                row.status
                  ? handleStatus(row.leadProviderId, true, showActive)
                  : handleStatus(row.leadProviderId, false, showActive)
            }}
          />
        </Box>
      );
    }
  },
  {
    headerName: 'Lead provider name',
    field: 'name',
    width: 200,
    renderCell: ({ row }) =>
      helpers.getActionPermission(permissionsMap.leadProvidersView) &&
      row.leadProviderId !== mainLeadProviderId ? (
        <Tooltip title="Lead Provider Page">
          <Link
            to={routes.leads.leadProviders.page.view.url(row.leadProviderId)}
          >
            {row.name}
          </Link>
        </Tooltip>
      ) : (
        row.name || noFieldData
      )
  },
  ...commonLeadProviderStatsColumns
];
