import React from 'react';

import BankCheckImage from '@assets/images/bank-check.svg';

const BankExample = () => (
  <>
    <div className="bank-example__label">Example</div>
    <div className="bank-example__wrap mb-3">
      <img src={BankCheckImage} alt="" className="bank-example__image" />
    </div>
  </>
);

export default BankExample;
