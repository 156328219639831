import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { getFilledColumns, initialFilterParams } from './config';

import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import routes from '@constants/routes';
import Loader from '@components/shared/Loader';
import { selectAppVersions } from '@store/settings';
import { fetchAppVersions } from '@store/settings/actions';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const DeviceInformation = ({ id }) => {
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const appVersions = useSelector(selectAppVersions);
  const dispatch = useDispatch();

  useEffect(() => {
    history.push({
      pathname: routes.users.page.view.url(id),
      search: ''
    });
  }, []);

  useEffect(() => {
    if (!history.location.search) {
      setLoader(false);
    }
  }, [history.location?.search]);

  useEffect(() => {
    if (!appVersions) {
      dispatch(fetchAppVersions());
    }
  }, [appVersions, dispatch]);

  if (appVersions === null) {
    return <Loader />;
  }

  const getColumns = getFilledColumns({
    appVersions: appVersions.map((name) => ({
      value: name,
      label: name
    }))
  });

  if (loader) return <Loader />;

  return (
    <ODataPaginatedTable
      getColumns={getColumns}
      usePaginatedDataOptions={{
        apiUrl: adminApiUrls.userAgents,
        initialFilterParams: {
          $orderBy: 'lastLoginDate desc',
          $filter: {
            ...initialFilterParams,
            userId: {
              eq: id
            }
          }
        }
      }}
      rowKey="agentId"
      exportFileName={`Device History by user ${id}`}
      customApi={AdminApi}
    />
  );
};

DeviceInformation.propTypes = {
  id: PT.string.isRequired
};

export default DeviceInformation;
