import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import PT from 'prop-types';
import qs from 'query-string';
import { useHistory } from 'react-router';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

import { ActionsSection } from '@components/shared/MainLayout/MainLayout.components';
import { getFieldsForExportCSV } from '@components/shared/DataTable/DataTable.utils';
import Button from '@components/shared/Button/Button';
import DataTable from '@components/shared/DataTable/DataTable';
import { usePaginatedData, defaultFilterParams } from '@hooks/usePaginatedData';
import helpers from '@utils/helpers';
import filterHelpers from '@utils/filterHelpers';
import { useQueryParams } from '@hooks/useQueryParams';

const ODataPaginatedTable = ({
  getColumns,
  rowKey,
  usePaginatedDataOptions,
  getDetailPanelContent,
  detailPanelHeight,
  exportCSV,
  exportFileName,
  exportExcludeFields,
  tableProps,
  expandedByDefault,
  additionalActionButtons,
  tableRef,
  withRefreshButton,
  customApi,
  signal,
  exportLastNameField,
  parseBooleans
}) => {
  const {
    data,
    filterParams,
    setFilterParams,
    setData,
    getData,
    setOrder,
    totalAmount,
    loading,
    paginationOptions
  } = usePaginatedData({ ...usePaginatedDataOptions, customApi, signal });

  const timeoutRef = useRef(null);
  const dataTableApiRef = useRef(null);

  const [initialParams, setInitialParams] = useState(null);
  const history = useHistory();
  const searchParams = useQueryParams();

  useImperativeHandle(tableRef, () => ({
    fetchData: getData,
    dataTableApiRef: dataTableApiRef.current
  }));

  const updatePaginationAfterDelete = (
    arrayOfDeletableIds = [],
    compareFunction
  ) => {
    setData((data) =>
      data.filter((record) => compareFunction(record, arrayOfDeletableIds))
    );
    const deletedAmount = arrayOfDeletableIds?.length;

    setFilterParams(
      {
        ...qs.parse(history.location.search),
        $filter: {},
        $skip:
          totalAmount - deletedAmount - filterParams.$skip
            ? filterParams.$skip
            : filterParams.$skip
              ? filterParams.$skip - filterParams.$top
              : 0
      },
      true
    );
  };

  const columns = useMemo(
    () => getColumns({ updatePaginationAfterDelete }),
    [getColumns]
  );

  const tableFilters = useMemo(
    () =>
      columns
        .filter((item) => item.filterable !== false)
        .map((item) => item.field),
    [columns]
  );

  const exportCSVApiUrl = useMemo(() => {
    if (exportCSV && history?.location?.search) {
      return `${usePaginatedDataOptions.apiUrl}?${helpers.parseCSVODataRequest(
        qs.parse(history.location.search, {
          parseBooleans,
          parseNumbers: true
        })
      )}`;
    }
  }, [history?.location?.search, exportCSV]);

  const csvFields = useMemo(
    () =>
      getFieldsForExportCSV(columns, exportExcludeFields, exportLastNameField),
    [columns, exportExcludeFields]
  );

  useEffect(() => {
    if (!initialParams) {
      const odataFilters = pick(filterParams.$filter, tableFilters);
      const parsedParams = {
        filter: {
          filterModel: {
            items: []
          }
        },
        sorting: {
          sortModel: []
        }
      };

      parsedParams.filter.filterModel.items =
        filterHelpers.parseODataToMuiFilters(columns, odataFilters);

      if (filterParams.$orderBy) {
        const sortData = filterParams.$orderBy.split(' ');

        parsedParams.sorting.sortModel = [
          { field: sortData[0], sort: sortData[1] }
        ];
      }
      setInitialParams(parsedParams);
    }
  }, [filterParams]);

  const handleFiltersChange = useCallback(
    debounce(({ filterModel }) => {
      if (!filterModel?.items?.length) {
        return null;
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      const { $filter, $orderBy, $expand, $skip } = qs.parse(
        history.location.search
      );
      let oldFilterData = {};
      if ($filter) {
        oldFilterData = JSON.parse($filter);
      }

      const newFilterData = filterHelpers.parseMuiToODataFilters(
        columns,
        filterModel
      );

      if (isEqual(oldFilterData, newFilterData)) {
        return;
      }

      const updatedSearchParams = {
        ...defaultFilterParams,
        ...searchParams,
        $orderBy: $orderBy || undefined,
        $expand: $expand || undefined,
        $filter: JSON.stringify({
          ...(typeof $filter === 'string' && JSON.parse($filter)),
          ...omit(oldFilterData, tableFilters),
          ...newFilterData
        }),
        $skip
      };

      const updatedQueryString = qs.stringify(updatedSearchParams, {
        skipNull: true
      });

      return history.push({
        pathname: history.location.pathname,
        search: updatedQueryString
      });
    }, 1000),
    []
  );

  const resetAllFilters = () => {
    const odataFilters = pick(filterParams?.$filter, tableFilters);

    if (Object.keys(odataFilters).length) {
      setInitialParams({});
    }

    dataTableApiRef?.current?.setFilterModel({ items: [] });

    history.push({
      pathname: history.location.pathname,
      search: qs.stringify({
        ...qs.parse(history.location.search),
        $filter: JSON.stringify(
          usePaginatedDataOptions.initialFilterParams?.$filter
        ),
        $skip: 0
      })
    });
  };

  const isFilterEmpty = useMemo(() => {
    const odataFilters = pick(filterParams?.$filter, tableFilters);
    const appliedAddnFilters = isEqual(
      filterParams?.$filter,
      usePaginatedDataOptions.initialFilterParams?.$filter
    );

    return appliedAddnFilters || isEmpty(odataFilters);
  }, [filterParams.$filter, tableFilters]);

  return (
    <>
      {(additionalActionButtons || withRefreshButton) && (
        <ActionsSection
          display="inline-flex"
          sx={{ pt: filterParams?.['filterFields[]']?.length ? 1 : 0 }}
        >
          {additionalActionButtons?.map((button) => button)}
          {withRefreshButton && (
            <Button variant="outlined" onClick={getData}>
              Refresh
            </Button>
          )}
        </ActionsSection>
      )}
      {initialParams && (
        <DataTable
          apiRef={dataTableApiRef}
          tableData={data}
          columns={columns}
          rowKey={rowKey}
          totalAmount={totalAmount}
          loading={loading}
          paginationOptions={paginationOptions}
          onFilterChange={handleFiltersChange}
          onSortingChange={(sortData) => setOrder(null, sortData)}
          setOrder={setOrder}
          initialParams={initialParams}
          detailPanelHeight={detailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          expandedByDefault={expandedByDefault}
          exportCsvProps={{
            fileName: exportFileName,
            apiUrl: exportCSVApiUrl,
            customApiBaseUrl: customApi?.defaults.baseURL,
            fields: csvFields,
            fromServer: true,
            hide: !exportCSV
          }}
          filtersProps={{
            resetHandle: resetAllFilters,
            disableReset: isFilterEmpty
          }}
          {...tableProps}
        />
      )}
    </>
  );
};

ODataPaginatedTable.defaultProps = {
  tableProps: {},
  exportCSV: true,
  exportExcludeFields: [],
  rowKey: 'id',
  getDetailPanelContent: null,
  parseBooleans: true
};

ODataPaginatedTable.propTypes = {
  tableRef: PT.object,
  getColumns: PT.func.isRequired,
  usePaginatedDataOptions: PT.object.isRequired,
  exportCSV: PT.bool,
  exportFileName: PT.string,
  exportExcludeFields: PT.array,
  tableProps: PT.object,
  rowKey: PT.string,
  getDetailPanelContent: PT.func,
  detailPanelHeight: PT.number,
  expandedByDefault: PT.array,
  additionalActionButtons: PT.array,
  withRefreshButton: PT.bool,
  customApi: PT.object,
  signal: PT.instanceOf(AbortSignal),
  exportLastNameField: PT.string,
  parseBooleans: PT.bool
};

export default ODataPaginatedTable;
