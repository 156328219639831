import React from 'react';
import PT from 'prop-types';
import { Link } from 'react-router-dom';

import helpers from '@utils/helpers';
import routes from '@constants/routes';
import { permissionsMap } from '@constants/permissions';
import ListContentBlock from '@components/shared/ListContentBlock/ListContentBlock';
import Typography from '@components/shared/Typography/Typography';
import Accordion from '@components/shared/Accordion/Accordion';
import Button from '@components/shared/Button/Button';

const FlatPageDetails = ({ data }) => {
  const pageInfoList = [
    {
      title: 'Page ID:',
      content: data?.contentEntityId
    },
    {
      title: 'Page title:',
      content: data?.title
    },
    {
      title: 'Page slug:',
      content: data?.key
    },
    {
      title: 'Published:',
      content: data?.publish ? 'Yes' : 'No'
    }
  ];

  const accordionList = [
    {
      key: '1',
      header: 'Page content',
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: data.value
          }}
        />
      )
    }
  ];

  return (
    <>
      <Typography variant="h3" my={1}>
        Role Details
      </Typography>
      <ListContentBlock contentList={pageInfoList} wrapperProps={{ mb: 2 }} />

      {helpers.getActionPermission(permissionsMap.flatManage) && (
        <Button
          component={Link}
          to={routes.flatPages.page.edit.url(data.contentEntityId)}
        >
          Edit Page
        </Button>
      )}

      <Accordion
        accordionList={accordionList}
        expandedAllByDefault
        wrapperProps={{
          my: 2
        }}
      />
    </>
  );
};

FlatPageDetails.defaultProps = {
  data: {}
};

FlatPageDetails.propTypes = {
  data: PT.object
};

export default FlatPageDetails;
