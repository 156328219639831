import * as Yup from 'yup';

export const costTypeOptions = [
  {
    value: 'Fixed',
    label: 'Fixed',
    key: 0
  },
  {
    value: 'PriceReject',
    label: 'Price reject',
    key: 1
  },
  {
    value: 'CostPerFounded',
    label: 'Cost per founded',
    key: 2
  }
];

export const campaignTypeOptions = [
  {
    value: 0,
    label: 'Default',
    key: 0
  },
  {
    value: 1,
    label: 'Paywallet',
    key: 1
  },
  {
    value: 2,
    label: 'Installment loan auto',
    key: 2
  }
];

export const priceRejectValue = costTypeOptions.find(
  (el) => el.value === 'PriceReject'
).value;

export const campaignFieldsSchema = Yup.object().shape({
  campaignName: Yup.string().label('Name').required(),
  status: Yup.bool(),
  storeKey: Yup.string().label('Name'),
  dailyLookCap: Yup.number().label('Day Look cap').required(),
  hourlyLookCap: Yup.number().label('Hr look cap').required(),
  dailyAcceptCap: Yup.number().label('Day accept cap').required(),
  hourlyAcceptCap: Yup.number().label('Hr accept cap').required(),
  dailyCostCap: Yup.number().label('Day cost cap').required(),
  cost: Yup.number().label('Day cap').required(),
  providerType: Yup.string().label('Cost type').required(),
  underwritingKey: Yup.string().label('Underwriting key').nullable(),
  lmsLogin: Yup.string().label('LMS login').nullable(),
  lmsPassword: Yup.string().label('LMS password').nullable(),
  strategyId: Yup.number()
    .oneOf(campaignTypeOptions.map((item) => item.value))
    .label('Campaign Type')
    .required()
});

export const getInitialValues = (data) => ({
  campaignName: '',
  status: false,
  storeKey: '',
  dailyLookCap: '',
  hourlyLookCap: '',
  dailyAcceptCap: '',
  hourlyAcceptCap: '',
  dailyCostCap: '',
  cost: '',
  providerType: '',
  underwritingKey: '',
  lmsLogin: '',
  lmsPassword: '',
  strategyId: '',
  ...data
});
