import React from 'react';
import PT from 'prop-types';
import { Editor as TimEditor } from '@tinymce/tinymce-react';

import './Editor.scss';

const Editor = ({ value, onChange, name, initOptions, ...props }) => (
  <>
    <TimEditor
      {...props}
      tinymceScriptSrc={
        window.location.origin + '/externalAssets/editor/tinymce/tinymce.min.js'
      }
      value={value}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link charmap print preview anchor directionality',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount table code'
        ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist | link | outdent indent | table | removeformat | code | help',
        ...initOptions
      }}
      onEditorChange={(newValue) => {
        onChange({
          target: {
            name,
            value: newValue
          }
        });
      }}
    />
  </>
);

Editor.propTypes = {
  name: PT.string.isRequired,
  value: PT.string.isRequired,
  onChange: PT.func.isRequired,
  initOptions: PT.object
};

export default Editor;
