import React, { useRef } from 'react';
import PT from 'prop-types';
import { ErrorMessage } from 'formik';

import { CSV_INPUT_NAME } from '@constants/notificationFormConfig';
import FormError from '@components/shared/Form/Error';
import Button from '@components/shared/Button/Button';
import Box from '@components/shared/Box/Box';
import { DeleteIcon, FileUploadIcon } from '@components/shared/icons/Icons';
import Stack from '@components/shared/Stack/Stack';
import TextField from '@components/shared/TextField/TextField';
import IconButton from '@components/shared/IconButton/IconButton';
import { palette } from '@utils/theme';

const ImportCSVButton = ({
  file,
  handleUploadFile,
  handleRemoveFile,
  wrapperProps,
  title = 'Upload CSV',
  loading
}) => {
  const inputRef = useRef(null);
  const handleUpload = (e) => {
    const [file] = e.target.files;
    if (!file) {
      return;
    }
    handleUploadFile(file);
  };

  return (
    <Box {...wrapperProps}>
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={handleUpload}
        accept=".csv"
      />
      <Button
        startIcon={<FileUploadIcon />}
        onClick={() => inputRef.current.click()}
        variant="outlined"
        my={2}
        loading={loading}
      >
        {title}
      </Button>
      {!!file && (
        <Stack
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
          gutter={16}
          gap={1}
        >
          <Box>
            <TextField value={file.FileName || file.name} readOnly />
          </Box>
          <Box>
            <IconButton
              color={palette.error}
              icon={<DeleteIcon />}
              onClick={() => handleRemoveFile(inputRef)}
            />
          </Box>
        </Stack>
      )}
      <Box>
        <ErrorMessage name={CSV_INPUT_NAME}>
          {(error) => <FormError error={error}></FormError>}
        </ErrorMessage>
      </Box>
    </Box>
  );
};

ImportCSVButton.propTypes = {
  file: PT.object,
  handleUploadFile: PT.func.isRequired,
  handleRemoveFile: PT.func.isRequired,
  wrapperProps: PT.object,
  isRow: PT.bool,
  title: PT.string,
  loading: PT.bool
};

export default ImportCSVButton;
