export const targetGroups = {
  custom: 11,
  bulkCsvNotification: 28,
  newCustomer: 35
};

export const jobTypeOptions = [
  {
    label: 'Basic filters',
    value: targetGroups?.custom
  },
  {
    label: 'Users Without an Account',
    value: targetGroups?.newCustomer
  },
  {
    label: 'Import CSV',
    value: targetGroups?.bulkCsvNotification
  }
];

export const requiredCSVFields = [
  {
    fieldName: 'email',
    fieldFormat: 'string',
    comments: 'Optional if "phoneNumber" is specified'
  },
  {
    fieldName: 'phoneNumber',
    fieldFormat: 'integer number',
    comments: 'Optional if "email" is specified'
  }
];

export const sampleCSVFileFormat = {
  names: ['Email', 'PhoneNumber'],
  values: ['john.doe@gmail.com', '0123456789']
};

export const emptyFormErrorMessage = 'Please fill out at least one field';
export const emptyCSVErrorMessage = 'Please upload a CSV file';
