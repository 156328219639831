import { styled } from '@mui/material/styles';
import { DataGridPremium } from '@mui/x-data-grid-premium';

import {
  cellClassNameRed,
  totalValueCellClassName
} from './DataTable.constants';

import { FormAutocomplete } from '@components/shared/Form/Select/Select';
import Box from '@components/shared/Box/Box';
import Menu from '@components/shared/Menu/Menu';
import { palette } from '@utils/theme';

export const StyledDataGrid = styled(DataGridPremium)`
  && {
    margin-top: 10px;
    border: 1px solid ${palette.border};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    .MuiDataGrid-row.Mui-hovered {
      background-color: ${palette.tableHoverColor};
    }
    .MuiDataGrid {
      &-row {
        color: ${palette.dark};
        cursor: ${({ onRowClick }) => (onRowClick ? 'pointer' : 'default')};
        position: relative;
      }

      &-columnHeader,
      &-cell {
        outline: none !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
        justify-content: left;
      }

      &-columnHeaderTitleContainer,
      &-columnHeaderDraggableContainer {
        flex-direction: row;
        justify-content: left;
      }

      &-cell {
        &.${totalValueCellClassName} {
          font-weight: 600 !important;

          &::before {
            content: '';
            border-top: 1px solid ${palette.darkBackground};
            width: 100%;
            height: 1px;
            position: absolute;
            top: 0;
            left: 0;
          }

          & > div {
            font-weight: 600 !important;
            color: ${palette.dark} !important;
          }
        }

        &.${cellClassNameRed} {
          background-color: ${palette.redCellColor} !important;
        }

        &[data-field='__row_group_by_columns_group__'] {
          &::before {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }

      &-aggregationColumnHeaderLabel {
        display: none;
      }

      &-columnHeaders,
      &-columnHeader {
        max-height: ${({ unstable_headerFilters }) =>
          unstable_headerFilters ? '60px' : '30px'} !important;
        min-height: ${({ unstable_headerFilters }) =>
          unstable_headerFilters ? '60px' : '30px'} !important;
        background-color: ${palette.tableHeaderBackground};
      }

      &-columnHeader {
        &[aria-label='Actions'] {
          padding-bottom: 4px !important;
          line-height: 1em;
        }
      }

      &-columnHeadersInner {
        max-height: ${({ unstable_headerFilters }) =>
          unstable_headerFilters ? '60px' : '30px'} !important;
        min-height: ${({ unstable_headerFilters }) =>
          unstable_headerFilters ? '60px' : '30px'} !important;

        & > div:nth-of-type(1) {
          height: 28px !important;

          & > div {
            max-height: 28px !important;
            min-height: 28px !important;
          }
        }

        & > div:nth-of-type(2) {
          height: 28px !important;

          & > div {
            max-height: 28px !important;
            min-height: 28px !important;

            .MuiInputBase-formControl {
              font-size: 12px;
              height: 16px;
              margin-top: 12px;

              & > .MuiInput-input {
                max-height: 14px;
                padding: 4px !important;
              }
            }
          }
        }
      }

      &-columnHeaderTitle {
        font-weight: 600;
        color: ${palette.dark};
      }

      &-columnHeaderRow {
        background-color: red;
      }

      &-footerContainer,
      &-virtualScroller {
        background-color: ${palette.rowBackground};
      }

      &-pinnedColumns,
      &-pinnedColumnHeaders {
        .MuiDataGrid-cell {
          display: flex;
          justify-content: center;
        }
      }

      &-pinnedColumns--left {
        .MuiDataGrid-cell {
          justify-content: flex-start;
        }
      }

      &-pinnedRows {
        display: ${({ rows }) => !rows?.length && 'none'};
      }

      &-virtualScroller {
        min-height: ${({ rows }) => (!rows?.length ? '120px' : 'initial')};
      }

      &-detailPanel {
        border-bottom: 2px solid ${palette.darkBackground};
      }

      &-toolbarContainer {
        justify-content: center;
        background-color: ${palette.tableHeaderBackground};

        & > div {
          display: none;
        }
      }

      &-groupingCriteriaCell {
        min-width: 250px;
      }
      &-groupingCriteriaCellToggle {
        margin-right: 0;
      }

      &-aggregationColumnHeader {
        &-alignRight {
          align-items: flex-start;
        }
      }
    }

    & .MuiTablePagination {
      &-selectLabel,
      &-displayedRows {
        margin-top: 0;
        margin-bottom: 0;
      }

      &-select {
        width: 35px;
        display: flex;
        border: 1px solid ${palette.border};
        border-radius: 4px;
        padding-right: 20px !important;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
      }
    }

    // This is a hack to hide the group field by filter option in the DataTable component. Remove this when the issue is fixed in MUI lib.
    .MuiNativeSelect-select option[value='__row_group_by_columns_group__'] {
      display: none;
    }

    .MuiInputLabel-root {
      position: absolute;
      top: 9px;
      left: 14px;
    }

    .MuiDataGrid-pinnedColumnHeaders.MuiDataGrid-pinnedColumnHeaders--right.MuiDataGrid-withBorderColor {
      padding-right: 0 !important;
    }
  }
`;

export const StyledDeleteModal = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    marginTop: '-10px',
    padding: '5px 15px',
    overflow: 'visible',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      right: 'calc(50% - 5px)',
      left: 'calc(50% - 5px)',
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(50%) rotate(45deg)',
      backgroundColor: '#fff',
      zIndex: 0
    }
  }
}));

export const LoaderWrapper = styled(Box)`
  height: 100%;
  background-color: ${palette.tableLoaderBackground};
`;

export const StyledFormAutocomplete = styled(FormAutocomplete)`
  && {
    .MuiAutocomplete-root {
      min-width: 70px;
    }

    .MuiFormControl-root {
      margin-top: 0;
      margin-bottom: 0;
    }

    .MuiInputBase-input {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }
  }
`;
