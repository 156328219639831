import dayjs from 'dayjs';

import { dateFormat, filterOperators } from '@constants/common';
import {
  aggregateGroupByFormula,
  formatValueWithCurrency,
  formatValueWithPercentage,
  getTotalAndSubtotalCellClassName,
  renderTotalAndSubtotalCell
} from '@components/shared/DataTable/DataTable.utils';
import {
  calculateCpaValue,
  calculateCpfValue,
  calculateDataCpfValue,
  calculateLeadCpfValue,
  getAppRatePercentage,
  getConversionPercentage,
  getDuplicateLeadsPercentage,
  getFpdRatePercentage,
  getFpmRatePercentage,
  getFundRatePercentage,
  getRedirectRate,
  getRoiPercentage,
  getSpdRatePercentage
} from '@components/LeadReports/LeadReports.utils';

export const stackReportMainGroupingField = 'datePeriod';

const mapMainGroupingFieldToHeaderName = (mainGroupingField) => {
  switch (mainGroupingField) {
    case 'leadProviderName':
      return 'Lead provider';
    case stackReportMainGroupingField:
      return 'Date Period';
  }
};

const mapSecondaryGroupingFieldToHeaderName = (secondaryGroupingField) => {
  switch (secondaryGroupingField) {
    case 'leadCampaign':
      return 'Lead campaign';
    case 'stackName':
      return 'Stack';
  }
};

const getSecondaryGroupingFieldByMainGroupingField = (mainGroupingField) => {
  switch (mainGroupingField) {
    case 'leadProviderName':
      return 'leadCampaign';
    case stackReportMainGroupingField:
      return 'stackName';
  }
};

export const getColumnsVisibilityModel = (mainGroupingField) => {
  const model = { period: false };
  model[mainGroupingField] = false;

  return model;
};

export const getPeriod = (minDate, maxDate) =>
  dayjs(minDate).format(dateFormat) + ' - ' + dayjs(maxDate).format(dateFormat);

export const getColumns = (data, mainGroupingField, isStackPerformance) => {
  const secondaryGroupingField =
    getSecondaryGroupingFieldByMainGroupingField(mainGroupingField);

  const secondryGroupingFieldHeader = mapSecondaryGroupingFieldToHeaderName(
    secondaryGroupingField
  );
  const createFilterOperator = (filterField) => ({
    filterOperators: [
      filterOperators.singleSelect({
        filterField,
        data
      })
    ]
  });

  const columns = [
    {
      headerName: mapMainGroupingFieldToHeaderName(mainGroupingField),
      field: mainGroupingField,
      width: 160,
      aggregable: false,
      cellClassName: getTotalAndSubtotalCellClassName,
      ...createFilterOperator(mainGroupingField)
    },
    {
      headerName: secondryGroupingFieldHeader,
      field: secondaryGroupingField,
      width: 140,
      aggregable: false,
      renderCell: renderTotalAndSubtotalCell,
      cellClassName: getTotalAndSubtotalCellClassName,
      ...createFilterOperator(secondaryGroupingField)
    },
    {
      headerName: 'Looks',
      field: 'looks',
      type: 'number',
      width: 55,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName
    },
    {
      headerName: 'Accepted',
      field: 'accepted',
      type: 'number',
      width: 75,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName
    },
    {
      headerName: 'Accepted%',
      field: 'appRate',
      type: 'number',
      width: 90,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName,
      valueFormatter: formatValueWithPercentage
    },
    {
      headerName: 'Errors',
      field: 'errors',
      type: 'number',
      width: 80,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName
    },
    {
      headerName: 'Timeouts',
      field: 'timeouts',
      type: 'number',
      width: 80,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName
    },
    {
      headerName: 'Duplicate',
      field: 'duplicateLeads',
      type: 'number',
      width: 80,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName
    },
    {
      headerName: 'Duplicate%',
      field: 'duplicateLeadsPercentage',
      type: 'number',
      width: 80,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName,
      valueFormatter: formatValueWithPercentage
    },
    {
      headerName: 'Redirect%',
      field: 'redirectRate',
      type: 'number',
      width: 80,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName,
      valueFormatter: formatValueWithPercentage
    },
    {
      headerName: 'Loan Approved',
      field: 'loanApproved',
      type: 'number',
      width: 110,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName
    },
    {
      headerName: 'Lead Cost',
      field: 'leadCost',
      type: 'number',
      width: 80,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName,
      valueFormatter: formatValueWithCurrency
    },
    {
      headerName: 'Data Cost',
      field: 'dataCost',
      type: 'number',
      width: 75,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName,
      valueFormatter: formatValueWithCurrency
    },
    {
      headerName: 'Total Cost',
      field: 'costSpent',
      type: 'number',
      width: 100,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName,
      valueFormatter: formatValueWithCurrency
    },
    {
      headerName: 'CPA',
      field: 'cpa',
      type: 'number',
      width: 50,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName,
      valueFormatter: formatValueWithCurrency
    },
    {
      headerName: 'Lead CPF',
      field: 'leadCPF',
      type: 'number',
      width: 75,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName,
      valueFormatter: formatValueWithCurrency
    },
    {
      headerName: 'Data CPF',
      field: 'dataCPF',
      type: 'number',
      width: 70,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName
    },
    {
      headerName: 'CPF',
      field: 'cpf',
      type: 'number',
      width: 50,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName,
      valueFormatter: formatValueWithCurrency
    },
    {
      headerName: 'FP Due',
      field: 'firstPaymentDue',
      type: 'number',
      width: 60,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName
    },
    {
      headerName: 'FPD',
      field: 'fpd',
      type: 'number',
      width: 50,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName
    },
    {
      headerName: 'FPD%',
      field: 'fpDrate',
      type: 'number',
      width: 50,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName,
      valueFormatter: formatValueWithPercentage
    },
    {
      headerName: 'FPM',
      field: 'fpm',
      type: 'number',
      width: 50,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName
    },
    {
      headerName: 'FPM%',
      field: 'fpMrate',
      type: 'number',
      width: 50,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName,
      valueFormatter: formatValueWithPercentage
    },
    {
      headerName: 'SP Due',
      field: 'secondPaymentDue',
      type: 'number',
      width: 130,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName
    },
    {
      headerName: 'SPD',
      field: 'spd',
      type: 'number',
      width: 50,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName
    },
    {
      headerName: 'SPD%',
      field: 'spDrate',
      type: 'number',
      width: 50,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName,
      valueFormatter: formatValueWithPercentage
    },
    {
      headerName: 'ROI%',
      field: 'roi',
      type: 'number',
      width: 50,
      aggregable: true,
      cellClassName: getTotalAndSubtotalCellClassName,
      valueFormatter: formatValueWithPercentage
    },
    ...(isStackPerformance
      ? [
          {
            headerName: 'Lead Campaign',
            field: 'leadCampaign',
            width: 120,
            aggregable: false,
            ...createFilterOperator('leadCampaign')
          },
          {
            headerName: 'Affiliate ID',
            field: 'affiliateId',
            width: 90,
            aggregable: false,
            ...createFilterOperator('affiliateId')
          }
        ]
      : [])
  ];

  return columns;
};

export const getPinnedColumns = (mainGroupingField, isStackPerformance) => [
  mainGroupingField,
  'period',
  getSecondaryGroupingFieldByMainGroupingField(mainGroupingField),
  ...(isStackPerformance ? ['leadCampaign', 'affiliateId'] : [])
];

export const aggregationModel = {
  looks: 'sum',
  accepted: 'sum',
  errors: 'sum',
  timeouts: 'sum',
  appRate: 'appRatePercentage',
  duplicateLeads: 'sum',
  duplicateLeadsPercentage: 'duplicateLeadsPercentage',
  cpa: 'cpaCalculatedValue',
  loanApproved: 'sum',
  foundRate: 'fundRatePercentage',
  conversion: 'conversionPercentage',
  redirectRate: 'redirectRatePercentage',
  cpf: 'cpfCalculatedValue',
  leadCost: 'sum',
  leadCPF: 'leadCpfCalculatedValue',
  dataCost: 'sum',
  dataCPF: 'dataCpfCalculatedValue',
  firstPaymentDue: 'sum',
  fpd: 'sum',
  fpDrate: 'fpdRatePercentage',
  fpmDue: 'sum',
  fpm: 'sum',
  fpMrate: 'fpmRatePercentage',
  spd: 'sum',
  spDrate: 'spdRatePercentage',
  roi: 'roiPercentage',
  costSpent: 'sum',
  secondPaymentDue: 'sum'
};

export const getAggregationFunctions = (data) => ({
  appRatePercentage: aggregateGroupByFormula(data, getAppRatePercentage),
  duplicateLeadsPercentage: aggregateGroupByFormula(
    data,
    getDuplicateLeadsPercentage
  ),
  fundRatePercentage: aggregateGroupByFormula(data, getFundRatePercentage),
  conversionPercentage: aggregateGroupByFormula(data, getConversionPercentage),
  fpdRatePercentage: aggregateGroupByFormula(data, getFpdRatePercentage),
  fpmRatePercentage: aggregateGroupByFormula(data, getFpmRatePercentage),
  spdRatePercentage: aggregateGroupByFormula(data, getSpdRatePercentage),
  redirectRatePercentage: aggregateGroupByFormula(data, getRedirectRate),
  cpaCalculatedValue: aggregateGroupByFormula(data, calculateCpaValue),
  cpfCalculatedValue: aggregateGroupByFormula(data, calculateCpfValue),
  leadCpfCalculatedValue: aggregateGroupByFormula(data, calculateLeadCpfValue),
  dataCpfCalculatedValue: aggregateGroupByFormula(data, calculateDataCpfValue),
  roiPercentage: aggregateGroupByFormula(data, getRoiPercentage)
});
