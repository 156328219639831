import React from 'react';
import PT from 'prop-types';
import startCase from 'lodash/startCase';
import { Formik, Form } from 'formik';

import { getOptionsConfig, typesList } from './GlobalSettings.config';
import { StyledBox } from './styled';

import Typography from '@components/shared/Typography/Typography';
import Stack from '@components/shared/Stack/Stack';
import FormikField, {
  fieldTypes
} from '@components/shared/Form/FormikField/FormikField';
import Button from '@components/shared/Button/Button';
import toastify from '@utils/toastify';
import Logger from '@utils/logger';
import { types } from '@constants/common';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const GlobalSettingSection = ({
  settingsId,
  name,
  description,
  valueType,
  value,
  refreshItem,
  options,
  loading
}) => {
  const [timeZonesOptions, SMSProvidersOptions] = options;
  const optionsConfig = getOptionsConfig({
    timeZonesOptions,
    SMSProvidersOptions
  });

  const handleChangeValue = async (value, type, actions) => {
    try {
      actions.setSubmitting(true);
      const payload = value.toString();
      await AdminApi.patch(adminApiUrls.settings.item(settingsId), {
        value: payload
      });
      toastify.success({ message: 'Setting have been saved' });
      actions.setSubmitting(false);
      refreshItem(settingsId);
    } catch (error) {
      Logger.log(error);
      toastify.error(error);
      actions.setSubmitting(false);
    }
  };

  const getValue = (value) => {
    if (valueType === types.boolean) {
      if (value === 'true') {
        return true;
      }
      return false;
    }
    return value;
  };

  const initialValues = {
    [settingsId]: getValue(value)
  };

  return (
    <StyledBox>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) =>
          handleChangeValue(values[settingsId], valueType, actions)
        }
        enableReinitialize
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <Stack
              flexDirection="row"
              alignItems="center"
              gap={3}
              justifyContent="space-between"
            >
              <Typography variant="strong">{startCase(name)}</Typography>
            </Stack>
            <Typography variant="body2">{description}</Typography>
            <Stack flexDirection="row" alignItems="center" gap={2}>
              <FormikField
                name={settingsId}
                type={typesList[valueType] || fieldTypes.text}
                options={optionsConfig[name]?.options || []}
                loading={loading}
                placeholder={optionsConfig[name]?.placeholder}
                sx={{ width: '100%' }}
              />
              <Button
                sx={{ ml: 'auto', display: 'block' }}
                type="submit"
                disabled={isSubmitting || !dirty}
              >
                Save
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </StyledBox>
  );
};

GlobalSettingSection.propTypes = {
  settingsId: PT.oneOfType([PT.number, PT.string]).isRequired,
  name: PT.string.isRequired,
  description: PT.string.isRequired,
  valueType: PT.string.isRequired,
  value: PT.string.isRequired,
  refreshItem: PT.func.isRequired,
  category: PT.string.isRequired,
  options: PT.array,
  loading: PT.bool
};

export default GlobalSettingSection;
