import { createAsyncThunk } from '@reduxjs/toolkit';

import Logger from '@utils/logger';
import { adminApiUrls } from '@constants/adminApiUrls';
import AdminApi from '@utils/adminApi';
import { paymentProcessingApiUrls } from '@constants/paymentProcessingApiUrls';
import PaymentProcessingApi from '@utils/paymentProcessingApi';

export const fetchApiVersion = createAsyncThunk(
  'settings/fetchApiVersion',
  async () => {
    try {
      const data = await AdminApi.get(adminApiUrls.apiVersion);
      return data.version;
    } catch (e) {
      Logger.error(e);
      throw new Error(`An error occurred fetching API version: ${e}`);
    }
  }
);

export const fetchAppVersions = createAsyncThunk(
  'settings/fetchAppVersions',
  async () => {
    try {
      const data = await AdminApi.get(adminApiUrls.appVersion.versions);
      return data.versions.reduce((acc, { name }) => {
        if (!name) {
          return acc;
        }
        return [...acc, name];
      }, []);
    } catch (e) {
      Logger.error(e);
      throw new Error(`An error occurred fetching app versions: ${e}`);
    }
  }
);

export const fetchServicesStatuses = createAsyncThunk(
  'settings/fetchServicesStatuses',
  async () => {
    try {
      const data = await AdminApi.get(
        adminApiUrls.healthCheck.servicesStatuses
      );
      return data.healthCheckServices;
    } catch (e) {
      Logger.error(e);
      throw new Error(`An error occurred fetching services statuses: ${e}`);
    }
  }
);

export const fetchDeclineActions = createAsyncThunk(
  'settings/fetchDeclineActions',
  async () => {
    try {
      const data = await PaymentProcessingApi.get(
        paymentProcessingApiUrls.common.declineActions
      );
      return data;
    } catch (e) {
      Logger.error(e);
      throw new Error(`An error occurred fetching decline actions: ${e}`);
    }
  }
);
