import React from 'react';
import PT from 'prop-types';
import MuiLinearProgress from '@mui/material/LinearProgress';

import Box from '@components/shared/Box/Box';

const LinearProgress = ({ width, wrapperProps, ...props }) => (
  <Box width={width} {...wrapperProps}>
    <MuiLinearProgress {...props} />
  </Box>
);

LinearProgress.defaultProps = {
  width: '100%'
};

LinearProgress.propTypes = {
  width: PT.oneOfType([PT.string, PT.number]),
  wrapperProps: PT.object
};

export default LinearProgress;
