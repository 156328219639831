import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import qs from 'query-string';

import { columns } from './config';

import Alert from '@components/shared/Alert/Alert';
import {
  defaultFilterParams,
  useQueryPagination
} from '@hooks/useQueryPagination';
import { useQueryParams } from '@hooks/useQueryParams';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import DashboardFilter from '@components/Dashboard/Filter';
import Logger from '@utils/logger';
import { selectDashboardFilters } from '@store/dashboardFilters';
import Typography from '@components/shared/Typography/Typography';
import DataTable from '@components/shared/DataTable/DataTable';
import {
  getStoresTotalRow,
  getTotalRow
} from '@components/shared/DataTable/DataTable.utils';
import Box from '@components/shared/Box/Box';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

let interval = null;

const DashboardLeadStatsPage = () => {
  const filtersInitialState = useSelector(selectDashboardFilters);
  const history = useHistory();
  const params = useQueryParams();
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [storesTotalRow, setStoresTotalRow] = useState([]);
  const [utmValue, setUTMValue] = useState('');
  const [isFilterChange, setIsFilterChange] = useState(Date.now());

  const { allData, list, isLoading, count, refetchList } = useQueryPagination({
    listUrl: adminApiUrls.dashboard.tiers,
    initialFilters: {
      ...defaultFilterParams,
      ...filtersInitialState
    },
    customApi: AdminApi
  });

  const {
    allData: organicData,
    list: organicList,
    isLoading: organicIsLoading,
    count: organicCount,
    refetchList: organicRefetchList
  } = useQueryPagination({
    listUrl: adminApiUrls.dashboard.organic,
    initialFilters: {
      ...defaultFilterParams,
      ...filtersInitialState
    },
    customApi: AdminApi
  });

  const tiersTotalRow = useMemo(
    () => getTotalRow(allData, 'nameStatisticsRow'),
    [allData]
  );

  const organicTotalRow = useMemo(
    () => getTotalRow(organicData, 'nameStatisticsRow'),
    [organicData]
  );

  useEffect(() => {
    delayReload();

    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchStoresData = async () => {
    try {
      if (history.location?.search && !refresh) {
        const parsedParams = qs.parse(history.location.search, {
          parseBooleans: true,
          parseNumbers: true
        });
        const response = await AdminApi.get(adminApiUrls.dashboard.stores, {
          params: {
            startDate: parsedParams.startDate,
            endDate: parsedParams.endDate,
            ...(utmValue && { utmValue })
          }
        });
        setData(response);
        if (response?.length) {
          const totalRow = getStoresTotalRow(response, 'nameStatisticsRow');
          setStoresTotalRow([totalRow]);
        } else {
          setStoresTotalRow([]);
        }
      }
    } catch (e) {
      Logger.error(e);
      setError(e);
    }
  };

  useEffect(() => {
    fetchStoresData();
  }, [isFilterChange]);

  const delayReload = (pagination) => {
    if (pagination) {
      handleTableChange(pagination);
    }
    clearInterval(interval);
    interval = setInterval(() => {
      const parsedParams = qs.parse(history.location.search, {
        parseBooleans: true,
        parseNumbers: true
      });
      refetchList({ ...parsedParams });
      organicRefetchList({ ...parsedParams });
      setRefresh(true);
      setRefresh(false);
    }, 60000);
  };

  const setParams = (queryParams) => {
    history.push({
      search: qs.stringify(queryParams, {
        skipNull: true,
        skipEmptyString: true
      })
    });
  };

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      ...qs.parse(history.location.search),
      take: pagination.pageSize,
      offset: pagination.pageSize * (pagination.current - 1)
    });
  };

  return (
    <MainLayout title="Application Rates">
      <DashboardFilter
        isUTMFieldShow
        setUTMValue={setUTMValue}
        setIsFilterChange={setIsFilterChange}
      />
      <Box my={2}>
        <Typography variant="h3">Tiers</Typography>
        <DataTable
          disableColumnFilter
          columns={columns}
          tableData={[...list, ...tiersTotalRow]}
          rowKey="nameStatisticsRow"
          loading={isLoading}
          onChange={handleTableChange}
          setOrder={delayReload}
          totalAmount={count}
          hideFooter
          sx={{ mt: 1 }}
        />
      </Box>
      <Box my={2}>
        <Typography variant="h3">Affiliates</Typography>
        <DataTable
          disableColumnFilter
          columns={columns}
          tableData={[...organicList, ...organicTotalRow]}
          rowKey="nameStatisticsRow"
          loading={organicIsLoading}
          onChange={handleTableChange}
          setOrder={delayReload}
          totalAmount={organicCount}
          hideFooter
          sx={{ mt: 1 }}
        />
      </Box>
      <Typography variant="h3">Stores</Typography>
      <DataTable
        disableColumnFilter
        columns={columns}
        tableData={[...data, ...storesTotalRow]}
        hideFooter
        rowKey="nameStatisticsRow"
        loading={refresh}
        onChange={delayReload}
        totalAmount={data?.length}
        sx={{ mt: 1 }}
      />
      {error && (
        <Alert
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
          sx={{ mb: 3 }}
        />
      )}
    </MainLayout>
  );
};

export default DashboardLeadStatsPage;
