import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { Formik, Form } from 'formik';

import { leadInfoFieldsSchema } from './schema';

import Alert from '@components/shared/Alert/Alert';
import helpers from '@utils/helpers';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import { fieldsMasks } from '@constants/common';
import Typography from '@components/shared/Typography/Typography';
import Accordion from '@components/shared/Accordion/Accordion';
import Button from '@components/shared/Button/Button';
import Box from '@components/shared/Box/Box';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const getInitialValues = (data) => ({
  name: '',
  address1: '',
  address2: '',
  city: '',
  stateId: '',
  zip: '',
  phone: '',
  dailyLookCap: '',
  hourlyLookCap: '',
  dailyAcceptCap: '',
  hourlyAcceptCap: '',
  dailyCostCap: '',
  maxTimeRespond: '',
  isOrganic: true,
  ...data
});

const InfoPageStep = ({ onSubmit, infoPageData }) => {
  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const statesResponse = await AdminApi.get(
        adminApiUrls.apply.loanForm.userInfo.options
      );

      const options = statesResponse?.state?.map((item) => ({
        label: item.displayName,
        value: item.id
      }));
      setStateOptions(options || []);
    })();
  }, []);

  const handleSubmit = (values, actions) => {
    const filledValues = { ...values, stateId: values.stateId || null };
    onSubmit({ values: filledValues, actions }, {});
  };

  const accordionList = [
    {
      key: '1',
      header: 'Address info (optional)',
      content: (
        <>
          <FormikField
            type="text"
            name="address1"
            label="Address 1:"
            placeholder="Address 1"
          />
          <FormikField
            type="text"
            name="address2"
            label="Address 2:"
            placeholder="Address 2"
          />
          <FormikField
            type="text"
            name="city"
            label="City:"
            placeholder="City"
          />
          <FormikField
            type="text"
            name="zip"
            label="Zipcode:"
            placeholder="Zipcode"
          />
          <FormikField
            type="mask"
            name="phone"
            mask={fieldsMasks.phoneUSA.mask}
            placeholder={fieldsMasks.phoneUSA.placeholder}
            maskChar="X"
            label="Phone number:"
          />
          <FormikField
            type="autocomplete"
            name="stateId"
            label="State:"
            placeholder="Select"
            options={stateOptions}
            filterOption={helpers.searchInclusionsFromWordBeginning}
            loading={!stateOptions?.length}
            disabled={!stateOptions?.length}
            withClear
          />
        </>
      )
    }
  ];

  return (
    <Formik
      initialValues={getInitialValues(infoPageData)}
      onSubmit={handleSubmit}
      validationSchema={leadInfoFieldsSchema}
      validateOnChange={false}
      validateOnBlur
    >
      {({ errors }) => (
        <Form>
          <Typography variant="h3" my={2}>
            Lead provider Info
          </Typography>
          <FormikField
            type="text"
            name="name"
            label="Name"
            placeholder="Name"
          />
          <Accordion accordionList={accordionList} />
          <Typography variant="h4" my={2}>
            General info
          </Typography>
          <FormikField
            type="number"
            name="dailyLookCap"
            label="Day Look cap:"
            placeholder="Day Look cap"
          />
          <FormikField
            type="number"
            name="hourlyLookCap"
            label="Hr look cap:"
            placeholder="Hr look cap"
          />
          <FormikField
            type="number"
            name="dailyAcceptCap"
            label="Day accept cap:"
            placeholder="Day accept cap"
          />

          <FormikField
            type="number"
            name="hourlyAcceptCap"
            label="Hr accept cap:"
            placeholder="Hr accept cap"
          />
          <FormikField
            type="number"
            name="dailyCostCap"
            label="Day cost cap:"
            placeholder="Day cost cap"
          />
          <FormikField
            type="number"
            name="maxTimeRespond"
            label="Max time to respond (Sec):"
            placeholder="Max time to respond (Sec)"
          />

          {errors.nonFieldErrors && (
            <Alert message={errors.nonFieldErrors} type="error" />
          )}
          <Box my={2}>
            <Button type="submit">Next</Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

InfoPageStep.defaultProps = {
  infoPageData: {}
};

InfoPageStep.propTypes = {
  onSubmit: PT.func,
  infoPageData: PT.object
};

export default InfoPageStep;
