import axios from 'axios';
import qs from 'query-string';

import helpers from '@utils/helpers';
import { csvComparisonApiBaseUrl } from '@constants/csvComparisonApi';
import { authService } from '@services/auth';

const headers = {
  'Accept-Language': 'en',
  'Content-Type': 'application/json'
};

const CsvComparisonApi = axios.create({
  baseURL: csvComparisonApiBaseUrl,
  headers,
  paramsSerializer: function (params) {
    // is we detect at least one params which start with '$' - we are using odata-query
    if (Object.keys(params).some((paramKey) => paramKey.startsWith('$'))) {
      return helpers.parseODataQuery(params);
    }

    return qs.stringify(params);
  }
});

CsvComparisonApi.interceptors.request.use(
  async (config) => {
    await authService.axiosRequestMiddleware(config.headers);

    config.headers.Authorization = `Bearer ${authService.getToken()}`;

    return {
      ...config,
      url: encodeURI(config.url)
    };
  },
  (error) => Promise.reject(error)
);

CsvComparisonApi.interceptors.response.use(
  (res) => {
    const contentDispositionHeader = res.headers['content-disposition'];

    if (contentDispositionHeader) {
      return {
        contentDisposition: contentDispositionHeader,
        content: res.data
      };
    }

    return res.data;
  },
  (err) => {
    void authService.axiosResponseMiddleware(err.response?.status);
    return Promise.reject(helpers.handleServerErrors(err));
  }
);

export default CsvComparisonApi;
