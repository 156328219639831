import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { v4 as uuidv4 } from 'uuid';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { LicenseInfo } from '@mui/x-license-pro';

import 'normalize.css';
import '@assets/styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';

import AppRouter from './router';
import { store } from './store';
import yupLocale from './constants/yupLocale';

import { MUI_LICENSE_KEY } from '@constants/common';
import { theme } from '@utils/theme';
import Storage from '@utils/storage';
import Api from '@utils/api';
import { DEVICE_UUID_KEY } from '@constants/common';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
Yup.setLocale(yupLocale);

[customParseFormat, isSameOrAfter, isSameOrBefore, localeData, weekday].forEach(
  dayjs.extend
);

const App = () => {
  useEffect(() => {
    Modal.setAppElement('#root');

    const deviceUUID = Storage.get(DEVICE_UUID_KEY) || uuidv4();
    if (!Storage.get(DEVICE_UUID_KEY)) {
      Storage.set(DEVICE_UUID_KEY, deviceUUID);
    }
    Api.defaults.headers['X-Device'] = deviceUUID;
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <AppRouter />
          <ToastContainer />
        </Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
