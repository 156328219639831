import NotificationStep from '@components/CustomNotifications/Form/NotificationStep';
import SchedulingStep from '@components/CustomNotifications/Form/SchedulingStep';
import TargetStep from '@components/CustomNotifications/Form/TargetStep';

export const CSV_INPUT_NAME = 'csvFile';

export const notificationFormStepsMap = {
  notification: 'Notification',
  target: 'Target',
  scheduling: 'Scheduling'
};

export const notificationFormStepsOrder = [
  notificationFormStepsMap.notification,
  notificationFormStepsMap.target,
  notificationFormStepsMap.scheduling
];

export const notificationFormStepsComponents = {
  [notificationFormStepsMap.notification]: NotificationStep,
  [notificationFormStepsMap.target]: TargetStep,
  [notificationFormStepsMap.scheduling]: SchedulingStep
};
