import React, { useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import helpers from '@utils/helpers';
import routes from '@constants/routes';
import { rolesMap } from '@constants/roles';
import Box from '@components/shared/Box/Box';
import Typography from '@components/shared/Typography/Typography';
import DataTable from '@components/shared/DataTable/DataTable';
import { useServerData } from '@hooks/useServerData';
import { columns } from '@components/Leads/LeadProvidersCampaigns/config';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import DashboardFilter from '@components/Dashboard/Filter';
import { selectDashboardFilters } from '@store/dashboardFilters';
import { leadManagementReportsApiUrls } from '@constants/leadManagementReportsApi';
import LeadManagementReportsApi from '@utils/leadManagementReportsApi';
import { setODataDateFilterParams } from '@components/LeadReports/filters/utils';

const title = 'Lead provider performance';

const LeadProvidersPerformancePage = () => {
  const history = useHistory();
  const params = qs.parse(history.location.search);
  const filtersInitialState = useSelector(selectDashboardFilters);

  let updatedParams = {};
  if (isEmpty(params)) {
    updatedParams = { ...filtersInitialState };
  } else {
    updatedParams = { ...filtersInitialState, ...params };
  }
  delete updatedParams.startTime;
  delete updatedParams.endTime;
  delete updatedParams.leadProviderId;

  const { data, isLoading, error, fetchData } = useServerData({
    endpoint: async () =>
      await LeadManagementReportsApi.get(
        leadManagementReportsApiUrls.leadProviderCampaignsById(
          params.leadProviderId
        ),
        {
          params: {
            $filter: JSON.stringify(
              setODataDateFilterParams(
                updatedParams.startDate,
                updatedParams.endDate
              )
            )
          }
        }
      )
  });
  useEffect(() => {
    if (!isEmpty(updatedParams)) {
      fetchData();
    }
  }, [history.location.search]);

  const isAdminOrManager = helpers.hasRole(rolesMap.Admin, rolesMap.Manager);
  if (!isAdminOrManager) {
    return <Redirect to={routes.permissionDenied} />;
  }

  return (
    <MainLayout title={title}>
      <Box mt={1}>
        <Box mb={2}>
          <DashboardFilter />
        </Box>
        {!error && (
          <DataTable
            rowKey="id"
            tableData={data || []}
            loading={isLoading}
            columns={columns}
            hasError={!!error}
            disableColumnFilter
            hideFooter
          />
        )}
        {!!error && (
          <Typography variant="h6" color="error">
            Something went wrong. Please try again later.
          </Typography>
        )}
      </Box>
    </MainLayout>
  );
};

export default LeadProvidersPerformancePage;
