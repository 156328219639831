const leadManagementReportsApiBaseUrl =
  process.env.API_LEAD_MANAGEMENT_REPORTS || '';

const leadManagementReportsApiUrls = {
  affiliateReport: '/v1/AffiliateReportItems',
  leadProviderAggregateReport: '/v1/LeadProviderAggregateReportItems',
  stackAggregateReport: '/v1/StackAggregateReportItems',
  denialReasonReport: '/v1/LeadProviderResponses',
  exportDetails: '/v1/AffiliateReportDetails',
  leadProviderCampaigns: '/v1/LeadProviderCampaigns',
  leadProviderCampaignsById: (leadProviderId) =>
    `/v1/LeadProviderCampaigns/${leadProviderId}`,
  leadReportsPerformance: '/v1/database/performance',
  stackNames: 'v1/StackNames',
  promoCodes: 'v1/PromoCodes'
};

export { leadManagementReportsApiBaseUrl, leadManagementReportsApiUrls };
