const wallboardApiBaseUrl = process.env.WALLBOARD_API_URL || '';

const wallboardApiUrls = {
  loanProcessing: '/api/wallboard/loan-processing',
  collection: '/api/wallboard/collection',
  productionDaily: '/api/wallboard/production/daily',
  productionWeekly: '/api/wallboard/production/weekly',
  productionMonthly: '/api/wallboard/production/monthly',
  teamGoals: '/api/wallboard/team-goals'
};

export { wallboardApiUrls, wallboardApiBaseUrl };
