import dayjs from 'dayjs';
import * as Yup from 'yup';

import { dateTimeFormat } from '@constants/common';

export const promoCodeSchema = Yup.object().shape({
  displayName: Yup.string().test(
    'length',
    'Promo code must be from 4 to 15 characters inclusively',
    (value) => value && value.length >= 4 && value.length <= 15
  ),
  description: Yup.string().optional(),
  startDate: Yup.date().nullable().min(dayjs(), 'Min allowed date is current'),
  expirationDate: Yup.date()
    .nullable()
    .when('startDate', ([startDate], schema) =>
      startDate
        ? schema.min(
            startDate,
            `Expiration date field must be later than ${dayjs(startDate).format(
              dateTimeFormat
            )}`
          )
        : schema.min(
            dayjs().add(1, 'hour'),
            `Expiration date field must be later than ${dayjs()
              .add(1, 'hour')
              .format(dateTimeFormat)}`
          )
    )
});

export const getInitialValues = (data) => ({
  displayName: '',
  description: '',
  startDate: null,
  expirationDate: null,
  ...data
});
