import dayjs from 'dayjs';
import * as Yup from 'yup';

import validators from '@utils/validators';

export const creditCardFormSchema = Yup.object().shape({
  name: Yup.string().min(1).max(50).label('Cardholder name').required(),
  streetAddress: validators
    .regExp(
      'Card billing address',
      /.*[a-zA-Z].*$/,
      'Card billing address should contain letters'
    )
    .max(100)
    .required(),
  state: Yup.mixed().label('Billing State').required(),
  city: Yup.string().label('Billing City').required(),
  zipCode: validators.zipCode().required(),
  number: Yup.string().min(13).max(16).label('Card number').required(),
  cardType: Yup.string().label('Card type'),
  expireDate: Yup.string()
    .test(
      'length',
      'Incorrect date',
      (val) => !val || (val.length > 2 && val.slice(0, 2) <= 12)
    )
    .test(
      'date',
      'Card expired',
      (val) =>
        !val ||
        (val.length === 4 &&
          dayjs(`${val.slice(0, 2)}/01/20${val.slice(-2)}`).isAfter(dayjs()))
    )
    .label('Expire date')
    .required(),
  code: Yup.string().min(3).max(4).label('CVV').required()
});

export const initialValues = {
  name: '',
  streetAddress: '',
  state: '',
  city: '',
  zipCode: '',
  number: '',
  cardType: '',
  expireDate: '',
  code: ''
};
