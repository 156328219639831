import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import Logger from '@utils/logger';
import FlatPageForm from '@components/FlatPage/Form';
import toastify from '@utils/toastify';
import Alert from '@components/shared/Alert/Alert';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const FlatPageEditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const responseData = await AdminApi.get(
          adminApiUrls.flatPages.page(id)
        );
        setData(responseData);
      } catch (e) {
        Logger.error(e);
        setError(e);
      }
    })();
  }, []);

  const handleSubmit = async ({ values }, { onSuccess, onError, onFinish }) => {
    try {
      const responseData = await AdminApi.put(
        adminApiUrls.flatPages.page(id),
        values
      );

      toastify.success({
        message: 'Edit operation success'
      });

      onSuccess && onSuccess(responseData);
    } catch (e) {
      onError && onError(e);
    } finally {
      onFinish && onFinish();
    }
  };

  return (
    <MainLayout isLoading={!data && !error}>
      {data && <FlatPageForm data={data} editMode onSubmit={handleSubmit} />}
      {error && (
        <Alert
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
        />
      )}
    </MainLayout>
  );
};

export default FlatPageEditPage;
