import React from 'react';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';

import { noFieldData, systemServicesStatuses } from '@constants/common';
import Typography from '@components/shared/Typography/Typography';
import Stack from '@components/shared/Stack/Stack';
import Box from '@components/shared/Box/Box';
import Tooltip from '@components/shared/Tooltip/Tooltip';

const getStatusColor = (status) =>
  status === systemServicesStatuses.active ? green[500] : red[500];

export const columns = [
  {
    headerName: 'Service name',
    field: 'projectWebName',
    minWidth: 270,
    sortable: false,
    renderCell: ({ row }) => (
      <Stack>
        <Typography>{row.projectWebName}</Typography>
        {row.healthCheckSubServiceItems && (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gap: '0 20px'
            }}
          >
            {row.healthCheckSubServiceItems.map((subService) => (
              <React.Fragment key={subService.subServiceName}>
                <Typography sx={{ fontSize: '12px' }}>
                  {subService.subServiceName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: getStatusColor(subService.status)
                  }}
                >
                  {subService.status}
                </Typography>
              </React.Fragment>
            ))}
          </Box>
        )}
      </Stack>
    )
  },
  {
    headerName: 'Service description',
    field: 'serviceDescription',
    sortable: false,
    renderCell: ({ row, field }) => row[field] || noFieldData,
    flex: 1
  },
  {
    headerName: 'Service status',
    field: 'status',
    width: 120,
    sortable: false,
    renderCell: ({ row, field }) => (
      <Typography variant="body2" sx={{ color: getStatusColor(row[field]) }}>
        {row[field]}
      </Typography>
    )
  },
  {
    headerName: 'Fail reason',
    field: 'errorMessage',
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <Box>
        {row.errorMessage ? (
          <Tooltip title={row.errorMessage}>
            <Typography sx={{ fontSize: '12px' }}>
              {row.errorMessage}
            </Typography>
          </Tooltip>
        ) : (
          <>
            {row.healthCheckSubServiceItems?.map(
              (subService) =>
                subService?.errorMessage && (
                  <React.Fragment key={subService?.errorMessage}>
                    <Tooltip title={subService?.errorMessage}>
                      <Typography sx={{ fontSize: '12px' }}>
                        {subService?.errorMessage}
                      </Typography>
                    </Tooltip>
                  </React.Fragment>
                )
            )}
          </>
        )}
      </Box>
    )
  },
  {
    headerName: 'Version',
    field: 'version',
    flex: 1,
    sortable: false
  }
];
