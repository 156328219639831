import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';

import CommonHelpers from '../utils/helpers';

import Api from '@utils/api';
import Logger from '@utils/logger';

const usePagination = ({
  listUrl,
  initialFilters = {},
  wait = 0,
  concatResults = false,
  requestOptions = {},
  hiddenParams = {},
  initialPage = 1,
  skipFirstFetch = false,
  customApi = null,
  enabled = true
}) => {
  const [allData, setAllData] = useState(null);
  const [list, setList] = useState([]);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(initialPage);
  const [pages, setPages] = useState(1);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = filters.size || 10;
  const clearData = () => {
    if (!concatResults) setList([]);
    setError(false);
  };

  useEffect(() => {
    if (!skipFirstFetch) {
      fetchList({ offset: pageSize * (page - 1), take: pageSize, ...filters });
    }
  }, []);

  const changePage = (page) => {
    setPage(page);
    clearData();
    fetchList({ offset: pageSize * (page - 1), take: pageSize, ...filters });
  };

  const fetchList = async (requestParams, hidden) => {
    const api = customApi ?? Api;

    if (enabled) {
      try {
        setIsLoading(true);
        const response = await api.get(listUrl, {
          ...requestOptions,
          params: { ...requestParams, ...hiddenParams, ...hidden }
        });
        const isFirstPage = requestParams.page === 1;
        const updatedList =
          isFirstPage || !concatResults
            ? response.items
            : [...list, ...response.items];
        setAllData(response);
        setList(updatedList);
        setCount(response.totalCount);
        setPages(CommonHelpers.getPagesNumber(response.totalCount));
      } catch (err) {
        Logger.error(err);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const debouncedFetchList = useCallback(debounce(fetchList, wait), []);

  const changeFilters = useCallback(
    (updatedFilters, skipComparing = false) => {
      if (!skipComparing && isEqual(filters, updatedFilters)) {
        return;
      }
      setIsLoading(true); // Show loader immediately for preventing empty list blinking with debounced fetcher
      setPages(1); // Reset pages immediately for preventing double fetch with debounce and infinite scroll handler
      clearData();
      setPage(1);
      setFilters(updatedFilters);
      debouncedFetchList({ offset: 0, take: pageSize, ...updatedFilters });
    },
    [filters]
  );

  return {
    list,
    setList,
    error,
    isLoading,
    hasMore: !isLoading && page < pages,
    pages,
    count,
    setCount,
    page,
    setPages,
    changePage,
    filters,
    refetchList: (
      params = { offset: pageSize * (page - 1), take: pageSize, ...filters },
      hidden = {}
    ) => fetchList(params, hidden),
    changeFilters,
    allData,
    setAllData
  };
};

export default usePagination;
