import { fieldTypes } from '@components/shared/Form/FormikField/FormikField';
import { adminApiUrls } from '@constants/adminApiUrls';

export const typesList = {
  dropdown: fieldTypes.autocomplete,
  numeric: fieldTypes.number,
  string: fieldTypes.text,
  email: fieldTypes.email,
  url: fieldTypes.text,
  boolean: fieldTypes.checkbox
};

export const settingCategories = {
  general: 'General',
  testing: 'Testing',
  authorization: 'Authorization',
  tokens: 'Tokens',
  smtp: 'SMTP',
  leadReport: 'Lead Report',
  leadPaymentPro: 'Lead Payment Pro',
  googleReCaptcha: 'Google ReCaptcha',
  epic: 'Epic',
  usaEPay: 'USA ePay',
  plm: 'PLM',
  lokyata: 'Lokyata',
  twilio: 'Twilio',
  SMSService: 'SMS Service',
  nmi: 'NMI',
  googleBot: 'Google Bot',
  healthCheck: 'Health Check',
  payments: 'Payments',
  historyService: 'History Service',
  ibv: 'IBV'
};

export const ibvProvidersOptions = [
  { label: 'Chirp', value: 'Chirp' },
  {
    label: 'Edge',
    value: 'Edge'
  }
];

export const endpointsForOptionsConfig = {
  timeZones: adminApiUrls.common.timeZones,
  SMSProviders: adminApiUrls.common.smsProviders
};

export const getOptionsConfig = ({
  timeZonesOptions,
  SMSProvidersOptions
}) => ({
  ClientTimeZoneId: {
    options: timeZonesOptions
  },
  IbvProvider: {
    options: ibvProvidersOptions,
    placeholder: 'Choose the provider'
  },
  SMSProvider: {
    options: SMSProvidersOptions,
    placeholder: 'Choose the provider'
  }
});
