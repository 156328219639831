import Cookies from './cookies';

import { ORGANIZATION_KEY } from '@constants/common';
import Logger from '@utils/logger';

class OrganizationForLP {
  static organizationKey = ORGANIZATION_KEY;

  static set(organization) {
    try {
      const serializedState = JSON.stringify(organization);
      Cookies.set(this.organizationKey, serializedState);
    } catch (error) {
      Logger.error(error);
    }
  }

  static get() {
    try {
      const serializedState = Cookies.get(this.organizationKey);
      return serializedState === null ? undefined : JSON.parse(serializedState);
    } catch (error) {
      Logger.error(error);
    }
  }

  static remove() {
    Cookies.remove(this.organizationKey);
  }
}

export default OrganizationForLP;
