import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '../Paper/Paper';

import { StyledTable, StyledTableRow, StyledTd, StyledTh } from './styled';

export const Table = ({ data, columns }) => {
  const headers = columns.map((column, index) => (
    <StyledTh key={`headCell-${index}`} className="!z-0">
      {column.title}
    </StyledTh>
  ));

  const rows = !data?.length ? (
    <StyledTableRow>
      <StyledTd colSpan={columns.length} className="text-center">
        No data
      </StyledTd>
    </StyledTableRow>
  ) : (
    data?.map((row, index) => (
      <StyledTableRow key={`row-${index}`}>
        {columns.map((column, index2) => {
          const value = row[column.key];
          let displayValue = '-';
          if (typeof value === 'number') {
            displayValue = value === 0 ? 0 : Number.parseInt(value);
          } else if (typeof value === 'string') {
            displayValue = value || '-';
          } else {
            displayValue = '-';
          }
          return <StyledTd key={`cell-${index2}`}>{displayValue}</StyledTd>;
        })}
      </StyledTableRow>
    ))
  );

  return (
    <Paper>
      <StyledTable>
        <thead>
          <StyledTableRow>{headers}</StyledTableRow>
        </thead>
        <tbody>{rows}</tbody>
      </StyledTable>
    </Paper>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object)
};
