import InfoPageStep from '@components/Leads/leadProviders/Form/InfoPageStep';
import WorkingCalendarStep from '@components/Leads/leadProviders/Form/WorkingCalendarStep';

export const leadFormStepsMap = {
  infoPage: 'Info page',
  workingCalendar: 'Working calendar'
};

export const leadFormStepsOrder = [
  leadFormStepsMap.infoPage,
  leadFormStepsMap.workingCalendar
];

export const leadFormStepsComponents = {
  [leadFormStepsMap.infoPage]: InfoPageStep,
  [leadFormStepsMap.workingCalendar]: WorkingCalendarStep
};
