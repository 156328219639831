import React from 'react';
import { Link } from 'react-router-dom';

import { cleanObject } from '@utils/object-cleaner';
import UserEventsLink from '@components/shared/UserEventsLink';
import Box from '@components/shared/Box/Box';
import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import { CheckCircleIcon } from '@components/shared/icons/Icons';
import {
  filterOperators,
  loanTypeMap,
  noFieldData,
  permissionsMap
} from '@constants/common';
import routes from '@constants/routes';
import helpers from '@utils/helpers';
import {
  emailColumnWidth,
  eventsColumnWidth,
  fullNameColumnWidth,
  phoneNumberColumnWidth,
  timeDateColumnWidth
} from '@components/shared/DataTable/DataTable.constants';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import { getBooleanValue } from '@components/shared/DataTable/DataTable.utils';

const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

export const getFilledColumns = () => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) =>
      row?.loanType !== loanTypeMap.advance && (
        <Box size={4}>
          <EditActionButton
            path={routes.applications.page.edit.url(row.applicationUid)}
            hidden={
              !helpers.getActionPermission(permissionsMap.applicationManage)
            }
          />
          <ShowDetailsActionButton
            path={routes.applications.page.view.url(row.applicationId)}
            hidden={!row.customerInfo}
          />
        </Box>
      )
  },
  {
    headerName: 'Loan number',
    exportField: 'userLoan.loanNumber',
    field: 'userLoan/loanNumber',
    minWidth: 120,
    flex: 1,
    renderCell: ({ row }) => row?.userLoan?.loanNumber
  },
  {
    headerName: 'Full name',
    exportField: 'customerInfo.firstName',
    field: 'customerInfo/firstName',
    minWidth: fullNameColumnWidth,
    flex: 1,
    renderCell: ({ row }) => {
      const displayName = helpers.getDisplayName(
        row?.customerInfo?.firstName,
        row?.customerInfo?.lastName
      );
      return helpers.getActionPermission(permissionsMap.usersView) &&
        row.userId ? (
        <Tooltip title="User page">
          <Link to={routes.users.page.view.url(row.userId)}>{displayName}</Link>
        </Tooltip>
      ) : (
        displayName
      );
    },
    type: muiDataTableTypes.fullName,
    filterOperators: [filterOperators.fullName]
  },
  {
    headerName: 'SSN (last 4)',
    exportField: 'customerInfo.ssnShort',
    field: 'customerInfo/ssnShort',
    minWidth: 100,
    flex: 1,
    type: muiDataTableTypes.number,
    renderCell: ({ row }) =>
      helpers.getFormattedSsn(row?.customerInfo?.ssnShort)
  },
  {
    headerName: 'User email',
    exportField: 'customerInfo.email',
    field: 'customerInfo/email',
    minWidth: emailColumnWidth,
    flex: 1,
    renderCell: ({ row }) => row?.customerInfo?.email
  },
  {
    headerName: 'Phone number',
    exportField: 'customerInfo.phone',
    field: 'customerInfo/phone',
    minWidth: phoneNumberColumnWidth,
    flex: 1,
    renderCell: ({ row }) => row?.customerInfo?.phone
  },
  {
    headerName: 'Loan Type',
    exportField: 'loanType',
    field: 'loanType',
    minWidth: phoneNumberColumnWidth,
    flex: 1,
    renderCell: ({ row }) => row?.loanType,
    type: muiDataTableTypes.singleSelect,
    valueOptions: [
      { label: 'Default', value: 'Default' },
      { label: 'Installment', value: 'Installment' },
      { label: 'Line Of Credit', value: 'LineOfCredit' },
      { label: 'Advance', value: 'Advance' }
    ]
  },
  {
    headerName: 'Signed',
    exportField: 'userLoan.status',
    field: 'userLoan/status',
    filterable: false,
    minWidth: 60,
    flex: 1,
    renderCell: ({ row }) =>
      row?.userLoan && row?.userLoan.status === 'S' ? <CheckCircleIcon /> : ''
  },
  {
    headerName: 'Created date',
    field: 'createdDate',
    minWidth: timeDateColumnWidth,
    flex: 1,
    type: muiDataTableTypes.dateRange,
    valueFormatter: (createdDate) => helpers.getFormattedDate(createdDate),
    renderCell: ({ row }) => helpers.getFormattedDate(row.createdDate),
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Promocode',
    exportField: 'customerInfo.promo',
    minWidth: 120,
    flex: 1,
    field: 'customerInfo/promo',
    renderCell: ({ row }) => row?.customerInfo?.promo
  },
  {
    headerName: 'App Version',
    field: 'appVersion',
    minWidth: 100,
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) =>
      row?.metaAttributes?.find((el) => el.metaAttributeName === 'X-Version')
        ?.metaAttributeValue
  },
  {
    headerName: 'Application id',
    field: 'applicationId',
    minWidth: 120,
    flex: 1,
    renderCell: ({ row }) => row.applicationId,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Decision',
    field: 'leadResponse/result',
    exportField: 'leadResponse.result',
    minWidth: 100,
    flex: 1,
    renderCell: ({ row }) => row?.leadResponse?.result
  },
  {
    headerName: 'Denied Reason',
    field: 'leadResponse/rejectionReason',
    exportField: 'leadResponse.rejectionReason',
    minWidth: 250,
    flex: 1,
    renderCell: ({ row }) => (
      <Tooltip title={row?.leadResponse?.rejectionReason}>
        {row?.leadResponse?.rejectionReason}
      </Tooltip>
    )
  },
  {
    headerName: 'Email Opt-in',
    field: 'customerInfo/emailCommunication',
    exportField: 'customerInfo.emailCommunication',
    minWidth: 105,
    flex: 1,
    type: muiDataTableTypes.singleSelect,
    valueOptions: booleanOptions,
    renderCell: ({ row }) =>
      getBooleanValue(row?.customerInfo?.emailCommunication)
  },
  {
    headerName: 'SMS Opt-in',
    field: 'customerInfo/phoneCommunication',
    exportField: 'customerInfo.phoneCommunication',
    minWidth: 95,
    flex: 1,
    type: muiDataTableTypes.singleSelect,
    valueOptions: booleanOptions,
    renderCell: ({ row }) =>
      getBooleanValue(row?.customerInfo?.phoneCommunication)
  },
  {
    headerName: 'Promo Email Opt-in',
    field: 'disclosure/marketingOptIn',
    exportField: 'disclosure.marketingOptIn',
    minWidth: 150,
    flex: 1,
    type: muiDataTableTypes.singleSelect,
    valueOptions: booleanOptions,
    renderCell: ({ row }) => getBooleanValue(row?.disclosure?.marketingOptIn)
  },
  {
    headerName: 'Events',
    field: 'userId',
    minWidth: eventsColumnWidth,
    flex: 1,
    renderCell: ({ row }) =>
      helpers.getActionPermission(permissionsMap.loggedView) && row?.userId ? (
        <UserEventsLink executedBy={row?.userId}>
          {helpers.cutString(row?.userId, 5)}
        </UserEventsLink>
      ) : (
        row?.userId || noFieldData
      )
  }
];

export const initialFilterParams = cleanObject({
  'userLoan/loanNumber': '',
  'userLoan/customerId': '',
  'customerInfo/email': {
    contains: ''
  },
  applicationId: '',
  userId: {
    contains: ''
  },
  applicationUid: {
    eq: {
      type: 'guid',
      value: ''
    }
  },
  'customerInfo/firstName': {
    startswith: ''
  },
  'customerInfo/lastName': {
    startswith: ''
  },
  'customerInfo/phone': {
    contains: ''
  },
  createdDate: {
    ge: null,
    le: null
  },
  locked: '',
  'leadResponse/rejectionReason': {
    contains: ''
  }
});
