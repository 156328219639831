import React, { useState } from 'react';
import ReactJson from 'react-json-view';

import { getColumns, initialFilterParams } from './config';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import AdditionalUserFilter from '@components/Users/AdditionalFilter';
import { ActionsBox } from '@components/shared/MainLayout/MainLayout.components';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const UsersListPage = () => {
  const [stopFetching, setStopFetching] = useState(false);

  return (
    <MainLayout title="Customers">
      <ActionsBox sx={{ marginTop: 0, verticalAlign: 'top' }}>
        <AdditionalUserFilter
          stopFetching={stopFetching}
          setStopFetching={setStopFetching}
        />
      </ActionsBox>
      <ODataPaginatedTable
        getColumns={getColumns}
        usePaginatedDataOptions={{
          apiUrl: adminApiUrls.users.list,
          initialFilterParams: {
            $orderBy: 'lastLogin desc',
            $filter: {
              ...initialFilterParams
            }
          },
          stopFetching
        }}
        rowKey="id"
        getDetailPanelContent={({ row }) => (
          <ReactJson
            src={row}
            shouldCollapse={({ type, name }) =>
              type === 'array' || name === 'data'
            }
          />
        )}
        detailPanelHeight={500}
        customApi={AdminApi}
        exportLastNameField="lastName"
      />
    </MainLayout>
  );
};

export default UsersListPage;
