import React, { useState } from 'react';
import PT from 'prop-types';
import Input from '@mui/material/Input';

import IconButton from '@components/shared/IconButton/IconButton';
import { CloseIcon } from '@components/shared/icons/Icons';

const SearchInput = ({ handleSearch }) => {
  const [searchValue, setSearchValue] = useState('');

  const onSearchResults = (value) => {
    setSearchValue(value);
    handleSearch(value);
  };

  return (
    <Input
      value={searchValue}
      placeholder="Search here"
      onChange={(e) => onSearchResults(e.target.value)}
      sx={{ mb: '8px' }}
      fullWidth
      endAdornment={
        !!searchValue?.length && (
          <IconButton
            color="primary"
            icon={<CloseIcon />}
            onClick={() => onSearchResults('')}
            sx={{ p: 0, height: '20px' }}
          />
        )
      }
    />
  );
};

SearchInput.propTypes = {
  handleSearch: PT.func.isRequired
};

export default SearchInput;
