import React from 'react';
import PropTypes from 'prop-types';

import { pageTypes } from '../config';

import {
  getStyledProfileIcon,
  StyledAccent,
  StyledAccentBox,
  StyledContainer,
  StyledEndTitle,
  StyledImage,
  StyledMiddleTitle,
  StyledPersonBox,
  StyledPersonName,
  StyledPlace,
  StyledPlaceBox,
  StyledProfileBox,
  StyledRace,
  StyledRaceBox,
  StyledScore,
  StyledScoreBox,
  StyledTitle,
  StyledTopBox
} from './styled';

export const RaceBox = ({ config }) => (
  <StyledContainer>
    <StyledRaceBox>
      <StyledTopBox>
        <StyledTitle>Place</StyledTitle>
        <StyledMiddleTitle>Person</StyledMiddleTitle>
        <StyledEndTitle>Score</StyledEndTitle>
      </StyledTopBox>
      <StyledRace>
        {config.map((item) => {
          const ProfileIcon = item.profileIcon;
          const StyledProfileIcon = getStyledProfileIcon(ProfileIcon);
          return (
            <StyledPersonBox key={item.person}>
              <StyledPlaceBox>
                <StyledPlace style={{ background: item.iconBackground }}>
                  {item.icon}
                </StyledPlace>
                <StyledProfileBox>
                  <StyledProfileIcon />
                </StyledProfileBox>
                <StyledPersonName>{item.person}</StyledPersonName>
              </StyledPlaceBox>

              <StyledAccentBox
                style={{
                  background: item.backgroundGradient,
                  width: item.width,
                  minWidth: '45%',
                  maxWidth: '88%'
                }}
              >
                <StyledImage
                  src={item.raceIconSrc}
                  style={{
                    ...(item.pageType === pageTypes.collectors
                      ? { transform: 'skewX(20deg) translate(70%, -50%)' }
                      : {})
                  }}
                />
              </StyledAccentBox>

              <StyledAccent
                style={{
                  background: item.accentGradient,
                  width: item.accentWidth,
                  maxWidth: '90%'
                }}
              ></StyledAccent>

              <StyledScoreBox>
                <StyledScore isFirstPlace={item.place === 1}>
                  {item.score}
                </StyledScore>
              </StyledScoreBox>
            </StyledPersonBox>
          );
        })}
      </StyledRace>
    </StyledRaceBox>
  </StyledContainer>
);

RaceBox.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.element, PropTypes.number]),
      iconBackground: PropTypes.string.isRequired,
      profileIcon: PropTypes.func.isRequired,
      backgroundGradient: PropTypes.string.isRequired,
      accentGradient: PropTypes.string.isRequired,
      width: PropTypes.string.isRequired,
      accentWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      raceIconSrc: PropTypes.string.isRequired,
      pageType: PropTypes.string.isRequired
    })
  ).isRequired
};
