import { cleanObject } from '@utils/object-cleaner';
import helpers from '@utils/helpers';
import { timeDateColumnWidth } from '@components/shared/DataTable/DataTable.constants';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import { filterOperators } from '@constants/common';

export const initialFilterParams = cleanObject({
  title: {
    contains: ''
  },
  body: {
    contains: ''
  },
  updatedDate: {
    ge: '',
    le: ''
  },
  createdDate: {
    ge: '',
    le: ''
  }
});

export const getColumns = () => [
  {
    headerName: 'Created date',
    field: 'createdDate',
    minWidth: timeDateColumnWidth,
    flex: 1,
    valueFormatter: (createdDate) => helpers.getFormattedDate(createdDate),
    renderCell: ({ row }) => helpers.getFormattedDate(row?.createdDate),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Read date',
    field: 'updatedDate',
    minWidth: timeDateColumnWidth,
    flex: 1,
    valueFormatter: (updatedDate) => helpers.getFormattedDate(updatedDate),
    renderCell: ({ row }) => helpers.getFormattedDate(row?.updatedDate),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Title',
    field: 'title',
    minWidth: 250,
    flex: 1
  },
  {
    headerName: 'Body',
    field: 'body',
    minWidth: 250,
    flex: 1
  },
  {
    headerName: 'Read',
    field: 'read',
    minWidth: 80,
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => row && String(row.read)
  }
];
