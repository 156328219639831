import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PT from 'prop-types';
import Tab from '@mui/material/Tab';

import { StyledTabs } from './styled';

import Box from '@components/shared/Box/Box';

const NavTabsPanel = ({
  currentIdx: _currentIdx, // refactoring: remove this prop
  currentId,
  tabsList,
  ariaLabel,
  isSubTabPanel,
  panelStyles,
  ...props
}) => {
  const currentIdx = useMemo(
    () => _currentIdx ?? tabsList.findIndex(({ id }) => id === currentId),
    [currentId, _currentIdx]
  );

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', ...panelStyles }}>
      <StyledTabs
        value={currentIdx}
        aria-label={ariaLabel}
        isSubTabPanel={isSubTabPanel}
      >
        {tabsList.map(({ id, title, link }, idx) => (
          <Tab
            aria-label={idx}
            id={id}
            label={title}
            to={link}
            component={Link}
            key={ariaLabel + idx}
            disabled={idx === currentIdx}
            {...props}
          />
        ))}
      </StyledTabs>
    </Box>
  );
};

NavTabsPanel.defaultProps = {
  ariaLabel: 'nav-tabs-panel',
  isSubTabPanel: false
};

NavTabsPanel.propTypes = {
  tabsList: PT.arrayOf(
    PT.shape({
      id: PT.string.isRequired,
      title: PT.string.isRequired,
      link: PT.string.isRequired
    })
  ).isRequired,
  ariaLabel: PT.string.isRequired,
  currentId: PT.oneOfType([PT.string, PT.number]),
  currentIdx: PT.number,
  isSubTabPanel: PT.bool,
  panelStyles: PT.object
};

export default NavTabsPanel;
