import React from 'react';

import { cleanObject } from '@utils/object-cleaner';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import helpers from '@utils/helpers';
import {
  filterOperators,
  permissionsMap,
  renderCommonCell
} from '@constants/common';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import DeleteActionButton from '@components/shared/DataTable/actions/DeleteActionButton';
import BINCheckerApi from '@utils/binCheckerAPI';
import { binCheckerApiUrls } from '@constants/binCheckerApiUrls';

export const initialFilterParams = cleanObject({});
const missingABANumberText = 'No ABA numbers available.';

export const getColumns = ({
  handleDeleteABANumber,
  setDataForEdit,
  setIsShowModal,
  filterOptions
}) => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    filterable: false,
    align: 'center',
    renderCell: ({ row }) => (
      <>
        <EditActionButton
          onClick={() => {
            setIsShowModal({ edit: true });
            setDataForEdit(row);
          }}
          hidden={!helpers.getActionPermission(permissionsMap.routingManage)}
        />
        <DeleteActionButton
          content="Are you sure you want to delete this BIN number?"
          onConfirm={() => handleDeleteABANumber(row)}
          hidden={!helpers.getActionPermission(permissionsMap.routingManage)}
        />
      </>
    )
  },
  {
    headerName: 'Routing number',
    field: 'routingNumber',
    width: 100,
    renderCell: ({ row, field }) => row[field] || missingABANumberText
  },
  {
    headerName: 'Bank name',
    field: 'bankName',
    width: 150,
    renderCell: renderCommonCell
  },
  {
    headerName: 'State',
    field: 'state',
    width: 100,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Source',
    field: 'bankSourceId',
    width: 100,
    type: muiDataTableTypes.singleSelect,
    valueOptions: filterOptions?.sourceOptions || [],
    renderCell: renderCommonCell
  },
  {
    headerName: 'Is Bank Name Translation Needed',
    field: 'isBankTranslationNeeded',
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: ({ row, field }) => (row[field] ? 'Yes' : 'No')
  },
  {
    headerName: 'Translation Name',
    field: 'bankTranslation',
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Used count',
    field: '',
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: () => 'TBD'
  },
  {
    headerName: 'Created date',
    field: 'createdDate',
    width: 170,
    type: muiDataTableTypes.dateRange,
    renderCell: ({ row }) => helpers.getFormattedDate(row?.createdDate),
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Last updated date',
    field: 'updatedDate',
    width: 170,
    type: muiDataTableTypes.dateRange,
    renderCell: ({ row }) => helpers.getFormattedDate(row?.createdDate),
    filterOperators: [filterOperators.dateRange]
  }
];

export const getABANumberListOptions = async () => {
  const result = await Promise.allSettled([
    BINCheckerApi.get(binCheckerApiUrls.options.ccSources)
  ]);

  const [sourceOptions] = Array.isArray(result)
    ? result.map((item) =>
        Array.isArray(item?.value)
          ? item.value.map((i) => ({ label: i.name, value: i.name }))
          : []
      )
    : [[], [], [], []];

  return {
    sourceOptions
  };
};

export const addABANumberFormInitialValues = {
  routingNumber: '',
  bankName: '',
  bankSourceId: '',
  bankTranslation: '',
  state: '',
  isBankTranslationNeeded: false
};

export const editABANumberFormInitialValues = (dataForEdit) => ({
  routingNumber: dataForEdit.routingNumber || '',
  bankName: dataForEdit.bankName || '',
  bankSourceId: dataForEdit.bankSourceId || '',
  bankTranslation: dataForEdit.bankTranslation || '',
  state: dataForEdit.state || '',
  isBankTranslationNeeded: dataForEdit.isBankTranslationNeeded || false
});
