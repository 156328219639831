import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFilledColumns, initialFilterParams } from './config';

import Toastify from '@utils/toastify';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Box from '@components/shared/Box/Box';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import { selectDeclineActions } from '@store/settings';
import { fetchDeclineActions } from '@store/settings/actions';
import Logger from '@utils/logger';
import { paymentProcessingApiUrls } from '@constants/paymentProcessingApiUrls';
import PaymentProcessingApi from '@utils/paymentProcessingApi';

const title = 'Decline Reason Settings';

const DeclineReasonSettings = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const declineActions = useSelector(selectDeclineActions);

  useEffect(() => {
    if (!declineActions) {
      dispatch(fetchDeclineActions());
    }
  }, [declineActions, dispatch]);

  const handleChange = async (declineReasonActionId, declineReasonId) => {
    const { declineReasonAction } = declineActions.items[declineReasonActionId];

    try {
      await PaymentProcessingApi.post(
        paymentProcessingApiUrls.declineReasons.updateAction(declineReasonId),
        {
          action: declineReasonAction
        }
      );
      Toastify.success({
        message: 'You successfully changed decline reason'
      });
      tableRef.current?.fetchData();
    } catch (e) {
      Logger.error(e);
      Toastify.error({
        message: 'An error occurred changing decline reason'
      });
    }
  };

  const declineActionsList = declineActions?.items?.map(
    ({ declineReasonAction, declineReasonActionId }) => ({
      label: declineReasonAction.replace(/([A-Z])/g, ' $1'),
      value: declineReasonActionId
    })
  );

  const getColumns = getFilledColumns({ declineActionsList, handleChange });

  return (
    <MainLayout title={title}>
      <Box mt={2}>
        <ODataPaginatedTable
          tableRef={tableRef}
          getColumns={getColumns}
          usePaginatedDataOptions={{
            apiUrl: paymentProcessingApiUrls.declineReasons.list,
            initialFilterParams: {
              $orderBy: 'declineReasonId desc',
              $filter: {
                ...initialFilterParams
              }
            }
          }}
          rowKey="declineReasonId"
          customApi={PaymentProcessingApi}
        />
      </Box>
    </MainLayout>
  );
};

export default DeclineReasonSettings;
