import * as Yup from 'yup';

import validators from '@utils/validators';
import { loanDefaultLimits, loanDefaultValue } from '@constants/common';
import { loanAmountSchema } from '@components/Application/LoanForm/GeneralStep/schema';

export const disclosureStepFieldsSchema = Yup.object().shape({
  amount: Yup.number()
    .min(loanDefaultLimits.min)
    .max(loanDefaultLimits.max)
    .label('Loan amount')
    .required(),
  militaryStatus: Yup.string().label('Military status').required(),
  marketingOptIn: Yup.bool().label('Next Payday'),
  privacyPolicy: validators.booleanTrue().required(),
  usPatriotAct: validators.booleanTrue().required(),
  electronicDisclosure: validators.booleanTrue().required(),
  backgroundCheckDisclosure: validators.booleanTrue().required(),
  otherPaydayorShortTermInstallmentLoans: validators.booleanTrue().required()
});

export const disclosureStepSchema =
  disclosureStepFieldsSchema.concat(loanAmountSchema);

export const disclosureStepInitialValues = {
  amount: loanDefaultValue,
  militaryStatus: '',
  marketingOptIn: false,
  privacyPolicy: true,
  usPatriotAct: true,
  electronicDisclosure: true,
  backgroundCheckDisclosure: true,
  otherPaydayorShortTermInstallmentLoans: true
};
