import React, { useEffect, useRef } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import qs from 'query-string';

import helpers from '@utils/helpers';
import routes from '@constants/routes';
import { rolesMap } from '@constants/roles';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import Box from '@components/shared/Box/Box';
import {
  getColumns,
  getExportFileName,
  getInitialFilterParams
} from '@components/Leads/LeadReports/config';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import { useServerData } from '@hooks/useServerData';
import {
  emailColumnWidth,
  fullNameColumnWidth
} from '@components/shared/DataTable/DataTable.constants';
import { noFieldData, renderCommonCell } from '@constants/common';
import { showAllValue } from '@components/LeadReports/filters/config';
import SeparatedDateTimeFilter from '@components/shared/SeparatedDateTimeFilter/SeparatedDateTimeFilter';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';
import { fieldWidth } from '@constants/filters';

const title = 'Lead providers reports';

const LeadProvidersReportsPage = () => {
  const history = useHistory();
  const query = qs.parse(history.location.search);
  const exportFileName = getExportFileName(query);
  const tableRef = useRef(null);

  const pinnedColumns = ['leadProviderName'];
  const additionalColumns = {
    start: [
      {
        headerName: 'Lead Provider',
        field: 'leadProviderName',
        minWidth: 150,
        flex: 1,
        renderCell: ({ row, field }) => (
          <Link
            to={
              routes.leads.leadProvidersReports.campaigns +
              `?leadProviderId=${row.leadProviderId}`
            }
          >
            {row[field]}
          </Link>
        )
      }
    ],
    middle: [
      {
        headerName: 'Name',
        field: 'fullName',
        minWidth: fullNameColumnWidth,
        flex: 1,
        renderCell: renderCommonCell
      },
      {
        headerName: 'SSN (last 4)',
        field: 'shortSSN',
        minWidth: 110,
        flex: 1,
        renderCell: ({ row, field }) =>
          helpers.getFormattedSsn(row[field]) || noFieldData
      },
      {
        headerName: 'Email',
        field: 'email',
        minWidth: emailColumnWidth,
        flex: 1,
        renderCell: renderCommonCell
      }
    ]
  };

  const { data: campaignsOptions, fetchData } = useServerData({
    endpoint: async () => {
      const response = await LeadManagementApi.get(
        leadManagementApiUrls.campaigns.list,
        {
          params: {
            $select: 'campaignId,campaignName',
            $orderby: 'campaignName'
          }
        }
      );

      return response.value?.map((item) => ({
        label: item.campaignName,
        leadCampaign: item.campaignName,
        value: item.campaignName
      }));
    }
  });

  useEffect(() => {
    fetchData();
  }, []);

  const getColumnsWithAdditional = () =>
    getColumns({ additionalColumns, campaignsOptions });

  const isAdminOrManager = helpers.hasRole(rolesMap.Admin, rolesMap.Manager);

  if (!isAdminOrManager) {
    return <Redirect to={routes.permissionDenied} />;
  }

  return (
    <MainLayout title={title}>
      <Box mt={1}>
        <Box mt={1}>
          <SeparatedDateTimeFilter
            tableRef={tableRef}
            campaignsOptions={campaignsOptions}
            isAdmin
            additionalFieldsProps={[
              {
                name: 'leadCampaign',
                type: 'autocomplete',
                label: 'Lead campaigns',
                options: [
                  { label: 'All campaigns', value: showAllValue },
                  ...(campaignsOptions || [])
                ],
                sx: { ...fieldWidth }
              }
            ]}
          />
        </Box>
        <ODataPaginatedTable
          tableRef={tableRef}
          getColumns={getColumnsWithAdditional}
          customApi={LeadManagementApi}
          usePaginatedDataOptions={{
            apiUrl: leadManagementApiUrls.adminLeadProviderRequests,
            initialFilterParams: {
              $orderBy: 'createdDate desc',
              $count: true,
              $filter: getInitialFilterParams()
            }
          }}
          tableProps={{
            pinnedColumnsLeft: pinnedColumns
          }}
          rowKey="leadProviderRequestId"
          exportFileName={exportFileName}
          withRefreshButton
          parseBooleans={false}
        />
      </Box>
    </MainLayout>
  );
};

export default LeadProvidersReportsPage;
