const reISO =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.{0,1}\d*))(?:Z|(\+|-)([\d|:]*))?$/;
const reMsAjax = /^\/Date\((d|-|.*)\)[/|\\]$/;

function jsonDateParser(_, value) {
  let parsedValue = value;
  if (typeof value === 'string') {
    let a = reISO.exec(value);
    if (a) {
      parsedValue = new Date(value);
    } else {
      a = reMsAjax.exec(value);
      if (a) {
        const b = a[1].split(/[-+,.]/);
        parsedValue = new Date(b[0] ? +b[0] : 0 - +b[1]);
      }
    }
  }

  return parsedValue;
}

function guidParser(key, value) {
  if (key === 'eq guid') {
    delete this['eq guid'];
    this.eq = { type: 'guid', value };
    return;
  }
  return value;
}

export function filterParser(key, value) {
  const partialParsedValue = jsonDateParser(key, value);
  return guidParser.call(this, key, partialParsedValue);
}
