import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserName } from '../utils';

import { getColumns } from './config';
import ComparisonsModal from './comparisonsModal/comparisonsModal';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';
import { subTabsListMain } from '@components/CsvComparison/config';
import CsvComparisonApi from '@utils/csvComparisonApi';
import { csvComparisonApiUrls } from '@constants/csvComparisonApi';
import Logger from '@utils/logger';
import toastify from '@utils/toastify';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import { permissionsMap } from '@constants/permissions';
import SecurityGuard from '@components/shared/SecurityGuard/SecurityGuard';
import { selectCurrentUser } from '@store/currentUser';
import { tabNameMain } from '@constants/comparison';

const CsvComparisonPage = () => {
  const tableRef = useRef();
  const [open, setOpen] = useState(false);
  const storeCurrentUser = useSelector(selectCurrentUser);

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onSubmitCreteComparison = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);

      const formData = new FormData();

      const userName = getUserName(storeCurrentUser);

      if (userName) {
        formData.append('username', userName);
      }

      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });

      await CsvComparisonApi.post(
        csvComparisonApiUrls.comparisons.main,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json'
          }
        }
      );

      tableRef.current?.fetchData();

      toastify.success({
        message: 'Comparison was created'
      });

      onClose();
    } catch (e) {
      Logger.error(e);
      toastify.error(
        e?.response?.data?.error || {
          message: 'An error occurred creating comparison'
        }
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <MainLayout title="CSV Compare">
      <NavTabsPanel
        currentId={tabNameMain.compare}
        tabsList={subTabsListMain}
        ariaLabel="access-tabs"
      />
      <Stack flexDirection="row" justifyContent="flex-end" marginTop={1}>
        <SecurityGuard permission={permissionsMap.CSVCompareManage}>
          <Button onClick={onOpen}>Compare</Button>
        </SecurityGuard>
      </Stack>

      <ODataPaginatedTable
        tableRef={tableRef}
        exportCSV={false}
        getColumns={getColumns}
        usePaginatedDataOptions={{
          apiUrl: csvComparisonApiUrls.comparisons.list,
          initialFilterParams: {
            $select:
              'id,dateCreated,usernameCreated,runtimeMilliseconds,status',
            $expand:
              'ComparisonFiles($select=id,fileRows,fileName,fileRowsMismatched,configName)',
            $orderBy: 'dateCreated desc'
          }
        }}
        customApi={CsvComparisonApi}
        rowKey="id"
      />

      <ComparisonsModal
        open={open}
        onClose={onClose}
        onSubmit={onSubmitCreteComparison}
      />
    </MainLayout>
  );
};

export default CsvComparisonPage;
