import React, { useRef, useEffect } from 'react';
import PT from 'prop-types';
import { Formik, Form } from 'formik';
import omit from 'lodash/omit';

import {
  creditCardFormSchema,
  initialValues
} from '@components/CreditCard/CCForm/schema';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import { fieldsMasks } from '@constants/common';
import toastify from '@utils/toastify';
import Typography from '@components/shared/Typography/Typography';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';
import helpers from '@utils/helpers';
import Alert from '@components/shared/Alert/Alert';
import { statesOptions } from '@constants/states';
import SchemaProvider from '@components/shared/Form/SchemaProvider';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const CCForm = ({ data, onSuccess }) => {
  const formRef = useRef(null);

  useEffect(() => {
    formRef.current.resetForm();
  }, [data]);

  const handleSubmit = async (_values, actions) => {
    actions.setSubmitting(true);

    try {
      const values = omit(_values, ['cardType']);
      const { streetAddress, zipCode, expireDate, ...otherValues } = values;
      const payload = {
        ...otherValues,
        billingAddress: {
          streetAddress,
          zipCode
        },
        zipCode,
        expMonth: expireDate.slice(0, 2),
        expYear: expireDate.slice(-2)
      };

      await AdminApi.post(adminApiUrls.creditCard.saveData(data.id), payload);

      toastify.success({
        message: 'Credit card saved successfully'
      });
      onSuccess();
    } catch (e) {
      const errorsList = helpers.handleServerErrors(e);
      actions.setErrors(errorsList);

      toastify.error({
        message: e.message || 'Failed to submit form',
        description: e.nonFieldErrors || 'Failed to submit form'
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleClear = () => formRef.current.resetForm();

  return (
    <>
      <Typography variant="h4" my={2}>
        Debit Card Information
      </Typography>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={creditCardFormSchema}
        innerRef={formRef}
        enableReinitialize
      >
        {({ values, errors }) => (
          <SchemaProvider schema={creditCardFormSchema}>
            <Form>
              <FormikField
                type="text"
                name="name"
                label="Cardholder name"
                placeholder="Cardholder name"
                autoFocus
              />
              <FormikField
                type="text"
                name="streetAddress"
                label="Billing address"
                placeholder="Billing address"
              />
              <FormikField type="text" name="city" label="Billing City" />
              <FormikField
                type="autocomplete"
                options={statesOptions}
                name="state"
                label="Billing State"
              />
              <FormikField
                type="text"
                name="zipCode"
                label="Billing ZIP code"
                placeholder="Billing ZIP code"
              />
              <FormikField
                type="mask"
                name="number"
                label="Card number"
                mask={fieldsMasks.cardNumber.mask}
                placeholder={fieldsMasks.cardNumber.placeholder}
                canBeNotCompleted
                plainValue
              />
              <FormikField
                type="text"
                name="cardType"
                label="Card Type"
                value={helpers.getCardType(values.number)}
                disabled
              />
              <FormikField
                type="mask"
                name="expireDate"
                label="Expire date"
                mask={fieldsMasks.cardExpire.mask}
                placeholder={fieldsMasks.cardExpire.placeholder}
                plainValue
              />
              <FormikField
                type="text"
                name="code"
                label="CVV"
                placeholder="CVV"
              />
              {errors.nonFieldErrors && (
                <Alert message={errors.nonFieldErrors} type="error" />
              )}

              <div className="before_fixed_buttons" />
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                className="fixed_buttons"
              >
                <Button type="submit">Save</Button>
                <Button onClick={handleClear}>Clear</Button>
              </Stack>
            </Form>
          </SchemaProvider>
        )}
      </Formik>
    </>
  );
};

CCForm.propTypes = {
  data: PT.any.isRequired,
  onSuccess: PT.func.isRequired
};

export default CCForm;
