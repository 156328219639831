import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Alert from '@components/shared/Alert/Alert';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Logger from '@utils/logger';
import CampaignGroupDetails from '@components/Leads/campaignGroups/Details';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

const CampaignGroupViewPage = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await LeadManagementApi.get(
          leadManagementApiUrls.campaignGroups.page(id)
        );
        setData(response);
      } catch (e) {
        Logger.error(e);
        setError(e);
      }
    })();
  }, []);

  return (
    <MainLayout title="View Campaign Group" isLoading={!data && !error}>
      {data && <CampaignGroupDetails data={data} />}
      {error && (
        <Alert
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
        />
      )}
    </MainLayout>
  );
};

export default CampaignGroupViewPage;
