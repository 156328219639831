import startCase from 'lodash/startCase';
import { useEffect, useState } from 'react';

import toastify from '@utils/toastify';
import AdminApi from '@utils/adminApi';

const getOptions = async (endpoints) => {
  const arrayOfPromises = Object.values(endpoints).map((endpoint) =>
    AdminApi.get(endpoint)
  );

  const response = await Promise.allSettled(arrayOfPromises);
  const keys = Object.keys(endpoints);

  const result = response.map(({ value, reason }, id) => {
    if (reason) {
      toastify.error({
        message: `Failed fetching ${startCase(keys[id])} data`
      });
    }
    if (value) {
      return value?.items.map((item) => ({
        label: item.name || item.displayName,
        value: item.id
      }));
    }
  });
  return result;
};

export const useGetOptions = (endpoints) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const response = await getOptions(endpoints);
    setResult(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data: result ? result : [], loading };
};
