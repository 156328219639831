import React, { useCallback, useEffect, useRef, useState } from 'react';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import BINCheckerApi from '@utils/binCheckerAPI';
import { binCheckerApiUrls } from '@constants/binCheckerApiUrls';
import toastify from '@utils/toastify';
import Logger from '@utils/logger';
import {
  getABANumberListOptions,
  getColumns,
  initialFilterParams
} from '@components/BINChecker/ABANumberList/abaNumbersList.config';
import Box from '@components/shared/Box/Box';
import Button from '@components/shared/Button/Button';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import Modal from '@components/shared/Modal/Modal';
import AddABANumberForm from '@components/BINChecker/ABANumberList/AddABANumberForm';
import EditABANumberForm from '@components/BINChecker/ABANumberList/EditABANumberForm';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';

const ABANumberListPage = () => {
  const [isShowModal, setIsShowModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataForEdit, setDataForEdit] = useState(null);
  const [filterOptions, setFilterOptions] = useState(null);
  const tableRef = useRef(null);

  const handleDeleteABANumber = async (row) => {
    try {
      await BINCheckerApi.delete(
        binCheckerApiUrls.bankRoutingNumbers.item(row.bankRoutingNumberId)
      );
      toastify.success({
        message: 'ABA number deleted successfully'
      });
      tableRef.current?.fetchData();
    } catch (error) {
      toastify.error({
        message: 'Failed to delete ABA number'
      });
      Logger.error(error);
    }
  };

  const handleEditABANumber = async (values) => {
    try {
      setIsLoading(true);
      await BINCheckerApi.patch(
        binCheckerApiUrls.bankRoutingNumbers.list,
        values,
        {
          params: { bankRoutingNumberId: dataForEdit.bankRoutingNumberId }
        }
      );
      toastify.success({
        message: 'ABA number updated successfully.'
      });
      setIsShowModal(false);
      tableRef.current?.fetchData();
    } catch (error) {
      toastify.error({
        message: 'Failed to submit form'
      });
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddABANumber = async (values) => {
    try {
      setIsLoading(true);
      await BINCheckerApi.post(
        binCheckerApiUrls.bankRoutingNumbers.list,
        values
      );
      toastify.success({
        message: 'ABA number added successfully.'
      });
      setIsShowModal(false);
      tableRef.current?.fetchData();
    } catch (error) {
      toastify.error({
        message: error.response?.data?.error?.message || 'Failed to submit form'
      });
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getABANumbersColumns = useCallback(
    () =>
      getColumns({
        handleDeleteABANumber,
        setDataForEdit,
        setIsShowModal,
        filterOptions
      }),
    [filterOptions]
  );

  const fetchOptions = async () => {
    const options = await getABANumberListOptions();
    setFilterOptions(options);
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <MainLayout>
      <Box mt={1}>
        {helpers.getActionPermission(permissionsMap.routingManage) && (
          <Button onClick={() => setIsShowModal({ add: true })}>
            Add ABA Number
          </Button>
        )}
      </Box>
      <ODataPaginatedTable
        tableRef={tableRef}
        getColumns={getABANumbersColumns}
        usePaginatedDataOptions={{
          apiUrl: binCheckerApiUrls.bankRoutingNumbers.list,
          initialFilterParams: {
            $orderBy: 'bankRoutingNumberId desc',
            $filter: {
              ...initialFilterParams
            }
          }
        }}
        rowKey="bankRoutingNumberId"
        detailPanelHeight={500}
        exportFileName="ABA Number List"
        customApi={BINCheckerApi}
        exportCSV={false}
      />
      <Modal
        title={isShowModal?.add ? 'Add ABA Number' : 'Edit ABA Number'}
        open={isShowModal?.add || isShowModal?.edit}
        onClose={() => setIsShowModal(false)}
        minContentWidth="400px"
        withCloseIcon
      >
        {isShowModal?.add && (
          <AddABANumberForm
            onSubmit={handleAddABANumber}
            isLoading={isLoading}
            onModalClose={() => setIsShowModal(false)}
            sourceOptions={filterOptions?.sourceOptions}
          />
        )}
        {isShowModal?.edit && (
          <EditABANumberForm
            onSubmit={handleEditABANumber}
            isLoading={isLoading}
            onModalClose={() => setIsShowModal(false)}
            dataForEdit={dataForEdit}
            sourceOptions={filterOptions?.sourceOptions}
          />
        )}
      </Modal>
    </MainLayout>
  );
};

export default ABANumberListPage;
