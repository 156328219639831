import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import routes from '@constants/routes';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Logger from '@utils/logger';
import MobileAppVersionsForm from '@components/MobileAppVersions/Form';
import toastify from '@utils/toastify';
import { adminApiUrls } from '@constants/adminApiUrls';
import AdminApi from '@utils/adminApi';

const MobileAppVersionsCreate = () => {
  const [latestVersion, setLatestVersion] = useState(null);
  const history = useHistory();

  const handleSubmit = async (data) => {
    try {
      await AdminApi.post(adminApiUrls.mobileAppVersions.list, data);
      history.push(routes.osAndAppVersions.mobileAppVersions.list);
    } catch (e) {
      Logger.error(e);
      toastify.error({
        message: 'Create operation failure'
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const latestVersion = await AdminApi.get(
          adminApiUrls.applicationLatestVersion
        );
        setLatestVersion(latestVersion);
      } catch (e) {
        Logger.error(e);
      }
    })();
  }, []);

  return (
    <MainLayout title="Create App Version" isLoading={latestVersion === null}>
      <MobileAppVersionsForm handleSubmit={handleSubmit} />
    </MainLayout>
  );
};

export default MobileAppVersionsCreate;
