import { formatValueWithPercentage } from '@components/shared/DataTable/DataTable.utils';
import { IBVPlatforms } from '@constants/internetBankVerification';
import { muiDataTableTypes } from '@constants/tableFilterConfig';

export const getColumns = () => [
  {
    headerName: 'IBV Provider Name',
    field: 'providerName',
    flex: 1,
    type: muiDataTableTypes.singleSelect,
    valueOptions: IBVPlatforms,
    renderCell: ({ row }) => row.providerName
  },
  {
    headerName: 'Login Attempts',
    field: 'loginAttempts',
    flex: 1,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Successful Logins',
    field: 'successfulAttempts',
    flex: 1,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Success rate',
    field: 'successRate',
    flex: 1,
    valueFormatter: formatValueWithPercentage,
    type: muiDataTableTypes.number
  }
];
