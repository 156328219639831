import React from 'react';
import { Link } from 'react-router-dom';

import ApplicationDetailsTab from './ApplicationDetailsTab/ApplicationDetailsTab';
import ApplicationTelemetryData from './ApplicationTelemetryData';
import ApplicationLeadResponsesHistory from './ApplicationLeadResponsesHistory';
import ApplicationAttributes from './ApplicationAttributes';
import IDVImagesTab from './IDVImagesTab/IDVImagesTab';

import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';
import routes from '@constants/routes';
import UserEventsLink from '@components/shared/UserEventsLink';
import Tooltip from '@components/shared/Tooltip/Tooltip';

const useApplicationDetailsConfig = (data, applicationId) => {
  const displayName = helpers.getDisplayName(
    data?.customerInfo?.firstName,
    data?.customerInfo?.lastName
  );

  const tabContentList = [
    {
      key: 'application-details',
      label: 'Application details',
      content: <ApplicationDetailsTab data={data} />
    },
    {
      key: 'telemetry',
      label: 'Telemetry',
      content: <ApplicationTelemetryData id={applicationId} />,
      hidden: !helpers.getActionPermission(permissionsMap.telemetryView)
    },
    {
      key: 'lead-responses-history',
      label: 'Submits history',
      content: <ApplicationLeadResponsesHistory id={applicationId} />,
      hidden: !helpers.getActionPermission(permissionsMap.telemetryView)
    },
    {
      key: 'query-strings',
      label: 'Attributes',
      content: <ApplicationAttributes />,
      hidden: !helpers.getActionPermission(permissionsMap.telemetryView)
    },
    {
      key: 'idv-images',
      label: 'IDV images',
      content: <IDVImagesTab id={applicationId} />,
      hidden: !helpers.getActionPermission(
        permissionsMap.identityVerificationView
      )
    }
  ];

  const appResultsInfoList = [
    {
      title: 'Result:',
      content: data.leadResponse?.result
    },
    {
      title: 'Date:',
      content: helpers.getFormattedDate(data.leadResponse?.createdDate)
    },
    {
      title: 'Loan agreement URL:',
      content: (
        <a
          href={data.leadResponse?.redirectUrl}
          target="_blank"
          rel="noreferrer"
          style={{ wordBreak: 'break-all' }}
        >
          {data.leadResponse?.redirectUrl}
        </a>
      )
    },
    {
      title: 'Reject reason:',
      content: data.leadResponse?.rejectionReason
    }
  ];

  const appGeneralInfoList = [
    {
      title: 'Customer name:',
      content:
        helpers.getActionPermission(permissionsMap.usersView) && data.userId ? (
          <Tooltip title="User page">
            <Link to={routes.users.page.view.url(data.userId)}>
              {displayName}
            </Link>
          </Tooltip>
        ) : (
          displayName
        )
    },
    {
      title: 'Application ID:',
      content: data.applicationId
    },
    {
      title: 'Customer email:',
      content: data.customerInfo?.email
    },
    {
      title: 'Application UID:',
      content: <UserEventsLink executedBy={data.applicationUid} />
    },
    {
      title: 'Loan amount:',
      content: data.loanPrincipal?.amount
    },
    {
      title: 'Events',
      content:
        data.userId &&
        helpers.getActionPermission(permissionsMap.loggedView) ? (
          <UserEventsLink executedBy={data.userId} />
        ) : (
          data.userId
        )
    },
    {
      title: 'LMS loan number:',
      content: data.userLoan?.loanNumber
    },
    {
      title: 'Application created:',
      content: helpers.getFormattedDate(data.createdDate)
    },
    {
      title: 'LMS customer ID:',
      content: data.userLoan?.customerId
    },
    {
      title: 'Application updated:',
      content: helpers.getFormattedDate(data.updatedDate)
    },
    {
      title: 'Promo code:',
      content: data.customerInfo?.promo
    },
    {
      title: 'Tier:',
      content: data.tier
    },
    {
      title: 'Lead Provider Source:',
      content: data.ppcQuerySource
    },
    {
      title: 'App Version:',
      content: data.metaAttributes?.find(
        (el) => el.metaAttributeName === 'X-Version'
      )?.metaAttributeValue
    },
    {
      title: 'Store:',
      content: data?.store
    },
    {
      title: 'Loan type:',
      content: helpers.getLoanType(data.loanType)
    },
    {
      title: 'User’s e-signature:',
      content: data.signature
    }
  ];

  return {
    tabContentList,
    appResultsInfoList,
    appGeneralInfoList
  };
};

export default useApplicationDetailsConfig;
