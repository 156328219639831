import dayjs from 'dayjs';
import qs from 'query-string';
import duration from 'dayjs/plugin/duration';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';
import camelCase from 'lodash/camelCase';
import concat from 'lodash/concat';
import pluralize from 'pluralize';
import buildQuery from 'odata-query';

// eslint-disable-next-line import/order
import Cookies from './cookies';

dayjs.extend(duration);

import Storage from './storage';
import Logger from './logger';
import Permissions from './permissions';

import {
  dateFormat,
  dateTimeFormat,
  loanTypeFilterMap,
  noFieldData,
  ROLE_KEY,
  ADMIN_ROLE,
  shortTimeFormat,
  timeFormat,
  pastDuePaymentsThresholdYears,
  timeFormat24h,
  DEFAULT_ERROR_MESSAGE,
  LEAD_PROVIDER_ID_KEY,
  redirectKey,
  ORGANIZATION_KEY
} from '@constants/common';
import { redCellColor } from '@constants/colors';
import routes, {
  leadProviderUserRoutes,
  orderedRoutesWithPermissions,
  singlePagePermissions
} from '@constants/routes';
import { cardTypes } from '@constants/cardTypes';

const nonFieldErrorsKey = 'nonFieldErrors';

function handleServerErrors(e) {
  if (e.response?.data) {
    const { data } = e.response;

    const { modelState, message } = data;

    if (modelState) {
      const fieldPrefix = 'model.';

      const errorsArray = Object.entries(modelState).map(([key, value]) => {
        let newKey = key;

        if (key.includes(fieldPrefix)) {
          newKey = key.replace(fieldPrefix, '');
          newKey =
            newKey.slice(0, 1).toLowerCase() + newKey.slice(1, newKey.length);
        } else if (key.includes('model')) {
          newKey = nonFieldErrorsKey;
        }

        return [newKey, Array.isArray(value) ? value.join(', ') : value];
      });

      return Object.fromEntries(errorsArray);
    } else if (message) {
      return { message };
    }
  }

  return e;
}

function handleFormErrors(error, formikActions) {
  let nonFieldErrors = null;

  if (error?.detail || error?.title) {
    nonFieldErrors = error.detail || error.title;
  }

  if (error?.errors) {
    const errors = error?.errors;
    error = Object.keys(errors)?.reduce(
      (acc, item) => ({
        ...acc,
        [camelCase(item)]: errors[item][0]
      }),
      {}
    );
  }

  nonFieldErrors = error?.message || DEFAULT_ERROR_MESSAGE;

  formikActions.setErrors({ ...error, nonFieldErrors });
}

function isAdmin() {
  return Storage.get(ROLE_KEY) === ADMIN_ROLE;
}

function isLeadProvider() {
  const leadProviderId = Cookies.get(ORGANIZATION_KEY);

  const serializedState = leadProviderId
    ? JSON.parse(leadProviderId)
    : undefined;

  return serializedState;
}

function mbToBytes(mb) {
  return mb * 1048576;
}

function getOptionsObject(obj) {
  const entries = Object.entries(obj).map(([key, itemOptions]) => [
    key,
    helpers.getOptions(itemOptions)
  ]);

  return Object.fromEntries(entries);
}

function getOptions(objArray) {
  return objArray.map(({ displayName, id }) => ({
    label: displayName,
    value: id
  }));
}

function searchInclusionsFromWordBeginning(searchText, option) {
  if (!searchText) return true;
  return new RegExp(`^${searchText}`, 'i').test(option.label);
}

function mergeLoanFormData(serverData, initialValues) {
  const serverDataWithRetrievedSelectValues = mapValues(serverData, (value) => {
    if (isPlainObject(value) && has(value, 'id') && has(value, 'displayName')) {
      return value.id;
    }

    return value;
  });

  return {
    ...initialValues,
    ...serverDataWithRetrievedSelectValues
  };
}

function formatDateDiff(seconds) {
  const structure = [
    ['day', 86400],
    ['hour', 3600],
    ['minute', 60],
    ['second', 1]
  ];

  let delta = seconds;

  return structure.map(([key, durationValue]) => {
    const value = Math.floor(delta / durationValue);
    delta -= value * durationValue;
    return [key, value];
  });
}

function getDateDiffString(seconds, labelsMap = {}) {
  const diffList = formatDateDiff(seconds);

  return diffList
    .reduce((acc, [key, value]) => {
      if (!value) return acc;

      acc.push(
        labelsMap[key] ? value + labelsMap[key] : pluralize(key, value, true)
      );

      return acc;
    }, [])
    .join(' ');
}

function getPagesNumber(count, pageSize = 10) {
  return Math.ceil(count / pageSize);
}

function getActionPermission(section) {
  const permissions = Permissions.get();

  if (permissions) {
    return permissions.includes(section);
  }
  return true;
}

function formatToServerTime(dateTimeString) {
  const structuredData = dateTimeString.split(':');
  const structure = [
    ['hour', 3600],
    ['minute', 60]
  ];

  return structuredData
    .map((item, index) => item * structure[index][1])
    .reduce((acc, item) => acc + item);
}

function getTimeFormat(string) {
  return +string <= 9 ? '0' + string : string;
}

function formatFromServerTime(seconds) {
  const dateEntries = new Map(formatDateDiff(seconds));
  const dateObj = Object.fromEntries(dateEntries);

  return `${getTimeFormat(dateObj.hour)}:${getTimeFormat(dateObj.minute)}`;
}

function mapWithPK(data) {
  return Array.isArray(data)
    ? data.map((item, index) => {
        item.key = index;
        return item;
      })
    : data;
}

function checkPolicies(permissions, item) {
  if (!item) {
    return true;
  }
  if (!Array.isArray(item.perm)) {
    return permissions.includes(item.perm);
  }
  switch (item.operator) {
    case 'AND': {
      return item.perm.every((permItem) => permissions.includes(permItem));
    }
    case 'OR': {
      return item.perm.some((permItem) => permissions.includes(permItem));
    }
  }
}

function getFormattedDate(date, format = dateTimeFormat) {
  if (!date) return null;
  return dayjs(date).format(format);
}

function renderBooleanValue(value) {
  return value ? 'yes' : 'no';
}

function getDisplayName(firstName = '', lastName = '') {
  return [firstName, lastName].filter(Boolean).join(' ');
}

function validateSignature(requiredValue, userInput) {
  return (
    userInput &&
    requiredValue.toLowerCase().trim() === userInput.toLowerCase().trim()
  );
}

function getLoanType(type) {
  return (
    loanTypeFilterMap.find((item) => item.value === type)?.value || noFieldData
  );
}

function parseODataQuery(params) {
  try {
    params.$filter = JSON.parse(params.$filter);
    // eslint-disable-next-line no-empty
  } catch {}
  const paramsWithRenamedKeys = Object.fromEntries(
    Object.entries(params).map(([key, value]) => [
      key.slice(1, key.length),
      value
    ])
  );

  const queryString = buildQuery(paramsWithRenamedKeys);
  return queryString.slice(1, queryString.length);
}

function fromMillisecToSec(ms) {
  if (!isFinite(parseFloat(ms / 1000))) return 0;
  return parseFloat(ms / 1000).toFixed(3);
}

function cutString(string, cutToIndex) {
  return `${String(string).substring(0, cutToIndex)}...`;
}

function parseCSVODataRequest(params) {
  let parsedParams = parseODataQuery(params);

  const dateFilters = parsedParams.matchAll(/(?:ge|le)\s('([^\s]+)')/g);
  for (const dateFilter of dateFilters) {
    parsedParams = parsedParams.replace(dateFilter[1], dateFilter[2]);
  }

  const datePeriod = params.datePeriod;
  if (datePeriod) {
    parsedParams = parsedParams.concat(
      '&',
      qs.stringify({ datePeriod: datePeriod })
    );
  }

  return parsedParams;
}

function renderRedCell(text, condition) {
  return {
    props: {
      style: {
        background: condition ? redCellColor : undefined
      }
    },
    children: text
  };
}

function handlePermissionsBasedRedirect({ permissions }) {
  const redirectUrl = orderedRoutesWithPermissions.find(({ permission }) =>
    permissions.includes(permission)
  )?.route;
  if (redirectUrl) {
    return redirectUrl;
  }

  if (!permissions.length) {
    return routes.permissionDenied;
  }
  if (helpers.isLeadProvider()) {
    return leadProviderUserRoutes[0];
  }

  return singlePagePermissions.find(
    ({ permissions }) => !xor(permissions, permissions).length
  )?.route;
}

function getMainPageRedirect({ permissions }) {
  if (Array.isArray(permissions)) {
    return handlePermissionsBasedRedirect({ permissions });
  }
  return routes.applications.list;
}

function isSinglePage(userPermission) {
  if (!userPermission || !Array.isArray(userPermission)) {
    return false;
  }

  const singlePerm = singlePagePermissions.reduce(
    (acc, item) => concat(acc, item.permissions),
    []
  );

  return singlePerm.some((permission) => userPermission.includes(permission));
}

function getValidServerTime(time) {
  return dayjs(time).isValid()
    ? dayjs(time).format(shortTimeFormat.server)
    : time;
}

function hasRole(...roles) {
  const rolesString = Storage.get(ROLE_KEY);
  if (!rolesString) {
    return true;
  }
  const currentRoles = JSON.parse(rolesString);
  return roles.some((role) => currentRoles.includes(role));
}

function getFormattedSsn(ssn) {
  if (!ssn) return null;
  const visibleDigits = ssn.substr(-4);
  return `XXX-XX-${visibleDigits}`;
}

function formatDateRange(dateRange, format = dateFormat) {
  const dateFrom = dayjs(dateRange[0]).format(format);
  const dateTo = dayjs(dateRange[1]).format(format);
  return `${dateFrom}-${dateTo}`;
}

function concatenateDateAndTime(
  startTime,
  endTime,
  startDateString,
  endDateString
) {
  const startTimeString = startTime.format(timeFormat);
  const endTimeString = endTime.format(timeFormat);

  const startDate = dayjs(`${startDateString} ${startTimeString}`);
  const endDate = dayjs(`${endDateString} ${endTimeString}`);
  return { startDate, endDate };
}

function convertStartAndEndDatesToDateTimes(startDate, endDate) {
  const startTimeString = dayjs().subtract(1, 'day').startOf('day');
  const endTimeString = dayjs().endOf('day');

  return concatenateDateAndTime(
    startTimeString,
    endTimeString,
    startDate,
    endDate
  );
}

function downloadFile(url, fileName) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    })
    .catch((e) => Logger.error(e));
}

function getFormattedDurationFromSeconds(seconds, format) {
  if (seconds === null) {
    return null;
  }
  const duration = dayjs.duration(seconds, 'seconds');
  const formattedTime = duration.format(format || timeFormat24h);
  return formattedTime;
}

function getFormattedTimeToSeconds(timeString, format = timeFormat) {
  const time = dayjs(timeString, format);
  const hours = time.hour() * 3600;
  const minutes = time.minute() * 60;
  const seconds = time.second();
  return hours + minutes + seconds;
}

function addSecondsToDate(dateString, seconds) {
  const dateObject = dayjs(dateString);

  if (!dateObject.isValid()) {
    throw new Error('Invalid date string');
  }

  const newDate = dateObject.add(seconds, 'second');

  return newDate.format(dateTimeFormat);
}

function isVeryLongAgo(date) {
  const inputDate = dayjs(date);
  const yearsDiff = dayjs().diff(inputDate, 'year');
  return yearsDiff >= pastDuePaymentsThresholdYears;
}

function getFormattedDurationFromMilliseconds(date1, date2, asSeconds) {
  if (date1 === null || date2 === null) {
    return null;
  }
  const dayjsDate1 = dayjs(date1);
  const dayjsDate2 = dayjs(date2);
  const differenceInMilliseconds = dayjsDate2.diff(dayjsDate1);
  const duration = dayjs.duration(differenceInMilliseconds);

  if (asSeconds) {
    return duration.asSeconds();
  }

  const formattedTime = duration.format('HH:mm:ss');
  return formattedTime;
}

function getCardType(_number) {
  if (!_number) return '';
  const number = _number?.replace(/[-\s]/g, '');

  const rules = {
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    maestro:
      /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    dankort: /^(5019)\d+$/,
    interPayment: /^(636)\d+$/,
    unionPay: /^(62|88)\d+$/,
    visa: /^4[0-9]{5,12}(?:[0-9]{3})?$/,
    mastercard:
      /^5[1-5][0-9]{0,14}|^(222[1-9]|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{0,12}$/,
    amex: /^3[47][0-9]{0,13}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{0,11}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{0,12}$/,
    jcb: /^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9]))\d{12})$/,
    jcb15: /^(?:2131|2100|1800|35[0-9]{3})[0-9]{11}$/
  };

  const typeKey = Object.keys(rules).find((key) => {
    if (rules[key].test(number)) {
      return key;
    }
  });
  return cardTypes[typeKey] || 'Unknown';
}

function getCardMask(bin, l4) {
  if (!bin && !l4) return noFieldData;
  return `${bin || ''} ****** ${l4 || ''}`;
}

// when default required prop doesn't apply
function getFieldLabelWithAsterisk(label, isRequired) {
  if (!isRequired) {
    return label;
  }
  return `${label}*`;
}

function getLoginRedirectUrl(redirectPath) {
  return routes.auth.login + `?${redirectKey}=` + redirectPath;
}

function isObjectWithEmptyValues(obj) {
  return Object.values(obj).every(isEmpty);
}

function formatMilliseconds(decimalMilliseconds) {
  const milliseconds = Math.floor(decimalMilliseconds);
  const duration = dayjs.duration(milliseconds);

  const hours = Math.floor(milliseconds / (1000 * 60 * 60))
    .toString()
    .padStart(2, '0');
  const minutes = duration.minutes().toString().padStart(2, '0');
  const seconds = duration.seconds().toString().padStart(2, '0');
  const formattedMilliseconds = (duration.milliseconds() / 1000)
    .toFixed(3)
    .slice(2, 4);

  return `${hours}:${minutes}:${seconds}.${formattedMilliseconds}`;
}

function isLeadProviderIDHasPolicies(policies) {
  if (!policies) {
    return;
  }
  return policies?.some((item) => item.policyType === LEAD_PROVIDER_ID_KEY);
}

function getDurationBetweenTwoDates(startDate, endDate) {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const diff = end.diff(start);
  const durationObj = dayjs.duration(diff);
  const durationAsDayjs = dayjs().startOf('day').add(durationObj);
  return durationAsDayjs;
}

const helpers = {
  handleServerErrors,
  handleFormErrors,
  isAdmin,
  mbToBytes,
  getOptionsObject,
  getOptions,
  searchInclusionsFromWordBeginning,
  mergeLoanFormData,
  getDateDiffString,
  getPagesNumber,
  getActionPermission,
  formatDateDiff,
  formatToServerTime,
  formatFromServerTime,
  mapWithPK,
  checkPolicies,
  getFormattedDate,
  renderBooleanValue,
  getDisplayName,
  validateSignature,
  getLoanType,
  parseODataQuery,
  fromMillisecToSec,
  cutString,
  parseCSVODataRequest,
  renderRedCell,
  getMainPageRedirect,
  isSinglePage,
  getValidServerTime,
  hasRole,
  isLeadProvider,
  getFormattedSsn,
  formatDateRange,
  concatenateDateAndTime,
  convertStartAndEndDatesToDateTimes,
  downloadFile,
  isVeryLongAgo,
  getFormattedDurationFromMilliseconds,
  getFormattedDurationFromSeconds,
  addSecondsToDate,
  getFormattedTimeToSeconds,
  getFieldLabelWithAsterisk,
  getCardType,
  getLoginRedirectUrl,
  isObjectWithEmptyValues,
  getCardMask,
  formatMilliseconds,
  isLeadProviderIDHasPolicies,
  getDurationBetweenTwoDates
};

export default helpers;
