import React, { useMemo } from 'react';
import PT from 'prop-types';

import { disclosureStepInitialValues, disclosureStepSchema } from './schema';

import { militaryStatusesDescriptions } from '@constants/militaryVariants';
import StepContainer from '@components/Application/LoanForm/StepContainer';
import withStepData from '@components/Application/LoanForm/withStepData';
import Typography from '@components/shared/Typography/Typography';
import FormikField from '@components/shared/Form/FormikField/FormikField';

const DisclosureStep = ({ onSubmit, initialValues, options }) => {
  const mergedInitialValues = useMemo(() => {
    const militaryStatusId = initialValues.militaryStatus?.id;

    return {
      ...disclosureStepInitialValues,
      ...initialValues,
      militaryStatus: militaryStatusId
        ? String(militaryStatusId)
        : disclosureStepInitialValues.militaryStatus
    };
  }, [initialValues]);

  const militaryStatusOptions = useMemo(
    () =>
      options.militaryStatus.map(({ id, displayName }, idx) => ({
        value: String(id),
        label: `Military Status ${idx + 1}`,
        description: militaryStatusesDescriptions[displayName] || ''
      })),
    [options.militaryStatus]
  );

  const handleSubmit = (values, handlers) =>
    onSubmit({ values, initialValues: mergedInitialValues }, handlers);

  return (
    <StepContainer
      formSchema={disclosureStepSchema}
      initialValues={mergedInitialValues}
      onSubmit={handleSubmit}
      title="Disclosure"
    >
      <div className="loan-form__fields-block">
        <Typography variant="h5">
          Military Status{' '}
          <Typography color="error" sx={{ display: 'inline' }}>
            *
          </Typography>
        </Typography>
        <div className="loan-form__text-body-s mb-2">
          Federal law provides important protections to active duty members of
          the Armed Forces and their dependents. To ensure that these
          protections are provided to eligible applicants, we require you to
          select one of the following statements as applicable:
        </div>
        <FormikField
          type="radio"
          name="militaryStatus"
          options={militaryStatusOptions}
          controlProps={{ size: 'small', sx: { py: 0 } }}
          sx={{ px: 1 }}
        />
      </div>

      <Typography variant="h5" mb={2}>
        Additional Information
      </Typography>
      <FormikField
        type="checkbox"
        name="marketingOptIn"
        label="Do not Send me Promotional email"
      />
      <FormikField
        type="checkbox"
        name="privacyPolicy"
        label={
          <>
            I have read and agree with <b>Privacy policy</b>
          </>
        }
      />
      <FormikField
        type="checkbox"
        name="usPatriotAct"
        label={
          <>
            I have read and agree with <b>US Patriot Act Disclosure</b>
          </>
        }
      />
      <FormikField
        type="checkbox"
        name="electronicDisclosure"
        label={
          <>
            I have read and I agree with <b>Electronic Disclosure Statement</b>:
            I expressly consent to eLoanWarehouse contacting me at: i) any
            telephone number; ii) any physical or home address; iii) at any
            email address. provided in this document, updated by me or provided
            by my references in the future, or ascertained by eLoanWarehouse
            through its resources.
          </>
        }
      />
      <FormikField
        type="checkbox"
        name="electronicDisclosure"
        label={
          <>
            I have read and I agree with <b>Electronic Disclosure Statement</b>:
            I expressly consent to eLoanWarehouse contacting me at: i) any
            telephone number; ii) any physical or home address; iii) at any
            email address. provided in this document, updated by me or provided
            by my references in the future, or ascertained by eLoanWarehouse
            through its resources.
          </>
        }
      />
      <FormikField
        type="checkbox"
        name="backgroundCheckDisclosure"
        label={
          <>
            I understand that eLoanWarehouse reserves the right to do a
            background/credit check at any time using any of the consumer
            reporting agencies including but not limited to Clarity, CL Verify,
            Factor Trust.
          </>
        }
      />
      <FormikField
        type="checkbox"
        name="otherPaydayorShortTermInstallmentLoans"
        label={
          <>
            By checking this box and entering your name below, you are
            electronically signing this document and agreeing with the disclosed
            terms and conditions and disclosures. You agree that this electronic
            signature has the full force and effect of your physical signature
            and that it binds you in the same manner a physical signature would
            do so.
          </>
        }
      />
    </StepContainer>
  );
};

DisclosureStep.propTypes = {
  onSubmit: PT.func.isRequired,
  initialValues: PT.object.isRequired,
  options: PT.object.isRequired
};

export default withStepData(DisclosureStep);
