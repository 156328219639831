import { FieldArray } from 'formik';
import PT from 'prop-types';
import React, { useEffect } from 'react';
import TickIcon from '@mui/icons-material/Done';

import { notifiedUsersValidationSchema } from './config';
import { EmailWrapper, StyledDrawer } from './styled';

import { palette } from '@utils/theme';
import { useServerData } from '@hooks/useServerData';
import Stack from '@components/shared/Stack/Stack';
import Logger from '@utils/logger';
import Toastify from '@utils/toastify';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Box from '@components/shared/Box/Box';
import IconButton from '@components/shared/IconButton/IconButton';
import Typography from '@components/shared/Typography/Typography';
import { CloseIcon } from '@components/shared/icons/Icons';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const NotifiedUsersList = ({ isOpen, handleClose }) => {
  const {
    data: notifiedUsersList,
    fetchData: fetchUsersList,
    isLoading: isUsersListLoading
  } = useServerData({
    endpoint: adminApiUrls.healthCheck.notifiedEmails,
    customApi: AdminApi
  });

  const emailsList = notifiedUsersList?.emailAddresses || [];

  useEffect(() => {
    isOpen && fetchUsersList();
  }, [isOpen]);

  const handleSubmitEmails = async (
    { emails: updatedEmails },
    { setSubmitting }
  ) => {
    try {
      const deletedEmails = emailsList.filter(
        (email) => !updatedEmails.includes(email)
      );
      if (deletedEmails.length > 0) {
        const deleteEmailsPromises = deletedEmails.map((email) =>
          AdminApi.delete(adminApiUrls.healthCheck.notifiedEmails, {
            params: { email }
          })
        );
        await Promise.all(deleteEmailsPromises);
      }
      const addedEmails = updatedEmails.filter(
        (email) => !emailsList.includes(email)
      );
      if (addedEmails.length > 0) {
        await AdminApi.post(adminApiUrls.healthCheck.notifiedEmails, {
          emailAddresses: addedEmails
        });
      }
      Toastify.success({ message: 'Emails list updated' });
      handleClose();
    } catch (e) {
      Logger.error(e);
      Toastify.error({ message: 'An error occurred saving notified emails' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StyledDrawer
      anchor="right"
      loading={isUsersListLoading}
      open={isOpen}
      onClose={handleClose}
      title="List of notified users"
      subTitle="Here you can add users who will be notified in case any of statuses will be failed."
      formikProps={{
        initialValues: { emails: emailsList, email: '' },
        validationSchema: notifiedUsersValidationSchema,
        onSubmit: handleSubmitEmails,
        enableReinitialize: true,
        getIsSubmitDisabled: ({ values, errors }) =>
          values.email && !errors.email
      }}
    >
      {({ values, errors, setFieldValue }) => (
        <FieldArray name="emails">
          {({ push, remove }) => (
            <>
              <Stack direction="row" alignItems="flex-start" gap={1} mb={1}>
                <Box flex={1}>
                  <FormikField
                    name="email"
                    type="email"
                    label="Notified users"
                    placeholder="Enter user's email"
                    fullWidth
                  />
                </Box>
                <Box sx={{ minWidth: '40px' }}>
                  {values.email.length > 0 && (
                    <IconButton
                      sx={{ marginLeft: 0 }}
                      onClick={() => {
                        push(values.email);
                        setFieldValue('email', '');
                      }}
                      disabled={!!errors.email}
                    >
                      <TickIcon />
                    </IconButton>
                  )}
                </Box>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                flexWrap="wrap"
                gap={1}
              >
                {values.emails.length === 0 && 'No emails added'}
                {values.emails.map((email, idx) => (
                  <EmailWrapper key={email}>
                    <Typography fontSize="12px">{email}</Typography>
                    <IconButton
                      sx={{ ml: 0, p: '4px', width: '20px', height: '20px' }}
                      onClick={() => remove(idx)}
                    >
                      <CloseIcon sx={{ color: palette.white }} />
                    </IconButton>
                  </EmailWrapper>
                ))}
              </Stack>
            </>
          )}
        </FieldArray>
      )}
    </StyledDrawer>
  );
};

NotifiedUsersList.propTypes = {
  isOpen: PT.bool.isRequired,
  handleClose: PT.func.isRequired
};

export default NotifiedUsersList;
