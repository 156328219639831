import React from 'react';
import XMLViewer from 'react-xml-viewer';
import ReactJson from 'react-json-view';

import helpers from '@utils/helpers';
import Accordion from '@components/shared/Accordion/Accordion';

export const leadKeys = {
  response: 'LeadResponse',
  request: 'LeadRequest',
  customFieldRequest: 'CustomFieldRequest',
  customerRequest: 'CustomerRequest'
};

const handleParseData = (data) => {
  let parseJSON;
  try {
    parseJSON = JSON.parse(data);
  } catch (e) {
    parseJSON = null;
  }

  if (parseJSON) {
    return (
      <ReactJson
        src={parseJSON}
        shouldCollapse={({ type, name }) => type === 'array' || name === 'data'}
      />
    );
  } else if (data.includes('<?xml')) {
    return <XMLViewer xml={data} />;
  } else {
    return data;
  }
};

export const getAccordionSubList = ({
  leadResponse,
  leadRequest,
  leadCustomFieldRequest,
  leadCustomerRequest
}) => [
  {
    key: '1',
    header: 'Request',
    content: handleParseData(leadRequest?.data),
    hidden: !leadRequest
  },
  {
    key: '2',
    header: 'Response',
    content: handleParseData(leadResponse?.data),
    hidden: !leadResponse
  },
  {
    key: '3',
    header: 'Custom field request',
    content: leadCustomFieldRequest && (
      <ReactJson
        src={leadCustomFieldRequest}
        shouldCollapse={({ type, name }) => type === 'array' || name === 'data'}
      />
    ),
    hidden: !leadCustomFieldRequest
  },
  {
    key: '4',
    header: 'Customer request',
    content: leadCustomerRequest && (
      <ReactJson
        src={JSON.parse(leadCustomerRequest?.data)}
        shouldCollapse={({ type, name }) => type === 'array' || name === 'data'}
      />
    ),
    hidden: !leadCustomerRequest
  }
];

export const getAccordionList = (data) =>
  data?.map(
    (
      {
        leadResponse,
        leadRequest,
        leadCustomFieldRequest,
        leadCustomerRequest,
        timeStamp,
        executionTime
      },
      idx
    ) => ({
      key: '1',
      header: `Submit ${idx + 1}: ${helpers.getFormattedDate(
        timeStamp
      )} (execution time - ${(executionTime / 1000).toFixed(2)} s)`,
      content: (
        <Accordion
          getAccordionList={() =>
            getAccordionSubList({
              leadResponse,
              leadRequest,
              leadCustomFieldRequest,
              leadCustomerRequest
            })
          }
        />
      )
    })
  );
