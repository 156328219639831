import React, { useState } from 'react';
import PT from 'prop-types';

import SubmitApplicationModal from '@components/Application/ApplicationDetails/SubmitApplicationModal';
import Logger from '@utils/logger';
import toastify from '@utils/toastify';
import Button from '@components/shared/Button/Button';
import Dialog from '@components/shared/Modal/Dialog';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const SubmitApplicationButton = ({ application, onSuccess }) => {
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (values, actions) => {
    const { amount, signature } = values;

    try {
      actions.setSubmitting(true);

      const endpoint = application.leadResponse
        ? adminApiUrls.apply.loanForm.reSubmit(application.applicationUid)
        : adminApiUrls.apply.loanForm.submit(application.applicationUid);

      await AdminApi.put(
        adminApiUrls.apply.applicationAmount(application.applicationUid),
        {
          amount
        }
      );
      await AdminApi.post(endpoint, {
        signature
      });

      toastify.success({
        message: 'Success',
        description: 'Application form submitted'
      });

      onSuccess();
      setShowModal(false);
    } catch (e) {
      Logger.error(e);

      actions.setErrors({
        ...e,
        nonFieldErrors: e.nonFieldErrors || e.message
      });

      toastify.error({
        message: 'Error',
        description: 'Failed to submit form'
      });

      actions.setSubmitting(false);
    } finally {
      actions.setSubmitting(false);
    }
  };

  if (!application.disclosure) return null;

  return (
    <>
      {application.leadResponse ? (
        <Button
          type="submit"
          onClick={() => setShowModal('resubmit')}
          variant="outlined"
        >
          Re-submit form
        </Button>
      ) : (
        <Button
          type="submit"
          onClick={() => setShowModal('submit')}
          variant="outlined"
        >
          Submit form
        </Button>
      )}

      <SubmitApplicationModal
        open={showModal === 'submit'}
        application={application}
        onClose={() => setShowModal(false)}
        onSubmit={handleSubmit}
      />
      <Dialog
        open={showModal === 'resubmit'}
        onClose={() => setShowModal(false)}
        onConfirm={() => setShowModal('submit')}
        title="Are you sure to open re-submission modal?"
      />
    </>
  );
};

SubmitApplicationButton.propTypes = {
  application: PT.object.isRequired,
  onSuccess: PT.func.isRequired
};

export default SubmitApplicationButton;
