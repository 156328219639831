import { Form, Formik } from 'formik';
import React, { memo, useState } from 'react';
import PT from 'prop-types';

import { CSV_INPUT_NAME } from './UploadCSVForm.config';

import Stack from '@components/shared/Stack/Stack';
import toastify from '@utils/toastify';
import Button from '@components/shared/Button/Button';
import ImportCSVButton from '@components/shared/ImportCSV/ImportCSVButton';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const UploadCSVForm = ({ onModalClose, csvSchema, initialValues }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    const csvFormData = new FormData();
    csvFormData.append(CSV_INPUT_NAME, values[CSV_INPUT_NAME]);

    try {
      setIsLoading(true);
      await AdminApi.post(adminApiUrls.uploadTeamGoalsFile, csvFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json'
        }
      });
      toastify.success({
        message: 'CSV file saved'
      });
    } catch (error) {
      toastify.error({
        message: 'Save operation failure'
      });
    } finally {
      setIsLoading(false);
      onModalClose();
    }
  };

  const handleUploadFile = (file, setFieldValue) => {
    setFieldValue(CSV_INPUT_NAME, file);
  };

  const handleRemoveFile = (setFieldValue, inputRef) => {
    setFieldValue(CSV_INPUT_NAME, null);
    inputRef.current.value = null;
  };

  return (
    <>
      <Formik
        initialValues={{
          ...initialValues
        }}
        validationSchema={csvSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, isValid }) => (
          <Form>
            <Stack direction="row" gap={1} alignItems="center">
              <ImportCSVButton
                file={values[CSV_INPUT_NAME]}
                handleUploadFile={(file) =>
                  handleUploadFile(file, setFieldValue)
                }
                handleRemoveFile={(inputRef) =>
                  handleRemoveFile(setFieldValue, inputRef)
                }
                wrapperProps={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
                isRow
                title="Choose file"
              />

              <Button
                type="submit"
                disabled={!isValid || !values[CSV_INPUT_NAME] || isLoading}
                loading={isLoading}
              >
                Save
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

UploadCSVForm.propTypes = {
  refetchList: PT.func.isRequired,
  onModalClose: PT.func,
  csvSchema: PT.object,
  initialValues: PT.object
};

export default memo(UploadCSVForm);
