import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import ReactJson from 'react-json-view';

import Loader from '@components/shared/Loader';
import Logger from '@utils/logger';
import Accordion from '@components/shared/Accordion/Accordion';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const DrawTelemetry = ({ drawId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [telemetryData, setTelemetryData] = useState({
    drawResponse: {},
    drawRequest: {}
  });

  useEffect(() => {
    (async () => {
      try {
        const {
          items: [telemetryData]
        } = await AdminApi.get(adminApiUrls.drawTelemetry(drawId));
        if (!telemetryData && telemetryData.formData === undefined) {
          return;
        }
        const drawResponse = telemetryData.formData.find(
          ({ dataType }) => dataType === 'DrawResponse'
        );
        const drawRequest = telemetryData.formData.find(
          ({ dataType }) => dataType === 'DrawRequest'
        );
        setTelemetryData({ drawResponse, drawRequest });
      } catch (e) {
        Logger.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleParseData = (data) => {
    let parsedData;
    try {
      parsedData = JSON.parse(data);
    } catch {
      parsedData = {};
    }
    return (
      <ReactJson
        src={parsedData}
        shouldCollapse={({ type, name }) => type === 'array' || name === 'data'}
      />
    );
  };

  const accordionList = [
    {
      key: 'drawResponse',
      header: 'Draw Response',
      content: handleParseData(telemetryData.drawResponse?.data)
    },
    {
      key: 'drawRequest',
      header: 'Draw Request',
      content: handleParseData(telemetryData.drawRequest?.data)
    }
  ];

  if (isLoading) {
    return <Loader />;
  }

  return <Accordion accordionList={accordionList} />;
};

DrawTelemetry.propTypes = {
  drawId: PT.string.isRequired
};

export default DrawTelemetry;
