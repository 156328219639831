export const fullNameColumnWidth = 200;
export const emailColumnWidth = 340;
export const phoneNumberColumnWidth = 90;
export const timeDateColumnWidth = 180;
export const eventsColumnWidth = 100;
export const ipAddressColumnWidth = 120;
export const requestIdColumnWidth = 340;

export const cellClassNameRed = 'cell--red';
export const totalValueCellClassName = 'cell--totalValue';
export const COLUMN_DROPDOWN_FILTER_NULL_OPTION_VALUE = 'null';
