import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { useHistory } from 'react-router';

import ApplicationStepFieldsErrorRate from './ApplicationStepFieldsErrorRate';
import {
  columns,
  getDefaultFilterInitialState,
  stepNamesToFieldErrorRatePages
} from './config';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import DashboardFilter from '@components/Dashboard/Filter';
import Alert from '@components/shared/Alert/Alert';
import DataTable from '@components/shared/DataTable/DataTable';
import { useQueryPagination } from '@hooks/useQueryPagination';
import { permissionsMap } from '@constants/permissions';
import helpers from '@utils/helpers';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const AbandonedApplicationsPage = () => {
  const [initialFilters] = useState(getDefaultFilterInitialState());

  const hasPermission = helpers.getActionPermission(
    permissionsMap.fieldsErrorRateView
  );
  const {
    allData: abandonedApplicationsData,
    error: abandonedApplicationsError,
    isLoading: areAbandonedApplicationsLoading
  } = useQueryPagination({
    listUrl: adminApiUrls.dashboard.abandonedApplications,
    initialFilters,
    customApi: AdminApi
  });
  const {
    allData: fieldsErrorRateData,
    error: fieldsErrorRateError,
    isLoading: isFieldsErrorRateLoading
  } = useQueryPagination({
    listUrl: adminApiUrls.dashboard.telemetry,
    initialFilters,
    enabled: hasPermission,
    customApi: AdminApi
  });
  const history = useHistory();

  useEffect(() => {
    if (!history.location?.search) {
      history.replace({
        search: qs.stringify(initialFilters)
      });
    }
  }, [history.location?.search]);

  const getFieldsErrorRateData = (row) => {
    if (!fieldsErrorRateData) {
      return [];
    }
    const pageName = stepNamesToFieldErrorRatePages[row.stepName];
    if (pageName) {
      return fieldsErrorRateData
        .find((page) => pageName === page.pageName)
        ?.dashboardTelemetry?.map((fieldRate) => {
          fieldRate.pageName = pageName;
          return fieldRate;
        });
    }
    return fieldsErrorRateData.flatMap((page) =>
      page?.dashboardTelemetry?.map((fieldRate) => {
        fieldRate.pageName = page.pageName;
        return fieldRate;
      })
    );
  };

  return (
    <MainLayout
      title="Abandoned applications"
      isLoading={!history.location?.search}
    >
      <>
        <DashboardFilter />
        <DataTable
          disableColumnFilter
          columns={columns}
          tableData={abandonedApplicationsData || []}
          loading={areAbandonedApplicationsLoading}
          rowKey="stepName"
          getDetailPanelContent={
            hasPermission
              ? ({ row }) => (
                  <ApplicationStepFieldsErrorRate
                    data={getFieldsErrorRateData(row)}
                    isLoading={isFieldsErrorRateLoading}
                    error={fieldsErrorRateError}
                  />
                )
              : null
          }
          hideFooter
        />
        {abandonedApplicationsError && (
          <Alert
            message="Failed to fetch"
            description={abandonedApplicationsError}
            type="error"
            showIcon
          />
        )}
      </>
    </MainLayout>
  );
};

export default AbandonedApplicationsPage;
