import React from 'react';
import PT from 'prop-types';
import { Form, Formik } from 'formik';
import pick from 'lodash/pick';

import {
  customTargetKeys,
  getInitialValues,
  notificationFieldsSchema
} from './schema';
import BasicFilters from './BasicFilters';
import {
  emptyCSVErrorMessage,
  emptyFormErrorMessage,
  jobTypeOptions,
  requiredCSVFields,
  sampleCSVFileFormat,
  targetGroups
} from './config';

import { CSV_INPUT_NAME } from '@constants/notificationFormConfig';
import Alert from '@components/shared/Alert/Alert';
import Typography from '@components/shared/Typography/Typography';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Grid from '@components/shared/Grid/Grid';
import Button from '@components/shared/Button/Button';
import Stack from '@components/shared/Stack/Stack';
import ImportCSVButton from '@components/shared/ImportCSV/ImportCSVButton';
import ImportCSVInfo from '@components/shared/ImportCSV/ImportCSVInfo';
import helpers from '@utils/helpers';

const TargetStep = ({ onSubmit, jobFilterData, setJobType }) => {
  const handleSubmit = (values, actions) => {
    const error = checkEmptyValues(values);
    if (error) {
      return actions.setErrors({
        nonFieldErrors: error
      });
    }

    onSubmit({ values, actions }, {});
  };

  const handleChangeTargetGroup = (event, setFieldValue, resetForm) => {
    resetForm();
    setJobType(event.target.value);
    setFieldValue('jobType', event.target.value);
  };

  const handleUploadFile = (file, setFieldValue) => {
    setFieldValue(CSV_INPUT_NAME, file);
  };

  const handleRemoveFile = (setFieldValue) => {
    setFieldValue(CSV_INPUT_NAME, null);
  };

  const initialValues = getInitialValues(jobFilterData);

  const checkEmptyValues = ({ jobType, ...values }) => {
    if (jobType == targetGroups.custom) {
      const filteredValues = pick(values, customTargetKeys);
      const isEmptyForm = helpers.isObjectWithEmptyValues(filteredValues);

      return isEmptyForm ? emptyFormErrorMessage : false;
    }
    if (jobType == targetGroups.bulkCsvNotification && !values?.csvFile) {
      return emptyCSVErrorMessage;
    }
    return false;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
      validationSchema={notificationFieldsSchema}
      validateOnChange={false}
      validateOnBlur
      enableReinitialize
    >
      {({ values, setFieldValue, errors, resetForm }) => (
        <Form>
          <Typography variant="h4" my={2}>
            Target users
          </Typography>

          <FormikField
            type="radio"
            name="jobType"
            options={jobTypeOptions}
            onChange={(e) =>
              handleChangeTargetGroup(e, setFieldValue, resetForm)
            }
            hasFeedback
            showValidateSuccess
            flexDirection="row"
          />
          {values.jobType == targetGroups.custom && (
            <Grid container columnSpacing={1}>
              <BasicFilters isUnauthorizedUsersChecked={values.newComer} />
            </Grid>
          )}
          {values.jobType == targetGroups.bulkCsvNotification && (
            <>
              <ImportCSVInfo
                requiredFields={requiredCSVFields}
                sampleFileFormat={sampleCSVFileFormat}
                hasOptionalFields
              />
              <ImportCSVButton
                file={values[CSV_INPUT_NAME]}
                handleUploadFile={(file) =>
                  handleUploadFile(file, setFieldValue)
                }
                sx={{ mb: 1 }}
                handleRemoveFile={() => handleRemoveFile(setFieldValue)}
              />
            </>
          )}
          {errors.nonFieldErrors && (
            <Alert
              message={errors.nonFieldErrors}
              type="error"
              className="mb-3"
            />
          )}
          <Stack alignItems="flex-start" my={2}>
            <Button type="submit">Next</Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

TargetStep.defaultProps = {
  jobFilterData: {}
};

TargetStep.propTypes = {
  onSubmit: PT.func.isRequired,
  jobFilterData: PT.object.isRequired,
  setJobType: PT.func.isRequired
};

export default TargetStep;
