import { useEffect, useRef, useState } from 'react';

export const useVirtualizing = (tableDataLength) => {
  const [height, setHeight] = useState(0);
  const tableContainerRef = useRef();
  const tableRowHeight = 21;
  const offset = 55;
  const acceptableRowNumberToShow = 25;
  const isRowsFitsInTable = tableDataLength > acceptableRowNumberToShow;
  const defaultTableHeight = 150;

  const getTableHeight = () => {
    if (isRowsFitsInTable) {
      return window.innerHeight - height - offset;
    } else if (!tableDataLength) {
      return defaultTableHeight;
    } else {
      return tableDataLength * tableRowHeight + offset;
    }
  };

  useEffect(() => {
    setHeight(tableContainerRef?.current?.offsetTop);
  }, []);

  return {
    tableContainerRef,
    getTableHeight
  };
};
