import React, { useRef, useState } from 'react';

import {
  getFileColumns,
  initialFileFilterParams,
  subTabsList
} from '@components/PastDuePayments/config';
import PastDueFilterForm from '@components/PastDuePayments/PastDueFilterForm';
import EditPlannedExecutionTimeModal from '@components/PastDuePayments/EditPlannedExecutionTimeModal/EditPlannedExecutionTimeModal';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import { ActionsSection } from '@components/shared/MainLayout/MainLayout.components';
import Dialog from '@components/shared/Modal/Dialog';
import toastify from '@utils/toastify';
import Button from '@components/shared/Button/Button';
import { FileUploadIcon } from '@components/shared/icons/Icons';
import { paymentProcessingApiUrls } from '@constants/paymentProcessingApiUrls';
import PaymentProcessingApi from '@utils/paymentProcessingApi';
import UploadCSVModal from '@components/shared/UploadCSVModal/UploadCSVModal';
import {
  requiredCSVFields,
  sampleCSVFileFormat
} from '@components/PastDuePayments/UploadCSV/UploadCSVForm.config';

const title = 'File list';

const FileList = () => {
  const [fileIdToDelete, setFileIdToDelete] = useState(null);
  const [fileIdToChange, setFileIdToChange] = useState(null);
  const [dateToEdit, setDateToEdit] = useState(null);
  const [fileNameToEdit, setFileNameToEdit] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getModalParams = ({ id, date, fileName }) => {
    setFileIdToChange(id);
    setDateToEdit(date);
    setFileNameToEdit(fileName);
  };

  const tableRef = useRef();

  const handleDeleteFile = async () => {
    try {
      await PaymentProcessingApi.delete(
        paymentProcessingApiUrls.batchProcessingsSettings.deleteUploadedFile(
          fileIdToDelete
        )
      );
      setFileIdToDelete(null);
      toastify.success({
        message: 'File deleted successfully'
      });
      tableRef?.current?.fetchData();
    } catch (e) {
      toastify.error({
        message: e?.response?.data?.error?.message || 'Failed to delete file'
      });
    }
  };

  const handleEditPlannedDate = async (date) => {
    try {
      await PaymentProcessingApi.patch(
        paymentProcessingApiUrls.batchProcessingsSettings.updateExecutionTime(
          fileIdToChange
        ),
        { plannedExecutionTime: date }
      );
      setFileIdToChange(null);
      toastify.success({
        message: 'Execution time edited successfully'
      });
      tableRef?.current?.fetchData();
    } catch (e) {
      toastify.error({
        message: e.message || 'Failed to edit execution time'
      });
    }
  };

  return (
    <MainLayout title={title}>
      <NavTabsPanel
        currentIdx={0}
        tabsList={subTabsList}
        ariaLabel="access-tabs"
      />
      <ActionsSection>
        <PastDueFilterForm />
        <div>
          <Button
            onClick={() => setIsModalOpen(true)}
            startIcon={<FileUploadIcon />}
            variant="outlined"
          >
            Upload CSV File
          </Button>
        </div>
      </ActionsSection>
      <ODataPaginatedTable
        tableRef={tableRef}
        getColumns={() =>
          getFileColumns({
            setFileIdToDelete,
            getModalParams
          })
        }
        usePaginatedDataOptions={{
          apiUrl: paymentProcessingApiUrls.batchProcessingsSettings.list,
          initialFilterParams: {
            $orderBy: 'createdDate desc',
            $filter: {
              ...initialFileFilterParams
            }
          }
        }}
        rowKey="batchProcessingId"
        detailPanelHeight={500}
        customApi={PaymentProcessingApi}
      />
      <Dialog
        open={Boolean(fileIdToDelete)}
        onClose={() => setFileIdToDelete(null)}
        onConfirm={handleDeleteFile}
        title="Are you sure you want to delete file and all the payments?"
      />
      <EditPlannedExecutionTimeModal
        isOpen={Boolean(fileIdToChange)}
        handleClose={() => setFileIdToChange(null)}
        handleSubmit={handleEditPlannedDate}
        modalParams={{ dateToEdit, fileNameToEdit }}
      />
      <UploadCSVModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        refetchList={tableRef.current?.fetchData}
        requiredCSVFields={requiredCSVFields}
        sampleCSVFileFormat={sampleCSVFileFormat}
      />
    </MainLayout>
  );
};

export default FileList;
