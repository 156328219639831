import { useEffect } from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';

import { leadDayHourFilterInitialValues } from '@components/Leads/DayHourFilterSeparated';
import { useQueryParams } from '@hooks/useQueryParams';
import { useServerData } from '@hooks/useServerData';
import LeadManagementApi from '@utils/leadManagementApi';

export const useLeadStatsData = (listUrl) => {
  const history = useHistory();
  const params = useQueryParams();

  const {
    data: leadProviderData,
    fetchData: fetchLeadProviderData,
    isLoading: isLeadProviderDataLoading
  } = useServerData({
    endpoint: async () =>
      LeadManagementApi.get(listUrl, {
        params: {
          date: params.date || leadDayHourFilterInitialValues.date,
          specifiedTime:
            params.specifiedTime || leadDayHourFilterInitialValues.specifiedTime
        }
      })
  });

  useEffect(() => {
    if (params.date) {
      fetchLeadProviderData();
    } else {
      history.push({
        search: qs.stringify(leadDayHourFilterInitialValues)
      });
    }
  }, [params]);

  return {
    leadProviderData,
    isLeadProviderDataLoading,
    refetchLeadProviderData: fetchLeadProviderData
  };
};
