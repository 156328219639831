import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';
import { useSelector } from 'react-redux';

import Alert from '@components/shared/Alert/Alert';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Logger from '@utils/logger';
import ApplicationErrorsContent from '@components/Dashboard/ApplicationErrors/Content';
import DashboardFilter from '@components/Dashboard/Filter';
import { selectDashboardFilters } from '@store/dashboardFilters';
import Loader from '@components/shared/Loader';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const DashboardApplicationErrorsPage = () => {
  const filtersInitialState = useSelector(selectDashboardFilters);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        if (!history.location?.search) {
          history.replace({
            search: qs.stringify(filtersInitialState)
          });
        } else {
          setData(null);
          const parsedParams = qs.parse(history.location.search, {
            parseBooleans: true,
            parseNumbers: true
          });
          const response = await AdminApi.get(
            adminApiUrls.dashboard.telemetry,
            {
              params: parsedParams
            }
          );
          setData(response);
        }
      } catch (e) {
        Logger.error(e);
        setError(e);
      }
    })();
  }, [history.location?.search]);

  return (
    <MainLayout title="Application fields error rate">
      <DashboardFilter />
      {!data && !error && <Loader />}
      {data && <ApplicationErrorsContent data={data} />}
      {error && (
        <Alert
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
        />
      )}
    </MainLayout>
  );
};

export default DashboardApplicationErrorsPage;
