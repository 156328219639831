import * as Yup from 'yup';

export const leadInfoFieldsSchema = Yup.object().shape({
  name: Yup.string().label('Name').required(),
  address1: Yup.string().min(5).max(150).label('Address 1').nullable(),
  address2: Yup.string()
    .min(5)
    .max(150)
    .nullable()
    .label('Address 2')
    .nullable(),
  city: Yup.string().min(3).max(50).label('City').nullable(),
  zip: Yup.string()
    .matches(/^[0-9]*$/, {
      message: 'Only digits are allowed'
    })
    .length(5)
    .label('Zip')
    .nullable(),
  phone: Yup.string().label('Phone number').min(10).nullable(),
  dailyLookCap: Yup.number().label('Day Look cap').required(),
  hourlyLookCap: Yup.number().label('Hr look cap').required(),
  dailyAcceptCap: Yup.number().label('Day accept cap').required(),
  hourlyAcceptCap: Yup.number().label('Hr accept cap').required(),
  dailyCostCap: Yup.number().label('Day cost cap').required(),
  stateId: Yup.number().label('State').nullable(),
  maxTimeRespond: Yup.string().label('Max time to respond (Sec)').required(),
  campaignGroupId: Yup.number().nullable()
});
