import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import Application from '@components/Application';
import LoanSteps from '@components/Application/LoanSteps';
import Grid from '@components/shared/Grid/Grid';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import { loanFormStepsOrder } from '@constants/loanFormConfig';
import Logger from '@utils/logger';
import Button from '@components/shared/Button/Button';
import routes from '@constants/routes';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const EditApplicationPage = () => {
  const { id: applicationID } = useParams();
  const [lastFilledStep, setLastFilledStep] = useState(null);
  const [activeStep, setActiveStep] = useState(lastFilledStep);
  const [applicationData, setApplicationData] = useState(null);

  useEffect(() => {
    if (applicationID) {
      (async () => {
        try {
          const data = await AdminApi.get(
            adminApiUrls.applications.status(applicationID)
          );

          setApplicationData({ applicationId: data.applicationId });

          const lastFilledStepIndex = loanFormStepsOrder.indexOf(
            data.lastFilledFrom
          );
          const lastConfigStepIndex = loanFormStepsOrder.length - 1;

          const step =
            lastFilledStepIndex === lastConfigStepIndex
              ? loanFormStepsOrder[lastConfigStepIndex]
              : data.lastFilledFrom;

          setLastFilledStep(step);
          setActiveStep(step);
        } catch (e) {
          Logger.error(e);
        }
      })();
    }
  }, [applicationID]);

  return (
    <MainLayout title="Edit application">
      <Grid container>
        <Grid item xs={12} md={4} lg={3}>
          <LoanSteps
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            lastFilledStep={lastFilledStep}
          />
          {applicationData?.applicationId && (
            <Button
              component={Link}
              to={routes.applications.page.view.url(
                applicationData?.applicationId
              )}
            >
              View application
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Application
            activeStep={activeStep}
            lastFilledStep={lastFilledStep}
            applicationData={applicationData}
            setActiveStep={setActiveStep}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default EditApplicationPage;
