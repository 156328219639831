import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useHistory } from 'react-router';

import {
  getPaymentColumns,
  initialPaymentFilterParams,
  subTabsList
} from '@components/PastDuePayments/config';
import PastDueFilterForm from '@components/PastDuePayments/PastDueFilterForm';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import Button from '@components/shared/Button/Button';
import { ActionsSection } from '@components/shared/MainLayout/MainLayout.components';
import { FileUploadIcon } from '@components/shared/icons/Icons';
import { paymentProcessingApiUrls } from '@constants/paymentProcessingApiUrls';
import PaymentProcessingApi from '@utils/paymentProcessingApi';
import UploadCSVModal from '@components/shared/UploadCSVModal/UploadCSVModal';
import {
  requiredCSVFields,
  sampleCSVFileFormat
} from '@components/PastDuePayments/UploadCSV/UploadCSVForm.config';

const title = 'Past Due Payments';

const PaymentList = () => {
  const tableRef = useRef();
  const [instanceUid, setInstanceUid] = useState('');
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signal, setSignal] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.instanceUid) {
      setInstanceUid(location.state.instanceUid);
    }
  }, [location]);

  useEffect(() => {
    const controller = new AbortController();
    setSignal(controller.signal);
    if (instanceUid) {
      history.push({
        pathname: history.location.pathname,
        search: qs.stringify({
          ...qs.parse(history.location.search),
          $filter: JSON.stringify({ instanceUid: { eq: instanceUid } }),
          $skip: 0
        })
      });
    }
    return () => {
      controller.abort();
    };
  }, [instanceUid]);

  return (
    <MainLayout title={title}>
      <NavTabsPanel
        currentIdx={1}
        tabsList={subTabsList}
        ariaLabel="access-tabs"
      />
      <ActionsSection>
        <PastDueFilterForm datePickerLabel="Planned between" />
        <div>
          <Button
            onClick={() => setIsModalOpen(true)}
            startIcon={<FileUploadIcon />}
            variant="outlined"
          >
            Upload CSV File
          </Button>
        </div>
      </ActionsSection>
      <ODataPaginatedTable
        signal={signal}
        tableRef={tableRef}
        getColumns={getPaymentColumns}
        usePaginatedDataOptions={{
          apiUrl: paymentProcessingApiUrls.unprocessedPayments.list,
          initialFilterParams: {
            $expand: 'batchProcessing',
            $orderBy: 'createdDate desc',
            $filter: {
              ...initialPaymentFilterParams
            }
          }
        }}
        rowKey="uprocessedPaymentId"
        detailPanelHeight={500}
        customApi={PaymentProcessingApi}
      />
      <UploadCSVModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        refetchList={tableRef.current?.fetchData}
        requiredCSVFields={requiredCSVFields}
        sampleCSVFileFormat={sampleCSVFileFormat}
      />
    </MainLayout>
  );
};

export default PaymentList;
