import React, { useCallback } from 'react';
import PT from 'prop-types';

import helpers from '@utils/helpers';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';
import { filterOperators } from '@constants/common';

const NotificationsHistory = ({
  initialExtraParams,
  startAdditionalColumns,
  endAdditionalColumns,
  ...props
}) => {
  const getHistoryColumns = useCallback(() => {
    const defaultColumns = [
      ...startAdditionalColumns,
      {
        key: 0,
        headerName: 'Date',
        field: 'createdDate',
        minWidth: 180,
        flex: 1,
        valueFormatter: (createdDate) => helpers.getFormattedDate(createdDate),
        renderCell: ({ row }) => helpers.getFormattedDate(row?.createdDate),
        type: muiDataTableTypes.dateRange,
        filterOperators: [filterOperators.dateRange]
      },
      {
        key: 1,
        headerName: 'Number of total sending attempts',
        field: 'sendingAttempts',
        minWidth: 200,
        sortable: false,
        filterable: false,
        flex: 1
      },
      {
        key: 2,
        headerName: 'Number of successful sending attempts',
        field: 'successfulSending',
        sortable: false,
        filterable: false,
        minWidth: 200,
        flex: 1
      },
      {
        key: 3,
        headerName: 'Number of views',
        field: 'readAmount',
        minWidth: 200,
        flex: 1,
        type: muiDataTableTypes.number,
        renderCell: ({ row }) => row?.readAmount
      },
      ...endAdditionalColumns
    ];

    return defaultColumns;
  }, [startAdditionalColumns, endAdditionalColumns]);

  return (
    <ODataPaginatedTable
      getColumns={getHistoryColumns}
      usePaginatedDataOptions={{
        apiUrl: adminApiUrls.notificationJobHistories.list,
        initialFilterParams: initialExtraParams
      }}
      rowKey="notificationJobHistoryId"
      customApi={AdminApi}
      {...props}
    />
  );
};

NotificationsHistory.defaultProps = {
  initialExtraParams: {},
  startAdditionalColumns: [],
  endAdditionalColumns: []
};

NotificationsHistory.propTypes = {
  initialExtraParams: PT.object,
  startAdditionalColumns: PT.array,
  endAdditionalColumns: PT.array
};

export default NotificationsHistory;
