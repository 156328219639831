import React from 'react';
import PT from 'prop-types';

import FormikField from '@components/shared/Form/FormikField/FormikField';

const LoanAmountBlock = ({ name }) => (
  <FormikField
    type="text"
    name={name}
    label="Loan Amount:"
    labelAlign="left"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    placeholder="in $"
  />
);

LoanAmountBlock.propTypes = {
  name: PT.string.isRequired
};

export default LoanAmountBlock;
