import React from 'react';
import PropTypes from 'prop-types';

import { StyledHeader, StyledHeading } from './styled';

import EloanLogo from '@assets/images/logo.svg';

export const Header = ({ title }) => (
  <StyledHeader>
    <EloanLogo />
    <StyledHeading>{title}</StyledHeading>
  </StyledHeader>
);

Header.propTypes = { title: PropTypes.string.isRequired };
