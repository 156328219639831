import { useEffect, useState } from 'react';
import contentDisposition from 'content-disposition';
import qs from 'query-string';

import { timeDateColumnWidth } from '@components/shared/DataTable/DataTable.constants';
import { csvComparisonApiUrls } from '@constants/csvComparisonApi';
import CsvComparisonApi from '@utils/csvComparisonApi';
import helpers from '@utils/helpers';
import Logger from '@utils/logger';
import toastify from '@utils/toastify';
import { comparisonMap } from '@constants/comparison';
import routes from '@constants/routes';

const { mismatches } = comparisonMap;

export const getDynamicColumns = (records) =>
  (
    records &&
    Object.keys(records).map((key) => {
      if (key === 'id' || key === 'row_id') {
        return null;
      }
      if (key === 'Date') {
        return {
          headerName: 'Date',
          field: 'Date',
          minWidth: timeDateColumnWidth,
          renderCell: ({ row }) => helpers.getFormattedDate(row.Date)
        };
      } else {
        return {
          headerName: key,
          field: key
        };
      }
    })
  )?.filter((item) => item) || [];

export const downloadFile = async (apiUrl, exportFileName) => {
  try {
    const data = await CsvComparisonApi.get(apiUrl);

    const {
      parameters: { filename }
    } = contentDisposition.parse(data.contentDisposition);

    const blob = new Blob([data.content], { type: 'octet/stream' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');

    link.href = url;
    link.download = filename || `${exportFileName || document.title}.csv`;
    link.click();
    window.URL.revokeObjectURL(url);
  } catch (e) {
    Logger.error(e);
    toastify.error(
      e?.response?.error || { message: 'An error occurred downloading a file' }
    );
  }
};

const setIdForRecords = (records) =>
  records.map((item, i) => ({
    ...item,
    row_id: `${i}_${new Date().getMilliseconds()}`
  }));

const dataKeysArray = ['resultFileA', 'resultFileB', 'consolidatedResultFile'];

export const useGetCsvComparisonsResult = (id, index = 0) => {
  const [data, setData] = useState();
  const [navItems, setTabNames] = useState([]);

  const currentKey = dataKeysArray[index];

  useEffect(() => {
    (async () => {
      try {
        const res = await CsvComparisonApi.get(
          csvComparisonApiUrls.comparisons.results(id)
        );

        setData(
          Object.entries(res).reduce((acc, [key, item]) => {
            let value = item;

            if (value?.records) {
              value = { ...item, records: setIdForRecords(item.records) };
            }
            return {
              ...acc,
              [key]: value
            };
          }, {})
        );

        const resultFileA = res.resultFileA;
        const resultFileB = res.resultFileB;

        setTabNames([
          {
            id: 0,
            title: `Missing from ${resultFileA?.comparedToFileName}`,
            link: qs.stringifyUrl({
              url: routes.csvCompare.details.url(
                id,
                resultFileA?.comparedToConfigName
              ),
              query: {
                index: 0
              }
            })
          },
          {
            id: 1,
            title: `Missing from ${resultFileB.comparedToFileName}`,
            link: qs.stringifyUrl({
              url: routes.csvCompare.details.url(
                id,
                resultFileB?.comparedToConfigName
              ),
              query: {
                index: 1
              }
            })
          },
          {
            id: 2,
            title: mismatches,
            link: qs.stringifyUrl({
              url: routes.csvCompare.details.url(id, mismatches),
              query: {
                index: 2
              }
            })
          }
        ]);
      } catch (e) {
        Logger.error(e);
        toastify.error(e);
      }
    })();
  }, [id]);

  return {
    comparisonId: data?.comparisonId,
    data: data?.[currentKey],
    navItems
  };
};
