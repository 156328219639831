import * as Yup from 'yup';

export const flatPageSchema = Yup.object().shape({
  key: Yup.string().label('Slug').required(),
  value: Yup.string().label('Body').required(),
  title: Yup.string().label('Title').required(),
  publish: Yup.bool().label('Status').required()
});

export const getInitialValues = (data) => ({
  key: '',
  value: '',
  title: '',
  publish: false,
  ...data
});
