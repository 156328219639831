import PT from 'prop-types';
import React, { useEffect } from 'react';

import { useServerData } from '@hooks/useServerData';
import Loader from '@components/shared/Loader';
import Typography from '@components/shared/Typography/Typography';
import JsonViewer from '@components/shared/JsonViewer/JsonViewer';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const ExpandedLoggedEvent = ({ row }) => {
  const {
    data: loggedEvent,
    fetchData: fetchLoggedEvent,
    isLoading,
    error
  } = useServerData({
    endpoint: async () => {
      const url = adminApiUrls.loggedEventWithFormData(row.loggedEventId);
      const loggedEvent = await AdminApi.get(url);
      return [row, ...loggedEvent.value];
    }
  });

  useEffect(() => {
    fetchLoggedEvent();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Typography variant="h5" color="error">
        Unable to fetch logged event details
      </Typography>
    );
  }

  return <JsonViewer src={loggedEvent} />;
};

ExpandedLoggedEvent.propTypes = {
  row: PT.object.isRequired
};

export default ExpandedLoggedEvent;
