import React, { useEffect, useState } from 'react';
import PT from 'prop-types';

import { workingCalendarServerData } from './WorkingCalendarStep/schema';

import Loader from '@components/shared/Loader';
import {
  leadFormStepsComponents,
  leadFormStepsMap,
  leadFormStepsOrder
} from '@constants/leadFormConfig';
import routes from '@constants/routes';
import history from '@utils/history';
import toastify from '@utils/toastify';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

const LeadProviderForm = ({
  id,
  activeStep,
  leadData,
  leadScheduleData,
  setLastLeadFilledStep,
  setLeadData,
  setLeadScheduleData,
  setActiveStep
}) => {
  const [infoPageData, setInfoPageData] = useState({});
  const [workingCalendarData, setWorkingCalendarData] = useState(null);

  useEffect(() => {
    setInfoPageData(leadData);
  }, [leadData]);

  useEffect(() => {
    setWorkingCalendarData(leadScheduleData);
  }, [leadScheduleData]);

  if (!activeStep) {
    return <Loader />;
  }

  const redirectAfterSubmit = (leadProviderId) => {
    if (leadProviderId) {
      return routes.leads.leadProviders.page.view.url(leadProviderId);
    }
    return routes.leads.leadProviders.list;
  };

  const currentStepIdx = leadFormStepsOrder.indexOf(activeStep);

  const nextStep = () => {
    setActiveStep(leadFormStepsOrder[currentStepIdx + 1]);
  };

  const handleSubmit = ({ values }, { onSuccess, onError, onFinish }) => {
    switch (activeStep) {
      case leadFormStepsMap.infoPage: {
        setLeadData(values);
        setLastLeadFilledStep(activeStep);
        nextStep();
        break;
      }
      case leadFormStepsMap.workingCalendar: {
        (async () => {
          try {
            if (!id) {
              const responseSchedule = await LeadManagementApi.post(
                leadManagementApiUrls.weeklySchedules.list,
                {
                  workDays: workingCalendarServerData.to(values.workDays, true)
                }
              );

              const response = await LeadManagementApi.post(
                leadManagementApiUrls.leadProviders.list,
                {
                  ...leadData,
                  weeklyScheduleId: responseSchedule.weeklyScheduleId
                }
              );

              history.push(redirectAfterSubmit(response?.leadProviderId));

              toastify.success({
                message: 'Create operation success'
              });
            } else {
              await LeadManagementApi.patch(
                leadManagementApiUrls.leadProviders.page(id),
                {
                  ...leadData
                }
              );

              leadData.weeklyScheduleId &&
                (await LeadManagementApi.put(
                  leadManagementApiUrls.scheduleManagement(
                    leadData.weeklyScheduleId
                  ),
                  {
                    weeklyScheduleId: leadData.weeklyScheduleId,
                    workDays: workingCalendarServerData.to(values.workDays)
                  }
                ));

              setLeadScheduleData(values);

              toastify.success({
                message: 'Update operation success'
              });

              onSuccess?.();
              history.push(redirectAfterSubmit(id));
            }
          } catch (e) {
            onError && onError(e);
            toastify.error(
              e?.response?.data?.error
                ? e.response.data.error
                : {
                    message: 'Update failure'
                  }
            );
          } finally {
            onFinish?.();
          }
        })();
        break;
      }
    }
  };

  const StepComponent = leadFormStepsComponents[activeStep];

  return (
    <StepComponent
      onSubmit={handleSubmit}
      infoPageData={infoPageData}
      workingCalendarData={workingCalendarData}
    />
  );
};

LeadProviderForm.propTypes = {
  id: PT.string.isRequired,
  activeStep: PT.string,
  leadData: PT.object,
  leadScheduleData: PT.object,
  setLastLeadFilledStep: PT.func,
  setLeadData: PT.func,
  setLeadScheduleData: PT.func,
  setActiveStep: PT.func
};

export default React.memo(LeadProviderForm);
