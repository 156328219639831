import React from 'react';
import PT from 'prop-types';

import Typography from '@components/shared/Typography/Typography';
import { csvComparisonApiUrls } from '@constants/csvComparisonApi';
import { downloadFile } from '@pages/csv-comparison/utils';

const FileName = ({ text, id }) => {
  const onClick = () => {
    if (id) {
      downloadFile(csvComparisonApiUrls.comparisonfiles.details(id));
    }
  };
  return (
    <Typography
      onClick={onClick}
      color="primary"
      sx={{
        cursor: 'pointer'
      }}
    >
      {text}
    </Typography>
  );
};

FileName.propTypes = {
  text: PT.string,
  id: PT.oneOfType([PT.string, PT.number])
};

export default FileName;
