import React, { useCallback, useEffect, useState } from 'react';
import PT from 'prop-types';
import { useHistory } from 'react-router';

import { getColumns, initialFilterParams } from './config';

import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import routes from '@constants/routes';
import Loader from '@components/shared/Loader';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';
import Logger from '@utils/logger';

const InternetBankVerification = ({ id }) => {
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [IBVScoringReportStatusOptions, setIBVScoringReportStatusOptions] =
    useState([]);

  useEffect(() => {
    history.push({
      pathname: routes.users.page.view.url(id),
      search: ''
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const statuses = await AdminApi.get(
          adminApiUrls.common.IBVScoringStatuses
        );

        setIBVScoringReportStatusOptions(
          statuses?.items?.map((item) => ({
            value: item.ibvStatusName,
            label: item.ibvStatusName
          }))
        );
      } catch (error) {
        Logger.error(error);
      }
    })();
  }, []);

  const getIBVScoringReportColumns = useCallback(
    () => getColumns({ IBVScoringReportStatusOptions }),
    [IBVScoringReportStatusOptions]
  );

  useEffect(() => {
    if (!history.location.search) {
      setLoader(false);
    }
  }, [history.location?.search]);

  if (loader) return <Loader />;

  return (
    <ODataPaginatedTable
      getColumns={getIBVScoringReportColumns}
      usePaginatedDataOptions={{
        apiUrl: adminApiUrls.dashboard.ibv.scoringReport,
        initialFilterParams: {
          $orderBy: 'createdDate desc',
          $filter: {
            ...initialFilterParams(id)
          }
        }
      }}
      exportFileName={`Instant Bank Verification by user ${id}`}
      rowKey="ibvUnderwritingId"
      detailPanelHeight="auto"
      customApi={AdminApi}
    />
  );
};

InternetBankVerification.propTypes = {
  id: PT.string.isRequired
};

export default InternetBankVerification;
