import dayjs from 'dayjs';

import { showAllValue } from '@components/LeadReports/filters/config';

const getDefaultFilter = () => ({
  startDate: dayjs().subtract(1, 'day').toDate(),
  endDate: dayjs().toDate(),
  leadCampaign: showAllValue
});

export const getInitialValues = (periodFilter) => {
  if (!periodFilter) {
    return getDefaultFilter();
  }
  const parsedFilters = JSON.parse(periodFilter);
  if (!parsedFilters.createdDate) {
    return getDefaultFilter();
  }
  return {
    startDate: dayjs(parsedFilters.createdDate.ge).toDate(),
    endDate: dayjs(parsedFilters.createdDate.le).toDate(),
    leadCampaign: parsedFilters.leadCampaign?.eq || showAllValue
  };
};
