import React from 'react';
import PT from 'prop-types';

import './ErrorsTable.scss';
import { columns } from './config';

import DataTable from '@components/shared/DataTable/DataTable';
import Box from '@components/shared/Box/Box';

const ErrorsTable = ({ logs }) => (
  <Box p={1}>
    <DataTable
      disableColumnFilter
      columns={columns}
      tableData={logs}
      rowKey="id"
      hideFooter
      pagination={false}
    />
  </Box>
);

ErrorsTable.propTypes = {
  logs: PT.array.isRequired
};

export default ErrorsTable;
