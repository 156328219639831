import React, { useState, useMemo } from 'react';
import PT from 'prop-types';
import { useHistory } from 'react-router';
import qs from 'query-string';
import { Formik, Form } from 'formik';
import isEmpty from 'lodash/isEmpty';

import {
  filterFieldsConfig,
  filterFieldsOptions
} from '@components/Users/AdditionalFilter/config';
import FilterSettingsModal from '@components/shared/FilterSettingsModal';
import { useQueryParams } from '@hooks/useQueryParams';
import { cleanObject } from '@utils/object-cleaner';
import Button from '@components/shared/Button/Button';
import filterHelpers from '@utils/filterHelpers';
import Box from '@components/shared/Box/Box';

const AdditionalUserFilter = ({ stopFetching, setStopFetching }) => {
  const [showFiltersSettingsModal, setShowFiltersSettingsModal] =
    useState(false);
  const history = useHistory();
  const { $filter, filterFields } = useQueryParams({ arrayFormat: 'bracket' });

  const parsedFilterFields = filterFields || [];

  let initialValues = useMemo(
    () =>
      filterFields?.reduce(
        (acc, key) => ({
          ...acc,
          ...filterFieldsConfig[key].initialValue
        }),
        {}
      ),
    [filterFields]
  );

  const updFilterData = useMemo(
    () => filterHelpers.updFilterData($filter, initialValues),
    [history.location.search]
  );

  if ($filter) {
    initialValues = updFilterData || {};
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    handleFilterUpdate(values);
    setSubmitting(false);
  };

  const handleFilterUpdate = (values) => {
    const clearedValues = cleanObject(values) || {};

    stopFetching && setStopFetching(false);

    history.push({
      pathname: history.location.pathname,
      search: qs.stringify({
        ...qs.parse(history.location.search),
        $filter: JSON.stringify(clearedValues),
        $skip: 0
      })
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting }) => (
        <>
          <Box width="100%">
            <Button onClick={() => setShowFiltersSettingsModal(true)}>
              Additional filter settings
            </Button>
          </Box>
          <Box
            sx={filterHelpers.getFlexForField(parsedFilterFields?.length && 20)}
            mb={parsedFilterFields ? 1 : 'auto'}
          >
            <Form className="page-filter">
              <Box sx={filterHelpers.getFlexForField(10)}>
                {parsedFilterFields.map((key) => (
                  <React.Fragment key={key}>
                    {filterFieldsConfig[key].renderFilterField()}
                  </React.Fragment>
                ))}
                {!!parsedFilterFields?.length && (
                  <>
                    <Button
                      disabled={isEmpty(cleanObject(values))}
                      loading={isSubmitting}
                      type="submit"
                    >
                      Apply
                    </Button>
                  </>
                )}
              </Box>
              <FilterSettingsModal
                show={showFiltersSettingsModal}
                onClose={() => setShowFiltersSettingsModal(false)}
                filterFields={parsedFilterFields}
                filterFieldsOptions={filterFieldsOptions}
                stopFetching={stopFetching}
                setStopFetching={setStopFetching}
              />
            </Form>
          </Box>
        </>
      )}
    </Formik>
  );
};

AdditionalUserFilter.propTypes = {
  stopFetching: PT.bool.isRequired,
  setStopFetching: PT.func.isRequired
};

export default AdditionalUserFilter;
