import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';
import routes from '@constants/routes';
import CreditCard from '@components/CreditCard';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Box from '@components/shared/Box/Box';

const title = 'Add debit card information';

const DebitCardPage = () => {
  const history = useHistory();

  useEffect(() => {
    if (!helpers.getActionPermission(permissionsMap.debitCardManage)) {
      history.push(routes.permissionDenied);
    }
  }, []);

  return (
    <MainLayout title={title}>
      <Box mt={2}>
        <Box width="50%">
          <CreditCard />
        </Box>
      </Box>
    </MainLayout>
  );
};

export default DebitCardPage;
