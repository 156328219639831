import React, { useEffect, useState } from 'react';
import omit from 'lodash/omit';

import {
  tabsIds,
  tabsList
} from '@components/ScheduledPayments/ScheduledPayments.config';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import { weekDays } from '@components/settings/DaysOfWeekList';
import Button from '@components/shared/Button/Button';
import SettingHeaderSection from '@components/settings/SettingHeaderSection';
import { useServerData } from '@hooks/useServerData';
import Alert from '@components/shared/Alert/Alert';
import Loader from '@components/shared/Loader';
import toastify from '@utils/toastify';
import Logger from '@utils/logger';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';
import PaymentProcessingApi from '@utils/paymentProcessingApi';
import { paymentProcessingApiUrls } from '@constants/paymentProcessingApiUrls';
import ScheduledPaymentsSettingsList from '@components/settings/ScheduledPaymentsSettingsList';
import StopPropagationWrapper from '@components/shared/StopPropagationWrapper/StopPropagationWrapper';
import Typography from '@components/shared/Typography/Typography';

const timeSlots = new Array(7).fill(0);

const ScheduledPaymentsSettingsPage = () => {
  const [timeslotsData, setTimeslotsData] = useState(null);
  const hasPermissionsToEdit = helpers.getActionPermission(
    permissionsMap.scheduledPaymentsManage
  );

  const { isLoading, error, fetchData } = useServerData({
    endpoint: async () => {
      const response = await PaymentProcessingApi.get(
        paymentProcessingApiUrls.scheduledPayments.settings.list
      );
      return setTimeslotsData(response.items);
    }
  });

  useEffect(() => {
    fetchData();
  }, []);

  const updateRetriesData = async () => {
    try {
      const payload = timeslotsData.map((item) => omit(item, ['isActive']));
      await PaymentProcessingApi.patch(
        paymentProcessingApiUrls.scheduledPayments.settings.list,
        payload
      );

      toastify.success({ message: 'Settings was updated successfully' });
    } catch (err) {
      Logger.error(err);
      toastify.error(err || { message: 'Something went wrong' });
    }
  };

  const setChanges = (id, data) => {
    const newData = timeslotsData.map((item) => {
      if (item.scheduledPaymentScheduleId === id) {
        return {
          ...item,
          ...data
        };
      }
      return item;
    });
    setTimeslotsData(newData);
  };

  const accordionList = timeSlots.map((_, idx) => ({
    key: idx,
    header: (
      <SettingHeaderSection
        settingId={timeslotsData?.[idx]?.scheduledPaymentScheduleId}
        timeSlot={timeslotsData?.[idx]?.startTime}
        isActive={timeslotsData?.[idx]?.isActive}
        isReadOnly={!hasPermissionsToEdit}
        setData={(data) => {
          setChanges(timeslotsData?.[idx]?.scheduledPaymentScheduleId, {
            startTime: data
          });
        }}
        day={weekDays[idx]}
        renderDayName={
          <StopPropagationWrapper style={{ width: '20%' }}>
            <Typography variant="body1">{weekDays[idx]}</Typography>
          </StopPropagationWrapper>
        }
        endpoint={paymentProcessingApiUrls.scheduledPayments.settings.item(
          timeslotsData?.[idx]?.scheduledPaymentScheduleId
        )}
      />
    )
  }));

  return (
    <MainLayout title="Settings - Scheduled Payments">
      <NavTabsPanel
        currentId={tabsIds.settings}
        tabsList={tabsList}
        ariaLabel="lead-provider-tabs"
      />
      {error && (
        <Alert
          title="Server Error"
          message="Failed to fetch"
          type="error"
          sx={{ my: 2 }}
        />
      )}
      {isLoading && <Loader />}
      {!error && !isLoading && (
        <>
          <ScheduledPaymentsSettingsList list={accordionList} />
          {hasPermissionsToEdit && (
            <Button onClick={updateRetriesData} sx={{ mt: 2 }}>
              Save changes
            </Button>
          )}
        </>
      )}
    </MainLayout>
  );
};

export default ScheduledPaymentsSettingsPage;
