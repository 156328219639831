import dayjs from 'dayjs';
import * as Yup from 'yup';

import { targetGroups } from './config';

import helpers from '@utils/helpers';
import { dateFormat } from '@constants/common';
import { CSV_INPUT_NAME } from '@constants/notificationFormConfig';

export const customTargetInitialValues = {
  ageMax: '',
  ageMin: '',
  birthDate: '',
  firstName: '',
  lastLogin: '',
  lastName: '',
  osUsed: '',
  paymentDaysBefore: '',
  paymentStatusDaysAfterMax: '',
  paymentStatusDaysAfterMin: '',
  paymentsNumberMade: '',
  percentBalanceRemainingMax: '',
  percentBalanceRemainingMin: '',
  state: '',
  statusLoan: '',
  lastLoginXDays: '',
  lastLoginDate: ''
};

export const bulkCsvNotificationInitialValues = {
  [CSV_INPUT_NAME]: null
};

export const customTargetKeys = Object.keys(customTargetInitialValues);

export const getInitialValues = (data) => ({
  ...customTargetInitialValues,
  ...bulkCsvNotificationInitialValues,
  newComer: false,
  ...data
});

export const notificationFieldsSchema = Yup.object().shape(
  {
    ageMax: Yup.number().when('ageMin', ([value]) =>
      value
        ? Yup.number()
            .moreThan(value - 1, 'Maximum age must be more than minimum')
            .integer()
            .positive()
            .min(18)
            .max(90)
            .label('Maximum age')
            .required()
        : Yup.number().integer().positive().min(18).max(90).label('Maximum age')
    ),
    ageMin: Yup.number().when('ageMax', ([value]) =>
      value
        ? Yup.number()
            .lessThan(value + 1, 'Minimum age must be less than maximum')
            .required('Minimum age is required')
            .integer()
            .positive()
            .min(18)
            .max(90)
            .label('Minimum age')
        : Yup.number().integer().positive().min(18).max(90).label('Minimum age')
    ),
    birthDate: Yup.date()
      .nullable()
      .max(dayjs().subtract(18, 'year').toDate(), 'Min allowed age is 18')
      .min(dayjs().subtract(90, 'year').toDate(), 'Max allowed age is 90')
      .label('Date of birth'),
    firstName: Yup.string().min(1).max(50).label('First name'),
    lastLogin: Yup.date().nullable().label('Users birthday'),
    lastName: Yup.string().min(1).max(100).label('Last name'),
    osUsed: Yup.string().label('OS used'),
    paymentDaysBefore: Yup.number()
      .integer()
      .positive()
      .label('Days before payment'),
    paymentStatusDaysAfterMax: Yup.number().when(
      'paymentStatusDaysAfterMin',
      ([value]) =>
        value
          ? Yup.number()
              .moreThan(value - 1)
              .integer()
              .positive()
              .label('Maximum day')
              .required()
          : Yup.number().integer().positive().label('Maximum day')
    ),
    paymentStatusDaysAfterMin: Yup.number().when(
      'paymentStatusDaysAfterMax',
      ([value]) =>
        value
          ? Yup.number()
              .lessThan(value + 1)
              .integer()
              .positive()
              .label('Minimum day')
              .required()
          : Yup.number().integer().positive().label('Minimum day')
    ),
    paymentsNumberMade: Yup.number()
      .integer()
      .positive()
      .label('Number of payments made'),
    percentBalanceRemainingMax: Yup.number().when(
      'percentBalanceRemainingMin',
      ([value]) =>
        value
          ? Yup.number()
              .moreThan(value - 1)
              .integer()
              .positive()
              .min(1)
              .max(100)
              .label('Maximum percent')
              .required()
          : Yup.number()
              .integer()
              .positive()
              .min(1)
              .max(100)
              .label('Maximum percent')
    ),
    percentBalanceRemainingMin: Yup.number()
      .when('percentBalanceRemainingMax', ([value]) =>
        value
          ? Yup.number()
              .lessThan(value + 1)
              .required()
          : Yup.number()
      )
      .integer()
      .min(1)
      .max(100)
      .label('Maximum percent'),
    state: Yup.string().label('State'),
    statusLoan: Yup.string().label('Status of loan'),
    lastLoginXDays: Yup.number().integer().label('Last login more than X days'),
    lastLoginDate: Yup.date().nullable().label('Last login on X or before'),
    jobType: Yup.number().oneOf[Object.values(targetGroups)],
    csvFile: Yup.mixed()
      .nullable()
      .when('jobType', {
        is: targetGroups.bulkCsvNotification,
        then: Yup.mixed().required('File is required')
      })
  },
  [
    ['ageMin', 'ageMax'],
    ['paymentStatusDaysAfterMin', 'paymentStatusDaysAfterMax'],
    ['percentBalanceRemainingMin', 'percentBalanceRemainingMax']
  ]
);

export const notificationOSOptions = [
  {
    value: '',
    label: 'Other'
  },
  {
    value: 'Android',
    label: 'Android'
  },
  {
    value: 'iPhone',
    label: 'iPhone'
  },
  {
    value: 'iPad',
    label: 'iPad'
  },
  {
    value: 'Windows',
    label: 'Windows'
  },
  {
    value: 'Ubuntu',
    label: 'Ubuntu'
  },
  {
    value: 'Macintosh',
    label: 'Macintosh'
  }
];

export const notificationStatusLoanOptions = [
  {
    value: '0',
    label: 'Undefined'
  },
  {
    value: '1',
    label: 'Pending'
  },
  {
    value: '2',
    label: 'New Loan'
  },
  {
    value: '3',
    label: 'Paid Off'
  },
  {
    value: '4',
    label: 'Canceled'
  }
];

export const targetMap = {
  firstName: 'firstName',
  lastName: 'lastName',
  age: 'age',
  state: 'state',
  osUsed: 'osUsed',
  lastLogin: 'lastLogin',
  statusLoan: 'statusLoan',
  birthDate: 'birthDate',
  paymentDaysBefore: 'paymentDaysBefore',
  paymentStatusDaysAfter: 'paymentStatusDaysAfter',
  paymentsNumberMade: 'paymentsNumberMade',
  percentBalanceRemaining: 'percentBalanceRemaining',
  lastLoginXDays: 'lastLoginXDays',
  lastLoginDate: 'lastLoginDate',
  newComer: 'newComer'
};

export const jobTypes = {
  [targetMap.lastName]: 12,
  [targetMap.paymentDaysBefore]: 13,
  [targetMap.firstName]: 14,
  [targetMap.age]: 15,
  [targetMap.state]: 16,
  [targetMap.osUsed]: 17,
  [targetMap.lastLogin]: 18,
  [targetMap.statusLoan]: 19,
  [targetMap.birthDate]: 20,
  [targetMap.paymentStatusDaysAfter]: 21,
  [targetMap.paymentsNumberMade]: 22,
  [targetMap.percentBalanceRemaining]: 23,
  [targetMap.lastLoginXDays]: 29,
  [targetMap.lastLoginDate]: 30,
  [targetMap.newComer]: 35
};

const rangeValues = [
  targetMap.age,
  targetMap.paymentStatusDaysAfter,
  targetMap.percentBalanceRemaining
];

export const notificationFilterData = {
  parse: (data) => {
    const parsedData = JSON.parse(data);
    const formObj = {};
    const jobTypesToValues = Object.fromEntries(
      Object.entries(jobTypes).map(([key, value]) => [value, key])
    );

    parsedData.JobTypeValues?.forEach((item) => {
      if (rangeValues.includes(jobTypesToValues[item.JobType])) {
        const [min, max] = item.JobTypeValue.split(',');
        formObj[`${jobTypesToValues[item.JobType]}Min`] = min;
        formObj[`${jobTypesToValues[item.JobType]}Max`] = max;
      } else {
        formObj[jobTypesToValues[item.JobType]] = item.JobTypeValue;
      }
    });

    return formObj;
  },
  stringify: (data) => {
    const filter = {
      JobTypeValues: []
    };

    Object.keys(data).forEach((item) => {
      if (jobTypes[item] && data[item] !== '') {
        filter.JobTypeValues.push({
          JobType: jobTypes[item],
          JobTypeValue: data[item]
        });
      }
    });

    rangeValues.forEach((item) => {
      if (data[`${item}Min`] && data[`${item}Max`]) {
        filter.JobTypeValues.push({
          JobType: jobTypes[item],
          JobTypeValue: `${data[`${item}Min`]},${data[`${item}Max`]}`
        });
      }
    });

    return JSON.stringify(filter);
  }
};

const defaultRender = (value) => value;

const rangeRender = (value) => value.replace(',', '-');

const dateRender = (value) => helpers.getFormattedDate(value, dateFormat);

const booleanRender = (value) => value.toString();

export const targetRenderConfig = {
  [jobTypes[targetMap.lastName]]: {
    title: 'Last name',
    render: defaultRender
  },
  [jobTypes[targetMap.paymentDaysBefore]]: {
    title: 'Days before payment',
    render: defaultRender
  },
  [jobTypes[targetMap.firstName]]: {
    title: 'First name',
    render: defaultRender
  },
  [jobTypes[targetMap.age]]: {
    title: 'Age',
    render: rangeRender
  },
  [jobTypes[targetMap.state]]: {
    title: 'State',
    render: defaultRender
  },
  [jobTypes[targetMap.osUsed]]: {
    title: 'OS used',
    render: (value) =>
      notificationOSOptions.find((item) => item.value === value)?.label
  },
  [jobTypes[targetMap.lastLogin]]: {
    title: 'Last login',
    render: dateRender
  },
  [jobTypes[targetMap.statusLoan]]: {
    title: 'Status of loan',
    render: (value) =>
      notificationStatusLoanOptions.find((item) => item.value === value).label
  },
  [jobTypes[targetMap.birthDate]]: {
    title: 'Users birthday',
    render: dateRender
  },
  [jobTypes[targetMap.paymentStatusDaysAfter]]: {
    title: 'Days after payment status',
    render: rangeRender
  },
  [jobTypes[targetMap.paymentsNumberMade]]: {
    title: 'Number of payments made',
    render: defaultRender
  },
  [jobTypes[targetMap.percentBalanceRemaining]]: {
    title: '% of balance remaining',
    render: (value) => `${rangeRender(value)}%`
  },
  [jobTypes[targetMap.lastLoginXDays]]: {
    title: 'Last login more than X days or equal',
    render: defaultRender
  },
  [jobTypes[targetMap.lastLoginDate]]: {
    title: 'Last login on X or before',
    render: dateRender
  },
  [jobTypes[targetMap.newComer]]: {
    title: 'Users Without an Account',
    render: booleanRender
  }
};
