import helpers from '@utils/helpers';

export const columns = [
  {
    headerName: 'Field',
    field: 'field'
  },
  {
    headerName: 'Error',
    field: 'error'
  },
  {
    headerName: 'Details',
    field: 'details'
  },
  {
    headerName: 'Timestamp',
    field: 'pulse',
    renderCell: ({ row }) => helpers.getFormattedDate(row?.createdDate)
  }
];
