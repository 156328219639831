import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().min(2).max(256).label('Name').required(),
  fileIdentifier: Yup.string()
    .min(2)
    .max(256)
    .label('File Identifier')
    .required(),
  columns: Yup.array()
    .label('Columns')
    .of(
      Yup.object().shape({
        name: Yup.string()
          .label('Data Points')
          .matches(/^(?!\s+$)/, 'Data Points cannot contain only blankspaces')
          .required(),
        value: Yup.string().label('Data Point value').required()
      })
    )
    .min(2)
    .max(10)
    .required()
});
