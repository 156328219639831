import React from 'react';
import { Link } from 'react-router-dom';

import { cleanObject } from '@utils/object-cleaner';
import {
  filterOperators,
  loanTypeFilterMap,
  permissionsMap
} from '@constants/common';
import helpers from '@utils/helpers';
import { CheckCircleIcon } from '@components/shared/icons/Icons';
import routes from '@constants/routes';
import { muiDataTableTypes } from '@constants/tableFilterConfig';

export const initialFilterParams = cleanObject({
  loanId: {
    eq: ''
  },
  applicationId: {
    contains: ''
  },
  loanNumber: {
    contains: ''
  },
  requestedOn: {
    ge: '',
    le: ''
  },
  loanType: {
    eq: ''
  }
});

export const getColumns = () => [
  {
    headerName: 'Loan Id',
    field: 'loanId',
    minWidth: 100,
    flex: 1,
    renderCell: ({ row }) => row.loanId,
    type: muiDataTableTypes.number,
    align: 'center',
    headerAlign: 'center'
  },
  {
    headerName: 'Signed',
    field: 'status',
    minWidth: 80,
    flex: 1,
    type: muiDataTableTypes.singleSelect,
    valueOptions: [
      { label: 'Signed', value: 'S' },
      { label: 'Unsigned', value: 'P' }
    ],
    renderCell: ({ row }) => (row?.status === 'S' ? <CheckCircleIcon /> : ''),
    align: 'center',
    headerAlign: 'center'
  },
  {
    headerName: 'Requested On',
    field: 'requestedOn',
    minWidth: 210,
    flex: 1,
    valueFormatter: (requestedOn) => helpers.getFormattedDate(requestedOn),
    renderCell: ({ row }) => helpers.getFormattedDate(row?.requestedOn),
    align: 'center',
    headerAlign: 'center',
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Application Id',
    exportField: 'customer.applicationId',
    field: 'customer/applicationId',
    minWidth: 100,
    flex: 1,
    type: muiDataTableTypes.number,
    renderCell: ({ row }) =>
      helpers.getActionPermission(permissionsMap.applicationView) &&
      row.applicationId ? (
        <Link to={routes.applications.page.view.url(row.applicationId)}>
          {row.applicationId}
        </Link>
      ) : (
        row.applicationId || '-'
      ),
    align: 'center',
    headerAlign: 'center'
  },
  {
    headerName: 'Loan Number',
    field: 'loanNumber',
    minWidth: 120,
    flex: 1,
    align: 'center',
    headerAlign: 'center'
  },
  {
    headerName: 'Loan Type',
    field: 'loanType',
    minWidth: 120,
    flex: 1,
    valueOptions: loanTypeFilterMap,
    type: muiDataTableTypes.singleSelect,
    renderCell: ({ row }) => helpers.getLoanType(row.loanType),
    align: 'center',
    headerAlign: 'center'
  }
];
