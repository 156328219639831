import React, { memo, useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'query-string';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { dashboardFilterFieldsSchema } from './schema';

import { useQueryParams } from '@hooks/useQueryParams';
import {
  setFilters,
  resetFilters,
  dashboardFiltersInitialValues,
  selectDashboardFilters
} from '@store/dashboardFilters';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import { dateFormat, shortTimeFormat } from '@constants/common';
import helpers from '@utils/helpers';

const DashboardFilter = ({
  isUTMFieldShow,
  setUTMValue,
  setIsFilterChange,
  shouldDisableDate,
  initialFilterValues
}) => {
  const history = useHistory();
  const params = useQueryParams();
  const dispatch = useDispatch();
  const baseInitialValues = useSelector(selectDashboardFilters);

  const handleFilterUpdate = useCallback(
    (_values) => {
      const values = {
        ..._values,
        startDate: _values.startDate,
        endDate: _values.endDate
      };
      delete params.startTime;
      delete params.endTime;
      setIsFilterChange?.(Date.now());

      const searchData = params.offset
        ? {
            ...params,
            ...values,
            offset: 0
          }
        : {
            ...params,
            ...values
          };

      history.push({
        search: qs.stringify(searchData, {
          skipEmptyString: true,
          skipNull: true
        })
      });
    },
    [history, params]
  );

  const formatDate = (dateString) => dayjs(dateString).format(dateFormat);

  const handleSubmit = useCallback(
    (values, actions) => {
      const {
        startTime: startTimeFromForm,
        endTime: endTimeTimeFromForm,
        utmValue,
        startDate: startDateStringUnFormatted,
        endDate: endDateStringUnFormatted,
        showStartTime,
        showEndTime
      } = values;
      const startDateStringFormatted = formatDate(startDateStringUnFormatted);
      const endDateStringFormatted = formatDate(endDateStringUnFormatted);

      const startTime = dayjs(
        showStartTime
          ? startTimeFromForm
          : dashboardFiltersInitialValues.startTime
      );
      const endTime = dayjs(
        showEndTime
          ? endTimeTimeFromForm
          : dashboardFiltersInitialValues.endTime
      );

      const { startDate, endDate } = helpers.concatenateDateAndTime(
        startTime,
        endTime,
        startDateStringFormatted,
        endDateStringFormatted
      );

      setUTMValue && setUTMValue(utmValue);

      const payload = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      };

      dispatch(setFilters(payload));
      handleFilterUpdate(payload);

      actions.setSubmitting(false);
    },
    [dispatch, handleFilterUpdate]
  );

  const handleReset = async (resetForm) => {
    resetForm({
      values: dashboardFiltersInitialValues,
      errors: {}
    });
    isUTMFieldShow && delete params.utmValue;

    setUTMValue && setUTMValue('');
    dispatch(resetFilters());
    handleFilterUpdate(dashboardFiltersInitialValues);
  };

  const initialValues = useMemo(() => {
    if (!history.location.search) {
      return {
        ...baseInitialValues,
        startTime: dayjs(baseInitialValues.startTime),
        endTime: dayjs(baseInitialValues.endTime)
      };
    }
    const params = qs.parse(history.location.search);
    const startDate = params.startDate
      ? dayjs(params.startDate)
      : dayjs(baseInitialValues.startDate);
    const endDate = params.endDate
      ? dayjs(params.endDate)
      : dayjs(baseInitialValues.endDate);

    return {
      ...params,
      startDate: startDate.format(dateFormat),
      endDate: endDate.format(dateFormat),
      startTime: startDate,
      endTime: endDate
    };
  }, [baseInitialValues, history.location.search]);

  const fieldWidth = { width: '150px !important' };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        showStartTime: false,
        showEndTime: false,
        ...(initialFilterValues && initialFilterValues)
      }}
      onSubmit={handleSubmit}
      validationSchema={dashboardFilterFieldsSchema}
    >
      {({ dirty, resetForm, values }) => (
        <Form className="page-filter">
          <Stack direction="row" gap={1} alignItems="center">
            <FormikField
              type="date"
              name="startDate"
              label="Start date"
              size="small"
              format={dateFormat}
              sx={fieldWidth}
              shouldDisableDate={shouldDisableDate}
            />
            <FormikField
              type="checkbox"
              name="showStartTime"
              label="Time"
              fullWidth
            />
            {values.showStartTime && (
              <>
                <FormikField
                  type="time"
                  name="startTime"
                  label="Start time"
                  format={shortTimeFormat.front}
                  sx={fieldWidth}
                />
              </>
            )}
            <FormikField
              type="date"
              name="endDate"
              label="End date"
              size="small"
              format={dateFormat}
              sx={fieldWidth}
              shouldDisableDate={shouldDisableDate}
            />
            <FormikField
              type="checkbox"
              name="showEndTime"
              label="Time"
              fullWidth
            />
            {values.showEndTime && (
              <>
                <FormikField
                  type="time"
                  name="endTime"
                  label="End time"
                  format={shortTimeFormat.front}
                  sx={fieldWidth}
                />
              </>
            )}
            {isUTMFieldShow && (
              <FormikField
                type="text"
                name="utmValue"
                label="Enter UTM value"
                size="small"
              />
            )}
            <Button type="submit">Apply</Button>
            <Button
              variant="outlined"
              onClick={() => handleReset(resetForm)}
              disabled={!dirty}
            >
              Reset
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

DashboardFilter.propTypes = {
  isUTMFieldShow: PropTypes.bool,
  setUTMValue: PropTypes.func,
  setIsFilterChange: PropTypes.number,
  shouldDisableDate: PropTypes.bool,
  initialFilterValues: PropTypes.shape({
    startDate: PropTypes.instanceOf(dayjs).isRequired
  })
};

export default memo(DashboardFilter);
