import React, { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import { binCheckerApiUrls } from '@constants/binCheckerApiUrls';
import BINCheckerApi from '@utils/binCheckerAPI';
import {
  initialFilterParams,
  getColumns,
  getBINNumberListOptions
} from '@components/BINChecker/BINNumbersList/binNumbersList.config';
import Button from '@components/shared/Button/Button';
import Box from '@components/shared/Box/Box';
import Modal from '@components/shared/Modal/Modal';
import AddBinNumberForm from '@components/BINChecker/BINNumbersList/AddBinNumberForm';
import Logger from '@utils/logger';
import toastify from '@utils/toastify';
import EditBinNumberForm from '@components/BINChecker/BINNumbersList/EditBinNumberForm';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';

const BINNumberListPage = () => {
  const [isShowModal, setIsShowModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataForEdit, setDataForEdit] = useState(null);
  const [filterOptions, setFilterOptions] = useState(null);
  const tableRef = useRef(null);

  const handleDeleteBINNumber = async (row) => {
    try {
      await BINCheckerApi.delete(
        binCheckerApiUrls.bankBinNumbers.item(row.bankBinNumberId)
      );
      toastify.success({
        message: 'BIN number deleted successfully'
      });
      tableRef.current?.fetchData();
    } catch (error) {
      toastify.error({
        message: 'Failed to delete BIN number'
      });
      Logger.error(error);
    }
  };

  const handleEditBINNumber = async (values) => {
    try {
      setIsLoading(true);
      await BINCheckerApi.patch(binCheckerApiUrls.bankBinNumbers.list, values, {
        params: { bankBinNumberIdKey: dataForEdit.bankBinNumberId }
      });
      toastify.success({
        message: 'BIN number updated successfully.'
      });
      setIsShowModal(false);
      tableRef.current?.fetchData();
    } catch (error) {
      toastify.error({
        message: 'Failed to submit form'
      });
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddBINNumber = async (values) => {
    const payload = {
      ...values,
      binNumber: values.binNumber.toString(),
      createdDate: dayjs().toISOString(),
      updatedDate: dayjs().toISOString()
    };
    try {
      setIsLoading(true);
      await BINCheckerApi.post(binCheckerApiUrls.bankBinNumbers.list, payload);
      toastify.success({
        message: 'BIN number added successfully.'
      });
      setIsShowModal(false);
      tableRef.current?.fetchData();
    } catch (error) {
      toastify.error({
        message: error.response?.data?.error?.message || 'Failed to submit form'
      });
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getBINNumbersColumns = useCallback(
    () =>
      getColumns({
        handleDeleteBINNumber,
        setDataForEdit,
        setIsShowModal,
        filterOptions
      }),
    [filterOptions]
  );
  const fetchOptions = async () => {
    const options = await getBINNumberListOptions();
    setFilterOptions(options);
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <MainLayout>
      <Box mt={1}>
        {helpers.getActionPermission(permissionsMap.binManage) && (
          <Button onClick={() => setIsShowModal({ add: true })}>
            Add BIN Number
          </Button>
        )}
      </Box>
      <ODataPaginatedTable
        tableRef={tableRef}
        getColumns={getBINNumbersColumns}
        usePaginatedDataOptions={{
          apiUrl: binCheckerApiUrls.bankBinNumbers.list,
          initialFilterParams: {
            $orderBy: 'bankBinNumberId desc',
            $filter: {
              ...initialFilterParams
            }
          }
        }}
        rowKey="bankBinNumberId"
        detailPanelHeight={500}
        exportFileName="BIN Number List"
        customApi={BINCheckerApi}
        exportCSV={false}
      />
      <Modal
        title={isShowModal?.add ? 'Add BIN Number' : 'Edit BIN Number'}
        open={isShowModal?.add || isShowModal?.edit}
        onClose={() => setIsShowModal(false)}
        minContentWidth="400px"
        withCloseIcon
      >
        {isShowModal?.add && (
          <AddBinNumberForm
            onSubmit={handleAddBINNumber}
            isLoading={isLoading}
            brandOptions={filterOptions.brandOptions}
            cardTypeOptions={filterOptions.cardTypeOptions}
            currencyOptions={filterOptions.currencyOptions}
            countryOptions={filterOptions.countryOptions}
            onModalClose={() => setIsShowModal(false)}
          />
        )}
        {isShowModal?.edit && (
          <EditBinNumberForm
            onSubmit={handleEditBINNumber}
            isLoading={isLoading}
            brandOptions={filterOptions.brandOptions}
            cardTypeOptions={filterOptions.cardTypeOptions}
            currencyOptions={filterOptions.currencyOptions}
            countryOptions={filterOptions.countryOptions}
            onModalClose={() => setIsShowModal(false)}
            dataForEdit={dataForEdit}
          />
        )}
      </Modal>
    </MainLayout>
  );
};

export default BINNumberListPage;
