import React, { useCallback, useEffect, useState } from 'react';
import PT from 'prop-types';
import { useParams } from 'react-router-dom';

import { columns } from './config';

import DataTable from '@components/shared/DataTable/DataTable';
import { adminApiUrls } from '@constants/adminApiUrls';
import AdminApi from '@utils/adminApi';
import Alert from '@components/shared/Alert/Alert';

const ApplicationAttributes = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const params = useParams();
  const fetchData = useCallback(async () => {
    try {
      const record = await AdminApi.get(
        adminApiUrls.applications.application(params.id),
        {
          params: {
            expand: 'metaAttributes'
          }
        }
      );

      setData(record);
    } catch (e) {
      setError(true);
    }
  }, [history, params.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {error && (
        <Alert
          type="error"
          title="Server error"
          message="Failed to load data"
        />
      )}
      <DataTable
        columns={columns}
        loading={!data}
        tableData={data?.metaAttributes || []}
        rowKey="metaAttributeId"
        hideFooter
        disableColumnMenu
        disableColumnFilter
        getRowHeight={() => 'auto'}
      />
    </>
  );
};

ApplicationAttributes.propTypes = {
  application: PT.object,
  fetchData: PT.func
};

export default ApplicationAttributes;
