import React from 'react';

import { getColumns, initialFilterParams } from './config';

import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const UserLoanListPage = () => (
  <MainLayout title="User Loans">
    <ODataPaginatedTable
      getColumns={getColumns}
      usePaginatedDataOptions={{
        apiUrl: adminApiUrls.userLoans,
        initialFilterParams: {
          $orderBy: 'requestedOn desc',
          $filter: {
            ...initialFilterParams
          },
          $expand: 'Customer'
        }
      }}
      rowKey="userLoanId"
      exportExcludeFields={['status']}
      customApi={AdminApi}
    />
  </MainLayout>
);

export default UserLoanListPage;
