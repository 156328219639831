import React, { useState } from 'react';
import PT from 'prop-types';

import Button from '@components/shared/Button/Button';
import { DeleteIcon } from '@components/shared/icons/Icons';
import Dialog from '@components/shared/Modal/Dialog';

const ConfirmDeleteButton = ({ handleDelete, itemName }) => {
  const [showModal, setShowModal] = useState();
  return (
    <>
      <Button
        startIcon={<DeleteIcon />}
        color="error"
        onClick={() => setShowModal(true)}
        variant="outlined"
      >
        Delete {itemName}
      </Button>
      <Dialog
        open={showModal}
        title={`Are you sure to delete this ${itemName}?`}
        onConfirm={handleDelete}
        onClose={() => setShowModal(false)}
        okText="Yes"
        cancelText="No"
      />
    </>
  );
};

ConfirmDeleteButton.propTypes = {
  handleDelete: PT.func.isRequired,
  itemName: PT.string.isRequired
};

export default ConfirmDeleteButton;
