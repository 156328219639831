import { configureStore } from '@reduxjs/toolkit';

import settings from './settings';
import dashboardFilters from './dashboardFilters';
import ui from './ui';
import currentUser from './currentUser';

export const store = configureStore({
  reducer: {
    settings,
    dashboardFilters,
    ui,
    currentUser
  },
  devTools: process.env.NODE_ENV !== 'production'
});
