import {
  booleanFilterFieldOptions,
  filterFieldsRenderers
} from '@constants/filters';

const defaultRemoveFilterValue = (fieldKey) => (values) =>
  Object.fromEntries(
    Object.entries(values).filter(([key]) => key !== fieldKey)
  );

const filterFieldsDict = {
  id: 'id',
  lockoutEnabled: 'lockoutEnabled',
  twoFactorEnabled: 'twoFactorEnabled'
};

export const filterFieldsOptions = [
  {
    title: 'Events',
    key: filterFieldsDict.id
  },
  {
    title: 'Lockout',
    key: filterFieldsDict.lockoutEnabled
  },
  {
    title: 'Two Factor',
    key: filterFieldsDict.twoFactorEnabled
  }
];

export const filterFieldsConfig = {
  [filterFieldsDict.id]: {
    initialValue: {
      id: {
        contains: ''
      }
    },
    renderFilterField: filterFieldsRenderers.text({
      placeholder: 'Events',
      name: 'id.contains'
    }),
    removeFilterValue: defaultRemoveFilterValue('id')
  },
  [filterFieldsDict.lockoutEnabled]: {
    initialValue: {
      lockoutEnabled: ''
    },
    renderFilterField: filterFieldsRenderers.select({
      options: booleanFilterFieldOptions,
      placeholder: 'Lockout',
      name: 'lockoutEnabled'
    }),
    removeFilterValue: defaultRemoveFilterValue('lockoutEnabled')
  },
  [filterFieldsDict.twoFactorEnabled]: {
    initialValue: {
      twoFactorEnabled: ''
    },
    renderFilterField: filterFieldsRenderers.select({
      options: booleanFilterFieldOptions,
      placeholder: 'Two Factor',
      name: 'twoFactorEnabled'
    }),
    removeFilterValue: defaultRemoveFilterValue('twoFactorEnabled')
  }
};
