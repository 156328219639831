import React from 'react';
import PT from 'prop-types';

import SystemNotificationUsersTable from '../UsersTable';

import helpers from '@utils/helpers';
import Typography from '@components/shared/Typography/Typography';
import ListContentBlock from '@components/shared/ListContentBlock/ListContentBlock';

const SystemNotificationHistoryDetails = ({ data }) => {
  const infoList = [
    {
      title: 'Date:',
      content: helpers.getFormattedDate(data?.createdDate)
    },
    {
      title: 'Number of total sending attempts:',
      content: data?.sendingAttempts
    },
    {
      title: 'Number of successful sending attempts:',
      content: data?.successfulSending
    },
    {
      title: 'Number of views:',
      content: data?.readAmount
    }
  ];

  return (
    <>
      <ListContentBlock
        title="Notification History Details"
        contentList={infoList}
        wrapperProps={{ mb: 2 }}
      />
      <Typography variant="h4" my={2}>
        Users List
      </Typography>
      <SystemNotificationUsersTable id={data.instanceUid} />
    </>
  );
};

SystemNotificationHistoryDetails.defaultProps = {
  data: {}
};

SystemNotificationHistoryDetails.propTypes = {
  data: PT.object
};

export default SystemNotificationHistoryDetails;
