import React from 'react';
import PT from 'prop-types';
import MuiModal from '@mui/material/Modal';

import { ContainerModal, HeaderModal } from './styled';

import Box from '@components/shared/Box/Box';
import Stack from '@components/shared/Stack/Stack';
import IconButton from '@components/shared/IconButton/IconButton';
import { CloseIcon } from '@components/shared/icons/Icons';
import Typography from '@components/shared/Typography/Typography';
import Skeleton from '@components/shared/Skeleton/Skeleton';

export const modalLoading = (
  <Box width="400px" marginX="auto">
    <Skeleton height="60px" width="400px" />
    <Skeleton height="60px" width="200px" />
    <Skeleton height="60px" width="200px" />
  </Box>
);

export const BaseModal = ({ open, children, onClose, ...rest }) => (
  <MuiModal
    sx={{ overflowY: 'scroll' }}
    open={open}
    onClose={onClose}
    {...rest}
  >
    <>{children}</>
  </MuiModal>
);

const Modal = ({
  children,
  title,
  withCloseIcon,
  loading,
  maxHeight,
  minContentWidth,
  containerModalStyle,
  ...rest
}) => (
  <BaseModal {...rest}>
    <ContainerModal maxHeight={maxHeight} style={containerModalStyle}>
      <HeaderModal minWidth={minContentWidth}>
        {title && (
          <Typography
            variant="h4"
            sx={{
              fontWeight: 800,
              textAlign: 'center'
            }}
          >
            {title}
          </Typography>
        )}
        {withCloseIcon && (
          <Stack alignItems="flex-end">
            <IconButton size="small" onClick={rest.onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        )}
      </HeaderModal>
      <Box>{loading ? modalLoading : children}</Box>
    </ContainerModal>
  </BaseModal>
);

Modal.defaultProps = {
  minContentWidth: '300px'
};
const defaultPropTypes = {
  open: PT.bool,
  onClose: PT.func,
  children: PT.node,
  maxHeight: PT.string
};
BaseModal.propTypes = defaultPropTypes;
Modal.propTypes = {
  ...defaultPropTypes,
  withCloseIcon: PT.bool,
  title: PT.string,
  loading: PT.bool,
  minContentWidth: PT.string,
  containerModalStyle: PT.object
};

export default Modal;
