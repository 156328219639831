import React from 'react';
import PT from 'prop-types';

import List from '@components/shared/List/List';
import ListItem from '@components/shared/List/ListItem';
import { palette } from '@utils/theme';

const ScheduledPaymentsSettingsList = ({ list }) => (
  <List>
    {list.map(
      ({ key, header, hidden }, idx) =>
        !hidden && (
          <ListItem
            key={key + idx}
            disablePadding
            sx={{ border: `1px solid ${palette.border}`, padding: 1 }}
          >
            {header}
          </ListItem>
        )
    )}
  </List>
);

export default ScheduledPaymentsSettingsList;

ScheduledPaymentsSettingsList.propTypes = {
  list: PT.arrayOf(
    PT.shape({
      key: PT.string,
      header: PT.string,
      content: PT.oneOfType([PT.string, PT.node]),
      hidden: PT.bool
    })
  )
};
