import React from 'react';
import PT from 'prop-types';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';

import {
  schedulingFieldsSchema,
  notificationFrequencyOptions,
  getInitialValues
} from './schema';

import { dateShortTimeFormat } from '@constants/common';
import Alert from '@components/shared/Alert/Alert';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Typography from '@components/shared/Typography/Typography';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';

const SchedulingStep = ({ onSubmit, scheduleData }) => {
  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    const payload = {
      ...values,
      dateTime: values.dateTime
        ? dayjs(values.dateTime).toISOString()
        : dayjs().toISOString()
    };

    onSubmit(
      { values: payload, actions },
      {
        onFinish: () => {
          actions.setSubmitting(false);
        }
      }
    );
  };

  return (
    <Formik
      initialValues={getInitialValues(scheduleData)}
      onSubmit={handleSubmit}
      validationSchema={schedulingFieldsSchema}
    >
      {({ values, errors, isSubmitting }) => (
        <Form>
          <Typography variant="h4" my={2}>
            Scheduling
          </Typography>

          <FormikField
            type="select"
            name="isNow"
            label="Select notification frequency"
            options={notificationFrequencyOptions}
            placeholder="Select"
          />
          {!values.isNow && (
            <>
              <FormikField
                type="date"
                name="dateTime"
                placeholder={dateShortTimeFormat}
                format={dateShortTimeFormat}
                label="Select date and time:"
                disablePast
              />
            </>
          )}
          {errors.nonFieldErrors && (
            <Alert
              message={errors.nonFieldErrors}
              type="error"
              className="mb-3"
            />
          )}
          <Stack size={20} alignItems="flex-start" my={2}>
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

SchedulingStep.defaultProps = {
  scheduleData: {}
};

SchedulingStep.propTypes = {
  onSubmit: PT.func,
  scheduleData: PT.object
};

export default SchedulingStep;
