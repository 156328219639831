import React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';

import { palette } from '@utils/theme';

export const StyledTabs = styled((props) => (
  <Tabs
    selectionFollowsFocus
    allowScrollButtonsMobile
    variant="scrollable"
    scrollButtons="auto"
    {...props}
  />
))(({ isSubTabPanel }) => ({
  '&.MuiTabs-root': {
    minHeight: isSubTabPanel ? '30px !important' : '40px !important',

    '& .MuiTabScrollButton-root.Mui-disabled': {
      opacity: '0.3 !important'
    }
  },
  '& .MuiTab-root': {
    padding: '5px 10px',
    maxWidth: '460px',
    fontSize: '14px',
    textTransform: isSubTabPanel ? 'capitalize' : 'uppercase',

    '&.Mui-disabled': {
      color: `${palette.primary} !important`
    }
  }
}));
