import React, { useCallback, useEffect, useRef, useState } from 'react';
import PT from 'prop-types';
import ReactJson from 'react-json-view';
import { useHistory } from 'react-router';

import { communicationLogsColumns } from './config';

import Button from '@components/shared/Button/Button';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import Loader from '@components/shared/Loader';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

const CommunicationLog = ({ filter, exportFileName, additionalColumns }) => {
  const tableRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const history = useHistory();

  useEffect(() => {
    history.push({
      pathname: history.location.pathname,
      search: ''
    });
  }, []);

  useEffect(() => {
    if (!history.location.search) {
      setLoader(false);
    }
  }, [history.location?.search]);

  const transformData = useCallback(
    (logs) =>
      logs.map((log, key) => ({
        ...log,
        response: JSON.parse(log.response),
        request: JSON.parse(log.request),
        key
      })),
    []
  );

  const getColumns = useCallback(
    () => () => [...additionalColumns, ...communicationLogsColumns],
    [additionalColumns, communicationLogsColumns]
  );

  if (loader) return <Loader />;

  return (
    <>
      <Button
        onClick={() => tableRef.current?.fetchData()}
        variant="outlined"
        sx={{ mr: 1, verticalAlign: 'unset', mt: 1 }}
      >
        Refresh logs
      </Button>
      <ODataPaginatedTable
        tableRef={tableRef}
        getColumns={getColumns()}
        customApi={LeadManagementApi}
        usePaginatedDataOptions={{
          apiUrl: leadManagementApiUrls.communicationLog,
          initialFilterParams: {
            $orderBy: 'leadReceivedDate desc',
            $filter: {
              ...filter
            }
          }
        }}
        rowKey="leadProviderRequestId"
        exportFileName={exportFileName}
        getDetailPanelContent={({ row }) => <ReactJson src={row} />}
        transformData={transformData}
      />
    </>
  );
};

CommunicationLog.defaultProps = {
  additionalColumns: []
};

CommunicationLog.propTypes = {
  filter: PT.any.isRequired,
  exportFileName: PT.string.isRequired,
  additionalColumns: PT.array
};

export default CommunicationLog;
