import { useEffect, useState } from 'react';

import { leadManagementReportsApiUrls } from '@constants/leadManagementReportsApi';
import LeadPerformance from '@components/LeadReports/LeadPerformance/LeadPerformance';
import LeadManagementReportsApi from '@utils/leadManagementReportsApi';
import toastify from '@utils/toastify';

const StackPerformancePage = () => {
  const [options, setOptions] = useState(null);

  const createOptionsForSelect = (data) =>
    data?.map(({ value }) => ({
      label: value.trim(),
      value: value.trim()
    })) || [];

  const getOptions = async () => {
    try {
      const [stackNames, promoCodes] = await Promise.all([
        LeadManagementReportsApi.get(leadManagementReportsApiUrls.stackNames),
        LeadManagementReportsApi.get(leadManagementReportsApiUrls.promoCodes)
      ]);

      setOptions({
        stackNames: createOptionsForSelect(stackNames),
        promoCodes: createOptionsForSelect(promoCodes)
      });
    } catch (error) {
      toastify.error({
        message: 'Failed to fetch options for filters'
      });
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  return LeadPerformance(
    leadManagementReportsApiUrls.stackAggregateReport,
    'Stack Performance',
    'datePeriod',
    options
  );
};

export default StackPerformancePage;
