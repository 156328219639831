import React from 'react';

import Tooltip from '@components/shared/Tooltip/Tooltip';
import routes from '@constants/routes';
import {
  leadManagementReportsApiBaseUrl,
  leadManagementReportsApiUrls
} from '@constants/leadManagementReportsApi';
import LeadManagementReportsApi from '@utils/leadManagementReportsApi';
import { adminApiBaseUrl, adminApiUrls } from '@constants/adminApiUrls';
import AdminApi from '@utils/adminApi';

export const webColumns = [
  {
    headerName: 'Http method',
    field: 'httpMethod',
    minWidth: 100
  },
  {
    headerName: 'Execution count',
    field: 'executionCount'
  },
  {
    headerName: 'Total time',
    field: 'totalTime',
    renderCell: ({ row, field }) =>
      row?.[field] ? row[field].toFixed(2) + ' ms' : '0 ms',
    defaultSortOrder: 'desc'
  },
  {
    headerName: 'Return code',
    field: 'returnCode'
  },
  {
    headerName: 'Minimal execution time',
    field: 'timeMin',
    renderCell: ({ row, field }) =>
      row?.[field] ? row[field].toFixed(2) + ' ms' : '0 ms'
  },
  {
    headerName: 'Average execution time',
    field: 'timeAverage',
    renderCell: ({ row, field }) =>
      row?.[field] ? row[field].toFixed(2) + ' ms' : '0 ms'
  },
  {
    headerName: 'Maximum execution time',
    field: 'timeMax',
    renderCell: ({ row, field }) =>
      row?.[field] ? row[field].toFixed(2) + ' ms' : '0 ms'
  },
  {
    headerName: 'Requested url',
    field: 'requestedUrl',
    minWidth: '350'
  }
];

export const dbColumns = [
  {
    headerName: 'Execution count',
    field: 'execution_count'
  },
  {
    headerName: 'Total time',
    field: 'total_time',
    renderCell: ({ row, field }) =>
      row[field] ? row[field].toFixed(2) + ' ms' : '0 ms',
    defaultSortOrder: 'desc'
  },
  {
    headerName: 'Minimal execution time',
    field: 'min_time',
    renderCell: ({ row, field }) =>
      row[field] ? row[field].toFixed(2) + ' ms' : '0 ms'
  },
  {
    headerName: 'Average execution time',
    field: 'avarage_time',
    renderCell: ({ row, field }) =>
      row[field] ? row[field].toFixed(2) + ' ms' : '0 ms'
  },
  {
    headerName: 'Maximum execution time',
    field: 'max_time',
    renderCell: ({ row, field }) =>
      row[field] ? row[field].toFixed(2) + ' ms' : '0 ms'
  },
  {
    headerName: 'Total rows',
    field: 'total_rows'
  },
  {
    headerName: 'Total logical reads',
    field: 'total_logical_reads'
  },
  {
    headerName: 'Total logical writes',
    field: 'total_logical_writes'
  },
  {
    headerName: 'Query',
    field: 'query_text',
    minWidth: 400,
    renderCell: ({ row, field }) => (
      <Tooltip
        placement="bottom-end"
        title={row?.[field]}
        cutContentByWidth="auto"
      >
        {row?.[field]}
      </Tooltip>
    )
  }
];

export const leadReportsPerformanceColumns = [
  {
    headerName: 'Execution count',
    field: 'executionCount'
  },
  {
    headerName: 'Total time',
    field: 'totalTime',
    renderCell: ({ row, field }) =>
      row[field] ? row[field].toFixed(2) + ' ms' : '0 ms',
    defaultSortOrder: 'desc'
  },
  {
    headerName: 'Minimal execution time',
    field: 'minimalExecutionTime',
    renderCell: ({ row, field }) =>
      row[field] ? row[field].toFixed(2) + ' ms' : '0 ms'
  },
  {
    headerName: 'Average execution time',
    field: 'averageExecutionTime',
    renderCell: ({ row, field }) =>
      row[field] ? row[field].toFixed(2) + ' ms' : '0 ms'
  },
  {
    headerName: 'Maximum execution time',
    field: 'maximumExecutionTime',
    renderCell: ({ row, field }) =>
      row[field] ? row[field].toFixed(2) + ' ms' : '0 ms'
  },
  {
    headerName: 'Total rows',
    field: 'totalRows'
  },
  {
    headerName: 'Total logical reads',
    field: 'totalLogicalReads'
  },
  {
    headerName: 'Query',
    field: 'query',
    minWidth: 400,
    renderCell: ({ row, field }) => (
      <Tooltip
        placement="bottom-end"
        title={row?.[field]}
        cutContentByWidth="auto"
      >
        {row?.[field]}
      </Tooltip>
    )
  }
];

export const tabNames = {
  web: 'Web Performance',
  db: 'Database Performance',
  lead: 'Lead Reports Performance'
};

export const subTabsList = [
  {
    id: tabNames.web,
    title: tabNames.web,
    link: routes.dashboard.internalPerformance.web
  },
  {
    id: tabNames.db,
    title: tabNames.db,
    link: routes.dashboard.internalPerformance.db
  },
  {
    id: tabNames.lead,
    title: tabNames.lead,
    link: routes.dashboard.internalPerformance.leadReportsPerformance
  }
];

export const performancePageConfig = {
  web: {
    title: tabNames.web,
    withoutFilter: false,
    endpoint: adminApiUrls.dashboard.webPerformance,
    exportFileName: 'Web-performance',
    columns: webColumns,
    getServerData: async (parsedParams) =>
      await AdminApi.get(adminApiUrls.dashboard.webPerformance, {
        params: parsedParams
      }),
    customApiBaseUrl: adminApiBaseUrl
  },
  db: {
    title: tabNames.db,
    withoutFilter: false,
    endpoint: adminApiUrls.dashboard.dbPerformance,
    exportFileName: 'Database-performance',
    columns: dbColumns,
    getServerData: async (parsedParams) =>
      await AdminApi.get(adminApiUrls.dashboard.dbPerformance, {
        params: parsedParams
      }),
    customApiBaseUrl: adminApiBaseUrl
  },
  lead: {
    title: tabNames.lead,
    withoutFilter: true,
    endpoint: leadManagementReportsApiUrls.leadReportsPerformance,
    exportFileName: 'Lead-reports-performance',
    columns: leadReportsPerformanceColumns,
    getServerData: async (parsedParams) => {
      const response = await LeadManagementReportsApi.get(
        leadManagementReportsApiUrls.leadReportsPerformance,
        {
          params: parsedParams
        }
      );
      return response;
    },
    customApiBaseUrl: leadManagementReportsApiBaseUrl
  }
};
