import { styled } from '@mui/material/styles';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';

export const StyledBreadcrumbs = styled(MUIBreadcrumbs)`
  .MuiBreadcrumbs {
    &-ol {
      padding-top: 5px;
    }
    &-li {
      font-size: 14px;
      text-decoration: none;
    }
`;
