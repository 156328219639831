import * as Yup from 'yup';

import validators from '@utils/validators';

export const addBinNumberSchema = Yup.object().shape({
  binNumber: validators.equalsTo(6).label('BIN number').required(),
  bankName: Yup.string().label('Bank name').required(),
  brandId: Yup.string().label('Brand').required(),
  typeId: Yup.string().label('Card Type').required(),
  currencyId: Yup.string().label('Currency').required(),
  countryId: Yup.string().label('Country').required(),
  isBankTranslationNeeded: Yup.bool().label('Is Bank Name Translation needed'),
  bankTranslation: Yup.string()
    .label('Translation Name')
    .when('isBankTranslationNeeded', {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired()
    })
});
