import PT from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import dayjs from 'dayjs';

import { getInitialValues } from './config';

import Stack from '@components/shared/Stack/Stack';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Button from '@components/shared/Button/Button';
import { cleanObject } from '@utils/object-cleaner';
import { dateFormat, shortTimeFormat } from '@constants/common';
import helpers from '@utils/helpers';
import { showAllValue } from '@components/LeadReports/filters/config';
import filterHelpers from '@utils/filterHelpers';

const fieldWidth = { width: '150px !important' };

const SeparatedDateTimeFilter = ({
  dateField,
  tableRef,
  additionalFieldsProps
}) => {
  const history = useHistory();
  const [showStartTime, setShowStartTime] = useState(false);
  const [showEndTime, setShowEndTime] = useState(false);

  const filters = qs.parse(history.location.search).$filter;

  const handleUpdateFilters = (values) => {
    const {
      startDate: startDateString,
      endDate: endDateString,
      startTime: startTimeFromForm,
      endTime: endTimeTimeFromForm
    } = values;

    const startTime = dayjs(
      showStartTime ? startTimeFromForm : getInitialValues().startTime
    );

    const endTime = dayjs(
      showEndTime ? endTimeTimeFromForm : getInitialValues().endTime
    );

    const { startDate, endDate } = helpers.concatenateDateAndTime(
      startTime,
      endTime,
      startDateString,
      endDateString
    );

    const parsedFilters = filters ? JSON.parse(filters) : {};
    const filterValues = cleanObject({
      ...parsedFilters,
      leadCampaign: {
        eq: values.leadCampaign === showAllValue ? '' : values.leadCampaign
      },
      [dateField]: {
        ge: startDate,
        le: endDate
      }
    });
    filterHelpers.handleChangeFilter(tableRef, filterValues);
  };

  useEffect(() => {
    if (!filters) {
      return;
    }
    const parsedFilters = filters ? JSON.parse(filters) : {};
    if (Object.keys(parsedFilters).length === 0) {
      handleUpdateFilters(getInitialValues());
    }
  }, [history.location.search, filters]);

  const onSubmitFilters = (values) => {
    handleUpdateFilters(values);
  };

  const additionalInitialValues = additionalFieldsProps?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.name]: curr.value || curr.options[0].value
    }),
    {}
  );

  return (
    <Formik
      initialValues={{
        ...getInitialValues(filters),
        showStartTime: false,
        showEndTime: false,
        ...(additionalInitialValues && additionalInitialValues)
      }}
      onSubmit={onSubmitFilters}
      enableReinitialize
    >
      {({ setFieldValue }) => (
        <Form>
          <Stack direction="row" gap={1} alignItems="center">
            {additionalFieldsProps &&
              additionalFieldsProps.map((elem) => (
                <FormikField key={elem.name} {...elem} />
              ))}
            <FormikField
              type="date"
              name="startDate"
              label="Start date"
              size="small"
              format={dateFormat}
              sx={fieldWidth}
            />
            <FormikField
              type="checkbox"
              name="showStartTime"
              value={showStartTime}
              onChange={() => {
                setShowStartTime(!showStartTime);
                setFieldValue('startTime', dayjs());
              }}
              label="Time"
              fullWidth
            />
            {showStartTime && (
              <>
                <FormikField
                  type="time"
                  name="startTime"
                  label="Start time"
                  format={shortTimeFormat.front}
                />
              </>
            )}
            <FormikField
              type="date"
              name="endDate"
              label="End date"
              size="small"
              format={dateFormat}
              sx={fieldWidth}
            />
            <FormikField
              type="checkbox"
              name="showEndTime"
              label="Time"
              value={showEndTime}
              onChange={() => {
                setShowEndTime(!showEndTime);
                setFieldValue('endTime', dayjs());
              }}
              fullWidth
            />
            {showEndTime && (
              <>
                <FormikField
                  type="time"
                  name="endTime"
                  label="End time"
                  format={shortTimeFormat.front}
                />
              </>
            )}
            <Button type="submit">Apply</Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

SeparatedDateTimeFilter.defaultProps = {
  dateField: 'createdDate',
  isAdmin: false
};
SeparatedDateTimeFilter.propTypes = {
  dateField: PT.string,
  tableRef: PT.object,
  additionalFieldsProps: PT.array
};

export default memo(SeparatedDateTimeFilter);
