import dayjs from 'dayjs';

import { dateTimeFormat } from '@constants/common';

const fileInfoMap = {
  fileName: 'FileName',
  fileCreated: 'FileCreated',
  rowsCount: 'RowsCount',
  createdDate: 'CreatedDate',
  updatedDate: 'UpdatedDate'
};

const fileInfoFieldNames = {
  [fileInfoMap.fileName]: 'File name',
  [fileInfoMap.fileCreated]: 'File created',
  [fileInfoMap.rowsCount]: 'Rows in file',
  [fileInfoMap.createdDate]: 'Created date',
  [fileInfoMap.updatedDate]: 'Updated date'
};

const parseToDate = (date) => dayjs(date).format(dateTimeFormat);

const fileInfoRender = {
  [fileInfoMap.fileCreated]: parseToDate,
  [fileInfoMap.createdDate]: parseToDate,
  [fileInfoMap.updatedDate]: parseToDate
};

export const fileInfoKeyHelper = (key) => fileInfoFieldNames[key] || key;

export const fileInfoValueHelper = (key, value) =>
  fileInfoRender[key] ? fileInfoRender[key](value) : value;
