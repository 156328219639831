import React from 'react';

import InternalPerformance from '@components/Dashboard/InternalPerformance/InternalPerformance';
import { performancePageConfig } from '@components/Dashboard/InternalPerformance/config';

const DashboardLeadReportsPerformancePage = () => {
  const leadConfig = performancePageConfig.lead;
  return <InternalPerformance {...leadConfig} />;
};

export default DashboardLeadReportsPerformancePage;
