import React, {
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import PT from 'prop-types';
import {
  GRID_AGGREGATION_FUNCTIONS,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
  GridLogicOperator,
  useGridApiRef
} from '@mui/x-data-grid-premium';

import { StyledDataGrid } from './styled';
import { getColumnForNewFilter, getFilterColumns } from './DataTable.utils';
import LoadingOverlay from './components/LoadingOverlay';
import CustomToolbar from './components/CustomToolbar';
import DataTableFooter from './components/DataTableFooter';
import { filtersPanelConfig } from './DataTable.config';
import CustomColumnMenu from './components/CustomColumnMenu';

import EmptyState from '@components/shared/EmptyState/EmptyState';
import { useVirtualizing } from '@hooks/useVirtualizing';

const DataTable = ({
  pinnedColumnsLeft,
  pinnedColumnsRight,
  hasError,
  loading,
  rowKey,
  totalAmount,
  paginationOptions,
  getDetailPanelContent,
  detailPanelHeight,
  setOrder,
  refetchList,
  expandedByDefault,
  initialParams,
  onFilterChange,
  onSortingChange,
  handleExpandedRowsChange,
  aggregationFunctions,
  tableData: _tableData,
  apiRef: _apiRef,
  tableRef,
  columns,
  exportCsvProps,
  filtersProps,
  slots,
  isVirtualized,
  additionalContentInToolbar,
  defaultExportTitle,
  hideAggregationInColumnMenu,
  ...props
}) => {
  const apiRef = useGridApiRef();
  const [tableData, setTableData] = useState([]);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState(
    []
  );

  const { tableContainerRef, getTableHeight } = useVirtualizing(
    tableData.length
  );

  useImperativeHandle(_apiRef, () => apiRef.current);

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
    setDetailPanelExpandedRowIds(newIds);
    handleExpandedRowsChange?.(newIds);
  }, []);

  const handleFilterChange = useCallback((filterModel) => {
    onFilterChange?.({ filterModel: { ...filterModel } });
  }, []);

  const expandedRowsProps = {
    getDetailPanelContent: getDetailPanelContent,
    rowThreshold: 0,
    getDetailPanelHeight: () => detailPanelHeight,
    detailPanelExpandedRowIds: detailPanelExpandedRowIds,
    onDetailPanelExpandedRowIdsChange: handleDetailPanelExpandedRowIdsChange
  };

  useEffect(() => {
    setTableData(_tableData || []);
  }, [_tableData]);

  useEffect(() => {
    expandedByDefault && setDetailPanelExpandedRowIds(expandedByDefault);
  }, [expandedByDefault]);

  const filteredColumns = columns.map((column) => ({
    ...column,
    aggregable: column.aggregable === undefined ? false : column.aggregable,
    disableColumnMenu:
      column.filterable === undefined ? false : !column.filterable
  }));

  const initialState = useMemo(
    () => ({
      apiRef,
      ...initialParams,
      pinnedColumns: {
        left: [
          'actions',
          GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
          ...pinnedColumnsLeft
        ],
        right: [
          getDetailPanelContent && GRID_DETAIL_PANEL_TOGGLE_FIELD,
          ...pinnedColumnsRight
        ]
      }
    }),
    [initialParams, pinnedColumnsLeft, pinnedColumnsRight]
  );
  useEffect(() => {
    const isFullNameOperatorPresent =
      initialState?.filter?.filterModel?.items?.some(
        (item) => item.operator === 'fullName'
      );
    if (isFullNameOperatorPresent) {
      return;
    }
  }, []);

  const resetClientFilters = () => {
    apiRef?.current?.setFilterModel({ items: [] });
  };

  const getClientFilterState = (filterModel) => !filterModel?.items?.length;

  return (
    <div
      style={{
        width: '100%',
        height: isVirtualized ? getTableHeight() : 'auto'
      }}
      ref={tableContainerRef}
    >
      <StyledDataGrid
        autoHeight={tableData.length === 1}
        apiRef={apiRef}
        ref={tableRef}
        rows={tableData}
        columns={filteredColumns}
        pagination={!!paginationOptions}
        disableSelectionOnClick
        disableColumnSelector
        disableDensitySelector
        hideFooterSelectedRowCount
        density="compact"
        paginationMode={paginationOptions ? 'server' : 'client'}
        filterMode={paginationOptions ? 'server' : 'client'}
        sortingMode={paginationOptions ? 'server' : 'client'}
        onSortModelChange={onSortingChange}
        onFilterModelChange={paginationOptions && handleFilterChange}
        getRowId={(row) => row[rowKey]}
        pinnedColumns
        initialState={initialState}
        rowHeight={30}
        loading={(!hasError && !tableData) || loading}
        rowCount={totalAmount}
        slots={{
          toolbar: () => (
            <CustomToolbar
              hideFilters={props.disableColumnFilter}
              exportCsvProps={exportCsvProps}
              filtersProps={{
                ...filtersProps,
                ...(!paginationOptions && {
                  resetHandle: resetClientFilters,
                  disableReset: getClientFilterState(
                    apiRef?.current?.state?.filter?.filterModel
                  )
                })
              }}
              additionalContentInToolbar={additionalContentInToolbar}
            />
          ),
          noRowsOverlay: EmptyState,
          loadingOverlay: LoadingOverlay,
          footer: () => (
            <DataTableFooter
              tableRef={tableRef}
              rowsCount={totalAmount}
              paginationOptions={paginationOptions}
              setOrder={setOrder} // from usePaginatedData hook
              refetchList={refetchList} // from usePagination hook
            />
          ),
          columnMenu: (props) => (
            <CustomColumnMenu
              hideAggregationInColumnMenu={hideAggregationInColumnMenu}
              {...props}
            />
          ),
          ...slots
        }}
        slotProps={{
          filterPanel: {
            ...filtersPanelConfig,
            logicOperators: [GridLogicOperator.And, GridLogicOperator.Or],
            filterFormProps: {
              filterColumns: getFilterColumns
            },
            getColumnForNewFilter
          },
          toolbar: {
            printOptions: { disableToolbarButton: true },
            excelOptions: { disableToolbarButton: true },
            exportCsvProps: {
              disableToolbarButton: !exportCsvProps,
              ...exportCsvProps
            }
          }
        }}
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          ...aggregationFunctions
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        {...(getDetailPanelContent && { ...expandedRowsProps })}
        localeText={{
          toolbarExport: defaultExportTitle || 'Export Summary'
        }}
        disableRowGrouping={paginationOptions ? true : false}
        {...props}
      />
    </div>
  );
};

DataTable.defaultProps = {
  totalAmount: 0,
  pinnedColumns: null,
  hasError: false,
  loading: false,
  rowKey: 'id',
  detailPanelHeight: 'auto',
  pinnedColumnsLeft: [],
  pinnedColumnsRight: [],
  disableColumnFilter: false,
  totalName: 'Total',
  slotProps: {},
  slots: {},
  exportCsvProps: null,
  csvOptions: null,
  aggregationFunctions: {},
  hideAggregationInColumnMenu: false
};

const columnsShape = PT.shape({
  headerName: PT.string.isRequired,
  field: PT.string.isRequired,
  minWidth: PT.number,
  flex: PT.number,
  renderCell: PT.func,
  sortable: PT.bool
});

DataTable.propTypes = {
  columns: PT.arrayOf(columnsShape).isRequired,
  pinnedColumns: PT.object,
  hasError: PT.bool,
  loading: PT.bool,
  tableData: PT.array,
  tableRef: PT.object,
  rowKey: PT.string,
  totalAmount: PT.number,
  detailPanelHeight: PT.oneOfType([PT.number, PT.string]),
  getDetailPanelContent: PT.func,
  paginationOptions: PT.object,
  rowTotal: PT.array,
  setOrder: PT.func,
  refetchList: PT.func,
  pinnedColumnsLeft: PT.array,
  pinnedColumnsRight: PT.array,
  expandedByDefault: PT.array,
  initialParams: PT.object,
  onSortingChange: PT.func,
  onFilterChange: PT.func,
  handleExpandedRowsChange: PT.func,
  aggregationFunctions: PT.object,
  apiRef: PT.object,
  exportCsvProps: PT.object,
  disableColumnFilter: PT.bool,
  filtersProps: PT.object,
  slots: PT.object,
  additionalContentInToolbar: PT.func,
  defaultExportTitle: PT.string,
  csvOptions: PT.object,
  hideAggregationInColumnMenu: PT.bool,
  isVirtualized: PT.bool
};

export default memo(DataTable);
