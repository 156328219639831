import isFunction from 'lodash/isFunction';
import { useEffect, useRef, useState } from 'react';

import Api from '@utils/api';
import Logger from '@utils/logger';

export const useServerData = ({ endpoint, customApi = Api }) => {
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isComponentExist = useRef(true);

  const getEndpoint = () =>
    isFunction(endpoint) ? endpoint() : customApi.get(endpoint);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (error) setError(false);

      const response = await getEndpoint();

      if (!response) {
        Logger.error('useServerData hooks expects to get response object');
      }

      if (isComponentExist.current) {
        setData(response);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      Logger.error(e);
      if (isComponentExist.current) {
        setError(true);
      }
    }
  };

  useEffect(
    () => () => {
      isComponentExist.current = false;
    },
    []
  );

  return {
    data,
    setData,
    error,
    setError,
    isLoading,
    fetchData
  };
};
