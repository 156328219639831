import React, { memo, useEffect, useMemo, useState } from 'react';
import PT from 'prop-types';
import omit from 'lodash/omit';
import TablePagination from '@mui/material/TablePagination';

import { getSelectedPage } from '../DataTable.utils';

import GoToPageSection from './GoToPageSection';

import Stack from '@components/shared/Stack/Stack';
import { defaultPageSizeOptions } from '@constants/common';
import { useQueryParams } from '@hooks/useQueryParams';

const DataTableFooter = ({
  tableRef,
  rowsCount,
  paginationOptions,
  refetchList,
  setOrder
}) => {
  const params = useQueryParams();
  const filters = useMemo(
    () => omit(params, ['offset', 'take', 'recordsCount']),
    [params]
  );

  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(paginationOptions?.current - 1);
  }, [tableRef, paginationOptions?.current]);

  const pageSize = useMemo(
    () => tableRef?.current?.pageSize || paginationOptions?.pageSize,
    [tableRef, paginationOptions]
  );

  const onPageChange = (newPage) => {
    setOrder?.({ pageSize: paginationOptions?.pageSize, current: newPage + 1 });
    refetchList?.({
      offset: paginationOptions?.pageSize * newPage,
      take: paginationOptions?.pageSize,
      ...filters
    });
    if (tableRef?.current) {
      tableRef.current.page = newPage;
    }
  };

  const onRowsPerPageChange = (newPageSize) => {
    setOrder?.({ pageSize: newPageSize, current: 1 });
    refetchList?.({
      offset: 0,
      take: newPageSize,
      ...filters
    });
    tableRef.current.page = 0;
    tableRef.current.pageSize = newPageSize;
  };

  const rowsPerPageOptions = defaultPageSizeOptions
    .concat(paginationOptions?.additionalPageSize || [])
    .sort((a, b) => a - b);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        backgroundColor: 'white',
        p: '0 10px',
        borderTop: '1px solid #e0e0e0'
      }}
    >
      {paginationOptions && (
        <>
          <GoToPageSection
            selectedPage={page}
            pageSize={pageSize}
            rowsCount={rowsCount}
            handlePageChange={(_event, selectedOption) => {
              onPageChange(getSelectedPage(selectedOption));
            }}
          />
          <TablePagination
            page={page}
            count={rowsCount || 0}
            pageSize={pageSize}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[...new Set(rowsPerPageOptions)]}
            showFirstButton
            showLastButton
            onPageChange={(_, page) => onPageChange(page)}
            onRowsPerPageChange={(e) => onRowsPerPageChange(e.target.value)}
            sx={{ border: 'none' }}
          />
        </>
      )}
    </Stack>
  );
};

DataTableFooter.propTypes = {
  tableRef: PT.object,
  rowsCount: PT.number,
  paginationOptions: PT.object,
  setOrder: PT.func,
  refetchList: PT.func
};

export default memo(DataTableFooter);
