import * as Yup from 'yup';

const validateFile = Yup.mixed().nullable().required('File is required');

export const validationSchema = Yup.object().shape({
  fileA: validateFile,
  fileB: validateFile
});

export const initialValues = {
  fileA: '',
  fileB: ''
};
