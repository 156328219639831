import React from 'react';
import PT from 'prop-types';

import DataTable from '@components/shared/DataTable/DataTable';
import Alert from '@components/shared/Alert/Alert';
import { columns as fieldsErrorRateColumns } from '@components/Dashboard/ApplicationErrors/Content/config';

const ApplicationStepFieldsErrorRate = ({ data, error, isLoading }) => (
  <>
    <DataTable
      sx={{ '& .MuiDataGrid-virtualScroller': { minHeight: '120px' } }}
      disableColumnFilter
      columns={fieldsErrorRateColumns}
      tableData={data}
      loading={isLoading}
      rowKey="fieldName"
    />
    {error && (
      <Alert
        message="Failed to fetch"
        description={error}
        type="error"
        showIcon
      />
    )}
  </>
);

ApplicationStepFieldsErrorRate.propTypes = {
  data: PT.array.isRequired,
  isLoading: PT.bool.isRequired,
  error: PT.string
};

export default ApplicationStepFieldsErrorRate;
