import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Alert from '@components/shared/Alert/Alert';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Logger from '@utils/logger';
import CampaignGroupForm from '@components/Leads/campaignGroups/Form';
import toastify from '@utils/toastify';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

const CampaignGroupEditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await LeadManagementApi.get(
          leadManagementApiUrls.campaignGroups.page(id)
        );
        setData(response);
      } catch (e) {
        Logger.error(e);
        setError(e);
      }
    })();
  }, []);

  const handleSubmit = async (
    { values, actions },
    { onSuccess, onError, onFinish }
  ) => {
    try {
      const response = await LeadManagementApi.put(
        leadManagementApiUrls.campaignGroup.page(id),
        values
      );

      toastify.success({
        message: 'Update operation success'
      });

      onSuccess && onSuccess(response);
    } catch (e) {
      onError && onError(e);

      actions.setErrors({
        ...e,
        nonFieldErrors:
          e.nonFieldErrors || e?.response?.data?.error?.message || e.message
      });

      toastify.error({
        message: 'Error',
        description: 'Failed to submit form'
      });
    } finally {
      onFinish && onFinish();
    }
  };

  return (
    <MainLayout title="Edit Campaign" isLoading={!data && !error}>
      {data && (
        <CampaignGroupForm data={data} editMode onSubmit={handleSubmit} />
      )}
      {error && (
        <Alert
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
        />
      )}
    </MainLayout>
  );
};

export default CampaignGroupEditPage;
