import React, { useEffect } from 'react';
import PT from 'prop-types';

import { getAccordionList, leadKeys } from './config';

import Accordion from '@components/shared/Accordion/Accordion';
import EmptyState from '@components/shared/EmptyState/EmptyState';
import Loader from '@components/shared/Loader';
import { useServerData } from '@hooks/useServerData';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const ApplicationLeadResponsesHistory = ({ id }) => {
  const { data, isLoading, error, fetchData } = useServerData({
    endpoint: async () => {
      const response = await AdminApi.get(
        adminApiUrls.applicationLeadTelemetry(id)
      );

      return response.items.map((record) => ({
        ...record,
        leadResponse: record.formData.find(
          ({ dataType }) => dataType === leadKeys.response
        ),
        leadRequest: record.formData.find(
          ({ dataType }) => dataType === leadKeys.request
        ),
        leadCustomFieldRequest: record.formData
          .filter(({ dataType }) => dataType === leadKeys.customFieldRequest)
          .map(({ data }) => JSON.parse(data)),
        leadCustomerRequest: record.formData.find(
          ({ dataType }) => dataType === leadKeys.customerRequest
        )
      }));
    }
  });

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) return <Loader />;
  if (error) return 'Failed to load telemetry data';

  if (!data?.length)
    return <EmptyState description="No submits history available" />;

  return <Accordion getAccordionList={() => getAccordionList(data)} />;
};

ApplicationLeadResponsesHistory.propTypes = {
  id: PT.string.isRequired
};

export default ApplicationLeadResponsesHistory;
