import React from 'react';

import InternalPerformance from '@components/Dashboard/InternalPerformance/InternalPerformance';
import { performancePageConfig } from '@components/Dashboard/InternalPerformance/config';

const DashboardWebPerformancePage = () => {
  const webConfig = performancePageConfig.web;
  return <InternalPerformance {...webConfig} />;
};

export default DashboardWebPerformancePage;
