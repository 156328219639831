import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import TextField from '../../TextField/TextField';

export const defaultFormatChars = {
  9: /[0-9]/,
  a: /[A-Za-z]/,
  '*': /[A-Za-z0-9]/
};

/*
// mind the trap, it removes everything except
// 1) escaped symbols
// 2) defaultFormatChars which satisfies regular exp)

// input: maskValue = "+1 551 515 1515"; mask = "+\1 999 999 999*"
// output: plainValue = "15515151515"
*/

const getPlainValue = (value, mask, formatChars = defaultFormatChars) => {
  if (!value) return value;

  const formatCharsKeys = Object.keys(formatChars);

  let newValue = '';
  let maskCharIdx = 0;
  let valueCharIdx = 0;

  while (maskCharIdx < mask.length) {
    const maskChar = mask[maskCharIdx];
    const valueChar = value[valueCharIdx];

    if (maskChar === '\\') {
      newValue += valueChar;
      maskCharIdx++; // skip that position
    } else {
      if (formatCharsKeys.includes(maskChar)) {
        if (!formatChars[maskChar].test(valueChar)) {
          // exit when one of chars invalid
          maskCharIdx = mask.length;
        } else {
          newValue += valueChar;
        }
      }
    }

    maskCharIdx++;
    valueCharIdx++;
  }

  return newValue;
};

const InputMasked = ({
  entireFillingRequired,
  mask,
  value,
  name,
  label,
  placeholder,
  onChange,
  onBlur,
  setError,
  invalid,
  alwaysShowMask,
  maskChar,
  plainValue,
  canBeNotCompleted,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  let maskInputValue = value;
  let handleChange = onChange;
  let handleBlur = onBlur;

  if (plainValue) {
    maskInputValue = localValue;

    handleChange = (e) => {
      const newValue = e.target.value;

      setLocalValue(newValue);
      onChange({
        target: {
          name,
          value: getPlainValue(newValue, mask, props.formatChars)
        }
      });
    };

    handleBlur = (e) => {
      const newValue = e.target.value;
      const newPlainValue = getPlainValue(newValue, mask, props.formatChars);

      onBlur({
        target: {
          name,
          value: newPlainValue
        }
      });
    };
  }

  return (
    <InputMask
      mask={mask}
      value={maskInputValue}
      onChange={handleChange}
      onBlur={(e) => {
        handleBlur(e);
        if (
          !canBeNotCompleted &&
          e.target.value.includes(maskChar) &&
          setError &&
          entireFillingRequired
        ) {
          setError('Field not completed');
        }
      }}
      alwaysShowMask={alwaysShowMask}
      maskChar={maskChar}
    >
      {() => (
        <TextField
          type="text"
          name={name}
          label={label}
          placeholder={placeholder}
          error={invalid}
          {...props}
        />
      )}
    </InputMask>
  );
};

InputMasked.defaultProps = {
  alwaysShowMask: false,
  maskChar: '_',
  plainValue: true,
  entireFillingRequired: true,
  canBeNotCompleted: false
};

InputMasked.propTypes = {
  mask: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  setError: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  alwaysShowMask: PropTypes.bool,
  label: PropTypes.string,
  formatChars: PropTypes.object,
  plainValue: PropTypes.bool,
  entireFillingRequired: PropTypes.bool,
  canBeNotCompleted: PropTypes.bool,
  maskChar: PropTypes.any
};

export default InputMasked;
