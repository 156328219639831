import React from 'react';
import PT from 'prop-types';
import ReactJson from 'react-json-view';

const JsonViewer = ({ src, ...props }) => {
  if (!src) {
    return <p className="text-center">No data</p>;
  }

  return (
    <ReactJson
      src={src}
      name={false}
      displayDataTypes={false}
      displayObjectSize={false}
      enableClipboard={false}
      displayArrayKey={false}
      {...props}
    />
  );
};

JsonViewer.propTypes = {
  src: PT.any
};

export default JsonViewer;
