import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ApplicationDetails from '@components/Application/ApplicationDetails';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import routes from '@constants/routes';
import Alert from '@components/shared/Alert/Alert';
import { adminApiUrls } from '@constants/adminApiUrls';
import AdminApi from '@utils/adminApi';

const ViewApplicationPage = () => {
  const history = useHistory();
  const params = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const loading = !data && !error;

  const fetchData = useCallback(
    async (expandValues = '') => {
      try {
        const record = await AdminApi.get(
          adminApiUrls.applications.application(params.id),
          {
            params: {
              $expand: `UserLoan, BankAccount($expand=CaToken,SaToken),LeadResponse, LoanPrincipal, CustomerAddress($expand=State), CustomerInfo($expand=DLLicenseState), CustomerEmployment($expand=PayFrequency,PayrollMethod,PayrollSource,EmploymentType), Disclosure($expand=MilitaryStatus), ${expandValues}`
            }
          }
        );

        if (!record.customerInfo) {
          history.push(
            routes.applications.page.edit.url(record.applicationUid)
          );
          return;
        }

        setData(record);
      } catch (e) {
        setError(true);
      }
    },
    [history, params.id]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <MainLayout title="Application page" isLoading={loading}>
      {error && (
        <Alert
          type="error"
          title="Server error"
          message="Failed to load data"
        />
      )}
      {!loading && !error && (
        <ApplicationDetails data={data} fetchData={fetchData} />
      )}
    </MainLayout>
  );
};
export default ViewApplicationPage;
