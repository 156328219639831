import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Alert, Box, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

import { authService } from '@services/auth';
import Loader from '@components/shared/Loader';
import routes from '@constants/routes';
import Button from '@components/shared/Button/Button';

const AccessVerificationPage = () => {
  const history = useHistory();
  const location = useLocation();

  const code = queryString.parse(location.search).code;
  const error = queryString.parse(location.search).error;

  useEffect(() => {
    if (error) return;
    if (code) {
      void authService.getAccessToken(code);
    } else {
      void history.push(routes.auth.login.path);
    }
  }, [code, error]);

  return (
    <Box display="flex" justifyContent="center" justifyItems="center">
      <Helmet>
        <title>Access verification - Payment Platform</title>
      </Helmet>
      <Box>
        {error && (
          <Stack alignItems="center">
            <Typography variant="h3">Access Verification</Typography>
            <Alert severity="error" sx={{ my: 2, width: '100%' }}>
              Server Error: {error}
            </Alert>
            <Button onClick={() => authService.redirectToAuthorize()}>
              Go to login page
            </Button>
          </Stack>
        )}
        {!error && (
          <>
            <Loader />
            <Typography variant="h3">Access Verification</Typography>
            <Typography variant="body2">
              Please, wait a moment while we verify your access...
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AccessVerificationPage;
