import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import {
  getMissingByDailyColumns,
  innerTableColumns,
  subTabsList
} from '@components/DiscrepancyReport/DiscrepancyReport.config';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import Stack from '@components/shared/Stack/Stack';
import Typography from '@components/shared/Typography/Typography';
import { secureVaultReportsApiUrls } from '@constants/secureVaultReportsApiUrls';
import SecureVaultReportsApi from '@utils/secureVaultReportsApi';
import Loader from '@components/shared/Loader';
import DataTable from '@components/shared/DataTable/DataTable';
import Alert from '@components/shared/Alert/Alert';
import Logger from '@utils/logger';
import Modal from '@components/shared/Modal/Modal';

const DiscrepancySummaryReportByDay = () => {
  const [data, setData] = useState(null);
  const [innerTableData, setInnerTableData] = useState(null);
  const [error, setError] = useState(null);
  const [modalProps, setModalProps] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const columns = getMissingByDailyColumns(setModalProps);

  const fetchTableData = async () => {
    try {
      const response = await SecureVaultReportsApi.get(
        secureVaultReportsApiUrls.discrepancyReport.summaryReportMissingByDaily
      );
      setData(response);
    } catch (e) {
      Logger.error(e);
      setError(e);
    }
  };

  const fetchInnerTableData = async (summaryDateTime) => {
    try {
      setIsLoading(true);
      const response = await SecureVaultReportsApi.get(
        modalProps.customerDetailDiscrepancy
          ? secureVaultReportsApiUrls.discrepancyReport.customerDetailsSummary(
              summaryDateTime
            )
          : secureVaultReportsApiUrls.discrepancyReport.cardDetailsSummary(
              summaryDateTime
            )
      );
      setInnerTableData(response);
    } catch (e) {
      Logger.error(e);
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (history.location) {
      fetchTableData();
    }
  }, [history.location?.search]);

  useEffect(() => {
    if (modalProps?.isOpen) {
      fetchInnerTableData(modalProps?.summaryDateTime);
    }
  }, [modalProps?.isOpen]);

  return (
    <MainLayout title="Discrepancy Report">
      <NavTabsPanel
        currentIdx={1}
        tabsList={subTabsList}
        ariaLabel="access-tabs"
      />
      <Stack sx={{ mt: 1 }}>
        <Typography variant="h3">Missing by Day</Typography>
      </Stack>
      {!data && !error && <Loader />}
      {data && (
        <DataTable
          disableColumnFilter
          columns={columns}
          tableData={data}
          rowKey="summaryDateTime"
          detailPanelHeight="auto"
          pagination={false}
          hideFooter
        />
      )}
      {error && (
        <Alert
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
        />
      )}
      <Modal
        open={modalProps?.isOpen}
        onClose={() => setModalProps(null)}
        title="Change"
        minContentWidth="900px"
        withCloseIcon
        maxHeight="700px"
        containerModalStyle={{ overflowY: 'auto' }}
      >
        <DataTable
          disableColumnFilter
          columns={innerTableColumns}
          tableData={
            modalProps?.customerDetailDiscrepancy
              ? innerTableData?.customerDetailDiscrepancy
              : innerTableData?.cardDetailDiscrepancy
          }
          rowKey="id"
          detailPanelHeight="auto"
          pagination={false}
          hideFooter
          disableRowGrouping
          isVirtualized
          loading={isLoading}
        />
      </Modal>
    </MainLayout>
  );
};

export default DiscrepancySummaryReportByDay;
