import React from 'react';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import LeadProviderDetails from '@components/Leads/leadProviders/Details';

const LeadProviderViewPage = () => (
  <MainLayout title="Lead Provider Details">
    <LeadProviderDetails />
  </MainLayout>
);

export default LeadProviderViewPage;
