import { styled } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';

import List from '@components/shared/List/List';
import { palette } from '@utils/theme';

export const StyledList = styled(List)`
  width: 100%;
  height: 230px;
  overflow: auto;
  background-color: ${palette.white};
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  & .MuiListItemIcon-root {
    min-width: 30px !important;

    & .MuiFormControl-root {
      margin: 0;
    }

    & .MuiFormControlLabel-root {
      margin-right: 0;
    }
  }
`;

export const StyledCardHeader = styled(CardHeader)`
  && {
    & .MuiCardHeader-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin-left: 10px;
    }

    & .MuiCardHeader-subheader {
      display: flex;
      align-items: center;
    }

    & .MuiCardHeader-avatar {
      margin-right: 0;

      & .MuiFormControl-root {
        margin: 0;
      }
    }
  }
`;
