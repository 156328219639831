const csvComparisonApiBaseUrl = process.env.API_CSV_COMPARISON || '';

const csvComparisonApiUrls = {
  comparisons: {
    main: '/api/comparisons',
    details: (id) => `/api/comparisons/${id}`,
    list: '/odata/comparisons',
    configs: {
      main: 'api/comparisons/configs',
      details: (id) => `api/comparisons/configs/${id}`,
      headers: '/api/comparisons/configs/headers'
    },
    results: (id) => `/api/comparisons/results/${id}`
  },
  comparisonfiles: {
    details: (id) => `/api/comparisonfiles/${id}`,
    results: {
      details: (id) => `/api/comparisonfiles/results/${id}`,
      consolidated: (id) => `/api/comparisonfiles/consolidated/${id}`
    }
  },
  configurations: {
    list: '/odata/comparisonConfigs'
  }
};

export { csvComparisonApiBaseUrl, csvComparisonApiUrls };
