import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip
} from 'chart.js';
import { Chip, MenuItem, OutlinedInput } from '@mui/material';

import useChartData, { getSelectedChartData } from './utils';

import Alert from '@components/shared/Alert/Alert';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import DashboardFilter from '@components/Dashboard/Filter';
import { chartColors as colors } from '@constants/colors';
import Loader from '@components/shared/Loader';
import Box from '@components/shared/Box/Box';
import Stack from '@components/shared/Stack/Stack';
import { StyledSelect } from '@components/shared/Form/Select/styled';
import Button from '@components/shared/Button/Button';
import { Checkbox } from '@components/shared/Form/Checkbox';

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip
);

const DashboardApiAndAppErrors = () => {
  const chartRef = useRef(null);
  const {
    chartData,
    maxYValue,
    error,
    selectHandler,
    getExtendedTooltip,
    selectOptions,
    selectedKeys,
    handleLabelShow,
    reset
  } = useChartData();

  const limitedSelectedKeys = selectedKeys.slice(0, 10);

  const graphOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: maxYValue,
        title: {
          display: true,
          text: 'Errors amount %'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Date'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          footer: getExtendedTooltip
        }
      }
    }
  };

  return (
    <MainLayout title="Error Response Rate">
      <DashboardFilter />
      {!chartData?.length && !error && <Loader />}
      {chartData?.length && (
        <>
          <Box
            my={1}
            sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}
          >
            <StyledSelect
              multiple
              value={selectedKeys}
              onChange={selectHandler}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value, id) => {
                    const label = selectOptions.find(
                      (option) => option.value == value
                    )?.label;
                    if (id >= 10) {
                      return;
                    }
                    return (
                      <Chip
                        key={value}
                        label={label}
                        size="small"
                        sx={{ color: '#fff', backgroundColor: colors[id] }}
                      />
                    );
                  })}
                </Box>
              )}
              fullWidth
              sx={{
                paddingTop: '5px !important',
                paddingBottom: '5px !important'
              }}
            >
              {selectOptions.map(({ label, value }) => (
                <MenuItem key={label + value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </StyledSelect>
            <Button onClick={reset}>Reset</Button>
          </Box>
          <div style={{ height: 'calc(100vh - 280px)' }}>
            <Line
              ref={chartRef}
              data={{
                datasets: getSelectedChartData(chartData, limitedSelectedKeys)
              }}
              options={graphOptions}
            />
          </div>
          <Stack align="center">
            <Box>
              {selectedKeys?.map((item, index) => {
                if (index >= 10) {
                  return;
                }
                return (
                  <span key={item}>
                    <Checkbox
                      onChange={(e) => handleLabelShow(e, item)}
                      checked={!chartData[item].hidden}
                      color={colors[index]}
                      label={chartData[item].label}
                      name={chartData[item].label}
                    />
                  </span>
                );
              })}
            </Box>
          </Stack>
        </>
      )}
      {error && (
        <Alert
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
        />
      )}
    </MainLayout>
  );
};

export default DashboardApiAndAppErrors;
