import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import Alert from '@components/shared/Alert/Alert';
import { campaignGroupSchema } from '@components/Leads/campaignGroups/Form/schema';
import routes from '@constants/routes';
import Loader from '@components/shared/Loader';
import toastify from '@utils/toastify';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Button from '@components/shared/Button/Button';
import { PersonIcon } from '@components/shared/icons/Icons';
import Stack from '@components/shared/Stack/Stack';
import Box from '@components/shared/Box/Box';
import CheckList from '@components/shared/CheckList/CheckList';
import ConfirmDeleteButton from '@components/shared/ConfirmDeleteButton/ConfirmDeleteButton';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

const getInitialValues = (data) => ({
  name: '',
  dailyLookCap: '',
  hourlyLookCap: '',
  dailyAcceptCap: '',
  hourlyAcceptCap: '',
  dailyCostCap: '',
  ...data
});

const CampaignGroupForm = ({ onSubmit, data, editMode }) => {
  const history = useHistory();
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsTargetKeys, setCampaignsTargetKeys] = useState([]);

  useEffect(() => {
    (async () => {
      const allCampaigns = await LeadManagementApi.get(
        leadManagementApiUrls.campaigns.list,
        {
          params: {
            $expand: 'CampaignInGroups'
          }
        }
      );

      if (editMode) {
        const campaignsKeysInGroup = allCampaigns.value
          .map((item) => ({
            ...item,
            key: item.campaignId,
            name: item.campaignName
          }))
          .filter((item) =>
            item.campaignInGroups.find(
              (elem) => elem.campaignGroupId === data.campaignGroupId
            )
          )
          .map((item) => item.campaignId);

        setCampaignsTargetKeys(campaignsKeysInGroup);
      }

      setCampaigns(
        allCampaigns.value.map((item) => ({
          ...item,
          key: item.campaignId,
          name: item.campaignName
        }))
      );
    })();
  }, []);

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);

    const result = { ...values };

    result.campaigns = campaignsTargetKeys;

    if (editMode) {
      result.campaignId = data.campaignId;
    }

    onSubmit(
      { values: result, actions },
      {
        onFinish: () => {
          actions.setSubmitting(false);
        }
      }
    );
  };

  const handleDelete = async () => {
    try {
      await LeadManagementApi.delete(
        leadManagementApiUrls.campaignGroups.page(data.campaignGroupId)
      );

      history.push(routes.leads.campaignGroups.list);

      toastify.success({
        message: 'Group deleted'
      });
    } catch (e) {
      toastify.error({
        message: 'Delete operation failure'
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={getInitialValues(data)}
        onSubmit={handleSubmit}
        validationSchema={campaignGroupSchema}
      >
        {({ errors }) => (
          <Form>
            <Box sx={{ width: '50%', minWidth: '500px', margin: '10px auto' }}>
              <FormikField
                type="text"
                name="name"
                label="Name"
                placeholder="Name"
                showValidateSuccess
              />
              <FormikField
                type="number"
                name="hourlyLookCap"
                label="Hr look cap:"
                placeholder="Hr look cap"
              />
              <FormikField
                type="number"
                name="dailyLookCap"
                label="Day Look cap:"
                placeholder="Day Look cap"
              />
              <FormikField
                type="number"
                name="dailyAcceptCap"
                label="Day accept cap:"
                placeholder="Day accept cap"
              />
              <FormikField
                type="number"
                name="hourlyAcceptCap"
                label="Hr accept cap:"
                placeholder="Hr accept cap"
              />
              <FormikField
                type="number"
                name="dailyCostCap"
                label="Day cost cap:"
                placeholder="Day cost cap"
              />

              {campaigns?.length ? (
                <CheckList
                  list={campaigns}
                  title="Campaigns"
                  selectedKeys={campaignsTargetKeys}
                  setSelected={setCampaignsTargetKeys}
                  listName="participants"
                  icon={<PersonIcon size={18} />}
                />
              ) : (
                <Loader />
              )}
              {errors.nonFieldErrors && (
                <Alert message={errors.nonFieldErrors} type="error" />
              )}
              <Stack direction="row" gap={2}>
                <Button type="submit">Save</Button>
                {editMode && (
                  <ConfirmDeleteButton
                    itemName="group"
                    handleDelete={handleDelete}
                  />
                )}
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

CampaignGroupForm.defaultProps = {
  data: null,
  editMode: false
};

CampaignGroupForm.propTypes = {
  onSubmit: PT.func,
  data: PT.object,
  editMode: PT.bool
};

export default CampaignGroupForm;
