import React from 'react';
import PT from 'prop-types';
import pluralize from 'pluralize';

import Accordion from '@components/shared/Accordion/Accordion';
import { militaryStatusesDescriptions } from '@constants/militaryVariants';
import helpers from '@utils/helpers';
import { dateFormat, noFieldData } from '@constants/common';
import { Checkbox } from '@components/shared/Form/Checkbox';

const ApplicationDetailsTab = ({ data }) => {
  const renderTimeAtByMonthsAmount = (totalMonths) => {
    if (!totalMonths) return noFieldData;

    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    return `${pluralize('year', years, true)} ${pluralize(
      'month',
      months,
      true
    )}`;
  };

  const accordionList = [
    {
      key: '1',
      header: 'Customer personal information',
      content: data?.customerInfo ? (
        <ul className="application__values-list text-body-m">
          <li className="application__values-list-item">
            <div className="application__value-name">First name:</div>
            <div className="application__value">
              {data.customerInfo.firstName || noFieldData}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">Last name:</div>
            <div className="application__value">
              {data.customerInfo.lastName || noFieldData}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">Date of birth:</div>
            <div className="application__value">
              {data.customerInfo.dateOfBirth
                ? helpers.getFormattedDate(
                    data.customerInfo.dateOfBirth,
                    dateFormat
                  )
                : noFieldData}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">Email:</div>
            <div className="application__value">
              <a href={`mailto:${data.customerInfo.email}`}>
                {data.customerInfo.email}
              </a>
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">Phone number:</div>
            <div className="application__value">
              <a href={`tel:${data.customerInfo.phone}`}>
                {data.customerInfo.phone}
              </a>
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">
              Social security number(last 4):
            </div>
            <div className="application__value">
              {data.customerInfo.ssnShort}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">DL number / id:</div>
            <div className="application__value">
              {data.customerInfo.dlLicenseNumber}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">DL state:</div>
            <div className="application__value">
              {data.customerInfo.dlLicenseState?.displayName || noFieldData}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">Promocode:</div>
            <div className="application__value">
              {data.customerInfo.promo || noFieldData}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">
              Allowed communication by email:
            </div>
            <div className="application__value">
              <Checkbox
                checked={data.customerInfo.emailCommunication}
                wrapperProps={{ sx: { margin: 0 } }}
                disabled
              />
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">
              Allowed communication by text:
            </div>
            <div className="application__value">
              <Checkbox
                checked={data.customerInfo.phoneCommunication}
                wrapperProps={{ sx: { margin: 0 } }}
                disabled
              />
            </div>
          </li>
        </ul>
      ) : (
        'No data'
      )
    },
    {
      key: '2',
      header: 'Customer address information',
      content: data?.customerAddress ? (
        <ul className="application__values-list text-body-m">
          <li className="application__values-list-item">
            <div className="application__value-name">Address 1:</div>
            <div className="application__value">
              {data.customerAddress.address1}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">Address 2:</div>
            <div className="application__value">
              {data.customerAddress.address2 || noFieldData}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">City:</div>
            <div className="application__value">
              {data.customerAddress.city}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">State:</div>
            <div className="application__value">
              {data.customerAddress.state?.displayName}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">Zip code:</div>
            <div className="application__value">{data.customerAddress.zip}</div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">Time at address:</div>
            <div className="application__value">
              {renderTimeAtByMonthsAmount(
                data?.customerAddress?.timeAtAddressMonths
              )}
            </div>
          </li>
        </ul>
      ) : (
        'No content'
      )
    },
    {
      key: '3',
      header: 'Customer employment information',
      content: data?.customerEmployment ? (
        <div className="loan-form__fields-block">
          <ul className="application__values-list text-body-m">
            <li className="application__values-list-item">
              <div className="application__value-name">Net pay per check:</div>
              <div className="application__value">
                {data.customerEmployment.netPayPerPayroll}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">Employment type:</div>
              <div className="application__value">
                {data.customerEmployment.employmentType?.displayName}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">Pay frequency:</div>
              <div className="application__value">
                {data.customerEmployment.payFrequency?.displayName}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">Payroll method:</div>
              <div className="application__value">
                {data.customerEmployment.payrollMethod?.displayName}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">Payroll source:</div>
              <div className="application__value">
                {data.customerEmployment.payrollSource?.displayName}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">Day of hire:</div>
              <div className="application__value">
                {data.customerEmployment.dateOfHire
                  ? helpers.getFormattedDate(
                      data.customerEmployment.dateOfHire,
                      dateFormat
                    )
                  : noFieldData}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">Next payday:</div>
              <div className="application__value">
                {data.customerEmployment.nextPayday
                  ? helpers.getFormattedDate(
                      data.customerEmployment.nextPayday,
                      dateFormat
                    )
                  : noFieldData}
              </div>
            </li>
            <br />
            <li className="application__values-list-item">
              <div className="application__value-name">Employer Name:</div>
              <div className="application__value">
                {data.customerEmployment.employerName || noFieldData}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">Employer Phone:</div>
              <div className="application__value">
                {data.customerEmployment.employerPhone || noFieldData}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">
                Employer Phone Extension:
              </div>
              <div className="application__value">
                {data.customerEmployment.employerPhoneExtension || noFieldData}
              </div>
            </li>
          </ul>
        </div>
      ) : (
        'No content'
      )
    },
    {
      key: '4',
      header: 'Customer bank information',
      content: data?.bankAccount ? (
        <div className="loan-form__fields-block">
          <ul className="application__values-list text-body-m">
            <li className="application__values-list-item">
              <div className="application__value-name">Bank name:</div>
              <div className="application__value">
                {data.bankAccount.bankName}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">Routing number:</div>
              <div className="application__value">
                {data.bankAccount.bankRoutingNumber}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">
                Checking account number:
              </div>
              <div className="application__value">
                {data.bankAccount.checkingAccount}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">
                Checking account balance:
              </div>
              <div className="application__value">
                {data.bankAccount.checkingAccountBalance}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">
                Savings account number:
              </div>
              <div className="application__value">
                {data.bankAccount.savingsAccount || noFieldData}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">
                Savings account balance:
              </div>
              <div className="application__value">
                {data.bankAccount.savingsAccountBalance || noFieldData}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">Time at bank:</div>
              <div className="application__value">
                {renderTimeAtByMonthsAmount(
                  data.bankAccount.timeAccountOpenMonths
                )}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">Bank phone:</div>
              <div className="application__value">
                {data.bankAccount.bankPhone || noFieldData}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">Have a debit card:</div>
              <div className="application__value">
                {helpers.renderBooleanValue(data.bankAccount.hasDc)}
              </div>
            </li>
            <li className="application__values-list-item">
              <div className="application__value-name">Bankruptcy Status</div>
              <div className="application__value">
                {helpers.renderBooleanValue(data.disclosure?.bankruptcyStatus)}
              </div>
            </li>
          </ul>
        </div>
      ) : (
        'No content'
      )
    },
    {
      key: '5',
      header: 'Customer disclosure information',
      content: data?.disclosure ? (
        <ul className="application__values-list text-body-m">
          <li className="application__values-list-item">
            <div className="application__value-name">Military status:</div>
            <div className="application__value">
              {
                militaryStatusesDescriptions[
                  data.disclosure?.militaryStatus?.displayName
                ]
              }
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">
              Do not Send me Promotional email:
            </div>
            <div className="application__value">
              {helpers.renderBooleanValue(data.disclosure.marketingOptIn)}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">
              I have read and agree with <b>Privacy policy</b>:
            </div>
            <div className="application__value">
              {helpers.renderBooleanValue(data.disclosure.privacyPolicy)}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">
              I have read and agree with <b>US Patriot Act Disclosure</b>:
            </div>
            <div className="application__value">
              {helpers.renderBooleanValue(data.disclosure.usPatriotAct)}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">
              I have read and I agree with{' '}
              <b>Electronic Disclosure Statement</b>:
            </div>
            <div className="application__value">
              {helpers.renderBooleanValue(data.disclosure.electronicDisclosure)}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">
              I understand that eLoanWarehouse reserves the right to do a
              background/credit check at any time using any of the consumer
              reporting agencies including but not limited to Clarity, CL
              Verify, Factor Trust.
            </div>
            <div className="application__value">
              {helpers.renderBooleanValue(
                data.disclosure.backgroundCheckDisclosure
              )}
            </div>
          </li>
          <li className="application__values-list-item">
            <div className="application__value-name">
              You agree that this electronic signature has the full force and
              effect of your physical signature and that it binds you in the
              same manner a physical signature would do so.
            </div>
            <div className="application__value">
              {helpers.renderBooleanValue(
                data.disclosure.otherPaydayOrShortTermInstallmentLoans
              )}
            </div>
          </li>
        </ul>
      ) : (
        'No content'
      )
    }
  ];

  const expandedByDefault = accordionList.map(({ key }) => key);

  return (
    <Accordion
      accordionList={accordionList}
      expandedByDefault={expandedByDefault}
    />
  );
};

ApplicationDetailsTab.propTypes = {
  data: PT.object.isRequired,
  fetchChangedData: PT.func.isRequired
};

export default ApplicationDetailsTab;
