import React from 'react';
import startCase from 'lodash/startCase';

import { cleanObject } from '@utils/object-cleaner';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import helpers from '@utils/helpers';
import {
  filterOperators,
  noFieldData,
  permissionsMap,
  renderCommonCell
} from '@constants/common';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import DeleteActionButton from '@components/shared/DataTable/actions/DeleteActionButton';
import BINCheckerApi from '@utils/binCheckerAPI';
import { binCheckerApiUrls } from '@constants/binCheckerApiUrls';
import toastify from '@utils/toastify';

export const initialFilterParams = cleanObject({});
const missingBinNumberText = 'No BIN numbers available.';

export const getColumns = ({
  handleDeleteBINNumber,
  setDataForEdit,
  setIsShowModal,
  filterOptions
}) => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    filterable: false,
    align: 'center',
    renderCell: ({ row }) => (
      <>
        <EditActionButton
          onClick={() => {
            setIsShowModal({ edit: true });
            setDataForEdit(row);
          }}
          hidden={!helpers.getActionPermission(permissionsMap.binManage)}
        />
        <DeleteActionButton
          content="Are you sure you want to delete this BIN number?"
          onConfirm={() => handleDeleteBINNumber(row)}
          hidden={!helpers.getActionPermission(permissionsMap.binManage)}
        />
      </>
    )
  },
  {
    headerName: 'BIN number',
    field: 'binNumber',
    width: 100,
    renderCell: ({ row, field }) => row[field] || missingBinNumberText
  },
  {
    headerName: 'Bank name',
    field: 'bankName',
    width: 150,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Brand name',
    field: 'brandId',
    width: 100,
    type: muiDataTableTypes.singleSelect,
    valueOptions: filterOptions?.brandOptions || [],
    renderCell: renderCommonCell
  },
  {
    headerName: 'Card Type',
    field: 'typeId',
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Currency',
    field: 'currencyId',
    width: 100,
    type: muiDataTableTypes.singleSelect,
    valueOptions: filterOptions?.currencyOptions || [],
    renderCell: renderCommonCell
  },
  {
    headerName: 'Country',
    field: 'countryId',
    width: 100,
    type: muiDataTableTypes.singleSelect,
    valueOptions: filterOptions?.countryOptions || [],
    renderCell: ({ row, field }) => startCase(row[field]) || noFieldData
  },
  {
    headerName: 'Is Bank Name Translation Needed',
    field: 'isBankTranslationNeeded',
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: ({ row, field }) => (row[field] ? 'Yes' : 'No')
  },
  {
    headerName: 'Bank Name Translation',
    field: 'bankTranslation',
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Used count',
    field: '',
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: () => 'TBD'
  },
  {
    headerName: 'Created date',
    field: 'createdDate',
    width: 170,
    type: muiDataTableTypes.dateRange,
    renderCell: ({ row }) => helpers.getFormattedDate(row?.createdDate),
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Last updated date',
    field: 'updatedDate',
    width: 170,
    type: muiDataTableTypes.dateRange,
    renderCell: ({ row }) => helpers.getFormattedDate(row?.createdDate),
    filterOperators: [filterOperators.dateRange]
  }
];

export const getBINNumberListOptions = async () => {
  try {
    const result = await Promise.allSettled([
      BINCheckerApi.get(binCheckerApiUrls.options.ccBrands),
      BINCheckerApi.get(binCheckerApiUrls.options.ccTypes),
      BINCheckerApi.get(binCheckerApiUrls.options.ccCurrencies),
      BINCheckerApi.get(binCheckerApiUrls.options.ccCountries)
    ]);

    let hasError = false;

    const [brandOptions, cardTypeOptions, currencyOptions, countryOptions] =
      result.map((item) => {
        if (item.status === 'fulfilled' && Array.isArray(item.value)) {
          return item.value.map((i) => ({ label: i.name, value: i.name }));
        } else {
          hasError = true;
          return [];
        }
      });

    if (hasError) {
      toastify.error({
        message: 'Failed to fetch BIN number list options'
      });
    }

    return {
      brandOptions,
      cardTypeOptions,
      currencyOptions,
      countryOptions
    };
  } catch (error) {
    toastify.error({
      message: 'Failed to fetch BIN number list options'
    });
  }
};

export const addBINNumberFormInitialValues = {
  binNumber: '',
  bankName: '',
  brandId: '',
  typeId: '',
  currencyId: '',
  countryId: '',
  isBankTranslationNeeded: false,
  bankTranslation: ''
};

export const editBINNumberFormInitialValues = (dataForEdit) => ({
  binNumber: dataForEdit.binNumber || '',
  bankName: dataForEdit.bankName || '',
  brandId: dataForEdit.brandId || '',
  typeId: dataForEdit.typeId || '',
  currencyId: dataForEdit.currencyId || '',
  countryId: dataForEdit.countryId || '',
  isBankTranslationNeeded: dataForEdit.isBankTranslationNeeded || false,
  bankTranslation: dataForEdit.bankTranslation || ''
});
