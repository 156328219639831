export const telemetryPagesOptions = [
  'Customer',
  'Address',
  'Banking',
  'Employee',
  'Disclosures'
];

export const fields = [
  {
    value: 'amount',
    label: 'Loan amount'
  },
  {
    value: 'firstName',
    label: 'First name'
  },
  {
    value: 'lastName',
    label: 'Last name'
  },
  {
    value: 'dateOfBirth',
    label: 'Date of birth'
  },
  {
    value: 'email',
    label: 'Email'
  },
  {
    value: 'confirmEmail',
    label: 'Confirm email'
  },
  {
    value: 'emailCommunication',
    label: 'Email communication'
  },
  {
    value: 'phone',
    label: 'Phone'
  },
  {
    value: 'phoneCommunication',
    label: 'Phone communication'
  },
  {
    value: 'ssn',
    label: 'SSN'
  },
  {
    value: 'dlLicenseNumber',
    label: 'DL number'
  },
  {
    value: 'dlLicenseState',
    label: 'DL state'
  },
  {
    value: 'promo',
    label: 'Promo'
  },
  {
    value: 'address1',
    label: 'Address 1'
  },
  {
    value: 'address2',
    label: 'Address 2'
  },
  {
    value: 'city',
    label: 'City'
  },
  {
    value: 'state',
    label: 'State'
  },
  {
    value: 'zip',
    label: 'Zip code'
  },
  {
    value: 'timeAtAddressMonths',
    label: 'Months at address'
  },
  {
    value: 'timeAtAddressYears',
    label: 'Years at address'
  },
  {
    value: 'netPayPerPayroll',
    label: 'Net pay per check'
  },
  {
    value: 'employmentType',
    label: 'Employment type'
  },
  {
    value: 'payFrequency',
    label: 'Pay frequency'
  },
  {
    value: 'payrollMethod',
    label: 'Payroll method'
  },
  {
    value: 'payrollSource',
    label: 'Payroll source'
  },
  {
    value: 'dateOfHire',
    label: 'Day of hire'
  },
  {
    value: 'nextPayday',
    label: 'Next Payday'
  },
  {
    value: 'employerName',
    label: 'Employer Name'
  },
  {
    value: 'employerPhone',
    label: 'Employer Phone'
  },
  {
    value: 'employerPhoneExtension',
    label: 'Employer Phone Extension'
  },
  { value: 'bankName', label: 'Bank name' },
  { value: 'bankRoutingNumber', label: 'Bank routing number' },
  { value: 'checkingAccount', label: 'Checking account' },
  { value: 'savingsAccount', label: 'Savings account' },
  { value: 'hasDC', label: 'Have a debit card' },
  { value: 'bankPhone', label: 'Bank phone' },
  { value: 'checkingAccountBalance', label: 'Checking account balance' },
  { value: 'savingsAccountBalance', label: 'Savings account balance' },
  { value: 'timeAccountOpenYears', label: 'Years at bank' },
  { value: 'timeAccountOpenMonths', label: 'Months at bank' },
  { value: 'militaryStatus', label: 'Military status' },
  { value: 'marketingOptIn', label: 'Do not Send me Promotional email' },
  { value: 'privacyPolicy', label: 'Privacy policy' },
  { value: 'electronicDisclosure', label: 'Electronic Disclosure' },
  { value: 'backgroundCheckDisclosure', label: 'Background check' },
  { value: 'bankruptcyStatus', label: 'Bankruptcy status' }
];
