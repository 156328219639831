import React, { useEffect } from 'react';
import PT from 'prop-types';
import { Form, Formik } from 'formik';
import isFunction from 'lodash/isFunction';

import FormError from '@components/shared/Form/Error';
import SchemaProvider from '@components/shared/Form/SchemaProvider';
import { formHasErrorsMessage } from '@constants/common';
import LoanAmountBlock from '@components/Application/LoanForm/LoanAmountBlock';
import LoanFormControls from '@components/Application/LoanForm/LoanFormControls';
import Typography from '@components/shared/Typography/Typography';

const StepContainer = ({
  title,
  onSubmit,
  children,
  initialValues,
  formSchema,
  validate
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur
      initialTouched={initialValues}
      validateOnMount={true}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={formSchema}
      validate={validate}
      schemaTest={formSchema}
    >
      {({ values, errors, isSubmitting, submitCount, isValid }) => (
        <SchemaProvider schema={formSchema}>
          <Form mt={2}>
            <LoanAmountBlock name="amount" />
            {title && (
              <Typography variant="h4" my={2}>
                {title}
              </Typography>
            )}
            {isFunction(children) ? children({ values }) : children}
            <FormError
              bold
              error={
                errors.nonFieldErrors ||
                (!!submitCount && !isValid && formHasErrorsMessage)
              }
            />
            <LoanFormControls isSubmitting={isSubmitting} />
          </Form>
        </SchemaProvider>
      )}
    </Formik>
  );
};

StepContainer.propTypes = {
  title: PT.string,
  children: PT.oneOfType([PT.arrayOf(PT.element), PT.func]).isRequired,
  onSubmit: PT.func.isRequired,
  onStepBack: PT.func,
  initialValues: PT.shape({}).isRequired,
  formSchema: PT.shape({}).isRequired,
  validate: PT.func,
  activeStep: PT.string,
  lastFilledStep: PT.string
};

export default StepContainer;
