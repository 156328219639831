import { styled } from '@mui/material/styles';

import AppBar from '@components/shared/AppBar/AppBar';
import Menu from '@components/shared/Menu/Menu';
import MenuItem from '@components/shared/Menu/MenuItem';
import { palette } from '@utils/theme';

export const StyledAppBar = styled(AppBar)(() => ({
  backgroundColor: '#1a1a1a',
  paddingLeft: '24px',
  height: '45px'
}));

export const StyledMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    marginTop: '15px',
    backgroundColor: '#1a1a1a',
    overflow: 'visible',
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      backgroundColor: '#1a1a1a',
      zIndex: 0
    }
  }
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  fontSize: '14px',
  color: palette.primary
}));
