import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@components/shared/Box/Box';
import Typography from '@components/shared/Typography/Typography';
import { palette } from '@utils/theme';
import Button from '@components/shared/Button/Button';
import routes from '@constants/routes';

const Page404 = () => {
  const history = useHistory();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      flexDirection="column"
    >
      <Typography
        fontWeight="600"
        lineHeight="1em"
        fontSize="140px"
        color={palette.tableHoverColor}
      >
        404
      </Typography>
      <Typography variant="h1" color={palette.tableHoverColor}>
        Page not found
      </Typography>
      <Box my={4}>
        <Button onClick={() => history.push(routes.home)} size="large">
          Go to homepage
        </Button>
      </Box>
    </Box>
  );
};
export default Page404;
