import React from 'react';

import { FormSelect } from '@components/shared/Form/Select/Select';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import { cleanObject } from '@utils/object-cleaner';

export const initialFilterParams = cleanObject({});
const selectWidth = 250;

export const getFilledColumns =
  ({ declineActionsList, handleChange }) =>
  () => [
    {
      headerName: 'Decline Reason Name',
      field: 'declineReasonName',
      flex: 1,
      type: muiDataTableTypes.string
    },
    {
      headerName: 'Payment Service Name',
      field: 'paymentServiceName',
      flex: 1,
      type: muiDataTableTypes.string
    },
    {
      headerName: 'Number Of Declines',
      field: 'numberOfDeclines',
      flex: 1,
      type: muiDataTableTypes.number
    },
    {
      headerName: 'Action',
      field: 'action',
      minWidth: selectWidth,
      type: muiDataTableTypes.number,
      renderCell: ({ row }) => (
        <FormSelect
          options={declineActionsList}
          value={row.action}
          label={row.action.replace(/([A-Z])/g, ' $1')}
          onChange={(e) => handleChange(e.target.value, row.declineReasonId)}
          sx={{ width: selectWidth }}
        />
      )
    }
  ];
