import dayjs from 'dayjs';
import * as Yup from 'yup';

import { dateHoursFormat } from '@constants/common';

const getMaxDate = () => dayjs().endOf('hour');

const getMaxDateErrorText = () =>
  `Date field must be at earlier than ${dayjs()
    .add(1, 'hour')
    .format(dateHoursFormat)}`;

export const leadDayHourFilterSeparatedSchema = Yup.object().shape({
  date: Yup.date()
    .required()
    .label('Date')
    .test('max_date', getMaxDateErrorText, function (value) {
      if (getMaxDate().isBefore(value)) {
        return false;
      } else {
        return true;
      }
    }),
  time: Yup.date().required().label('Time')
});
