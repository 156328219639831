import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import qs from 'query-string';
import { useSelector } from 'react-redux';

import { columns } from './config';

import { useQueryPagination } from '@hooks/useQueryPagination';
import { useQueryParams } from '@hooks/useQueryParams';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import TelemetryDataTable from '@components/TelemetryDataTable';
import DashboardFilter from '@components/Dashboard/Filter';
import { selectDashboardFilters } from '@store/dashboardFilters';
import DataTable from '@components/shared/DataTable/DataTable';
import Typography from '@components/shared/Typography/Typography';
import Loader from '@components/shared/Loader';
import Box from '@components/shared/Box/Box';
import { adminApiUrls } from '@constants/adminApiUrls';
import AdminApi from '@utils/adminApi';

const defaultFilterParams = {
  take: 25,
  offset: 0
};

const DashboardApplicationErrorDetailsPage = () => {
  const dashboardFilters = useSelector(selectDashboardFilters);
  const history = useHistory();
  const params = useQueryParams();
  const { page, field } = useParams();
  const { list, isLoading, count } = useQueryPagination({
    listUrl: adminApiUrls.applicationsTelemetry,
    initialFilters: {
      ...defaultFilterParams,
      ...dashboardFilters,
      page: page,
      field: field,
      orderBy: 'applicationId desc'
    },
    customApi: AdminApi
  });

  const setParams = (queryParams) => {
    history.push({
      search: qs.stringify(queryParams, {
        skipNull: true,
        skipEmptyString: true
      })
    });
  };

  const handleSortingChange = (sortData) => {
    const orderBy = sortData.reduce(
      (acc, { field, sort }) => `${field} ${sort}`,
      ''
    );

    if (orderBy) {
      setParams({
        ...params,
        ...qs.parse(history.location.search),
        orderBy: `${orderBy}`
      });
    } else {
      setParams({
        ...params,
        ...qs.parse(history.location.search),
        orderBy: 'applicationId desc'
      });
    }
  };

  const handleTableChange = (pagination, sortData) => {
    if (sortData) {
      handleSortingChange(sortData);
      return;
    }

    setParams({
      ...params,
      ...qs.parse(history.location.search),
      take: pagination.pageSize,
      offset: pagination.pageSize * (pagination.current - 1)
    });
  };

  const expandedByDefault = useMemo(
    () => list.map(({ applicationId }) => applicationId),
    [list]
  );

  return (
    <MainLayout title="Applications telemetry">
      <DashboardFilter />
      <Typography variant="h3">
        Page: {page}, Field: {field}
      </Typography>
      {!isLoading ? (
        <DataTable
          disableColumnFilter
          columns={columns}
          tableData={list}
          rowKey="applicationId"
          loading={isLoading}
          getDetailPanelContent={({ row }) => (
            <Box mx={2}>
              <TelemetryDataTable data={row.pulses} />
            </Box>
          )}
          expandedByDefault={expandedByDefault}
          detailPanelHeight="auto"
          onSortingChange={(sortData) => handleTableChange(null, sortData)}
          setOrder={handleTableChange}
          refetchList={setParams}
          totalAmount={count}
          paginationOptions={{
            additionalPageSize: defaultFilterParams.take,
            pageSize: +params.take,
            current: params.offset
              ? Math.ceil(params.offset / params.take) + 1
              : 1
          }}
        />
      ) : (
        <Loader />
      )}
    </MainLayout>
  );
};

export default DashboardApplicationErrorDetailsPage;
