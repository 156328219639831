import { cleanObject } from '@utils/object-cleaner';

export const initialFilterParams = cleanObject({
  name: {
    contains: ''
  },
  createdDate: {
    ge: '',
    le: ''
  },
  updatedDate: {
    ge: '',
    le: ''
  }
});
