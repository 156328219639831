import { Formik } from 'formik';
import PT from 'prop-types';
import React from 'react';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import Button from '../Button/Button';
import Divider from '../Divider/Divider';
import IconButton from '../IconButton/IconButton';
import { modalLoading } from '../Modal/Modal';
import Stack from '../Stack/Stack';
import Typography from '../Typography/Typography';

import { StyledDrawer } from './styled';

const FormikWrapper = ({ children, getIsSubmitDisabled, ...props }) => (
  <Formik {...props}>
    {(formik) => children({ ...formik, getIsSubmitDisabled })}
  </Formik>
);
const formikProps = {
  initialValues: PT.object,
  validationSchema: PT.object,
  onSubmit: PT.func
};
FormikWrapper.propTypes = {
  ...formikProps,
  children: PT.func.isRequired
};

const EmptyWrapper = ({ children }) => children();
EmptyWrapper.propTypes = {
  children: PT.func.isRequired
};

const Drawer = ({
  children,
  onClose,
  anchor,
  title,
  subTitle,
  loading,
  submitButtonTitle,
  formikProps,
  ...props
}) => {
  const withFormik = !!formikProps;
  const ContentWrapper = withFormik ? FormikWrapper : EmptyWrapper;
  return (
    <StyledDrawer onClose={onClose} anchor={anchor} {...props}>
      {loading ? (
        modalLoading
      ) : (
        <ContentWrapper {...formikProps}>
          {(formik) => (
            <>
              <Stack
                direction={anchor === 'right' ? 'row' : 'row-reverse'}
                alignItems="center"
                justifyContent="space-between"
              >
                <IconButton onClick={onClose}>
                  <KeyboardDoubleArrowLeftIcon
                    sx={{
                      transform: anchor === 'right' ? 'rotate(180deg)' : ''
                    }}
                  />
                </IconButton>
                {withFormik && (
                  <Button
                    disabled={
                      !formik.isValid ||
                      !formik.dirty ||
                      formik.isSubmitting ||
                      formik.getIsSubmitDisabled?.(formik)
                    }
                    loading={formik.isSubmitting}
                    onClick={formik.handleSubmit}
                  >
                    {submitButtonTitle}
                  </Button>
                )}
              </Stack>
              <Divider sx={{ mt: 1, mb: 2 }} />
              {title && (
                <Typography mb={2} variant="h2">
                  {title}
                </Typography>
              )}
              {subTitle && (
                <Typography variant="body2" mb={2}>
                  {subTitle}
                </Typography>
              )}
              {withFormik ? children(formik) : children}
            </>
          )}
        </ContentWrapper>
      )}
    </StyledDrawer>
  );
};

Drawer.defaultProps = {
  anchor: 'left',
  submitButtonTitle: 'Save changes'
};
Drawer.propTypes = {
  children: PT.node.isRequired,
  onClose: PT.func.isRequired,
  anchor: PT.oneOf(['left', 'right', 'top', 'bottom']),
  title: PT.string,
  subTitle: PT.string,
  loading: PT.bool,
  submitButtonTitle: PT.string,
  formikProps: PT.shape(formikProps)
};

export default Drawer;
