import { Form, Formik } from 'formik';
import PT from 'prop-types';
import React, { useState } from 'react';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';

import { editPlannedExecutionTimeSchema } from '../config';

import Modal from '@components/shared/Modal/Modal';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Button from '@components/shared/Button/Button';
import { dateFormat, shortTimeFormat, timeFormat } from '@constants/common';
import { fieldWidth } from '@constants/filters';

const EditPlannedExecutionTimeModal = ({
  isOpen,
  handleClose,
  handleSubmit,
  modalParams
}) => {
  const [isLoading] = useState(false);

  const initialValues = {
    date: dayjs(modalParams.dateToEdit).format(dateFormat),
    time: dayjs()
  };

  const onSubmit = (values) => {
    const timeString = values.time.format(timeFormat);

    const date = dayjs(`${values.date} ${timeString}`).toISOString();
    handleSubmit(date);
  };

  return (
    <Modal
      title={`Edit planned date and time of execution for ${modalParams.fileNameToEdit}`}
      open={isOpen}
      onClose={handleClose}
      minContentWidth={800}
      withCloseIcon
    >
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={editPlannedExecutionTimeSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ isValid }) => (
            <Form>
              <Stack direction="row" gap={1} alignItems="center">
                <FormikField
                  type="date"
                  name="date"
                  label="Date"
                  size="small"
                  format={dateFormat}
                  sx={fieldWidth}
                  disablePast
                />
                <FormikField
                  type="time"
                  name="time"
                  label="Time"
                  format={shortTimeFormat.front}
                />
                <Button
                  type="submit"
                  disabled={!isValid || isLoading}
                  loading={isLoading}
                >
                  Apply
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </>
    </Modal>
  );
};

EditPlannedExecutionTimeModal.propTypes = {
  isOpen: PT.bool.isRequired,
  handleClose: PT.func.isRequired,
  handleSubmit: PT.func.isRequired,
  modalParams: PT.object
};

export default EditPlannedExecutionTimeModal;
