import React, { useEffect } from 'react';

import { authService } from '@services/auth';
import Loader from '@components/shared/Loader';

function Login() {
  useEffect(() => {
    authService.redirectToAuthorize();
  }, []);

  return <Loader />;
}

export default Login;
