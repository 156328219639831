import { useLayoutEffect, useRef } from 'react';

const useFirstRenderFlag = () => {
  const firstRender = useRef(true);

  useLayoutEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
};

export default useFirstRenderFlag;
