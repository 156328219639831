import React from 'react';
import PropTypes from 'prop-types';

import { RaceBox } from '../RaceBox/RaceBox';

import { StyledContainer } from './styled';

import { LeaderBox } from '@components/Wallboard/LeaderBox/LeaderBox';

export const TopFive = ({ config, timePeriod, leaderData }) => (
  <StyledContainer>
    <LeaderBox timePeriod={timePeriod} leaderData={leaderData} />
    <RaceBox config={config} />
  </StyledContainer>
);

TopFive.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.element, PropTypes.number]),
      iconBackground: PropTypes.string.isRequired,
      profileIcon: PropTypes.func.isRequired,
      backgroundGradient: PropTypes.string.isRequired,
      accentGradient: PropTypes.string.isRequired,
      width: PropTypes.string.isRequired,
      accentWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      raceIconSrc: PropTypes.string.isRequired,
      pageType: PropTypes.string.isRequired
    })
  ).isRequired,
  timePeriod: PropTypes.oneOf(['day', 'week', 'month']),
  leaderData: PropTypes.shape({
    name: PropTypes.string,
    prevDayScore: PropTypes.number,
    beforePrevDayScore: PropTypes.number,
    currentDayScore: PropTypes.number
  })
};
