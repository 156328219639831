import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router';
import qs from 'query-string';
import omit from 'lodash/omit';
import dayjs from 'dayjs';

import { useQueryParams } from '@hooks/useQueryParams';
import {
  telemetryPagesOptions,
  fields
} from '@components/ApplicationsTelemetry/config';
import Loader from '@components/shared/Loader';
import Button from '@components/shared/Button/Button';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import { dateFormat } from '@constants/common';
import { fieldWidth } from '@constants/filters';
import filterHelpers from '@utils/filterHelpers';
import { cleanObject } from '@utils/object-cleaner';
import Box from '@components/shared/Box/Box';

const baseInitialValues = {
  page: '',
  field: '',
  error: '',
  startDate: dayjs().startOf('week').startOf('day').toISOString(),
  endDate: dayjs().endOf('week').startOf('day').toISOString(),
  email: '',
  customer: ''
};

const initialFilterParams = cleanObject({
  startDate: dayjs().startOf('week').startOf('day').toISOString(),
  endDate: dayjs().endOf('week').startOf('day').toISOString(),
  email: '',
  customer: ''
});

const TelemetryFilter = () => {
  const history = useHistory();
  const params = useQueryParams();

  const handleSubmit = (values, setSubmitting) => {
    handleFilterUpdate(values);

    setSubmitting(false);
  };

  const handleFilterUpdate = (values) => {
    history.push({
      search: qs.stringify(
        {
          ...params,
          ...baseInitialValues,
          ...values,
          offset: 0
        },
        { skipEmptyString: true, skipNull: true }
      )
    });
  };

  const updFilterData = useMemo(() => {
    if (history.location.search) {
      const parsedFilters = qs.parse(history.location.search);
      const values = {};
      Object.keys(baseInitialValues).forEach((item) => {
        if (Object.keys(parsedFilters).includes(item)) {
          values[item] = parsedFilters[item];
        }
      });
      return {
        ...baseInitialValues,
        ...values
      };
    }
    return baseInitialValues;
  }, [history.location.search]);

  let initialValues = updFilterData;

  const resetAllFilters = () => {
    history.push({
      pathname: history.location.pathname,
      search: qs.stringify({
        ...initialFilterParams,
        offset: 0
      })
    });
  };

  if (history.location.search) {
    const parsedFilter = omit(params, ['recordsCount', 'take', 'offset']);
    if (Object.keys(parsedFilter).length) {
      initialValues = { ...updFilterData, ...parsedFilter };
    }
  }

  if (!history.location.search) {
    return <Loader />;
  }

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      {({ values, setSubmitting }) => (
        <>
          <Form className="page-filter">
            <Box sx={filterHelpers.getFlexForField(10)}>
              <FormikField
                type="select"
                name="page"
                label="Application step"
                options={telemetryPagesOptions?.map((page) => ({
                  label: page,
                  value: page
                }))}
                sx={{ width: '215px !important' }}
              />
              <FormikField
                type="select"
                name="field"
                label="Form field"
                options={fields}
                sx={{ width: '215px !important' }}
              />
              <FormikField
                type="text"
                name="error"
                label="Validation error"
                placeholder="Enter error"
              />
              <FormikField
                name="startDate"
                type="date"
                label="Start Date"
                placeholder="Start Date"
                format={dateFormat}
                formatOnDateStart
                sx={fieldWidth}
              />
              <FormikField
                name="endDate"
                type="date"
                label="End Date"
                placeholder="End Date"
                format={dateFormat}
                formatOnDateStart
                sx={fieldWidth}
              />
              <FormikField
                type="text"
                name="email"
                label="Email"
                placeholder="Enter email"
              />
              <FormikField
                type="text"
                name="customer"
                label="Customer"
                placeholder="Enter customer name"
              />
              <Box sx={filterHelpers.getFlexForField(10)}>
                <Button onClick={() => handleSubmit(values, setSubmitting)}>
                  Apply
                </Button>
                <Button onClick={resetAllFilters} variant="outlined">
                  Reset All Filters
                </Button>
              </Box>
            </Box>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default TelemetryFilter;
