import React, { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation, useRouteMatch } from 'react-router';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { StyledBreadcrumbs } from './styled';

import { NavigateNextIcon } from '@components/shared/icons/Icons';
import Box from '@components/shared/Box/Box';
import routes from '@constants/routes';
import { abbreviations } from '@components/shared/Breadcrumbs/config';

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const pathnamesWithoutIds = pathnames.filter(
    (item) => !/\bpage\b|\d+/.test(item)
  );

  const match = useRouteMatch(routes.leads.leadProviders.view);

  const getLabel = (label) => {
    const wordToReplace = label
      .split(' ')
      .find((word) => abbreviations[word.toLowerCase()] === word);
    return wordToReplace
      ? label.replace(wordToReplace, wordToReplace.toUpperCase())
      : label;
  };

  return (
    <Box>
      <StyledBreadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {pathnamesWithoutIds.map((value, index) => {
          const last = index === pathnamesWithoutIds.length - 1;
          const label = value
            .replace(/-/g, ' ')
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

          const to =
            label === 'Lead Provider' && match?.isExact
              ? routes.leads.leadProviders.page.view.url(match.params.leadId)
              : `/${pathnamesWithoutIds.slice(0, index + 1).join('/')}`;

          return last ? (
            <Typography variant="body2" color="inherit" key={to}>
              {getLabel(label)}
            </Typography>
          ) : (
            <LinkRouter color="primary" to={to} key={to}>
              {label}
            </LinkRouter>
          );
        })}
      </StyledBreadcrumbs>
    </Box>
  );
};

export default memo(Breadcrumbs);
