import React, { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { notificationFilterData } from '../Form/TargetStep/schema';
import { targetGroups } from '../Form/TargetStep/config';

import CustomNotificationSteps from '@components/CustomNotifications/Steps';
import CustomNotificationForm from '@components/CustomNotifications/Form';
import Logger from '@utils/logger';
import Grid from '@components/shared/Grid/Grid';
import {
  CSV_INPUT_NAME,
  notificationFormStepsOrder
} from '@constants/notificationFormConfig';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const CustomNotificationStepForm = () => {
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(null);
  const [lastFilledStep, setLastFilledStep] = useState(null);
  const [jobFilterData, setJobFilterData] = useState(null);
  const [jobData, setJobData] = useState(null);
  const [scheduleData, setScheduleData] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [jobType, setJobType] = useState(targetGroups.custom);

  useEffect(() => {
    if (!id) {
      setActiveStep(notificationFormStepsOrder[0]);
      return;
    }
    (async () => {
      try {
        const serverJobData = await AdminApi.get(
          adminApiUrls.notificationJob.page(id)
        );

        const serverScheduleData = await AdminApi.get(
          adminApiUrls.notificationSchedules.list,
          {
            params: {
              $filter: {
                notificationJobId: {
                  eq: serverJobData.notificationJobId
                }
              }
            }
          }
        );

        const {
          filter,
          jobTitle,
          jobType: serverJobType,
          messageTitle,
          messageTemplate,
          isActive
        } = serverJobData;

        setJobType(serverJobType);

        if (serverJobType == targetGroups.custom) {
          setJobFilterData(notificationFilterData.parse(filter));
        }
        if (serverJobType == targetGroups.bulkCsvNotification) {
          setFileData({
            [CSV_INPUT_NAME]: JSON.parse(filter)
          });
        }

        setJobData({
          jobTitle,
          messageTitle,
          messageTemplate,
          isActive
        });
        setScheduleData(serverScheduleData.value[0]);
        setLastFilledStep(
          notificationFormStepsOrder[notificationFormStepsOrder.length - 1]
        );
        setActiveStep(
          notificationFormStepsOrder[notificationFormStepsOrder.length - 1]
        );
      } catch (e) {
        Logger.error(e);
      }
    })();
  }, [id]);

  return (
    <Grid container>
      <Grid item xs={12} sm={3} md={2}>
        <CustomNotificationSteps
          activeStep={activeStep}
          lastFilledStep={lastFilledStep}
          setActiveStep={setActiveStep}
        />
      </Grid>
      <Grid item xs={12} sm={9} md={10}>
        <CustomNotificationForm
          id={id}
          activeStep={activeStep}
          jobData={jobData}
          jobFilterData={jobFilterData}
          scheduleData={scheduleData}
          fileData={fileData}
          setFileData={setFileData}
          jobType={jobType}
          setJobType={setJobType}
          setLastFilledStep={setLastFilledStep}
          setJobData={setJobData}
          setScheduleData={setScheduleData}
          setJobFilterData={setJobFilterData}
          setActiveStep={setActiveStep}
        />
      </Grid>
    </Grid>
  );
};

export default memo(CustomNotificationStepForm);
