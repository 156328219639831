import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Alert from '@components/shared/Alert/Alert';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Logger from '@utils/logger';
import CampaignDetails from '@components/Leads/campaigns/Details';
import routes from '@constants/routes';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

const CampaignViewPage = () => {
  const { campaignId } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const handleBackButton = () => {
      const pathString = history.location.pathname;
      const regex = /\/lead-providers\/(\d+)/;
      const match = pathString.match(regex);

      if (match && match[1]) {
        const leadProviderID = parseInt(match[1]);
        history.push(routes.leads.leadProviders.page.view.url(leadProviderID));
      }
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const fetchData = async () => {
    try {
      setData(null);
      const response = await LeadManagementApi.get(
        leadManagementApiUrls.campaigns.page(campaignId),
        {
          params: {
            $expand: 'LeadProvider'
          }
        }
      );
      setData(response);
    } catch (e) {
      Logger.error(e);
      setError(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MainLayout title="View Campaign" isLoading={!data && !error}>
      {data && <CampaignDetails data={data} refetchData={fetchData} />}
      {error && (
        <Alert
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
        />
      )}
    </MainLayout>
  );
};

export default CampaignViewPage;
