import React from 'react';
import { Link } from 'react-router-dom';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import routes from '@constants/routes';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';
import toastify from '@utils/toastify';
import Button from '@components/shared/Button/Button';
import { CheckCircleIcon } from '@components/shared/icons/Icons';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';
import Box from '@components/shared/Box/Box';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';
import DeleteActionButton from '@components/shared/DataTable/actions/DeleteActionButton';

const FlatPagesListPage = () => {
  const handleDelete = async (id, updatePaginationAfterDelete) => {
    try {
      await AdminApi.delete(adminApiUrls.flatPages.page(id));

      updatePaginationAfterDelete(
        [id],
        (record, arrayOfDeletableIds) =>
          !arrayOfDeletableIds.includes(record.contentEntityId)
      );

      toastify.success({
        message: 'Page deleted'
      });
    } catch (e) {
      toastify.error({
        message: 'Delete operation failure'
      });
    }
  };

  const getColumns = ({ updatePaginationAfterDelete }) => [
    {
      headerName: 'Action',
      field: 'actions',
      width: 80,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <Box size={4}>
          <EditActionButton
            path={routes.flatPages.page.edit.url(row.contentEntityId)}
            hidden={!helpers.getActionPermission(permissionsMap.flatManage)}
          />
          <ShowDetailsActionButton
            path={routes.flatPages.page.view.url(row.contentEntityId)}
          />
          <DeleteActionButton
            hidden={!helpers.getActionPermission(permissionsMap.flatManage)}
            itemId={row.contentEntityId}
            onConfirm={() =>
              handleDelete(row.contentEntityId, updatePaginationAfterDelete)
            }
          />
        </Box>
      )
    },
    {
      headerName: 'Title',
      field: 'title',
      minWidth: 400,
      flex: 3
    },
    {
      headerName: 'Slug',
      field: 'key',
      minWidth: 250,
      flex: 2
    },
    {
      headerName: 'Published',
      field: 'publish',
      minWidth: 90,
      flex: 1,
      type: muiDataTableTypes.boolean,
      renderCell: ({ row }) => (row.publish ? <CheckCircleIcon /> : '')
    }
  ];

  return (
    <MainLayout title="Pages">
      <ODataPaginatedTable
        additionalActionButtons={[
          helpers.getActionPermission(permissionsMap.flatManage) && (
            <Button component={Link} to={routes.flatPages.page.create}>
              Add new page
            </Button>
          )
        ]}
        getColumns={getColumns}
        usePaginatedDataOptions={{ apiUrl: adminApiUrls.flatPages.list }}
        rowKey="contentEntityId"
        customApi={AdminApi}
      />
    </MainLayout>
  );
};

export default FlatPagesListPage;
