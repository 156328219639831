import * as Yup from 'yup';

import { loanDefaultLimits, loanDefaultValue } from '@constants/common';
import validators from '@utils/validators';
import { loanAmountSchema } from '@components/Application/LoanForm/GeneralStep/schema';

export const bankStepFieldsSchema = Yup.object().shape({
  amount: Yup.number()
    .min(loanDefaultLimits.min)
    .max(loanDefaultLimits.max)
    .label('Loan amount')
    .required(),
  bankName: Yup.string().label('Bank name').required(),
  bankRoutingNumber: validators
    .bankRoutingNumber('bankRoutingNumber')
    .max(9)
    .label('Bank routing number')
    .required(),
  account: Yup.string()
    .matches(/^[0-9]*$/, {
      message: 'Only digits are allowed'
    })
    .matches(/^[0-9]{4,14}$/, {
      message: 'Account number must be 4-14 digits in length'
    })
    .label('Checking account')
    .required(),
  accountBalance: validators.moneyFloatField
    .label('Checking account balance')
    .required(),
  hasDC: Yup.bool().label('Have a debit card'),
  bankPhone: Yup.string().label('Bank phone').nullable(),
  timeAccountOpenYears: Yup.number().label('Years at bank').required(),
  timeAccountOpenMonths: Yup.number().label('Months at bank').required(),
  bankruptcyStatus: Yup.bool(),
  accountType: Yup.string().label('Account type').required()
});

export const bankStepSchema = bankStepFieldsSchema.concat(loanAmountSchema);

export const bankStepInitialValues = {
  amount: loanDefaultValue,
  bankName: '',
  bankRoutingNumber: '',
  account: '',
  hasDC: false,
  bankPhone: '',
  accountBalance: '',
  timeAccountOpenYears: '',
  timeAccountOpenMonths: '',
  bankruptcyStatus: false,
  accountType: ''
};
