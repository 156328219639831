import React from 'react';
import dayjs from 'dayjs';

import { cleanObject } from '@utils/object-cleaner';
import helpers from '@utils/helpers';
import { IBVPlatforms } from '@constants/internetBankVerification';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';
import { filterOperators } from '@constants/common';

export const initialFilterParams = (id) =>
  cleanObject({
    createdDate: {
      ge: dayjs().subtract(1, 'month').startOf('day').toISOString(),
      le: dayjs().endOf('day').toISOString()
    },
    refId: '',
    ibvPlatform: {
      contains: ''
    },
    status: {
      contains: ''
    },
    score: {
      eq: ''
    },
    userId: {
      eq: id
    }
  });

export const getColumns = ({ IBVScoringReportStatusOptions }) => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <ShowDetailsActionButton
        path={{
          pathname: row?.view
        }}
        target="_blank"
        hidden={!row?.view}
      />
    )
  },
  {
    headerName: 'Request ID',
    field: 'referenceId',
    flex: 1
  },
  {
    headerName: 'IBV Platform',
    field: 'provider',
    flex: 1,
    type: muiDataTableTypes.singleSelect,
    valueOptions: IBVPlatforms,
    renderCell: ({ row }) => row.provider
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: 1,
    type: muiDataTableTypes.singleSelect,
    valueOptions: IBVScoringReportStatusOptions,
    renderCell: ({ row }) => row.status
  },
  {
    headerName: 'Created',
    field: 'createdDate',
    flex: 1,
    type: muiDataTableTypes.dateRange,
    renderCell: ({ row }) => helpers.getFormattedDate(row?.createdDate),
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Score',
    field: 'score',
    flex: 1,
    type: muiDataTableTypes.number,
    renderCell: ({ row }) => row.score
  }
];
