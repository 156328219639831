import React from 'react';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import {
  csvSchema,
  initialValues,
  requiredCSVFields,
  SampleElement,
  tableColumns
} from '@components/TeamGoals/UploadCSVForm.config';
import UploadCSVForm from '@components/TeamGoals/UploadCSVForm';
import Stack from '@components/shared/Stack/Stack';
import Typography from '@components/shared/Typography/Typography';
import ImportCSVInfo from '@components/shared/ImportCSV/ImportCSVInfo';

const TeamGoalsUploadSCVPage = () => (
  <MainLayout title="Team goals">
    <Stack sx={{ mt: 2 }} alignItems="flex-start">
      <Typography variant="h3" mb={1}>
        Upload CSV file for Team Goals table in Wallboad
      </Typography>

      <ImportCSVInfo
        requiredFields={requiredCSVFields}
        sampleFileFormat={SampleElement}
        tableColumns={tableColumns}
      />

      <UploadCSVForm csvSchema={csvSchema} initialValues={initialValues} />
    </Stack>
  </MainLayout>
);

export default TeamGoalsUploadSCVPage;
