import React from 'react';

import { getColumns, loggedEventsExtraParams } from './config';
import ExpandedLoggedEvent from './ExpandedLoggedEvent';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import LoggedEventsFilter from '@components/LoggedEvents/Filter';
import { ActionsSection } from '@components/shared/MainLayout/MainLayout.components';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

export const LoggedEventsPage = () => (
  <MainLayout title="System Events">
    <ActionsSection>
      <LoggedEventsFilter />
    </ActionsSection>
    <ODataPaginatedTable
      getColumns={getColumns}
      usePaginatedDataOptions={{
        apiUrl: adminApiUrls.loggedEvents,
        initialFilterParams: loggedEventsExtraParams
      }}
      rowKey="loggedEventId"
      getDetailPanelContent={({ row }) => <ExpandedLoggedEvent row={row} />}
      customApi={AdminApi}
    />
  </MainLayout>
);

export default LoggedEventsPage;
