import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Alert from '@components/shared/Alert/Alert';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Logger from '@utils/logger';
import SystemNotificationHistoryDetails from '@components/SystemNotifications/HistoryDetails';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const HistoryViewSystemNotificationsPage = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await AdminApi.get(
          adminApiUrls.notificationJobHistories.list,
          {
            params: {
              $filter: {
                notificationJobHistoryId: {
                  eq: +id
                }
              }
            }
          }
        );
        setData(response.value[0]);
      } catch (e) {
        Logger.error(e);
        setError(e);
      }
    })();
  }, []);

  return (
    <MainLayout title="View Campaign" isLoading={!data && !error}>
      {data && <SystemNotificationHistoryDetails data={data} />}
      {error && (
        <Alert
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
        />
      )}
    </MainLayout>
  );
};

export default HistoryViewSystemNotificationsPage;
