export const statesList = [
  {
    id: 1,
    displayName: 'Alabama',
    shortName: 'AL'
  },
  {
    id: 2,
    displayName: 'Alaska',
    shortName: 'AK'
  },
  {
    id: 3,
    displayName: 'Arizona',
    shortName: 'AZ'
  },
  {
    id: 4,
    displayName: 'Arkansas',
    shortName: 'AR'
  },
  {
    id: 5,
    displayName: 'California',
    shortName: 'CA'
  },
  {
    id: 6,
    displayName: 'Colorado',
    shortName: 'CO'
  },
  {
    id: 7,
    displayName: 'Connecticut',
    shortName: 'CT'
  },
  {
    id: 8,
    displayName: 'Delaware',
    shortName: 'DE'
  },
  {
    id: 51,
    displayName: 'District of Columbia',
    shortName: 'DC'
  },
  {
    id: 9,
    displayName: 'Florida',
    shortName: 'FL'
  },
  {
    id: 10,
    displayName: 'Georgia',
    shortName: 'GA'
  },
  {
    id: 11,
    displayName: 'Hawaii',
    shortName: 'HI'
  },
  {
    id: 12,
    displayName: 'Idaho',
    shortName: 'ID'
  },
  {
    id: 13,
    displayName: 'Illinois',
    shortName: 'IL'
  },
  {
    id: 14,
    displayName: 'Indiana',
    shortName: 'IN'
  },
  {
    id: 15,
    displayName: 'Iowa',
    shortName: 'IA'
  },
  {
    id: 16,
    displayName: 'Kansas',
    shortName: 'KS'
  },
  {
    id: 17,
    displayName: 'Kentucky',
    shortName: 'KY'
  },
  {
    id: 18,
    displayName: 'Louisiana',
    shortName: 'LA'
  },
  {
    id: 19,
    displayName: 'Maine',
    shortName: 'ME'
  },
  {
    id: 20,
    displayName: 'Maryland',
    shortName: 'MD'
  },
  {
    id: 21,
    displayName: 'Massachusetts',
    shortName: 'MA'
  },
  {
    id: 22,
    displayName: 'Michigan',
    shortName: 'MI'
  },
  {
    id: 23,
    displayName: 'Minnesota',
    shortName: 'MN'
  },
  {
    id: 24,
    displayName: 'Mississippi',
    shortName: 'MS'
  },
  {
    id: 25,
    displayName: 'Missouri',
    shortName: 'MO'
  },
  {
    id: 26,
    displayName: 'Montana',
    shortName: 'MT'
  },
  {
    id: 27,
    displayName: 'Nebraska',
    shortName: 'NE'
  },
  {
    id: 28,
    displayName: 'Nevada',
    shortName: 'NV'
  },
  {
    id: 29,
    displayName: 'New Hampshire',
    shortName: 'NH'
  },
  {
    id: 30,
    displayName: 'New Jersey',
    shortName: 'NJ'
  },
  {
    id: 31,
    displayName: 'New Mexico',
    shortName: 'NM'
  },
  {
    id: 32,
    displayName: 'New York',
    shortName: 'NY'
  },
  {
    id: 33,
    displayName: 'North Carolina',
    shortName: 'NC'
  },
  {
    id: 34,
    displayName: 'North Dakota',
    shortName: 'ND'
  },
  {
    id: 35,
    displayName: 'Ohio',
    shortName: 'OH'
  },
  {
    id: 36,
    displayName: 'Oklahoma',
    shortName: 'OK'
  },
  {
    id: 37,
    displayName: 'Oregon',
    shortName: 'OR'
  },
  {
    id: 38,
    displayName: 'Pennsylvania',
    shortName: 'PA'
  },
  {
    id: 39,
    displayName: 'Rhode Island',
    shortName: 'RI'
  },
  {
    id: 40,
    displayName: 'South Carolina',
    shortName: 'SC'
  },
  {
    id: 41,
    displayName: 'South Dakota',
    shortName: 'SD'
  },
  {
    id: 42,
    displayName: 'Tennessee',
    shortName: 'TN'
  },
  {
    id: 43,
    displayName: 'Texas',
    shortName: 'TX'
  },
  {
    id: 44,
    displayName: 'Utah',
    shortName: 'UT'
  },
  {
    id: 45,
    displayName: 'Vermont',
    shortName: 'VT'
  },
  {
    id: 46,
    displayName: 'Virginia',
    shortName: 'VA'
  },
  {
    id: 47,
    displayName: 'Washington',
    shortName: 'WA'
  },
  {
    id: 48,
    displayName: 'West Virginia',
    shortName: 'WV'
  },
  {
    id: 49,
    displayName: 'Wisconsin',
    shortName: 'WI'
  },
  {
    id: 50,
    displayName: 'Wyoming',
    shortName: 'WY'
  }
];

export const statesOptions = statesList?.map((option) => ({
  label: option.displayName,
  value: option.shortName
}));
