import { styled } from '@mui/material/styles';

import Stack from '@components/shared/Stack/Stack';
import { palette } from '@utils/theme';

export const ContainerModal = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${palette.white};
  padding: 10px 25px 40px;
  border-radius: 12px;
  max-width: calc(100vw - 32px);
  max-height: ${({ maxHeight }) => maxHeight};
`;

export const HeaderModal = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  min-width: ${({ minWidth }) => minWidth};
`;
