import React, { useEffect, useMemo } from 'react';
import PT from 'prop-types';
import DocumentTitle from 'react-document-title';
import { useDispatch, useSelector } from 'react-redux';

import HeaderComponent from '@components/shared/Header';
import '@components/shared/SinglePageLayout/SinglePageLayout.scss';
import { StyledMainBox } from '@components/shared/MainLayout/styled';
import { selectCurrentUser } from '@store/currentUser';
import { fetchCurrentUser } from '@store/currentUser/actions';

const SinglePageLayout = ({ title, children }) => {
  const dispatch = useDispatch();
  const storeCurrentUser = useSelector(selectCurrentUser);

  const currentUser = useMemo(
    () => storeCurrentUser,
    [storeCurrentUser?.userName, storeCurrentUser?.firstName]
  );

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, []);

  return (
    <DocumentTitle
      title={[title, 'eLoanWarehouse Manager'].filter(Boolean).join(' | ')}
    >
      <>
        <HeaderComponent currentUser={currentUser} />
        <StyledMainBox
          component="main"
          sx={{
            flexGrow: 1,
            px: { sm: 3 },
            margin: 0,
            minHeight: '100vh'
          }}
        >
          {children}
        </StyledMainBox>
      </>
    </DocumentTitle>
  );
};

SinglePageLayout.propTypes = {
  children: PT.any.isRequired,
  title: PT.string.isRequired
};

export default SinglePageLayout;
