import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { AuthRoute, PrivateRoute } from './GuardRoutes';

import history from '@utils/history';
import routes from '@constants/routes';
import ScrollToTop from '@components/shared/ScrollToTop';
import Page404 from '@pages/page-404';
import PermissionDeniedPage from '@pages/permission-denied';
import FlatPagesListPage from '@pages/flat-pages/list';
import ApplicationsListPage from '@pages/applications/list';
import EditApplicationPage from '@pages/applications/application/Edit';
import ViewApplicationPage from '@pages/applications/application/View';
import LoggedEventsPage from '@pages/logs-management/logged-events';
import Login from '@pages/auth/login';
import FlatPageEditPage from '@pages/flat-pages/flat-page/edit';
import FlatPageViewPage from '@pages/flat-pages/flat-page/view';
import FlatPageCreatePage from '@pages/flat-pages/flat-page/create';
import UsersListPage from '@pages/users';
import UsersViewPage from '@pages/users/user/view';
import TelemetryPage from '@pages/logs-management/telemetry';
import SystemNotificationsList from '@pages/notifications/system-notifications/list';
import SystemNotificationsEditPage from '@pages/notifications/system-notifications/system-notification/edit';
import ViewSystemNotificationPage from '@pages/notifications/system-notifications/system-notification/view';
import ViewHistorySystemNotificationPage from '@pages/notifications/system-notifications/system-notification/history-view';
import CustomNotificationsList from '@pages/notifications/custom-notifications/list';
import CustomNotificationsCreatePage from '@pages/notifications/custom-notifications/custom-notification/create';
import CustomNotificationsEditPage from '@pages/notifications/custom-notifications/custom-notification/edit';
import CustomNotificationsViewPage from '@pages/notifications/custom-notifications/custom-notification/view';
import DashboardApplicationErrorsPage from '@pages/dashboard/application-errors';
import DashboardApplicationErrorDetailsPage from '@pages/dashboard/application-errors/application-error';
import DashboardApplicationSpentTimePage from '@pages/dashboard/application-spent-time';
import DashboardApiAndAppErrorsPage from '@pages/dashboard/api-and-app-errors';
import DashboardLeadStatsPage from '@pages/dashboard/lead-stats';
import DashboardAbandonedApplicationsPage from '@pages/dashboard/abandoned-applications';
import DashboardHealthStatusPage from '@pages/dashboard/health-status';
import DashboardLeadProvidersPage from '@pages/leads/lead-providers-rates';
import LeadProvidersReportsPage from '@pages/leads/lead-providers-reports';
import LeadProvidersPerformancePage from '@pages/leads/lead-providers-reports/lead-providers-performance';
import AffiliateReportPage from '@pages/lead-reports/affiliate-report';
import DenialReasonsReportPage from '@pages/lead-reports/denial-reasons-report';
import LeadProvidersListPage from '@pages/leads/lead-providers/list';
import LeadProviderCreatePage from '@pages/leads/lead-providers/lead-provider/create';
import LeadProviderEditPage from '@pages/leads/lead-providers/lead-provider/edit';
import LeadProviderViewPage from '@pages/leads/lead-providers/lead-provider/view';
import CampaignCreatePage from '@pages/leads/lead-providers/campaign/create';
import CampaignEditPage from '@pages/leads/lead-providers/campaign/edit';
import CampaignViewPage from '@pages/leads/lead-providers/campaign/view';
import LeadGroupsListPage from '@pages/leads/campaign-groups/list';
import LeadGroupCreatePage from '@pages/leads/campaign-groups/campaign-group/create';
import LeadGroupEditPage from '@pages/leads/campaign-groups/campaign-group/edit';
import LeadGroupViewPage from '@pages/leads/campaign-groups/campaign-group/view';
import AppUsersListPage from '@pages/os-and-app-versions/app-users';
import UserLoansListPage from '@pages/user-loans';
import MobileAppVersionsList from '@pages/os-and-app-versions/mobile-app-versions/list';
import MobileAppVersionsCreate from '@pages/os-and-app-versions/mobile-app-versions/mobile-app-version/create';
import MobileAppVersionsEdit from '@pages/os-and-app-versions/mobile-app-versions/mobile-app-version/edit';
import BlockedIPAddresses from '@pages/blocked-ip-addresses';
import PromoCodesList from '@pages/promo-codes/list';
import PromoCodeCreate from '@pages/promo-codes/promo-code/create';
import PromoCodeEdit from '@pages/promo-codes/promo-code/edit';
import PaymentsListPage from '@pages/payments/list';
import DebitCardPage from '@pages/debit-card';
import ConfigurationDebitCardPage from '@pages/configuration/debit-card';
import DeclineReasonSettingsPage from '@pages/configuration/decline-reason-settings';
import PastDuePaymentsPage from '@pages/payments/past-due-payments/payment-list';
import PastDuePaymentsFilePage from '@pages/payments/past-due-payments/file-list';
import LeadProvider__LeadRequestsPage from '@pages/lead-provider/lead-requests';
import LeadProvider__CampaignPerformancePage from '@pages/lead-provider/campaign-performance';
import DiscrepancyReportPage from '@pages/dashboard/discrepancy-report';
import IBVTrafficReportPage from '@pages/dashboard/ibv-traffic-report';
import IBVScoringReportPage from '@pages/dashboard/ibv-scoring-report';
import PaymentsRerunPage from '@pages/payments/payments-rerun/list';
import PaymentsRerunSummaryPage from '@pages/payments/payments-rerun/performance';
import PaymentsRerunSettingsPage from '@pages/payments/payments-rerun/settings';
import DashboardWebPerformancePage from '@pages/dashboard/internal-performance/web-performance';
import DashboardDBPerformancePage from '@pages/dashboard/internal-performance/db-performance';
import DashboardLeadReportsPerformancePage from '@pages/dashboard/internal-performance/lead-reports-performance';
import CsvComparisonPage from '@pages/csv-comparison/comparisons';
import CsvComparisonConfigurationsPage from '@pages/csv-comparison/configurations';
import CsvComparisonConfigurationsCreateEditPage from '@pages/csv-comparison/configurations-create-edit';
import CsvComparisonDetailsPage from '@pages/csv-comparison/comparison-details';
import GlobalSettingsPage from '@pages/configuration/global-settings';
import MaintenanceMode from '@pages/configuration/maintenance-mode';
import DiscrepancySummaryReportByDay from '@pages/dashboard/discrepancy-report/discrepancy-summary-by-day';
import DiscrepancySummaryReportByStore from '@pages/dashboard/discrepancy-report/discrepancy-summary-by-store';
import AccessVerificationPage from '@pages/access-verification';
import LoanOriginatorsDaily from '@pages/wallboard/loan-originators-daily';
import { TeamGoals } from '@pages/wallboard/team-goals';
import { LoanProcessing } from '@pages/wallboard/loan-procesing';
import { Collection } from '@pages/wallboard/collection';
import LoanOriginatorsWeekly from '@pages/wallboard/loan-originators-weekly';
import LoanOriginatorsMonthly from '@pages/wallboard/loan-originators-monthly';
import TeamGoalsUploadSCVPage from '@pages/configuration/team-goals';
import ScheduledPaymentsSummaryPage from '@pages/payments/scheduled-payments/performance';
import ScheduledPaymentsSettingsPage from '@pages/payments/scheduled-payments/settings';
import ScheduledPaymentsPage from '@pages/payments/scheduled-payments/list';
import StackPerformancePage from '@pages/lead-reports/stack-performance';
import LeadProviderPerformancePage from '@pages/lead-reports/lead-provider-performance';
import BINNumberListPage from '@pages/bin-checker/bin-numbers-list';
import ABANumberListPage from '@pages/bin-checker/aba-numbers-list';

const AppRouter = () => (
  <Router history={history}>
    <ScrollToTop>
      <Switch>
        <AuthRoute exact path={routes.auth.login} component={Login} />
        <AuthRoute
          exact
          path={routes.auth.accessVerification}
          component={AccessVerificationPage}
        />
        <PrivateRoute
          exact
          path={routes.flatPages.list}
          component={FlatPagesListPage}
        />
        <PrivateRoute
          exact
          path={routes.flatPages.page.edit.path}
          component={FlatPageEditPage}
        />
        <PrivateRoute
          exact
          path={routes.flatPages.page.view.path}
          component={FlatPageViewPage}
        />
        <PrivateRoute
          exact
          path={routes.flatPages.page.create}
          component={FlatPageCreatePage}
        />
        <PrivateRoute exact path={routes.telemetry} component={TelemetryPage} />
        <PrivateRoute
          exact
          path={routes.applications.list}
          component={ApplicationsListPage}
        />
        <PrivateRoute
          exact
          path={routes.applications.page.edit.path}
          component={EditApplicationPage}
        />
        <PrivateRoute
          exact
          path={routes.applications.page.view.path}
          component={ViewApplicationPage}
        />
        <PrivateRoute
          exact
          path={routes.loggedEvents}
          component={LoggedEventsPage}
        />
        <PrivateRoute exact path={routes.home}>
          <Redirect to={routes.applications.list} />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={routes.users.list}
          component={UsersListPage}
        />
        <PrivateRoute
          exact
          path={routes.users.page.view.path}
          component={UsersViewPage}
        />
        <PrivateRoute
          exact
          path={routes.notifications.systemNotifications.list}
          component={SystemNotificationsList}
        />
        <PrivateRoute
          exact
          path={routes.notifications.systemNotifications.page.edit.path}
          component={SystemNotificationsEditPage}
        />
        <PrivateRoute
          exact
          path={routes.notifications.systemNotifications.page.view.path}
          component={ViewSystemNotificationPage}
        />
        <PrivateRoute
          exact
          path={routes.notifications.systemNotifications.historyPage.path}
          component={ViewHistorySystemNotificationPage}
        />
        <PrivateRoute
          exact
          path={routes.notifications.customNotifications.list}
          component={CustomNotificationsList}
        />
        <PrivateRoute
          exact
          path={routes.notifications.customNotifications.page.create}
          component={CustomNotificationsCreatePage}
        />
        <PrivateRoute
          exact
          path={routes.notifications.customNotifications.page.edit.path}
          component={CustomNotificationsEditPage}
        />
        <PrivateRoute
          exact
          path={routes.notifications.customNotifications.page.view.path}
          component={CustomNotificationsViewPage}
        />
        <PrivateRoute
          exact
          path={routes.dashboard.applicationErrors.list}
          component={DashboardApplicationErrorsPage}
        />
        <PrivateRoute
          exact
          path={routes.dashboard.applicationErrors.page.path}
          component={DashboardApplicationErrorDetailsPage}
        />
        <PrivateRoute
          exact
          path={routes.dashboard.applicationSpentTime}
          component={DashboardApplicationSpentTimePage}
        />
        <PrivateRoute
          exact
          path={routes.dashboard.apiAppErrors}
          component={DashboardApiAndAppErrorsPage}
        />
        <PrivateRoute
          exact
          path={routes.dashboard.internalPerformance.web}
          component={DashboardWebPerformancePage}
        />
        <PrivateRoute
          exact
          path={routes.dashboard.internalPerformance.db}
          component={DashboardDBPerformancePage}
        />
        <PrivateRoute
          exact
          path={routes.leads.reports.affiliateReport}
          component={AffiliateReportPage}
        />
        <PrivateRoute
          exact
          path={routes.leads.reports.leadProviderPerformance}
          component={LeadProviderPerformancePage}
        />
        <PrivateRoute
          exact
          path={routes.leads.reports.stackPerformance}
          component={StackPerformancePage}
        />
        <PrivateRoute
          exact
          path={routes.leads.reports.denialReasonsReport}
          component={DenialReasonsReportPage}
        />
        <PrivateRoute
          exact
          path={routes.leads.leadProviders.list}
          component={LeadProvidersListPage}
        />
        <PrivateRoute
          exact
          path={routes.leads.leadProviders.page.create}
          component={LeadProviderCreatePage}
        />
        <PrivateRoute
          exact
          path={routes.leads.leadProviders.page.edit.path}
          component={LeadProviderEditPage}
        />
        <PrivateRoute
          exact
          path={routes.leads.leadProviders.page.view.path}
          component={LeadProviderViewPage}
        />
        <PrivateRoute
          exact
          path={routes.leads.leadProviders.campaign.create.path}
          component={CampaignCreatePage}
        />
        <PrivateRoute
          exact
          path={routes.leads.leadProviders.campaign.edit.path}
          component={CampaignEditPage}
        />
        <PrivateRoute
          exact
          path={routes.leads.leadProviders.campaign.view.path}
          component={CampaignViewPage}
        />
        <PrivateRoute
          exact
          path={routes.leads.campaignGroups.list}
          component={LeadGroupsListPage}
        />
        <PrivateRoute
          exact
          path={routes.leads.campaignGroups.page.create}
          component={LeadGroupCreatePage}
        />
        <PrivateRoute
          exact
          path={routes.leads.campaignGroups.page.edit.path}
          component={LeadGroupEditPage}
        />
        <PrivateRoute
          exact
          path={routes.leads.campaignGroups.page.view.path}
          component={LeadGroupViewPage}
        />
        <PrivateRoute
          exact
          path={routes.leads.leadProvidersRates}
          component={DashboardLeadProvidersPage}
        />
        <PrivateRoute
          exact
          path={routes.leads.leadProvidersReports.path}
          component={LeadProvidersReportsPage}
        />
        <PrivateRoute
          exact
          path={routes.leads.leadProvidersReports.campaigns}
          component={LeadProvidersPerformancePage}
        />
        <PrivateRoute
          exact
          path={routes.userLoans}
          component={UserLoansListPage}
        />
        <PrivateRoute
          exact
          path={routes.dashboard.leadStats}
          component={DashboardLeadStatsPage}
        />
        <PrivateRoute
          exact
          path={routes.dashboard.abandonedApplications}
          component={DashboardAbandonedApplicationsPage}
        />
        <PrivateRoute
          exact
          path={routes.dashboard.healthStatus}
          component={DashboardHealthStatusPage}
        />
        <PrivateRoute
          exact
          path={routes.osAndAppVersions.userAgents}
          component={AppUsersListPage}
        />
        <PrivateRoute
          exact
          path={routes.osAndAppVersions.mobileAppVersions.list}
          component={MobileAppVersionsList}
        />
        <PrivateRoute
          exact
          path={routes.osAndAppVersions.mobileAppVersions.create}
          component={MobileAppVersionsCreate}
        />
        <PrivateRoute
          exact
          path={routes.osAndAppVersions.mobileAppVersions.edit}
          component={MobileAppVersionsEdit}
        />
        <PrivateRoute
          exact
          path={routes.blockedIPAddresses}
          component={BlockedIPAddresses}
        />
        <PrivateRoute
          exact
          path={routes.promoCodes.list}
          component={PromoCodesList}
        />
        <PrivateRoute
          exact
          path={routes.promoCodes.create}
          component={PromoCodeCreate}
        />
        <PrivateRoute
          exact
          path={routes.promoCodes.edit}
          component={PromoCodeEdit}
        />
        <PrivateRoute exact path={routes.debitCard} component={DebitCardPage} />
        <PrivateRoute
          exact
          path={routes.permissionDenied}
          component={PermissionDeniedPage}
        />
        <PrivateRoute
          exact
          path={routes.payments.list}
          component={PaymentsListPage}
        />
        <PrivateRoute
          exact
          path={routes.payments.debitCard}
          component={ConfigurationDebitCardPage}
        />
        <PrivateRoute
          exact
          path={routes.payments.declineReasonSettings}
          component={DeclineReasonSettingsPage}
        />
        <PrivateRoute
          exact
          path={routes.payments.pastDuePayments.paymentsList}
          component={PastDuePaymentsPage}
        />
        <PrivateRoute
          exact
          path={routes.payments.pastDuePayments.fileList}
          component={PastDuePaymentsFilePage}
        />
        <PrivateRoute
          exact
          path={routes.leadProvider.leadRequests}
          component={LeadProvider__LeadRequestsPage}
        />
        <PrivateRoute
          exact
          path={routes.leadProvider.campaignPerformance}
          component={LeadProvider__CampaignPerformancePage}
        />
        <PrivateRoute
          exact
          path={routes.dashboard.discrepancyReport.path}
          component={DiscrepancyReportPage}
        />
        <PrivateRoute
          exact
          path={
            routes.dashboard.discrepancyReport.discrepancySummaryReportByDay
          }
          component={DiscrepancySummaryReportByDay}
        />
        <PrivateRoute
          exact
          path={
            routes.dashboard.discrepancyReport.discrepancySummaryReportByStore
          }
          component={DiscrepancySummaryReportByStore}
        />
        <PrivateRoute
          exact
          path={routes.dashboard.ibv.trafficReport}
          component={IBVTrafficReportPage}
        />
        <PrivateRoute
          exact
          path={routes.dashboard.ibv.scoringReport.list}
          component={IBVScoringReportPage}
        />

        <PrivateRoute exact path={routes.csvCompare.path}>
          <Redirect to={routes.csvCompare.comparisons} />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={routes.csvCompare.comparisons}
          component={CsvComparisonPage}
        />
        <PrivateRoute
          exact
          path={routes.csvCompare.configurations.list}
          component={CsvComparisonConfigurationsPage}
        />
        <PrivateRoute
          exact
          path={routes.csvCompare.configurations.create}
          component={CsvComparisonConfigurationsCreateEditPage}
        />
        <PrivateRoute
          exact
          path={routes.csvCompare.configurations.edit.path}
          component={CsvComparisonConfigurationsCreateEditPage}
        />
        <PrivateRoute
          exact
          path={routes.csvCompare.details.path}
          component={CsvComparisonDetailsPage}
        />
        <PrivateRoute
          exact
          path={routes.payments.rerun.list}
          component={PaymentsRerunPage}
        />
        <PrivateRoute
          exact
          path={routes.payments.rerun.performance}
          component={PaymentsRerunSummaryPage}
        />
        <PrivateRoute
          exact
          path={routes.payments.rerun.settings}
          component={PaymentsRerunSettingsPage}
        />
        <PrivateRoute
          exact
          path={routes.dashboard.internalPerformance.leadReportsPerformance}
          component={DashboardLeadReportsPerformancePage}
        />
        <PrivateRoute
          exact
          path={routes.configuration.globalSettings}
          component={GlobalSettingsPage}
        />
        <PrivateRoute
          exact
          path={routes.configuration.maintenanceMode}
          component={MaintenanceMode}
        />

        <Route
          exact
          path={routes.wallboard.loanOriginatorsDaily}
          component={LoanOriginatorsDaily}
        />
        <Route
          exact
          path={routes.wallboard.loanOriginatorsWeekly}
          component={LoanOriginatorsWeekly}
        />
        <Route
          exact
          path={routes.wallboard.loanOriginatorsMonthly}
          component={LoanOriginatorsMonthly}
        />
        <Route exact path={routes.wallboard.teamGoals} component={TeamGoals} />
        <Route
          exact
          path={routes.wallboard.loanProcessing}
          component={LoanProcessing}
        />
        <Route
          exact
          path={routes.wallboard.collection}
          component={Collection}
        />
        <PrivateRoute
          exact
          path={routes.configuration.teamGoals}
          component={TeamGoalsUploadSCVPage}
        />
        <PrivateRoute
          exact
          path={routes.payments.scheduledPayments.list}
          component={ScheduledPaymentsPage}
        />
        <PrivateRoute
          exact
          path={routes.payments.scheduledPayments.performance}
          component={ScheduledPaymentsSummaryPage}
        />
        <PrivateRoute
          exact
          path={routes.payments.scheduledPayments.settings}
          component={ScheduledPaymentsSettingsPage}
        />
        <PrivateRoute
          exact
          path={routes.binChecker.binNumberList}
          component={BINNumberListPage}
        />
        <PrivateRoute
          exact
          path={routes.binChecker.abaNumberList}
          component={ABANumberListPage}
        />

        <PrivateRoute exact path={routes.dashboard.path}>
          <Redirect to={routes.dashboard.applicationErrors.list} />
        </PrivateRoute>
        <PrivateRoute exact path={routes.configuration.path}>
          <Redirect to={routes.flatPages.list} />
        </PrivateRoute>
        <PrivateRoute exact path={routes.logs.path}>
          <Redirect to={routes.telemetry} />
        </PrivateRoute>
        <PrivateRoute exact path={routes.notifications.path}>
          <Redirect to={routes.notifications.systemNotifications.list} />
        </PrivateRoute>
        <PrivateRoute exact path={routes.osAndAppVersions.path}>
          <Redirect to={routes.osAndAppVersions.userAgents} />
        </PrivateRoute>
        <PrivateRoute exact path={routes.leads.path}>
          <Redirect to={routes.leads.leadProvidersRates} />
        </PrivateRoute>
        <PrivateRoute exact path={routes.leads.reports.path}>
          <Redirect to={routes.leads.reports.affiliateReport} />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={routes.dashboard.applicationErrors.customer.path}
        >
          <Redirect to={routes.dashboard.applicationErrors.list} />
        </PrivateRoute>
        <PrivateRoute exact path={routes.payments.pastDuePayments.path}>
          <Redirect to={routes.payments.pastDuePayments.fileList} />
        </PrivateRoute>
        <PrivateRoute exact path={routes.payments.rerun.path}>
          <Redirect to={routes.payments.rerun.performance} />
        </PrivateRoute>
        <PrivateRoute exact path={routes.csvCompare.path}>
          <Redirect to={routes.csvCompare.comparisons} />
        </PrivateRoute>
        <PrivateRoute exact path={routes.dashboard.internalPerformance.path}>
          <Redirect to={routes.dashboard.internalPerformance.web} />
        </PrivateRoute>
        <PrivateRoute exact path={routes.dashboard.internalPerformance.path}>
          <Redirect to={routes.dashboard.internalPerformance.web} />
        </PrivateRoute>
        <PrivateRoute exact path={routes.payments.scheduledPayments.path}>
          <Redirect to={routes.payments.scheduledPayments.performance} />
        </PrivateRoute>
        <PrivateRoute exact path={routes.binChecker.path}>
          <Redirect to={routes.binChecker.binNumberList} />
        </PrivateRoute>
        <Route component={Page404} />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default AppRouter;
