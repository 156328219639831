const leadManagementApiBaseUrl = process.env.API_LEAD_MANAGEMENT || '';

const leadManagementApiUrls = {
  leadProvider: {
    statistics: '/v1/api/LeadProvider/statistics',
    information: (leadProviderId) =>
      `/v1/api/LeadProvider/information/${leadProviderId}`,
    campaignStatistic: (leadProviderId) =>
      `/v1/api/LeadProvider/campaign-statistics/${leadProviderId}`
  },
  adminLeadProviderRequests: '/v1/AdminLeadProviderRequests',
  leadProviderLeadRequests: '/v1/LeadProviderLeadRequests',
  campaignGroups: {
    list: '/v1/CampaignGroups',
    page: (id) => `/v1/CampaignGroups(${id})`,
    pauseOnCampaignGroupSingle: (id) =>
      `/v1/CampaignGroups(${id})/Default.PauseOnCampaignGroupSingle`
  },
  campaignGroup: {
    list: '/v1/api/CampaignGroup/group',
    page: (id) => `/v1/api/CampaignGroup/group/${id}`,
    campaignStatistic: (campaignGroupId) =>
      `/v1/api/CampaignGroup/campaign-statistics/${campaignGroupId}`
  },
  campaigns: {
    list: '/v1/Campaigns',
    page: (id) => `/v1/Campaigns(${id})`,
    pause: (id) => `/v1/Campaigns(${id})/Default.Pause`
  },
  leadProviders: {
    list: '/v1/LeadProviders',
    page: (id) => `/v1/LeadProviders(${id})`,
    pauseOnLeadProviderSingle: (id) =>
      `/v1/LeadProviders(${id})/Default.PauseOnLeadProviderSingle`
  },
  communicationLog: '/v1/CommunicationLogs',
  weeklySchedules: {
    list: '/v1/WeeklySchedules',
    page: (id) => `/v1/WeeklySchedules(${id})`
  },
  scheduleManagement: (id) =>
    `/v1/api/ScheduleManagement/weekly-schedule/${id}`,
  common: {
    campaigns: 'v1/api/common/campaigns'
  },
  dashboard: {
    leadProvidersRates: '/v1/api/LeadProviderDashboard/lead-providers/rates',
    leadProvidersRatesGlobal:
      '/v1/api/LeadProviderDashboard/lead-providers/rates/global',
    campaignGroupsRates:
      '/v1/api/LeadProviderDashboard/lead-providers/groups/rates'
  }
};

export { leadManagementApiBaseUrl, leadManagementApiUrls };
