import { Auth } from '@eloanwarehouse/frontend-core';

import { CLIENT_ID, SCOPES_LIST, API_AUTH_BASE } from '@constants/common';
import routes from '@constants/routes';
import Api from '@utils/api';
import iamApi from '@utils/iamApi';
import history from '@utils/history';
import Permissions from '@utils/permissions';
import OrganizationForLP from '@utils/organization';
import helpers from '@utils/helpers';
import Logger from '@utils/logger';

export const authService = new Auth.IAMAuthService({
  clientId: CLIENT_ID,
  scopes: [SCOPES_LIST.OFFLINE_ACCESS],
  apiUrl: API_AUTH_BASE,
  callbackUrl: window.location.origin + routes.auth.accessVerification,
  onTokenChange(token) {
    if (token) {
      Api.defaults.headers.Authorization = `Bearer ${token}`;
      iamApi.defaults.headers.Authorization = `Bearer ${token}`;
    } else {
      delete Api.defaults.headers.Authorization;
      delete iamApi.defaults.headers.Authorization;
    }
  },
  onLogin() {
    (async function () {
      try {
        const response = await authService.fetchUserInfo();
        const permissions = response.data.policies;
        if (response.data.organization) {
          OrganizationForLP.set(response.data.organization);
        }

        const mainPage = helpers.getMainPageRedirect({
          permissions: permissions
        });

        history.push(mainPage);
      } catch (error) {
        Logger.error(error);
      }
    })();
  },
  onLogout() {
    Permissions.remove();
    OrganizationForLP.remove();
    void history.push(routes.auth.login);
  },
  onError() {
    void history.push(routes.auth.login);
  }
});
