import qs from 'query-string';

export const exportTableConfig = {
  and: ' and ',
  eq: ' eq ',
  state: 'State',
  leadCampaignId: 'LeadCampaignId'
};

export const getQueryString = (history) => {
  const queryParams = qs.parse(history?.location?.search).$filter;

  if (!queryParams) {
    return '';
  }

  const parsedParams = JSON.parse(queryParams);

  if (!parsedParams.Date) {
    return '';
  }

  const { and, eq, state, leadCampaignId } = exportTableConfig;

  const dateQueryString = Object.entries(parsedParams.Date)
    .map(([operator, date]) => `Date ${operator} ${date}`)
    .join(and);

  delete parsedParams.Date;

  const restParams = parsedParams;
  const stateParam = restParams.State
    ? and + state + eq + `'${restParams.State}'`
    : '';

  const leadCampaignIdParams = restParams.LeadCampaignId
    ? and + leadCampaignId + eq + restParams.LeadCampaignId
    : '';

  const queryString =
    '?' +
    qs.stringify({
      $filter: dateQueryString + stateParam + leadCampaignIdParams
    });

  return queryString;
};
