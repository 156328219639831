import { createAsyncThunk } from '@reduxjs/toolkit';

import Logger from '@utils/logger';
import { authService } from '@services/auth';
import Permissions from '@utils/permissions';
import OrganizationForLP from '@utils/organization';

export const fetchCurrentUser = createAsyncThunk(
  'currentUser/fetchCurrentUser',
  async () => {
    try {
      const response = await authService.fetchUserInfo();
      Permissions.set(response.data.policies);
      if (response.data.organization) {
        OrganizationForLP.set(response.data.organization);
      }
      return response.data;
    } catch (e) {
      Logger.error(e);
    }
  }
);
