import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router';
import qs from 'query-string';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { filterFormInitialValue } from './config';

import { shortcutsItems } from '@components/LeadReports/filters/config';
import Stack from '@components/shared/Stack/Stack';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Button from '@components/shared/Button/Button';
import { fieldWidth } from '@constants/filters';
import { serverDateFormat, types } from '@constants/common';

const PastDueFilterForm = ({ datePickerLabel = 'Date between' }) => {
  const history = useHistory();

  const handleFilterUpdate = useCallback(
    (values, callback) => {
      let dateField = Object.keys(values)[0];
      let dateRange = values[dateField];
      const filterParams = {};

      const getParams = () =>
        (filterParams[dateField] = {
          ge: dayjs(dateRange[0]).format(serverDateFormat),
          le: dayjs(dateRange[1]).format(serverDateFormat)
        });

      const getUpdatedLocation = () => ({
        pathname: history.location.pathname,
        search: qs.stringify({
          ...qs.parse(history.location.search),
          $filter: JSON.stringify(filterParams)
        })
      });

      if (typeof callback === types.function) {
        callback();
        dateField = Object.keys(filterFormInitialValue)[0];
        dateRange = filterFormInitialValue[dateField];
        getParams();
        return history.push(getUpdatedLocation());
      }

      getParams();
      return history.push(getUpdatedLocation());
    },
    [history.location.search]
  );

  return (
    <Formik
      initialValues={filterFormInitialValue}
      onSubmit={handleFilterUpdate}
      enableReinitialize
    >
      {({ values, resetForm }) => (
        <Form className="page-filter">
          <Stack
            direction="row"
            gap={1}
            alignItems="flex-start"
            flexWrap="wrap"
          >
            <FormikField
              type="dateRange"
              name="createdDate"
              label={datePickerLabel}
              size="small"
              shortcutsItems={shortcutsItems}
              sx={{ ...fieldWidth, marginTop: '-8px' }}
            />
            <Stack direction="row" gap={1} alignItems="center">
              <Button type="submit">Apply</Button>
              <Button
                variant="outlined"
                onClick={() => handleFilterUpdate(values, resetForm)}
                disabled={isEqual(filterFormInitialValue, values)}
              >
                Reset
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default PastDueFilterForm;

PastDueFilterForm.propTypes = {
  datePickerLabel: PropTypes.string
};
