import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { columns } from './config';
import NotifiedUsersListModal from './NotifiedUsersListModal';

import DataTable from '@components/shared/DataTable/DataTable';
import { selectServicesStatuses } from '@store/settings';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Typography from '@components/shared/Typography/Typography';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';
import { fetchServicesStatuses } from '@store/settings/actions';
import { RefreshIcon } from '@components/shared/icons/Icons';

const HealthStatus = () => {
  const [isNotifiedUsersModalOpen, setIsNotifiedUsersModalOpen] =
    useState(false);
  const { data: servicesStatuses, isLoading: areServicesStatusesLoading } =
    useSelector(selectServicesStatuses);
  const dispatch = useDispatch();

  const refreshServicesStatuses = () => {
    dispatch(fetchServicesStatuses());
  };

  const handleOpenNotifiedUsersModal = () => {
    setIsNotifiedUsersModalOpen(true);
  };
  const handleCloseNotifiedUsersModal = () => {
    setIsNotifiedUsersModalOpen(false);
  };

  return (
    <MainLayout title="Health status">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h2">Health status</Typography>
        <Stack flexDirection="row" gap={2}>
          <Button onClick={handleOpenNotifiedUsersModal}>
            Notified users list
          </Button>
          <Button
            onClick={refreshServicesStatuses}
            variant="outlined"
            startIcon={<RefreshIcon />}
          >
            Refresh
          </Button>
        </Stack>
      </Stack>
      <DataTable
        disableColumnFilter
        hideFooter
        loading={areServicesStatusesLoading}
        tableData={servicesStatuses}
        columns={columns}
        rowKey="projectWebName"
        getRowHeight={() => 'auto'}
        paginationOptions={{
          current: 1,
          pageSize: servicesStatuses?.length ?? 0,
          additionalPageSize: servicesStatuses?.length ?? 0
        }}
      />
      <NotifiedUsersListModal
        isOpen={isNotifiedUsersModalOpen}
        handleClose={handleCloseNotifiedUsersModal}
      />
    </MainLayout>
  );
};

export default HealthStatus;
