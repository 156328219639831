import React, { useEffect } from 'react';
import PT from 'prop-types';
import { Alert } from '@mui/material';

import { getColumns } from './config';

import { useServerData } from '@hooks/useServerData';
import DataTable from '@components/shared/DataTable/DataTable';
import toastify from '@utils/toastify';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const IDVImagesTab = ({ id }) => {
  const { data, isLoading, error, fetchData } = useServerData({
    endpoint: adminApiUrls.applicationIDVImages.list(id),
    customApi: AdminApi
  });

  useEffect(() => {
    fetchData();
  }, []);

  const handleDownload = async ({ resourceId, name }) => {
    try {
      const response = await AdminApi.get(
        adminApiUrls.applicationIDVImages.download(resourceId),
        {
          responseType: 'blob'
        }
      );

      const blob = new Blob([response], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      toastify.success({
        message: 'Image downloaded'
      });
    } catch (error) {
      toastify.error({
        message: 'Download operation failure'
      });
    }
  };

  const columns = getColumns(handleDownload);

  return (
    <>
      {!error && (
        <>
          <DataTable
            hideFilters
            tableData={data || []}
            columns={columns}
            loading={isLoading}
            rowKey="resourceId"
            pagination={false}
            hideFooter
            columnBuffer={2}
            columnThreshold={2}
          />
        </>
      )}
      {error && (
        <Alert
          title="Server Error"
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
          sx={{ mt: 3 }}
        />
      )}
    </>
  );
};

IDVImagesTab.propTypes = {
  id: PT.string.isRequired
};

export default IDVImagesTab;
