import * as Yup from 'yup';

export const notifiedUsersValidationSchema = Yup.object().shape({
  emails: Yup.array().of(Yup.string().email().label('Email')),
  email: Yup.string()
    .email()
    .label('Email')
    .test('not-already-selected', 'Email already added', function (value) {
      return !this.parent.emails.includes(value);
    })
});
