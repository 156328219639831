import React, { useMemo } from 'react';
import PT from 'prop-types';

import Stack from '@components/shared/Stack/Stack';
import { Checkbox } from '@components/shared/Form/Checkbox';

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const DaysOfWeekList = ({ selectedDays, setData, isReadOnly }) => {
  const daysList = useMemo(
    () =>
      weekDays.reduce(
        (acc, _, idx) => [
          ...acc,
          {
            id: idx,
            label: weekDays[idx],
            checked: selectedDays?.includes(idx)
          }
        ],
        []
      ),
    [selectedDays, setData]
  );

  const markDay = (id, value) => {
    let newList;
    if (value) {
      newList = [...selectedDays, id];
    } else {
      newList = selectedDays.filter((pr) => pr !== id);
    }

    setData(newList);
  };

  return (
    <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
      {daysList.map(({ id, ...item }) => (
        <Checkbox
          key={id}
          name={item.label + id}
          onChange={(_, newValue) => markDay(id, newValue)}
          disabled={isReadOnly}
          {...item}
        />
      ))}
    </Stack>
  );
};

DaysOfWeekList.propTypes = {
  selectedDays: PT.arrayOf(PT.number),
  setData: PT.func.isRequired,
  isReadOnly: PT.bool
};

export default DaysOfWeekList;
