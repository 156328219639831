import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';

import { defaultPageSize, defaultPageSizeOptions } from '@constants/common';
import Api from '@utils/api';
import Logger from '@utils/logger';
import { filterParser } from '@utils/jsonDateParser';

export const defaultFilterParams = {
  $filter: {},
  $top: defaultPageSize,
  $skip: 0,
  $count: true
};

export const usePaginatedData = ({
  apiUrl,
  initialFilterParams = defaultFilterParams,
  transformServerResponse,
  stopFetching = false,
  customApi = null,
  signal
}) => {
  const history = useHistory();
  const parsedParams = useMemo(() => {
    if (!history.location.search) return {};

    const params = qs.parse(history.location.search, {
      parseBooleans: true,
      parseNumbers: true
    });

    if (params.$filter) {
      params.$filter = JSON.parse(params.$filter, filterParser);
    }

    return params;
  }, [history.location?.search]);

  const [data, setData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(null);
  const [loading, setLoading] = useState(null);

  const setParams = (newParams) => {
    const queryParams = newParams;

    if (queryParams.$filter) {
      queryParams.$filter = JSON.stringify(queryParams.$filter);
    }

    history.replace({
      pathname: history.location.pathname,
      search: qs.stringify(queryParams)
    });
  };

  const getData = async () => {
    if (stopFetching) return;

    try {
      setLoading(true);

      const api = customApi ?? Api;
      let data = await api.get(apiUrl, { params: parsedParams, signal });
      if (transformServerResponse) {
        data = transformServerResponse(data);
      }

      setData(data.value);
      setTotalAmount(data['@odata.count']);
    } catch (e) {
      setData([]);
      setTotalAmount(0);
      Logger.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!history.location?.search) {
      return setParams({
        ...defaultFilterParams,
        ...initialFilterParams
      });
    } else {
      getData();
    }
  }, [history.location?.search]);

  const paginationOptions = {
    pageSize: parsedParams.$top,
    total: totalAmount,
    showSizeChanger: true,
    current: parsedParams.$skip
      ? Math.ceil(parsedParams.$skip / parsedParams.$top) + 1
      : 1,
    pageSizeOptions: defaultPageSizeOptions
  };

  const handleSortingChange = (sortData) => {
    const orderBy = sortData.reduce(
      (acc, { field, sort }) => `${field} ${sort}`,
      ''
    );

    if (orderBy) {
      setParams({
        ...parsedParams,
        $orderBy: `${orderBy}`
      });
    } else if (initialFilterParams.$orderBy) {
      setParams({
        ...parsedParams,
        $orderBy: initialFilterParams.$orderBy
      });
    } else {
      delete parsedParams.$orderBy;
      setParams(parsedParams);
    }
  };

  const handleOrderChange = (pagination, sortData) => {
    if (sortData) {
      handleSortingChange(sortData);
      return;
    }

    const otherData = {
      $top: pagination.pageSize,
      $skip: pagination.pageSize * (pagination.current - 1)
    };
    setParams({
      ...parsedParams,
      ...otherData
    });
  };

  return {
    filterParams: parsedParams,
    setFilterParams: setParams,
    getData,
    setData,
    setOrder: handleOrderChange,
    totalAmount,
    loading,
    data,
    paginationOptions
  };
};
