import { toast } from 'react-toastify';

const defaultProps = {
  theme: 'light',
  position: 'top-right',
  hideProgressBar: true,
  closeOnClick: true,
  autoClose: false,
  draggable: true,
  closeButton: true
};

class Toastify {
  success = ({ message }, { props } = {}) => {
    toast.success(message, {
      ...defaultProps,
      ...props
    });
  };

  error = ({ message }, { props } = {}) => {
    toast.error(message, {
      ...defaultProps,
      ...props
    });
  };

  warn = ({ message }, { props } = {}) => {
    toast.warn(message, {
      ...defaultProps,
      ...props
    });
  };

  info = ({ message }, { props } = {}) => {
    toast.info(message, {
      ...defaultProps,
      ...props
    });
  };
}

export default new Toastify();
