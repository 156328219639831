import { Form, Formik } from 'formik';
import PT from 'prop-types';
import React, { useState } from 'react';

import { getInitialValues, validationSchema } from './config';

import IconButton from '@components/shared/IconButton/IconButton';
import { SettingsIcon } from '@components/shared/icons/Icons';
import Button from '@components/shared/Button/Button';
import Modal from '@components/shared/Modal/Modal';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Toastify from '@utils/toastify';
import Logger from '@utils/logger';
import { commonButtonStyles } from '@components/shared/DataTable/actions/config';
import { leadManagementApiUrls } from '@constants/leadManagementApi';
import LeadManagementApi from '@utils/leadManagementApi';

const ManageGlobalRatesButton = ({ globalRates, onSuccess }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit = async (values, actions) => {
    try {
      await LeadManagementApi.patch(
        leadManagementApiUrls.leadProviders.page(globalRates.leadProviderId),
        values
      );
      onSuccess({ ...globalRates, ...values });
      Toastify.success({ message: 'Global rates saved' });
      handleCloseModal();
    } catch (e) {
      Logger.error(e);
      Toastify.error({ message: 'Something went wrong saving global rates' });
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <IconButton
        size="small"
        sx={commonButtonStyles}
        onClick={() => setIsModalOpen(true)}
      >
        <SettingsIcon />
      </IconButton>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        title="Manage total caps"
        withCloseIcon
      >
        <Formik
          onSubmit={onSubmit}
          initialValues={getInitialValues(globalRates)}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form>
              <FormikField
                type="number"
                name="dailyLookCap"
                label="Daily look cap"
              />
              <FormikField
                type="number"
                name="hourlyLookCap"
                label="Hourly look cap"
              />
              <FormikField
                type="number"
                name="dailyAcceptCap"
                label="Daily accept cap"
              />
              <FormikField
                type="number"
                name="hourlyAcceptCap"
                label="Hourly accept cap"
              />
              <FormikField
                type="number"
                name="dailyCostCap"
                label="Daily cost cap"
              />

              <Button
                type="submit"
                disabled={isSubmitting || !isValid || !dirty}
                loading={isSubmitting}
                sx={{ width: '100%', mt: 2, mb: 1 }}
              >
                Save
              </Button>
              <Button
                sx={{ width: '100%' }}
                variant="outlined"
                onClick={handleCloseModal}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

ManageGlobalRatesButton.propTypes = {
  globalRates: PT.object.isRequired,
  onSuccess: PT.func.isRequired
};

export default ManageGlobalRatesButton;
