import React, { useMemo } from 'react';
import PT from 'prop-types';

import ErrorsTable from './ErrorsTable';
import { columns } from './config';

import helpers from '@utils/helpers';
import Box from '@components/shared/Box/Box';
import DataTable from '@components/shared/DataTable/DataTable';

const TelemetryDataTable = ({ data }) => {
  const parsedData = useMemo(
    () =>
      data?.map((item, index) => ({
        ...item,
        key: index
      })),
    [data]
  );

  const expandedByDefault = useMemo(
    () =>
      parsedData?.filter(({ logs }) => !!logs?.length).map(({ key }) => key),
    [parsedData]
  );

  const totalTimeSeconds = helpers.getDateDiffString(
    data?.reduce(
      (acc, { pulse }) => (pulse?.pulse ? acc + pulse?.pulse : acc),
      0
    )
  );

  const renderErrors = (logs) => <ErrorsTable logs={logs} />;

  return (
    <Box my={1}>
      <Box mb={1}>
        <b>Total time user spent on application:</b> {totalTimeSeconds}
      </Box>
      <DataTable
        disableColumnFilter
        columns={columns}
        tableData={parsedData}
        rowKey="key"
        getDetailPanelContent={({ row }) =>
          row?.logs?.length && renderErrors(row?.logs)
        }
        expandedByDefault={expandedByDefault}
        canExpand={(row) => !!row.logs?.length}
        detailPanelHeight="auto"
        pagination={false}
        hideFooter
      />
    </Box>
  );
};

TelemetryDataTable.defaultProps = {
  field: null
};

TelemetryDataTable.propTypes = {
  data: PT.arrayOf(
    PT.shape({
      logs: PT.array,
      pulse: PT.object
    })
  ).isRequired
};

export default TelemetryDataTable;
