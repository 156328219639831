import React from 'react';
import { Link } from 'react-router-dom';

import helpers from '@utils/helpers';
import routes from '@constants/routes';
import {
  filterOperators,
  loanTypeFilterMap,
  noFieldData,
  permissionsMap
} from '@constants/common';
import { cleanObject } from '@utils/object-cleaner';
import UserEventsLink from '@components/shared/UserEventsLink';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import { phoneNumberColumnWidth } from '@components/shared/DataTable/DataTable.constants';
import { CheckCircleIcon } from '@components/shared/icons/Icons';

export const initialFilterParams = cleanObject({
  loanId: {
    contains: ''
  },
  applicationId: {
    contains: ''
  },
  loanNumber: {
    contains: ''
  },
  requestedOn: {
    ge: '',
    le: ''
  }
});

export const getColumns = () => [
  {
    key: 0,
    headerName: 'Loan Number',
    field: 'loanNumber',
    minWidth: 120,
    flex: 1
  },
  {
    key: 1,
    headerName: 'Email',
    exportField: 'customer.email',
    field: 'customer/email',
    minWidth: 280,
    flex: 1,
    renderCell: ({ row }) =>
      helpers.getActionPermission(permissionsMap.usersView) &&
      row?.userId &&
      row.customer?.email ? (
        <Tooltip title="User page">
          <Link to={routes.users.page.view.url(row.userId)}>
            {row.customer.email}
          </Link>
        </Tooltip>
      ) : (
        row.customer?.email || '-'
      )
  },
  {
    key: 2,
    headerName: 'Phone Number',
    exportField: 'customer.phone',
    field: 'customer/phone',
    minWidth: phoneNumberColumnWidth,
    flex: 1,
    renderCell: ({ row }) => row?.customer?.phone || '-'
  },
  {
    key: 3,
    headerName: 'Loan Type',
    field: 'loanType',
    minWidth: 100,
    type: muiDataTableTypes.singleSelect,
    flex: 1,
    valueOptions: loanTypeFilterMap,
    renderCell: ({ row }) => helpers.getLoanType(row?.loanType)
  },
  {
    key: 4,
    headerName: 'Web signed',
    field: 'status',
    width: 70,
    flex: 1,
    type: muiDataTableTypes.singleSelect,
    valueOptions: [
      { label: 'Signed', value: 'S' },
      { label: 'Unsigned', value: 'P' }
    ],
    renderCell: ({ row }) => (row?.status === 'S' ? <CheckCircleIcon /> : '')
  },
  {
    key: 5,
    headerName: 'Requested On',
    field: 'requestedOn',
    minWidth: 210,
    flex: 1,
    valueFormatter: (requestedOn) => helpers.getFormattedDate(requestedOn),
    renderCell: ({ row }) => helpers.getFormattedDate(row?.requestedOn),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    key: 6,
    headerName: 'Application Id',
    exportField: 'applicationId',
    minWidth: 120,
    flex: 1,
    field: 'applicationId',
    type: muiDataTableTypes.number,
    renderCell: ({ row }) =>
      helpers.getActionPermission(permissionsMap.applicationView) &&
      row?.customer ? (
        <Link to={routes.applications.page.view.url(row.applicationId)}>
          {row.applicationId}
        </Link>
      ) : (
        row?.applicationId || '-'
      )
  },
  {
    key: 7,
    headerName: 'Loan Id',
    field: 'loanId',
    minWidth: 100,
    flex: 1,
    renderCell: ({ row }) => row.loanId,
    type: muiDataTableTypes.number
  },
  {
    key: 8,
    headerName: 'Events',
    field: 'userId',
    minWidth: 90,
    flex: 1,
    renderCell: ({ row }) =>
      helpers.getActionPermission(permissionsMap.loggedView) ? (
        <UserEventsLink executedBy={row?.userId}>
          {helpers.cutString(row?.userId, 5)}
        </UserEventsLink>
      ) : (
        row?.userId || noFieldData
      )
  }
];
