import axios from 'axios';
import qs from 'query-string';

import helpers from '@utils/helpers';
import { adminApiBaseUrl } from '@constants/adminApiUrls';
import { authService } from '@services/auth';

const headers = {
  'Accept-Language': 'en',
  'Content-Type': 'application/json'
};

const AdminApi = axios.create({
  baseURL: adminApiBaseUrl,
  headers,
  paramsSerializer: function (params) {
    // is we detect at least one params which start with '$' - we are using odata-query
    if (Object.keys(params).some((paramKey) => paramKey.startsWith('$'))) {
      return helpers.parseCSVODataRequest(params);
    }

    return qs.stringify(params);
  }
});

AdminApi.interceptors.request.use(
  async (config) => {
    await authService.axiosRequestMiddleware(config.headers);

    config.headers.Authorization = `Bearer ${authService.getToken()}`;

    return {
      ...config,
      url: encodeURI(config.url)
    };
  },
  (error) => Promise.reject(error)
);

AdminApi.interceptors.response.use(
  (res) => res.data,
  (err) => {
    void authService.axiosResponseMiddleware(err.response?.status);
    return Promise.reject(helpers.handleServerErrors(err));
  }
);

export default AdminApi;
