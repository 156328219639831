import React from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import PromoCodeForm from '@components/PromoCodes/Form';
import routes from '@constants/routes';
import Logger from '@utils/logger';
import toastify from '@utils/toastify';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const PromoCodeCreate = () => {
  const history = useHistory();

  const handleSubmit = async (data) => {
    const payload = {
      ...data,
      ...(data?.expirationDate && {
        expirationDate: dayjs(data?.expirationDate).toISOString()
      }),
      ...(data?.startDate && {
        startDate: dayjs(data?.startDate).toISOString()
      })
    };
    try {
      await AdminApi.post(adminApiUrls.promoCodes.list, payload);
      history.push(routes.promoCodes.list);
    } catch (e) {
      Logger.error(e);
      toastify.error({
        message: 'Create operation failure'
      });
    }
  };

  return (
    <MainLayout title="Create promo code">
      <PromoCodeForm handleSubmit={handleSubmit} />
    </MainLayout>
  );
};

export default PromoCodeCreate;
