import * as Yup from 'yup';
import dayjs from 'dayjs';

import validators from '@utils/validators';
import {
  loanDefaultLimits,
  loanDefaultValue,
  dateFormat
} from '@constants/common';

export const loanAmountSchema = Yup.object().shape({
  amount: Yup.number()
    .min(loanDefaultLimits.min)
    .max(loanDefaultLimits.max)
    .integer()
    .label('Loan amount')
    .typeError('Only digits are allowed')
    .required()
});

export const generalStepFieldsSchema = Yup.object().shape({
  firstName: Yup.string().min(1).max(50).label('First name').required(),
  lastName: Yup.string().min(1).max(100).label('Last name').required(),
  dateOfBirth: Yup.date()
    .max(dayjs().subtract(18, 'year').toDate(), 'Min allowed age is 18')
    .min(
      dayjs('1930-01-01').format(dateFormat),
      'Min allowed year of birth is 1930'
    )
    .label('Date of birth')
    .typeError(({ label }) => `${label} is required field`)
    .required(),
  email: validators.email('Email').max(150).required(),
  confirmEmail: validators
    .fieldConfirm('email', 'Confirmation email', 'Emails are not matched')
    .max(150)
    .required(),
  phone: Yup.string().label('Phone number').min(10).required(),
  ssn: Yup.string().label('Social security number').min(9).required(),
  dlLicenseNumber: validators
    .regExp(
      'DL number',
      /^[A-Za-z0-9]*$/,
      'Only latin symbols and digits are allowed'
    )
    .min(1)
    .max(20)
    .required(),
  dlLicenseState: Yup.string().label('DL State').required(),
  promo: Yup.string().min(4).max(15).nullable().label('Promo'),
  phoneCommunication: Yup.bool(),
  emailCommunication: Yup.bool()
});

export const generalStepSchema =
  generalStepFieldsSchema.concat(loanAmountSchema);

export const generalStepInitialValues = {
  amount: loanDefaultValue,
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  confirmEmail: '',
  emailCommunication: true,
  phone: '',
  phoneCommunication: true,
  ssn: '',
  dlLicenseNumber: '',
  dlLicenseState: '',
  promo: ''
};
