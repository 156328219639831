import React from 'react';
import dayjs from 'dayjs';
import PT from 'prop-types';

import { daysOfWeekOptions } from '../../Form/WorkingCalendarStep/schema';

import helpers from '@utils/helpers';
import { shortTimeFormat } from '@constants/common';
import DataTable from '@components/shared/DataTable/DataTable';

const WorkingCalendar = ({ data }) => {
  const calendarColumns = [
    {
      headerName: 'Day of week',
      field: 'dayOfWeek',
      minWidth: 120,
      flex: 1
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 100,
      flex: 1
    },
    {
      headerName: 'Start time',
      field: 'startWork',
      minWidth: 120,
      flex: 1
    },
    {
      headerName: 'Stop Time',
      field: 'stopWork',
      minWidth: 120,
      flex: 1
    }
  ];

  const calendarData = (serverData) =>
    daysOfWeekOptions.map((item, index) => {
      const element = (serverData.weeklySchedule?.workDays || []).find(
        (elem) => elem.dayOfWeek === index
      );

      if (!element) {
        return {
          dayOfWeek: item,
          status: 'Inactive',
          startWork: '-',
          stopWork: '-'
        };
      }
      return {
        dayOfWeek: item,
        status: 'Active',
        startWork: dayjs(
          helpers.formatFromServerTime(element.startWork),
          shortTimeFormat.server
        ).format(shortTimeFormat.front),
        stopWork: dayjs(
          helpers.formatFromServerTime(element.stopWork),
          shortTimeFormat.server
        ).format(shortTimeFormat.front)
      };
    });

  const tableData = calendarData(data);

  return (
    <DataTable
      disableColumnFilter
      columns={calendarColumns}
      tableData={tableData || []}
      rowKey="dayOfWeek"
      pagination={false}
      hideFooter
    />
  );
};

WorkingCalendar.propTypes = {
  data: PT.any.isRequired
};

export default WorkingCalendar;
