import { styled } from '@mui/material/styles';
import Step from '@mui/material/Step';

export const StyledStep = styled(Step)`
  && {
    .MuiStepLabel-label.Mui-active {
      color: ${({ theme }) => theme.palette.primary.main};
    }
    .Mui-disabled {
      cursor: pointer;
    }
    .MuiStep-vertical {
      cursor: pointer;
    }
    .MuiStep-root {
      cursor: pointer;
    }
  }
`;
