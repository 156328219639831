import React, { useEffect, useMemo } from 'react';
import PT from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { StyledMainBox } from './styled';
import SidebarMenu from './SidebarMenu';

import Breadcrumbs from '@components/shared/Breadcrumbs/Breadcrumbs';
import HeaderComponent from '@components/shared/Header';
import Loader from '@components/shared/Loader';
import { closeSidebar, openSidebar, selectSidebarIsOpen } from '@store/ui';
import { selectCurrentUser } from '@store/currentUser';
import { fetchCurrentUser } from '@store/currentUser/actions';
import Permissions from '@utils/permissions';

const MainLayout = ({ children, title, isLoading }) => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(selectSidebarIsOpen);
  const storeCurrentUser = useSelector(selectCurrentUser);
  const permissions = Permissions.get();

  const currentUser = useMemo(
    () => storeCurrentUser,
    [storeCurrentUser?.userName, storeCurrentUser?.firstName]
  );

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {[title, 'eLoanWarehouse Manager'].filter(Boolean).join(' | ')}
        </title>
      </Helmet>

      {!permissions ? (
        <Loader />
      ) : (
        <>
          <HeaderComponent currentUser={currentUser} />
          <SidebarMenu
            isOpen={isSidebarOpen}
            onToggleSidebar={() =>
              dispatch(isSidebarOpen ? closeSidebar() : openSidebar())
            }
          />
          <StyledMainBox
            open={isSidebarOpen}
            component="main"
            sx={{
              flexGrow: 1,
              px: { sm: 3 },
              margin: 0,
              minHeight: '100vh'
            }}
          >
            <Breadcrumbs />
            {isLoading ? <Loader /> : children}
          </StyledMainBox>
        </>
      )}
    </>
  );
};

MainLayout.defaultProps = {
  isLoading: false
};

MainLayout.propTypes = {
  children: PT.any.isRequired,
  title: PT.string,
  isLoading: PT.bool
};

export default MainLayout;
