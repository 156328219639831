import React from 'react';
import PT from 'prop-types';
import Chip from '@mui/material/Chip';

import Stack from '@components/shared/Stack/Stack';

export const TabContent = ({ children, value, idx, ...props }) => (
  <div
    role="tabpanel"
    hidden={value !== idx}
    id={`simple-tabpanel-${idx}`}
    aria-labelledby={`simple-tab-${idx}`}
    {...props}
  >
    {value === idx && <div>{children}</div>}
  </div>
);
TabContent.propTypes = {
  children: PT.node.isRequired,
  value: PT.number.isRequired,
  idx: PT.number.isRequired
};

export const TabLabel = ({ label, count }) => (
  <Stack direction="row" alignItems="center" gap="10px">
    <div>{label}</div>
    {(count || count === 0) && (
      <Chip
        label={count}
        size="small"
        color="primary"
        style={{ lineHeight: '1em' }}
      />
    )}
  </Stack>
);
TabLabel.propTypes = {
  label: PT.string.isRequired,
  count: PT.string
};
