import { Form, Formik } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

import { editABANumberFormInitialValues } from './abaNumbersList.config';
import { addABANumberSchema } from './addABANumber.schema';

import FormikField from '@components/shared/Form/FormikField/FormikField';
import Button from '@components/shared/Button/Button';
import { statesOptions } from '@constants/states';

const EditABANumberForm = ({
  onSubmit,
  isLoading,
  onModalClose,
  dataForEdit,
  sourceOptions
}) => (
  <>
    <Formik
      initialValues={editABANumberFormInitialValues(dataForEdit)}
      validationSchema={addABANumberSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid }) => (
        <Form>
          <Stack gap={1} mb={1} justifyContent="center">
            <FormikField
              type="text"
              name="routingNumber"
              label="Routing number"
              size="small"
              placeholder="Enter Routing Number"
              disabled
            />
            <FormikField
              type="text"
              name="bankName"
              label="Bank name"
              size="small"
              placeholder="Enter bank name"
            />
            <FormikField
              type="select"
              name="state"
              label="State"
              options={statesOptions}
            />
            <FormikField
              type="select"
              name="bankSourceId"
              label="Source"
              options={sourceOptions}
            />
            <FormikField
              type="checkbox"
              name="isBankTranslationNeeded"
              label="Is Bank Name Translation needed"
            />
            <FormikField
              type="text"
              name="bankTranslation"
              label="Translation Name"
              size="small"
              placeholder="Enter translation name"
              showErrorUntouched
            />
          </Stack>
          <Stack direction="row" gap={1} justifyContent="center">
            <Button
              type="submit"
              disabled={!isValid || isLoading}
              loading={isLoading}
            >
              Save
            </Button>
            <Button
              disabled={isLoading}
              variant="outlined"
              onClick={onModalClose}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  </>
);

EditABANumberForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  dataForEdit: PropTypes.object.isRequired,
  sourceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired
};

export default EditABANumberForm;
