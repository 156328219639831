import React from 'react';
import Typography from '@mui/material/Typography';
import PT from 'prop-types';

import { loanFormStepsOrder, stepsConfigMap } from '@constants/loanFormConfig';
import Loader from '@components/shared/Loader';
import { Stepper, Step, StepLabel } from '@components/shared/Stepper/Stepper';

const LoanSteps = ({ activeStep, setActiveStep, lastFilledStep }) => {
  if (!activeStep) return <Loader />;

  const activeStepIndex = loanFormStepsOrder.indexOf(activeStep);
  const lastFilledStepIndex = loanFormStepsOrder.indexOf(lastFilledStep);

  const handleStepChange = (stepKey) => {
    setActiveStep(stepKey);
  };

  return (
    <div>
      <Typography variant="h4" component="div" my={2}>
        Form steps
      </Typography>

      <Stepper activeStep={activeStepIndex} orientation="vertical">
        {loanFormStepsOrder.map((stepKey, idx) => (
          <Step
            key={idx}
            completed={idx <= lastFilledStepIndex}
            disabled={idx > lastFilledStepIndex + 1}
            onClick={() => handleStepChange(stepKey)}
          >
            <StepLabel>{stepsConfigMap[stepKey]?.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

LoanSteps.propTypes = {
  activeStep: PT.string,
  setActiveStep: PT.func.isRequired,
  lastFilledStep: PT.string
};

export default React.memo(LoanSteps);
