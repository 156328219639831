import React from 'react';
import { useHistory } from 'react-router-dom';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import routes from '@constants/routes';
import CampaignGroupForm from '@components/Leads/campaignGroups/Form';
import toastify from '@utils/toastify';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

const CampaignGroupCreatePage = () => {
  const history = useHistory();

  const handleSubmit = async (
    { values, actions },
    { onSuccess, onError, onFinish }
  ) => {
    try {
      const response = await LeadManagementApi.post(
        leadManagementApiUrls.campaignGroup.list,
        values
      );

      history.push(routes.leads.campaignGroups.list);

      toastify.success({
        message: 'Create operation success'
      });

      onSuccess && onSuccess(response);
    } catch (e) {
      onError && onError(e);

      actions.setErrors({
        ...e,
        nonFieldErrors:
          e.nonFieldErrors || e?.response?.data?.error?.message || e.message
      });

      toastify.error({
        message: 'Error',
        description: 'Failed to submit form'
      });
    } finally {
      onFinish && onFinish();
    }
  };

  return (
    <MainLayout title="Create Campaign">
      <CampaignGroupForm onSubmit={handleSubmit} />
    </MainLayout>
  );
};

export default CampaignGroupCreatePage;
