import { noFieldData } from '@constants/common';

export const columns = [
  {
    headerName: 'Campaign name',
    field: 'leadCampaign',
    minWidth: 120,
    flex: 1,
    renderCell: ({ row, field }) => (row[field] ? row[field] : noFieldData)
  },
  {
    headerName: 'Looks',
    field: 'looks',
    minWidth: 60,
    flex: 1
  },
  {
    headerName: 'Approved',
    field: 'accepted',
    minWidth: 80,
    flex: 1
  },
  {
    headerName: 'Accept rate',
    field: 'appRate',
    minWidth: 90,
    flex: 1,
    renderCell: ({ row }) => row.appRate.toFixed(2)
  },
  {
    headerName: 'Funded',
    field: 'loanApproved',
    minWidth: 65,
    flex: 1
  },
  {
    headerName: 'Conversion rate',
    field: 'conversionRate',
    minWidth: 120,
    flex: 1,
    renderCell: ({ row }) => row.conversionRate.toFixed(2)
  },
  {
    headerName: 'Redirected',
    field: 'redirected',
    minWidth: 85,
    flex: 1
  },
  {
    headerName: 'Redirect rate',
    field: 'redirectRate',
    minWidth: 95,
    flex: 1,
    renderCell: ({ row }) => row.redirectRate.toFixed(2)
  },
  {
    headerName: 'Spend',
    field: 'costSpent',
    minWidth: 60,
    flex: 1,
    renderCell: ({ row }) => row.costSpent.toFixed(2)
  },
  {
    headerName: 'Data cost',
    field: 'dataCost',
    minWidth: 90,
    flex: 1,
    renderCell: ({ row }) => row.dataCost.toFixed(2)
  },
  {
    headerName: 'CPA',
    field: 'cpa',
    minWidth: 60,
    flex: 1,
    renderCell: ({ row }) => row.cpa.toFixed(2)
  },
  {
    headerName: 'Lead CPF',
    field: 'leadCPF',
    minWidth: 90,
    flex: 1,
    renderCell: ({ row }) => row.leadCPF.toFixed(2)
  },
  {
    headerName: 'CPF',
    field: 'cpf',
    minWidth: 60,
    flex: 1,
    renderCell: ({ row }) => row.cpf.toFixed(2)
  },
  {
    headerName: 'FPD',
    field: 'fpd',
    minWidth: 60,
    flex: 1,
    renderCell: ({ row }) => row.fpd.toFixed(2)
  },
  {
    headerName: 'FPD %',
    field: 'fpDrate',
    minWidth: 60,
    flex: 1,
    renderCell: ({ row }) => row.fpDrate.toFixed(2)
  },
  {
    headerName: 'SPD',
    field: 'spd',
    minWidth: 60,
    flex: 1,
    renderCell: ({ row }) => row.spd.toFixed(2)
  },
  {
    headerName: 'SPD %',
    field: 'spDrate',
    minWidth: 60,
    flex: 1,
    renderCell: ({ row }) => row.spDrate.toFixed(2)
  },
  {
    headerName: 'ROI',
    field: 'roi',
    minWidth: 60,
    flex: 1,
    renderCell: ({ row }) => row.roi.toFixed(2)
  }
];
