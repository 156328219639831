import React from 'react';
import { Link } from 'react-router-dom';
import PT from 'prop-types';

import { getLeadProviderColumns } from './config';

import routes from '@constants/routes';
import helpers from '@utils/helpers';
import { noFieldData, permissionsMap } from '@constants/common';
import { useLeadStatsData } from '@components/Leads/leadProviders/Details/LeadStatsList/useLeadStatsData';
import toastify from '@utils/toastify';
import Button from '@components/shared/Button/Button';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import DataTable from '@components/shared/DataTable/DataTable';
import CampaignsDataTable from '@components/Leads/CampaignsDataTable';
import LeadDayHourFilterSeparated from '@components/Leads/DayHourFilterSeparated';
import { leadManagementApiUrls } from '@constants/leadManagementApi';
import LeadManagementApi from '@utils/leadManagementApi';
import DeleteActionButton from '@components/shared/DataTable/actions/DeleteActionButton';

const LeadStatsList = ({ id }) => {
  const {
    leadProviderData,
    isLeadProviderDataLoading,
    refetchLeadProviderData
  } = useLeadStatsData(
    leadManagementApiUrls.leadProvider.campaignStatistic(id)
  );

  const handleDelete = async (record) => {
    try {
      await LeadManagementApi.delete(
        leadManagementApiUrls.campaigns.page(record.campaignId)
      );

      await refetchLeadProviderData();

      toastify.success({
        message: 'Group deleted'
      });
    } catch (e) {
      toastify.error(
        e?.response?.data?.error
          ? e.response.data.error
          : {
              message: 'Delete operation failure'
            }
      );
    }
  };

  const handleStatus = async (id, status) => {
    await LeadManagementApi.post(
      leadManagementApiUrls.leadProviders.pauseOnLeadProviderSingle(id),
      {
        pause: status
      }
    );

    await refetchLeadProviderData();
  };

  const leadProviderColumns = getLeadProviderColumns({
    handleStatus
  });

  return (
    <>
      <LeadDayHourFilterSeparated>
        {helpers.getActionPermission(permissionsMap.leadProvidersManage) && (
          <Button
            component={Link}
            to={routes.leads.leadProviders.campaign.create.url(id)}
          >
            Add new campaign
          </Button>
        )}
      </LeadDayHourFilterSeparated>
      <DataTable
        disableColumnFilter
        columns={leadProviderColumns}
        tableData={leadProviderData ? [leadProviderData] : []}
        rowKey="leadProviderId"
        loading={isLeadProviderDataLoading}
        refetchList={refetchLeadProviderData}
        pagination={false}
        hideFooter
      />
      <CampaignsDataTable
        tableData={leadProviderData?.campaigns || []}
        additionalColumns={{
          startColumns: [],
          endColumns: [
            {
              headerName: 'Group names',
              field: 'groupNames',
              renderCell: ({ row }) =>
                row.groupNames ? row.groupNames.join(', ') : noFieldData
            }
          ]
        }}
        additionalActions={(row) =>
          helpers.getActionPermission(permissionsMap.leadProvidersManage) && (
            <>
              <EditActionButton
                path={routes.leads.leadProviders.campaign.edit.url(
                  id,
                  row.campaignId
                )}
                hidden={
                  !helpers.getActionPermission(
                    permissionsMap.leadProvidersManage
                  )
                }
              />
              <DeleteActionButton
                hidden={
                  !helpers.getActionPermission(
                    permissionsMap.leadProvidersManage
                  )
                }
                itemId={row.leadProviderId}
                onConfirm={() => handleDelete(row)}
              />
            </>
          )
        }
        refetchList={refetchLeadProviderData}
      />
    </>
  );
};

LeadStatsList.propTypes = {
  id: PT.number.isRequired
};

export default LeadStatsList;
