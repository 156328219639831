import React from 'react';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import {
  getColumns,
  subTabsList
} from '@components/DiscrepancyReport/DiscrepancyReport.config';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import SecureVaultReportsApi from '@utils/secureVaultReportsApi';
import { secureVaultReportsApiUrls } from '@constants/secureVaultReportsApiUrls';

const DiscrepancyReportPage = () => (
  <MainLayout title="Discrepancy Report">
    <NavTabsPanel
      currentIdx={0}
      tabsList={subTabsList}
      ariaLabel="access-tabs"
    />
    <ODataPaginatedTable
      getColumns={getColumns}
      usePaginatedDataOptions={{
        apiUrl: secureVaultReportsApiUrls.discrepancyReport.report,
        initialFilterParams: {
          $orderBy: 'dateCreated desc'
        }
      }}
      rowKey="id"
      customApi={SecureVaultReportsApi}
    />
  </MainLayout>
);

export default DiscrepancyReportPage;
