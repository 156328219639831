import React, { useEffect, useMemo, useState } from 'react';
import PT from 'prop-types';
import { useHistory } from 'react-router';
import qs from 'query-string';
import { Formik, Form } from 'formik';
import { Box } from '@mui/material';

import { FilterOptionsContext } from './FilterOptionsContext';

import { useQueryParams } from '@hooks/useQueryParams';
import { cleanObject } from '@utils/object-cleaner';
import {
  filterFieldsConfig,
  filterFieldsOptions
} from '@components/Applications/Filter/config';
import FilterSettingsModal from '@components/shared/FilterSettingsModal';
import Logger from '@utils/logger';
import { unsetOption } from '@constants/filters';
import Button from '@components/shared/Button/Button';
import filterHelpers from '@utils/filterHelpers';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const ApplicationsFilter = ({ stopFetching, setStopFetching }) => {
  const [filterOptions, setFilterOptions] = useState({});
  const [showFiltersSettingsModal, setShowFiltersSettingsModal] =
    useState(false);
  const history = useHistory();
  const { $filter, filterFields } = useQueryParams({ arrayFormat: 'bracket' });

  const parsedFilterFields = filterFields || [];

  const handleSubmit = (values, setSubmitting) => {
    const filteredValues = Object.keys(values).reduce((acc, current) => {
      const valuesSubstring = current.slice(0, 3);
      const isValueInParsedFilters = parsedFilterFields.some(
        (item) => item.slice(0, 3) === valuesSubstring
      );

      if (isValueInParsedFilters) {
        acc[current] = values[current];
      }

      return acc;
    }, {});
    handleFilterUpdate(filteredValues);

    setSubmitting(false);
  };

  const handleFilterUpdate = (values) => {
    const clearedValues = cleanObject(values) || {};
    // temporary solution for filter by metaAttributeValue
    const params = clearedValues.metaAttributes?.any?.metaAttributeValue
      ?.contains
      ? {
          ...clearedValues,
          metaAttributes: {
            any: {
              metaAttributeName: {
                eq: 'Query'
              },
              metaAttributeValue: {
                contains:
                  clearedValues.metaAttributes.any.metaAttributeValue.contains
              }
            }
          }
        }
      : clearedValues;

    stopFetching && setStopFetching(false);

    history.push({
      pathname: history.location.pathname,
      search: qs.stringify({
        ...qs.parse(history.location.search),
        $filter: JSON.stringify(params),
        $skip: 0
      })
    });
  };

  const updFilterData = useMemo(
    () => filterHelpers.updFilterData($filter, initialValues),
    [history.location.search]
  );

  let initialValues = {};
  let isApplyDisabled = true;
  if ($filter) {
    initialValues = updFilterData;
    isApplyDisabled = Boolean(!Object.keys(JSON.parse($filter)).length);
  }

  useEffect(() => {
    (async () => {
      try {
        const result = await Promise.allSettled([
          AdminApi.get(adminApiUrls.applyOptions.employment),
          AdminApi.get(adminApiUrls.applyOptions.userInfo),
          AdminApi.get(adminApiUrls.applyOptions.disclosures)
        ]);

        const utmSourceOptions = await AdminApi.get(
          adminApiUrls.common.utmSourceList
        );

        let options = {};

        result.forEach(({ value = {} }) => {
          options = {
            ...options,
            ...value
          };
        });

        const processedOptions = Object.fromEntries(
          Object.entries(options).map(([key, optionsArray]) => [
            key,
            [
              unsetOption,
              ...optionsArray.map(({ id, displayName }) => ({
                label: displayName,
                value: id
              }))
            ]
          ])
        );

        processedOptions.utmSource = utmSourceOptions;

        setFilterOptions(processedOptions);
      } catch (e) {
        Logger.error(e);
      }
    })();
  }, []);

  return (
    <FilterOptionsContext.Provider value={filterOptions}>
      <Box width="100%">
        <Button onClick={() => setShowFiltersSettingsModal(true)}>
          Additional filter settings
        </Button>
      </Box>
      <Formik initialValues={initialValues} enableReinitialize>
        {({ values, setSubmitting }) => (
          <Box
            sx={filterHelpers.getFlexForField(parsedFilterFields?.length && 20)}
            mb={parsedFilterFields ? 1 : 'auto'}
          >
            <Form className="page-filter">
              <Box
                sx={{
                  ...filterHelpers.getFlexForField(10),
                  alignItems: 'center'
                }}
              >
                {parsedFilterFields.map((key) => (
                  <React.Fragment key={key}>
                    {filterFieldsConfig[key].renderFilterField()}
                  </React.Fragment>
                ))}
              </Box>
            </Form>
            <Box sx={filterHelpers.getFlexForField(10)}>
              {!!parsedFilterFields?.length && (
                <Button
                  type="submit"
                  onClick={() => handleSubmit(values, setSubmitting)}
                  disabled={isApplyDisabled && !parsedFilterFields.length}
                >
                  Apply
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Formik>
      <FilterSettingsModal
        show={showFiltersSettingsModal}
        onClose={() => setShowFiltersSettingsModal(false)}
        filterFields={parsedFilterFields}
        filterFieldsOptions={filterFieldsOptions}
        stopFetching={stopFetching}
        setStopFetching={setStopFetching}
      />
    </FilterOptionsContext.Provider>
  );
};

ApplicationsFilter.propTypes = {
  stopFetching: PT.bool.isRequired,
  setStopFetching: PT.func.isRequired
};

export default ApplicationsFilter;
