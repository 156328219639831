import routes from '@constants/routes';

export const tabsIds = {
  leadRequests: 'leadRequests',
  campaignPerformance: 'campaignPerformance'
};

export const tabsList = [
  {
    id: tabsIds.campaignPerformance,
    title: 'Lead performance',
    link: routes.leadProvider.campaignPerformance
  },
  {
    id: tabsIds.leadRequests,
    title: 'Lead Reports',
    link: routes.leadProvider.leadRequests
  }
];
