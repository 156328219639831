import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  getColumns,
  initialFilterParams
} from '@components/Dashboard/IBV/ScoringReport/config';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import SeparatedDateTimeFilter from '@components/shared/SeparatedDateTimeFilter/SeparatedDateTimeFilter';
import { adminApiUrls } from '@constants/adminApiUrls';
import AdminApi from '@utils/adminApi';
import Logger from '@utils/logger';

const IBVScoringReportPage = () => {
  const tableRef = useRef();
  const [IBVScoringReportStatusOptions, setIBVScoringReportStatusOptions] =
    useState([]);

  useEffect(() => {
    (async () => {
      try {
        const statuses = await AdminApi.get(
          adminApiUrls.common.IBVScoringStatuses
        );

        setIBVScoringReportStatusOptions(
          statuses?.items?.map((item) => ({
            value: item.ibvStatusName,
            label: item.ibvStatusName
          }))
        );
      } catch (error) {
        Logger.error(error);
      }
    })();
  }, []);

  const getIBVScoringReportColumns = useCallback(
    () => getColumns({ IBVScoringReportStatusOptions }),
    [IBVScoringReportStatusOptions]
  );

  return (
    <MainLayout title="IBV Scoring Report">
      <SeparatedDateTimeFilter tableRef={tableRef} />
      <ODataPaginatedTable
        tableRef={tableRef}
        getColumns={getIBVScoringReportColumns}
        usePaginatedDataOptions={{
          apiUrl: adminApiUrls.dashboard.ibv.scoringReport,
          initialFilterParams: {
            $orderBy: 'createdDate desc',
            $filter: {
              ...initialFilterParams
            }
          }
        }}
        rowKey="ibvUnderwritingId"
        detailPanelHeight={500}
        withRefreshButton
        exportFileName="IBV Scoring Report"
        customApi={AdminApi}
      />
    </MainLayout>
  );
};

export default IBVScoringReportPage;
