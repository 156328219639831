import React from 'react';
import capitalize from 'lodash/capitalize';

import { cleanObject } from '@utils/object-cleaner';
import DeviceEventsLink from '@components/Users/Details/DeviceInformation/DeviceEventsLink';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';
import { CheckCircleIcon } from '@components/shared/icons/Icons';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import { filterOperators } from '@constants/common';

export const initialFilterParams = cleanObject({
  device: {
    contains: ''
  },
  ipAddress: {
    contains: ''
  },
  operationSystem: {
    contains: ''
  },
  lastLoginDate: {
    ge: '',
    le: ''
  }
});

export const getFilledColumns =
  ({ appVersions }) =>
  () => [
    {
      key: 0,
      headerName: 'Device Id',
      field: 'device',
      minWidth: 210,
      flex: 1,
      renderCell: ({ row }) =>
        helpers.getActionPermission(permissionsMap.loggedView) ? (
          <DeviceEventsLink deviceId={row?.device} />
        ) : (
          row?.device
        )
    },
    {
      key: 1,
      headerName: 'Device type',
      field: 'deviceClass',
      minWidth: 120,
      flex: 1,
      type: muiDataTableTypes.singleSelect,
      valueOptions: [
        { label: 'Mobile', value: 'mobile' },
        { label: 'Web', value: 'web' }
      ],
      renderCell: ({ row }) => capitalize(row?.deviceClass)
    },
    {
      key: 2,
      headerName: 'IP Address',
      field: 'ipAddress',
      minWidth: 150,
      flex: 1,
      customFilter: [
        {
          field: 'ipAddress',
          type: 'text',
          operator: 'contains'
        }
      ]
    },
    {
      key: 3,
      headerName: 'Verified',
      field: 'virified',
      minWidth: 80,
      flex: 1,
      type: muiDataTableTypes.boolean,
      renderCell: ({ row }) => (row?.virified ? <CheckCircleIcon /> : '')
    },
    {
      key: 4,
      headerName: 'App Version',
      field: 'version',
      minWidth: 140,
      flex: 1,
      type: muiDataTableTypes.singleSelect,
      valueOptions: appVersions
    },
    {
      key: 5,
      headerName: 'Operational system',
      field: 'operationSystem',
      minWidth: 180,
      flex: 1
    },
    {
      key: 6,
      headerName: 'Last Login',
      field: 'lastLoginDate',
      minWidth: 250,
      flex: 1,
      valueFormatter: (lastLoginDate) =>
        helpers.getFormattedDate(lastLoginDate),
      renderCell: ({ row }) => helpers.getFormattedDate(row?.lastLoginDate),
      type: muiDataTableTypes.dateRange,
      filterOperators: [filterOperators.dateRange]
    }
  ];
