import React from 'react';
import { Form, Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import PT from 'prop-types';

import {
  leadPerformanceBaseInitialValues,
  datePeriodOptions,
  stackPerformanceBaseInitialValues,
  loanTypesOptions
} from './config';

import Stack from '@components/shared/Stack/Stack';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Button from '@components/shared/Button/Button';
import { fieldWidth } from '@constants/filters';
import useLeadReportFilters from '@components/LeadReports/filters/useLeadReportFilters';
import {
  shortcutsItems,
  showAllValue,
  statesOptions
} from '@components/LeadReports/filters/config';

const AggregatedReportsFilterForm = ({
  isStackPerformance = false,
  stackPerformanceOptions
}) => {
  const { initialValues, onReset, handleFilterUpdate, campaignsOptions } =
    useLeadReportFilters({
      baseInitialValues:
        isStackPerformance === true
          ? stackPerformanceBaseInitialValues
          : leadPerformanceBaseInitialValues,
      dateRangeField: 'Date',
      isStackPerformance
    });

  return (
    <Formik initialValues={initialValues} onSubmit={handleFilterUpdate}>
      {({ values, resetForm }) => (
        <Form className="page-filter">
          <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
            {isStackPerformance === true && (
              <FormikField
                type="select"
                name="DatePeriod"
                label="Date Period"
                size="small"
                options={datePeriodOptions}
                sx={fieldWidth}
              />
            )}
            <FormikField
              type="autocomplete"
              name="LeadCampaignId"
              label="Lead campaign"
              size="small"
              options={[
                { label: 'All campaigns', value: showAllValue },
                ...(campaignsOptions || [])
              ]}
              sx={fieldWidth}
            />
            <FormikField
              type="autocomplete"
              name="State"
              label="State"
              placeholder="Select state"
              options={[
                { label: 'All states', value: showAllValue },
                ...statesOptions
              ]}
              sx={fieldWidth}
            />
            <FormikField
              type="dateRange"
              name="Date"
              label="Date between"
              size="small"
              shortcutsItems={shortcutsItems}
              sx={fieldWidth}
            />
            {isStackPerformance === true && (
              <>
                <FormikField
                  type="select"
                  name="StackName"
                  label="Stack Name"
                  size="small"
                  options={[
                    { label: 'All Stack Names', value: showAllValue },
                    ...(stackPerformanceOptions?.stackNames || [])
                  ]}
                  sx={fieldWidth}
                />
                <FormikField
                  type="select"
                  name="PromoCode"
                  label="Promo Code"
                  size="small"
                  options={[
                    { label: 'All Promo Codes', value: showAllValue },
                    ...(stackPerformanceOptions?.promoCodes || [])
                  ]}
                  sx={fieldWidth}
                />
                <FormikField
                  type="number"
                  name="LeadCostFrom"
                  label="Lead Price from"
                  placeholder="Lead Price from"
                  sx={fieldWidth}
                />
                <FormikField
                  type="number"
                  name="LeadCostTo"
                  label="Lead Price to"
                  placeholder="Lead Price to"
                  sx={fieldWidth}
                />
                <FormikField
                  type="select"
                  name="LoanType"
                  label="Loan Type"
                  size="small"
                  options={[
                    { label: 'All Loan Types', value: showAllValue },
                    ...(loanTypesOptions || [])
                  ]}
                  sx={fieldWidth}
                />
              </>
            )}
            <Stack direction="row" gap={1} alignItems="center">
              <Button type="submit">Apply</Button>
              <Button
                variant="outlined"
                onClick={() => onReset(resetForm)}
                disabled={isEqual(leadPerformanceBaseInitialValues, values)}
              >
                Reset
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

AggregatedReportsFilterForm.propTypes = {
  isStackPerformance: PT.bool,
  stackPerformanceOptions: PT.shape({
    stackNames: PT.arrayOf(
      PT.shape({
        label: PT.string,
        value: PT.string
      })
    ),
    promoCodes: PT.arrayOf(
      PT.shape({
        label: PT.string,
        value: PT.string
      })
    )
  })
};

export default AggregatedReportsFilterForm;
