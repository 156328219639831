import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

import App from './app';

import { IS_PRODUCTION, SENTRY_DSN_KEY } from '@constants/common';

IS_PRODUCTION &&
  Sentry.init({
    dsn: SENTRY_DSN_KEY,
    environment: `${process.env.CI_COMMIT_REF_SLUG}-${process.env.DEPLOY_ENVIRONMENT}`,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],

    tracesSampleRate: 0.0,
    tracesSampler: 0.0
  });

const root = createRoot(document.getElementById('root'));

root.render(<App />);
