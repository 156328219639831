import React from 'react';
import { Link, generatePath } from 'react-router-dom';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import { cleanObject } from '@utils/object-cleaner';
import helpers from '@utils/helpers';
import { noFieldData, permissionsMap } from '@constants/common';
import { platformTypes } from '@constants/mobileAppVersions';
import routes from '@constants/routes';
import toastify from '@utils/toastify';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import Box from '@components/shared/Box/Box';
import Button from '@components/shared/Button/Button';
import { ActionsBox } from '@components/shared/MainLayout/MainLayout.components';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import DeleteActionButton from '@components/shared/DataTable/actions/DeleteActionButton';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const initialFilterParams = cleanObject({
  applicationVersionId: {
    contains: ''
  },
  name: {
    contains: ''
  },
  clientOperatingSystem: {
    eq: ''
  }
});

const getAppVersionEditUrl = (appVersion) =>
  generatePath(routes.osAndAppVersions.mobileAppVersions.edit, {
    id: appVersion.applicationVersionId
  });

const getColumns = ({ updatePaginationAfterDelete }) => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <EditActionButton
          path={getAppVersionEditUrl(row)}
          hidden={!helpers.getActionPermission(permissionsMap.appVersionManage)}
        />
        <DeleteActionButton
          hidden={!helpers.getActionPermission(permissionsMap.appVersionManage)}
          itemId={row.applicationVersionId}
          onConfirm={() =>
            handleDeleteVersion(
              row.applicationVersionId,
              updatePaginationAfterDelete
            )
          }
        />
      </Box>
    )
  },
  {
    headerName: 'Application version ID',
    field: 'applicationVersionId',
    minWidth: 200,
    flex: 1,
    renderCell: ({ row }) => row.applicationVersionId,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Version',
    minWidth: 100,
    flex: 1,
    field: 'name'
  },
  {
    headerName: 'Platform',
    field: 'clientOperatingSystem',
    minWidth: 120,
    flex: 1,
    sortable: false,
    type: muiDataTableTypes.singleSelect,
    valueOptions: Object.keys(platformTypes).map((value) => ({
      value,
      label: value
    })),
    renderCell: ({ row }) => row.clientOperatingSystem || noFieldData
  }
];

const handleDeleteVersion = async (
  applicationVersionId,
  updatePaginationAfterDelete
) => {
  try {
    await AdminApi.delete(
      adminApiUrls.mobileAppVersions.page(applicationVersionId)
    );

    updatePaginationAfterDelete(
      [applicationVersionId],
      (record, arrayOfDeletableIds) =>
        !arrayOfDeletableIds.includes(record.applicationVersionId)
    );

    toastify.success({
      message: 'App version deleted'
    });
  } catch (e) {
    toastify.error({
      message: 'Delete operation failure'
    });
  }
};

const MobileAppVersionsList = () => (
  <MainLayout title="App versions">
    <ActionsBox>
      {helpers.getActionPermission(permissionsMap.appVersionManage) && (
        <Button
          component={Link}
          to={routes.osAndAppVersions.mobileAppVersions.create}
        >
          Add new app version
        </Button>
      )}
    </ActionsBox>
    <ODataPaginatedTable
      getColumns={getColumns}
      usePaginatedDataOptions={{
        apiUrl: adminApiUrls.mobileAppVersions.list,
        initialFilterParams: {
          $orderBy: 'name desc',
          $filter: initialFilterParams
        }
      }}
      rowKey="applicationVersionId"
      customApi={AdminApi}
    />
  </MainLayout>
);

export default MobileAppVersionsList;
