/*
  INFO: This util should be imported instead of `history` object
  and can be used outside of React context to manipulate the history object.
  It can be used in Redux or Mobx stores or anywhere you want. You need just import it.
  This util will replace react-mobx-router or Redux equivalents.
  Do not use `window.location` as it forces full app reload.
  If there should be some toaster message it won't be shown.
  Also, it can cause extra requests to the server.
*/
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export default history;
