import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { shortTimeFormat, timeFormat24h } from '@constants/common';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const getFormattedTime = (time) => {
  const isValidDate = dayjs(time).isValid();
  let formattedTime;

  if (isValidDate) {
    formattedTime = dayjs(time).format(timeFormat24h);
  } else {
    formattedTime = dayjs(time, shortTimeFormat.server).format(timeFormat24h);
  }

  return dayjs().format(`YYYY-MM-DDT[${formattedTime}]`);
};

export const compareTime = (startWork, stopWork) => {
  const formattedStopWork = getFormattedTime(stopWork);
  const formattedStartWork = getFormattedTime(startWork);

  return dayjs(formattedStartWork).isSameOrBefore(dayjs(formattedStopWork));
};
