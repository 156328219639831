import { createSlice } from '@reduxjs/toolkit';

import { fetchCurrentUser } from './actions';

import { handleAsyncThunk } from '@store/helpers';

const initialState = {
  profile: {
    data: null,
    isLoading: false,
    error: null
  }
};

export const selectCurrentUser = ({ currentUser }) => currentUser.profile.data;

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleAsyncThunk(builder, fetchCurrentUser, 'profile');
  }
});

export default currentUserSlice.reducer;
