import { memo } from 'react';
import PT from 'prop-types';

import helpers from '@utils/helpers';

const SecurityGuard = ({ permission, children }) => {
  if (!permission || !helpers.getActionPermission(permission)) return;

  return children;
};

SecurityGuard.propTypes = {
  permission: PT.string.isRequired,
  children: PT.any
};

export default memo(SecurityGuard);
