import React from 'react';

import { CheckCircleIcon } from '@components/shared/icons/Icons';
import { systemNotificationTypeMap } from '@components/SystemNotifications/Form/schema';
import routes from '@constants/routes';
import { permissionsMap } from '@constants/permissions';
import { cleanObject } from '@utils/object-cleaner';
import helpers from '@utils/helpers';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';
import Box from '@components/shared/Box/Box';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import { filterOperators, noFieldData } from '@constants/common';

export const initialFilterParams = cleanObject({
  jobTitle: {
    contains: ''
  },
  messageTitle: {
    contains: ''
  },
  updatedDate: {
    ge: '',
    le: ''
  }
});

export const tabsList = [
  {
    id: 'tab-0',
    title: 'System Notifications',
    link: routes.notifications.systemNotifications.list
  },
  {
    id: 'tab-1',
    title: 'Custom Notifications',
    link: routes.notifications.customNotifications.list
  }
];

export const getColumns = () => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <EditActionButton
          path={routes.notifications.systemNotifications.page.edit.url(
            row.notificationJobId
          )}
          hidden={
            !helpers.getActionPermission(permissionsMap.notificationJobManage)
          }
        />
        <ShowDetailsActionButton
          path={routes.notifications.systemNotifications.page.view.url(
            row.notificationJobId
          )}
        />
      </Box>
    )
  },
  {
    headerName: 'Job title',
    field: 'jobTitle',
    width: 180
  },
  {
    headerName: 'Message title',
    field: 'messageTitle',
    width: 130
  },
  {
    headerName: 'Active',
    field: 'isActive',
    type: muiDataTableTypes.boolean,
    renderCell: ({ row }) =>
      row?.isActive ? <CheckCircleIcon size={14} /> : ''
  },
  {
    headerName: 'Notification type',
    field: 'notificationType',
    width: 130,
    type: muiDataTableTypes.singleSelect,
    valueOptions: Object.values(systemNotificationTypeMap).map((item) => ({
      label: item,
      value: item
    }))
  },
  {
    headerName: 'Number of total sending attempts',
    field: 'sendingAttempts',
    width: 130,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => row?.notificationJobHistories[0]?.sendingAttempts
  },
  {
    headerName: 'Number of successful sending attempts',
    field: 'successfulSending',
    width: 130,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => row?.notificationJobHistories[0]?.successfulSending
  },
  {
    headerName: 'Last Executed On',
    field: 'createdDate',
    width: 180,
    sortable: false,
    renderCell: ({ row }) =>
      row?.notificationJobHistories?.length
        ? helpers.getFormattedDate(row.notificationJobHistories[0].createdDate)
        : noFieldData,
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Last Modified On',
    field: 'updatedDate',
    width: 180,
    valueFormatter: (updatedDate) => helpers.getFormattedDate(updatedDate),
    renderCell: ({ row }) =>
      row?.updatedDate && helpers.getFormattedDate(row.updatedDate),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  }
];
