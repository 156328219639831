import styled from '@emotion/styled';

export const StyledHeader = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  padding: 28px 32px;

  @media only screen and (width <= 1024px) {
    padding: 20px 32px;
  }
`;

export const StyledHeading = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -2px;
  margin: 0;
  padding: 0;
  color: #fff;
  transform: translate(-50%, -50%);

  @media only screen and (width <= 1024px) {
    font-size: 32px;
  }

  @media only screen and (1024 > width) {
    font-size: 48px;
  }
`;
