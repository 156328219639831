import helpers from '@utils/helpers';
import { filterOperators, noFieldData } from '@constants/common';
import { muiDataTableTypes } from '@constants/tableFilterConfig';

export const getColumns = () => [
  {
    key: 0,
    headerName: 'Login ID',
    minWidth: 90,
    flex: 1,
    field: 'loginId'
  },
  {
    key: 1,
    headerName: 'Login date',
    field: 'loginDate',
    minWidth: 180,
    flex: 1,
    valueFormatter: (loginDate) => helpers.getFormattedDate(loginDate),
    renderCell: ({ row }) => helpers.getFormattedDate(row?.loginDate),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    key: 2,
    headerName: 'IP address',
    minWidth: 140,
    flex: 1,
    field: 'ipAddress'
  },
  {
    key: 3,
    headerName: 'Is success',
    field: 'isLoginSuccessful',
    type: muiDataTableTypes.boolean,
    minWidth: 90,
    flex: 1,
    renderCell: ({ row }) => (row?.isLoginSuccessful ? 'Yes' : 'No')
  },
  {
    key: 4,
    headerName: 'Failure reason',
    field: 'failedLoginReason',
    minWidth: 270,
    flex: 1,
    renderCell: ({ row }) => row?.failedLoginReason || noFieldData
  },
  {
    key: 5,
    headerName: 'User agent',
    minWidth: 540,
    flex: 1,
    field: 'agent'
  }
];
