import dayjs from 'dayjs';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import qs from 'query-string';

import { showAllValue } from './config';

import { cleanObject } from '@utils/object-cleaner';

export const getDateFromQuery = (date, initialValue) => {
  if (!date) initialValue;
  return [dayjs(date.ge), dayjs(date.le)];
};

export const setODataDateFilterParams = (startDate, endDate) =>
  setODataFilterParams({ Date: [startDate, endDate] }, 'Date');

export const setODataFilterParams = (values, dateRangeField) => {
  const clearedValues = cleanObject(values) || {};
  const dateRange = values[dateRangeField];

  if (clearedValues[dateRangeField]) {
    clearedValues[dateRangeField] = {
      ge: dateRange[0],
      le: dateRange[1]
    };
  }

  return omitBy(
    clearedValues,
    (value, key) =>
      value === showAllValue || isNull(value) || key === 'DatePeriod'
  );
};

export const getODataFilterParams = (_params) => {
  const params = qs.parse(_params);
  return JSON.parse(params.$filter);
};
