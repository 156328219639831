import React from 'react';
import PT from 'prop-types';
import { Formik, Form } from 'formik';
import dayjs from 'dayjs';

import { workingCalendarSchema, daysOfWeekOptions } from './schema';

import Alert from '@components/shared/Alert/Alert';
import { shortTimeFormat } from '@constants/common';
import Typography from '@components/shared/Typography/Typography';
import Grid from '@components/shared/Grid/Grid';
import Button from '@components/shared/Button/Button';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import helpers from '@utils/helpers';
import Box from '@components/shared/Box/Box';

const initialValues = {
  workDays: daysOfWeekOptions.map((item) => ({
    active: false,
    dayOfWeek: item,
    startWork: dayjs().startOf('d').format(shortTimeFormat.server),
    stopWork: dayjs().endOf('d').format(shortTimeFormat.server)
  }))
};

const WorkingCalendarStep = ({ onSubmit, workingCalendarData }) => {
  const handleSubmit = (values, actions) => {
    const payload = {
      workDays: values.workDays.map((item) => ({
        ...item,
        startWork: helpers.getValidServerTime(item.startWork),
        stopWork: helpers.getValidServerTime(item.stopWork)
      }))
    };

    onSubmit(
      { values: payload, actions },
      {
        onFinish: () => {
          actions.setSubmitting(false);
        }
      }
    );
  };

  return (
    <Formik
      initialValues={workingCalendarData || initialValues}
      onSubmit={handleSubmit}
      validationSchema={workingCalendarSchema}
      enableReinitialize
    >
      {({ errors, values }) => (
        <Form className="page-filter">
          <Typography variant="h3" my={1}>
            Calendar
          </Typography>
          {daysOfWeekOptions.map((item, index) => (
            <Grid
              container
              justify="space-between"
              alignItems="center"
              align="center"
              key={index}
            >
              <Grid item sm={4} md={3}>
                <FormikField
                  type="checkbox"
                  name={`workDays[${index}].active`}
                  label={item}
                />
              </Grid>
              <Grid item sm={8} md={9}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item md={6}>
                    <FormikField
                      type="time"
                      name={`workDays[${index}].startWork`}
                      label="Start time"
                      format={shortTimeFormat.front}
                      disabled={!values.workDays[index].active}
                      value={dayjs(
                        values.workDays[index].startWork,
                        shortTimeFormat.server
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FormikField
                      type="time"
                      name={`workDays[${index}].stopWork`}
                      label="Stop time"
                      format={shortTimeFormat.front}
                      disabled={!values.workDays[index].active}
                      value={dayjs(
                        values.workDays[index].stopWork,
                        shortTimeFormat.server
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
          {errors.nonFieldErrors && (
            <Alert message={errors.nonFieldErrors} type="error" />
          )}
          <Box my={2}>
            <Button type="submit">Save</Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

WorkingCalendarStep.defaultProps = {
  workingCalendarData: {}
};

WorkingCalendarStep.propTypes = {
  onSubmit: PT.func,
  workingCalendarData: PT.object,
  infoPageData: PT.object
};

export default WorkingCalendarStep;
