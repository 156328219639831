import React, { memo } from 'react';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import LeadProvideStepForm from '@components/Leads/leadProviders/StepForm';

const LeadProviderEditPage = () => (
  <MainLayout title="Edit Lead Provider">
    <LeadProvideStepForm />
  </MainLayout>
);

export default memo(LeadProviderEditPage);
