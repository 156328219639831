import React, { useState } from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';

import CampaignGroupsTable from './CampaignGroupsTable';
import LeadProvidersTable from './LeadProvidersTable';

import { useQueryParams } from '@hooks/useQueryParams';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import GlobalProvidersRates from '@pages/leads/lead-providers-rates/GlobalProvidersRates';
import ShowActiveButton from '@components/shared/ShowActiveButton/ShowActiveButton';
import LeadDayHourFilterSeparated from '@components/Leads/DayHourFilterSeparated';

const LeadLeadProvidersRatesPage = () => {
  const history = useHistory();
  const params = useQueryParams();
  const [showActive, setShowActive] = useState(
    params?.isActive === undefined ? true : params?.isActive
  );
  const [expandAllTables, setExpandAllTables] = useState(false);
  const setParams = (queryParams) => {
    history.push({
      search: qs.stringify(queryParams, {
        skipNull: true,
        skipEmptyString: true
      })
    });
  };

  const handleSortingChange = (sortData) => {
    const orderBy = sortData.reduce(
      (_, { field, sort }) => `${field} ${sort}`,
      ''
    );

    if (orderBy) {
      setParams({
        ...params,
        ...qs.parse(history.location.search),
        orderBy: `${orderBy}`
      });
    } else {
      delete params.orderBy;
      setParams({
        ...params
      });
    }
  };

  const handleTableChange = (pagination, sortData) => {
    if (sortData) {
      handleSortingChange(sortData);
      return;
    }

    setParams({
      ...params,
      ...qs.parse(history.location.search),
      take: pagination.pageSize,
      offset: pagination.pageSize * (pagination.current - 1)
    });
  };

  const [keyLeadProvidersTable, setKeyLeadProvidersTable] = useState(
    'lead' + new Date().getTime()
  );
  const [keyCampaignGroupsTable, setKeyCampaignGroupsTable] = useState(
    'campaign' + new Date().getTime()
  );

  const updateKey = (arr) => () => {
    arr.forEach(([setter, prefix]) => {
      setter(prefix + new Date().getTime());
    });
  };

  return (
    <MainLayout title="Lead Providers Dashboard">
      <LeadDayHourFilterSeparated>
        <ShowActiveButton
          showActive={showActive}
          setShowActive={setShowActive}
          params={params}
          setParams={setParams}
          setExpandAllTables={setExpandAllTables}
        />
      </LeadDayHourFilterSeparated>

      <GlobalProvidersRates
        rowKey="leadProviderId"
        updateKey={updateKey([
          [setKeyLeadProvidersTable, 'lead'],
          [setKeyCampaignGroupsTable, 'campaign']
        ])}
      />

      <LeadProvidersTable
        key={keyLeadProvidersTable}
        updateKey={updateKey([[setKeyCampaignGroupsTable, 'campaign']])}
        showActive={showActive}
        handleTableChange={handleTableChange}
        expandAllTables={expandAllTables}
        setExpandAllTables={setExpandAllTables}
      />

      <CampaignGroupsTable
        key={keyCampaignGroupsTable}
        updateKey={updateKey([[setKeyLeadProvidersTable, 'lead']])}
        showActive={showActive}
        handleTableChange={handleTableChange}
        expandAllTables={expandAllTables}
        setExpandAllTables={setExpandAllTables}
      />
    </MainLayout>
  );
};

export default LeadLeadProvidersRatesPage;
