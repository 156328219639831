import { filterOperators } from '@constants/common';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import helpers from '@utils/helpers';
import { cleanObject } from '@utils/object-cleaner';

export const initialFilterParams = cleanObject({
  amount: {
    eq: ''
  },
  status: {
    contains: ''
  },
  description: {
    contains: ''
  },
  createdDate: {
    ge: '',
    le: ''
  }
});

export const getColumns = () => [
  {
    headerName: 'Amount',
    field: 'amount',
    type: muiDataTableTypes.number,
    renderCell: ({ row }) => row.amount,
    minWidth: 150
  },
  {
    key: 1,
    headerName: 'Status',
    field: 'status'
  },
  {
    key: 2,
    headerName: 'Description',
    field: 'description',
    width: 500
  },
  {
    key: 3,
    headerName: 'Created On',
    field: 'createdDate',
    valueFormatter: (createdDate) => helpers.getFormattedDate(createdDate),
    renderCell: ({ row }) => helpers.getFormattedDate(row?.createdDate),
    minWidth: 200,
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  }
];
