import * as Yup from 'yup';

import { appVersionRegex } from '@constants/regexp';

export const appVersionSchema = Yup.object().shape({
  name: Yup.string()
    .required('App version is required')
    .matches(appVersionRegex, 'App version is invalid'),
  clientOperatingSystem: Yup.string().required(
    'Client operating system is required'
  )
});
