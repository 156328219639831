import React, { memo } from 'react';
import PT from 'prop-types';
import omit from 'lodash/omit';

import { notificationFilterData } from './TargetStep/schema';
import { targetGroups } from './TargetStep/config';

import Loader from '@components/shared/Loader';
import {
  CSV_INPUT_NAME,
  notificationFormStepsComponents,
  notificationFormStepsMap,
  notificationFormStepsOrder
} from '@constants/notificationFormConfig';
import routes from '@constants/routes';
import history from '@utils/history';
import toastify from '@utils/toastify';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const CustomNotificationForm = ({
  id,
  activeStep,
  jobData,
  jobFilterData,
  scheduleData,
  fileData,
  setFileData,
  jobType,
  setJobType,
  setLastFilledStep,
  setJobData,
  setScheduleData,
  setJobFilterData,
  setActiveStep
}) => {
  if (!activeStep) {
    return <Loader />;
  }

  const fetchSaveCSV = (id) => {
    const payload = new FormData();
    const payloadData = { [CSV_INPUT_NAME]: fileData[CSV_INPUT_NAME] };

    Object.entries(payloadData).forEach(([key, value]) => {
      payload.append(key, value);
    });

    return AdminApi.post(adminApiUrls.notificationBulk.upload(id), payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
      }
    });
  };

  const fetchCreateNotification = async (values, jobSendingData, setErrors) => {
    try {
      const { notificationJobId } = await AdminApi.post(
        adminApiUrls.notificationJob.list,
        {
          ...jobSendingData,
          isCustom: true,
          jobType
        }
      );

      const scheduleRequestData = {
        ...values,
        notificationJobId,
        scheduleCustomId: notificationJobId
      };

      const fetchSchedule = () =>
        AdminApi.post(
          adminApiUrls.notificationSchedules.list,
          scheduleRequestData
        );

      if (
        jobType == targetGroups.bulkCsvNotification &&
        fileData[CSV_INPUT_NAME] instanceof File
      ) {
        await Promise.all([fetchSchedule(), fetchSaveCSV(notificationJobId)]);
      } else {
        await fetchSchedule();
      }
      history.push(routes.notifications.customNotifications.list);
      toastify.success({
        message: 'Create operation success'
      });
    } catch (error) {
      setErrors({
        ...error,
        nonFieldErrors:
          error.nonFieldErrors ||
          error?.response?.data?.error?.message ||
          error.message
      });
    }
  };

  const fetchUpdateNotification = async (
    values,
    jobSendingData,
    setErrors,
    onSuccess
  ) => {
    try {
      const responseData = await AdminApi.patch(
        adminApiUrls.notificationJob.page(id),
        jobSendingData
      );

      const scheduleRequestData = {
        ...values,
        notificationJobId: id,
        scheduleCustomId: id
      };

      const fetchSchedule = () =>
        AdminApi.patch(
          adminApiUrls.notificationSchedules.page(
            scheduleRequestData.scheduleCustomId
          ),
          scheduleRequestData
        );

      if (
        jobType == targetGroups.bulkCsvNotification &&
        fileData[CSV_INPUT_NAME] instanceof File
      ) {
        await Promise.all([fetchSchedule(), fetchSaveCSV(id)]);
      } else {
        await fetchSchedule();
      }
      setScheduleData(values);
      setLastFilledStep(activeStep);
      history.push(routes.notifications.customNotifications.page.view.url(id));
      toastify.success({
        message: 'Update operation success'
      });
      onSuccess?.(responseData);
    } catch (error) {
      setErrors({
        ...error,
        nonFieldErrors:
          error.nonFieldErrors ||
          error?.response?.data?.error?.message ||
          error.message
      });
    }
  };

  const handleCreateNotification = async (
    values,
    { onSuccess, onFinish, setErrors }
  ) => {
    try {
      const jobSendingData = {
        ...jobData,
        jobType
      };

      if (jobType == targetGroups.custom) {
        jobSendingData.filter = notificationFilterData.stringify(jobFilterData);
      }

      if (!id) {
        await fetchCreateNotification(values, jobSendingData, setErrors);
        return;
      }

      fetchUpdateNotification(values, jobSendingData, setErrors, onSuccess);
    } catch (e) {
      setErrors({
        ...e,
        nonFieldErrors:
          e.nonFieldErrors || e?.response?.data?.error?.message || e.message
      });
    } finally {
      onFinish?.();
    }
  };

  const currentStepIdx = notificationFormStepsOrder.indexOf(activeStep);

  const nextStep = () => {
    setActiveStep(notificationFormStepsOrder[currentStepIdx + 1]);
  };

  const handleSubmit = async ({ values, actions }, handlers) => {
    switch (activeStep) {
      case notificationFormStepsMap.notification:
        handleNotificationStep(values);
        break;
      case notificationFormStepsMap.target:
        handleTargetStep(values);
        nextStep();
        break;
      case notificationFormStepsMap.scheduling:
        await handleCreateNotification(values, { ...actions, handlers });
        break;
      default:
        break;
    }
  };

  const handleNotificationStep = (values) => {
    setJobData(values);
    setLastFilledStep(activeStep);
    nextStep();
  };

  const handleTargetStep = (values) => {
    if (values.jobType == targetGroups.custom) {
      const filters = omit(values, ['jobType', CSV_INPUT_NAME]);
      setJobFilterData(filters);
    }
    if (values.jobType == targetGroups.bulkCsvNotification) {
      setFileData({
        [CSV_INPUT_NAME]: values[CSV_INPUT_NAME]
      });
    }
    setLastFilledStep(activeStep);
  };

  const StepComponent = notificationFormStepsComponents[activeStep];

  return (
    <StepComponent
      onSubmit={handleSubmit}
      jobData={jobData}
      jobFilterData={{
        ...jobFilterData,
        ...fileData,
        jobType
      }}
      setJobType={setJobType}
      scheduleData={scheduleData}
    />
  );
};

CustomNotificationForm.propTypes = {
  id: PT.string.isRequired,
  activeStep: PT.string,
  jobData: PT.object,
  jobFilterData: PT.object,
  scheduleData: PT.object,
  fileData: PT.object,
  setFileData: PT.func.isRequired,
  jobType: PT.number.isRequired,
  setJobType: PT.func.isRequired,
  setLastFilledStep: PT.func.isRequired,
  setJobData: PT.func.isRequired,
  setScheduleData: PT.func.isRequired,
  setJobFilterData: PT.func.isRequired,
  setActiveStep: PT.func.isRequired
};

export default memo(CustomNotificationForm);
