import dayjs from 'dayjs';

import { statesList } from '@constants/states';

export const showAllValue = 'all';

export const dateRange = [dayjs().startOf('week'), dayjs().endOf('week')];

export const shortcutsItems = [
  {
    label: 'Today',
    getValue: () => [dayjs().startOf('day'), dayjs().endOf('day')]
  },
  {
    label: 'Yesterday',
    getValue: () => [
      dayjs().subtract(1, 'day').startOf('day'),
      dayjs().subtract(1, 'day').endOf('day')
    ]
  },
  {
    label: 'This Week',
    getValue: () => [dayjs().startOf('week'), dayjs().endOf('week')]
  },
  {
    label: 'Last Week',
    getValue: () => {
      const prevWeek = dayjs().subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    }
  },
  {
    label: 'Last 7 Days',
    getValue: () => [dayjs().subtract(7, 'day'), dayjs()]
  },
  {
    label: 'This Month',
    getValue: () => [dayjs().startOf('month'), dayjs().endOf('month')]
  },
  {
    label: 'Last Month',
    getValue: () => {
      const prevMonth = dayjs().subtract(1, 'month');
      return [prevMonth.startOf('month'), prevMonth.endOf('month')];
    }
  }
];

export const statesOptions = statesList.map((state) => ({
  value: state.shortName,
  label: state.displayName
}));
