import { useContext, useMemo } from 'react';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';

import { SchemaContext } from '@components/shared/Form/SchemaProvider';

export const useIsSchemaRequiredField = (name) => {
  const { schema } = useContext(SchemaContext);
  const { values } = useFormikContext();

  return useMemo(() => {
    if (!schema) return false;

    const fieldSchema = Yup.reach(schema, name, values);

    const isRequired = !!fieldSchema
      .describe()
      .tests.find((test) => test.name === 'required');

    if (isRequired || !fieldSchema._conditions?.length) return isRequired;

    const conditionalSchema = fieldSchema.resolve({
      path: name,
      value: get(values, name),
      parent: values
    });

    return conditionalSchema
      .describe()
      .tests.find((test) => test.name === 'required');
  }, [schema, values]);
};
