import styled from '@mui/material/styles/styled';

import Box from '@components/shared/Box/Box';
import Drawer from '@components/shared/Drawer/Drawer';

export const EmailWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  color: theme.palette.common.white,
  borderRadius: '5px',
  padding: theme.spacing(0.25, 1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5)
}));

export const StyledDrawer = styled(Drawer)(() => ({
  '.MuiDrawer-paper': {
    maxWidth: '520px'
  }
}));
