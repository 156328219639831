import React from 'react';
import { Link } from 'react-router-dom';

import ConfirmActionSwitch from '@components/shared/DataTable/actions/ConfirmActionSwitch';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';
import Box from '@components/shared/Box/Box';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import routes from '@constants/routes';
import { noFieldData } from '@constants/common';
import { commonLeadProviderStatsColumns } from '@constants/leads';

export const getColumns = ({ handleStatus, showActive }) => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <ConfirmActionSwitch
          size="small"
          checked={row.status}
          hidden={
            !helpers.getActionPermission(permissionsMap.leadProvidersManage)
          }
          modalProps={{
            itemId: row.campaignGroupId,
            content: row.status
              ? 'Do you want to pause?'
              : 'Do you want to resume?',
            onConfirm: () =>
              row.status
                ? handleStatus(row.campaignGroupId, true, showActive)
                : handleStatus(row.campaignGroupId, false, showActive)
          }}
        />
      </Box>
    )
  },
  {
    headerName: 'Campaign name',
    field: 'name',
    width: 200,
    renderCell: ({ row }) =>
      helpers.getActionPermission(permissionsMap.leadGroupsManage) ? (
        <Tooltip title="Campaign page">
          <Link
            to={routes.leads.campaignGroups.page.view.url(row.campaignGroupId)}
          >
            {row.name}
          </Link>
        </Tooltip>
      ) : (
        row.name || noFieldData
      )
  },
  ...commonLeadProviderStatsColumns
];
