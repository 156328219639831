import React, { useCallback, useEffect, useMemo } from 'react';
import { Form, Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router';
import qs from 'query-string';
import omitBy from 'lodash/omitBy';
import dayjs from 'dayjs';

import { baseInitialValues } from './config';

import Stack from '@components/shared/Stack/Stack';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Button from '@components/shared/Button/Button';
import { fieldWidth } from '@constants/filters';
import {
  shortcutsItems,
  showAllValue,
  statesOptions
} from '@components/LeadReports/filters/config';
import { useServerData } from '@hooks/useServerData';
import { useQueryParams } from '@hooks/useQueryParams';
import { dateFormat } from '@constants/common';
import helpers from '@utils/helpers';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

const DenialReasonReportsFilterForm = () => {
  const history = useHistory();
  const params = useQueryParams();

  const { data: campaignsOptions, fetchData } = useServerData({
    endpoint: async () => {
      const response = await LeadManagementApi.get(
        leadManagementApiUrls.common.campaigns,
        {
          params
        }
      );

      return response?.map((item) => ({
        label: item.campaignName,
        value: item.campaignId
      }));
    }
  });

  const setFilterParams = ({ period, campaignId, state }) => {
    const { startDate, endDate } = helpers.convertStartAndEndDatesToDateTimes(
      dayjs(period[0]).format(dateFormat),
      dayjs(period[1]).format(dateFormat)
    );

    const params = {
      startDate: startDate,
      endDate: endDate,
      campaignId,
      state
    };

    return omitBy(params, (item) => item === showAllValue);
  };

  useEffect(() => {
    fetchData();

    handleFilterUpdate(initialValues);
  }, []);

  const initialValues = useMemo(() => {
    if (!history.location.search) {
      return baseInitialValues;
    }
    const filterParams = qs.parse(history.location.search);

    if (filterParams && Object.keys(filterParams)?.length) {
      return {
        ...baseInitialValues,
        ...filterParams,
        period: [dayjs(filterParams?.startDate), dayjs(filterParams?.endDate)]
      };
    }
    return baseInitialValues;
  }, [baseInitialValues, history.location.search]);

  const handleFilterUpdate = useCallback(
    (values) => {
      const filterParams = setFilterParams(values);
      history.push({
        pathname: history.location.pathname,
        search: qs.stringify(filterParams)
      });
    },
    [history.location.search, params]
  );

  const onReset = async (resetForm) => {
    resetForm({
      values: baseInitialValues,
      errors: {}
    });
    handleFilterUpdate(baseInitialValues);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleFilterUpdate}>
      {({ values, resetForm }) => (
        <Form>
          <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
            <FormikField
              type="autocomplete"
              name="campaignId"
              label="Lead campaign"
              size="small"
              options={[
                { label: 'All campaigns', value: showAllValue },
                ...(campaignsOptions || [])
              ]}
              sx={fieldWidth}
            />
            <FormikField
              type="autocomplete"
              name="state"
              label="State"
              placeholder="Select state"
              options={[
                { label: 'All states', value: showAllValue },
                ...statesOptions
              ]}
              sx={fieldWidth}
            />
            <FormikField
              type="dateRange"
              name="period"
              label="Date between"
              size="small"
              shortcutsItems={shortcutsItems}
              sx={fieldWidth}
            />
            <Stack direction="row" gap={1} alignItems="center">
              <Button type="submit">Apply</Button>
              <Button
                variant="outlined"
                onClick={() => onReset(resetForm)}
                disabled={isEqual(baseInitialValues, values)}
              >
                Reset
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default DenialReasonReportsFilterForm;
