import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import PromoCodeForm from '@components/PromoCodes/Form';
import Logger from '@utils/logger';
import routes from '@constants/routes';
import toastify from '@utils/toastify';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const PromoCodeEdit = () => {
  const history = useHistory();
  const params = useParams();
  const [promoCode, setPromoCode] = useState(null);

  useEffect(() => {
    if (!params?.id) {
      return;
    }
    (async () => {
      try {
        const { value: promoCodes } = await AdminApi.get(
          adminApiUrls.promoCodes.list
        );
        setPromoCode(
          promoCodes.find(({ promoCodeId }) => +promoCodeId === +params.id)
        );
      } catch (e) {
        Logger.error(e);
      }
    })();
  }, [params?.id]);

  const handleSubmit = async (data, actions) => {
    const payload = {
      ...data,
      ...(data?.expirationDate && {
        expirationDate: dayjs(data?.expirationDate).toISOString()
      }),
      ...(data?.startDate && {
        startDate: dayjs(data?.startDate).toISOString()
      })
    };

    try {
      await AdminApi.put(adminApiUrls.promoCodes.page(params.id), payload);
      history.push(routes.promoCodes.list);
    } catch (e) {
      Logger.error(e);
      actions.setErrors(e.message);
    }
  };

  const handleDelete = async () => {
    try {
      await AdminApi.delete(adminApiUrls.promoCodes.page(params.id));
      history.push(routes.promoCodes.list);

      toastify.success({
        message: 'Promo code deleted'
      });
    } catch (e) {
      toastify.error({
        message: 'Delete operation failure'
      });
    }
  };

  return (
    <MainLayout title="Edit promo code" isLoading={!promoCode}>
      <PromoCodeForm
        handleSubmit={handleSubmit}
        data={promoCode}
        handleDelete={handleDelete}
        editMode
      />
    </MainLayout>
  );
};

export default PromoCodeEdit;
