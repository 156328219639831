import { cleanObject } from '@utils/object-cleaner';
import {
  aggregateGroupByFormula,
  formatValueWithPercentage,
  getTotalAndSubtotalCellClassName,
  renderTotalAndSubtotalCell
} from '@components/shared/DataTable/DataTable.utils';
import {
  getAppRatePercentage,
  getFpdRatePercentage,
  getFundRatePercentage,
  getRedirectRate,
  getRoiPercentage,
  getSpdRatePercentage
} from '@components/LeadReports/LeadReports.utils';
import { filterOperators } from '@constants/common';

export const initialFilterParams = cleanObject({
  Date: {
    ge: '',
    le: ''
  },
  State: {
    eq: ''
  },
  LeadCampaignName: {
    eq: ''
  }
});

export const getColumns = (data) => [
  {
    headerName: 'Lead campaign',
    field: 'leadCampaignName',
    width: 150,
    flex: 1,
    aggregable: false,
    filterOperators: [
      filterOperators.singleSelect({
        filterField: 'leadCampaignName',
        data: data
      })
    ]
  },
  {
    headerName: 'Affiliate ID',
    field: 'affiliateId',
    width: 90,
    aggregable: false,
    filterOperators: [
      filterOperators.singleSelect({
        filterField: 'affiliateId',
        data: data
      })
    ]
  },
  {
    headerName: 'Application URL',
    field: 'applicationUrl',
    width: 230,
    aggregable: false,
    renderCell: renderTotalAndSubtotalCell,
    cellClassName: getTotalAndSubtotalCellClassName,
    filterOperators: [
      filterOperators.singleSelect({
        filterField: 'applicationUrl',
        data: data
      })
    ]
  }
];

export const additionalColumns = [
  {
    headerName: 'Looks',
    field: 'looks',
    type: 'number',
    width: 55,
    aggregable: true,
    cellClassName: getTotalAndSubtotalCellClassName
  },
  {
    headerName: 'Accepted',
    field: 'accepted',
    type: 'number',
    width: 75,
    aggregable: true,
    cellClassName: getTotalAndSubtotalCellClassName
  },
  {
    headerName: 'Accepted%',
    field: 'approvedRate',
    type: 'number',
    aggregable: true,
    cellClassName: getTotalAndSubtotalCellClassName,
    valueFormatter: formatValueWithPercentage
  },
  {
    headerName: 'Redirect#',
    field: 'redirected',
    type: 'number',
    width: 80,
    aggregable: true,
    cellClassName: getTotalAndSubtotalCellClassName
  },
  {
    headerName: 'Redirect%',
    field: 'redirectRate',
    type: 'number',
    width: 80,
    aggregable: true,
    cellClassName: getTotalAndSubtotalCellClassName,
    valueFormatter: formatValueWithPercentage
  },
  {
    headerName: 'Loan Approved',
    field: 'loanApproved',
    type: 'number',
    aggregable: true,
    width: 120,
    cellClassName: getTotalAndSubtotalCellClassName
  },
  {
    headerName: 'FPD',
    field: 'fpd',
    type: 'number',
    width: 50,
    aggregable: true,
    cellClassName: getTotalAndSubtotalCellClassName
  },
  {
    headerName: 'FPD%',
    field: 'fpdRate',
    type: 'number',
    width: 50,
    aggregable: true,
    cellClassName: getTotalAndSubtotalCellClassName,
    valueFormatter: formatValueWithPercentage
  },
  {
    headerName: 'SPD',
    field: 'spd',
    type: 'number',
    width: 50,
    aggregable: true,
    cellClassName: getTotalAndSubtotalCellClassName
  },
  {
    headerName: 'SPD%',
    field: 'spdRate',
    type: 'number',
    width: 50,
    aggregable: true,
    cellClassName: getTotalAndSubtotalCellClassName,
    valueFormatter: formatValueWithPercentage
  },
  {
    headerName: 'ROI%',
    field: 'roi',
    type: 'number',
    width: 50,
    aggregable: true,
    cellClassName: getTotalAndSubtotalCellClassName,
    valueFormatter: formatValueWithPercentage
  }
];

export const pinnedColumns = [
  'leadCampaignName',
  'affiliateId',
  'applicationUrl'
];

export const aggregationModel = {
  looks: 'sum',
  accepted: 'sum',
  approvedRate: 'appRatePercentage',
  loanApproved: 'sum',
  foundRate: 'fundRatePercentage',
  fpd: 'sum',
  fpdRate: 'fpdRatePercentage',
  spd: 'sum',
  spdRate: 'spdRatePercentage',
  roi: 'roiPercentage',
  redirectRate: 'redirectRatePercentage',
  redirected: 'sum'
};

export const getAggregationFunctions = (data) => ({
  appRatePercentage: aggregateGroupByFormula(data, getAppRatePercentage),
  fundRatePercentage: aggregateGroupByFormula(data, getFundRatePercentage),
  fpdRatePercentage: aggregateGroupByFormula(data, getFpdRatePercentage),
  spdRatePercentage: aggregateGroupByFormula(data, getSpdRatePercentage),
  redirectRatePercentage: aggregateGroupByFormula(data, getRedirectRate),
  roiPercentage: aggregateGroupByFormula(data, getRoiPercentage)
});

export const exportOptions = {
  allColumns: true,
  fileName: 'Affiliate_Report'
};

export const exportFields = [
  { field: 'ID', exportField: 'id', key: 0 },
  { field: 'ApplicationURL', exportField: 'applicationUrl', key: 1 },
  { field: 'FPD', exportField: 'fpd', key: 2 },
  { field: 'SPD', exportField: 'spd', key: 3 },
  { field: 'ROI', exportField: 'roi', key: 4 },
  { field: 'Accepted', exportField: 'accepted', key: 5 },
  { field: 'Accepted date', exportField: 'acceptedDate', key: 6 },
  { field: 'AffiliateID', exportField: 'affiliateId', key: 7 },
  { field: 'Redirected', exportField: 'redirected', key: 8 },
  { field: 'LoanApproved', exportField: 'loanApproved', key: 9 }
];
