import { Form, Formik } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

import { addBinNumberSchema } from './addBinNumber.schema';
import { addBINNumberFormInitialValues } from './binNumbersList.config';

import FormikField from '@components/shared/Form/FormikField/FormikField';
import Button from '@components/shared/Button/Button';

const AddBinNumberForm = ({
  onSubmit,
  isLoading,
  brandOptions,
  cardTypeOptions,
  currencyOptions,
  countryOptions,
  onModalClose
}) => (
  <>
    <Formik
      initialValues={addBINNumberFormInitialValues}
      validationSchema={addBinNumberSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid }) => (
        <Form>
          <Stack gap={1} mb={1} justifyContent="center">
            <FormikField
              type="text"
              name="binNumber"
              label="BIN Number"
              size="small"
              placeholder="Enter BIN Number"
            />
            <FormikField
              type="text"
              name="bankName"
              label="Bank name"
              size="small"
              placeholder="Enter bank name"
            />
            <FormikField
              type="select"
              name="brandId"
              label="Brand"
              options={brandOptions}
            />
            <FormikField
              type="select"
              name="typeId"
              label="Card Type"
              options={cardTypeOptions}
            />
            <FormikField
              type="select"
              name="currencyId"
              label="Currency"
              options={currencyOptions}
            />
            <FormikField
              type="select"
              name="countryId"
              label="Country"
              options={countryOptions}
            />
            <FormikField
              type="checkbox"
              name="isBankTranslationNeeded"
              label="Is Bank Name Translation needed "
            />
            <FormikField
              type="text"
              name="bankTranslation"
              label="Translation Name"
              size="small"
              placeholder="Enter translation name"
            />
          </Stack>
          <Stack direction="row" gap={1} justifyContent="center">
            <Button
              type="submit"
              disabled={!isValid || isLoading}
              loading={isLoading}
            >
              Save
            </Button>
            <Button
              disabled={isLoading}
              variant="outlined"
              onClick={onModalClose}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  </>
);

const selectOptions = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
).isRequired;

AddBinNumberForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  brandOptions: selectOptions,
  cardTypeOptions: selectOptions,
  currencyOptions: selectOptions,
  countryOptions: selectOptions,
  onModalClose: PropTypes.func.isRequired
};

export default AddBinNumberForm;
