import dayjs from 'dayjs';

import { cleanObject } from '@utils/object-cleaner';
import {
  ipAddressColumnWidth,
  timeDateColumnWidth
} from '@components/shared/DataTable/DataTable.constants';
import helpers from '@utils/helpers';
import { defaultPageSize, filterOperators } from '@constants/common';
import { muiDataTableTypes } from '@constants/tableFilterConfig';

export const initialFilterParams = cleanObject({
  logGroup: {
    eq: 'WebApiThrottle'
  },
  timestamp: {
    ge: dayjs().subtract(1, 'week').startOf('day').toISOString(),
    le: dayjs().endOf('day').toISOString()
  }
});

export const initialExtraParams = cleanObject({
  $filter: initialFilterParams,
  $top: defaultPageSize,
  $skip: 0,
  $count: true,
  $orderBy: 'loggedEventId desc'
});

export const getColumns = () => [
  {
    headerName: 'IP Address',
    field: 'remoteIpAddress',
    minWidth: ipAddressColumnWidth,
    flex: 1
  },
  {
    headerName: 'Date',
    field: 'timeStamp',
    minWidth: timeDateColumnWidth,
    flex: 1,
    valueFormatter: (timeStamp) => helpers.getFormattedDate(timeStamp),
    renderCell: ({ row }) => helpers.getFormattedDate(row.timeStamp),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Customer ID',
    field: 'executedBy',
    minWidth: 120,
    flex: 1,
    renderCell: ({ row }) => row.customerId || '-'
  },
  {
    headerName: 'Reason of blocking',
    field: 'message',
    minWidth: 600,
    flex: 2,
    sortable: false,
    filterable: false
  }
];
