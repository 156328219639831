import React from 'react';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import CustomNotificationStepForm from '@components/CustomNotifications/StepForm';

const CustomNotificationCreate = () => (
  <MainLayout title="Create Notification">
    <CustomNotificationStepForm />
  </MainLayout>
);

export default CustomNotificationCreate;
