import React, { memo } from 'react';
import PT from 'prop-types';
import { GridColumnMenu } from '@mui/x-data-grid-premium';

const CustomColumnMenu = ({ hideAggregationInColumnMenu, ...props }) => (
  <GridColumnMenu
    {...props}
    slots={{
      ...(hideAggregationInColumnMenu && {
        columnMenuAggregationItem: null
      })
    }}
  />
);

CustomColumnMenu.propTypes = {
  hideAggregationInColumnMenu: PT.bool
};

export default memo(CustomColumnMenu);
