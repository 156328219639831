import * as Yup from 'yup';
import dayjs from 'dayjs';

import { dateFormat } from '@constants/common';

export const CSV_INPUT_NAME = 'file';

export const initialValues = {
  date: dayjs().format(dateFormat),
  time: dayjs(),
  [CSV_INPUT_NAME]: null
};

export const csvSchema = Yup.object().shape({
  [CSV_INPUT_NAME]: Yup.mixed().nullable().required('File is required'),
  date: Yup.date().required('Date is required'),
  time: Yup.date().required('Time is required')
});

export const requiredCSVFields = [
  {
    fieldName: 'CustomerID',
    fieldFormat: 'integer number',
    comments: 'mandatory'
  },
  { fieldName: 'LoanID', fieldFormat: 'integer number', comments: 'mandatory' },
  { fieldName: 'Amount', fieldFormat: 'integer number', comments: 'mandatory' }
];

export const sampleCSVFileFormat = {
  names: ['CustomerID', 'LoanID', 'Amount'],
  values: ['1472', '1234', '50.00']
};
