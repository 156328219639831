import React, { forwardRef } from 'react';
import PT from 'prop-types';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { StyledContentDiv } from './styled';

export { tooltipClasses };

// eslint-disable-next-line react/display-name
const Tooltip = forwardRef(({ children, cutContentByWidth, ...props }, ref) => (
  <MuiTooltip {...props} ref={ref}>
    {cutContentByWidth ? (
      <StyledContentDiv width={cutContentByWidth}>{children}</StyledContentDiv>
    ) : (
      <div>{children}</div>
    )}
  </MuiTooltip>
));

Tooltip.defaultProps = {
  cutContentByWidth: null
};

Tooltip.propTypes = {
  children: PT.any.isRequired,
  minWidth: PT.number,
  cutContentByWidth: PT.string
};

export default Tooltip;
