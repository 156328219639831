import { useLocation } from 'react-router';
import qs from 'query-string';

import WallboardApi from '@utils/wallboardAPi';

export const useSetWallboardToken = () => {
  const { search } = useLocation();
  const { accessToken } = qs.parse(search);
  WallboardApi.defaults.headers.common['Authorization'] =
    `Bearer ${accessToken}`;
};
