import React from 'react';
import PT from 'prop-types';

import { StyledFormAutocomplete } from '../styled';

import Stack from '@components/shared/Stack/Stack';

const GoToPageSection = ({
  rowsCount,
  pageSize,
  selectedPage,
  handlePageChange
}) => {
  const pagesCount = Math.ceil((rowsCount || 0) / pageSize);
  const pagesList = new Array(pagesCount || 1).fill(0).map((_, idx) => idx);
  const pagesListOptions = pagesList.map((idx) => ({
    label: String(idx + 1),
    value: idx
  }));

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <span>Go to page:</span>
      <StyledFormAutocomplete
        labelId="select-page"
        id="simple-select-page"
        value={selectedPage}
        onChange={handlePageChange}
        hasValue
        options={pagesListOptions}
        disableClearable
        formControlProps={{
          size: 'small'
        }}
        ListboxProps={{
          style: {
            maxHeight: '200px'
          }
        }}
        freeSolo
      />
    </Stack>
  );
};

GoToPageSection.propTypes = {
  rowsCount: PT.number,
  pageSize: PT.number,
  pagesList: PT.array,
  selectedPage: PT.number,
  handlePageChange: PT.func
};

export default GoToPageSection;
