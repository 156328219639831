import React from 'react';
import PT from 'prop-types';
import { Field } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';

import SwitchToggle from '../SwitchToggle/SwitchToggle';

import FormHelperText from '@components/shared/Form/FormHelperText';
import FormControl from '@components/shared/Form/FormControl/FormControl';

const Switch = (props) => (
  <FormControl
    fullWidth={props.fullWidth}
    error={!!props.error}
    margin={props.margin}
  >
    <FormControlLabel control={<SwitchToggle />} {...props} />
    <FormHelperText error={!!props.error}>{props.error}</FormHelperText>
  </FormControl>
);

const FormikSwitch = ({ name, ...rest }) => (
  <Field name={name}>
    {({ field, meta }) => {
      const error = meta.touched ? meta.error : '';
      return <Switch {...field} {...rest} error={error} />;
    }}
  </Field>
);

Switch.defaultProps = {
  margin: 'normal'
};

const propTypes = {
  name: PT.string.isRequired,
  label: PT.string,
  error: PT.string,
  fullWidth: PT.bool,
  helperText: PT.string,
  value: PT.any,
  margin: PT.oneOf(['dense', 'none', 'normal'])
};
Switch.propTypes = propTypes;
FormikSwitch.propTypes = propTypes;

export { Switch, FormikSwitch };
