import helpers from '@utils/helpers';
import { filterOperators, noFieldData } from '@constants/common';
import { muiDataTableTypes } from '@constants/tableFilterConfig';

export const getColumns = () => [
  {
    headerName: 'Loan ID',
    field: 'loanId',
    renderCell: ({ row }) => row.loanId,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Payment ID',
    field: 'paymentId',
    renderCell: ({ row }) => row.paymentId,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Payment Processor',
    field: 'paymentProviderName',
    minWidth: 150,
    flex: 1,
    renderCell: ({ row }) => row.paymentProviderName
  },
  {
    headerName: 'Payment Amount',
    field: 'paymentAmount',
    renderCell: ({ row }) => row.paymentAmount,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Status',
    field: 'status',
    type: muiDataTableTypes.singleSelect,
    valueOptions: [
      { label: 'Scheduled', value: 'Scheduled' },
      { label: 'Timeout', value: 'Timeout' },
      { label: 'Approved', value: 'Approved' },
      { label: 'Error', value: 'Error' },
      { label: 'Returned', value: 'Returned' }
    ],
    renderCell: ({ row }) => row.status
  },
  {
    headerName: 'Payment type',
    field: 'paymentType',
    type: muiDataTableTypes.singleSelect,
    valueOptions: [
      { label: 'Cash', value: 'Cash' },
      { label: 'Check', value: 'Check' },
      { label: 'Ach', value: 'Ach' },
      { label: 'DebitCard', value: 'DebitCard' },
      { label: 'MoneyOrder', value: 'MoneyOrder' },
      { label: 'PrepaidCard', value: 'PrepaidCard' },
      { label: 'ECheck', value: 'ECheck' },
      { label: 'LoanTransfer', value: 'LoanTransfer' }
    ],
    renderCell: ({ row }) => row.paymentType
  },
  {
    headerName: 'Scheduled At',
    field: 'createdDate',
    valueFormatter: (createdDate) => helpers.getFormattedDate(createdDate),
    renderCell: ({ row }) => helpers.getFormattedDate(row.createdDate),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Updated At',
    field: 'updatedDate',
    valueFormatter: (updatedDate) => helpers.getFormattedDate(updatedDate),
    renderCell: ({ row }) => helpers.getFormattedDate(row.updatedDate),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Reference №',
    field: 'referenceNumber',
    renderCell: ({ row }) => row.referenceNumber || noFieldData
  },
  {
    headerName: 'Account number',
    field: 'accountNumber',
    renderCell: ({ row }) => row.accountNumber || noFieldData
  },
  {
    headerName: 'Transaction Key',
    field: 'transactionKey',
    renderCell: ({ row }) => row.transactionKey || noFieldData
  },
  {
    headerName: 'Decline reason',
    field: 'declineReason',
    renderCell: ({ row }) => row.declineReason || noFieldData
  }
];
