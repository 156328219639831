import styled from '@emotion/styled';

import { palette } from '@utils/theme';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 70%;
`;

export const StyledRaceBox = styled.div`
  height: 100%;
  padding: 16px;
  background-color: ${palette.white};
  border-radius: 16px;
`;

export const StyledTopBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 5%;

  margin-bottom: 8px;
  padding: 0 24px 0 16px;
`;

export const StyledTitle = styled.h2`
  margin: 0;
  font-weight: 500;
  letter-spacing: -0.5px;
  flex: 1;

  @media only screen and (width <= 1440px) {
    font-size: 1.5rem;
  }

  @media only screen and (width >= 1441px) {
    font-size: 2rem;
  }

  @media only screen and (width >= 1520) {
    font-size: 2.5rem;
  }
`;

export const StyledMiddleTitle = styled.h2`
  margin: 0;
  font-weight: 500;
  letter-spacing: -0.5px;
  flex: 2;

  @media only screen and (width <= 1440px) {
    font-size: 1.5rem;
  }

  @media only screen and (width >= 1441px) {
    font-size: 2rem;
  }

  @media only screen and (width >= 1520) {
    font-size: 2.5rem;
  }
`;

export const StyledEndTitle = styled.h2`
  margin: 0;
  font-weight: 500;
  letter-spacing: -0.5px;
  flex: 1;
  text-align: right;

  @media only screen and (width <= 1440px) {
    font-size: 1.5rem;
  }

  @media only screen and (width >= 1441px) {
    font-size: 2rem;
  }

  @media only screen and (width >= 1520) {
    font-size: 2.5rem;
  }
`;

export const StyledRace = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  height: 94%;
`;

export const StyledPersonBox = styled.div`
  position: relative;

  overflow: hidden;

  height: 25%;

  border: 1px solid rgb(0 0 0 / 12%);
  border-radius: 8px;
  box-shadow: 0 8px 12px -2px rgb(0 0 0 / 7%);
`;

export const StyledPlaceBox = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 3;

  display: flex;
  align-items: center;
  gap: 8px;

  font-style: normal;

  transform: translate(12px, -50%);

  @media only screen and (width >= 1441px) {
    height: 90%;
  }
`;

export const StyledPlace = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  padding: 6px;

  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -1px;
  text-align: center;

  color: ${palette.white};

  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgb(33 43 54 / 14%);

  @media only screen and (width >= 1441px) {
    width: 90%;
    height: 90%;
    aspect-ratio: 1/1;

    font-size: 30px;
  }
`;

export const StyledProfileBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 8px;
  padding: 6px;

  border: 1px solid rgb(255 255 255 / 50%);
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgb(33 43 54 / 14%);

  @media only screen and (width >= 1441px) {
    width: 90%;
    height: 90%;
    aspect-ratio: 1/1;
  }
`;

export const getStyledProfileIcon = (ProfileIcon) => styled(ProfileIcon)`
  height: 24px;
  width: 24px;
  @media only screen and (width >= 1441px) {
    width: 90%;
    height: 90%;
    aspect-ratio: 1/1;
  }
`;

export const StyledPersonName = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -1px;

  color: ${palette.white};

  @media only screen and (width <= 1024px) {
    -webkit-text-stroke: 1px black;
    text-stroke: 1px black;
    font-weight: 700;
  }

  @media only screen and (width >= 1441px) {
    font-size: 30px;
    text-wrap: nowrap;
  }
`;

export const StyledAccentBox = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  height: 100%;
  border-radius: 8px;
  transform: skewX(-20deg) translate(-30px, 0);
`;

export const StyledImage = styled.img`
  position: absolute;
  top: 50%;
  right: 0;

  transform: skewX(20deg) translate(50%, -50%) scale(0.8);

  @media only screen and (width <= 1024px) {
    transform: skewX(20deg) translate(50%, -50%) scale(0.65);
  }

  @media only screen and (width >= 1520px) {
    transform: skewX(20deg) translate(50%, -50%) scale(1.3);
  }
`;

export const StyledAccent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 8px;
  transform: skewX(-20deg);
`;

export const StyledScoreBox = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-12px, -50%);
`;

export const StyledScore = styled.p`
  font-size: 32px;
  font-style: italic;
  font-weight: 900;
  line-height: 38px;
  letter-spacing: 0.15px;
  text-align: right;

  @media only screen and (width >= 1441px) {
    font-size: 50px;
  }

  ${({ isFirstPlace }) =>
    isFirstPlace &&
    `font-size: 38px;
  font-style: italic;
  font-weight: 900;
  line-height: 160%;
  letter-spacing: 0.15px;
  text-align: right;

  color: #fff;

  text-shadow: 1px 2px 0 #3fb148;
  -webkit-text-stroke: 1px black;

  @media only screen and (width >= 1441px) {
    font-size: 66px;
  }
    `}
`;
