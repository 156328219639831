import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import routes from '@constants/routes';
import CampaignForm from '@components/Leads/campaigns/Form';
import toastify from '@utils/toastify';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

const CampaignCreatePage = () => {
  const { leadId } = useParams();
  const history = useHistory();

  const handleSubmit = async (
    { values, actions },
    { onSuccess, onError, onFinish }
  ) => {
    try {
      const responseData = await LeadManagementApi.post(
        leadManagementApiUrls.campaigns.list,
        {
          ...values,
          leadProviderId: leadId
        }
      );

      history.push(routes.leads.leadProviders.page.view.url(leadId));

      toastify.success({
        message: 'Create operation success'
      });

      onSuccess && onSuccess(responseData);
    } catch (e) {
      onError && onError(e);

      actions.setErrors({
        ...e,
        nonFieldErrors:
          e.nonFieldErrors || e?.response?.data?.error?.message || e.message
      });

      toastify.error({
        message: 'Error',
        description: 'Failed to submit form'
      });
    } finally {
      onFinish && onFinish();
    }
  };

  return (
    <MainLayout title="Create Campaign">
      <CampaignForm onSubmit={handleSubmit} />
    </MainLayout>
  );
};

export default CampaignCreatePage;
