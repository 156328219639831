import React, { useRef, useState } from 'react';
import PT from 'prop-types';

import Stack from '@components/shared/Stack/Stack';
import LinearProgress from '@components/shared/LinearProgress/LinearProgress';
import Button from '@components/shared/Button/Button';
import ExportTableCSVForm from '@components/shared/ExportTableCSV/ExportTableCSVForm';
import { FileDownloadIcon } from '@components/shared/icons/Icons';
import { exportApiUrls } from '@constants/exportApi';
import ExportApi from '@utils/exportApi';
import Modal from '@components/shared/Modal/Modal';
import { apiBaseUrl } from '@constants/api';
import { useSimulationProgress } from '@hooks/useSimulationProgress';
import toastify from '@utils/toastify';

export const exportTypes = {
  odata: 'odata',
  json: 'json',
  odataCustom: 'odataCustom'
};

const exportEndpoint = {
  [exportTypes.odata]: exportApiUrls.odata,
  [exportTypes.json]: exportApiUrls.json,
  [exportTypes.odataCustom]: exportApiUrls.odataCustom
};

const ExportTableCSV = ({
  exportFileName,
  customApiBaseUrl,
  apiUrl,
  fields,
  type,
  title = 'Export CSV',
  additionalParams,
  buttonProps,
  root
}) => {
  const [exportCSVModal, setExportCSVModal] = useState(false);
  const downloadInputRef = useRef(null);

  const { simulateProgress, setDownloadProgress, totalProgress } =
    useSimulationProgress(setExportCSVModal);

  const handleSubmit = async ({ properties, rows }) => {
    simulateProgress(rows * 0.05);
    const payload = {
      url: (customApiBaseUrl || apiBaseUrl) + apiUrl,
      batchSize: additionalParams ? rows : 500,
      properties: fields
        .filter(({ key }) => properties.includes(key.toString()))
        .map(({ field, fieldName }) =>
          fieldName === undefined ? field : `${fieldName}:${field}`
        )
        .join(),
      rows: rows,
      root,
      timeOffset: new Date().getTimezoneOffset(),
      ...(additionalParams && { ...additionalParams })
    };
    try {
      const data = await ExportApi.get(exportEndpoint[type], {
        params: payload,
        onDownloadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setDownloadProgress(percentCompleted);
        }
      });
      const blob = new Blob([data], { type: 'octet/stream' });
      const url = window.URL.createObjectURL(blob);
      downloadInputRef.current.href = url;
      downloadInputRef.current.download = `${
        exportFileName || document.title
      }.csv`;
      downloadInputRef.current.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toastify.error({
        message: 'Error',
        description: 'Failed to download file'
      });
    }
  };

  return (
    <>
      <a ref={downloadInputRef} style={{ display: 'none' }} />
      <Button
        onClick={() => setExportCSVModal(true)}
        startIcon={<FileDownloadIcon size={18} />}
        variant="text"
        {...buttonProps}
      >
        {title}
      </Button>
      <Modal
        open={exportCSVModal}
        onClose={() => setExportCSVModal(false)}
        title="Export CSV"
        minContentWidth="400px"
        withCloseIcon
      >
        <ExportTableCSVForm fields={fields} onSubmit={handleSubmit} />
        <Stack mt={2}>
          <LinearProgress variant="determinate" value={totalProgress} />
        </Stack>
      </Modal>
    </>
  );
};

ExportTableCSV.propTypes = {
  customApiBaseUrl: PT.string,
  apiUrl: PT.string.isRequired,
  fields: PT.array.isRequired,
  type: PT.oneOf(Object.values(exportTypes)).isRequired,
  exportFileName: PT.string,
  title: PT.string,
  additionalParams: PT.object,
  buttonProps: PT.object,
  root: PT.string
};

export default ExportTableCSV;
