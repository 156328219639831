import React from 'react';

import routes from '@constants/routes';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';
import Box from '@components/shared/Box/Box';
import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';
import LinearProgress from '@components/shared/LinearProgress/LinearProgress';
import DataTable from '@components/shared/DataTable/DataTable';
import Stack from '@components/shared/Stack/Stack';

export const columns = [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <ShowDetailsActionButton
          path={routes.dashboard.applicationErrors.page.url(
            row.pageName,
            row.fieldName
          )}
          hidden={!helpers.getActionPermission(permissionsMap.telemetryView)}
        />
      </Box>
    )
  },
  {
    headerName: 'Field name',
    field: 'fieldName',
    minWidth: 210,
    flex: 1
  },
  {
    headerName: 'Errors count',
    field: 'errorsCount',
    minWidth: 100,
    flex: 1
  },
  {
    headerName: 'Percentage of errors',
    field: 'persantageErrors',
    minWidth: 200,
    flex: 1,
    renderCell: ({ row }) => (
      <LinearProgress variant="determinate" value={row.persantageErrors} />
    )
  }
];

export const getTabList = (data) =>
  data.map((item, idx) => ({
    key: idx,
    label: (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        {item.pageName}
      </Stack>
    ),
    count: item.attemptsCount,
    content: (
      <DataTable
        disableColumnFilter
        columns={columns}
        tableData={item.dashboardTelemetry.map((elem) => ({
          ...elem,
          pageName: item.pageName
        }))}
        rowKey="fieldName"
        hideFooter
      />
    )
  }));
