import React, { memo, useEffect, useMemo, useState } from 'react';
import PT from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { StyledAppBar, StyledMenu, StyledMenuItem } from './styled';
import { getSystemStatus, getSystemStatusStyles } from './utils';

import { AccountIcon, BurgerMenuIcon } from '@components/shared/icons/Icons';
import IconButton from '@components/shared/IconButton/IconButton';
import Skeleton from '@components/shared/Skeleton/Skeleton';
import Divider from '@components/shared/Divider/Divider';
import Toolbar from '@components/shared/Toolbar/Toolbar';
import MenuItem from '@components/shared/Menu/MenuItem';
import Button from '@components/shared/Button/Button';
import Stack from '@components/shared/Stack/Stack';
import Link from '@components/shared/Link/Link';
import Loader from '@components/shared/Loader';
import Dialog from '@components/shared/Modal/Dialog';
import { permissionsMap } from '@constants/permissions';
import { botDashboardUri } from '@constants/common';
import { rolesMap } from '@constants/roles';
import routes from '@constants/routes';
import { palette } from '@utils/theme';
import helpers from '@utils/helpers';
import Logger from '@utils/logger';
import {
  selectApiVersion,
  selectAreServicesStatusesLoading,
  selectServicesStatuses
} from '@store/settings';
import {
  fetchApiVersion,
  fetchServicesStatuses
} from '@store/settings/actions';
import { authService } from '@services/auth';
import Permissions from '@utils/permissions';
import { adminApiUrls } from '@constants/adminApiUrls';
import AdminApi from '@utils/adminApi';
import toastify from '@utils/toastify';

const Header = ({ currentUser }) => {
  const [isBotDashboardLoading, setIsBotDashboardLoading] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const apiVersion = useSelector(selectApiVersion);
  const { data: servicesStatuses } = useSelector(selectServicesStatuses);
  const areServicesStatusesLoading = useSelector(
    selectAreServicesStatusesLoading
  );
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const isLeadProvider = helpers.isLeadProvider();

  const homeLink = useMemo(
    () =>
      helpers.getMainPageRedirect({
        permissions: Permissions.get()
      }),
    []
  );

  const currentUserName = useMemo(() => currentUser?.userName, [currentUser]);

  const hasAccessToHealthCheck =
    helpers.hasRole(rolesMap.Admin, rolesMap.Manager) && !isLeadProvider;

  const systemStatus = getSystemStatus(servicesStatuses);

  useEffect(() => {
    if (!hasAccessToHealthCheck) {
      return;
    }
    dispatch(fetchServicesStatuses());
  }, []);

  useEffect(() => {
    if (apiVersion) {
      return;
    }
    dispatch(fetchApiVersion());
  }, [apiVersion, dispatch]);

  useEffect(() => {
    if (areServicesStatusesLoading || !hasAccessToHealthCheck) {
      return;
    }
    const interval = setInterval(
      () => {
        dispatch(fetchServicesStatuses());
      },
      1000 * 60 * 5
    );
    return () => clearInterval(interval);
  }, [areServicesStatusesLoading]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBotDashboardRedirect = async () => {
    setIsBotDashboardLoading(true);
    try {
      const data = await AdminApi.get(adminApiUrls.botDashboard);
      // Safari open link new tab fix
      setTimeout(() => {
        window.open(`${botDashboardUri}?token=${data.token}`, '_blank').focus();
      }, 0);
    } catch (e) {
      Logger.error(e);
      toastify.error({
        message: e.message,
        description: e.message
      });
    } finally {
      setIsBotDashboardLoading(false);
    }
  };

  return (
    <StyledAppBar position="fixed">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Link to={homeLink} color={palette.primary} component={RouterLink}>
          eLoanWarehouse Manager {apiVersion && `v${apiVersion}`}
        </Link>
        {hasAccessToHealthCheck && (
          <Stack
            sx={getSystemStatusStyles(systemStatus)}
            direction="row"
            alignItems="center"
            gap="5px"
            component={RouterLink}
            to={routes.dashboard.healthStatus}
          >
            <span>• System status:</span>
            {systemStatus || <Loader size="1em" sx={{ p: 0 }} />}
          </Stack>
        )}
        <Toolbar>
          <Stack direction="row" gap={1} alignItems="center">
            <AccountIcon />
            <span>
              {currentUser ? (
                currentUserName
              ) : (
                <Skeleton
                  height="30px"
                  width="40px"
                  sx={{ bgcolor: palette.dark }}
                />
              )}
            </span>
          </Stack>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            sx={{ ml: '0', color: '#fff' }}
          >
            <BurgerMenuIcon />
          </IconButton>
        </Toolbar>
      </Stack>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={() => setOpenLogoutModal(true)}>
          Log out
        </StyledMenuItem>
        {helpers.getActionPermission(permissionsMap.botAdministrationView) && (
          <div>
            <Divider variant="middle" color="#d1d9e2" />
            <MenuItem>
              <Button
                loading={isBotDashboardLoading}
                onClick={handleBotDashboardRedirect}
              >
                Bot Dashboard
              </Button>
            </MenuItem>
          </div>
        )}
      </StyledMenu>
      <Dialog
        open={openLogoutModal}
        onClose={() => setOpenLogoutModal(false)}
        onConfirm={() => authService.logout()}
        title="Are you sure you want to log out?"
      />
    </StyledAppBar>
  );
};

Header.propTypes = {
  currentUser: PT.object
};

export default memo(Header);
