import React from 'react';

import Box from '@components/shared/Box/Box';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';
import ConfirmActionSwitch from '@components/shared/DataTable/actions/ConfirmActionSwitch';
import { commonLeadProviderStatsColumns } from '@constants/leads';

export const getLeadProviderColumns = ({ handleStatus }) => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <ConfirmActionSwitch
          size="small"
          checked={row.status}
          hidden={
            !helpers.getActionPermission(permissionsMap.leadProvidersManage)
          }
          modalProps={{
            itemId: row.leadProviderId,
            content: row.status
              ? 'Do you want to pause?'
              : 'Do you want to resume?',
            onConfirm: () =>
              row.status
                ? handleStatus(row.leadProviderId, true)
                : handleStatus(row.leadProviderId, false)
          }}
        />
      </Box>
    )
  },
  {
    headerName: 'Name',
    field: 'name',
    width: 200
  },
  ...commonLeadProviderStatsColumns
];
