import { unsetOption } from '@constants/filters';

export const dataTypes = {
  Request: 'Request',
  Headers: 'Headers',
  Response: 'Response',
  ClientValidation: 'ClientValidation',
  ClientRequest: 'ClientRequest',
  LeadRequest: 'LeadRequest',
  LeadResponse: 'LeadResponse',
  CustomFieldResponse: 'CustomFieldResponse',
  CustomFieldRequest: 'CustomFieldRequest',
  CustomerRequest: 'CustomerRequest',
  CustomerResponse: 'CustomerResponse',
  QueryString: 'QueryString',
  Exception: 'Exception'
};

export const dataTypesOptions = [
  unsetOption,
  ...Object.values(dataTypes).map((value) => ({
    value,
    label: value
  }))
];
