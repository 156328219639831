import React from 'react';
import PT from 'prop-types';

import Button from '@components/shared/Button/Button';
import Box from '@components/shared/Box/Box';

const LoanFormControls = ({ isSubmitting, additionalControls }) => (
  <Box my={2}>
    <Button
      type="submit"
      variant="primary"
      disabled={isSubmitting}
      loading={isSubmitting}
    >
      Save
    </Button>
    {additionalControls}
  </Box>
);

LoanFormControls.propTypes = {
  isSubmitting: PT.bool.isRequired,
  additionalControls: PT.node
};

export default LoanFormControls;
