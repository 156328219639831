import React from 'react';
import PT from 'prop-types';
import { Field } from 'formik';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import dayjs from 'dayjs';

import { StyledDateRangePicker } from './styled';

import FormControl from '@components/shared/Form/FormControl/FormControl';
import FormHelperText from '@components/shared/Form/FormHelperText';
import { dateFormat } from '@constants/common';

const DateRangeInput = ({ error, shortcutsItems, ...props }) => (
  <FormControl fullWidth={props.fullWidth} margin="normal" error={!!error}>
    <StyledDateRangePicker
      {...props}
      size="small"
      slotProps={{
        shortcuts: {
          items: shortcutsItems
        },
        actionBar: { actions: ['cancel', 'accept'] }
      }}
      slots={{ field: SingleInputDateRangeField }}
      calendars={2}
      hasValue={props.value?.every((date) => !!date)}
    />
    {!!error && <FormHelperText error={!!error}>{error}</FormHelperText>}
  </FormControl>
);

const FormikDateRangeInput = ({
  name,
  preventDefaultHandler,
  format = dateFormat,
  ...rest
}) => (
  <Field name={name}>
    {({ meta, field, form }) => {
      const error = meta.touched ? meta.error : '';
      const onChange = (date) => {
        form.setFieldValue(
          name,
          date?.map((d) => (d ? dayjs(d) : date[0] || null)) // when user choose only one date, it need to set second date as first date - this is a change request from client
        );
      };

      return (
        <DateRangeInput
          {...field}
          {...rest}
          format={format}
          error={error}
          onChange={preventDefaultHandler ? rest.onChange : onChange}
        />
      );
    }}
  </Field>
);

DateRangeInput.defaultProps = {
  views: ['day', 'hours', 'minutes']
};

const propTypes = {
  name: PT.string.isRequired,
  label: PT.string.isRequired,
  value: PT.arrayOf(PT.oneOfType([PT.string, PT.object])),
  onChange: PT.func,
  onBlur: PT.func,
  error: PT.oneOfType([PT.bool, PT.string]),
  helperText: PT.string,
  format: PT.string,
  fullWidth: PT.bool,
  views: PT.arrayOf(PT.string),
  preventDefaultHandler: PT.bool,
  shortcutsItems: PT.arrayOf(
    PT.shape({
      label: PT.string.isRequired,
      getValue: PT.func.isRequired
    })
  )
};
DateRangeInput.propTypes = propTypes;
FormikDateRangeInput.propTypes = propTypes;

export default FormikDateRangeInput;
