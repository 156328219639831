import * as Yup from 'yup';

export const systemNotificationTypeMap = {
  operation: 'Operation',
  marketing: 'Marketing'
};

export const systemNotificationSchema = Yup.object().shape({
  messageTitle: Yup.string().label('Message Title').max(50).nullable(),
  messageTemplate: Yup.string().label('Message Template').max(250).required(),
  notificationType: Yup.string()
    .label('Notification Type')
    .oneOf(Object.values(systemNotificationTypeMap))
    .required()
});
