import React from 'react';
import PT from 'prop-types';

import { getTabList } from './config';

import TabsPanel from '@components/shared/TabsPanel/TabsPanel';

const ApplicationErrorsContent = ({ data }) => {
  const tabContentList = getTabList(data);
  return <TabsPanel tabContentList={tabContentList} />;
};

ApplicationErrorsContent.defaultProps = {
  data: []
};

ApplicationErrorsContent.propTypes = {
  data: PT.array.isRequired
};

export default ApplicationErrorsContent;
