import React from 'react';
import { Link } from 'react-router-dom';

import { CheckCircleIcon } from '@components/shared/icons/Icons';
import {
  filterOperators,
  noFieldData,
  permissionsMap
} from '@constants/common';
import routes from '@constants/routes';
import { cleanObject } from '@utils/object-cleaner';
import helpers from '@utils/helpers';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import {
  emailColumnWidth,
  fullNameColumnWidth,
  timeDateColumnWidth
} from '@components/shared/DataTable/DataTable.constants';

export const initialFilterParams = cleanObject({
  email: {
    contains: ''
  },
  firstName: {
    startswith: ''
  },
  lastName: {
    startswith: ''
  },
  readTime: {
    ge: '',
    le: ''
  },
  message: {
    contains: ''
  }
});

export const getHistoryColumns = () => [
  {
    headerName: 'Email',
    field: 'email',
    minWidth: emailColumnWidth,
    flex: 1
  },
  {
    headerName: 'Full name',
    field: 'firstName',
    minWidth: fullNameColumnWidth,
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      const displayName = helpers.getDisplayName(row?.firstName, row?.lastName);
      return helpers.getActionPermission(permissionsMap.usersView) &&
        row?.userId ? (
        <Tooltip title="User page">
          <Link to={routes.users.page.view.url(row.userId)}>{displayName}</Link>
        </Tooltip>
      ) : (
        displayName
      );
    }
  },
  {
    headerName: 'Read',
    field: 'read',
    minWidth: 80,
    flex: 1,
    type: muiDataTableTypes.boolean,
    renderCell: ({ row }) => (row?.read ? <CheckCircleIcon /> : '')
  },
  {
    headerName: 'Read Time',
    field: 'readTime',
    minWidth: timeDateColumnWidth,
    flex: 1,
    valueFormatter: (readTime) => helpers.getFormattedDate(readTime),
    renderCell: ({ row }) =>
      row?.readTime ? helpers.getFormattedDate(row.readTime) : noFieldData,
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Message',
    field: 'message',
    minWidth: 300,
    flex: 1,
    renderCell: ({ row }) => row?.message || noFieldData
  }
];
