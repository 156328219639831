import { adminApiUrls } from './adminApiUrls';

import GeneralStep from '@components/Application/LoanForm/GeneralStep';
import AddressStep from '@components/Application/LoanForm/AddressStep';
import EmploymentStep from '@components/Application/LoanForm/EmploymentStep';
import BankStep from '@components/Application/LoanForm/BankStep';
import DisclosureStep from '@components/Application/LoanForm/DisclosureStep';

export const loanFormStepsMap = {
  userInfo: 'UserInfo',
  userEmployment: 'UserEmployment',
  disclosure: 'Disclosure',
  userAddress: 'UserAddress',
  bankAccount: 'BankAccount'
};

export const loanFormStepsOrder = [
  loanFormStepsMap.userInfo,
  loanFormStepsMap.userAddress,
  loanFormStepsMap.userEmployment,
  loanFormStepsMap.bankAccount,
  loanFormStepsMap.disclosure
];

export const stepsConfigMap = {
  [loanFormStepsMap.userInfo]: {
    component: GeneralStep,
    title: 'General',
    apiEndpoints: adminApiUrls.apply.loanForm.userInfo
  },
  [loanFormStepsMap.userAddress]: {
    component: AddressStep,
    title: 'Address',
    apiEndpoints: adminApiUrls.apply.loanForm.userAddress
  },
  [loanFormStepsMap.userEmployment]: {
    component: EmploymentStep,
    title: 'Employment',
    apiEndpoints: adminApiUrls.apply.loanForm.userEmployment
  },
  [loanFormStepsMap.bankAccount]: {
    component: BankStep,
    title: 'Bank',
    apiEndpoints: adminApiUrls.apply.loanForm.bankAccount
  },
  [loanFormStepsMap.disclosure]: {
    component: DisclosureStep,
    title: 'Disclosure',
    apiEndpoints: adminApiUrls.apply.loanForm.disclosure
  }
};
