import React from 'react';
import PT from 'prop-types';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import Alert from '@components/shared/Alert/Alert';
import {
  flatPageSchema,
  getInitialValues
} from '@components/FlatPage/Form/schema';
import routes from '@constants/routes';
import toastify from '@utils/toastify';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import ConfirmDeleteButton from '@components/shared/ConfirmDeleteButton/ConfirmDeleteButton';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const FlatPageForm = ({ onSubmit, data, editMode }) => {
  const history = useHistory();

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);

    onSubmit(
      { values, actions },
      {
        onFinish: () => {
          actions.setSubmitting(false);
        }
      }
    );
  };

  const handleDelete = async () => {
    try {
      await AdminApi.delete(adminApiUrls.flatPages.page(data.contentEntityId));

      history.push(routes.flatPages.list);

      toastify.success({
        message: 'Page deleted'
      });
    } catch (e) {
      toastify.error({
        message: 'Delete operation failure'
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={getInitialValues(data)}
        onSubmit={handleSubmit}
        validationSchema={flatPageSchema}
      >
        {({ errors }) => (
          <Form>
            <FormikField
              type="text"
              name="title"
              label="Page title"
              placeholder="Page title"
              showValidateSuccess
            />
            <FormikField
              type="text"
              name="key"
              label="Page slug"
              placeholder="Page slug"
              showValidateSuccess
            />
            <FormikField
              type="editor"
              name="value"
              label="Page content"
              placeholder="Page content"
              showValidateSuccess
            />
            <FormikField
              type="checkbox"
              name="publish"
              label="Published"
              showValidateSuccess
            />
            {errors.nonFieldErrors && (
              <Alert message={errors.nonFieldErrors} type="error" />
            )}
            <Stack direction="row" gap={1}>
              <Button type="submit">Save</Button>
              {editMode && (
                <ConfirmDeleteButton
                  itemName="page"
                  handleDelete={handleDelete}
                />
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

FlatPageForm.defaultProps = {
  data: {},
  editMode: false
};

FlatPageForm.propTypes = {
  onSubmit: PT.func,
  data: PT.object,
  editMode: PT.bool
};

export default FlatPageForm;
