import React from 'react';
import { Link } from 'react-router-dom';

import { noFieldData, permissionsMap } from '@constants/common';
import routes from '@constants/routes';
import helpers from '@utils/helpers';
import UserEventsLink from '@components/shared/UserEventsLink';
import Box from '@components/shared/Box/Box';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import {
  emailColumnWidth,
  eventsColumnWidth,
  fullNameColumnWidth,
  timeDateColumnWidth
} from '@components/shared/DataTable/DataTable.constants';

export const columns = [
  {
    headerName: 'Action',
    field: 'actions',
    sortable: false,
    width: 60,
    renderCell: ({ row }) => (
      <Box size={4}>
        <EditActionButton
          path={routes.applications.page.edit.url(
            row.application.applicationUID
          )}
          hidden={!helpers.getActionPermission(permissionsMap.telemetryManage)}
        />
        <ShowDetailsActionButton
          path={routes.applications.page.view.url(
            row.application.applicationId
          )}
          hidden={
            !(
              row.application.customer &&
              helpers.getActionPermission(permissionsMap.applicationView)
            )
          }
        />
      </Box>
    )
  },
  {
    headerName: 'Application id',
    field: 'applicationId',
    sortField: 'applicationId',
    minWidth: 120,
    flex: 1,
    renderCell: ({ row }) =>
      helpers.getActionPermission(permissionsMap.applicationView) &&
      row.applicationId ? (
        <Link to={routes.applications.page.view.url(row.applicationId)}>
          {row.applicationId}
        </Link>
      ) : (
        row.applicationId || '-'
      )
  },
  {
    headerName: 'User email',
    field: 'Customer.Email',
    minWidth: emailColumnWidth,
    flex: 1,
    renderCell: ({ row }) => row.application.customer?.email
  },
  {
    headerName: 'Full name',
    field: 'Customer.FirstName',
    minWidth: fullNameColumnWidth,
    flex: 1,
    renderCell: ({ row }) => {
      const displayName = helpers.getDisplayName(
        row.application.customer?.firstName,
        row.application.customer?.lastName
      );
      return helpers.getActionPermission(permissionsMap.usersView) &&
        row.application.userId ? (
        <Tooltip title="User page">
          <Link to={routes.users.page.view.url(row.application.userId)}>
            {displayName}
          </Link>
        </Tooltip>
      ) : (
        displayName
      );
    }
  },
  {
    headerName: 'Created date',
    field: 'CreatedDate',
    minWidth: timeDateColumnWidth,
    flex: 1,
    renderCell: ({ row }) =>
      helpers.getFormattedDate(row.application.createdDate)
  },
  {
    headerName: 'Events',
    field: 'userId',
    minWidth: eventsColumnWidth,
    flex: 1,
    renderCell: ({ row }) =>
      helpers.getActionPermission(permissionsMap.loggedView) &&
      row.application.userId ? (
        <UserEventsLink executedBy={row.application.userId}>
          {helpers.cutString(row.application.userId, 5)}
        </UserEventsLink>
      ) : (
        row.application.userId || noFieldData
      )
  }
];
