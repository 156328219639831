import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import qs from 'query-string';

import WorkingCalendar from './WorkingCalendar';
import LeadStatsList from './LeadStatsList';

import CommunicationLog from '@components/Leads/CommunicationLog';
import Loader from '@components/shared/Loader';
import Logger from '@utils/logger';
import helpers from '@utils/helpers';
import { noFieldData, permissionsMap } from '@constants/common';
import routes from '@constants/routes';
import Stack from '@components/shared/Stack/Stack';
import Typography from '@components/shared/Typography/Typography';
import Button from '@components/shared/Button/Button';
import Accordion from '@components/shared/Accordion/Accordion';
import ListContentBlock from '@components/shared/ListContentBlock/ListContentBlock';
import TabsPanel from '@components/shared/TabsPanel/TabsPanel';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const LeadProviderDetails = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [states, setStates] = useState(null);
  const [error, setError] = useState(false);
  const history = useHistory();

  const loading = !data && !error;

  const fetchData = useCallback(async () => {
    try {
      const [leadData, { state }] = await Promise.all([
        LeadManagementApi.get(
          leadManagementApiUrls.leadProvider.information(params.id)
        ),
        AdminApi.get(adminApiUrls.apply.loanForm.userInfo.options)
      ]);
      setData(leadData);
      setStates(state);
    } catch (e) {
      Logger.error(e);
      setError(true);
    }
  }, [params.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const copyLeadHandler = () => {
    history.push({
      pathname: routes.leads.leadProviders.page.create,
      search: qs.stringify({
        leadId: data.leadProviderId
      })
    });
  };

  if (loading) return <Loader />;

  const generalInfoList = [
    {
      title: 'Day look cap:',
      content: data?.dailyLookCap
    },
    {
      title: 'Hr look cap:',
      content: data?.hourlyLookCap
    },
    {
      title: 'Day accept cap:',
      content: data?.dailyAcceptCap
    },
    {
      title: 'Hr accept cap:',
      content: data?.hourlyAcceptCap
    },
    {
      title: 'Day cost cap:',
      content: data?.dailyCostCap
    },
    {
      title: 'Max time to respond (Sec):',
      content: data?.maxTimeRespond
    }
  ];

  const addressInfoList = [
    {
      title: 'Address:',
      content: data?.address1
    },
    {
      title: 'Address 2:',
      content: data?.address2
    },
    {
      title: 'City:',
      content: data?.city
    },
    {
      title: 'Zipcode:',
      content: data?.zip
    },
    {
      title: 'Phone number:',
      content: data?.phone
    },
    {
      title: 'State:',
      content: states?.find((item) => item.id === data.stateId)?.displayName
    }
  ];

  const accordionList = [
    {
      key: '1',
      header: 'Address info',
      content: <ListContentBlock contentList={addressInfoList} />
    }
  ];

  const tabContentList = [
    {
      key: '1',
      label: 'Campaigns stats',
      content: <LeadStatsList id={data.leadProviderId} />
    },
    {
      key: '2',
      label: 'Calendar',
      content: <WorkingCalendar data={data} />
    },
    {
      key: '3',
      label: 'Communication log',
      content: (
        <CommunicationLog
          filter={{
            leadProviderId: data.leadProviderId
          }}
          exportFileName={`Communication Log by Lead Provider ${data.name}`}
          additionalColumns={[
            {
              headerName: 'Campaign',
              field: 'campaignName',
              minWidth: 150,
              flex: 1,
              renderCell: ({ row }) =>
                helpers.getActionPermission(
                  permissionsMap.leadProvidersView
                ) ? (
                  <Tooltip title="Campaign page">
                    <Link
                      to={routes.leads.leadProviders.campaign.view.url(
                        row.leadProviderId,
                        row.campaignId
                      )}
                    >
                      {row.campaignName}
                    </Link>
                  </Tooltip>
                ) : (
                  row.campaignName || noFieldData
                )
            }
          ]}
        />
      )
    }
  ];

  return (
    data &&
    states && (
      <>
        <Stack
          direction="row"
          alignItems="center"
          gap={3}
          my={2}
          flexWrap="wrap"
        >
          {data.name && <Typography variant="h3">{data.name}</Typography>}
          <Stack direction="row" gap={1}>
            {helpers.getActionPermission(
              permissionsMap.leadProvidersManage
            ) && (
              <Button onClick={copyLeadHandler}>
                Create new lead provider with this data
              </Button>
            )}
            {helpers.getActionPermission(
              permissionsMap.leadProvidersManage
            ) && (
              <Button
                component={Link}
                to={routes.leads.leadProviders.page.edit.url(
                  data.leadProviderId
                )}
                variant="outlined"
              >
                Edit Lead Provider
              </Button>
            )}
          </Stack>
        </Stack>

        <ListContentBlock title="General info" contentList={generalInfoList} />

        <Accordion
          accordionList={accordionList}
          wrapperProps={{
            my: 2
          }}
        />

        <TabsPanel tabContentList={tabContentList} />
      </>
    )
  );
};

export default LeadProviderDetails;
