import React from 'react';
import dayjs from 'dayjs';

import { getInitialValues } from './PeriodFilter/config';

import {
  requestIdColumnWidth,
  timeDateColumnWidth
} from '@components/shared/DataTable/DataTable.constants';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import helpers from '@utils/helpers';
import {
  filterOperators,
  noFieldData,
  renderCommonCell
} from '@constants/common';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import { decisionColumnValueOptions } from '@constants/leads';
import { cleanObject } from '@utils/object-cleaner';
import { showAllValue } from '@components/LeadReports/filters/config';

export const getColumns = ({ additionalColumns, campaignsOptions }) => [
  ...(additionalColumns?.start || []),
  {
    headerName: 'Created date',
    field: 'createdDate',
    minWidth: timeDateColumnWidth,
    flex: 1,
    renderHeaderFilter: () => null,
    valueFormatter: (createdDate) => helpers.getFormattedDate(createdDate),
    renderCell: ({ row, field }) => helpers.getFormattedDate(row[field]),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Lead campaign',
    field: 'leadCampaign',
    minWidth: 150,
    flex: 1,
    type: muiDataTableTypes.singleSelect,
    valueOptions: campaignsOptions,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Affiliate ID',
    field: 'affiliateId',
    minWidth: 100,
    flex: 1,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Sub-Affiliate ID',
    field: 'subAffiliateId',
    minWidth: 120,
    flex: 1,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Request ID',
    field: 'requestId',
    minWidth: requestIdColumnWidth,
    flex: 1,
    renderCell: renderCommonCell
  },
  ...(additionalColumns?.middle || []),
  {
    headerName: 'State',
    field: 'state',
    minWidth: 100,
    flex: 1,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Amount',
    field: 'amount',
    minWidth: 90,
    flex: 1,
    renderCell: renderCommonCell,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Decision',
    field: 'decision',
    minWidth: 150,
    flex: 1,
    type: 'singleSelect',
    valueOptions: decisionColumnValueOptions,
    renderCell: renderCommonCell
  },
  {
    headerName: 'Approved date',
    field: 'approvalDate',
    minWidth: timeDateColumnWidth,
    flex: 1,
    renderCell: ({ row }) =>
      row.approvalDate
        ? helpers.getFormattedDate(row.approvalDate)
        : noFieldData,
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Denial reason',
    field: 'denialReason',
    minWidth: 150,
    flex: 1,
    renderCell: ({ row, field }) => (
      <Tooltip title={row[field]}>{row[field] || noFieldData}</Tooltip>
    )
  },
  {
    headerName: 'Error message',
    field: 'errorMessage',
    minWidth: 150,
    flex: 1,
    renderCell: ({ row, field }) => (
      <Tooltip title={row[field]}>{row[field] || noFieldData}</Tooltip>
    )
  },
  ...(additionalColumns?.end || [])
];

export const getExportFileName = (query) => {
  const filterParams = JSON.parse(query.$filter || '{}');
  if (!filterParams.createdDate) {
    return 'Lead requests report';
  }
  const fromDate = helpers.getFormattedDate(filterParams.createdDate.ge);
  const toDate = helpers.getFormattedDate(filterParams.createdDate.le);
  return `Lead requests report ${fromDate} - ${toDate}`;
};

export const getInitialFilterParams = () =>
  cleanObject({
    createdDate: {
      ge: dayjs().subtract(1, 'week').startOf('day').toISOString(),
      le: dayjs().endOf('day').toISOString()
    },
    leadCampaign: {
      eq:
        getInitialValues().leadCampaign === showAllValue
          ? ''
          : getInitialValues().leadCampaign
    }
  });
