import React, { useMemo } from 'react';
import PT from 'prop-types';

import { getColumns } from './config';

import DataTable from '@components/shared/DataTable/DataTable';
import toastify from '@utils/toastify';
import './CampaignsDataTable.scss';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

const CampaignsDataTable = ({
  refetchList,
  additionalColumns,
  additionalActions,
  updateKey,
  isShownCaps,
  useExport,
  ...props
}) => {
  const handleStatus = async (id, status) => {
    try {
      await LeadManagementApi.post(leadManagementApiUrls.campaigns.pause(id), {
        pause: status
      });

      refetchList();
      updateKey();
    } catch (error) {
      toastify.error({
        message: error.response?.data?.error?.message
      });
    }
  };

  const actionsWidth = additionalActions?.length * 30 + 35 || 60;

  const columns = useMemo(
    () =>
      getColumns({ actionsWidth, additionalColumns, handleStatus }).filter(
        (item) => (isShownCaps ? item : !item.hide)
      ),
    [isShownCaps, additionalColumns]
  );

  return (
    <div className="mb-8">
      {useExport && useExport(columns)}
      <DataTable
        disableColumnFilter
        columns={columns}
        rowKey="campaignId"
        pagination={false}
        hideFooter
        {...props}
      />
    </div>
  );
};

CampaignsDataTable.defaultProps = {
  additionalColumns: {
    startColumns: [],
    endColumns: []
  }
};

CampaignsDataTable.propTypes = {
  tableData: PT.arrayOf(
    PT.shape({
      campaignId: PT.number,
      campaignName: PT.string,
      cost: PT.number,
      dailyAcceptCap: PT.number,
      dailyCostCap: PT.number,
      dailyLookCap: PT.number,
      hourlyAcceptCap: PT.number,
      hourlyLookCap: PT.number,
      providerType: PT.number,
      status: PT.bool,
      storeKey: PT.string
    })
  ).isRequired,
  additionalColumns: PT.shape({
    startColumns: PT.array.isRequired,
    endColumns: PT.array.isRequired
  }),
  additionalActions: PT.func,
  refetchList: PT.func.isRequired,
  updateKey: PT.func,
  isShownCaps: PT.bool,
  useExport: PT.func
};

export default CampaignsDataTable;
