import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button/Button';

const ShowCapsButton = ({ isShownCaps, setIsShownCaps }) => {
  const handleClick = () => {
    setIsShownCaps(!isShownCaps);
  };
  return (
    <Button onClick={handleClick} variant="outlined">
      {isShownCaps ? 'Hide' : 'Show'} Caps
    </Button>
  );
};

ShowCapsButton.propTypes = {
  isShownCaps: PropTypes.bool.isRequired,
  setIsShownCaps: PropTypes.func.isRequired
};
export default ShowCapsButton;
