import * as Yup from 'yup';

export const campaignGroupSchema = Yup.object().shape({
  name: Yup.string().label('Name').max(30).required(),
  dailyLookCap: Yup.number().label('Day Look cap').required(),
  hourlyLookCap: Yup.number().label('Hr look cap').required(),
  dailyAcceptCap: Yup.number().label('Day accept cap').required(),
  hourlyAcceptCap: Yup.number().label('Hr accept cap').required(),
  dailyCostCap: Yup.number().label('Day cost cap').required()
});
