import React, { useMemo, useState } from 'react';
import PT from 'prop-types';

import Button from '@components/shared/Button/Button';

const ExpandShrinkButton = ({
  list,
  rowKey,
  expandedRows,
  setExpandedRows,
  setExpandAllTables
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const rowsCanExpand = useMemo(
    () => list?.filter((item) => item.campaigns?.length),
    [list]
  );
  const expandedRowsList = useMemo(
    () => rowsCanExpand?.map((item) => item[rowKey]),
    [rowsCanExpand, rowKey]
  );

  const canShrinkAll = useMemo(
    () => expandedRows?.length === rowsCanExpand?.length,
    [expandedRows, rowsCanExpand]
  );
  const canExpandAll = useMemo(() => !expandedRows?.length, [expandedRows]);

  const handleExpandAll = () => {
    setExpandAllTables?.(false);
    setExpandedRows(expandedRowsList);
    setIsExpand(true);
  };

  const handleShrinkAll = () => {
    setExpandedRows([]);
    setIsExpand(false);
  };

  const buttonProps = useMemo(() => {
    if (canExpandAll) {
      return {
        label: 'Expand all',
        onClick: handleExpandAll
      };
    }

    if (isExpand || canShrinkAll) {
      return {
        label: 'Shrink all',
        onClick: handleShrinkAll
      };
    } else {
      return {
        label: 'Expand all',
        onClick: handleExpandAll
      };
    }
  }, [canExpandAll, canShrinkAll, isExpand]);

  return (
    <Button onClick={buttonProps.onClick} variant="outlined">
      {buttonProps.label}
    </Button>
  );
};

ExpandShrinkButton.propTypes = {
  list: PT.array.isRequired,
  rowKey: PT.string.isRequired,
  expandedRows: PT.array.isRequired,
  setExpandedRows: PT.func.isRequired,
  setExpandAllTables: PT.func
};

export default ExpandShrinkButton;
