import * as Yup from 'yup';
import isNil from 'lodash/isNil';

import {
  floatNumberRegExp,
  nineDigitFloatRegExp,
  positiveFloatNumberRegExp,
  positiveNineDigitFloatRegExp
} from '@constants/regexp';

export default {
  email: (label) => Yup.string().label(label).email(),
  password: (label) => Yup.string().label(label).min(8),
  phone: (label) =>
    Yup.string()
      .label(label)
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        {
          message: 'Phone number is not valid'
        }
      ),
  regExp: (label, mask, message) =>
    Yup.string().label(label).matches(mask, {
      message
    }),
  fieldConfirm: (ref, label, errorMessage = 'Fields are not matched') =>
    Yup.string()
      .label(label)
      .oneOf([Yup.ref(ref), ''], errorMessage),
  booleanTrue: (message = 'Field must be checked') =>
    Yup.bool().oneOf([true], message),
  numberOfFixedLength: (
    length,
    message = `\${path} must consist of ${length} digits`
  ) =>
    Yup.string()
      .matches(/^[0-9]+$/, '${path} must contain only digits')
      .length(length, message),
  strictInteger: (message = '${path} must have integer value') =>
    Yup.number().test('strictInteger', message, function () {
      return /^\d*$/.test(this.options.originalValue);
    }),
  moneyFloatField: Yup.number()
    .test('maxIntegerLength', '${path} allows at most 9 digits', function () {
      if (isNil(this.options.originalValue)) return true;

      return nineDigitFloatRegExp.test(String(this.options.originalValue));
    })
    .test(
      'maxPrecisionReached',
      'There can be no more than two digits after the decimal point.',
      function () {
        if (isNil(this.options.originalValue)) return true;

        return floatNumberRegExp.test(String(this.options.originalValue));
      }
    )
    .typeError('${path} mast contain only digits'),
  moneyPositiveFloatField: Yup.number()
    .min(0)
    .test('maxIntegerLength', '${path} allows at most 9 digits', function () {
      if (isNil(this.options.originalValue)) return true;

      return positiveNineDigitFloatRegExp.test(
        String(this.options.originalValue)
      );
    })
    .test(
      'maxPrecisionReached',
      'There can be no more than two digits after the decimal point.',
      function () {
        if (isNil(this.options.originalValue)) return true;

        return positiveFloatNumberRegExp.test(
          String(this.options.originalValue)
        );
      }
    )
    .typeError('${path} mast contain only digits'),
  bankRoutingNumber: (name, message = 'Not valid routing number') =>
    Yup.string()
      .matches(/^[0-9]+$/, {
        message: 'Only digits are allowed'
      })
      .test(name, message, (routing) => {
        if (!routing || routing.length !== 9) {
          return false;
        }

        // http://en.wikipedia.org/wiki/Routing_transit_number#MICR_Routing_number_format
        const checksumTotal =
          7 *
            (parseInt(routing.charAt(0), 10) +
              parseInt(routing.charAt(3), 10) +
              parseInt(routing.charAt(6), 10)) +
          3 *
            (parseInt(routing.charAt(1), 10) +
              parseInt(routing.charAt(4), 10) +
              parseInt(routing.charAt(7), 10)) +
          9 *
            (parseInt(routing.charAt(2), 10) +
              parseInt(routing.charAt(5), 10) +
              parseInt(routing.charAt(8), 10));

        const checksumMod = checksumTotal % 10;

        return checksumMod === 0;
      }),
  zipCode: (label = 'ZIP code') =>
    Yup.string()
      .label(label)
      .min(5, `${label} must be exactly 5 digits`)
      .max(5, `${label} must be exactly 5 digits`),
  equalsTo: (number) =>
    Yup.string().matches(
      new RegExp(`^\\d{${number}}$`),
      `The field must be exactly ${number} digits`
    )
};
