import React, { useMemo, useState } from 'react';
import PT from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { campaignTypeOptions, costTypeOptions } from '../Form/schema';

import routes from '@constants/routes';
import helpers from '@utils/helpers';
import { noFieldData, permissionsMap } from '@constants/common';
import CommunicationLog from '@components/Leads/CommunicationLog';
import Stack from '@components/shared/Stack/Stack';
import Typography from '@components/shared/Typography/Typography';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import ListContentBlock from '@components/shared/ListContentBlock/ListContentBlock';
import Button from '@components/shared/Button/Button';
import Dialog from '@components/shared/Modal/Dialog';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

const campaignStatusLabels = {
  pause: 'pause',
  resume: 'resume'
};

const CampaignDetails = ({ data, refetchData }) => {
  const [showModal, setShowModal] = useState(false);

  const getStatusLabel = (status) =>
    status ? campaignStatusLabels.pause : campaignStatusLabels.resume;

  const displayStatusLabel = useMemo(
    () => _.capitalize(`${getStatusLabel(data.status)} Campaign`),
    [data]
  );

  const handleStatus = async (id, status) => {
    await LeadManagementApi.post(leadManagementApiUrls.campaigns.pause(id), {
      pause: status
    });

    refetchData();
  };

  const infoList = [
    {
      title: 'Campaign:',
      content: data?.campaignName
    },
    {
      title: 'Enabled:',
      content: helpers.renderBooleanValue(data?.status)
    },
    {
      title: 'Store name:',
      content: data?.storeKey
    },
    {
      title: 'Hr look cap:',
      content: data?.hourlyLookCap
    },
    {
      title: 'Day look cap:',
      content: data?.dailyLookCap
    },
    {
      title: 'Day accept cap:',
      content: data?.dailyAcceptCap
    },
    {
      title: 'Hr accept cap:',
      content: data?.hourlyAcceptCap
    },
    {
      title: 'Day cost cap:',
      content: data?.dailyCostCap
    },
    {
      title: 'Cost:',
      content: data?.cost
    },
    {
      title: 'Cost type:',
      content: data.providerType
        ? costTypeOptions.find((item) => item.value === data.providerType).label
        : noFieldData
    },
    {
      title: 'Lead provider:',
      content: helpers.getActionPermission(permissionsMap.leadProvidersView) ? (
        <Tooltip title="Lead Provider Page">
          <Link
            to={routes.leads.leadProviders.page.view.url(data.leadProviderId)}
          >
            {data.leadProvider.name}
          </Link>
        </Tooltip>
      ) : (
        data.leadProvider?.name || noFieldData
      )
    },
    {
      title: 'Underwriting key:',
      content: data?.underwritingKey
    },
    {
      title: 'LMS login:',
      content: data?.lmsLogin
    },
    {
      title: 'LMS password:',
      content: data?.lmsPassword
    },
    {
      title: 'Campaign type:',
      content: campaignTypeOptions.find(
        (item) => item.value === data.strategyId
      ).label
    }
  ];

  return (
    <>
      <Stack direction="row" alignItems="center" gap={3} my={2}>
        <Typography variant="h3">Campaign Details</Typography>
      </Stack>

      <ListContentBlock contentList={infoList} />

      {helpers.getActionPermission(permissionsMap.leadProvidersManage) && (
        <Stack direction="row" gap={1} my={1}>
          <Button
            component={Link}
            to={routes.leads.leadProviders.campaign.edit.url(
              data.leadProviderId,
              data.campaignId
            )}
            variant="outlined"
          >
            Edit Campaign
          </Button>

          <Button onClick={() => setShowModal(true)}>
            {displayStatusLabel}
          </Button>
        </Stack>
      )}

      <CommunicationLog
        filter={{
          campaignId: data.campaignId
        }}
        exportFileName={`Communication Log by Campaign ${data.name}`}
      />

      <Dialog
        open={showModal}
        title={`Do you want ${getStatusLabel(data.status)}?`}
        onConfirm={() => handleStatus(data.campaignId, data.status)}
        okText="Yes"
        cancelText="No"
      />
    </>
  );
};

CampaignDetails.defaultProps = {
  data: {}
};

CampaignDetails.propTypes = {
  data: PT.object,
  refetchData: PT.func.isRequired
};

export default CampaignDetails;
