import React from 'react';
import PT from 'prop-types';

import Loader from '@components/shared/Loader';
import { notificationFormStepsOrder } from '@constants/notificationFormConfig';
import Typography from '@components/shared/Typography/Typography';
import { Stepper, Step, StepLabel } from '@components/shared/Stepper/Stepper';

const CustomNotificationSteps = ({
  activeStep,
  lastFilledStep,
  setActiveStep
}) => {
  if (!activeStep) return <Loader />;

  const activeStepIndex = notificationFormStepsOrder.indexOf(activeStep);
  const lastFilledStepIndex =
    notificationFormStepsOrder.indexOf(lastFilledStep);

  const handleStepChange = (stepKey, idx) => {
    lastFilledStepIndex + 1 >= idx && setActiveStep(stepKey);
  };

  return (
    <div>
      <Typography variant="h4" component="div" my={2}>
        Form steps
      </Typography>

      <Stepper activeStep={activeStepIndex} orientation="vertical">
        {notificationFormStepsOrder.map((stepKey, idx) => (
          <Step
            key={idx}
            disabled={idx === activeStepIndex || idx > lastFilledStepIndex + 1}
            onClick={() => handleStepChange(stepKey, idx)}
          >
            <StepLabel>{stepKey}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

CustomNotificationSteps.propTypes = {
  activeStep: PT.string,
  lastFilledStep: PT.string,
  setActiveStep: PT.func.isRequired
};

export default React.memo(CustomNotificationSteps);
