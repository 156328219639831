import React from 'react';
import mapValues from 'lodash/mapValues';
import isFunction from 'lodash/isFunction';
import { useParams } from 'react-router-dom';
import PT from 'prop-types';

import Loader from '@components/shared/Loader';
import { stepsConfigMap } from '@constants/loanFormConfig';
import { adminApiUrls } from '@constants/adminApiUrls';

export const getStepsConfigMap = (applicationId) =>
  mapValues(stepsConfigMap, (stepConfig) => ({
    ...stepConfig,
    apiEndpoints: mapValues(stepConfig.apiEndpoints, (endpoint) =>
      isFunction(endpoint) ? endpoint(applicationId) : endpoint
    )
  }));

const Application = ({
  activeStep,
  applicationData,
  lastFilledStep,
  setActiveStep
}) => {
  const { id: applicationID } = useParams();

  if (!activeStep) {
    return <Loader />;
  }

  const processedStepsConfig = getStepsConfigMap(applicationID);

  const StepComponent = stepsConfigMap[activeStep].component;

  return (
    <StepComponent
      activeStep={activeStep}
      amountEndpoint={adminApiUrls.apply.applicationAmount(applicationID)}
      stepsConfig={processedStepsConfig}
      submit
      isPublicForm
      applicationData={applicationData}
      lastFilledStep={lastFilledStep}
      setActiveStep={setActiveStep}
    />
  );
};

Application.propTypes = {
  activeStep: PT.string,
  lastFilledStep: PT.string,
  applicationData: PT.object,
  setActiveStep: PT.func.isRequired
};

export default React.memo(Application);
