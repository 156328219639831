import React from 'react';
import PT from 'prop-types';
import MuiPagination from '@mui/material/Pagination';

import GoToPageSection from '@components/shared/DataTable/components/GoToPageSection';
import PaginationItem from '@components/shared/Pagination/PaginationItem';
import Stack from '@components/shared/Stack/Stack';

const Pagination = (props) => {
  const { page, rowsPerPage, count, onChange } = props;
  const current = page - 1;

  return (
    <Stack padding={1}>
      <GoToPageSection
        rowsCount={count}
        pageSize={rowsPerPage}
        selectedPage={current}
        handlePageChange={(_, option) => {
          onChange(rowsPerPage, option.value);
        }}
      />
      <Stack
        direction="row"
        gap={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <span>
          {current * rowsPerPage + 1}-{current * rowsPerPage + rowsPerPage} of{' '}
          {count}
        </span>
        <MuiPagination
          showFirstButton
          showLastButton
          shape="rounded"
          renderItem={(item) => {
            const isUnnecessaryItem = [
              'start-ellipsis',
              'page',
              'end-ellipsis'
            ].includes(item.type);

            if (isUnnecessaryItem) {
              return null;
            }

            return <PaginationItem {...item} />;
          }}
          siblingCount={0}
          boundaryCount={0}
          {...props}
          count={count / rowsPerPage}
        />
      </Stack>
    </Stack>
  );
};

Pagination.propTypes = {
  page: PT.number.isRequired,
  rowsPerPage: PT.number.isRequired,
  count: PT.number.isRequired,
  onChange: PT.func.isRequired
};

export default Pagination;
