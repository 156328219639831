import React from 'react';
import { getGridStringOperators } from '@mui/x-data-grid-premium';

import routes from '@constants/routes';
import { permissionsMap } from '@constants/permissions';
import {
  ApplicationIcon,
  ApplicationUsersIcon,
  ConfigurationIcon,
  DashboardIcon,
  LeadIcon,
  LogsIcon,
  NotificationsIcon,
  PaymentsIcon,
  UserLoansIcon,
  VersionsIcon,
  CompareIcon,
  WallboardIcon,
  BinCheckerIcon
} from '@components/shared/icons/Icons';
import filterHelpers from '@utils/filterHelpers';
import DateRangeInTwoInputs from '@components/shared/DataTable/filters/DateRangeInTwoInputs';
import FilterByOptions from '@components/shared/DataTable/filters/FiltersByOptions';
import FullNameFilter from '@components/shared/DataTable/filters/FullNameFilter';

export { permissionsMap } from '@constants/permissions';
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const MUI_LICENSE_KEY = process.env.MUI_LICENSE_KEY;
export const SENTRY_DSN_KEY = process.env.SENTRY_DSN_KEY;
export const WALLBOARD_ACCESS_TOKEN = process.env.WALLBOARD_ACCESS_TOKEN;

export const API_AUTH_BASE = process.env.API_AUTH_BASE;
export const CLIENT_ID = process.env.CLIENT_ID;
export const SCOPES_LIST = {
  OFFLINE_ACCESS: 'offline_access',
  ROLES: 'roles'
};

export const SESSION_KEY = '__user-token__';
export const REFRESH_KEY = '__user-refresh-token__';
export const EXPIRE_KEY = '__user-expires__';
export const REFRESH_PROCESSING_KEY = '__user-refresh-processing__';
export const POLICY_KEY = 'policies';
export const ORGANIZATION_KEY = 'organization';

export const ISSUED_KEY = '__token-issued__';
export const COPYRIGHT_YEAR = 2020;
export const REFRESH_TIME = process.env.REFRESH_TIME;
export const REFRESH_TIME_SINGLE_PAGES = process.env.REFRESH_TIME_SINGLE_PAGES;
export const DEVICE_UUID_KEY = '__user-device-uuid__';

export const ROLE_KEY = '__user-role__';
export const ADMIN_ROLE = '["Admin"]';
export const LEAD_PROVIDER_ID_KEY = 'leadProviderId';

export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please try again later.';

export const formHasErrorsMessage = 'Scroll up and correct errors on page';
export const customerSiteSrc = process.env.PUBLIC_CUSTOMER_WEB_URL;

export const loanDefaultLimits = {
  min: 300,
  max: 2500
};

export const loanDefaultValue = 600;

export const payrollSources = {
  employed: {
    displayName: 'Employed',
    id: 1,
    shortName: 'E'
  }
};

const getWallboardURL = (link) =>
  link + '?accessToken=' + WALLBOARD_ACCESS_TOKEN;

//! if the page from the menu item has tabs with different paths,
// you need to add "path" key to item and put there root path of item

export const mainMenu = [
  {
    title: 'Dashboard',
    link: routes.dashboard.applicationErrors.list,
    icon: <DashboardIcon />,
    perm: [
      permissionsMap.ibvResultView,
      permissionsMap.applicationTimeView,
      permissionsMap.fieldsErrorRateView,
      permissionsMap.errorResponseRateView,
      permissionsMap.internalPerformanceView,
      permissionsMap.discrepancyReportView,
      permissionsMap.abandonStepStatisticsView,
      permissionsMap.applicationRatesView
    ],
    operator: 'OR',
    children: [
      {
        title: 'Fields Error Rate',
        link: routes.dashboard.applicationErrors.list,
        perm: permissionsMap.fieldsErrorRateView
      },
      {
        title: 'Application Time',
        link: routes.dashboard.applicationSpentTime,
        perm: permissionsMap.applicationTimeView
      },
      {
        title: 'Error Response Rate',
        link: routes.dashboard.apiAppErrors,
        perm: permissionsMap.errorResponseRateView
      },
      {
        title: 'Internal performance',
        link: routes.dashboard.internalPerformance.web,
        path: routes.dashboard.internalPerformance.path,
        perm: permissionsMap.internalPerformanceView
      },
      {
        title: 'Application Rates',
        link: routes.dashboard.leadStats,
        perm: permissionsMap.applicationRatesView
      },
      {
        title: 'Abandoned Applications',
        link: routes.dashboard.abandonedApplications,
        perm: permissionsMap.abandonStepStatisticsView
      },
      {
        title: 'Health Status',
        link: routes.dashboard.healthStatus,
        perm: permissionsMap.abandonStepStatisticsView // need permission
      },
      {
        title: 'Discrepancy Report',
        link: routes.dashboard.discrepancyReport.path,
        perm: permissionsMap.discrepancyReportView
      },
      {
        title: 'IBV Traffic Report',
        link: routes.dashboard.ibv.trafficReport,
        perm: permissionsMap.ibvResultView
      },
      {
        title: 'IBV Scoring Report',
        link: routes.dashboard.ibv.scoringReport.list,
        perm: permissionsMap.ibvResultView
      }
    ]
  },
  {
    title: 'Configuration',
    link: routes.configuration.controlPanel,
    icon: <ConfigurationIcon />,
    perm: [
      permissionsMap.managersView,
      permissionsMap.groupView,
      permissionsMap.viewSettings,
      permissionsMap.manageSettings,
      permissionsMap.flatView,
      permissionsMap.loggedView,
      permissionsMap.promoCodesView
    ],
    operator: 'OR',
    children: [
      {
        title: 'Pages',
        link: routes.flatPages.list,
        perm: permissionsMap.flatView
      },
      {
        title: 'Blocked IP Addresses',
        link: routes.blockedIPAddresses,
        perm: permissionsMap.loggedView
      },
      {
        title: 'Promo Codes',
        link: routes.promoCodes.list,
        perm: permissionsMap.promoCodesView
      },
      {
        title: 'Global Settings',
        link: routes.configuration.globalSettings,
        perm: [permissionsMap.viewSettings, permissionsMap.manageSettings],
        operator: 'OR'
      },
      {
        title: 'Maintenance Mode',
        link: routes.configuration.maintenanceMode,
        perm: permissionsMap.maintananceModeView
      },
      {
        title: 'Wallboard - Team Goals',
        link: routes.configuration.teamGoals,
        perm: permissionsMap.wallboardManage
      }
    ]
  },
  {
    title: 'Applications',
    link: routes.applications.list,
    icon: <ApplicationIcon />,
    perm: permissionsMap.applicationView
  },
  {
    title: 'Logs',
    link: routes.telemetry,
    icon: <LogsIcon />,
    perm: [permissionsMap.telemetryView, permissionsMap.loggedView],
    operator: 'OR',
    children: [
      {
        title: 'Telemetry',
        link: routes.telemetry,
        perm: permissionsMap.telemetryView
      },
      {
        title: 'System Events',
        link: routes.loggedEvents,
        perm: permissionsMap.loggedView
      }
    ]
  },
  {
    title: 'Customers',
    link: routes.users.list,
    icon: <ApplicationUsersIcon />,
    perm: permissionsMap.customerView
  },
  {
    title: 'User Loans',
    link: routes.userLoans,
    icon: <UserLoansIcon />,
    perm: permissionsMap.userLoansView
  },

  {
    title: 'Payments',
    link: routes.payments.list,
    icon: <PaymentsIcon />,
    perm: [
      permissionsMap.paymentUserView,
      permissionsMap.debitCardManage,
      permissionsMap.paymentsRerunView,
      permissionsMap.paymentsRerunManage,
      permissionsMap.scheduledPaymentsView,
      permissionsMap.scheduledPaymentsManage
    ],
    operator: 'OR',
    children: [
      {
        title: 'All payments',
        link: routes.payments.list,
        perm: permissionsMap.paymentUserView
      },
      {
        title: 'Add DC information',
        link: routes.payments.debitCard,
        perm: permissionsMap.debitCardManage
      },
      {
        title: 'Decline reasons settings',
        link: routes.payments.declineReasonSettings,
        perm: [
          permissionsMap.declineReasonsView,
          permissionsMap.declineReasonsManage
        ],
        operator: 'OR'
      },
      {
        title: 'Past Due Payments',
        link: routes.payments.pastDuePayments.fileList,
        path: routes.payments.pastDuePayments.path,
        perm: permissionsMap.paymentUserView
      },
      {
        title: 'Payments Rerun',
        link: routes.payments.rerun.performance,
        path: routes.payments.rerun.path,
        perm: [
          permissionsMap.paymentsRerunView,
          permissionsMap.paymentsRerunManage
        ],
        operator: 'OR'
      },
      {
        title: 'Scheduled Payments',
        link: routes.payments.scheduledPayments.performance,
        path: routes.payments.scheduledPayments.path,
        perm: [
          permissionsMap.scheduledPaymentsView,
          permissionsMap.scheduledPaymentsManage
        ],
        operator: 'OR'
      }
    ]
  },
  {
    title: 'Notifications',
    link: routes.notifications.systemNotifications.list,
    icon: <NotificationsIcon />,
    perm: permissionsMap.notificationJobView,
    children: [
      {
        title: 'System Notifications',
        link: routes.notifications.systemNotifications.list,
        perm: permissionsMap.notificationJobView
      },
      {
        title: 'Custom Notifications',
        link: routes.notifications.customNotifications.list,
        perm: permissionsMap.notificationJobView
      }
    ]
  },
  {
    title: 'OS and app versions',
    link: routes.osAndAppVersions.userAgents,
    icon: <VersionsIcon />,
    perm: [permissionsMap.appVersionView, permissionsMap.userAgentsView],
    operator: 'OR',
    children: [
      {
        title: 'Devices and OS',
        link: routes.osAndAppVersions.userAgents,
        perm: permissionsMap.userAgentsView
      },
      {
        title: 'Mobile App Versions',
        link: routes.osAndAppVersions.mobileAppVersions.list,
        perm: permissionsMap.appVersionView
      }
    ]
  },
  {
    title: 'Lead Management',
    link: routes.leads.leadProvidersRates,
    icon: <LeadIcon />,
    perm: [
      permissionsMap.leadRatesView,
      permissionsMap.leadReportsView,
      permissionsMap.leadProvidersView,
      permissionsMap.adminLeadProviderDataView,
      permissionsMap.leadGroupsView
    ],
    operator: 'OR',
    children: [
      {
        title: 'Lead Reports',
        link: routes.leads.reports.affiliateReport,
        perm: permissionsMap.leadReportsView,
        children: [
          {
            title: 'Affiliate Report',
            link: routes.leads.reports.affiliateReport,
            perm: permissionsMap.leadReportsView
          },
          {
            title: 'Lead Performance',
            link: routes.leads.reports.leadProviderPerformance,
            perm: permissionsMap.leadReportsView
          },
          {
            title: 'Stack Performance',
            link: routes.leads.reports.stackPerformance,
            perm: permissionsMap.leadReportsView
          },
          {
            title: 'Denial Reasons Report',
            link: routes.leads.reports.denialReasonsReport,
            perm: permissionsMap.leadReportsView
          }
        ]
      },
      {
        title: 'Lead Providers Dashboard',
        link: routes.leads.leadProvidersRates,
        perm: permissionsMap.leadRatesView
      },
      {
        title: 'Lead Providers',
        link: routes.leads.leadProviders.list,
        perm: permissionsMap.leadProvidersView
      },
      {
        title: 'Lead Providers Reports',
        link: routes.leads.leadProvidersReports.path,
        perm: permissionsMap.adminLeadProviderDataView
      },
      {
        title: 'Campaign Groups',
        link: routes.leads.campaignGroups.list,
        perm: permissionsMap.leadGroupsView
      }
    ]
  },
  {
    title: 'CSV Compare',
    link: routes.csvCompare.path,
    icon: <CompareIcon />,
    perm: [permissionsMap.CSVCompareManage, permissionsMap.CSVCompareView],
    operator: 'OR'
  },
  {
    title: 'BIN/ABA Database',
    link: routes.binChecker.path,
    icon: <BinCheckerIcon />,
    perm: [permissionsMap.binView, permissionsMap.routingView],
    operator: 'OR',
    children: [
      {
        title: 'BIN Numbers',
        link: routes.binChecker.binNumberList,
        perm: permissionsMap.binView
      },
      {
        title: 'ABA Numbers',
        link: routes.binChecker.abaNumberList,
        perm: permissionsMap.routingView
      }
    ]
  },
  {
    title: 'Wallboard',
    link: routes.wallboard.loanOriginatorsDaily,
    icon: <WallboardIcon />,
    perm: [permissionsMap.wallboardManage],
    operator: 'OR',
    children: [
      {
        title: 'Loan Originators Daily',
        link: getWallboardURL(routes.wallboard.loanOriginatorsDaily),
        perm: permissionsMap.wallboardManage,
        linkTarget: 'blank'
      },
      {
        title: 'Loan Originators Weekly',
        link: getWallboardURL(routes.wallboard.loanOriginatorsWeekly),
        perm: permissionsMap.wallboardManage,
        linkTarget: 'blank'
      },
      {
        title: 'Loan Originators Monthly',
        link: getWallboardURL(routes.wallboard.loanOriginatorsMonthly),
        perm: permissionsMap.wallboardManage,
        linkTarget: 'blank'
      },
      {
        title: 'Collection',
        link: getWallboardURL(routes.wallboard.collection),
        perm: permissionsMap.wallboardManage,
        linkTarget: 'blank'
      },
      {
        title: 'Loan Processing',
        link: getWallboardURL(routes.wallboard.loanProcessing),
        perm: permissionsMap.wallboardManage,
        linkTarget: 'blank'
      },
      {
        title: 'Team Goals',
        link: getWallboardURL(routes.wallboard.teamGoals),
        perm: permissionsMap.wallboardManage,
        linkTarget: 'blank'
      }
    ]
  }
];

export const defaultPageSizeOptions = [10, 25, 50, 100];
export const defaultPageSize = defaultPageSizeOptions[1];

export const dateFormat = 'MM/DD/YYYY';
export const serverDateFormat = 'YYYY-MM-DD';
export const serverDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
export const dateTimeFormat = 'MM/DD/YYYY hh:mm:ss A';
export const dateShortTimeFormat = 'MM/DD/YYYY hh:mm A';
export const filterTimeFormat = 'YYYY-MM-DD HH:mm';
export const dateHoursFormat = 'MM/DD/YYYY hh A';
export const timeFormat = 'hh:mm:ss A';
export const timeOnlyHoursFormat = 'hh A';
export const shortTimeFormat = {
  front: 'hh:mm A',
  server: 'HH:mm'
};
export const timeFormat24h = 'HH:mm:ss';
export const minutesFormat = 'mm:ss.SSS';
export const expDateFormat = 'MM/YY';

export const fieldsMasks = {
  phoneUSA: {
    mask: '999 999 9999',
    placeholder: 'XXX XXX XXXX'
  },
  ssn: {
    mask: '999-99-9999',
    placeholder: 'XXX-XX-XXXX'
  },
  accessCode: {
    mask: '99 99 99',
    placeholder: '** ** **'
  },
  cardNumber: {
    mask: '9999-9999-9999-9999',
    placeholder: 'XXXX-XXXX-XXXX-XXXX'
  },
  cardExpire: {
    mask: '99/99',
    placeholder: expDateFormat
  }
};

export const loanResultKeys = {
  new: 'N',
  rejected: 'R',
  accepted: 'A',
  signed: 'S'
};

export const formStepPulseMap = {
  Banking: 'Bank details',
  Customer: 'General',
  Disclosures: 'Disclosure',
  Employee: 'Employment',
  Address: 'Address'
};

export const routesPermissionsMap = {
  [routes.flatPages.list]: {
    perm: permissionsMap.flatView
  },
  [routes.applications.list]: {
    perm: permissionsMap.applicationView
  },
  [routes.telemetry]: {
    perm: permissionsMap.telemetryView
  },
  [routes.dashboard.applicationErrors.list]: {
    perm: permissionsMap.fieldsErrorRateView
  },
  [routes.dashboard.applicationSpentTime]: {
    perm: permissionsMap.applicationTimeView
  },
  [routes.dashboard.apiAppErrors]: {
    perm: permissionsMap.errorResponseRateView
  },
  [routes.dashboard.internalPerformance.web]: {
    perm: permissionsMap.internalPerformanceView
  },
  [routes.dashboard.leadStats]: {
    perm: permissionsMap.applicationRatesView
  },
  [routes.dashboard.ibv.trafficReport]: {
    perm: permissionsMap.ibvResultView
  },
  [routes.dashboard.ibv.scoringReport.list]: {
    perm: permissionsMap.ibvResultView
  },
  [routes.users.list]: {
    perm: permissionsMap.customerView
  },
  [routes.loggedEvents]: {
    perm: permissionsMap.loggedView
  },
  [routes.blockedIPAddresses]: {
    perm: permissionsMap.loggedView
  },
  [routes.notifications.customNotifications.list]: {
    perm: permissionsMap.notificationJobView
  },
  [routes.notifications.systemNotifications.list]: {
    perm: permissionsMap.notificationJobView
  },
  [routes.leads.leadProviders.list]: {
    perm: permissionsMap.leadProvidersView
  },
  [routes.leads.reports.affiliateReport]: {
    perm: permissionsMap.leadReportsView
  },
  [routes.leads.reports.leadProviderPerformance]: {
    perm: permissionsMap.leadReportsView
  },
  [routes.leads.reports.denialReasonsReport]: {
    perm: permissionsMap.leadReportsView
  },
  [routes.leads.campaignGroups.list]: {
    perm: permissionsMap.leadGroupsView
  },
  [routes.leads.leadProvidersRates]: {
    perm: permissionsMap.leadRatesView
  },
  [routes.leads.leadProvidersReports.path]: {
    perm: permissionsMap.adminLeadProviderDataView
  },
  [routes.leads.leadProvidersReports.campaigns]: {
    perm: permissionsMap.adminLeadProviderDataView
  },
  [routes.userLoans]: {
    perm: permissionsMap.userLoansView
  },
  [routes.osAndAppVersions.userAgents]: {
    perm: permissionsMap.userAgentsView
  },
  [routes.osAndAppVersions.mobileAppVersions.list]: {
    perm: permissionsMap.appVersionView
  },
  [routes.promoCodes.list]: {
    perm: permissionsMap.promoCodesView
  },
  [routes.configuration.controlPanel]: {
    perm: permissionsMap.managersView
  },
  [routes.notifications.path]: {
    perm: permissionsMap.notificationJobView
  },
  [routes.osAndAppVersions.path]: {
    perm: [permissionsMap.userAgentsView, permissionsMap.appVersionView],
    operator: 'OR'
  },
  [routes.leads.path]: {
    perm: [
      permissionsMap.leadProvidersView,
      permissionsMap.leadGroupsView,
      permissionsMap.leadRatesView
    ],
    operator: 'OR'
  },
  [routes.payments.list]: {
    perm: permissionsMap.paymentUserView
  },
  [routes.logs.path]: {
    perm: [permissionsMap.telemetryView, permissionsMap.loggedView],
    operator: 'OR'
  },
  [routes.debitCard]: {
    perm: permissionsMap.debitCardManage
  },
  [routes.configuration.debitCard]: {
    perm: permissionsMap.debitCardManage
  },
  [routes.payments.rerun.path]: {
    perm: [
      permissionsMap.paymentsRerunView,
      permissionsMap.paymentsRerunManage
    ],
    operator: 'OR'
  },
  [routes.csvCompare.path]: {
    perm: [permissionsMap.CSVCompareManage, permissionsMap.CSVCompareView],
    operator: 'OR'
  },
  [routes.leadProvider.leadRequests]: {
    perm: permissionsMap.leadProviderUsersLeadReports
  },
  [routes.leadProvider.campaignPerformance]: {
    perm: permissionsMap.leadProviderUsersLeadPerformance
  },
  [routes.configuration.globalSettings]: {
    perm: [permissionsMap.viewSettings, permissionsMap.manageSettings],
    operator: 'OR'
  },
  [routes.dashboard.discrepancyReport]: {
    perm: permissionsMap.discrepancyReportView
  },
  [routes.dashboard.abandonedApplications]: {
    perm: permissionsMap.abandonStepStatisticsView
  },
  [routes.configuration.maintenanceMode]: {
    perm: permissionsMap.maintananceModeView
  },
  [routes.configuration.teamGoals]: {
    perm: permissionsMap.wallboardManage
  },
  [routes.payments.scheduledPayments.path]: {
    perm: [
      permissionsMap.scheduledPaymentsView,
      permissionsMap.scheduledPaymentsManage
    ],
    operator: 'OR'
  },
  [routes.binChecker.path]: {
    perm: [permissionsMap.binView, permissionsMap.routingView],
    operator: 'OR'
  }
};

export const loanTypeMap = {
  installment: 'Installment',
  lineOfCredit: 'LineOfCredit',
  default: 'Default',
  advance: 'Advance'
};

export const loanTypeFilterMap = [
  { label: 'Default', value: loanTypeMap.default },
  { label: 'Installment', value: loanTypeMap.installment },
  { label: 'Line Of Credit', value: loanTypeMap.lineOfCredit }
];

export const loanTypeOptions = [
  {
    label: 'All',
    value: ''
  },
  ...loanTypeFilterMap.map(({ text, value }) => ({ label: text, value }))
];

export const noFieldData = '-';
export const defaultTimeValue = '0s';

export const botDashboardUri = process.env.BOT_DASHBOARD;

export const systemServicesStatuses = {
  active: 'Active',
  failed: 'Failed'
};

export const pastDuePaymentsThresholdYears = 100;

const getApplyFilterOption = (filterItem) => {
  const { value } = filterItem;
  return (params) => params?.value === value || !value;
};

export const filterOperators = {
  fullName: {
    label: 'contains',
    value: 'fullName',
    InputComponent: FullNameFilter
  },
  dateRange: {
    label: 'Date Range',
    value: 'dateRange',
    getApplyFilterFn: filterHelpers.getApplyFilterFn,
    InputComponent: DateRangeInTwoInputs
  },
  singleSelect: ({ filterField, data, options }) => ({
    label: 'is',
    value: 'is',
    getApplyFilterFn: getApplyFilterOption,
    InputComponent: (props) => (
      <FilterByOptions
        {...props}
        filterField={filterField}
        data={data}
        options={options}
      />
    )
  }),
  equals: (InputComponent) => ({
    label: 'equals',
    value: 'eq',
    getApplyFilterFn: getApplyFilterOption,
    InputComponent
  }),
  getMuiOperator: (userOperator) =>
    getGridStringOperators().filter(
      (operator) => operator.value === userOperator
    )
};

export const redirectKey = 'redirectTo';

export const types = {
  function: 'function',
  boolean: 'boolean',
  numeric: 'numeric'
};

export const leadProviderIDNoteText =
  'This role is dedicated for lead provider users. By adding policies you may expose internal components of the system to 3rd parties';

export const leadProviderIDNote = {
  title: 'Note',
  content: (
    <p style={{ color: 'red', margin: 0, minWidth: 500 }}>
      {leadProviderIDNoteText}
    </p>
  )
};

export const ELOAN_ADMIN_KEY = 'ElwAdmin';
export const renderCommonCell = ({ row, field }) => row[field] || noFieldData;
