import React from 'react';
import PT from 'prop-types';

import Typography from '@components/shared/Typography/Typography';

const FormError = ({ error, centered, bold, hideEmpty }) => {
  if (hideEmpty && !error) return null;

  return (
    <Typography
      color="error"
      textAlign={centered ? 'center' : 'left'}
      fontWeight={bold ? 'bold' : 'normal'}
    >
      {error && error}
      &nbsp;
    </Typography>
  );
};

FormError.defaultProps = {
  className: '',
  hideEmpty: false
};

FormError.propTypes = {
  centered: PT.bool,
  bold: PT.bool,
  hideEmpty: PT.bool,
  error: PT.any,
  className: PT.string
};

export default FormError;
