import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import qs from 'query-string';

import { columns } from './config';

import {
  useQueryPagination,
  defaultFilterParams
} from '@hooks/useQueryPagination';
import { useQueryParams } from '@hooks/useQueryParams';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import TelemetryFilter from '@components/ApplicationsTelemetry/Filter';
import TelemetryDataTable from '@components/TelemetryDataTable';
import DataTable from '@components/shared/DataTable/DataTable';
import { exportTypes } from '@components/shared/ExportTableCSV';
import Box from '@components/shared/Box/Box';
import { ActionsSection } from '@components/shared/MainLayout/MainLayout.components';
import { adminApiBaseUrl, adminApiUrls } from '@constants/adminApiUrls';
import AdminApi from '@utils/adminApi';
import { getFieldsForExportCSV } from '@components/shared/DataTable/DataTable.utils';

const TelemetryPage = () => {
  const history = useHistory();
  const params = useQueryParams();
  const { list, isLoading, count } = useQueryPagination({
    listUrl: adminApiUrls.applicationsTelemetry,
    initialFilters: {
      ...defaultFilterParams,
      startDate: dayjs().startOf('week').startOf('day').toISOString(),
      endDate: dayjs().endOf('week').endOf('day').toISOString(),
      orderBy: 'applicationId desc'
    },
    customApi: AdminApi
  });

  const tableColumns = useMemo(() => columns.filter((item) => !item.hide), []);

  const setParams = (queryParams) => {
    history.push({
      search: qs.stringify(queryParams, {
        skipNull: true,
        skipEmptyString: true
      })
    });
  };

  const handleSortingChange = (sortData) => {
    const orderBy = sortData.reduce(
      (acc, { field, sort }) => `${field} ${sort}`,
      ''
    );

    if (orderBy) {
      setParams({
        ...params,
        ...qs.parse(history.location.search),
        orderBy: `${orderBy}`
      });
    } else {
      setParams({
        ...params,
        ...qs.parse(history.location.search),
        orderBy: 'applicationId desc'
      });
    }
  };

  const handleTableChange = (pagination, sortData) => {
    if (sortData) {
      handleSortingChange(sortData);
      return;
    }

    setParams({
      ...params,
      ...qs.parse(history.location.search),
      take: pagination.pageSize,
      offset: pagination.pageSize * (pagination.current - 1)
    });
  };

  const csvFields = useMemo(
    () =>
      getFieldsForExportCSV(
        tableColumns,
        null,
        'application.customer.lastName'
      ),
    [tableColumns]
  );

  return (
    <MainLayout title="Applications telemetry">
      <ActionsSection>
        <TelemetryFilter />
      </ActionsSection>
      <DataTable
        disableColumnFilter
        columns={tableColumns}
        loading={isLoading}
        tableData={list}
        getDetailPanelContent={({ row }) => (
          <Box mx={2}>
            <TelemetryDataTable data={row.pulses} rowId={row.applicationId} />
          </Box>
        )}
        rowKey="applicationId"
        onSortingChange={(sortData) => handleTableChange(null, sortData)}
        setOrder={handleTableChange}
        totalAmount={count}
        paginationOptions={{
          pageSize: +params.take,
          current: params.offset
            ? Math.ceil(params.offset / params.take) + 1
            : 1
        }}
        exportCsvProps={{
          apiUrl: adminApiUrls.applicationsTelemetry + history.location.search,
          fields: csvFields,
          fromServer: true,
          type: exportTypes.json,
          root: 'items',
          customApiBaseUrl: adminApiBaseUrl
        }}
      />
    </MainLayout>
  );
};

export default TelemetryPage;
