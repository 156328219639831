import React from 'react';
import { generatePath } from 'react-router-dom';

import Box from '@components/shared/Box/Box';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import { timeDateColumnWidth } from '@components/shared/DataTable/DataTable.constants';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import {
  filterOperators,
  noFieldData,
  permissionsMap
} from '@constants/common';
import routes from '@constants/routes';
import helpers from '@utils/helpers';
import toastify from '@utils/toastify';
import { cleanObject } from '@utils/object-cleaner';
import DeleteActionButton from '@components/shared/DataTable/actions/DeleteActionButton';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

export const initialFilterParams = cleanObject({
  displayName: {
    contains: ''
  },
  description: {
    contains: ''
  }
});

const handleDeletePromoCode = async (
  promoCodeId,
  updatePaginationAfterDelete
) => {
  try {
    await AdminApi.delete(adminApiUrls.promoCodes.page(promoCodeId));

    updatePaginationAfterDelete(
      [promoCodeId],
      (record, arrayOfDeletableIds) =>
        !arrayOfDeletableIds.includes(record.promoCodeId)
    );

    toastify.success({
      message: 'Promo code deleted'
    });
  } catch (e) {
    toastify.error({
      message: 'Delete operation failure'
    });
  }
};

export const getColumns = ({ updatePaginationAfterDelete }) => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <EditActionButton
          path={() =>
            generatePath(routes.promoCodes.edit, {
              id: row.promoCodeId
            })
          }
          hidden={!helpers.getActionPermission(permissionsMap.promoCodesManage)}
        />
        <DeleteActionButton
          hidden={!helpers.getActionPermission(permissionsMap.promoCodesManage)}
          itemId={row.promoCodeId}
          onConfirm={() =>
            handleDeletePromoCode(row.promoCodeId, updatePaginationAfterDelete)
          }
        />
      </Box>
    )
  },
  {
    headerName: 'Promo code ID',
    field: 'promoCodeId',
    width: 120,
    renderCell: ({ row }) => row.promoCodeId,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Name',
    field: 'displayName',
    minWidth: 150,
    flex: 1
  },
  {
    headerName: 'Description',
    field: 'description',
    minWidth: 150,
    flex: 1
  },
  {
    headerName: 'Start date',
    field: 'startDate',
    minWidth: timeDateColumnWidth,
    flex: 1,
    valueFormatter: (startDate) => helpers.getFormattedDate(startDate),
    renderCell: ({ row }) =>
      row?.startDate ? helpers.getFormattedDate(row.startDate) : noFieldData,
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Expiration date',
    field: 'expirationDate',
    minWidth: timeDateColumnWidth,
    flex: 1,
    valueFormatter: (expirationDate) =>
      helpers.getFormattedDate(expirationDate),
    renderCell: ({ row }) =>
      row?.expirationDate
        ? helpers.getFormattedDate(row.expirationDate)
        : noFieldData,
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  }
];
