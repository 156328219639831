import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';
import dayjs from 'dayjs';

import { chartColors as colors } from '@constants/colors';
import Logger from '@utils/logger';
import helpers from '@utils/helpers';
import { dateFormat } from '@constants/common';
import toastify from '@utils/toastify';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const mainLabels = {
  clientErrors: '4xx',
  serverErrors: '5xx'
};
const DEFINED_ERROR_GRAPH_TYPE = 4;
const AMOUNT_VISIBLE_ERRORS = 10;
const defaultColor = '#ffffff';
const defaultStateTopErrors = {
  [mainLabels.clientErrors]: {},
  [mainLabels.serverErrors]: {}
};
const getTopErrors = (errors, dataIndex, amount) =>
  errors
    .sort((a, b) => b.data[dataIndex].y - a.data[dataIndex].y)
    .slice(0, amount)
    .map((item) => `${item.label}: ${item.data[dataIndex].y}`)
    .join('\n');

export const getSelectedChartData = (fullChartData, selectedKeys) =>
  fullChartData.filter((_, index) => selectedKeys?.includes(index));

const getDefaultSelectedKeys = (chartData) => {
  if (!chartData) {
    return null;
  }
  return chartData.reduce((acc, { label }, index) => {
    if (Object.values(mainLabels).includes(label)) {
      return [...acc, index];
    }
    return acc;
  }, []);
};

const useChartData = () => {
  const [chartData, setChartData] = useState(null);
  const [maxYValue, setMaxYValue] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [error, setError] = useState(null);
  const topErrorsRef = useRef(defaultStateTopErrors);
  const history = useHistory();

  const monthFilterInitialValues = {
    startDate: dayjs().subtract(1, 'month').toISOString(),
    endDate: dayjs().toISOString()
  };

  const selectOptions = useMemo(() => {
    if (!chartData) {
      return null;
    }
    return chartData.map(({ label }, index) => ({
      label,
      value: index
    }));
  }, [chartData]);

  useEffect(() => {
    (async () => {
      try {
        if (!history.location?.search) {
          return history.replace({
            search: qs.stringify(monthFilterInitialValues)
          });
        }
        setChartData(null);
        const parsedParams = qs.parse(history.location.search, {
          parseBooleans: true,
          parseNumbers: true
        });
        const response = await AdminApi.get(adminApiUrls.dashboard.log, {
          params: parsedParams
        });
        const chartData = response
          .sort((a, b) => a.graphType - b.graphType)
          .map(({ errorCode, graphType, ...item }, index) => ({
            label: item.legend,
            data: item.timePoints.map((el) => ({
              x: helpers.getFormattedDate(el.logDateTime, dateFormat),
              y: el.errorNumber
            })),
            borderColor: colors[index] || defaultColor,
            backgroundColor: colors[index] || defaultColor,
            errorCode,
            graphType
          }));
        setChartData(chartData);
        setSelectedKeys(getDefaultSelectedKeys(chartData));
      } catch (e) {
        Logger.error(e);
        setError(e);
      }
    })();
  }, [history.location?.search]);

  useEffect(() => {
    setChartData((prevChartData) => {
      if (!prevChartData) {
        return null;
      }
      const selectedChartData = getSelectedChartData(
        prevChartData,
        selectedKeys
      );

      const yMax = Math.max(
        selectedChartData
          .reduce((acc, item) => [...acc, ...item.data], [])
          .map((item) => item.y)
      );
      setMaxYValue(yMax + 10);

      return prevChartData.map((item, index) => {
        if (selectedKeys?.includes(index)) {
          const colorKey = selectedKeys.indexOf(index);
          return {
            ...item,
            borderColor: colors[colorKey],
            backgroundColor: colors[colorKey]
          };
        }
        return item;
      });
    });
  }, [selectedKeys]);

  const selectHandler = useCallback(
    (e) => {
      if (!Number.isInteger(selectedKeys?.length)) {
        return;
      }
      const newValue = e.target.value;
      if (newValue.length <= 10) {
        setSelectedKeys(newValue);
      } else {
        toastify.error({
          message: 'Forbidden to select more then 10 graph labels'
        });
      }
    },
    [selectedKeys?.length]
  );

  const handleLabelShow = useCallback((e, index) => {
    setChartData((prevChartData) => {
      const updData = [...prevChartData];
      updData[index].hidden = !e.target.checked;
      return updData;
    });
  }, []);

  const getExtendedTooltip = useCallback(
    (tooltipItems) => {
      const globalErrors = tooltipItems.filter((item) =>
        Object.values(mainLabels).includes(item.dataset.label)
      );
      const { current: topErrors } = topErrorsRef;
      return globalErrors
        .map((globalError) => {
          const dataIndex = globalError.dataIndex;
          const label = globalError.dataset.label;
          if (topErrors[label] && topErrors[label][dataIndex] != null) {
            return topErrors[label][dataIndex];
          }
          switch (label) {
            case mainLabels.clientErrors: {
              const top = getTopErrors(
                chartData.filter(
                  (item) =>
                    item.graphType === DEFINED_ERROR_GRAPH_TYPE &&
                    item.errorCode >= 400 &&
                    item.errorCode < 500 &&
                    item.data[dataIndex].y
                ),
                dataIndex,
                AMOUNT_VISIBLE_ERRORS
              );
              topErrors[label][dataIndex] = top.length
                ? `Top 4xx errors:\n${top}`
                : top;
              return top;
            }
            case mainLabels.serverErrors: {
              const top = getTopErrors(
                chartData.filter(
                  (item) =>
                    item.graphType === DEFINED_ERROR_GRAPH_TYPE &&
                    item.errorCode >= 500 &&
                    item.data[dataIndex].y
                ),
                dataIndex,
                AMOUNT_VISIBLE_ERRORS
              );
              topErrors[label][dataIndex] = top.length
                ? `Top 5xx errors:\n${top}`
                : top;
              return top;
            }
          }
        })
        .filter(Boolean)
        .join('\n\n');
    },
    [chartData]
  );

  const reset = () => {
    setSelectedKeys([0, 1]);
  };

  return {
    chartData,
    selectHandler,
    getExtendedTooltip,
    selectOptions,
    error,
    maxYValue,
    selectedKeys,
    handleLabelShow,
    reset
  };
};

export default useChartData;
