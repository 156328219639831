import React, { useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {
  downloadFile,
  getDynamicColumns,
  useGetCsvComparisonsResult
} from './utils';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';
import DataTable from '@components/shared/DataTable/DataTable';
import { csvComparisonApiUrls } from '@constants/csvComparisonApi';
import { comparisonMap } from '@constants/comparison';
import routes from '@constants/routes';
import Loader from '@components/shared/Loader';
import { useQueryParams } from '@hooks/useQueryParams';

const CsvComparisonDetailsPage = () => {
  const { id, key } = useParams();
  const history = useHistory();
  const { index } = useQueryParams();
  const { data, navItems, comparisonId } = useGetCsvComparisonsResult(
    id,
    index
  );

  const handleDownload = () => {
    const url =
      key === comparisonMap.mismatches
        ? csvComparisonApiUrls.comparisonfiles.results.consolidated(
            comparisonId
          )
        : csvComparisonApiUrls.comparisonfiles.results.details(
            data.comparisonFileId
          );

    downloadFile(url);
  };

  useEffect(() => {
    if (navItems && navItems?.length < index) {
      history.replace(routes.csvCompare.details.url(id, key));
    }
  }, [history, navItems, index, id, key]);

  const columns = useMemo(() => getDynamicColumns(data?.records?.[0]), [data]);

  const title = `${navItems?.[0]?.title || ''} vs ${
    navItems?.[1]?.title || ''
  }`;

  return (
    <MainLayout title={title}>
      {!data && <Loader />}
      {data && navItems?.length && (
        <>
          <NavTabsPanel
            currentId={Number(index) || 0}
            tabsList={navItems}
            ariaLabel="access-tabs"
          />
          <Stack flexDirection="row" justifyContent="flex-end" marginTop={1}>
            <Button onClick={handleDownload} disabled={!data?.records?.length}>
              Download
            </Button>
          </Stack>
          <DataTable
            loading={!data}
            columns={columns}
            tableData={data?.records || []}
            disableColumnMenu
            disableColumnFilter
            rowKey="row_id"
          />
        </>
      )}
    </MainLayout>
  );
};
export default CsvComparisonDetailsPage;
