import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import PT from 'prop-types';

import { getColumns } from './config';

import { leadDayHourFilterInitialValues } from '@components/Leads/DayHourFilterSeparated';
import { excludeExportFields } from '@constants/leads';
import {
  useQueryPagination,
  defaultFilterParams
} from '@hooks/useQueryPagination';
import { useQueryParams } from '@hooks/useQueryParams';
import ExpandShrinkButton from '@components/shared/ExpandShrinkButton/ExpandShrinkButton';
import ExportTableCSV, { exportTypes } from '@components/shared/ExportTableCSV';
import LeadRatesTable from '@components/Leads/LeadRatesTable';
import { noFieldData } from '@constants/common';
import Typography from '@components/shared/Typography/Typography';
import Stack from '@components/shared/Stack/Stack';
import toastify from '@utils/toastify';
import ShowCapsButton from '@components/shared/ShowCapsButton/ShowCapsButton';
import {
  leadManagementApiBaseUrl,
  leadManagementApiUrls
} from '@constants/leadManagementApi';
import LeadManagementApi from '@utils/leadManagementApi';

const LeadProvidersTable = ({
  showActive,
  expandAllTables,
  setExpandAllTables,
  handleTableChange,
  updateKey
}) => {
  const [isShownCaps, setIsShownCaps] = useState(true);
  const history = useHistory();
  const params = useQueryParams();
  const [expandedRows, setExpandedRows] = useState([]);
  const expandedList = useMemo(
    () => (expandAllTables ? [] : expandedRows),
    [expandAllTables, expandedRows]
  );

  const { list, isLoading, count, refetchList } = useQueryPagination({
    customApi: LeadManagementApi,
    listUrl: leadManagementApiUrls.dashboard.leadProvidersRates,
    initialFilters: {
      ...defaultFilterParams,
      ...leadDayHourFilterInitialValues,
      isActive: showActive ? 'true' : null
    }
  });

  const handleStatus = async (id, status, showActive) => {
    try {
      await LeadManagementApi.post(
        leadManagementApiUrls.leadProviders.pauseOnLeadProviderSingle(id),
        {
          pause: status
        }
      );

      if (!expandedRows.includes(id)) {
        setExpandedRows([...expandedRows, id]);
      }

      refetchList({
        ...defaultFilterParams,
        ...leadDayHourFilterInitialValues,
        isActive: showActive ? 'true' : null
      });
      updateKey();
    } catch (error) {
      toastify.error({
        message: error.response?.data?.error?.message
      });
    }
  };

  const columns = useMemo(
    () =>
      getColumns({ handleStatus, showActive }).filter((item) =>
        isShownCaps ? item : !item.hide
      ),
    [isShownCaps, showActive]
  );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        mt={3}
        mb={1}
      >
        <Typography variant="h2">Lead provider statistic</Typography>
        <Stack direction="row" gap={1} alignItems="center">
          <ShowCapsButton
            setIsShownCaps={setIsShownCaps}
            isShownCaps={isShownCaps}
          />
          <ExpandShrinkButton
            list={list}
            rowKey="leadProviderId"
            expandedRows={expandedList}
            setExpandedRows={setExpandedRows}
            setExpandAllTables={setExpandAllTables}
          />
          <ExportTableCSV
            customApiBaseUrl={leadManagementApiBaseUrl}
            apiUrl={
              leadManagementApiUrls.dashboard.leadProvidersRates +
              history.location.search
            }
            fields={columns
              .filter(
                ({ field }) => !excludeExportFields.includes(field) && field
              )
              .map(({ field, headerName }, index) => ({
                field,
                fieldName: headerName,
                key: index
              }))}
            type={exportTypes.json}
            root="items"
          />
        </Stack>
      </Stack>
      <LeadRatesTable
        updateKey={updateKey}
        columns={columns}
        tableData={list}
        rowKey="leadProviderId"
        loading={isLoading}
        onSortingChange={(sortData) => handleTableChange(null, sortData)}
        setOrder={handleTableChange}
        refetchList={refetchList}
        totalAmount={count}
        paginationOptions={{
          pageSize: +params.take,
          current: params.offset
            ? Math.ceil(params.offset / params.take) + 1
            : 1
        }}
        expandedByDefault={expandedList}
        handleExpandedRowsChange={(ids) => {
          setExpandAllTables(false);
          setExpandedRows(ids);
        }}
        additionalCampaignColumns={{
          startColumns: [],
          endColumns: [
            {
              headerName: 'Group names',
              field: 'groupNames',
              renderCell: ({ row }) =>
                row.groupNames ? row.groupNames.join(', ') : noFieldData
            }
          ]
        }}
        isShownCaps={isShownCaps}
      />
    </>
  );
};

LeadProvidersTable.propTypes = {
  showActive: PT.bool,
  expandAllTables: PT.bool,
  setExpandAllTables: PT.func,
  handleTableChange: PT.func.isRequired,
  updateKey: PT.bool
};

export default LeadProvidersTable;
