import React from 'react';
import { Grid } from '@mui/material';

import FormikField from '@components/shared/Form/FormikField/FormikField';
import { dateFormat } from '@constants/common';

export const unsetOption = {
  label: 'Unset',
  value: ''
};

export const fieldWidth = { width: '215px !important' };
export const dateTimeFieldWidth = { width: '230px !important' };

export const booleanFilterFieldOptions = [
  unsetOption,
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

export const filterFieldsRenderers = {
  text:
    ({ placeholder, name, type = 'text', colProps = {} }) =>
    () => (
      <Grid item sm={4} {...colProps}>
        <FormikField name={name} label={placeholder} type={type} />
      </Grid>
    ),
  number:
    ({ placeholder, name, colProps = {} }) =>
    () => (
      <Grid item sm={4} {...colProps}>
        <FormikField name={name} label={placeholder} type="number" />
      </Grid>
    ),
  valueRange:
    ({ name, placeholder, type = 'number', colProps = {} }) =>
    () => (
      <>
        {filterFieldsRenderers.text({
          placeholder: placeholder + ' [from]',
          name: name + '.ge',
          type,
          colProps
        })()}
        {filterFieldsRenderers.text({
          placeholder: placeholder + ' [to]',
          name: name + '.le',
          type,
          colProps
        })()}
      </>
    ),
  select:
    ({ options, name, placeholder, colProps = {} }) =>
    () => (
      <Grid item sm={4} {...colProps}>
        <FormikField
          type="select"
          name={name}
          label={placeholder}
          options={options}
          sx={{ width: '215px !important' }}
        />
      </Grid>
    ),
  date:
    ({ name, placeholder, colProps }) =>
    () => (
      <Grid item sm={4} {...colProps}>
        <FormikField
          type="date"
          name={name}
          label={placeholder}
          placeholder={placeholder}
          format={dateFormat}
          formatOnDateStart
          sx={fieldWidth}
        />
      </Grid>
    ),
  dateRange:
    ({ name, placeholder, colProps = {} }) =>
    () => (
      <>
        {filterFieldsRenderers.date({
          placeholder: placeholder + ' [from]',
          name: name + '.ge',
          colProps
        })()}
        {filterFieldsRenderers.date({
          placeholder: placeholder + ' [to]',
          name: name + '.le',
          colProps
        })()}
      </>
    ),
  autoComplete:
    ({ name, placeholder, options, colProps }) =>
    () => (
      <Grid item sm={4} {...colProps}>
        <FormikField
          type="autocomplete"
          name={name}
          label={placeholder}
          options={options}
          sx={{ width: '215px !important' }}
        />
      </Grid>
    )
};
