import React from 'react';

import {
  notificationOSOptions,
  notificationStatusLoanOptions
} from '@components/CustomNotifications/Form/TargetStep/schema';
import { dateFormat } from '@constants/common';
import Grid from '@components/shared/Grid/Grid';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Box from '@components/shared/Box/Box';
import Typography from '@components/shared/Typography/Typography';
import { statesOptions } from '@constants/states';

const fieldWidth = { maxWidth: '215px', width: '100%' };

const BasicFilters = () => (
  <>
    <Grid item sm={6} md={4}>
      <FormikField
        type="text"
        name="firstName"
        label="First name"
        placeholder="Enter first name"
        sx={fieldWidth}
      />
    </Grid>
    <Grid item sm={6} md={4}>
      <FormikField
        type="text"
        name="lastName"
        label="Last name"
        placeholder="Enter last name"
        sx={fieldWidth}
      />
    </Grid>
    <Grid
      item
      sm={6}
      md={4}
      gap="5px"
      sx={{ flexDirection: 'row', display: 'flex' }}
    >
      <FormikField
        type="number"
        name="ageMin"
        label="Age from"
        placeholder="From"
        sx={{ width: '105px !important' }}
      />
      <FormikField
        type="number"
        name="ageMax"
        label="Age to"
        placeholder="To"
        sx={{ width: '105px !important' }}
      />
    </Grid>
    <Grid item sm={6} md={4}>
      <FormikField
        type="autocomplete"
        name="state"
        label="State"
        placeholder="Select state"
        options={statesOptions}
        sx={fieldWidth}
      />
    </Grid>
    <Grid item sm={6} md={4}>
      <FormikField
        type="select"
        name="osUsed"
        label="OS used"
        placeholder="Select OS"
        options={notificationOSOptions}
        sx={fieldWidth}
      />
    </Grid>
    <Grid item sm={6} md={4}>
      <FormikField
        type="date"
        name="lastLogin"
        label="Last login"
        placeholder="Select login date"
        format={dateFormat}
        formatOnDateStart
        sx={fieldWidth}
      />
    </Grid>
    <Grid item sm={6} md={4}>
      <FormikField
        type="select"
        name="statusLoan"
        label="Status of loan"
        placeholder="Select status"
        options={notificationStatusLoanOptions}
        sx={fieldWidth}
      />
    </Grid>
    <Grid item sm={6} md={4}>
      <FormikField
        type="date"
        name="birthDate"
        label="Users birthday"
        placeholder="Select users birthday"
        format={dateFormat}
        disableFuture
        sx={fieldWidth}
      />
    </Grid>
    <Grid item sm={6} md={4}>
      <FormikField
        type="number"
        name="paymentDaysBefore"
        label="Days before payment"
        placeholder="Days before payment"
        sx={fieldWidth}
      />
    </Grid>
    <Grid item sm={6} md={4}>
      <FormikField
        type="number"
        name="paymentsNumberMade"
        label="Number of payments made"
        placeholder="To"
        sx={fieldWidth}
      />
    </Grid>
    <Grid item sm={6} md={4}>
      <FormikField
        type="number"
        name="lastLoginXDays"
        label="Last login more than X days or equal"
        placeholder="X days"
        sx={fieldWidth}
      />
    </Grid>
    <Grid item sm={6} md={4}>
      <FormikField
        type="date"
        name="lastLoginDate"
        label="Last login on X or before"
        placeholder="Select last login date (or before)"
        format={dateFormat}
        sx={fieldWidth}
      />
    </Grid>
    <Grid item sm={6} md={4}>
      <Typography mt={1} variant="body2">
        Days after payment status
      </Typography>
      <Box gap="5px" sx={{ flexDirection: 'row', display: 'flex' }}>
        <FormikField
          type="number"
          name="paymentStatusDaysAfterMin"
          label="From"
          placeholder="From"
          sx={{ width: '105px !important' }}
        />
        <FormikField
          type="number"
          name="paymentStatusDaysAfterMax"
          label="To"
          placeholder="To"
          sx={{ width: '105px !important' }}
        />
      </Box>
    </Grid>
    <Grid item sm={6} md={4}>
      <Typography mt={1} variant="body2">
        % of balance remaining
      </Typography>
      <Box gap="5px" sx={{ flexDirection: 'row', display: 'flex' }}>
        <FormikField
          type="number"
          name="percentBalanceRemainingMin"
          label="From"
          placeholder="From"
          sx={{ width: '105px !important' }}
        />
        <FormikField
          type="number"
          name="percentBalanceRemainingMax"
          label="To"
          placeholder="To"
          sx={{ width: '105px !important' }}
        />
      </Box>
    </Grid>
  </>
);

export default BasicFilters;
