import React from 'react';
import PT from 'prop-types';
import dayjs from 'dayjs';
import { Field } from 'formik';

import { StyledDatePicker, StyledDateTimePicker } from './styled';

import TextField from '@components/shared/TextField/TextField';
import FormControl from '@components/shared/Form/FormControl/FormControl';
import { dateFormat, dateTimeFormat } from '@constants/common';
import FormHelperText from '@components/shared/Form/FormHelperText';
import helpers from '@utils/helpers';

const DateTimeInput = ({ error, views, filterVariant, ...props }) => (
  <FormControl
    fullWidth={props.fullWidth}
    margin="normal"
    error={!!error}
    filterVariant={filterVariant}
  >
    {props.format === dateFormat ? (
      <StyledDatePicker
        {...props}
        size="small"
        label={helpers.getFieldLabelWithAsterisk(props.label, props.required)}
        renderInput={(inputProps) => (
          <TextField
            {...inputProps}
            error={!!props.error}
            helperText={props.error}
            onBlur={props.onBlur}
            size="small"
            views={views}
            variant={filterVariant ? 'standard' : 'outlined'}
          />
        )}
      />
    ) : (
      <StyledDateTimePicker
        {...props}
        size="small"
        views={views}
        timeSteps={{ hours: 1, minutes: 1 }}
        renderInput={(inputProps) => (
          <TextField
            {...inputProps}
            label={props.label}
            error={!!props.error}
            helperText={props.error}
            onBlur={props.onBlur}
            size="small"
            variant={filterVariant ? 'standard' : 'outlined'}
          />
        )}
      />
    )}
    {!!error && <FormHelperText error={!!error}>{error}</FormHelperText>}
  </FormControl>
);

const FormikDateTimeInput = ({
  name,
  preventDefaultHandler,
  format = dateTimeFormat,
  ...rest
}) => (
  <Field name={name}>
    {({ meta, field, form }) => {
      const error = meta.touched ? meta.error : '';
      const onChange = (date) => {
        form.setFieldValue(name, dayjs(date).format(format));
      };
      const onClose = (e) => {
        setTimeout(() => {
          if (e?.target?.value) {
            form.setFieldValue(name, dayjs(e.target.value).format(format));
          }
          form.setFieldTouched(name, true);
        }, 0);
      };

      return (
        <DateTimeInput
          {...field}
          {...rest}
          format={format}
          value={field?.value ? dayjs(field.value) : null}
          error={error}
          onChange={preventDefaultHandler ? rest.onChange : onChange}
          onClose={onClose}
        />
      );
    }}
  </Field>
);

DateTimeInput.defaultProps = {
  views: ['day', 'hours', 'minutes']
};

const propTypes = {
  name: PT.string.isRequired,
  label: PT.string.isRequired,
  value: PT.oneOfType([PT.string, PT.object]),
  onChange: PT.func,
  onBlur: PT.func,
  error: PT.oneOfType([PT.bool, PT.string]),
  helperText: PT.string,
  format: PT.string,
  fullWidth: PT.bool,
  views: PT.arrayOf(PT.string),
  filterVariant: PT.bool,
  preventDefaultHandler: PT.bool,
  required: PT.bool
};
DateTimeInput.propTypes = propTypes;
FormikDateTimeInput.propTypes = propTypes;

export { FormikDateTimeInput };
