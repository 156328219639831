import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useHistory } from 'react-router';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import Logger from '@utils/logger';
import PaymentProcessingApi from '@utils/paymentProcessingApi';
import { paymentProcessingApiUrls } from '@constants/paymentProcessingApiUrls';
import {
  getColumns,
  initialFilterParams,
  tabsIds,
  tabsList
} from '@components/ScheduledPayments/ScheduledPayments.config';

const ScheduledPaymentsPage = () => {
  const [declineReasonsOptions, setDeclineReasonsOptions] = useState([]);
  const [scheduledPaymentsPerformanceId, setScheduledPaymentsPerformanceId] =
    useState('');
  const [signal, setSignal] = useState(null);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location?.state?.scheduledPaymentsPerformanceId) {
      setScheduledPaymentsPerformanceId(
        location.state.scheduledPaymentsPerformanceId
      );
    }
  }, [location]);

  useEffect(() => {
    const controller = new AbortController();
    setSignal(controller.signal);
    if (scheduledPaymentsPerformanceId) {
      history.push({
        pathname: history.location.pathname,
        search: qs.stringify({
          ...qs.parse(history.location.search),
          $filter: JSON.stringify({
            scheduledPaymentsPerformanceId: {
              eq: scheduledPaymentsPerformanceId
            }
          }),
          $skip: 0
        })
      });
    }
    return () => {
      controller.abort();
    };
  }, [scheduledPaymentsPerformanceId]);

  useEffect(() => {
    (async () => {
      try {
        const declineReasons = await PaymentProcessingApi.get(
          paymentProcessingApiUrls.declineReasons.list
        );

        setDeclineReasonsOptions(
          declineReasons?.value?.map((item) => ({
            value: item.declineReasonId,
            label: item.declineReasonName
          }))
        );
      } catch (error) {
        Logger.error(error);
      }
    })();
  }, []);

  const getScheduledPaymentsColumns = useCallback(
    () => getColumns({ declineReasonsOptions }),
    [declineReasonsOptions]
  );

  return (
    <MainLayout title="Payments List">
      <NavTabsPanel
        currentId={tabsIds.list}
        tabsList={tabsList}
        ariaLabel="lead-provider-tabs"
      />
      <ODataPaginatedTable
        signal={signal}
        getColumns={getScheduledPaymentsColumns}
        usePaginatedDataOptions={{
          apiUrl: paymentProcessingApiUrls.scheduledPayments.list,
          initialFilterParams: {
            $orderBy: 'scheduledPaymentId desc',
            $expand: 'DeclineReason',
            $filter: {
              ...initialFilterParams
            }
          }
        }}
        rowKey="scheduledPaymentId"
        customApi={PaymentProcessingApi}
      />
    </MainLayout>
  );
};

export default ScheduledPaymentsPage;
