import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';

import {
  aggregationModel,
  getAggregationFunctions,
  getColumns,
  getColumnsVisibilityModel,
  getPinnedColumns,
  stackReportMainGroupingField
} from './config';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import AggregatedReportsFilterForm from '@components/LeadReports/filters/AggregatedReportsFilterForm/AggregatedReportsFilterForm';
import DataTable from '@components/shared/DataTable/DataTable';
import { useServerData } from '@hooks/useServerData';
import LeadManagementReportsApi from '@utils/leadManagementReportsApi';

const LeadPerformance = (
  endpoint,
  title,
  mainGroupingField,
  stackPerformanceOptions
) => {
  const history = useHistory();
  const params = qs.parse(history.location.search);
  const isStackPerformance = stackReportMainGroupingField === mainGroupingField;

  const { data, isLoading, error, fetchData } = useServerData({
    endpoint: async () => {
      const response = await LeadManagementReportsApi.get(endpoint, {
        params
      });

      return response;
    }
  });

  useEffect(() => {
    if (!isEmpty(params)) {
      fetchData();
    }
  }, [history.location.search]);

  const columnsList = useMemo(
    () => getColumns(data, mainGroupingField, isStackPerformance),
    [data]
  );

  return (
    <MainLayout title={title}>
      <AggregatedReportsFilterForm
        isStackPerformance={isStackPerformance}
        stackPerformanceOptions={stackPerformanceOptions}
      />
      <DataTable
        columns={columnsList}
        tableData={data}
        rowKey="id"
        loading={isLoading}
        error={error}
        pinnedColumnsLeft={getPinnedColumns(
          mainGroupingField,
          isStackPerformance
        )}
        pagination={false}
        initialParams={{
          columns: {
            columnVisibilityModel: getColumnsVisibilityModel(mainGroupingField)
          },
          rowGrouping: {
            model: [mainGroupingField, 'period']
          },
          aggregation: {
            model: aggregationModel
          }
        }}
        aggregationFunctions={getAggregationFunctions(data)}
        getAggregationPosition={(groupNode) =>
          groupNode.childrenExpanded ? 'footer' : 'inline'
        }
        exportCsvProps={{ allColumns: true, fileName: 'Aggregated_Report' }}
        disableRowSelectionOnClick
        hideAggregationInColumnMenu
        hideFooter
      />
    </MainLayout>
  );
};

export default LeadPerformance;
