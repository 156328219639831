import React from 'react';

import UserEventsLink from '@components/shared/UserEventsLink';
import { permissionsMap, loanTypeMap } from '@constants/common';
import helpers from '@utils/helpers';

export const getProfileInfoList = (data) => [
  {
    title: 'First name',
    content: data?.firstName
  },
  {
    title: 'Last name',
    content: data?.lastName
  },
  {
    title: 'Email',
    content: data?.email
  },
  {
    title: 'Email confirmed',
    content: helpers.renderBooleanValue(data?.emailConfirmed)
  },
  {
    title: 'User Agent',
    content: data?.userAgent
  },
  {
    title: 'Validation code status',
    content: helpers.renderBooleanValue(data?.isCodeValidated)
  },
  {
    title: 'Expire code date',
    content: helpers.getFormattedDate(data?.codeExpire)
  },
  {
    title: 'Last login date',
    content: helpers.getFormattedDate(data?.lastLogin)
  },
  {
    title: "Today's sign in attempts",
    important: true,
    content: data?.accessFailedWebsiteTodayCount
  },
  {
    title: 'Phone number',
    content: data?.phoneNumber
  },
  {
    title: 'Phone number confirmed',
    content: helpers.renderBooleanValue(data?.phoneNumberConfirmed)
  },
  {
    title: 'Events',
    content: data?.id &&
      helpers.getActionPermission(permissionsMap.loggedView) && (
        <UserEventsLink executedBy={data?.id} />
      )
  },
  {
    title: 'User name',
    content: data?.userName
  },
  {
    title: 'Customer tier',
    content: helpers.getLoanType(data?.customerTier?.tier)
  },
  {
    title: 'Mobile device',
    content: data?.operationSystem
  },
  {
    title: 'Last time logged in mobile app',
    content: data?.lastLoginDate
      ? helpers.getFormattedDate(data?.lastLoginDate)
      : null
  }
];

export const userDetailsPermissions = {
  application: permissionsMap.applicationView,
  userLoan: permissionsMap.userLoansView,
  notification: permissionsMap.notificationJobView,
  paymentOrders: permissionsMap.paymentUserView,
  withdraws: (data) => data.customerTier?.tier === loanTypeMap.lineOfCredit,
  manageNotifications: permissionsMap.notificationJobView,
  deviceInformation: permissionsMap.userAgentsView,
  ibvUnderwritings: permissionsMap.ibvResultView,
  loginAttempts: permissionsMap.groupView
};
