import React from 'react';
import styled from '@emotion/styled';
import TableContainer from '@mui/material/TableContainer';

import { palette } from '@utils/theme';

export const StyledTableContainer = styled((props) => (
  <TableContainer {...props} />
))(({ maxWidth }) => ({
  maxWidth: maxWidth || '100%',
  borderRadius: '5px',
  '.MuiTableCell-root': {
    padding: '0 5px'
  },
  '.MuiTable-root': {
    borderCollapse: 'unset',
    backgroundColor: palette.white,
    border: `1px solid ${palette.border}`
  },
  '.MuiTableCell-head': {
    fontWeight: '600',
    backgroundColor: palette.tableHeaderBackground,
    paddingTop: '3px',
    paddingBottom: '3px'
  }
}));
