import React from 'react';

import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';
import routes from '@constants/routes';
import { permissionsMap } from '@constants/permissions';
import { cleanObject } from '@utils/object-cleaner';
import helpers from '@utils/helpers';
import Box from '@components/shared/Box/Box';
import {
  customerSiteSrc,
  filterOperators,
  noFieldData
} from '@constants/common';
import UserEventsLink from '@components/shared/UserEventsLink';
import { CheckCircleIcon, LoginIcon } from '@components/shared/icons/Icons';
import ConfirmActionButton from '@components/shared/DataTable/actions/ConfirmActionButton';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import {
  fullNameColumnWidth,
  phoneNumberColumnWidth
} from '@components/shared/DataTable/DataTable.constants';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

export const initialFilterParams = cleanObject({
  firstName: {
    startswith: ''
  },
  lastName: {
    startswith: ''
  },
  phoneNumber: {
    contains: ''
  },
  lastLogin: {
    ge: '',
    le: ''
  },
  email: {
    contains: ''
  },
  userName: {
    contains: ''
  }
});

const handleCustomerLogin = async (id) => {
  const response = await AdminApi.get(adminApiUrls.users.impersonate(id));
  window.location = `${customerSiteSrc}private/dashboard?access_token=${response.access_token}`;
};

export const getColumns = () => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <ConfirmActionButton
          hidden={!helpers.getActionPermission(permissionsMap.usersImpersonate)}
          icon={<LoginIcon />}
          modalProps={{
            itemId: row.notificationJobId,
            content: `Are you sure to login as ${helpers.getDisplayName(
              row.firstName,
              row.lastName
            )}?`,
            onConfirm: () => handleCustomerLogin(row.id)
          }}
        />
        <ShowDetailsActionButton
          path={routes.users.page.view.url(row.id)}
          hidden={!helpers.getActionPermission(permissionsMap.usersView)}
        />
      </Box>
    )
  },
  {
    headerName: 'Full name',
    field: 'firstName',
    width: fullNameColumnWidth,
    renderCell: ({ row }) =>
      helpers.getDisplayName(row.firstName, row.lastName),
    type: muiDataTableTypes.fullName,
    filterOperators: [filterOperators.fullName]
  },
  {
    headerName: 'Email',
    field: 'email',
    width: 300
  },
  {
    headerName: 'Phone number',
    field: 'phoneNumber',
    minWidth: phoneNumberColumnWidth
  },
  {
    headerName: 'Last login',
    field: 'lastLogin',
    width: 200,
    valueFormatter: (lastLogin) => helpers.getFormattedDate(lastLogin),
    renderCell: ({ row }) => helpers.getFormattedDate(row.lastLogin),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Signed',
    field: 'enabled',
    type: muiDataTableTypes.boolean,
    width: 50,
    renderCell: ({ row }) => (row?.enabled ? <CheckCircleIcon /> : '')
  },
  {
    headerName: 'Events',
    field: 'id',
    width: 80,
    renderCell: ({ row }) =>
      helpers.getActionPermission(permissionsMap.loggedView) ? (
        <UserEventsLink executedBy={row?.id}>
          {helpers.cutString(row.id, 5)}
        </UserEventsLink>
      ) : (
        row?.id || noFieldData
      )
  }
];
