import React from 'react';
import PT from 'prop-types';
import qs from 'query-string';
import { Link } from 'react-router-dom';

import routes from '@constants/routes';
import { loggedEventsExtraParams } from '@pages/logs-management/logged-events/config';
import { cleanObject } from '@utils/object-cleaner';
import { baseInitialValues as loggedEventsInitialValuesFilter } from '@components/LoggedEvents/Filter';
import Tooltip from '@components/shared/Tooltip/Tooltip';

const UserEventsLink = ({ children, executedBy }) => (
  <Tooltip title="System events">
    <Link
      to={{
        pathname: routes.loggedEvents,
        search: qs.stringify({
          ...loggedEventsExtraParams,
          $filter: JSON.stringify(
            cleanObject({
              ...loggedEventsInitialValuesFilter,
              executedBy: {
                contains: executedBy
              }
            })
          )
        })
      }}
    >
      {children || executedBy}
    </Link>
  </Tooltip>
);

UserEventsLink.propTypes = {
  children: PT.node,
  executedBy: PT.string
};

export default UserEventsLink;
