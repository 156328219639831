import React, { useState } from 'react';
import PT from 'prop-types';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

import TransferList from '../TransferList/TransferList';

import { useQueryParams } from '@hooks/useQueryParams';
import './SettingsModal.scss';
import Modal from '@components/shared/Modal/Modal';

const FilterSettingsModal = ({
  show,
  onClose,
  filterFields,
  filterFieldsOptions,
  stopFetching,
  setStopFetching
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = filterFieldsOptions.filter(
    (item) =>
      item.title.toLowerCase().includes(searchTerm) &&
      !filterFields.includes(item.key)
  );

  const checkedOption = filterFieldsOptions.filter((item) =>
    filterFields.includes(item.key)
  );

  const unCheckedOptions = filterFieldsOptions.filter(
    (item) => !filterFields.includes(item.key)
  );

  const history = useHistory();

  const queryParams = useQueryParams({ arrayFormat: 'bracket' });

  const handleChange = (options) => {
    const optionsKeys = options.map((item) => item.key);
    !stopFetching && setStopFetching(true);

    history.push({
      pathname: history.location.pathname,
      search: qs.stringify(
        {
          ...queryParams,
          filterFields: optionsKeys.length ? optionsKeys : null
        },
        { arrayFormat: 'bracket', skipNull: true }
      )
    });
  };

  return (
    <Modal
      title="Filters settings"
      open={show}
      onClose={onClose}
      minContentWidth="800px"
      withCloseIcon
    >
      <TransferList
        choicesProps={{
          list: unCheckedOptions,
          title: 'Available filters',
          searchResults: filteredOptions,
          searchTerm,
          setSearchTerm
        }}
        chosenProps={{
          list: checkedOption,
          title: 'Selected filters'
        }}
        onSelectChange={handleChange}
      />
    </Modal>
  );
};

FilterSettingsModal.propTypes = {
  show: PT.bool.isRequired,
  onClose: PT.func.isRequired,
  filterFields: PT.arrayOf(PT.string),
  parsedFilter: PT.object,
  filterFieldsConfig: PT.object,
  filterFieldsOptions: PT.array,
  stopFetching: PT.bool.isRequired,
  setStopFetching: PT.func.isRequired
};

export default FilterSettingsModal;
