import * as Yup from 'yup';

import {
  loanDefaultLimits,
  loanDefaultValue,
  payrollSources
} from '@constants/common';
import validators from '@utils/validators';
import { loanAmountSchema } from '@components/Application/LoanForm/GeneralStep/schema';

export const employmentStepFieldsSchema = Yup.object().shape({
  amount: Yup.number()
    .min(loanDefaultLimits.min)
    .max(loanDefaultLimits.max)
    .label('Loan amount')
    .required(),
  netPayPerPayroll: validators.moneyPositiveFloatField
    .label('Net pay per check')
    .required(),
  employmentType: Yup.string().label('Employment type').required(),
  payFrequency: Yup.string().label('Pay frequency').required(),
  payrollMethod: Yup.string().label('Payroll method').required(),
  payrollSource: Yup.string().label('Payroll source').required(),
  dateOfHire: Yup.date()
    .max(new Date(), ({ label }) => `${label} can't be later today's date`)
    .label('Day of hire')
    .typeError(({ label }) => `${label} is required field`)
    .required(),
  nextPayday: Yup.date()
    .min(new Date(), ({ label }) => `${label} can't be earlier today's date`)
    .label('Next Payday')
    .typeError(({ label }) => `${label} is required field`)
    .required(),
  employerName: Yup.string()
    .label('Employer Name')
    .nullable()
    .when('payrollSource', ([payrollSource]) =>
      [payrollSources.employed.id].includes(+payrollSource)
        ? Yup.string().min(3).max(50).required()
        : Yup.string()
    ),
  employerPhone: Yup.number()
    .label('Employer Phone')
    .nullable()
    .when('payrollSource', ([payrollSource]) =>
      [payrollSources.employed.id].includes(+payrollSource)
        ? Yup.number().required()
        : Yup.number()
    ),
  employerPhoneExtension: Yup.string()
    .matches(/^[0-9]*$/, {
      message: 'Only digits are allowed'
    })
    .max(5)
    .nullable()
    .label('Employer Phone Extension')
});

export const employmentStepSchema =
  employmentStepFieldsSchema.concat(loanAmountSchema);

export const employmentStepInitialValues = {
  amount: loanDefaultValue,
  netPayPerPayroll: '',
  employmentType: '',
  monthlyIncome: '',
  payFrequency: '',
  payrollMethod: '',
  payrollSource: '',
  dateOfHire: '',
  nextPayday: '',
  employerName: '',
  employerPhone: '',
  employerPhoneExtension: ''
};
