import React from 'react';
import PT from 'prop-types';
import { Form, Formik } from 'formik';

import Alert from '@components/shared/Alert/Alert';
import { dateTimeFormat } from '@constants/common';
import {
  getInitialValues,
  promoCodeSchema
} from '@components/PromoCodes/Form/schema';
import Typography from '@components/shared/Typography/Typography';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';
import Box from '@components/shared/Box/Box';
import ConfirmDeleteButton from '@components/shared/ConfirmDeleteButton/ConfirmDeleteButton';

const PromoCodeForm = ({ data, editMode, handleSubmit, handleDelete }) => (
  <>
    <Formik
      initialValues={getInitialValues(data)}
      onSubmit={handleSubmit}
      validationSchema={promoCodeSchema}
      validateOnBlur
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <Typography variant="h3" my={1}>
            {editMode ? 'Edit' : 'Add'} promo code
          </Typography>
          <Box width="100%" maxWidth="500px">
            <FormikField
              type="text"
              name="displayName"
              label="Display name"
              placeholder="Enter display name"
              autoFocus
            />
            <FormikField
              type="textarea"
              name="description"
              label="Description"
              placeholder="Enter description"
              multiline
              minRows={4}
            />
            <FormikField
              type="date"
              name="startDate"
              label="Start date"
              placeholder="Start date"
              format={dateTimeFormat}
            />
            <FormikField
              type="date"
              name="expirationDate"
              label="Expiration date"
              placeholder="Expiration date"
              format={dateTimeFormat}
            />

            {errors.nonFieldErrors && (
              <Alert message={errors.nonFieldErrors} type="error" />
            )}
          </Box>
          <Stack direction="row" gap={1} my={1}>
            <Button
              type="submit"
              disabled={isSubmitting && Object.keys(errors).length > 0}
            >
              {editMode ? 'Edit' : 'Create'}
            </Button>
            {editMode && (
              <ConfirmDeleteButton
                itemName="promo code"
                handleDelete={handleDelete}
              />
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  </>
);

const PromoCode = PT.shape({
  displayName: PT.string,
  description: PT.string,
  startDate: PT.string,
  expirationDate: PT.string
});

PromoCodeForm.defaultProps = {
  editMode: false,
  data: {}
};

PromoCodeForm.propTypes = {
  data: PromoCode,
  editMode: PT.bool,
  handleSubmit: PT.func.isRequired,
  handleDelete: PT.func
};

export default PromoCodeForm;
