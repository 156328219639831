import * as Yup from 'yup';
import dayjs from 'dayjs';

import { dateFormat } from '@constants/common';

export const dashboardFilterFieldsSchema = Yup.object().shape(
  {
    startDate: Yup.date().when(
      'endDate',
      ([endDate], schema) =>
        endDate &&
        schema.max(
          endDate,
          `Start date field must be at earlier than ${dayjs(endDate).format(
            dateFormat
          )}`
        )
    ),
    endDate: Yup.date().when(
      'startDate',
      ([startDate], schema) =>
        startDate &&
        schema.min(
          startDate,
          `End date field must be later than ${dayjs(startDate).format(
            dateFormat
          )}`
        )
    )
  },
  ['startDate', 'endDate']
);
