import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button/Button';

import toastify from '@utils/toastify';
import AdminApi from '@utils/adminApi';

const DownloadButton = ({ endpoint, fileName }) => {
  const handleDownload = async () => {
    try {
      const response = await AdminApi.get(endpoint, {
        headers: {
          'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
      });

      const blob = new Blob([response], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      toastify.success({
        message: 'PDF file downloaded'
      });
    } catch (error) {
      toastify.error({
        message: 'Download operation failure'
      });
    }
  };

  return (
    <Button onClick={handleDownload} variant="outlined">
      Download application
    </Button>
  );
};

export default DownloadButton;

DownloadButton.propTypes = {
  endpoint: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired
};
