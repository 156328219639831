import React from 'react';
import PT from 'prop-types';
import Card from '@mui/material/Card';

import { StyledList, StyledCardHeader } from './styled';
import { intersection, not, union } from './TransferList.utils';

import ListItemButton from '@components/shared/List/ListItemButton';
import { Checkbox } from '@components/shared/Form/Checkbox';
import ListItem from '@components/shared/List/ListItem';
import ListItemIcon from '@components/shared/List/ListItemIcon';
import ListItemText from '@components/shared/List/ListItemText';
import { CloseIcon } from '@components/shared/icons/Icons';
import LinearProgress from '@components/shared/LinearProgress/LinearProgress';
import Pagination from '@components/shared/Pagination/Pagination';
import Input from '@components/shared/Input/Input';
import Divider from '@components/shared/Divider/Divider';
import IconButton from '@components/shared/IconButton/IconButton';

export const SideList = ({
  title,
  list,
  checkedList,
  setCheckedList,
  loading,
  paginationProps,
  searchTerm,
  setSearchTerm,
  choicesSide
}) => {
  const handleToggle = (value) => () => {
    const currentIndex = checkedList.indexOf(value);
    const newCheckedList = [...checkedList];

    if (currentIndex === -1) {
      newCheckedList.push(value);
    } else {
      newCheckedList.splice(currentIndex, 1);
    }
    setCheckedList(newCheckedList);
  };

  const numberOfChecked = (items) => intersection(checkedList, items)?.length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items?.length) {
      setCheckedList(not(checkedList, items));
    } else {
      setCheckedList(union(checkedList, items));
    }
  };

  return (
    <Card sx={{ height: '100%', position: 'relative', overflow: 'unset' }}>
      <StyledCardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(list)}
            checked={
              numberOfChecked(list) === list?.length && list?.length !== 0
            }
            indeterminate={
              numberOfChecked(list) !== list?.length &&
              numberOfChecked(list) !== 0
            }
            disabled={list.length === 0}
            labelStyles={{ marginRight: 0, marginTop: 0, marginBottom: 0 }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(list) || 0}/${list.length || 0} selected`}
      />
      {choicesSide && (
        <Input
          value={searchTerm}
          placeholder="Search here"
          sx={{ mb: 0 }}
          fullWidth
          onChange={(e) => setSearchTerm(e.target.value)}
          endAdornment={
            !!searchTerm?.length && (
              <IconButton
                color="primary"
                icon={<CloseIcon />}
                onClick={() => setSearchTerm('')}
                sx={{ p: 0, height: '20px' }}
              />
            )
          }
        />
      )}
      {choicesSide && loading && (
        <LinearProgress
          wrapperProps={{
            sx: {
              position: 'absolute',
              zIndex: 1
            }
          }}
        />
      )}
      <StyledList dense component="div" role="list">
        {list.map((value) => {
          const labelId = `transfer-list-all-item-${value.key}-label`;
          return (
            <ListItem key={value.campaignId || value.key} disablePadding>
              <ListItemButton onClick={handleToggle(value)} disablePadding>
                <ListItemIcon>
                  <Checkbox
                    checked={checkedList.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={value.name || value.title}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </StyledList>
      {choicesSide && paginationProps && (
        <Card sx={{ overflow: 'unset' }}>
          <Divider />
          <Pagination
            component="div"
            count={paginationProps.total}
            page={paginationProps.current}
            rowsPerPage={paginationProps.pageSize}
            onChange={(_, page) =>
              paginationProps?.onChange(page, paginationProps.pageSize)
            }
            paginationMode="server"
            disabled={loading}
          />
        </Card>
      )}
    </Card>
  );
};
SideList.propTypes = {
  title: PT.string,
  list: PT.array,
  checkedList: PT.array,
  setCheckedList: PT.func,
  loading: PT.bool,
  paginationProps: PT.object,
  searchTerm: PT.string,
  setSearchTerm: PT.func,
  choicesSide: PT.bool
};
