import React, { useState } from 'react';
import ReactJson from 'react-json-view';

import { getFilledColumns, initialFilterParams } from './config';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import ApplicationsFilter from '@components/Applications/Filter';
import { ActionsSection } from '@components/shared/MainLayout/MainLayout.components';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const ApplicationsListPage = () => {
  const [stopFetching, setStopFetching] = useState(false);

  const excludeExportFields = ['userLoan.status', 'appVersion'];

  return (
    <MainLayout title="Applications">
      <ActionsSection>
        <ApplicationsFilter
          stopFetching={stopFetching}
          setStopFetching={setStopFetching}
        />
      </ActionsSection>
      <ODataPaginatedTable
        getColumns={getFilledColumns}
        rowKey="applicationId"
        usePaginatedDataOptions={{
          apiUrl: adminApiUrls.applications.list,
          initialFilterParams: {
            $orderBy: 'applicationId desc',
            $filter: initialFilterParams,
            $expand:
              'BankAccount($expand=CaToken,SaToken),LeadResponse, LoanPrincipal, CustomerAddress($expand=State), CustomerInfo($expand=DLLicenseState), CustomerEmployment($expand=PayFrequency,PayrollMethod,PayrollSource,EmploymentType), Disclosure($expand=MilitaryStatus), UserLoan'
          },
          stopFetching
        }}
        getDetailPanelContent={({ row }) => (
          <ReactJson
            src={row}
            shouldCollapse={({ type, name }) =>
              type === 'array' || name === 'data'
            }
          />
        )}
        detailPanelHeight={500}
        exportExcludeFields={excludeExportFields}
        exportLastNameField="customerInfo.lastName"
        customApi={AdminApi}
      />
    </MainLayout>
  );
};

export default ApplicationsListPage;
