import axios from 'axios';

import helpers from '@utils/helpers';
import { wallboardApiBaseUrl } from '@constants/wallboardApiUrls';

const headers = {
  'Accept-Language': 'en',
  'Content-Type': 'application/json'
};

const WallboardApi = axios.create({
  baseURL: wallboardApiBaseUrl,
  headers
});

WallboardApi.interceptors.request.use(
  async (config) => ({
    ...config,
    url: encodeURI(config.url)
  }),
  (error) => Promise.reject(error)
);

WallboardApi.interceptors.response.use(
  (res) => res.data,
  (err) => Promise.reject(helpers.handleServerErrors(err))
);

export default WallboardApi;
