import React from 'react';

import { getColumns, initialExtraParams } from './config';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const BlockedIPAddressesPage = () => (
  <MainLayout title="Blocked IP Addresses">
    <ODataPaginatedTable
      getColumns={getColumns}
      usePaginatedDataOptions={{
        apiUrl: adminApiUrls.blockedIPAddresses.list,
        initialFilterParams: initialExtraParams
      }}
      rowKey="loggedEventId"
      exportExcludeFields={['message']}
      customApi={AdminApi}
    />
  </MainLayout>
);

export default BlockedIPAddressesPage;
