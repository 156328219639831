import React, { useEffect } from 'react';
import PT from 'prop-types';

import { useServerData } from '@hooks/useServerData';
import Loader from '@components/shared/Loader';
import TelemetryDataTable from '@components/TelemetryDataTable';
import { adminApiUrls } from '@constants/adminApiUrls';
import AdminApi from '@utils/adminApi';

const ApplicationTelemetryData = ({ id }) => {
  const { data, isLoading, error, fetchData } = useServerData({
    endpoint: adminApiUrls.applicationTelemetry(id),
    customApi: AdminApi
  });

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) return <Loader />;
  if (error) return 'Failed to load telemetry data';

  return <TelemetryDataTable data={data?.pulses} />;
};

ApplicationTelemetryData.propTypes = {
  id: PT.string.isRequired
};

export default ApplicationTelemetryData;
