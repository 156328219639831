import React, { useState } from 'react';
import PT from 'prop-types';
import { useParams } from 'react-router-dom';

import { getProfileInfoList, userDetailsPermissions } from './config';

import { customerSiteSrc, permissionsMap } from '@constants/common';
import Loader from '@components/shared/Loader';
import ApplicationsList from '@components/Users/Details/ApplicationsList';
import LoansList from '@components/Users/Details/LoansList';
import NotificationsDetails from '@components/Users/Details/NotificationDetails';
import PaymentOrders from '@components/Users/Details/PaymentOrders';
import WithdrawsList from '@components/Users/Details/WithdrawsList';
import ManageNotifications from '@components/Users/Details/ManageNotifications';
import helpers from '@utils/helpers';
import Logger from '@utils/logger';
import DeviceInformation from '@components/Users/Details/DeviceInformation';
import InternetBankVerification from '@components/Users/Details/InternetBankVerification';
import LoginAttempts from '@components/Users/Details/LoginAttempts';
import Button from '@components/shared/Button/Button';
import Accordion from '@components/shared/Accordion/Accordion';
import toastify from '@utils/toastify';
import Stack from '@components/shared/Stack/Stack';
import Dialog from '@components/shared/Modal/Dialog';
import Typography from '@components/shared/Typography/Typography';
import TabsPanel from '@components/shared/TabsPanel/TabsPanel';
import ListContentBlock from '@components/shared/ListContentBlock/ListContentBlock';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const UserDetails = ({ data, refreshData }) => {
  const params = useParams();
  const [impersonateLoader, setImpersonateLoader] = useState(false);
  const [resetPasswordLoader, setResetPasswordLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const loading = !data;

  const showTab = (perm) => {
    if (perm instanceof Function) {
      return perm(data);
    }
    return helpers.getActionPermission(perm);
  };

  const handleCustomerLogin = async (id) => {
    setImpersonateLoader(true);
    try {
      const response = await AdminApi.get(adminApiUrls.users.impersonate(id));
      window.location = `${customerSiteSrc}private/dashboard?access_token=${response.access_token}`;
    } catch {
      setImpersonateLoader(false);
    }
  };

  const handleResetCustomerPassword = async (id) => {
    try {
      await AdminApi.post(adminApiUrls.account.resetPassword(id), {
        force: true
      });
      await refreshData();
      toastify.success({
        message: 'Customer credentials has been successfully reset'
      });
    } catch (e) {
      Logger.error(e);
      toastify.error({
        message:
          e?.nonFieldErrors || 'An error occurred while resetting password'
      });
      setResetPasswordLoader(false);
    } finally {
      setShowModal(false);
    }
  };

  if (loading || !data) return <Loader />;

  const permissionsValues = Object.values(userDetailsPermissions);
  const defaultTabIndex = permissionsValues.findIndex(showTab);
  const shouldShowTabs = defaultTabIndex !== -1;

  const profileInfoList = getProfileInfoList(data);
  const accordionList = [
    {
      key: '1',
      header: 'User profile details',
      content: (
        <ListContentBlock
          contentList={profileInfoList}
          wrapperProps={{ mb: 2 }}
        />
      )
    }
  ];

  const tabContentList = [
    {
      key: 'application-list',
      label: 'Application list',
      content: <ApplicationsList id={params?.id} />,
      hidden: !showTab(userDetailsPermissions.application)
    },
    {
      key: 'loans-list',
      label: 'Loans list',
      content: <LoansList id={params?.id} />,
      hidden: !showTab(userDetailsPermissions.userLoan)
    },
    {
      key: 'notifications-details',
      label: 'Notifications details',
      content: <NotificationsDetails id={params?.id} />,
      hidden: !showTab(userDetailsPermissions.notification)
    },
    {
      key: 'payment-orders',
      label: 'Payments',
      content: <PaymentOrders id={params?.id} />,
      hidden: !showTab(userDetailsPermissions.paymentOrders)
    },
    {
      key: 'withdraws-list',
      label: 'Withdraw List',
      content: <WithdrawsList id={params?.id} />,
      hidden: !showTab(userDetailsPermissions.withdraws)
    },
    {
      key: 'manage-notifications',
      label: 'Manage Notifications',
      content: <ManageNotifications id={params?.id} />,
      hidden: !showTab(userDetailsPermissions.manageNotifications)
    },
    {
      key: 'device-information',
      label: 'Device History',
      content: <DeviceInformation id={params?.id} />,
      hidden: !showTab(userDetailsPermissions.deviceInformation)
    },
    {
      key: 'ibv-underwritings',
      label: 'Instant Bank Verification',
      content: <InternetBankVerification id={params?.id} />,
      hidden: !showTab(userDetailsPermissions.ibvUnderwritings)
    },
    {
      key: 'login-attempts',
      label: 'Login Attempts',
      content: <LoginAttempts id={params?.id} />,
      hidden: !showTab(userDetailsPermissions.loginAttempts)
    }
  ];

  return (
    <>
      <Stack
        direction="row"
        gap={5}
        my={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3">User Profile</Typography>
        <Stack direction="row" gap={2}>
          {helpers.getActionPermission(permissionsMap.usersImpersonate) && (
            <Button
              onClick={() => handleCustomerLogin(data.id)}
              loading={impersonateLoader}
              disabled={impersonateLoader}
            >
              Login as {helpers.getDisplayName(data.firstName, data.lastName)}
            </Button>
          )}
          {helpers.getActionPermission(permissionsMap.resetCustomerPwd) && (
            <Button
              loading={resetPasswordLoader}
              disabled={resetPasswordLoader}
              onClick={() => setShowModal(true)}
              variant="outlined"
            >
              Reset credentials
            </Button>
          )}
        </Stack>
      </Stack>
      <Accordion accordionList={accordionList} expandedByDefault={['1']} />
      {shouldShowTabs && <TabsPanel tabContentList={tabContentList} />}
      <Dialog
        open={showModal}
        title="Are you sure you want to reset the customer's password?"
        confirmText="Yes"
        cancelText="No"
        onConfirm={() => handleResetCustomerPassword(data.id)}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

UserDetails.defaultProps = {
  data: {}
};

UserDetails.propTypes = {
  data: PT.object,
  refreshData: PT.func.isRequired
};

export default UserDetails;
