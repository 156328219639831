import React from 'react';
import PT from 'prop-types';
import MuiIconButton from '@mui/material/IconButton';

import { getButtonStyle } from './IconButton.utils';

const IconButton = ({ color = 'primary', sx, icon, children, ...props }) => (
  <MuiIconButton sx={getButtonStyle(color, sx)} {...props}>
    {icon || children}
  </MuiIconButton>
);

IconButton.propTypes = {
  tooltipProps: PT.object,
  onClick: PT.func,
  color: PT.string,
  children: PT.element,
  icon: PT.element,
  sx: PT.object
};

export default IconButton;
