import React, { memo } from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router';
import qs from 'query-string';
import dayjs from 'dayjs';
import PT from 'prop-types';

import { leadDayHourFilterSeparatedSchema } from './schema';

import { useQueryParams } from '@hooks/useQueryParams';
import { dateFormat, shortTimeFormat, timeFormat } from '@constants/common';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';
import { fieldWidth } from '@constants/filters';
import { RefreshIcon } from '@components/shared/icons/Icons';

export const leadDayHourFilterInitialValues = {
  date: dayjs(),
  specifiedTime: false
};

const LeadDayHourFilterSeparated = ({ children }) => {
  const history = useHistory();
  const params = useQueryParams();

  const handleSubmit = (values, { setSubmitting }) => {
    handleFilterUpdate(values);
    setSubmitting(false);
  };

  const handleFilterUpdate = (values) => {
    const { date, time, specifiedTime, ...rest } = values;

    const formattedDate = dayjs(date).format(dateFormat);
    const formattedTime = specifiedTime
      ? time.format(timeFormat)
      : dayjs().format(timeFormat);

    const searchData = {
      ...params,
      ...rest,
      specifiedTime,
      date: dayjs(`${formattedDate} ${formattedTime}`).toISOString(),
      ...(params.offset && { offset: 0 })
    };

    history.push({
      search: qs.stringify(searchData, {
        skipEmptyString: true,
        skipNull: true
      })
    });
  };

  let initialValues = { ...leadDayHourFilterInitialValues, time: dayjs() };

  if (history.location.search) {
    const params = qs.parse(history.location.search);

    if (params && Object.keys(params).length) {
      const specifiedTimeBooleanValue =
        params?.specifiedTime?.toLowerCase() === 'false';
      initialValues = {
        ...leadDayHourFilterInitialValues,
        ...params,
        specifiedTime: !specifiedTimeBooleanValue,
        time: dayjs(params.date)
      };
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={leadDayHourFilterSeparatedSchema}
    >
      {({
        values,
        dirty,
        isSubmitting,
        resetForm,
        setFieldValue,
        submitForm
      }) => (
        <Form>
          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            justifyContent="space-between"
            gap={1}
            mt={1}
          >
            <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1}>
              <FormikField
                type="date"
                name="date"
                label="Date"
                size="small"
                format={dateFormat}
                sx={fieldWidth}
                disableFuture
              />
              <FormikField
                type="checkbox"
                name="specifiedTime"
                label="Time"
                fullWidth
              />
              {values.specifiedTime && (
                <>
                  <FormikField
                    type="time"
                    name="time"
                    label="Time"
                    format={shortTimeFormat.front}
                  />
                </>
              )}
              <Button type="submit" disabled={isSubmitting}>
                Apply
              </Button>
              <Button
                onClick={async () => {
                  resetForm({
                    values: leadDayHourFilterInitialValues,
                    errors: {}
                  });
                  setTimeout(submitForm);
                }}
                variant="outlined"
                disabled={!dirty || isSubmitting}
              >
                Reset
              </Button>
            </Stack>
            <div>
              <Stack direction="row" gap={1}>
                <Button
                  onClick={() => {
                    const date = dayjs().toISOString();
                    const time = dayjs();
                    setFieldValue('date', date);
                    setFieldValue('time', time);
                    setTimeout(submitForm);
                  }}
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                >
                  Refresh
                </Button>
                {children}
              </Stack>
            </div>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

LeadDayHourFilterSeparated.propTypes = {
  children: PT.any
};

export default memo(LeadDayHourFilterSeparated);
