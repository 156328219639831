import { totalValueCellClassName } from '@components/shared/DataTable/DataTable.constants';
import { defaultTimeValue } from '@constants/common';
import helpers from '@utils/helpers';

const highlightedCellClassName = ({ row }) =>
  row.pageName === 'Total' && totalValueCellClassName;

export const columns = [
  {
    headerName: 'Page Name',
    field: 'pageName',
    minWidth: 100,
    flex: 1,
    cellClassName: highlightedCellClassName
  },
  {
    headerName: 'Average Time',
    field: 'pulseAverage',
    minWidth: 80,
    flex: 1,
    cellClassName: highlightedCellClassName,
    renderCell: ({ row, field }) =>
      row[field]
        ? helpers.getDateDiffString(row[field], {
            day: 'd',
            hour: 'h',
            minute: 'm',
            second: 's'
          })
        : defaultTimeValue
  },
  {
    headerName: 'Median time',
    field: 'pulseMedian',
    minWidth: 80,
    flex: 1,
    cellClassName: highlightedCellClassName,
    renderCell: ({ row, field }) =>
      row[field]
        ? helpers.getDateDiffString(row[field], {
            day: 'd',
            hour: 'h',
            minute: 'm',
            second: 's'
          })
        : defaultTimeValue
  },
  {
    headerName: 'Max Time',
    field: 'pulseMax',
    minWidth: 80,
    flex: 1,
    cellClassName: highlightedCellClassName,
    renderCell: ({ row, field }) =>
      row[field]
        ? helpers.getDateDiffString(row[field], {
            day: 'd',
            hour: 'h',
            minute: 'm',
            second: 's'
          })
        : defaultTimeValue
  },
  {
    headerName: 'Min Time',
    field: 'pulseMin',
    minWidth: 80,
    flex: 1,
    cellClassName: highlightedCellClassName,
    renderCell: ({ row, field }) =>
      row[field]
        ? helpers.getDateDiffString(row[field], {
            day: 'd',
            hour: 'h',
            minute: 'm',
            second: 's'
          })
        : defaultTimeValue
  }
];
