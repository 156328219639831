import React, { useState } from 'react';
import PT from 'prop-types';

import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails
} from './styled';

import Box from '@components/shared/Box/Box';

const Accordion = ({
  accordionList: _accordionList,
  getAccordionList,
  internalSetState,
  expandedByDefault,
  expandedAllByDefault,
  wrapperProps,
  noPadding,
  ...props
}) => {
  const expandedList =
    (expandedAllByDefault && _accordionList?.map(({ key }) => key)) || null;

  const [expanded, setExpanded] = useState(
    expandedList || expandedByDefault || []
  );
  const accordionList = _accordionList || getAccordionList?.();

  const handleChange = (panel) => (_, newExpanded) => {
    if (newExpanded) {
      setExpanded((prevExpanded) => [...prevExpanded, panel]);
      internalSetState?.((prevExpanded) => [...prevExpanded, panel]);
    } else {
      setExpanded((prevExpanded) =>
        prevExpanded.filter((item) => item !== panel)
      );
      internalSetState?.((prevExpanded) =>
        prevExpanded.filter((item) => item !== panel)
      );
    }
  };

  return (
    <Box {...wrapperProps}>
      {accordionList.map(
        ({ key, header, content, hidden }, idx) =>
          !hidden && (
            <StyledAccordion
              expanded={expanded.includes(key)}
              onChange={handleChange(key)}
              key={key + idx}
              {...props}
            >
              <StyledAccordionSummary
                aria-controls={key + 'content'}
                id={key + 'header'}
              >
                {header}
              </StyledAccordionSummary>
              <StyledAccordionDetails noPadding={noPadding}>
                {content}
              </StyledAccordionDetails>
            </StyledAccordion>
          )
      )}
    </Box>
  );
};

Accordion.defaultProps = {
  expandedByDefault: null,
  expandedAllByDefault: false,
  noPadding: false
};

Accordion.propTypes = {
  accordionList: PT.arrayOf(
    PT.shape({
      key: PT.string,
      header: PT.string,
      content: PT.oneOfType([PT.string, PT.node]),
      hidden: PT.bool
    })
  ),
  getAccordionList: PT.func,
  internalSetState: PT.func,
  wrapperProps: PT.object,
  expandedByDefault: PT.array,
  expandedAllByDefault: PT.bool,
  noPadding: PT.bool
};

export default Accordion;
