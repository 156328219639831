import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getUserName } from '../utils';

import { validationSchema } from './validationSchema';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import { subTabsListMain } from '@components/CsvComparison/config';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Button from '@components/shared/Button/Button';
import FormError from '@components/shared/Form/Error';
import Typography from '@components/shared/Typography/Typography';
import Stack from '@components/shared/Stack/Stack';
import Box from '@components/shared/Box/Box';
import ImportCSVButton from '@components/shared/ImportCSV/ImportCSVButton';
import IconButton from '@components/shared/IconButton/IconButton';
import { AddCircleIcon, DeleteIcon } from '@components/shared/icons/Icons';
import CsvComparisonApi from '@utils/csvComparisonApi';
import { csvComparisonApiUrls } from '@constants/csvComparisonApi';
import Logger from '@utils/logger';
import toastify from '@utils/toastify';
import routes from '@constants/routes';
import Loader from '@components/shared/Loader';
import { selectCurrentUser } from '@store/currentUser';
import { theme } from '@utils/theme';

const iconStyle = {
  width: 30,
  height: 30,
  mx: 0
};

const defaultInitialValues = {
  name: '',
  file: '',
  fileIdentifier: '',
  columns: [
    {
      name: '',
      value: ''
    },
    {
      name: '',
      value: ''
    }
  ]
};

const CsvComparisonConfigurationsCreateEditPage = () => {
  const { id: configId } = useParams();
  const [options, setOptions] = useState([]);
  const [loadingHeaders, setLoadingHeaders] = useState(false);
  const history = useHistory();
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const storeCurrentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (!configId) return;
    (async () => {
      try {
        const data = await CsvComparisonApi.get(
          csvComparisonApiUrls.comparisons.configs.details(configId)
        );

        const configOptions = data.configOptions;

        const preparedOptions = configOptions.map(
          ({ columnName, headerName }) => ({
            label: headerName || columnName,
            value: headerName || columnName,
            disabled: !!columnName
          })
        );

        setOptions(preparedOptions);

        const values = {
          name: data.name,
          fileIdentifier: data.fileIdentifier,
          file: new File(['text'], 'file.csv', {
            type: 'text/csv'
          }),
          columns: configOptions
            .filter((item) => item.columnName)
            .map((item) => ({
              value: item.headerName,
              name: item.columnName
            }))
        };

        setInitialValues(values);
      } catch (e) {
        Logger.error(e);
        toastify.error(
          e?.response?.data?.error || {
            message: 'An error occurred getting a configuration'
          }
        );
      }
    })();
  }, [configId]);

  const getColumns = async (file) => {
    const formData = new FormData();

    formData.append('file', file);

    const res = await CsvComparisonApi.post(
      csvComparisonApiUrls.comparisons.configs.headers,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json'
        }
      }
    );

    const preparedOptions = res?.map((item) => ({
      label: item,
      value: item
    }));

    setOptions(preparedOptions);
  };

  const onHandleUploadFile = async (file, setFieldValue) => {
    try {
      setFieldValue('columns', [
        {
          name: '',
          value: ''
        },
        {
          name: '',
          value: ''
        }
      ]);

      setLoadingHeaders(true);
      await getColumns(file);

      handleUploadFile(file, setFieldValue, 'file');
    } catch (e) {
      Logger.error(e);
      toastify.error(
        e?.response?.data?.error || {
          message: 'An error occurred parsing a file'
        }
      );
    } finally {
      setLoadingHeaders(false);
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const copiedOptions = [...options];

      const preparedColumns = values.columns.map((item, i) => {
        const index = copiedOptions.findIndex(
          (option) => option.value === item.value
        );
        if (index != -1) {
          copiedOptions.splice(index, 1);
        }

        return {
          headerName: item.value,
          columnName: item.name,
          columnNumber: i
        };
      });

      const preparedUnSelectColumns = copiedOptions.map((item) => ({
        headerName: item.value
      }));

      const data = {
        name: values.name,
        fileIdentifier: values.fileIdentifier,
        configOptions: [...preparedColumns, ...preparedUnSelectColumns],
        username: getUserName(storeCurrentUser)
      };

      if (configId) {
        data.id = configId;
      }

      const method = configId ? 'put' : 'post';

      await CsvComparisonApi[method](
        csvComparisonApiUrls.comparisons.configs.main,
        data
      );

      toastify.success({
        message: configId
          ? 'Configuration was updated'
          : 'Configuration was created'
      });

      history.push(routes.csvCompare.configurations.list);
    } catch (e) {
      const errorBody = e?.response?.data;
      Logger.error(e);

      toastify.error(
        errorBody?.title ||
          errorBody?.error || {
            message: 'An error occurred creating comparison'
          }
      );
    } finally {
      setSubmitting(false);
    }
  };

  const handleRemoveFile = (setFieldValue, inputRef, name) => {
    setFieldValue(name, null);
    inputRef.current.value = null;
  };

  const handleUploadFile = (file, setFieldValue, name) => {
    setFieldValue(name, file);
  };

  const handleChangeSelect = ({ target }, { setFieldValue, index, values }) => {
    const value = target.value;
    const name = target.value;
    setFieldValue(name, value);

    setOptions((state) =>
      state.map((item) => {
        const found = values.columns.find((elem, i) =>
          i === index ? false : item.value === elem.value
        );

        return {
          ...item,
          disabled: !!found || item.value === value
        };
      })
    );
  };

  const handleDeleteItem = ({ values, setFieldValue, index }) => {
    const newColumns = [...values.columns];
    newColumns.splice(index, 1);

    setOptions((state) =>
      state.map((item) => ({
        ...item,
        disabled: !!newColumns.find((elem) => item.value === elem.value)
      }))
    );

    if (newColumns.length < 2) {
      newColumns.push({
        name: '',
        value: ''
      });
    }
    setFieldValue('columns', newColumns);
  };

  const isLoading = configId ? !initialValues : false;

  return (
    <MainLayout title="CSV Compare">
      <NavTabsPanel
        currentIdx={1}
        tabsList={subTabsListMain}
        ariaLabel="access-tabs"
      />

      {isLoading && <Loader />}

      {!isLoading && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            errors,
            setFieldValue,
            values,
            isValid,
            dirty
          }) => {
            const columnsLen = values.columns.length;

            return (
              <Form>
                <Box pt={4}>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    gap={`${theme.spacing(2)} ${theme.spacing(5)}`}
                  >
                    <FormikField
                      sx={{ width: 200, marginTop: '2px' }}
                      placeholder="Enter name"
                      label="Configuration name:"
                      name="name"
                    />

                    {values.file && (
                      <FormikField
                        sx={{ width: 200, marginTop: '2px' }}
                        placeholder="Enter file identifier"
                        label="File identifier:"
                        name="fileIdentifier"
                      />
                    )}

                    <ImportCSVButton
                      file={values.file}
                      handleUploadFile={(file) => {
                        onHandleUploadFile(file, setFieldValue);
                      }}
                      handleRemoveFile={(inputRef) =>
                        handleRemoveFile(setFieldValue, inputRef, 'file')
                      }
                      wrapperProps={{
                        minHeight: 44,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}
                      loading={loadingHeaders}
                      isRow
                      title="Choose file"
                    />
                  </Stack>

                  <Typography sx={{ py: 1 }}>
                    The file must contain headers for creating configuration.
                  </Typography>
                </Box>
                {values.file && (
                  <Box pt={4} sx={{ width: 510 }}>
                    <Typography mt={1}>
                      Configure columns for comparison
                    </Typography>
                    <Typography mt={2}>
                      Data Points should be consistent between configurations
                      when comparing!
                    </Typography>
                    <Typography mt={2}>
                      Card Numbers are compared using only 4 last digits of the
                      number.
                    </Typography>
                    <Typography mt={2}>
                      File Identifier field should contain part of the filename
                      used for comparison.
                    </Typography>
                    <Typography mt={2}>
                      Predefined fields: FirstName, LastName, FullName, and
                      CardNumber should be used in Data Points for the
                      corresponding fields.
                    </Typography>
                    <Typography mt={2}>
                      When comparing two files, where First & Last Name are in
                      the same column, column name under Data Points must be
                      called FullName. When comparing two files, where First &
                      Last Name are in separate columns, then columns must be
                      named FirstName and LastName.
                    </Typography>
                    <Stack direction="row" mt={4} gap={5}>
                      <Typography sx={{ width: 200 }}>Data Points:</Typography>
                      <Typography sx={{ width: 200 }}>
                        Choose columns in file:
                      </Typography>
                    </Stack>
                    {values.columns?.map((_, index) => (
                      <Stack
                        direction="row"
                        gap={5}
                        alignItems="flex-start"
                        key={index}
                      >
                        <FormikField
                          placeholder="Enter name"
                          sx={{ width: 200 }}
                          type="text"
                          name={`columns.${index}.name`}
                        />
                        <FormikField
                          options={options}
                          placeholder="Select"
                          onChange={(e) =>
                            handleChangeSelect(e, {
                              setFieldValue,
                              index,
                              values
                            })
                          }
                          sx={{ width: 200 }}
                          type="select"
                          name={`columns.${index}.value`}
                        />

                        <Box mt={1}>
                          <IconButton
                            disabled={columnsLen <= 2}
                            type="button"
                            onClick={() =>
                              handleDeleteItem({ setFieldValue, values, index })
                            }
                            size="small"
                            sx={iconStyle}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Stack>
                    ))}
                    <Box
                      sx={{
                        width: 200,
                        marginTop: 1,
                        marginRight: '70px',
                        marginLeft: 'auto',
                        textAlign: 'center'
                      }}
                    >
                      <IconButton
                        disabled={
                          columnsLen >= options.length || columnsLen > 9
                        }
                        type="button"
                        onClick={() => {
                          const newColumns = [
                            ...values.columns,
                            {
                              name: '',
                              value: ''
                            }
                          ];
                          setFieldValue('columns', newColumns);
                        }}
                        size="small"
                        sx={iconStyle}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </Box>
                    <div>
                      <FormError bold error={errors.nonFieldErrors} />
                    </div>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      pr="70px"
                      gap={1}
                    >
                      <Button
                        loading={isSubmitting}
                        type="submit"
                        sx={{ width: 200 }}
                        disabled={!dirty || !isValid || isSubmitting}
                      >
                        Save configuration
                      </Button>
                    </Stack>
                  </Box>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </MainLayout>
  );
};

export default CsvComparisonConfigurationsCreateEditPage;
