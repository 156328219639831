import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getFilledColumns, initialFilterParams } from './config';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import { selectAppVersions } from '@store/settings';
import { fetchAppVersions } from '@store/settings/actions';
import Logger from '@utils/logger';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const AppUsersListPage = () => {
  const [operatingSystems, setOperatingSystems] = useState(null);
  const dispatch = useDispatch();
  const appVersions = useSelector(selectAppVersions);

  const fetchOperatingSystems = async () => {
    try {
      const data = await AdminApi.get(adminApiUrls.common.operatingSystems);
      setOperatingSystems(data);
    } catch (e) {
      Logger.error(e);
      throw new Error(`An error occurred fetching operating systems: ${e}`);
    }
  };

  useEffect(() => {
    if (!appVersions) {
      dispatch(fetchAppVersions());
    }

    fetchOperatingSystems();
  }, []);

  const getColumns = getFilledColumns({
    appVersions: appVersions?.map((name) => ({
      value: name,
      label: name
    })),
    operatingSystems: operatingSystems?.items?.map(({ value }) => ({
      value: value,
      label: value
    }))
  });

  return (
    <MainLayout
      title="Devices and Op. Systems"
      isLoading={appVersions === null || operatingSystems === null}
    >
      <ODataPaginatedTable
        getColumns={getColumns}
        usePaginatedDataOptions={{
          apiUrl: adminApiUrls.userAgents,
          initialFilterParams: {
            $orderBy: 'createdDate desc',
            $filter: {
              ...initialFilterParams
            },
            $expand: 'User'
          }
        }}
        rowKey="agentId"
        customApi={AdminApi}
        exportLastNameField="user.lastName"
      />
    </MainLayout>
  );
};

export default AppUsersListPage;
