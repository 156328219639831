const adminApiBaseUrl = process.env.ADMIN_API_URL || '';

const adminApiUrls = {
  flatPages: {
    list: '/odata/ContentEntities',
    page: (id) => `/odata/ContentEntities(${id})`
  },
  settings: {
    odata: '/odata/Settings',
    item: (id) => `/odata/Settings(${id})`
  },
  blockedIPAddresses: {
    list: '/odata/LoggedEvents'
  },
  loggedEventWithFormData: (id) => `/odata/LoggedEvents(${id})/FormData`,
  loggedEvents: '/odata/LoggedEvents',
  notificationJob: {
    list: '/odata/NotificationJobs',
    page: (id) => `/odata/NotificationJobs(${id})`
  },
  notificationSchedules: {
    list: '/odata/NotificationSchedules',
    page: (id) => `/odata/NotificationSchedules(${id})`
  },
  notificationJobHistories: {
    list: '/odata/NotificationJobHistoryReadRates'
  },
  notificationCustomers: {
    list: '/odata/NotificationCustomers'
  },
  notificationSubscriptions: {
    list: `/odata/NotificationSubscriptions`
  },
  notifications: {
    list: '/odata/Notifications',
    test: (notificationJobId) => `/api/notifications/test/${notificationJobId}`
  },
  notificationBulk: {
    upload: (jobId) => `/api/notificationbulk/${jobId}/upload`,
    download: (fileIdentifierId) =>
      `/api/notificationbulk/download/${fileIdentifierId}/url`
  },
  notificationTest: (notificationJobId) =>
    `/api/notifications/test/${notificationJobId}/batch`,
  dashboard: {
    ibv: {
      scoringReport: '/odata/IbvScoringItems',
      statusReport: '/api/ibv-report/status-report'
    },
    telemetry: '/api/dashboard/application/telemetry/error-rates',
    applicationTime: '/api/dashboard/application-time',
    log: '/api/dashboard/log',
    tiers: '/api/dashboard/statistics-tiers',
    organic: '/api/dashboard/statistics-organic',
    stores: '/api/dashboard/statistics-stores',
    webPerformance: '/api/dashboard/web-performance',
    dbPerformance: '/api/dashboard/db-performance',
    abandonedApplications: '/api/dashboard/application-step-statistics'
  },
  applications: {
    list: '/odata/Applications',
    application: (id) => `/odata/Applications(${id})`,
    status: (applicationId) => `api/application/${applicationId}/status`
  },
  mobileAppVersions: {
    list: '/odata/ApplicationVersions',
    page: (id) => `/odata/ApplicationVersions(${id})`
  },
  draws: '/odata/Draws',
  promoCodes: {
    list: '/odata/PromoCodes',
    page: (id) => `/odata/PromoCodes(${id})`
  },
  userLoans: '/odata/UserLoanCustomers',
  users: {
    list: '/odata/ApplicationUsers',
    page: (id) => `/odata/ApplicationUserAgents('${id}')`,
    impersonate: (id) => `/api/account/user/${id}/impersonate/token`,
    customerTier: (id) => `/api/customer-tier/${id}`
  },
  userAgents: '/odata/UserAgents',
  controlPanel: {
    policies: {
      list: '/odata/Policies'
    }
  },
  loginAttempts: '/odata/UserWebsiteLogins',
  paymentOrders: '/odata/PaymentOrders',
  unprocessedPayments: '/odata/UnprocessedPayments',
  healthCheck: {
    servicesStatuses: '/api/all-healthz',
    notifiedEmails: '/api/emails'
  },
  common: {
    operatingSystems: '/api/common/operating-systems',
    timeZones: '/api/common/time-zones',
    utmSourceList: '/api/common/utm_source/list',
    smsProviders: '/api/common/sms-providers',
    IBVScoringStatuses: '/api/common/ibv-scoring-statuses'
  },
  maintenanceMode: {
    getMaintenanceMode: '/api/Maintanance/GetMaintenanceMode',
    setMaintenanceMode: '/api/Maintanance/SetMaintenanceMode'
  },
  applicationFile: {
    getURL: (applicationUId) => `/api/application/${applicationUId}/pdf`
  },
  applicationsTelemetry: `/api/telemetry/application/summary`,
  applicationTelemetry: (id) => `/api/telemetry/application/${id}/summary`,
  applicationLeadTelemetry: (id) =>
    `/api/telemetry/application/${id}/lead-data`,
  applicationIDVImages: {
    list: (applicationId) =>
      `/api/idv-images/application/${applicationId}/images`,
    download: (resourceId) => `/api/idv-images/${resourceId}/download`
  },
  creditCard: {
    search: '/api/cc-customer',
    saveData: (customerId) => `/api/cc-customer/${customerId}/creditcard`
  },
  account: {
    resetPassword: (id) => `/api/account/user/${id}/reset-password`
  },
  drawTelemetry: (drawId) => `/api/telemetry/draw/${drawId}/log`,
  appVersion: {
    versions: '/api/application/versions'
  },
  botDashboard: '/api/bot/redirect-login',
  uploadTeamGoalsFile: '/api/wallboard/file/team-goals/upload',
  paymentOrderCustomers: '/odata/PaymentOrderCustomers',
  applicationLatestVersion: '/api/application/latest-versions',
  apply: {
    applicationAmount: (applicationId) =>
      `api/application/${applicationId}/amount`,
    loanForm: {
      userInfo: {
        url: (applicationId) => `api/application/${applicationId}/user-info`,
        options: 'api/apply/user-info/options'
      },
      userEmployment: {
        url: (applicationId) =>
          `api/application/${applicationId}/user-employment`,
        options: 'api/apply/user-employment/options'
      },
      disclosure: {
        url: (applicationId) => `api/application/${applicationId}/disclosure`,
        options: 'api/apply/disclosure/options'
      },
      bankAccount: {
        url: (applicationId) => `api/application/${applicationId}/bank-account`
      },
      userAddress: {
        url: (applicationId) => `api/application/${applicationId}/user-address`,
        options: 'api/apply/user-info/options'
      },
      submit: (applicationId) => `api/application/${applicationId}/submit`,
      reSubmit: (applicationId) => `api/application/${applicationId}/re-submit`
    }
  },
  applyOptions: {
    employment: '/api/apply/user-employment/options',
    userInfo: '/api/apply/user-info/options',
    disclosures: '/api/apply/disclosure/options'
  },
  notificationsUserSettings: {
    page: (userId) => `api/notifications/settings/${userId}`
  },
  apiVersion: '/api/apiVersion'
};

export { adminApiUrls, adminApiBaseUrl };
