import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PT from 'prop-types';

import helpers from '@utils/helpers';
import { loanAmountSchema } from '@components/Application/LoanForm/GeneralStep/schema';
import FormError from '@components/shared/Form/Error';
import Button from '@components/shared/Button/Button';
import Stack from '@components/shared/Stack/Stack';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Modal from '@components/shared/Modal/Modal';

const SubmitApplicationModal = ({ open, application, onSubmit, onClose }) => {
  const requiredSignature =
    application?.customerInfo?.firstName +
    ' ' +
    application?.customerInfo?.lastName;

  const checkStepSchema = useMemo(
    () =>
      Yup.object()
        .shape({
          signature: Yup.string()
            .label('Full name')
            .required()
            .test(
              'requiredSignature',
              'Value must be equal to the text above',
              (value) => helpers.validateSignature(requiredSignature, value)
            )
        })
        .concat(loanAmountSchema),
    [requiredSignature]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Submit application"
      minContentWidth="400px"
      withCloseIcon
    >
      <Formik
        initialValues={{
          amount: application.loanPrincipal?.amount || '',
          signature: ''
        }}
        validationSchema={checkStepSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <FormikField label="Loan Amount:" type="number" name="amount" />
            <FormikField
              type="text"
              name="signature"
              placeholder={requiredSignature}
              label={
                <>
                  Full name (e-Signature)&nbsp;-&nbsp;
                  {requiredSignature}
                </>
              }
            />
            <div>
              <FormError bold error={errors.nonFieldErrors} />
            </div>
            <Stack direction="row" gap={1}>
              <Button type="submit" disabled={isSubmitting}>
                Confirm
              </Button>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

SubmitApplicationModal.defaultProps = {
  open: false
};

SubmitApplicationModal.propTypes = {
  open: PT.bool.isRequired,
  application: PT.object.isRequired,
  onSubmit: PT.func.isRequired,
  onClose: PT.func.isRequired
};

export default SubmitApplicationModal;
