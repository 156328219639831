import React from 'react';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

import Button from '@components/shared/Button/Button';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import filterHelpers from '@utils/filterHelpers';
import { dateTimeFormat } from '@constants/common';

const DateRangeInTwoInputs = (props) => {
  const { item, applyValue } = props;

  const initialValues = {
    startDate:
      item.value?.[0] ||
      dayjs().subtract(1, 'day').startOf('day').format(dateTimeFormat),
    endDate: item.value?.[1] || dayjs().format(dateTimeFormat)
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = [dayjs(values.startDate), dayjs(values.endDate)];

    if (values.startDate !== null && values.endDate !== null) {
      applyValue({ ...item, value: payload });
    }

    setSubmitting(payload);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Form className="page-filter">
        <Box sx={filterHelpers.getFlexForField(20)}>
          <Grid item sm={4}>
            <FormikField
              type="date"
              name="startDate"
              label="Period from"
              size="small"
              disableFuture
            />
          </Grid>
          <Grid item sm={4}>
            <FormikField
              type="date"
              name="endDate"
              label="Period to"
              size="small"
              disableFuture
            />
          </Grid>
        </Box>
        <Button
          type="submit"
          sx={{ height: '32px', margin: 'auto' }}
          filterVariant
        >
          Apply
        </Button>
      </Form>
    </Formik>
  );
};

DateRangeInTwoInputs.propTypes = {
  item: PropTypes.array,
  applyValue: PropTypes.func
};

export default DateRangeInTwoInputs;
