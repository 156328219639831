export const permissionsMap = {
  flatView: 'FlatView',
  flatManage: 'FlatManage',
  applicationView: 'ApplicationView',
  applicationManage: 'ApplicationManage',
  adminLeadProviderDataView: 'AdminLeadProviderDataView',
  telemetryView: 'TelemetryView',
  telemetryManage: 'TelemetryManage',
  usersView: 'UsersView',
  loggedView: 'LoggedView',
  managersView: 'ManagersView',
  managersManage: 'ManagersManage',
  groupView: 'GroupView',
  groupManage: 'GroupManage',
  usersImpersonate: 'UsersImpersonate',
  notificationJobView: 'NotificationJobView',
  notificationJobManage: 'NotificationJobManage',
  fieldsErrorRateView: 'FieldsErrorRateView',
  applicationTimeView: 'ApplicationTimeView',
  errorResponseRateView: 'ErrorResponseRateView',
  internalPerformanceView: 'InternalPerformanceView',
  applicationRatesView: 'ApplicationRatesView',
  leadProvidersView: 'LeadProvidersView',
  leadProvidersManage: 'LeadProvidersManage',
  leadGroupsView: 'LeadGroupsView',
  leadGroupsManage: 'LeadGroupsManage',
  leadRatesView: 'LeadRatesView',
  leadReportsView: 'LeadReportsView',
  userLoansView: 'UserLoansView',
  userAgentsView: 'UserAgentsView',
  appVersionView: 'AppVersionView',
  appVersionManage: 'AppVersionManage',
  paymentCollectionsView: 'PaymentCollectionsView',
  promoCodesView: 'PromoCodesView',
  promoCodesManage: 'PromoCodesManage',
  resetCustomerPwd: 'ResetCustomerPwd',
  ibvResultView: 'IbvResultView',
  paymentUserView: 'PaymentUserView',
  botAdministrationView: 'BotAdministrationView',
  debitCardManage: 'DebitCardManage',
  abandonStepStatisticsView: 'AbandonStepStatisticsView',
  usersByLeadProviderView: 'UsersByLeadProviderView',
  usersByLeadProviderManage: 'UsersByLeadProviderManage',
  CSVCompareManage: 'CSVCompareManage',
  CSVCompareView: 'CSVCompareView',
  paymentsRerunView: 'PaymentsRerunView',
  paymentsRerunManage: 'PaymentsRerunManage',
  leadProviderUsersLeadReports: 'LeadProviderUsersLeadReports',
  leadProviderUsersLeadPerformance: 'LeadProviderUsersLeadPerformance',
  viewSettings: 'ViewSettings',
  manageSettings: 'ManageSettings',
  discrepancyReportView: 'DiscrepancyReportView',
  customerView: 'CustomerView',
  maintananceModeManage: 'MaintananceModeManage',
  maintananceModeView: 'MaintananceModeView',
  identityVerificationView: 'IdentityVerificationView',
  wallboardManage: 'WallboardManage',
  scheduledPaymentsView: 'ScheduledPaymentsView',
  scheduledPaymentsManage: 'ScheduledPaymentsManage',
  declineReasonsView: 'DeclineReasonsView',
  declineReasonsManage: 'DeclineReasonsManage',
  binView: 'BinView',
  binManage: 'BinManage',
  routingView: 'RoutingView',
  routingManage: 'RoutingManage'
};

export const oneDayPermissions = [permissionsMap.debitCardManage];
