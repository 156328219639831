import contentDisposition from 'content-disposition';

import CsvComparisonApi from '@utils/csvComparisonApi';
import Logger from '@utils/logger';
import toastify from '@utils/toastify';
import helpers from '@utils/helpers';

export const downloadFile = async (apiUrl, exportFileName) => {
  try {
    const data = await CsvComparisonApi.get(apiUrl);

    const {
      parameters: { filename }
    } = contentDisposition.parse(data.contentDisposition);

    const blob = new Blob([data.content], { type: 'octet/stream' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');

    link.href = url;
    link.download = filename || `${exportFileName || document.title}.csv`;
    link.click();
    window.URL.revokeObjectURL(url);
  } catch (e) {
    Logger.error(e);
    toastify.error(
      e?.response?.error || { message: 'An error occurred downloading a file' }
    );
  }
};

export const getUserName = (currentUser) => {
  if (currentUser?.firstName || currentUser?.lastName) {
    return helpers.getDisplayName(
      currentUser?.firstName,
      currentUser?.lastName
    );
  }

  return null;
};
