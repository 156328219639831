import React from 'react';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import CustomNotificationsDetails from '@components/CustomNotifications/Details';

const ViewCustomNotificationPage = () => (
  <MainLayout title="Custom notification details">
    <CustomNotificationsDetails />
  </MainLayout>
);

export default ViewCustomNotificationPage;
