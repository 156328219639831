import React from 'react';
import PT from 'prop-types';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import { Field } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import { createFilterOptions } from '@mui/material/Autocomplete';

import { StyledSelect, StyledFormControl, StyledAutocomplete } from './styled';

import TextField from '@components/shared/TextField/TextField';
import { CloseIcon } from '@components/shared/icons/Icons';
import IconButton from '@components/shared/IconButton/IconButton';
import Box from '@components/shared/Box/Box';
import MenuItem from '@components/shared/Menu/MenuItem';
import { Checkbox } from '@components/shared/Form/Checkbox';
import FormHelperText from '@components/shared/Form/FormHelperText';
import { palette } from '@utils/theme';
import helpers from '@utils/helpers';

export const FormAutocomplete = ({
  withClear,
  disableClearable,
  variant,
  ...props
}) => (
  <Box position="relative">
    <StyledAutocomplete
      filterOptions={createFilterOptions({ trim: true })}
      {...props}
      disablePortal
      options={props.options?.filter(
        ({ value }) => ![props.value].includes(value)
      )}
      isOptionEqualToValue={(option, value) => option.label === value}
      value={
        props.options?.find(({ value }) => value == props.value)?.label || ''
      }
      clearOnEscape
      renderInput={(inputProps) => (
        <TextField
          {...inputProps}
          label={helpers.getFieldLabelWithAsterisk(props.label, props.required)}
          sx={{ m: 0 }}
          error={props.error}
          variant={variant}
        />
      )}
      openOnFocus
      size="small"
      disableClearable={!withClear || disableClearable}
    />
    {props.error && (
      <FormHelperText error={!!props.error}>{props.error}</FormHelperText>
    )}
  </Box>
);

const FormSelect = ({
  options,
  multipleChip,
  multiple,
  error,
  value,
  withClear,
  defaultValue,
  hasValue,
  sx,
  ...rest
}) => (
  <Box position="relative">
    <InputLabel
      hasSelectedValue={!!isEmpty(value) || !!defaultValue}
      sx={{ color: rest?.disabled && palette.disabledSelectLabel }}
    >
      {rest.label}
    </InputLabel>
    <StyledSelect
      multiple={multiple}
      openOnFocus
      renderValue={(selected) => {
        const selectedOptions = options.filter(({ value }) =>
          Array.isArray(selected)
            ? selected.includes(value)
            : `${selected}` === `${value}`
        );
        if (multipleChip && multiple) {
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selectedOptions?.map((option) => (
                <Chip key={option.value} label={option.label} />
              ))}
            </Box>
          );
        }
        if (multiple) {
          return selectedOptions?.map(({ label }) => label).join(', ');
        }
        return selectedOptions[0]?.label;
      }}
      size="small"
      error={!!error}
      value={value === null ? '' : value}
      sx={{ ...sx }}
      endAdornment={
        withClear && !!hasValue ? (
          <IconButton
            sx={{ marginRight: '16px', height: '20px', width: '20px' }}
            disabled={!value}
            onClick={(e) => {
              rest.onChange(e, { label: '', value: '' });
            }}
          >
            <CloseIcon size={20} />
          </IconButton>
        ) : null
      }
      {...rest}
    >
      {!options?.length && (
        <MenuItem value="" disabled>
          No options
        </MenuItem>
      )}
      {options?.map(({ value: option, label, disabled }, i) => (
        <MenuItem key={`${value}_${i}`} value={option} disabled={disabled}>
          {multiple && <Checkbox checked={value?.indexOf(option) > -1} />}
          <ListItemText primary={label} />
        </MenuItem>
      ))}
    </StyledSelect>
  </Box>
);

const FormikFormSelect = ({ name, margin, formControlProps, ...rest }) => (
  <Field name={name}>
    {({ form, field, meta }) => {
      const error = meta.touched ? meta.error : '';
      const hasValue =
        isBoolean(field.value) ||
        !isEmpty(field.value?.toString()) ||
        !!rest.defaultValue;

      if (rest.type === 'autocomplete') {
        const onChange = (_, newValue) => {
          const mappedValue = (() => {
            if (!rest.multiple) {
              return newValue?.value;
            }
            const values = newValue.map(({ value }) => value);
            return Array.from(new Set(values).values());
          })();
          form.setFieldValue(name, mappedValue);
        };
        return (
          <StyledFormControl
            fullWidth={rest.fullWidth}
            margin={margin}
            hasValue={hasValue}
            {...formControlProps}
          >
            <FormAutocomplete
              {...field}
              {...rest}
              hasValue={hasValue}
              error={error}
              onChange={onChange}
            />
          </StyledFormControl>
        );
      }

      const defaultOnChange = (e) => form.setFieldValue(name, e.target.value);

      return (
        <StyledFormControl
          fullWidth={rest.fullWidth}
          margin={margin}
          hasValue={hasValue}
          {...formControlProps}
        >
          <FormSelect
            {...field}
            {...rest}
            hasValue={hasValue}
            error={error}
            onChange={(e) => {
              defaultOnChange(e);
              rest.onChange?.(e);
            }}
          />
          {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
        </StyledFormControl>
      );
    }}
  </Field>
);

const propTypes = {
  label: PT.string,
  name: PT.string.isRequired,
  error: PT.oneOfType([PT.string, PT.bool]),
  helperText: PT.string,
  multiple: PT.bool,
  multipleChip: PT.bool,
  actionsList: PT.array,
  value: PT.any,
  defaultValue: PT.any,
  hasValue: PT.bool,
  sx: PT.object,
  withClear: PT.bool,
  margin: PT.string,
  fullWidth: PT.bool,
  onChange: PT.func,
  type: PT.oneOf(['select', 'autocomplete']),
  options: PT.arrayOf(
    PT.shape({
      label: PT.oneOfType([PT.string, PT.number]).isRequired,
      value: PT.any.isRequired,
      disabled: PT.bool
    })
  ),
  formControlProps: PT.object,
  disableClearable: PT.bool,
  variant: PT.oneOf(['standard', 'outlined', 'filled']),
  required: PT.bool
};

FormSelect.defaultProps = {
  withClear: false,
  margin: 'normal',
  sx: {},
  options: [],
  hasValue: false,
  variant: 'outlined'
};

FormSelect.propTypes = propTypes;
FormikFormSelect.propTypes = propTypes;
FormAutocomplete.propTypes = propTypes;

export { FormSelect, FormikFormSelect };
