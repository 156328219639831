import React, { useState } from 'react';
import PT from 'prop-types';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router';

import Alert from '@components/shared/Alert/Alert';
import {
  systemNotificationSchema,
  systemNotificationTypeMap
} from '@components/SystemNotifications/Form/schema';
import toastify from '@utils/toastify';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';
import Dialog from '@components/shared/Modal/Dialog';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const getInitialValues = (data) => ({
  messageTitle: '',
  messageTemplate: '',
  notificationType: '',
  ...data
});

const SystemNotificationForm = ({ onSubmit, data, status }) => {
  const { id } = useParams();
  const [isActive, setStatus] = useState(status);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);

    onSubmit(
      { values, actions },
      {
        onFinish: () => {
          actions.setSubmitting(false);
        }
      }
    );
  };

  const handleStatus = async () => {
    setLoadingStatus(true);

    try {
      await AdminApi.patch(adminApiUrls.notificationJob.page(id), {
        isActive: !isActive
      });

      setStatus(!isActive);

      toastify.success({
        message: !isActive
          ? 'Notification Activated'
          : 'Notification Deactivated'
      });
    } catch (e) {
      toastify.error({
        message: 'Change status operation failure'
      });
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <Formik
      initialValues={getInitialValues(data)}
      onSubmit={handleSubmit}
      validationSchema={systemNotificationSchema}
    >
      {({ errors }) => (
        <Form>
          <FormikField
            type="text"
            name="messageTitle"
            label="Message Title"
            placeholder="Message Title"
            hasFeedback
            showValidateSuccess
          />
          <FormikField
            type="textarea"
            name="messageTemplate"
            label="Message Template"
            placeholder="Message Template"
            multiline
            minRows={4}
            hasFeedback
            showValidateSuccess
          />
          <FormikField
            type="radio"
            name="notificationType"
            label="Notification Type"
            options={Object.values(systemNotificationTypeMap).map((item) => ({
              value: item,
              label: item
            }))}
            hasFeedback
            showValidateSuccess
          />

          {errors.nonFieldErrors && (
            <Alert message={errors.nonFieldErrors} type="error" />
          )}
          <Stack direction="row" gap={2}>
            <Button type="submit">Save</Button>

            <Button
              onClick={() => setShowModal(true)}
              variant="outlined"
              disabled={loadingStatus}
            >
              {!isActive ? 'Activate' : 'Deactivate'}
            </Button>
            <Dialog
              open={showModal}
              title={`Are you sure to ${
                !isActive ? 'activate' : 'deactivate'
              }?`}
              onConfirm={handleStatus}
              onClose={() => setShowModal(false)}
              okText="Yes"
              cancelText="No"
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

SystemNotificationForm.defaultProps = {
  data: {}
};

SystemNotificationForm.propTypes = {
  onSubmit: PT.func,
  data: PT.object,
  status: PT.bool
};

export default SystemNotificationForm;
