import React from 'react';
import { Formik, Form } from 'formik';
import PT from 'prop-types';

import { appVersionSchema } from './schema';

import Alert from '@components/shared/Alert/Alert';
import { platformTypes } from '@constants/mobileAppVersions';
import Typography from '@components/shared/Typography/Typography';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';
import Box from '@components/shared/Box/Box';

const getInitialValues = (appVersion) => ({
  name: '',
  clientOperatingSystem: '',
  ...appVersion
});

const platformTypesOptions = Object.keys(platformTypes).map((value) => ({
  value,
  label: value
}));

const MobileAppVersionsForm = ({ data, handleSubmit, editMode }) => (
  <Formik
    initialValues={getInitialValues(data)}
    onSubmit={handleSubmit}
    validationSchema={appVersionSchema}
  >
    {({ errors }) => (
      <Form>
        <Typography variant="h2" my={2}>
          {editMode ? 'Edit' : 'Add'} App Version
        </Typography>
        <Box width="100%" maxWidth="500px" my={2}>
          <FormikField
            type="text"
            name="name"
            label="App version"
            placeholder="Enter app version"
          />
          <FormikField
            type="autocomplete"
            name="clientOperatingSystem"
            label="Platform type"
            placeholder="Enter platform type"
            options={platformTypesOptions}
          />
          {errors.nonFieldErrors && (
            <Alert
              message={errors.nonFieldErrors}
              type="error"
              className="mb-3"
            />
          )}
        </Box>
        <Stack size={20}>
          <Button type="submit">{editMode ? 'Edit' : 'Create'}</Button>
        </Stack>
      </Form>
    )}
  </Formik>
);

MobileAppVersionsForm.defaultProps = {
  editMode: false,
  data: {}
};

MobileAppVersionsForm.propTypes = {
  handleSubmit: PT.func.isRequired,
  data: PT.object,
  editMode: PT.bool
};

export default MobileAppVersionsForm;
