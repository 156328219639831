import React from 'react';
import PT from 'prop-types';

import Typography from '@components/shared/Typography/Typography';
import Table from '@components/shared/Table/Table';
import Box from '@components/shared/Box/Box';

const ImportCSVInfo = ({
  requiredFields,
  sampleFileFormat,
  hasOptionalFields = false,
  tableColumns = ['#', 'Field Name', 'Field Format', 'Comments']
}) => {
  const SampleFileFormat = () => {
    if (typeof sampleFileFormat === 'function') {
      return sampleFileFormat();
    } else {
      return (
        <>
          {sampleFileFormat.names.toString() + ','}
          <br />
          {sampleFileFormat.values.toString() + ','}
        </>
      );
    }
  };

  return (
    <Box my={1}>
      <Typography variant="body2" my={1}>
        The file must include {hasOptionalFields ? 'at least ONE of ' : ''} the
        required columns specified below and can contain other columns.
        <br />
        The first line of the CSV file is considered the header line which
        identifies the data in each column and must be in valid CSV format.
      </Typography>
      <Box my={2}>
        <Typography variant="h6" mb={1}>
          CSV file data format
        </Typography>
        <Table columns={tableColumns} rows={requiredFields} maxWidth="450px" />
      </Box>
      <Box my={2}>
        <Typography variant="h6">Sample File Format</Typography>
        <Typography variant="body2">
          <SampleFileFormat />
        </Typography>
      </Box>
    </Box>
  );
};

ImportCSVInfo.propTypes = {
  requiredFields: PT.arrayOf(PT.shape({})).isRequired,
  sampleFileFormat: PT.shape({
    names: PT.arrayOf(PT.string),
    values: PT.arrayOf(PT.string)
  }).isRequired,
  hasOptionalFields: PT.bool,
  tableColumns: PT.arrayOf(PT.string)
};

export default ImportCSVInfo;
