import React from 'react';
import { Link } from 'react-router-dom';
import startCase from 'lodash/startCase';

import UserEventsLink from '@components/shared/UserEventsLink';
import routes from '@constants/routes';
import { cleanObject } from '@utils/object-cleaner';
import helpers from '@utils/helpers';
import {
  filterOperators,
  noFieldData,
  permissionsMap
} from '@constants/common';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import { fullNameColumnWidth } from '@components/shared/DataTable/DataTable.constants';

export const initialFilterParams = cleanObject({});

export const getColumns =
  (pointOfOriginationList, paymentProvidersList, orderStatusesList) => () => [
    {
      headerName: 'Loan ID',
      field: 'loanId',
      minWidth: 90,
      flex: 1,
      type: muiDataTableTypes.number,
      renderCell: ({ row }) =>
        helpers.getActionPermission(permissionsMap.applicationView) &&
        row.applicationId &&
        row.loanId ? (
          <Link to={routes.applications.page.view.url(row.applicationId)}>
            {row.loanId}
          </Link>
        ) : (
          row.loanId || noFieldData
        )
    },
    {
      headerName: 'Payment ID',
      field: 'paymentId',
      minWidth: 90,
      flex: 1,
      renderCell: ({ row }) => row.paymentId,
      type: muiDataTableTypes.number
    },
    {
      headerName: 'Payment Processor',
      field: 'paymentProviderName',
      minWidth: 150,
      flex: 1,
      type: muiDataTableTypes.singleSelect,
      valueOptions: paymentProvidersList,
      renderCell: ({ row, field }) => startCase(row[field])
    },
    {
      headerName: 'Payment Amount',
      field: 'paymentAmount',
      minWidth: 130,
      flex: 1,
      renderCell: ({ row }) => row.paymentAmount,
      type: muiDataTableTypes.number
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 100,
      flex: 1,
      type: muiDataTableTypes.singleSelect,
      valueOptions: orderStatusesList,
      renderCell: ({ row, field }) => startCase(row[field])
    },
    {
      headerName: 'Payment type',
      field: 'paymentType',
      minWidth: 130,
      flex: 1,
      type: muiDataTableTypes.singleSelect,
      valueOptions: [
        { label: 'Cash', value: 'Cash' },
        { label: 'Check', value: 'Check' },
        { label: 'Ach', value: 'Ach' },
        { label: 'DebitCard', value: 'DebitCard' },
        { label: 'MoneyOrder', value: 'MoneyOrder' },
        { label: 'PrepaidCard', value: 'PrepaidCard' },
        { label: 'ECheck', value: 'ECheck' },
        { label: 'LoanTransfer', value: 'LoanTransfer' }
      ],
      renderCell: ({ row, field }) => startCase(row[field])
    },
    {
      headerName: 'Point Of Origination',
      field: 'pointOfOrigination',
      minWidth: 100,
      flex: 1,
      type: muiDataTableTypes.singleSelect,
      valueOptions: pointOfOriginationList,
      renderCell: ({ row, field }) => startCase(row[field])
    },
    {
      headerName: 'Customer email',
      exportField: 'customer.email',
      field: 'customer/email',
      minWidth: 310,
      flex: 1,
      renderCell: ({ row }) =>
        helpers.getActionPermission(permissionsMap.usersView) &&
        row.userId &&
        row.customer?.email ? (
          <Tooltip title="User page">
            <Link to={routes.users.page.view.url(row.userId)}>
              {row.customer.email}
            </Link>
          </Tooltip>
        ) : (
          row.customer?.email || noFieldData
        )
    },
    {
      headerName: 'Full name',
      exportField: 'customer.firstName',
      minWidth: fullNameColumnWidth,
      flex: 1,
      field: 'customer/firstName',
      renderCell: ({ row }) =>
        helpers.getActionPermission(permissionsMap.usersView) ? (
          <Tooltip title="User page">
            <Link to={routes.users.page.view.url(row.userId)}>
              {helpers.getDisplayName(
                row.customer?.firstName,
                row.customer?.lastName
              )}
            </Link>
          </Tooltip>
        ) : (
          helpers.getDisplayName(
            row.customer?.firstName,
            row.customer?.lastName
          )
        ),
      type: muiDataTableTypes.fullName,
      filterOperators: [filterOperators.fullName]
    },
    {
      headerName: 'Scheduled At',
      field: 'createdDate',
      minWidth: 190,
      flex: 1,
      valueFormatter: (createdDate) => helpers.getFormattedDate(createdDate),
      renderCell: ({ row }) => helpers.getFormattedDate(row.createdDate),
      type: muiDataTableTypes.dateRange,
      filterOperators: [filterOperators.dateRange]
    },
    {
      headerName: 'Updated At',
      field: 'updatedDate',
      minWidth: 190,
      flex: 1,
      valueFormatter: (updatedDate) => helpers.getFormattedDate(updatedDate),
      renderCell: ({ row }) => helpers.getFormattedDate(row.updatedDate),
      type: muiDataTableTypes.dateRange,
      filterOperators: [filterOperators.dateRange]
    },
    {
      headerName: 'Reference №',
      field: 'referenceNumber',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => row.referenceNumber || noFieldData
    },
    {
      headerName: 'Account number',
      field: 'accountNumber',
      minWidth: 220,
      flex: 1,
      renderCell: ({ row }) => row.accountNumber || noFieldData
    },
    {
      headerName: 'Transaction Key',
      field: 'transactionKey',
      minWidth: 190,
      flex: 1,
      renderCell: ({ row }) => row.transactionKey || noFieldData
    },
    {
      headerName: 'Decline reason',
      field: 'declineReason',
      minWidth: 310,
      flex: 1,
      renderCell: ({ row }) =>
        row.declineReason ? (
          <Tooltip title={row.declineReason}>{row.declineReason}</Tooltip>
        ) : (
          noFieldData
        )
    },
    {
      headerName: 'Events',
      field: 'userId',
      minWidth: 90,
      flex: 1,
      renderCell: ({ row }) =>
        helpers.getActionPermission(permissionsMap.loggedView) ? (
          <UserEventsLink executedBy={row.userId}>
            {helpers.cutString(row.userId, 5)}
          </UserEventsLink>
        ) : (
          row.userId || noFieldData
        )
    }
  ];
