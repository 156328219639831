import dayjs from 'dayjs';

import { cleanObject } from '@utils/object-cleaner';
import helpers from '@utils/helpers';
import {
  ipAddressColumnWidth,
  timeDateColumnWidth
} from '@components/shared/DataTable/DataTable.constants';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import { defaultPageSize, filterOperators } from '@constants/common';

export const initialFilterParams = cleanObject({
  loggedEventId: {
    eq: ''
  },
  remoteIpAddress: {
    contains: ''
  },
  returnCode: {
    eq: ''
  },
  timeStamp: {
    ge: dayjs().subtract(1, 'day'),
    le: dayjs().endOf('day')
  },
  requestedUrl: {
    contains: ''
  },
  executedBy: {
    contains: ''
  }
});

export const loggedEventsExtraParams = {
  $top: defaultPageSize,
  $skip: 0,
  $count: true,
  $orderBy: 'timeStamp desc',
  $filter: initialFilterParams
};

export const getColumns = () => [
  {
    headerName: 'Event id',
    field: 'loggedEventId',
    minWidth: 90,
    flex: 1,
    renderCell: ({ row }) => row.loggedEventId,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'IP address',
    field: 'remoteIpAddress',
    minWidth: ipAddressColumnWidth,
    flex: 1
  },
  {
    headerName: 'Message',
    field: 'message',
    minWidth: 200,
    flex: 1
  },
  {
    headerName: 'Return code',
    field: 'returnCode',
    minWidth: 100,
    flex: 1,
    renderCell: ({ row }) => row.returnCode,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Time stamp',
    field: 'timeStamp',
    minWidth: timeDateColumnWidth,
    flex: 1,
    valueFormatter: (updatedDate) => helpers.getFormattedDate(updatedDate),
    renderCell: ({ row }) => helpers.getFormattedDate(row.timeStamp),
    type: muiDataTableTypes.dateRange,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Http method',
    field: 'httpMethod',
    minWidth: 100,
    flex: 1,
    type: muiDataTableTypes.singleSelect,
    valueOptions: [
      { label: 'POST', value: 'POST' },
      { label: 'GET', value: 'GET' },
      { label: 'DELETE', value: 'DELETE' },
      { label: 'PUT', value: 'PUT' },
      { label: 'OPTIONS', value: 'OPTIONS' }
    ],
    renderCell: ({ row }) => row.httpMethod
  },
  {
    headerName: 'Requested url',
    field: 'requestedUrl',
    minWidth: 350,
    flex: 1
  },
  {
    headerName: 'Requested url mask',
    field: 'requestedUrlMask',
    minWidth: 350,
    flex: 1
  },
  {
    headerName: 'Executed by',
    field: 'executedBy',
    minWidth: 290,
    flex: 1
  }
];
