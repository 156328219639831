import React from 'react';

import blackCarUrl from '@assets/images/black-car.png';
import CupIcon from '@assets/icons/cup.svg';
import ProfileIcon from '@assets/icons/profile.svg';
import goldenCarUrl from '@assets/images/golden-car.png';
import bronzeCarUrl from '@assets/images/bronze-car.png';
import silverCarUrl from '@assets/images/silver-car.png';

export const teamGoalsColumns = [
  {
    key: 'name',
    title: ''
  },
  {
    key: 'dailyGoal',
    title: 'Daily'
  },
  {
    key: 'weeklyGoal',
    title: 'Weekly'
  },
  {
    key: 'monthlyGoal',
    title: 'Monthly'
  }
];

export const loanProcessingColumns = [
  {
    key: 'name',
    title: ''
  },
  {
    key: 'leadBuyValue',
    title: 'LeadBuy leads'
  },
  {
    key: 'organicValue',
    title: 'Organic leads'
  }
];

export const collectionColumns = [
  {
    key: 'name',
    title: ''
  },
  {
    key: 'leadBuyValue',
    title: 'LeadBuy leads'
  },
  {
    key: 'organicValue',
    title: 'Organic leads'
  }
];

export const pageTypes = {
  loanOriginators: 'loanOriginators',
  collectors: 'collectors'
};

const WIN_PLACES = [1, 2, 3];

const getWidth = (array, score, addition = 0) => {
  const biggestScore = Math.max(...array.map((item) => item.score));
  const biggestScoreWithGap = biggestScore + biggestScore * 0.2;
  return `${Math.round((score * 100) / biggestScoreWithGap + addition)}%`;
};

const gradients = {
  1: 'linear-gradient(180deg, #FFEE53 0%, #E39600 100%)',
  2: 'linear-gradient(180deg, #DFDFDF 0%, #B9B9B9 100%)',
  3: 'linear-gradient(180deg, #D18459 0%, #8E5839 100%)'
};

const getGradient = (place) => gradients[place] || 'rgba(255, 255, 255, 0.32)';

const raceIcons = {
  [pageTypes.loanOriginators]: {
    1: goldenCarUrl,
    2: silverCarUrl,
    3: bronzeCarUrl,
    default: blackCarUrl
  }
};

const getRaceIcon = (place, pageType) => {
  const iconSrc = raceIcons[pageType];
  return iconSrc[place] || iconSrc.default;
};

const backgroundGradients = {
  [pageTypes.loanOriginators]: {
    1: 'linear-gradient(90deg, #461E3B 0%, #D63EBD 100%)'
  },
  [pageTypes.collectors]: {
    1: 'linear-gradient(270deg, #F98341 3.72%, #FF3030 100%)'
  },
  default: {
    1: 'linear-gradient(90deg, #461E3B 0%, #D63EBD 100%)'
  }
};

const getBackgroundGradient = (place, pageType) => {
  const gradients =
    backgroundGradients[pageType] || backgroundGradients.default;
  return (
    gradients[place] ||
    (pageType === pageTypes.loanOriginators
      ? 'linear-gradient(90deg, #1E4620 0%, #32983A 100%)'
      : 'linear-gradient(90deg, #212580 0%, #38B7FF 100%)')
  );
};

export const getConfig = (resp, pageType) =>
  resp?.map((item) => ({
    ...item,
    icon:
      item.place === 1 ? (
        <>
          <CupIcon className="cup-icon" />
        </>
      ) : (
        item.place
      ),
    iconBackground: getGradient(item.place),
    profileIcon: ProfileIcon,
    backgroundGradient: getBackgroundGradient(item.place, pageType),
    accentGradient: getGradient(item.place),
    width: getWidth(resp, item.score),
    accentWidth: WIN_PLACES.includes(item.place)
      ? getWidth(resp, item.score, 2)
      : 0,
    raceIconSrc: getRaceIcon(item.place, pageType),
    pageType: pageType
  }));

export const timePeriods = {
  day: 'Day',
  week: 'Week',
  month: 'Month'
};

export const getLeaderScore = (data) => ({
  name: data?.agentName,
  prevDayScore: data?.loansFundedPreviousPeriod,
  beforePrevDayScore: data?.loansFundedBeforePreviousPeriod,
  currentDayScore: data?.productions[0]?.loansFunded
});
