import {
  COLUMN_DROPDOWN_FILTER_NULL_OPTION_VALUE,
  cellClassNameRed
} from '@components/shared/DataTable/DataTable.constants';
import {
  formatValueWithCurrency,
  formatValueWithPercentage
} from '@components/shared/DataTable/DataTable.utils';

export const mainLeadProviderId = 0;
export const excludeExportFields = ['action', 'groupsName'];
export const isActiveProvidersKey = 'isActiveProviders';
export const isActiveCampaignsKey = 'isActiveCampaigns';

export const decisionColumnValueOptions = [
  { label: 'Declined', value: 'Declined' },
  { label: 'Approved', value: 'Approved' },
  { label: 'Empty', value: COLUMN_DROPDOWN_FILTER_NULL_OPTION_VALUE }
];

export const commonLeadProviderStatsColumns = [
  {
    headerName: 'Day look #',
    field: 'dailyLookCount',
    width: 80,
    cellClassName: ({ row }) => row.dailyLookCountCapReached && cellClassNameRed
  },
  {
    headerName: 'Day look cap',
    field: 'dailyLookCap',
    width: 95,
    cellClassName: ({ row }) =>
      row.dailyLookCountCapReached && cellClassNameRed,
    hide: true
  },
  {
    headerName: 'Hr look #',
    field: 'hourlyLookCount',
    width: 70,
    cellClassName: ({ row }) =>
      row.hourlyLookCountCapReached && cellClassNameRed
  },
  {
    headerName: 'Hr look cap',
    field: 'hourlyLookCap',
    width: 85,
    cellClassName: ({ row }) =>
      row.hourlyLookCountCapReached && cellClassNameRed,
    hide: true
  },
  {
    headerName: 'Day accept #',
    field: 'dailyAcceptCount',
    width: 95,
    cellClassName: ({ row }) => row.dailyAcceptCapReached && cellClassNameRed
  },
  {
    headerName: 'Day accept cap',
    field: 'dailyAcceptCap',
    width: 110,
    cellClassName: ({ row }) => row.dailyAcceptCapReached && cellClassNameRed,
    hide: true
  },
  {
    headerName: 'Day accept %',
    field: 'dailyAcceptRate',
    width: 100,
    valueFormatter: formatValueWithPercentage,
    cellClassName: ({ row }) =>
      row.hourlyAcceptCountCapReached && cellClassNameRed
  },
  {
    headerName: 'Hr accept #',
    field: 'hourlyAcceptCount',
    width: 85,
    cellClassName: ({ row }) =>
      row.hourlyAcceptCountCapReached && cellClassNameRed
  },
  {
    headerName: 'Hr accept cap',
    field: 'hourlyAcceptCap',
    width: 100,
    cellClassName: ({ row }) =>
      row.hourlyAcceptCountCapReached && cellClassNameRed,
    hide: true
  },
  {
    headerName: 'Day Cost$',
    field: 'dailyCostCount',
    width: 80,
    cellClassName: ({ row }) => row.dailyCostCapReached && cellClassNameRed,
    valueFormatter: formatValueWithCurrency
  },
  {
    headerName: 'Day cost cap',
    field: 'dailyCostCap',
    width: 95,
    cellClassName: ({ row }) => row.dailyCostCapReached && cellClassNameRed,
    valueFormatter: formatValueWithCurrency,
    hide: true
  },
  {
    headerName: 'Day Redirect #',
    field: 'dailyRedirectsCount',
    width: 105
  },
  {
    headerName: 'Day Redirect %',
    field: 'dailyRedirectRate',
    width: 115,
    valueFormatter: formatValueWithPercentage
  }
];
