import React, { useRef, useState } from 'react';
import PT from 'prop-types';

import TestNotificationForm from '@components/shared/TestNotificationButton/TestNotificationForm';
import Button from '@components/shared/Button/Button';
import Modal from '@components/shared/Modal/Modal';
import toastify from '@utils/toastify';
import { adminApiUrls } from '@constants/adminApiUrls';
import AdminApi from '@utils/adminApi';

const TestNotificationButton = ({ isCustom, notificationId }) => {
  const [testNotificationModal, setTestNotificationModal] = useState(false);
  const downloadInputRef = useRef(null);

  const handleSubmit = async (values) => {
    setTestNotificationModal(false);
    try {
      await AdminApi.post(adminApiUrls.notificationTest(notificationId), {
        userIds: values,
        isCustom
      });

      toastify.success({
        message: 'Notification will send shortly'
      });
    } catch (e) {
      toastify.error({
        message: 'Operation failure'
      });
    }
  };

  return (
    <>
      <a ref={downloadInputRef} style={{ display: 'none' }} />
      <Button onClick={() => setTestNotificationModal(true)} variant="outlined">
        Send test notification
      </Button>
      {testNotificationModal && (
        <Modal
          open={!!testNotificationModal}
          title="Send test notification"
          onClose={() => setTestNotificationModal(false)}
          minContentWidth="90vw"
          withCloseIcon
        >
          <TestNotificationForm onSubmit={handleSubmit} />
        </Modal>
      )}
    </>
  );
};

TestNotificationButton.propTypes = {
  isCustom: PT.bool.isRequired,
  notificationId: PT.number.isRequired
};

export default TestNotificationButton;
