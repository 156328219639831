import React from 'react';
import PT from 'prop-types';
import { useHistory } from 'react-router';

import ExportTableCSV, { exportTypes } from '../ExportTableCSV';

import { getQueryString } from './config';

import {
  leadManagementReportsApiBaseUrl,
  leadManagementReportsApiUrls
} from '@constants/leadManagementReportsApi';

const ExportTable = ({ fields }) => {
  const history = useHistory();

  const getFilename = () => {
    const decodedString = decodeURIComponent(history?.location?.search);

    const regexPattern = /"ge":"([^"]+)","le":"([^"]+)"/;

    const match = decodedString.match(regexPattern);

    if (match) {
      const startDate = match[1];
      const endDate = match[2];

      return `Affiliate Report - Date: ${startDate} - ${endDate}`;
    } else {
      return 'Affiliate Report';
    }
  };

  return (
    <>
      <ExportTableCSV
        exportFileName={getFilename()}
        customApiBaseUrl={leadManagementReportsApiBaseUrl}
        apiUrl={
          leadManagementReportsApiUrls.exportDetails + getQueryString(history)
        }
        fields={fields
          .filter(
            ({ exportField, field }) =>
              exportField !== 'actions' && { exportField, field }
          )
          .map(({ exportField, field }, index) => ({
            field: exportField,
            fieldName: field,
            key: index
          }))}
        type={exportTypes.json}
        title="Export Detail"
        additionalParams={{ root: 'affiliateDetailReportItems' }}
      />
    </>
  );
};

export default ExportTable;

ExportTable.propTypes = {
  apiRef: PT.oneOfType([PT.func, PT.shape({ current: PT.any })]),
  exportOptions: PT.object,
  fields: PT.array.isRequired
};
