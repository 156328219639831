export const militaryStatuses = {
  activeDuty: 'ACTIVE_DUTY',
  activeDutyOnFinancialSupport: 'ACTIVE_DUTY_ON_FINANCIAL_SUPPORT',
  nonActive: 'NON_ACTIVE'
};

export const militaryStatusesDescriptions = {
  [militaryStatuses.activeDuty]:
    'I AM a regular or reserve member of the Army, Navy, Marine Corps, Air Force, ' +
    'or Coast Guard, serving on active duty under a call or order that does not ' +
    'specify a period of 30 days or fewer',
  [militaryStatuses.activeDutyOnFinancialSupport]:
    'I AM a dependent of a member of the Amed Forces on active duty as described ' +
    "above, because I am the member's spouse, the member's child under the age " +
    'of eighteen years old, or l am an individual for whom the member provided ' +
    'more than one-half of my financial support for 180 days immediately preceding ' +
    ' todays date.',
  [militaryStatuses.nonActive]:
    'I AM NOT a regular or reserve member of the Amy, Navy, Marine Corps, Air' +
    ' Force or Coast Guard, serving on active duty under a call or order that' +
    ' does not specity a period of 30 days or fewer (or a dependent of such' +
    ' a member).'
};
