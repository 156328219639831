import React, { useRef } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import qs from 'query-string';

import { tabsIds, tabsList } from '@pages/lead-provider/tabs';
import helpers from '@utils/helpers';
import routes from '@constants/routes';
import SinglePageLayout from '@components/shared/SinglePageLayout';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import Box from '@components/shared/Box/Box';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import {
  getColumns,
  getExportFileName,
  getInitialFilterParams
} from '@components/Leads/LeadReports/config';
import SeparatedDateTimeFilter from '@components/shared/SeparatedDateTimeFilter/SeparatedDateTimeFilter';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

const title = 'Lead reports';
const LeadRequestsPage = () => {
  const history = useHistory();
  const query = qs.parse(history.location.search);
  const exportFileName = getExportFileName(query);
  const tableRef = useRef(null);

  const isLeadProvider = helpers.isLeadProvider();
  if (!isLeadProvider) {
    return <Redirect to={routes.permissionDenied} />;
  }

  return (
    <SinglePageLayout title={title}>
      <Box mt={1}>
        <NavTabsPanel
          currentId={tabsIds.leadRequests}
          tabsList={tabsList}
          ariaLabel="lead-provider-tabs"
        />
        <Box mt={1}>
          <Box mb={2}>
            <SeparatedDateTimeFilter tableRef={tableRef} />
          </Box>
          <ODataPaginatedTable
            tableRef={tableRef}
            getColumns={getColumns}
            customApi={LeadManagementApi}
            usePaginatedDataOptions={{
              apiUrl: leadManagementApiUrls.leadProviderLeadRequests,
              initialFilterParams: {
                $orderBy: 'createdDate desc',
                $filter: getInitialFilterParams()
              }
            }}
            rowKey="leadProviderRequestId"
            exportFileName={exportFileName}
            withRefreshButton
          />
        </Box>
      </Box>
    </SinglePageLayout>
  );
};

export default LeadRequestsPage;
