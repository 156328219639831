import axios from 'axios';

import helpers from '@utils/helpers';
import { API_AUTH_BASE } from '@constants/common';
import { authService } from '@services/auth';

const iamApi = axios.create({
  baseURL: API_AUTH_BASE,
  headers: {
    'Content-Type': 'application/json'
  }
});

iamApi.interceptors.request.use(async (config) => {
  await authService.axiosRequestMiddleware(config.headers);
  config.headers.Authorization = `Bearer ${authService.getToken()}`;

  return config;
});

iamApi.interceptors.response.use(
  (response) => response,
  (error) => {
    void authService.axiosResponseMiddleware(error.response?.status);
    return Promise.reject(helpers.handleServerErrors(error));
  }
);

export default iamApi;
