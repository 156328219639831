import React from 'react';

import Typography from '@components/shared/Typography/Typography';

const NotYetExecutedMessage = () => (
  <Typography
    color="error"
    variant="body2"
    sx={{
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
      whiteSpace: 'nowrap'
    }}
  >
    Not yet executed
  </Typography>
);

export default NotYetExecutedMessage;
