import React from 'react';

import Button from '@components/shared/Button/Button';
import helpers from '@utils/helpers';

export const getColumns = (handleDownload) => [
  {
    headerName: 'Completion date',
    field: 'completedAt',
    width: 200,
    renderCell: ({ row }) => helpers.getFormattedDate(row?.completedAt)
  },
  {
    headerName: 'Inquiry ID',
    field: 'inquiryId',
    width: 200
  },
  {
    headerName: 'Link',
    field: 'name',
    flex: 1,
    renderCell: ({ row }) => (
      <Button
        variant="text"
        onClick={() => {
          handleDownload({
            resourceId: row.resourceId,
            name: row.name
          });
        }}
      >
        {row.name}
      </Button>
    )
  }
];
