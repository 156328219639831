import PT from 'prop-types';
import React, { useMemo, useState } from 'react';

import { getColumns } from './config';

import DataTable from '@components/shared/DataTable/DataTable';
import Typography from '@components/shared/Typography/Typography';
import { isActiveProvidersKey, isActiveCampaignsKey } from '@constants/leads';
import {
  defaultFilterParams,
  useQueryPagination
} from '@hooks/useQueryPagination';
import { leadManagementApiUrls } from '@constants/leadManagementApi';
import LeadManagementApi from '@utils/leadManagementApi';
import ShowCapsButton from '@components/shared/ShowCapsButton/ShowCapsButton';
import Stack from '@components/shared/Stack/Stack';

const paginationOptions = {
  pageSize: 1,
  current: 1,
  additionalPageSize: [1]
};

const GlobalProvidersRates = ({ rowKey, updateKey }) => {
  const [isShownCaps, setIsShownCaps] = useState(true);
  const { allData, isLoading, count, setAllData, refetchList } =
    useQueryPagination({
      customApi: LeadManagementApi,
      listUrl: leadManagementApiUrls.dashboard.leadProvidersRatesGlobal,
      initialFilters: {
        ...defaultFilterParams
      },
      omitParamsArray: [isActiveProvidersKey, isActiveCampaignsKey, 'isActive']
    });

  const handleStatus = async (id, status) => {
    await LeadManagementApi.post(
      leadManagementApiUrls.leadProviders.pauseOnLeadProviderSingle(id),
      {
        pause: status
      }
    );

    refetchList();
    updateKey();
  };

  const columns = useMemo(
    () =>
      getColumns({ handleStatus, setAllData }).filter((item) =>
        isShownCaps ? item : !item.hide
      ),
    [isShownCaps]
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="center"
      mt={1}
      mb={1}
    >
      <Typography variant="h2" mt={3} mb={1}>
        Lead buy total statistic
      </Typography>
      <Stack justifyContent="flex-end" alignItems="flex-end">
        <ShowCapsButton
          setIsShownCaps={setIsShownCaps}
          isShownCaps={isShownCaps}
        />
      </Stack>
      <DataTable
        tableData={allData ? [allData] : []}
        columns={columns}
        rowKey={rowKey}
        loading={isLoading}
        totalAmount={count}
        paginationOptions={paginationOptions}
        disableColumnFilter
        hideFooter
      />
    </Stack>
  );
};

GlobalProvidersRates.propTypes = {
  rowKey: PT.string.isRequired,
  updateKey: PT.func
};

export default GlobalProvidersRates;
