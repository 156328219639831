import React, { useEffect, useState } from 'react';

import { WallboardLayout } from '@components/Wallboard/WallboardLayout/WallboardLayout';
import { TopFive } from '@components/Wallboard/TopFive/TopFive';
import Loader from '@components/shared/Loader';
import {
  getConfig,
  getLeaderScore,
  pageTypes,
  timePeriods
} from '@components/Wallboard/config';
import { useSetWallboardToken } from '@hooks/useSetWallboardToken';
import WallboardApi from '@utils/wallboardAPi';
import { wallboardApiUrls } from '@constants/wallboardApiUrls';
import Logger from '@utils/logger';

const LoanOriginatorsDaily = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useSetWallboardToken();

  const getData = async () => {
    try {
      setLoading(true);
      const response = await WallboardApi.get(wallboardApiUrls.productionDaily);
      setData(response);
    } catch (error) {
      Logger.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const refactoredData = data?.productions.length
    ? data?.productions.map((item) => ({
        place: parseInt(item.rank),
        person: item.agentName,
        score: item.loansFunded
      }))
    : [];

  const config = getConfig(refactoredData, pageTypes.loanOriginators);

  return (
    <WallboardLayout title="Top 5 Loan Originators Daily">
      {loading && <Loader />}
      {!loading && (
        <TopFive
          config={config}
          timePeriod={timePeriods.day.toLowerCase()}
          leaderData={getLeaderScore(data)}
        />
      )}
    </WallboardLayout>
  );
};

export default LoanOriginatorsDaily;
