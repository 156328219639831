import React from 'react';
import dayjs from 'dayjs';

import { performancePageConfig } from '@components/Dashboard/InternalPerformance/config';
import InternalPerformance from '@components/Dashboard/InternalPerformance/InternalPerformance';

const DashboardDBPerformancePage = () => {
  const dbConfig = performancePageConfig.db;
  const shouldDisableDate = (date) => {
    const today = dayjs();
    const twoDaysAgo = today.subtract(2, 'day');
    return date.isAfter(today, 'day') || date.isBefore(twoDaysAgo, 'day');
  };
  const initialFilterValues = { startDate: dayjs().subtract(2, 'days') };
  return (
    <InternalPerformance
      {...dbConfig}
      shouldDisableDate={shouldDisableDate}
      initialFilterValues={initialFilterValues}
    />
  );
};

export default DashboardDBPerformancePage;
