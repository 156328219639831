import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { useHistory } from 'react-router-dom';

import { loanFormStepsOrder } from '@constants/loanFormConfig';
import Logger from '@utils/logger';
import Loader from '@components/shared/Loader';
import { formHasErrorsMessage } from '@constants/common';
import routes from '@constants/routes';
import AdminApi from '@utils/adminApi';

const withStepData = (Component) => {
  const WrappedComponent = ({
    activeStep,
    stepsConfig,
    isPublicForm,
    amountEndpoint,
    applicationData,
    lastFilledStep,
    setActiveStep,
    ...props
  }) => {
    const history = useHistory();
    const [initialValues, setInitialValues] = useState(null);
    const [options, setOptions] = useState(null);

    const stepApi = stepsConfig[activeStep].apiEndpoints;
    const currentStepIdx = loanFormStepsOrder.indexOf(activeStep);
    const isLastStepIdx = loanFormStepsOrder.length - 1 === currentStepIdx;
    const isFilledStep =
      loanFormStepsOrder.indexOf(lastFilledStep) >= currentStepIdx;

    const nextStep = () => {
      setActiveStep(loanFormStepsOrder[currentStepIdx + 1]);
    };

    const completeStep = () => {
      setActiveStep(loanFormStepsOrder[loanFormStepsOrder.length - 1]);
    };

    useEffect(() => {
      (async () => {
        try {
          const { amount } = await AdminApi.get(amountEndpoint);

          const data = await AdminApi.get(stepApi.url);

          setInitialValues({
            ...(data || {}),
            amount
          });
        } catch (e) {
          Logger.error(e);
        }
      })();
    }, []);

    useEffect(() => {
      if (stepApi.options) {
        (async () => {
          try {
            const response = await AdminApi.get(stepApi.options);
            setOptions(response);
          } catch (e) {
            Logger.error(e);
          }
        })();
      }
    }, []);

    const handleSubmit = async ({ values, initialValues }, formActions) => {
      formActions.setSubmitting(true);

      try {
        if (!isFilledStep || +values?.amount !== +initialValues?.amount) {
          await AdminApi.put(amountEndpoint, { amount: values?.amount });
        }

        if (values !== initialValues) {
          await AdminApi.put(stepApi.url, values);
        }

        if (
          loanFormStepsOrder.indexOf(lastFilledStep) >=
          loanFormStepsOrder.indexOf(activeStep)
        ) {
          nextStep();
        } else {
          completeStep();
        }

        if (isLastStepIdx) {
          history.push(
            routes.applications.page.view.url(applicationData.applicationId)
          );
        }
      } catch (e) {
        Logger.error(e);
        formActions.setErrors({
          ...e,
          nonFieldErrors: e.nonFieldErrors || e.message || formHasErrorsMessage
        });
      } finally {
        formActions.setSubmitting(false);
      }
    };

    if (!initialValues || (!options && stepApi.options)) {
      return <Loader />;
    }

    return (
      <Component
        {...props}
        initialValues={initialValues}
        options={options}
        isFilledStep={isFilledStep}
        stepEndpointsConfig={stepApi}
        amountEndpoint={amountEndpoint}
        isPublicForm={isPublicForm}
        onSubmit={handleSubmit}
      />
    );
  };

  WrappedComponent.defaultProps = {
    isPublicForm: false
  };

  WrappedComponent.propTypes = {
    activeStep: PT.string.isRequired,
    amountEndpoint: PT.string.isRequired,
    stepsConfig: PT.objectOf(
      PT.shape({
        component: PT.any,
        title: PT.string,
        apiEndpoints: PT.object
      })
    ),
    isPublicForm: PT.bool,
    lastFilledStep: PT.string,
    applicationData: PT.object.isRequired,
    setActiveStep: PT.func.isRequired
  };

  return WrappedComponent;
};

export default withStepData;
