import PT from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { getColumns } from './config';

import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import Loader from '@components/shared/Loader';
import { cleanObject } from '@utils/object-cleaner';
import routes from '@constants/routes';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const initialFilterParams = cleanObject({});

const LoginAttempts = ({ id }) => {
  const history = useHistory();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    history.push({
      pathname: routes.users.page.view.url(id),
      search: ''
    });
  }, []);

  useEffect(() => {
    if (!history.location.search) {
      setLoader(false);
    }
  }, [history.location?.search]);

  if (loader) return <Loader />;

  return (
    <ODataPaginatedTable
      getColumns={getColumns}
      usePaginatedDataOptions={{
        apiUrl: adminApiUrls.loginAttempts,
        initialFilterParams: {
          $orderBy: 'loginDate desc',
          $filter: {
            ...initialFilterParams,
            userId: {
              eq: id
            }
          }
        }
      }}
      rowKey="loginId"
      exportFileName={`Login attempts by user ${id}`}
      customApi={AdminApi}
    />
  );
};

LoginAttempts.propTypes = {
  id: PT.string.isRequired
};

export default LoginAttempts;
