import React, { useMemo, useState } from 'react';
import PT from 'prop-types';
import Typography from '@mui/material/Typography';

import {
  leadManagementApiBaseUrl,
  leadManagementApiUrls
} from '@constants/leadManagementApi';
import CampaignsDataTable from '@components/Leads/CampaignsDataTable';
import { useLeadStatsData } from '@components/Leads/leadProviders/Details/LeadStatsList/useLeadStatsData';
import LeadDayHourFilterSeparated from '@components/Leads/DayHourFilterSeparated';
import {
  getCampaignGroupColumns,
  getCampaignsAdditionalColumns
} from '@components/Leads/campaignGroups/Details/CampaignsStats/config';
import ExportTableCSV, { exportTypes } from '@components/shared/ExportTableCSV';
import { excludeExportFields } from '@constants/leads';
import Stack from '@components/shared/Stack/Stack';
import DataTable from '@components/shared/DataTable/DataTable';
import LeadManagementApi from '@utils/leadManagementApi';
import ShowCapsButton from '@components/shared/ShowCapsButton/ShowCapsButton';

const CampaignsStats = ({ campaignGroupId }) => {
  const [isShownCaps, setIsShownCaps] = useState(true);

  const campaignsStatisticsUrl =
    leadManagementApiUrls.campaignGroup.campaignStatistic(campaignGroupId);

  const leadStatsDataSet = useLeadStatsData(campaignsStatisticsUrl);
  const isCampaignGroupDataLoading = leadStatsDataSet.isLeadProviderDataLoading;
  const campaignGroupData = leadStatsDataSet.leadProviderData;
  const refetchCampaignGroupData = leadStatsDataSet.refetchLeadProviderData;

  const useExport = (columns) => (
    <Stack
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="flex-end"
    >
      <Typography variant="h4" mt={2}>
        Campaigns
      </Typography>
      <ExportTableCSV
        apiUrl={campaignsStatisticsUrl}
        customApiBaseUrl={leadManagementApiBaseUrl}
        fields={columns
          .filter(({ field }) => !excludeExportFields.includes(field) && field)
          .map(({ field }, index) => ({
            field,
            key: index
          }))}
        type={exportTypes.json}
      />
    </Stack>
  );

  const handleStatus = async (id, status) => {
    await LeadManagementApi.post(
      leadManagementApiUrls.campaignGroups.pauseOnCampaignGroupSingle(id),
      {
        pause: status
      }
    );

    await refetchCampaignGroupData();
  };

  const campaignGroupColumns = useMemo(
    () =>
      getCampaignGroupColumns(handleStatus).filter((item) =>
        isShownCaps ? item : !item.hide
      ),
    [isShownCaps]
  );

  return (
    <>
      <LeadDayHourFilterSeparated>
        <ShowCapsButton
          setIsShownCaps={setIsShownCaps}
          isShownCaps={isShownCaps}
        />
      </LeadDayHourFilterSeparated>
      <DataTable
        disableColumnFilter
        columns={campaignGroupColumns}
        tableData={campaignGroupData ? [campaignGroupData] : []}
        rowKey="campaignGroupId"
        loading={isCampaignGroupDataLoading}
        refetchList={refetchCampaignGroupData}
        pagination={false}
        hideFooter
      />
      <CampaignsDataTable
        tableData={campaignGroupData?.campaigns || []}
        refetchList={refetchCampaignGroupData}
        additionalColumns={{
          startColumns: [...getCampaignsAdditionalColumns()],
          endColumns: []
        }}
        useExport={useExport}
        isShownCaps={isShownCaps}
      />
    </>
  );
};

CampaignsStats.propTypes = {
  campaignGroupId: PT.number.isRequired
};

export default CampaignsStats;
