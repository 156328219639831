const paymentProcessingApiBaseUrl =
  process.env.PAYMENT_PROCESSING_API_URL || '';

const paymentProcessingApiUrls = {
  batchProcessingsSettings: {
    list: '/odata/BatchProcessings',
    deleteUploadedFile: (batchProcessingId) =>
      `/odata/BatchProcessings(${batchProcessingId})`,
    updateExecutionTime: (batchProcessingId) =>
      `/odata/BatchProcessings(${batchProcessingId})`
  },
  unprocessedPayments: {
    uploadCSV: '/api/unprocessed-payments/upload',
    list: '/odata/UnprocessedPayments'
  },
  common: {
    paymentProviders: '/api/common/payments/payment-provider-type-name',
    pointOfOrigination: '/api/common/payments/points-of-origination',
    orderStatuses: '/api/common/payments/order-statuses',
    declineActions: '/api/common/decline-actions',
    originalDeclineReasons: '/api/common/original-decline-reasons'
  },
  declineReasons: {
    list: '/odata/DeclineReasons',
    updateAction: (declineReasonId) =>
      `/odata/DeclineReasons(${declineReasonId})/Default.UpdateAction`
  },
  paymentsRerun: {
    performance: '/odata/PaymentsRerunPerformances',
    list: '/odata/ReversedPayments',
    settings: {
      list: '/api/rerun-schedules',
      item: (id) => `/api/rerun-schedules/${id}`
    }
  },
  scheduledPayments: {
    performance: '/odata/ScheduledPaymentsPerformances',
    list: '/odata/ScheduledPayments',
    settings: {
      list: '/api/scheduled-payments-schedules',
      item: (id) => `/api/scheduled-payments-schedules/${id}`
    }
  }
};

export { paymentProcessingApiBaseUrl, paymentProcessingApiUrls };
