import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import qs from 'query-string';

import {
  getFilterInitialStateMissingByStore,
  getMissingByStoreColumns,
  subTabsList
} from '@components/DiscrepancyReport/DiscrepancyReport.config';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import Stack from '@components/shared/Stack/Stack';
import Typography from '@components/shared/Typography/Typography';
import { secureVaultReportsApiUrls } from '@constants/secureVaultReportsApiUrls';
import SecureVaultReportsApi from '@utils/secureVaultReportsApi';
import DataTable from '@components/shared/DataTable/DataTable';
import Logger from '@utils/logger';
import Loader from '@components/shared/Loader';
import Alert from '@components/shared/Alert/Alert';

const DiscrepancySummaryReportByStore = () => {
  const [initialFilters] = useState(getFilterInitialStateMissingByStore());
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();
  const columns = getMissingByStoreColumns();

  const fetchTableData = async () => {
    try {
      if (!history.location.search) {
        history.replace({
          search: qs.stringify(initialFilters)
        });
      } else {
        setData(null);
        const parsedParams = qs.parse(history.location.search, {
          parseBooleans: true,
          parseNumbers: true
        });
        const response = await SecureVaultReportsApi.get(
          secureVaultReportsApiUrls.discrepancyReport
            .summaryReportMissingByStore,
          {
            params: parsedParams
          }
        );
        setData(response);
      }
    } catch (e) {
      Logger.error(e);
      setError(e);
    }
  };

  useEffect(() => {
    if (history.location) {
      fetchTableData();
    }
  }, [history.location?.search]);

  return (
    <MainLayout title="Discrepancy Report">
      <NavTabsPanel
        currentIdx={2}
        tabsList={subTabsList}
        ariaLabel="access-tabs"
      />
      <Stack sx={{ mt: 1 }}>
        <Typography variant="h3">Missing by Store</Typography>
      </Stack>
      {!data && !error && <Loader />}
      {data && (
        <DataTable
          disableColumnFilter
          columns={columns}
          tableData={data}
          rowKey="id"
          detailPanelHeight="auto"
          pagination={false}
          hideFooter
        />
      )}
      {error && (
        <Alert
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
        />
      )}
    </MainLayout>
  );
};

export default DiscrepancySummaryReportByStore;
