import pick from 'lodash/pick';
import * as Yup from 'yup';

const initialValues = {
  dailyLookCap: '',
  hourlyLookCap: '',
  dailyAcceptCap: '',
  hourlyAcceptCap: '',
  dailyCostCap: ''
};
export const getInitialValues = (prefilledValues) => ({
  ...initialValues,
  ...pick(prefilledValues, Object.keys(initialValues))
});

const integerOrDecimalNumberRegex = /^(\d+|\d+\.\d+)$/;
const fieldSchema = (label) =>
  Yup.string()
    .test(
      'positive-number',
      '${path} value is invalid',
      (value) => integerOrDecimalNumberRegex.test(value) && +value > 0
    )
    .label(label);

export const validationSchema = Yup.object().shape({
  dailyLookCap: fieldSchema('Daily Look Cap'),
  hourlyLookCap: fieldSchema('Hourly Look Cap'),
  dailyAcceptCap: fieldSchema('Daily Accept Cap'),
  hourlyAcceptCap: fieldSchema('Hourly Accept Cap'),
  dailyCostCap: fieldSchema('Daily Cost Cap')
});
