import React from 'react';

import helpers from '@utils/helpers';
import { filterOperators, noFieldData } from '@constants/common';
import { timeDateColumnWidth } from '@components/shared/DataTable/DataTable.constants';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import { decisionColumnValueOptions } from '@constants/leads';
import { formatValueWithCurrency } from '@components/shared/DataTable/DataTable.utils';

export const communicationLogsColumns = [
  {
    headerName: 'Received Date',
    field: 'leadReceivedDate',
    minWidth: timeDateColumnWidth,
    flex: 1,
    type: muiDataTableTypes.dateRange,
    valueFormatter: (leadReceivedDate) =>
      helpers.getFormattedDate(leadReceivedDate),
    renderCell: ({ row }) => helpers.getFormattedDate(row.leadReceivedDate),
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Lokyata delay (Sec)',
    field: 'responseTime',
    minWidth: 160,
    flex: 1,
    type: muiDataTableTypes.number,
    renderCell: ({ row }) => +row.responseTime + 's'
  },
  {
    headerName: 'Total delay (Sec)',
    field: 'delayLead',
    minWidth: 160,
    flex: 1,
    type: muiDataTableTypes.number,
    renderCell: ({ row }) => +row.delayLead + 's'
  },
  {
    headerName: 'Decision',
    field: 'decision',
    minWidth: 240,
    flex: 1,
    type: 'singleSelect',
    valueOptions: decisionColumnValueOptions,
    renderCell: ({ row }) => (
      <Tooltip title={row.decision}>{row.decision || noFieldData}</Tooltip>
    )
  },
  {
    headerName: 'Approval Date',
    field: 'approvalDate',
    minWidth: timeDateColumnWidth,
    flex: 1,
    type: muiDataTableTypes.dateRange,
    valueFormatter: (approvalDate) => helpers.getFormattedDate(approvalDate),
    renderCell: ({ row }) =>
      helpers.getFormattedDate(row.approvalDate) || noFieldData,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Approved Price',
    field: 'approvedPrice',
    width: 140,
    valueFormatter: formatValueWithCurrency,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Denial reason',
    field: 'denialReason',
    minWidth: 240,
    flex: 1,
    renderCell: ({ row, field }) => (
      <Tooltip title={row[field]}>{row[field] || noFieldData}</Tooltip>
    )
  },
  {
    headerName: 'Error',
    field: 'errorMessage',
    minWidth: 500,
    flex: 1,
    renderCell: ({ row, field }) => (
      <Tooltip title={row[field]}>{row[field] || noFieldData}</Tooltip>
    )
  }
];
