import { styled } from '@mui/material';

import Box from '@components/shared/Box/Box';
import { palette } from '@utils/theme';

export const StyledBox = styled(Box)`
  && {
    margin-top: 6px;
    margin-bottom: 6px;
    padding: 6px 12px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${palette.border};
  }
`;
