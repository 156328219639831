import React, { useEffect, useState } from 'react';

import { getColumns, initialFilterParams } from './config';

import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Logger from '@utils/logger';
import { paymentProcessingApiUrls } from '@constants/paymentProcessingApiUrls';
import PaymentProcessingApi from '@utils/paymentProcessingApi';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const PaymentsListPage = () => {
  const [pointOfOriginationList, setPointOfOriginationList] = useState([]);
  const [paymentProvidersList, setPaymentProvidersList] = useState([]);
  const [orderStatusesList, setOrderStatusesList] = useState([]);

  useEffect(() => {
    const getAdditionData = async () => {
      try {
        const [paymentProviders, pointsOfOrigination, orderStatuses] =
          await Promise.all([
            PaymentProcessingApi.get(
              paymentProcessingApiUrls.common.paymentProviders
            ),
            PaymentProcessingApi.get(
              paymentProcessingApiUrls.common.pointOfOrigination
            ),
            PaymentProcessingApi.get(
              paymentProcessingApiUrls.common.orderStatuses
            )
          ]);

        setPaymentProvidersList(createOptionsForSelect(paymentProviders));
        setPointOfOriginationList(createOptionsForSelect(pointsOfOrigination));
        setOrderStatusesList(createOptionsForSelect(orderStatuses));
      } catch (error) {
        Logger.error(error);
      }
    };

    getAdditionData();
  }, []);

  const createOptionsForSelect = (data) =>
    data?.items?.map(({ name }) => ({
      label: name.replace(/([A-Z])/g, ' $1').trim(),
      value: name.replace(' ', '').trim()
    })) || [];

  return (
    <MainLayout title="Payments">
      <ODataPaginatedTable
        getColumns={getColumns(
          pointOfOriginationList,
          paymentProvidersList,
          orderStatusesList
        )}
        usePaginatedDataOptions={{
          apiUrl: adminApiUrls.paymentOrderCustomers,
          initialFilterParams: {
            $orderBy: 'paymentOrderId desc',
            $filter: {
              ...initialFilterParams
            }
          }
        }}
        rowKey="paymentOrderId"
        customApi={AdminApi}
        exportLastNameField="customer.lastName"
      />
    </MainLayout>
  );
};

export default PaymentsListPage;
