import React, { useEffect, useState } from 'react';
import PT from 'prop-types';

import Loader from '@components/shared/Loader';
import Grid from '@components/shared/Grid/Grid';
import { Switch } from '@components/shared/Switch/Switch';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const systemNotificationsParams = {
  $filter: {
    isCustom: {
      eq: false
    }
  }
};

const ManageNotifications = ({ id }) => {
  const [systemNotifications, setSystemNotifications] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    (async () => {
      const systemNotifications = await AdminApi.get(
        adminApiUrls.notificationsUserSettings.page(id),
        {
          params: systemNotificationsParams
        }
      );
      setSystemNotifications(systemNotifications?.userSettings);
    })();
  }, [id]);

  if (!systemNotifications) {
    return <Loader />;
  }

  const handleToggleNotification = async (type) => {
    setIsUpdating(true);
    const newData = systemNotifications.map((item) =>
      item.notificationType === type
        ? { ...item, toBeSent: !item.toBeSent }
        : item
    );
    await AdminApi.put(adminApiUrls.notificationsUserSettings.page(id), {
      userSettings: newData
    });
    setSystemNotifications(newData);
    setIsUpdating(false);
  };

  return (
    <Grid container padding={3} alignItems="center">
      {systemNotifications?.map(
        ({ description, notificationType, toBeSent }, idx) => (
          <React.Fragment key={notificationType}>
            <Grid item xs={8}>
              <h3>{description}</h3>
            </Grid>
            <Grid item xs={4}>
              <Switch
                margin="none"
                checked={toBeSent}
                name={'switch-' + idx}
                onChange={() => handleToggleNotification(notificationType)}
                disabled={isUpdating}
                sx={{ mr: 0 }}
              />
            </Grid>
          </React.Fragment>
        )
      )}
    </Grid>
  );
};

ManageNotifications.propTypes = {
  id: PT.string.isRequired
};

export default ManageNotifications;
