import React, { useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import helpers from '@utils/helpers';
import routes from '@constants/routes';
import SinglePageLayout from '@components/shared/SinglePageLayout';
import Box from '@components/shared/Box/Box';
import Typography from '@components/shared/Typography/Typography';
import { tabsIds, tabsList } from '@pages/lead-provider/tabs';
import NavTabsPanel from '@components/shared/TabsPanel/NavTabsPanel';
import DataTable from '@components/shared/DataTable/DataTable';
import { useServerData } from '@hooks/useServerData';
import { columns } from '@components/Leads/LeadProvidersCampaigns/config';
import ExportTableCSV, { exportTypes } from '@components/shared/ExportTableCSV';
import { excludeExportFields } from '@constants/leads';
import DashboardFilter from '@components/Dashboard/Filter';
import { selectDashboardFilters } from '@store/dashboardFilters';
import {
  leadManagementReportsApiBaseUrl,
  leadManagementReportsApiUrls
} from '@constants/leadManagementReportsApi';
import LeadManagementReportsApi from '@utils/leadManagementReportsApi';
import { setODataDateFilterParams } from '@components/LeadReports/filters/utils';

const title = 'Lead performance';
const CampaignPerformancePage = () => {
  const history = useHistory();
  const params = qs.parse(history.location.search);
  const filtersInitialState = useSelector(selectDashboardFilters);

  let updatedParams = {};
  if (isEmpty(params)) {
    updatedParams = { ...filtersInitialState };
  } else {
    updatedParams = { ...params };
  }

  const oDataFilterObject = {
    $filter: JSON.stringify(
      setODataDateFilterParams(updatedParams.startDate, updatedParams.endDate)
    )
  };

  const { data, isLoading, error, fetchData } = useServerData({
    endpoint: async () =>
      await LeadManagementReportsApi.get(
        leadManagementReportsApiUrls.leadProviderCampaigns,
        { params: oDataFilterObject }
      )
  });

  useEffect(() => {
    if (!isEmpty(updatedParams)) {
      fetchData();
    }
  }, [history.location.search]);

  if (!helpers.isLeadProvider()) {
    return <Redirect to={routes.permissionDenied} />;
  }

  return (
    <SinglePageLayout title={title}>
      <Box mt={1}>
        <NavTabsPanel
          currentId={tabsIds.campaignPerformance}
          tabsList={tabsList}
          ariaLabel="lead-provider-tabs"
        />
        <Box mt={1}>
          <Box mb={2}>
            <DashboardFilter />
          </Box>
          <ExportTableCSV
            customApiBaseUrl={leadManagementReportsApiBaseUrl}
            apiUrl={
              `${leadManagementReportsApiUrls.leadProviderCampaigns}?` +
              helpers.parseCSVODataRequest(oDataFilterObject)
            }
            fields={columns
              .filter(
                ({ field }) => !excludeExportFields.includes(field) && field
              )
              .map(({ field }, index) => ({
                field,
                key: index
              }))}
            type={exportTypes.odata}
          />
          {!error && (
            <DataTable
              rowKey="id"
              tableData={data || []}
              loading={isLoading}
              columns={columns}
              hasError={!!error}
              disableColumnFilter
              hideFooter
            />
          )}
          {!!error && (
            <Typography variant="h6" color="error">
              Something went wrong. Please try again later.
            </Typography>
          )}
        </Box>
      </Box>
    </SinglePageLayout>
  );
};

export default CampaignPerformancePage;
