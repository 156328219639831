import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledAccent,
  StyledCircle,
  StyledContainer,
  StyledCrownIcon,
  StyledCupIcon,
  StyledHeading,
  StyledPaper,
  StyledPaperBox,
  StyledPaperSubTitle,
  StyledPaperTitle,
  StyledTextContainer,
  StyledTitle,
  StyledTitleBox,
  StyledUserIcon
} from './styled';

export const LeaderBox = ({ timePeriod, leaderData }) => {
  const time = {
    day: {
      now: 'Today',
      prev: 'Prev. day',
      beforePrev: '2 days ago'
    },
    week: {
      now: 'This week',
      prev: 'Prev. week',
      beforePrev: '2 weeks ago'
    },
    month: {
      now: 'This months',
      prev: 'Prev. months',
      beforePrev: '2 months ago'
    }
  };

  return (
    <StyledContainer>
      <StyledAccent>
        <StyledTitleBox>
          <StyledCrownIcon />
          <StyledHeading>Current Leader</StyledHeading>
          <StyledCrownIcon />
        </StyledTitleBox>
        <StyledCircle>
          <StyledUserIcon />
          <StyledCupIcon />
        </StyledCircle>
        <StyledTextContainer>
          <StyledTitle>{leaderData.name}</StyledTitle>
        </StyledTextContainer>
        <StyledPaperBox>
          <StyledPaper>
            <StyledPaperTitle>{leaderData.beforePrevDayScore}</StyledPaperTitle>
            <StyledPaperSubTitle>
              {time[timePeriod.toLowerCase()].beforePrev}
            </StyledPaperSubTitle>
          </StyledPaper>
          <StyledPaper>
            <StyledPaperTitle>{leaderData.prevDayScore}</StyledPaperTitle>
            <StyledPaperSubTitle>
              {time[timePeriod.toLowerCase()].prev}
            </StyledPaperSubTitle>
          </StyledPaper>
          <StyledPaper>
            <StyledPaperTitle>{leaderData.currentDayScore}</StyledPaperTitle>
            <StyledPaperSubTitle>
              {time[timePeriod.toLowerCase()].now}
            </StyledPaperSubTitle>
          </StyledPaper>
        </StyledPaperBox>
      </StyledAccent>
    </StyledContainer>
  );
};

LeaderBox.propTypes = {
  timePeriod: PropTypes.oneOf(['day', 'week', 'month']),
  leaderData: PropTypes.shape({
    name: PropTypes.string,
    prevDayScore: PropTypes.number,
    beforePrevDayScore: PropTypes.number,
    currentDayScore: PropTypes.number
  })
};
