import React from 'react';
import PT from 'prop-types';
import { Formik, Form } from 'formik';

import { notificationFieldsSchema } from './schema';

import Alert from '@components/shared/Alert/Alert';
import Typography from '@components/shared/Typography/Typography';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Stack from '@components/shared/Stack/Stack';
import Button from '@components/shared/Button/Button';

const getInitialValues = (data) => ({
  jobTitle: '',
  messageTitle: '',
  messageTemplate: '',
  isActive: true,
  ...data
});

const NotificationStep = ({ onSubmit, jobData }) => {
  const handleSubmit = (values, actions) => {
    onSubmit({ values, actions }, {});
  };

  return (
    <Formik
      initialValues={getInitialValues(jobData)}
      onSubmit={handleSubmit}
      validationSchema={notificationFieldsSchema}
      validateOnChange={false}
      validateOnBlur
    >
      {({ errors }) => (
        <Form>
          <Typography variant="h4" my={2}>
            Notification details
          </Typography>
          <FormikField
            type="text"
            name="jobTitle"
            label="Job Title"
            placeholder="Enter job title"
          />
          <FormikField
            type="text"
            name="messageTitle"
            label="Message title"
            placeholder="Enter message title"
          />
          <FormikField
            type="text"
            name="messageTemplate"
            label="Message template"
            placeholder="Enter message template"
            rows="3"
          />
          <FormikField
            type="checkbox"
            name="isActive"
            label="Is active"
            fullWidth
          />

          {errors.nonFieldErrors && (
            <Alert
              message={errors.nonFieldErrors}
              type="error"
              className="mb-3"
            />
          )}
          <Stack size={20} alignItems="flex-start" my={2}>
            <Button type="submit">Next</Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

NotificationStep.defaultProps = {
  jobData: {}
};

NotificationStep.propTypes = {
  onSubmit: PT.func,
  jobData: PT.object
};

export default NotificationStep;
