import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { fullNameColumnWidth } from '@components/shared/DataTable/DataTable.constants';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import { filterOperators, noFieldData } from '@constants/common';
import { muiDataTableTypes } from '@constants/tableFilterConfig';
import helpers from '@utils/helpers';
import routes from '@constants/routes';
import { cleanObject } from '@utils/object-cleaner';
import { IBVPlatforms } from '@constants/internetBankVerification';
import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';

const decisions = [
  { label: 'Approved', value: 'Approved' },
  {
    label: 'Denied',
    value: 'Denied'
  }
];

export const getColumns = ({ IBVScoringReportStatusOptions }) => [
  {
    headerName: 'Action',
    field: 'actions',
    width: 60,
    sortable: false,
    filterable: false,
    align: 'center',
    renderCell: ({ row }) => (
      <ShowDetailsActionButton
        path={{
          pathname: row?.view
        }}
        target="_blank"
        hidden={!row?.view}
      />
    )
  },
  {
    headerName: 'Created',
    field: 'createdDate',
    width: 170,
    type: muiDataTableTypes.dateRange,
    renderCell: ({ row }) => helpers.getFormattedDate(row?.createdDate),
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Customer Start',
    field: 'customerStart',
    width: 170,
    type: muiDataTableTypes.dateRange,
    renderCell: ({ row }) =>
      row?.customerStart
        ? helpers.getFormattedDate(row?.customerStart)
        : noFieldData,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Completed',
    field: 'completed',
    width: 170,
    type: muiDataTableTypes.dateRange,
    renderCell: ({ row }) =>
      row?.completed ? helpers.getFormattedDate(row?.completed) : noFieldData,
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Runtime',
    field: 'runtime',
    width: 100,
    renderCell: ({ row }) => {
      const formattedTime = helpers.formatMilliseconds(row?.runtime);

      return formattedTime;
    }
  },
  {
    headerName: 'Completion Time',
    field: 'completionTime',
    width: 150,
    renderCell: ({ row }) => {
      const formattedTime = helpers.formatMilliseconds(row?.completionTime);

      return formattedTime;
    }
  },
  {
    headerName: 'Ref ID',
    field: 'referenceId',
    width: 70
  },
  {
    headerName: 'Loan ID',
    field: 'loanId',
    width: 90,
    renderCell: ({ row }) => row.loanId,
    type: muiDataTableTypes.number
  },
  {
    headerName: 'Customer Name',
    field: 'customerName',
    sortable: false,
    minWidth: fullNameColumnWidth,
    flex: 1,
    renderCell: ({ row }) => {
      const displayName = helpers.getDisplayName(row?.firstName, row?.lastName);
      return (
        <Tooltip title="User page">
          <Link to={routes.users.page.view.url(row.userId)}>{displayName}</Link>
        </Tooltip>
      );
    },
    type: muiDataTableTypes.fullName,
    filterOperators: [filterOperators.fullName]
  },
  {
    headerName: 'ABA #',
    field: 'aba',
    width: 100
  },
  {
    headerName: 'IBV Platform',
    field: 'provider',
    width: 90,
    type: muiDataTableTypes.singleSelect,
    valueOptions: IBVPlatforms,
    renderCell: ({ row }) => row.provider
  },
  {
    headerName: 'Status',
    field: 'status',
    width: 90,
    type: muiDataTableTypes.singleSelect,
    valueOptions: IBVScoringReportStatusOptions || [],
    renderCell: ({ row }) => row.status
  },
  {
    headerName: 'Decision',
    field: 'decision',
    width: 70,
    type: muiDataTableTypes.singleSelect,
    valueOptions: decisions,
    renderCell: ({ row }) => row.decision ?? noFieldData
  },
  {
    headerName: 'Error',
    field: 'error',
    width: 110,
    renderCell: ({ row }) =>
      row.error ? <Tooltip title={row.error}>{row.error}</Tooltip> : noFieldData
  },
  {
    headerName: 'Score',
    field: 'score',
    width: 70,
    renderCell: ({ row }) => row.score ?? noFieldData,
    type: muiDataTableTypes.number
  }
];

export const initialFilterParams = cleanObject({
  createdDate: {
    ge: dayjs().subtract(1, 'month').startOf('day').toISOString(),
    le: dayjs().endOf('day').toISOString()
  },
  customerStartDate: {
    ge: null,
    le: null
  },
  completedDate: {
    ge: null,
    le: null
  },
  runtime: '',
  refId: '',
  loanId: '',
  firstName: {
    startswith: ''
  },
  lastName: {
    startswith: ''
  },
  aba: {
    eq: ''
  },
  ibvPlatform: {
    contains: ''
  },
  status: {
    contains: ''
  },
  decision: {
    contains: ''
  },
  error: '',
  score: {
    eq: ''
  },
  field: ''
});
