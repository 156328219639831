import { noFieldData } from '@constants/common';

export const columns = [
  {
    headerName: 'Type',
    flex: 1,
    field: 'metaAttributeName',
    sortable: false
  },
  {
    headerName: 'Value',
    flex: 3,
    field: 'metaAttributeValue',
    sortable: false,
    valueFormatter: ({ value }) => value || noFieldData,
    renderCell: ({ row, field }) => row[field] || noFieldData
  }
];
