import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Alert from '@components/shared/Alert/Alert';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import routes from '@constants/routes';
import CampaignForm from '@components/Leads/campaigns/Form';
import Logger from '@utils/logger';
import toastify from '@utils/toastify';
import LeadManagementApi from '@utils/leadManagementApi';
import { leadManagementApiUrls } from '@constants/leadManagementApi';

const CampaignEditPage = () => {
  const { leadId, campaignId } = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await LeadManagementApi.get(
          leadManagementApiUrls.campaigns.page(campaignId)
        );
        setData(response);
      } catch (e) {
        Logger.error(e);
        setError(e);
      }
    })();
  }, []);

  const handleSubmit = async (
    { values, actions },
    { onSuccess, onError, onFinish }
  ) => {
    try {
      const responseData = await LeadManagementApi.patch(
        leadManagementApiUrls.campaigns.page(campaignId),
        values
      );

      history.push(routes.leads.leadProviders.page.view.url(leadId));

      toastify.success({
        message: 'Edit operation success'
      });

      onSuccess && onSuccess(responseData);
    } catch (e) {
      onError && onError(e);

      actions.setErrors({
        ...e,
        nonFieldErrors:
          e.nonFieldErrors || e?.response?.data?.error?.message || e.message
      });

      toastify.error({
        message: 'Error',
        description: 'Failed to submit form'
      });
    } finally {
      onFinish && onFinish();
    }
  };

  const handleDelete = async () => {
    try {
      await LeadManagementApi.delete(
        leadManagementApiUrls.campaigns.page(campaignId)
      );

      history.push(routes.leads.leadProviders.page.view.url(leadId));

      toastify.success({
        message: 'Campaign deleted'
      });
    } catch (e) {
      toastify.error({
        message: 'Delete operation failure'
      });
    }
  };

  return (
    <MainLayout title="Edit Campaign" isLoading={!data && !error}>
      {data && (
        <CampaignForm
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          data={data}
        />
      )}
      {error && (
        <Alert
          message="Failed to fetch"
          description={error}
          type="error"
          showIcon
        />
      )}
    </MainLayout>
  );
};

export default CampaignEditPage;
