import React from 'react';
import PT from 'prop-types';

import ImportCSVInfo from '@components/shared/ImportCSV/ImportCSVInfo';
import Modal from '@components/shared/Modal/Modal';
import UploadCSVForm from '@components/PastDuePayments/UploadCSV/UploadCSVForm';
import {
  requiredCSVFields,
  sampleCSVFileFormat
} from '@components/PastDuePayments/UploadCSV/UploadCSVForm.config';

const UploadCSVModal = ({ open, onClose, refetchList }) => (
  <Modal
    title="Upload CSV File"
    open={open}
    onClose={onClose}
    withCloseIcon
    minContentWidth={800}
  >
    <ImportCSVInfo
      requiredFields={requiredCSVFields}
      sampleFileFormat={sampleCSVFileFormat}
    />
    <UploadCSVForm refetchList={refetchList} onModalClose={onClose} />
  </Modal>
);

UploadCSVModal.propTypes = {
  open: PT.bool.isRequired,
  onClose: PT.func.isRequired,
  refetchList: PT.func.isRequired
};

export default UploadCSVModal;
