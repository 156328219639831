import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import SinglePageLayout from '@components/shared/SinglePageLayout';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';
import routes from '@constants/routes';
import CreditCard from '@components/CreditCard';
import Box from '@components/shared/Box/Box';

const title = 'Add debit card information';

const DebitCardPage = () => {
  const history = useHistory();

  useEffect(() => {
    if (!helpers.getActionPermission(permissionsMap.debitCardManage)) {
      history.push(routes.permissionDenied);
    }
  }, []);

  return (
    <SinglePageLayout title={title}>
      <Box
        width="100%"
        maxWidth="500px"
        alignItems="center"
        mt="50px"
        mx="auto"
      >
        <CreditCard />
      </Box>
    </SinglePageLayout>
  );
};

export default DebitCardPage;
