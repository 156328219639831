import { unsetOption } from '@constants/filters';

export const groupTypes = {
  Account: 'Account',
  Application: 'Application',
  Admin: 'Admin',
  CustomerPortal: 'Customer Portal',
  Referrer: 'Referrer',
  LeadReportingService: 'Lead Reporting Service'
};

export const groupTypesOptions = [
  unsetOption,
  ...Object.values(groupTypes).map((value) => ({
    value,
    label: value
  }))
];
