import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import Alert from '@components/shared/Alert/Alert';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Logger from '@utils/logger';
import SystemNotificationForm from '@components/SystemNotifications/Form';
import history from '@utils/history';
import routes from '@constants/routes';
import Box from '@components/shared/Box/Box';
import Typography from '@components/shared/Typography/Typography';
import toastify from '@utils/toastify';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';

const ControlPanelGroupEditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await AdminApi.get(
          adminApiUrls.notificationJob.page(id)
        );
        setData(response);
      } catch (e) {
        Logger.error(e);
        setError(e);
      }
    })();
  }, []);

  const handleSubmit = async ({ values }, { onSuccess, onError, onFinish }) => {
    try {
      const response = await AdminApi.patch(
        adminApiUrls.notificationJob.page(id),
        values
      );

      history.push(routes.notifications.systemNotifications.page.view.url(id));

      toastify.success({
        message: 'Update operation success'
      });

      onSuccess && onSuccess(response);
    } catch (e) {
      onError && onError(e);
    } finally {
      onFinish && onFinish();
    }
  };

  return (
    <MainLayout title="Edit Notification" isLoading={!data && !error}>
      <Typography variant="h3" my={2}>
        Edit Notification
      </Typography>
      <Box sx={{ maxWidth: '450px' }}>
        {data && (
          <SystemNotificationForm
            data={{
              messageTitle: data.messageTitle,
              messageTemplate: data.messageTemplate,
              notificationType: data.notificationType
            }}
            status={data.isActive}
            onSubmit={handleSubmit}
          />
        )}
        {error && (
          <Alert
            message="Failed to fetch"
            description={error}
            type="error"
            showIcon
          />
        )}
      </Box>
    </MainLayout>
  );
};

export default ControlPanelGroupEditPage;
