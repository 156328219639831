import React from 'react';
import { Link } from 'react-router-dom';

import MainLayout from '@components/shared/MainLayout/MainLayout';
import helpers from '@utils/helpers';
import { permissionsMap } from '@constants/common';
import routes from '@constants/routes';
import ODataPaginatedTable from '@components/shared/ODataPaginatedTable';
import Button from '@components/shared/Button/Button';
import { initialFilterParams, getColumns } from '@components/PromoCodes/config';
import { adminApiUrls } from '@constants/adminApiUrls';
import AdminApi from '@utils/adminApi';

const PromoCodesList = () => (
  <MainLayout title="Promo codes">
    <ODataPaginatedTable
      getColumns={getColumns}
      usePaginatedDataOptions={{
        apiUrl: adminApiUrls.promoCodes.list,
        initialFilterParams: {
          $filter: {
            ...initialFilterParams
          }
        }
      }}
      rowKey="promoCodeId"
      additionalActionButtons={[
        helpers.getActionPermission(permissionsMap.promoCodesManage) && (
          <Button component={Link} to={routes.promoCodes.create} key={0}>
            Add new promo code
          </Button>
        )
      ]}
      customApi={AdminApi}
    />
  </MainLayout>
);

export default PromoCodesList;
