import React, { useCallback, useEffect } from 'react';

import {
  endpointsForOptionsConfig,
  settingCategories
} from '@components/GlobalSettings/GlobalSettings.config';
import GlobalSettingSection from '@components/GlobalSettings/GlobalSettingSection';
import MainLayout from '@components/shared/MainLayout/MainLayout';
import Alert from '@components/shared/Alert/Alert';
import Box from '@components/shared/Box/Box';
import toastify from '@utils/toastify';
import { useServerData } from '@hooks/useServerData';
import Accordion from '@components/shared/Accordion/Accordion';
import AdminApi from '@utils/adminApi';
import { adminApiUrls } from '@constants/adminApiUrls';
import { useGetOptions } from '@hooks/useGetOptions';

const title = 'Global Settings';

const GlobalSettingsPage = () => {
  const { data, isLoading, error, fetchData, setData } = useServerData({
    endpoint: async () => {
      const response = await AdminApi.get(adminApiUrls.settings.odata);
      return response?.value;
    }
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchItem = async (settingsId) => {
    try {
      const response = await AdminApi.get(
        adminApiUrls.settings.item(settingsId)
      );
      const index = data.findIndex(
        (setting) => setting.settingsId == settingsId
      );
      const newData = [...data];
      newData[index] = response;
      setData(newData);
    } catch (error) {
      toastify.error(error);
    }
  };

  const { data: options, loading } = useGetOptions(endpointsForOptionsConfig);

  const getAccordionList = useCallback(
    (category) => [
      {
        key: category,
        header: category,
        hidden: !data?.filter((settings) => settings.category === category)
          ?.length,
        content: data?.map((setting) => {
          if (setting.category === category) {
            return (
              <GlobalSettingSection
                key={setting.settingsId}
                refreshItem={fetchItem}
                {...setting}
                value={setting.value}
                valueType={setting.valueType?.toLowerCase()}
                options={options}
                loading={loading}
              />
            );
          }
        })
      }
    ],
    [data]
  );

  return (
    <MainLayout title={title} isLoading={isLoading}>
      <Box my={1} maxWidth={550}>
        {Object.values(settingCategories)?.map((category, idx) => (
          <Box mb={2} key={idx}>
            <Accordion
              getAccordionList={() => getAccordionList(category)}
              noPadding
            />
          </Box>
        ))}
      </Box>

      {error && <Alert type="error" title="Server error" message={error} />}
    </MainLayout>
  );
};

export default GlobalSettingsPage;
