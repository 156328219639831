import dayjs from 'dayjs';

import { totalValueCellClassName } from '@components/shared/DataTable/DataTable.constants';

const highlightedCellClassName = ({ row }) =>
  row.stepName === 'Total' && totalValueCellClassName;

export const stepNamesToFieldErrorRatePages = {
  'Customer personal information': 'Customer',
  'Customer address information': 'Address',
  'Customer employment information': 'Employee',
  'Customer bank information': 'Banking',
  'Customer disclosure information': 'Disclosures',
  'Customer check information': 'check',
  'Final landing': 'lead-response'
};

export const columns = [
  {
    headerName: 'Application step',
    field: 'stepName',
    minWidth: 100,
    flex: 1,
    cellClassName: highlightedCellClassName
  },
  {
    headerName: '# of Applications Starting this Page',
    field: 'pageStartedCount',
    minWidth: 100,
    flex: 1,
    cellClassName: highlightedCellClassName
  },
  {
    headerName: '# of Applications Completing this Page',
    field: 'pageFinishedCount',
    minWidth: 100,
    flex: 1,
    cellClassName: highlightedCellClassName
  },

  {
    headerName: 'Abandon Rate',
    field: 'abandonRate',
    minWidth: 100,
    flex: 1,
    cellClassName: highlightedCellClassName
  }
];

export const getDefaultFilterInitialState = () => ({
  startDate: dayjs().subtract(1, 'month').startOf('day').toISOString(),
  endDate: dayjs().endOf('day').toISOString()
});
