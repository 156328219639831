import PT from 'prop-types';
import React, { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';

import { TabContent, TabLabel } from './TabsPanel.components';
import { StyledTabs } from './styled';

import Box from '@components/shared/Box/Box';

const TabsPanel = ({
  tabContentList,
  ariaLabel,
  setCurrentTab,
  passedValue,
  ...props
}) => {
  const [value, setValue] = useState(tabContentList[0]?.key || 0);

  useEffect(() => {
    if (passedValue) {
      setValue(passedValue);
      setCurrentTab?.(passedValue);
    }
  }, [passedValue]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    setCurrentTab?.(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label={ariaLabel}
          {...props}
        >
          {tabContentList?.map(
            ({ key, label, hidden, count }, idx) =>
              !hidden && (
                <Tab
                  label={<TabLabel label={label} count={count} />}
                  value={key || idx}
                  aria-controls="tabcontent-0"
                  key={key || idx}
                />
              )
          )}
        </StyledTabs>
      </Box>
      {tabContentList?.map(
        ({ key, content, hidden }, idx) =>
          !hidden && (
            <TabContent value={value} idx={key || idx} key={idx}>
              {content}
            </TabContent>
          )
      )}
    </Box>
  );
};

TabsPanel.defaultProps = {
  ariaLabel: 'tabs-panel'
};

TabsPanel.propTypes = {
  tabContentList: PT.arrayOf(
    PT.shape({
      key: PT.oneOfType([PT.string, PT.number]),
      label: PT.string.isRequired,
      count: PT.number,
      content: PT.node.isRequired,
      hidden: PT.bool
    })
  ),
  ariaLabel: PT.string,
  setCurrentTab: PT.func,
  passedValue: PT.string
};

export default TabsPanel;
