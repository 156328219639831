import React, { useState } from 'react';
import { Stack } from '@mui/material';

import CCSearchInput from '@components/CreditCard/CCSearchInput';
import CCForm from '@components/CreditCard/CCForm';
import TextField from '@components/shared/TextField/TextField';

const CreditCard = () => {
  const [data, setData] = useState(null);
  const [showSearch, setShowSearch] = useState(true);

  const handleCardAdded = () => {
    setShowSearch(false);
    setData(null);
    setShowSearch(true);
  };

  return (
    <>
      {showSearch && (
        <>
          <CCSearchInput setSearchData={setData} />
          <Stack direction="column" gap={1}>
            <TextField
              value={data?.firstName || ''}
              label="First name"
              disabled
            />
            <TextField
              value={data?.lastName || ''}
              label="Last name"
              disabled
            />
          </Stack>
        </>
      )}
      {data && <CCForm data={data} onSuccess={handleCardAdded} />}
    </>
  );
};

export default CreditCard;
